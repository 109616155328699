import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import { RxReset } from "react-icons/rx";
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Checkbox from "@mui/material/Checkbox";
import TextField from '@mui/material/TextField';
import Constants from '../../../config/constants'
import billingRequest from '../../../_network/billing_request';
import { billingServiceURLs } from '../../../_network/apiUrls';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CustomDialog from '../../_common/CustomDialog';
import FormControlLabel from '@mui/material/FormControlLabel';
import { 
    openStackServiceLabels,
    openStackResourceLabels,
    openStackServices
 } from '../../../config/openStackConstants';

const drawerWidth = Constants.drawerWidth;

const BillingNewResources = (props) => {
    const drawerOpen = useSelector(state => state.drawer.drawerOpened);
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const clientID = useSelector(state => state.profile.clientAccountID)
    const regionName = useSelector(state => state.settings.regionName)
    const { newResources, handleDataFetch } = props
    const [selectedResources, setSelectedResources] = useState([])
    const [resourceAddMode, setResourceAddMode] = useState(false)
    const purchasedService = useSelector(
        state => state.openstack.purchasedServicesAll)
    const [billingServicesChecked, setBillingServicesChecked] = useState(false)
    const [priceCreateAllowed, setPriceCreateAllowed] = useState(false)
    const [newResourceCreateDialogOpen, setNewResourceCreateDialogOpen] = useState(false)
    const [selectedFWResources, setSelectedFWResources] = useState([]);
    const [newFWResources, setNewFWResources] = useState([]);
    const [newFWResource, setNewFWResource] = useState(false)

    const theme = useTheme();

    const handleSelectNewResourceEditing = (event, resource) => {
        if (event.target.checked) {
            let new_item = {}
            new_item["account_id"] = clientID
            new_item["region_name"] = resource.region_name
            new_item["service_name"] = resource.service_name
            new_item["resource_name"] = resource.resource_name
            new_item["resource_id"] = resource.id
            if (resource.resource_name === "instance") {
                new_item["price"] = {"active": 0.0, "other": 0.0}
                new_item["props"] = {
                    name: resource.name, 
                    cpu: resource.vcpus, 
                    ram: resource.ram, 
                    disk: resource.disk
                }
            } else {
                new_item["price"] = 0.0
                new_item["props"] = {name: resource.name}
            }
            let new_list = [...selectedResources, new_item]
            setSelectedResources(new_list)
        } else {
            let new_list = selectedResources.filter(
                res => res.resource_id !== resource.id)
                setSelectedResources(new_list)
        }
    }

    const handlePriceChange = (event, item_id, status) => {
        const new_list = selectedResources.map(item => {
            if (item.resource_id === item_id) {
                let new_item = {...item}
                if (item.resource_name === "instance") {
                    new_item.price[status] = parseFloat(event.target.value) ? 
                    parseFloat(event.target.value) : 0
                } else {
                    new_item.price = parseFloat(event.target.value) ? 
                    parseFloat(event.target.value) : 0
                }
                return new_item
            } else {
                return item
            }
        })
        setSelectedResources(new_list)
    }

    const handleResourceAddReset = () => {
        setSelectedResources([])
        setResourceAddMode(false)
    }

    const handleSaveNewResources = async () => {
        if (selectedResources.length > 0) {
            let data = []
            for (let res in selectedResources) {
                if (selectedResources[res].resource_name === "instance") {
                    let active_price = {
                        ...selectedResources[res], 
                        price: selectedResources[res].price.active, 
                        resource_status: "active"}
                    let other_price = {
                        ...selectedResources[res], 
                        price: selectedResources[res].price.other, 
                        resource_status: "other"}
                    data.push(active_price)
                    data.push(other_price)
                } else {
                    data.push({...selectedResources[res]})
                }
            }
            const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingPricesEP}-list`
            let request_data = {
                url: url,
                method: "POST",
                data: {resources: data}
            }
            const response = await billingRequest(request_data)
            if (response.status_code === 201) {
                setSelectedResources([])
                setResourceAddMode(false)
                handleDataFetch()
            }
        } else {
            setResourceAddMode(false)
        }
    }

    const handleSelectNewFWResourceEditing = (event, index) => {
        if (event.target.checked) {
            const new_list = [...new Set([...selectedFWResources, index])]
            setSelectedFWResources(new_list)
        } else {
            const new_list = selectedFWResources.filter(item => item !== index)
            setSelectedFWResources(new_list)
        }
    }

    const handleFWCountChange = (event, index, position) => {
        let new_list = [...newFWResources]
        if (position === "start") {
            new_list[index].resource_count[0] = parseInt(event.target.value) ? parseInt(event.target.value) : 5
        } else {
            new_list[index].resource_count[1] = parseInt(event.target.value) ? parseInt(event.target.value) : 100000
        }
        setNewFWResources(new_list)
    }

    const handleFWPriceChange = (event, index) => {
        let new_list = [...newFWResources]
        new_list[index].price = parseFloat(event.target.value) ? 
        parseFloat(event.target.value) : 0
        setNewFWResources(new_list)
    }

    const handleSaveNewFWResources = async () => {
        if (selectedFWResources.length > 0) {
            let data = newFWResources.filter((item,index) => selectedFWResources.includes(index))
            console.log(data)
            const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingPricesEP}-list`
            let request_data = {
                url: url,
                method: "POST",
                data: {resources: data}
            }
            const response = await billingRequest(request_data)
            if (response.status_code === 201) {
                setSelectedFWResources([])
                setNewFWResources([])
                handleDataFetch()
            }
        }
    }

    const onNewResourceCreate = () => {
        if (newFWResource) {
            let new_item = {}
            new_item["account_id"] = clientID
            new_item["resource_count"] = [5, 100]
            new_item["price"] = 0
            new_item["region_name"] = regionName
            new_item["resource_id"] = null
            new_item["resource_name"] = "fwaas_rule"
            new_item["service_name"] = openStackServices.networkService

            const new_list = [...newFWResources, new_item]
            setNewFWResources(new_list)
        }
    }

    const handleImaNewResourceCreateDialogClose = () => {
        setNewResourceCreateDialogOpen(false)
    }

    useEffect(() => {
        if (!billingServicesChecked && purchasedService.length > 0) {
            const billingService = purchasedService.filter(
            service => service.service === Constants.config_service_billing_name)
            const billingConfiguration = billingService[0].config_params[Constants.config_service_custom_admin_ui]
            if (billingConfiguration.filter(
                section => section.section === Constants.billing_sections.billing_prices && 
                section.permissions.includes(Constants.permission_create)).length > 0) {
                    setPriceCreateAllowed(true)
            }
            setBillingServicesChecked(true)
        }
    },[
        purchasedService,
        billingServicesChecked
    ])

    return (
        <React.Fragment>
            <Box sx={{height: 40, mt: 5}}>
                {priceCreateAllowed && 
                    <Grid 
                        container 
                        alignItems="center" 
                        justifyContent="space-between"
                        sx={{
                            maxWidth: drawerOpen ? 
                            `calc(100vw - ${drawerWidth}px)` : 
                            `calc(100vw - ${110}px)`,
                        }}
                    >
                        <Grid item>
                        <Button 
                            variant="contained" 
                            color="secondary"
                            onClick={() => setNewResourceCreateDialogOpen(true)}
                        >
                            {defaultTexts.addNewResourceButtonTitle}
                        </Button>
                        </Grid>
                        <Grid item>
                            <Grid 
                                container
                                alignItems="center" 
                                justifyContent="flex-end"
                            >
                                {resourceAddMode && <Grid item>
                                    <Tooltip title={defaultTexts.resetPriceEditModeTooltipText}>
                                        <IconButton onClick={handleResourceAddReset} sx={{mr: 1}}>
                                            <RxReset 
                                                color={theme.palette.text.primary}
                                                fontSize={'large'}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>}
                                <Grid item>
                                    {resourceAddMode && 
                                        <Button 
                                            variant="contained" 
                                            color="primary"
                                            sx={{width: 120}}
                                            onClick={handleSaveNewResources}
                                        >
                                            {defaultTexts.saveButtonTitle}
                                        </Button>
                                    }
                                    {!resourceAddMode && 
                                        <Button 
                                            variant="outlined" 
                                            color="primary"
                                            sx={{width: 120}}
                                            onClick={() => setResourceAddMode(true)}
                                        >
                                            {defaultTexts.editButtonTitle}
                                        </Button>
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                </Grid>}
            </Box>
            {!resourceAddMode && <Box sx={{
                maxWidth: drawerOpen ? 
                `calc(100vw - ${drawerWidth}px)` : 
                `calc(100vw - ${110}px)`,
                mt: 2
            }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 300}} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell  align="left">
                                    {defaultTexts.serviceFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.regionFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.resourceFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.nameFormFieldLabel}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {newResources.map((row) => (
                                <TableRow 
                                    key={`${row.service_name}_${row.region_name}_${row.resource_name}_${row.id}`}
                                >
                                    <TableCell>{openStackServiceLabels[row.service_name]}</TableCell>
                                    <TableCell>{row.region_name}</TableCell>
                                    <TableCell>{openStackResourceLabels[row.resource_name]}</TableCell>
                                    <TableCell>{row.name}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>}
            {resourceAddMode && <Box sx={{
                maxWidth: drawerOpen ? 
                `calc(100vw - ${drawerWidth}px)` : 
                `calc(100vw - ${110}px)`,
                mt: 2
            }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 300}} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" style={{width: 30}}/>
                                <TableCell  align="left">
                                    {defaultTexts.serviceFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.regionFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.resourceFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.nameFormFieldLabel}
                                </TableCell>
                                <TableCell  align="right">
                                    {defaultTexts.priceFormFieldLabel}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {newResources.map((row) => (
                                <TableRow key={`${row.service_name}_${row.region_name}_${row.id}`}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            checked={selectedResources.filter(
                                                price => price.resource_id === row.id).length > 0}
                                            onClick={
                                                (event) => handleSelectNewResourceEditing(event, row)
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>{openStackServiceLabels[row.service_name]}</TableCell>
                                    <TableCell>{row.region_name}</TableCell>
                                    <TableCell>{openStackResourceLabels[row.resource_name]}</TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    {selectedResources.filter(price => price.resource_id === row.id).length > 0 ?
                                        <React.Fragment>
                                        {row.resource_name === "instance" ? 
                                            <TableCell align="right">
                                                <TextField 
                                                    variant="standard" 
                                                    label={defaultTexts.activeStatusFormFieldLabel}
                                                    value={selectedResources.filter(
                                                        i => i.resource_id === row.id)[0].price.active}
                                                    onChange={(event) => handlePriceChange(event, row.id, "active")}
                                                    color="primary"
                                                    type="number"
                                                    inputProps={{
                                                        step: 0.000001
                                                    }}
                                                    sx={{width: 100}}
                                                />
                                                <TextField 
                                                    variant="standard" 
                                                    label={defaultTexts.otherStatusFormFieldLabel}
                                                    value={selectedResources.filter(i => i.resource_id === row.id)[0].price.other}
                                                    onChange={(event) => handlePriceChange(event, row.id, "other")}
                                                    color="primary"
                                                    type="number"
                                                    inputProps={{
                                                        step: 0.000001
                                                    }}
                                                    sx={{width: 100}}
                                                />
                                            </TableCell> :
                                            <TableCell  align="right">
                                                <TextField 
                                                    variant="standard" 
                                                    value={selectedResources.filter(i => i.resource_id === row.id)[0].price}
                                                    onChange={(event) => handlePriceChange(event, row.id)}
                                                    color="primary"
                                                    type="number"
                                                    inputProps={{
                                                        step: 0.000001
                                                    }}
                                                    sx={{width: 200}}
                                                />
                                            </TableCell>
                                        }
                                        </React.Fragment> :
                                        <TableCell align="right">{defaultTexts.noPriceTableTitle}</TableCell>
                                    }
                                </TableRow> 
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>}
            {newFWResources.length > 0 && <Box sx={{
                maxWidth: drawerOpen ? 
                `calc(100vw - ${drawerWidth}px)` : 
                `calc(100vw - ${110}px)`,
                mt: 2
            }}>
                <Button 
                    variant="contained" 
                    color="primary"
                    sx={{width: 120, my: 2}}
                    onClick={handleSaveNewFWResources}
                >
                    {defaultTexts.saveButtonTitle}
                </Button>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 300}} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" style={{width: 30}}/>
                                <TableCell  align="left">
                                    {defaultTexts.serviceFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.regionFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.resourceFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.ruleRangeStartFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.ruleRangeEndFormFieldLabel}
                                </TableCell>
                                <TableCell  align="right">
                                    {defaultTexts.priceFormFieldLabel}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {newFWResources.map((row,index) => (
                                <TableRow key={`${row.service_name}_${row.region_name}_${row.id}`}>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            checked={selectedFWResources.includes(index)}
                                            onClick={
                                                (event) => handleSelectNewFWResourceEditing(event, index)
                                            }
                                        />
                                    </TableCell>
                                    <TableCell>{openStackServiceLabels[row.service_name]}</TableCell>
                                    <TableCell>{row.region_name}</TableCell>
                                    <TableCell>{openStackResourceLabels[row.resource_name]}</TableCell>
                                    <TableCell  align="left">
                                        <TextField 
                                            variant="standard" 
                                            value={row.resource_count[0]}
                                            onChange={(event) => handleFWCountChange(event, index, "start")}
                                            color="primary"
                                            type="number"
                                            sx={{width: 200}}
                                        />
                                    </TableCell>
                                    <TableCell  align="left">
                                        <TextField 
                                            variant="standard" 
                                            value={row.resource_count[1]}
                                            onChange={(event) => handleFWCountChange(event, index, "end")}
                                            color="primary"
                                            type="number"
                                            sx={{width: 200}}
                                        />
                                    </TableCell>
                                    <TableCell  align="right">
                                        <TextField 
                                            variant="standard" 
                                            value={row.price}
                                            onChange={(event) => handleFWPriceChange(event, index)}
                                            color="primary"
                                            type="number"
                                            inputProps={{
                                                step: 0.000001
                                            }}
                                            sx={{width: 200}}
                                        />
                                    </TableCell>
                                </TableRow> 
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>}
            <CustomDialog
                open={newResourceCreateDialogOpen}
                onClose={handleImaNewResourceCreateDialogClose}
                dialogTitle={{
                    title: defaultTexts.selectNewResourceToCreateFormTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.selectNewResourceToCreateFormText, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText,
                    onClick: onNewResourceCreate, 
                    sx: {color: 'primary.main'}}]}
            >
                <FormControlLabel 
                    control={<Checkbox 
                        color="primary"
                        checked={newFWResource}
                        onClick={
                            (event) => setNewFWResource(!newFWResource)
                        }
                        />} 
                    label={defaultTexts.firewallRuleRangeFormFieldLable} 
                />
            </CustomDialog>
        </React.Fragment>
    );

}

export default BillingNewResources;