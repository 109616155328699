import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
//import Constants from '../../../../../config/constants';
import AddButton from '../../../../_common/AddButton';
//import FilterButton from '../../../../_common/FilterButton';
import WrapperBox from '../../../../_common/WrapperBox';
import { FormGroup } from '@mui/material';
import { keypairDataForm } from 
'../../../../../_data/openstack/compute/keypairs/v2.1';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { computeNovaConstants } from 
'../../../../../config/openStackConstants';
import { openstackRequest } from 
'../../../../../_network/openstack_request';
import { keypairsUrl as keypairsUrlResponses } from 
'../../../../../_api_responses/openstack/compute/keypairs/v2.1';
import CustomDialog from '../../../../_common/CustomDialog';
import { getXAuthTokenProjectScope } 
from '../../../../../_network/openstack_request';

const SERVICE_NAME = openStackServices.computeService

const ComputeNovaKeypairsSubheaderV21 = (props) => {
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [error, setError] = useState()
    const [successKeypairAdd, setSuccessKeypairAdd] = useState()
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    //const { selectedFilter, setSelectedFilter } = props
    //const { selectedFilterValue, setSelectedFilterValue } = props
    const { handleFetchData } = props
    //const { filterMenu, defaultTexts } = props
    const { defaultTexts } = props
    //const { handleFilteredSearch, handleFilterReset } = props
    const [formData, setFormData] = useState({})
    const [formDataOptions, setFormDataOptions] = useState({});
    const { users } = props

    const computeServiceDomain = useSelector(state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const computeServiceVersion = useSelector(state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const keypairsUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.keyPairsUrl)[0].url)

    const handleFormDataValidation = () => {
        let validation_faild = true
        let updatedDataFormOptions = {...formDataOptions}
        for (let n in keypairDataForm) {
            if (keypairDataForm[n].required && !formData[keypairDataForm[n].field_key]) {
                validation_faild = false
                updatedDataFormOptions[keypairDataForm[n].field_key] = {}
                updatedDataFormOptions[keypairDataForm[n].field_key]["error"] = true
                updatedDataFormOptions[keypairDataForm[n].field_key]["errorText"] = defaultTexts[keypairDataForm[n].error_label]
            }
        }
        setFormDataOptions(updatedDataFormOptions)
        return validation_faild
    }

    const onAddKeypair = async () => {
        const validateFormData = handleFormDataValidation()
        if (validateFormData) {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${computeServiceDomain}/${computeServiceVersion}/${keypairsUrl}`
                const method = "POST"
                
                const keypair_response = await openstackRequest({url:url, method:method, data: {keypair: formData}, token: project_token})
                if (keypair_response.status_code === keypairsUrlResponses.post.success_response.status_code) {
                    handleAddKeypairFormReset()
                    handleFetchData()
                    setSuccessKeypairAdd({
                        success_title: keypairsUrlResponses.post.success_response.response_title, 
                        success_message: keypairsUrlResponses.post.success_response.response_message
                    })
                } else {
                    const error_response = keypairsUrlResponses.post.error_response.filter(
                        error_item => error_item.status_code === keypair_response.status_code)
                    if (error_response.length > 0) {
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: keypair_response.error
                        }
                        setError(errorObject)
                    } else {
                        const error_response = keypairsUrlResponses.post.error_response.filter(
                            error_item => error_item.status_code === "unknown")
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: keypair_response.error
                        }
                        setError(errorObject)
                    }
                }
            }
        }
        return validateFormData
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleSuccessDialogClose = () => {
        setSuccessKeypairAdd(null);
        setSuccessDialogOpen(false);
    }

    const handleAddKeypairFormReset = () => {
        setFormDataOptions({})
        let new_form_data = {}
        for (const n in keypairDataForm) {
            if (
                keypairDataForm[n].field_type === "string" || 
                keypairDataForm[n].field_type === "select" ||
                keypairDataForm[n].field_type === "list"
                ) {
                new_form_data[keypairDataForm[n].field_key] = ""
            } else if (keypairDataForm[n].field_type === "bool") {
                new_form_data[keypairDataForm[n].field_key] = keypairDataForm[n].default_value ? 
                keypairDataForm[n].default_value : 
                false
            }
        }
        setFormData(new_form_data)
    }

    const handleFormDataChange = (event,field_key) => {
        let new_form_data = {...formData}
        if (keypairDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (keypairDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setFormData(new_form_data)
    }

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessDialogOpen(true)
    },[successKeypairAdd]);

    useEffect(() => {
        if (Object.keys(formData).length === 0) {
            let new_form_data = {}
            for (const n in keypairDataForm) {
                if (
                    keypairDataForm[n].field_type === "string" || 
                    keypairDataForm[n].field_type === "select" ||
                    keypairDataForm[n].field_type === "list"
                    ) {
                    new_form_data[keypairDataForm[n].field_key] = ""
                } else if (keypairDataForm[n].field_type === "bool") {
                    new_form_data[keypairDataForm[n].field_key] = keypairDataForm[n].default_value ? 
                    keypairDataForm[n].default_value : 
                    false
                }
            }
            setFormData(new_form_data)
        }
    },[formData]);

    const getDataForm = () => {
        return (
            <FormGroup>
                {keypairDataForm.map(field => {
                    let form_field_options = {...formDataOptions[field.field_key]}
                    if (field.field_key === "user_id") {
                        form_field_options["items"] = users
                        form_field_options["self_item_titles"] = field.self_item_titles
                        form_field_options["default_empty"] = field.default_empty
                    }
                    if (field.field_key === "type") {
                        form_field_options["items"] = field.items
                        form_field_options["self_item_titles"] = field.self_item_titles
                        form_field_options["default_empty"] = field.default_empty
                    }
                    if (field.field_key === "public_key") {
                        form_field_options["multiline"] = field.multiline
                        form_field_options["minRows"] = field.minRows
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            formData,
                            handleFormDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    return (
        <WrapperBox 
            sx={{
                flexDirection: "row", 
                justifyContent: 'space-between',
                mt: 1
            }}>
            <AddButton 
                getDataForm={getDataForm}               
                onSubmit={onAddKeypair}
                formReset={handleAddKeypairFormReset}
                customTexts={{
                    title: defaultTexts.addKeypairDialogTitle,
                    description: defaultTexts.addKeypairDialogDescriptionText
                }}
            />
            {successKeypairAdd && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[successKeypairAdd.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successKeypairAdd.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>Details:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </WrapperBox>
    )
}

export default ComputeNovaKeypairsSubheaderV21;