import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    isAuthenticated: false,
    sessionDatetime: null,
    userID: null,
    userDomain: null,
    username: '',
    password: '',
    clientAccountID: null,
    access_token: null,
    x_auth_token: null,
    adminProjects: [],
    defaultAdminProject: null
}

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setUserCredentials: (state, action) => {
        state.username = action.payload.username
        state.password = action.payload.password
    },
    setUserID: (state, action) => {
      state.userID = action.payload.user_id
    },
    setUserDomain: (state, action) => {
      state.userDomain = action.payload.domain_id
    },
    setClientAccountID: (state, action) => {
        state.clientAccountID = action.payload.clientAccountID
    },
    setUserIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload.isAuthenticated
    },
    setAccessToken: (state, action) => {
      state.access_token = action.payload.access_token
    },
    setXAuthToken: (state, action) => {
      state.x_auth_token = action.payload.x_auth_token
    },
    setAdminProjects: (state, action) => {
      state.adminProjects = action.payload.adminProjects
    },
    setDefaultAdminProject: (state, action) => {
      state.defaultAdminProject = action.payload.defaultAdminProject
    },
    setSessionDatetime: (state, action) => {
      state.sessionDatetime = action.payload.sessionDatetime
    },
    profileReset: () => {
      return {...initialState}
    }
  }
})

export const { 
    setUserCredentials, 
    setUserID,
    setUserDomain,
    setClientAccountID, 
    setUserIsAuthenticated, 
    setAccessToken,
    setXAuthToken,
    setAdminProjects,
    setDefaultAdminProject,
    setSessionDatetime,
    profileReset
} = profileSlice.actions

export default profileSlice.reducer