import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import WrapperBox from '../../../../../_common/WrapperBox';
import Paper from '@mui/material/Paper';
import NoDataNote from '../../../../../_common/NoDataNote';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import NetworkSpecsV20 from './networkSpecsV2.0';
import NetworkSegmentsV20 from './networkSegmentsV2.0';
import NetworkSubnetsV20 from './networkSubnetsV2.0';
import useWindowDimensions from 
'../../../../../_common/WindowDimensions';
import { getFormFieldComponent } from 
'../../../../../_common/_form_fields/form_helpers';
import Constants from '../../../../../../config/constants';
import { Grid }  from '@mui/material';
import { 
    openstackRequest,
    getXAuthTokenProjectScope} from 
'../../../../../../_network/openstack_request';
import { networksUrl as networkUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/networks/v2.0';
import { openStackServices } from 
'../../../../../../config/openStackConstants';
import { 
    networkNeutronConstants
} from '../../../../../../config/openStackConstants';
import ServiceContentHeader from 
'../../../../../_common/ServiceContentHeader';
import CustomSelectField from 
'../../../../../_common/_form_fields/CustomSelectField';
import CustomDialog from 
'../../../../../_common/CustomDialog';
import { 
    networkDataUpdateForm
} from '../../../../../../_data/openstack/neutron/networks/v2.0';

const SERVICE_NAME = openStackServices.networkService
const FOOTER_HEIGHT = Constants.actions_bar_height + 20

const NetworkDetailV20 = (props) => {
    const [isCardLoading, setIsCardLoading] = useState(true)
    const [error, setError] = useState();
    const { selectedRow, handleDataFetch } = props
    const { widthWeight } = props
    const { handleDelete } = props
    const { projects } = props
    const { handleNavigateToSubnet } = props
    const { width } = useWindowDimensions();
    const FOOTER_WIDTH =  width - (width * widthWeight)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);

    const [networkActions, setNetworkActions] = useState([]);
    const [currentAction, setCurrentAction] = useState("");

    const [networkUpdateData, setNetworkUpdateData] = useState({})
    const [updateNetworkDialogOpen, setUpdateNetworkDialogOpen] = useState(false)
    const [networkSubnetsList, setNetworkSubnetsList] = useState([])

    const [networkSubMenu, setNetworkSubMenu] = useState([
        {keyword: "submenuDetails", navigation: "/network-details", is_active: true},
        {keyword: "submenuSubnets", navigation: "/network-subnets", is_active: false},
        {keyword: "submenuNetworkSegments", navigation: "/network-segments", is_active: false}
    ])

    const [currentTab, setCurrentTab] = useState("/network-details")
    
    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const networksUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.networksUrl)[0].url)
    const subnetsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.subnetsUrl)[0].url)

    const common_url = `${neutronServiceDomain}/${neutronServiceVersion}/${networksUrl}/${selectedRow.id}`

    const getFormattedNetworkData = useCallback((data) => {
        const project = projects.filter(p => p.id === data.project_id)[0]
        let formatted_data = {...data}
        formatted_data.project_id = project ? 
        project.name : 
        data.project_id
        return formatted_data
    },[projects])


    const handleNetworkDetailTabChange = useCallback((navigation) => {
        let newNetworkSubmenuData = networkSubMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
            } else {
                item.is_active = false
            }
            return item
        })
        setNetworkSubMenu(newNetworkSubmenuData)
        setCurrentTab(navigation)
    },[
        networkSubMenu
    ])

    const handleConfirmDeleteDialogOpen = () => {
        handleDelete([selectedRow.id])
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const getDataForm = (form,form_options,data,onDataChange) => {
        return (
            <FormGroup>
                {form.map(field => {
                    return (
                        getFormFieldComponent(
                            field,
                            data,
                            onDataChange,
                            defaultTexts[field.label],
                            {...form_options[field.field_key]}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleNetworkUpdateDataChange = (event,field_key) => {
        let new_form_data = {...networkUpdateData}
        if (networkDataUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else {
            new_form_data[field_key] = event.target.value
        }
        setNetworkUpdateData(new_form_data)
    }

    const handleUpdateNetworkDialogOpen = () => {
        setUpdateNetworkDialogOpen(true)
    }

    const handleUpdateNetworkDialogClose = () => {
        setUpdateNetworkDialogOpen(false)
    }

    const onNetworkUpdate = async () => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const method = "PUT"
            const network_response = await openstackRequest({
                url: common_url, 
                method: method, 
                data: {network: networkUpdateData},
                token: project_token
            })
            if (network_response.status_code === networkUrlResponses.put.success_response.status_code) {
                setCurrentAction("")
                handleUpdateNetworkDialogClose()
                handleDataFetch()
            } else {
                setError(network_response.error)
            }
        }
    }

    useEffect(() => {
        let network_actions = []
        let new_action = {}
        new_action["value"] = "update_network"
        new_action["action"] = handleUpdateNetworkDialogOpen
        new_action["keyword"] = "networkUpdateActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        network_actions.push({...new_action})
        
        setNetworkActions(network_actions)
    },[
        selectedRow
    ])

    useEffect(() => {
        (async () => {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${subnetsUrl}`
            const method = "GET"
            const project_token = await getXAuthTokenProjectScope(
                defaultAdminProject)
            if (project_token) {
                try {
                    const subnets_response = await openstackRequest({
                        url:url, 
                        method:method,
                        token: project_token
                    })
                    const subnets_list = subnets_response.data.subnets.filter(s => selectedRow.subnets.includes(s.id))
                    setNetworkSubnetsList(subnets_list)
                } catch {
                    setNetworkSubnetsList([])
                }
            } else {
                setNetworkSubnetsList([])
            }
        })();
    },[
        neutronServiceDomain,
        neutronServiceVersion,
        subnetsUrl,
        defaultAdminProject,
        selectedRow
    ]);

    useEffect(() => {
        if (Object.keys(networkUpdateData).length === 0) {
            let new_form_data = {}
            for (const n in networkDataUpdateForm) {
                new_form_data[networkDataUpdateForm[n].field_key] = selectedRow[networkDataUpdateForm[n].field_key]
            }
            setNetworkUpdateData(new_form_data)
        }
    },[networkUpdateData, selectedRow]);

    useEffect(() => {
        setTimeout(() => setIsCardLoading(false), 600)
    },[])

    return (
        <React.Fragment>
        {selectedRow !== null && 
        <WrapperBox>
            <ServiceContentHeader 
                service_menu={networkSubMenu}
                service_menu_titles={defaultTexts}
                onClick={handleNetworkDetailTabChange}
            />
        </WrapperBox>}
        {currentTab === "/network-details" &&
            <NetworkSpecsV20
                networkData={getFormattedNetworkData(selectedRow)}
                selectedRow={selectedRow}
            />
        }
        {currentTab === "/network-subnets" && 
            <div>
                {selectedRow.subnets.length > 0 ?
                    <NetworkSubnetsV20
                        networkSubnets={networkSubnetsList}
                        handleNavigateToSubnet={handleNavigateToSubnet}
                    />
                :
                    <NoDataNote text={defaultTexts.noSubnetsNoteText} />}
            </div>
        }
        {currentTab === "/network-segments" && 
            <div>
                {(Object.keys(selectedRow).includes("segments") && selectedRow.segments.length > 0) ?
                    <NetworkSegmentsV20
                        networkSegments={[...selectedRow.segments]} 
                        selectedRow={selectedRow}
                        handleDataFetch={handleDataFetch}
                    />
                :
                    <NoDataNote text={defaultTexts.noSegmentsNoteText} />}
            </div>
        }
        {!isCardLoading && <Paper sx={{ 
            position: 'fixed', 
            bottom: 0, 
            height: FOOTER_HEIGHT,
            left: FOOTER_WIDTH, 
            right: 12,
            zIndex: 3000
            }} 
            elevation={24}
            square={true}
        >
            <Grid 
                container 
                alignItems="center"  
                justifyContent="space-between"
            >
                <Grid item>
                    <CustomSelectField 
                        items={networkActions} 
                        currentValue={currentAction}
                        setCurrentValue={setCurrentAction}
                        item_titles={defaultTexts}
                        label={defaultTexts.actionsDropdownLabelText}
                        empty={true}
                        size="small"
                        sx={{m: 1}}
                    />
                    {currentAction.length > 0 && 
                        <Button 
                                variant="contained"
                                color="secondary"
                                sx={{m: 1, height: '70%'}}
                                onClick={networkActions.filter(
                                    action => action.value === currentAction)[0].action
                                }
                            >
                            {defaultTexts[networkActions.filter(
                                action => action.value === currentAction)[0].button_text]}
                        </Button>
                    }
                </Grid>
                <Grid item>
                    {selectedRow !== null && 
                        <IconButton onClick={handleConfirmDeleteDialogOpen}>
                            <DeleteIcon 
                                color="primary"
                            />
                        </IconButton>}
                </Grid>
            </Grid>
        </Paper>} 
        <CustomDialog
            open={updateNetworkDialogOpen}
            onClose={handleUpdateNetworkDialogClose}
            dialogTitle={{
                title: defaultTexts.updateNetworkActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onNetworkUpdate, 
                sx: {color: 'primary.main'}}]}
        >
            {getDataForm(
                networkDataUpdateForm,
                {},
                networkUpdateData,
                handleNetworkUpdateDataChange
            )}
        </CustomDialog>
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts.failedActionErrorDialogTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts.failedActionErrorDialogMessage}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
        </React.Fragment>
    )
};

export default NetworkDetailV20;