import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import WrapperBox from '../../../../../_common/WrapperBox';
import Paper from '@mui/material/Paper';
import NoDataNote from '../../../../../_common/NoDataNote';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import TrunkSpecsV20 from './trunkSpecsV2.0';
import TrunkSubPortsV20 from './trunkSubPortsV2.0';
import useWindowDimensions from 
'../../../../../_common/WindowDimensions';
import { getFormFieldComponent } from 
'../../../../../_common/_form_fields/form_helpers';
import Constants from '../../../../../../config/constants';
import { Grid }  from '@mui/material';
import { 
    openstackRequest,
    getXAuthTokenProjectScope} from 
'../../../../../../_network/openstack_request';
import { trunksUrl as trunkUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/trunks/v2.0';
import { openStackServices } from 
'../../../../../../config/openStackConstants';
import { 
    networkNeutronConstants
} from '../../../../../../config/openStackConstants';
import ServiceContentHeader from 
'../../../../../_common/ServiceContentHeader';
import CustomSelectField from 
'../../../../../_common/_form_fields/CustomSelectField';
import CustomDialog from 
'../../../../../_common/CustomDialog';
import { 
    trunkDataUpdateForm, 
    trunkSubportForm,
    trunkSubportRemoveForm
} from '../../../../../../_data/openstack/neutron/trunks/v2.0';

const SERVICE_NAME = openStackServices.networkService
const FOOTER_HEIGHT = Constants.actions_bar_height + 20

const TrunkDetailV20 = (props) => {
    const [isCardLoading, setIsCardLoading] = useState(true)
    const [error, setError] = useState();
    const { selectedRow, handleDataFetch } = props
    const { widthWeight } = props
    const { handleDelete, handleAdminStateUpdate } = props
    const { projects, ports, subnets } = props
    const { width } = useWindowDimensions();
    const FOOTER_WIDTH =  width - (width * widthWeight)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);

    const [trunkActions, setTrunkActions] = useState([]);
    const [currentAction, setCurrentAction] = useState("");

    const [trunkUpdateData, setTrunkUpdateData] = useState({})
    const [updateTrunkDialogOpen, setUpdateTrunkDialogOpen] = useState(false)
    const [trunkSubPorts, setTrunkSubPorts] = useState([])
    const [trunkAddSubportDialogOpen, setTrunkAddSubportDialogOpen] = useState(false)
    const [trunkSubportData, setTrunkSubportData] = useState({})
    const [trunkRemoveSubportDialogOpen, setTrunkRemoveSubportDialogOpen] = useState(false)
    const [trunkSubportRemoveData, setTrunkSubportRemoveData] = useState({})
    const [trunkSubportOptions, setTrunkSubportOptions] = useState({})
    const [trunkSubportRemoveOptions, setTrunkSubportRemoveOptions] = useState({})

    const [trunkSubMenu, setTrunkSubMenu] = useState([
        {keyword: "submenuDetails", navigation: "/trunk-details", is_active: true},
        {keyword: "submenuSubPorts", navigation: "/trunk-subports", is_active: false}
    ])

    const [currentTab, setCurrentTab] = useState("/trunk-details")
    
    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const trunksUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.trunksUrl)[0].url)

    const common_url = `${neutronServiceDomain}/${neutronServiceVersion}/${trunksUrl}/${selectedRow.id}`

    const getFormattedTrunkData = useCallback((data) => {
        let formatted_data = {...data}
        const project = projects.filter(p => p.id === data.project_id)[0]
        formatted_data.project_id = project ? 
        project.name : 
        data.project_id

        return formatted_data
    },[projects])

    const handleTrunkAddSubportDialogOpen = useCallback(() => {
        setTrunkAddSubportDialogOpen(true)
    },[])

    const handleTrunkAddSubportDialogClose = () => {
        setTrunkSubportData({})
        setTrunkAddSubportDialogOpen(false)
    }

    const handleTrunkRemoveSubportDialogOpen = useCallback(() => {
        setTrunkRemoveSubportDialogOpen(true)
    },[])

    const handleTrunkRemoveSubportDialogClose = () => {
        setTrunkSubportRemoveData({})
        setTrunkRemoveSubportDialogOpen(false)
    }

    const handleTrunkDetailTabChange = useCallback((navigation) => {
        let newTrunkSubmenuData = trunkSubMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
            } else {
                item.is_active = false
            }
            return item
        })
        setTrunkSubMenu(newTrunkSubmenuData)
        setCurrentTab(navigation)
    },[
        trunkSubMenu
    ])

    const handleConfirmDeleteDialogOpen = () => {
        handleDelete([selectedRow.id])
    }

    const onTrunkUpdateAdminStateOpen = useCallback(() => {
        handleAdminStateUpdate([selectedRow.id])
    },[
        handleAdminStateUpdate,
        selectedRow
    ])

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const getDataForm = (form,form_options,data,onDataChange) => {
        let form_data = [...form]
        return (
            <FormGroup>
                {form_data.map(field => {
                    let form_field_options = {}
                    return (
                        getFormFieldComponent(
                            field,
                            data,
                            onDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const getSubportDataForm = (form,form_options,data,onDataChange) => {
        let form_data = [...form]
        return (
            <FormGroup>
                {form_data.map(field => {
                    let form_field_options = {...trunkSubportOptions[field.field_key]}
                    form_field_options = {...form_field_options, ...field}
                    delete form_field_options.label
                    form_field_options["item_titles"] = defaultTexts
                    if (field.field_key === "port_id") {
                        const trunk_port_ids = trunkSubPorts.map(t => t.port_id)
                        const project_ports = ports.filter(
                            p => p.project_id === selectedRow.project_id && 
                            p.device_id === "" &&
                            !trunk_port_ids.includes(p.id)
                        )
                        const port_filter = project_ports.map(p => {
                            const subnet = subnets.filter(s => s.id === p.fixed_ips[0].subnet_id)
                            return {keyword: `${subnet[0].name}: ${p.fixed_ips[0].ip_address}`, value: p.id, default: false}
                        })
                        form_field_options["items"] = [...port_filter]
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = false
                        
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            data,
                            onDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const getSubportRemoveDataForm = (form,form_options,data,onDataChange) => {
        let form_data = [...form]
        return (
            <FormGroup>
                {form_data.map(field => {
                    let form_field_options = {...trunkSubportRemoveOptions[field.field_key]}
                    form_field_options = {...form_field_options, ...field}
                    delete form_field_options.label
                    form_field_options["item_titles"] = defaultTexts
                    if (field.field_key === "port_id") {
                        const port_filter = trunkSubPorts.map(p => {
                            const port = ports.filter(pr => pr.id === p.port_id)
                            const subnet = subnets.filter(s => s.id === port[0].fixed_ips[0].subnet_id)
                            return {
                                keyword: `${subnet[0].name}: ${port[0].fixed_ips[0].ip_address} (Vlan ${p.segmentation_id})`, 
                                value: p.port_id, 
                                default: false
                            }
                        })
                        form_field_options["items"] = [...port_filter]
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = false
                        
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            data,
                            onDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleTrunkSubportDataChange = (event,field_key) => {
        setTrunkSubportOptions({})
        let new_form_data = {...trunkSubportData}
        if (trunkSubportForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (trunkSubportForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
                new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setTrunkSubportData(new_form_data)
    }

    const handleTrunkSubportRemoveDataChange = (event,field_key) => {
        setTrunkSubportRemoveOptions({})
        let new_form_data = {...trunkSubportRemoveData}
        if (trunkSubportRemoveForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (trunkSubportRemoveForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
                new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setTrunkSubportRemoveData(new_form_data)
    }

    const handleTrunkUpdateDataChange = (event,field_key) => {
        let new_form_data = {...trunkUpdateData}
        if (trunkDataUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (trunkDataUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
                new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setTrunkUpdateData(new_form_data)
    }

    const handleUpdateTrunkDialogOpen = () => {
        setUpdateTrunkDialogOpen(true)
    }

    const handleUpdateTrunkDialogClose = () => {
        setUpdateTrunkDialogOpen(false)
    }

    const handleFormDataValidation = (form,data,options,handleOptionsChange) => {
        let validation_faild = true
        let updatedDataFormOptions = {...options}
        for (let n in form) {
            if (form[n].required && !data[form[n].field_key]) {
                validation_faild = false
                updatedDataFormOptions[form[n].field_key] = {}
                updatedDataFormOptions[form[n].field_key]["error"] = true
                updatedDataFormOptions[form[n].field_key]["errorText"] = defaultTexts[form[n].error_label]
            }
        }

        handleOptionsChange(updatedDataFormOptions)
        return validation_faild
    }

    const onTrunkAddSubport = async () => {
        let updated_data = [{...trunkSubportData}]
        const is_valid = handleFormDataValidation(trunkSubportForm, trunkSubportData, trunkSubportOptions, setTrunkSubportOptions)
        if (is_valid) {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const method = "PUT"
                const trunk_response = await openstackRequest({
                    url: `${common_url}/add_subports`, 
                    method: method, 
                    data: {sub_ports: updated_data},
                    token: project_token
                })
                if (trunk_response.status_code === trunkUrlResponses.put.success_response.status_code) {
                    setCurrentAction("")
                    handleTrunkAddSubportDialogClose()
                    handleDataFetch()
                } else {
                    const error_response = trunkUrlResponses.put.error_response.filter(
                        error_item => error_item.status_code === trunk_response.status_code)
                    if (error_response.length > 0) {
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: trunk_response.error
                        }
                        setError(errorObject)
                    } else {
                        const error_response = trunkUrlResponses.put.error_response.filter(
                            error_item => error_item.status_code === "unknown")
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: trunk_response.error
                        }
                        setError(errorObject)
                    }
                }
            }
        }
    }

    const onTrunkRemoveSubport = async () => {
        let updated_data = [{...trunkSubportRemoveData}]
        const is_valid = handleFormDataValidation(
            trunkSubportRemoveForm, 
            trunkSubportRemoveData, 
            trunkSubportRemoveOptions, 
            setTrunkSubportRemoveOptions
        )
        if (is_valid) {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const method = "PUT"
                const trunk_response = await openstackRequest({
                    url: `${common_url}/remove_subports`, 
                    method: method, 
                    data: {sub_ports: updated_data},
                    token: project_token
                })
                if (trunk_response.status_code === trunkUrlResponses.put.success_response.status_code) {
                    setCurrentAction("")
                    handleTrunkRemoveSubportDialogClose()
                    handleDataFetch()
                } else {
                    const error_response = trunkUrlResponses.put.error_response.filter(
                        error_item => error_item.status_code === trunk_response.status_code)
                    if (error_response.length > 0) {
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: trunk_response.error
                        }
                        setError(errorObject)
                    } else {
                        const error_response = trunkUrlResponses.put.error_response.filter(
                            error_item => error_item.status_code === "unknown")
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: trunk_response.error
                        }
                        setError(errorObject)
                    }
                }
            }
        }
    }

    const onTrunkUpdate = async () => {
        let updated_data = {...trunkUpdateData}
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const method = "PUT"
            const trunk_response = await openstackRequest({
                url: common_url, 
                method: method, 
                data: {trunk: updated_data},
                token: project_token
            })
            if (trunk_response.status_code === trunkUrlResponses.put.success_response.status_code) {
                setCurrentAction("")
                handleUpdateTrunkDialogClose()
                handleDataFetch()
            } else {
                const error_response = trunkUrlResponses.put.error_response.filter(
                    error_item => error_item.status_code === trunk_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: trunk_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = trunkUrlResponses.put.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: trunk_response.error
                    }
                    setError(errorObject)
                }
            }
        }
    }

    useEffect(() => {
        let trunk_actions = []
        let new_action = {}
        new_action["value"] = "update_trunk"
        new_action["action"] = handleUpdateTrunkDialogOpen
        new_action["keyword"] = "trunkUpdateActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        trunk_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "update_admin_state"
        new_action["action"] = onTrunkUpdateAdminStateOpen
        new_action["keyword"] = "trunkUpdateAdminStateActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        trunk_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "trunk_add_subport"
        new_action["action"] = handleTrunkAddSubportDialogOpen
        new_action["keyword"] = "trunkAddSubportActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        trunk_actions.push({...new_action})
        if (trunkSubPorts.length > 0) { 
            new_action = {}
            new_action["value"] = "trunk_remove_subport"
            new_action["action"] = handleTrunkRemoveSubportDialogOpen
            new_action["keyword"] = "trunkRemoveSubportActionTitle"
            new_action["button_text"] = "selectButtonTitleText"
            trunk_actions.push({...new_action})
        }
        setTrunkActions(trunk_actions)
    },[
        selectedRow,
        trunkSubPorts,
        onTrunkUpdateAdminStateOpen,
        handleTrunkAddSubportDialogOpen,
        handleTrunkRemoveSubportDialogOpen
    ])

    useEffect(() => {
        if (Object.keys(trunkUpdateData).length === 0) {
            let new_form_data = {}
            for (const n in trunkDataUpdateForm) {
                if (trunkDataUpdateForm[n].field_key === "security_groups") {
                    new_form_data[trunkDataUpdateForm[n].field_key] = [...selectedRow[trunkDataUpdateForm[n].field_key]]
                } else {
                    new_form_data[trunkDataUpdateForm[n].field_key] = selectedRow[trunkDataUpdateForm[n].field_key]
                }
            }
            setTrunkUpdateData(new_form_data)
        }
    },[trunkUpdateData, selectedRow]);

    useEffect(() => {
        if (selectedRow.sub_ports && selectedRow.sub_ports.length > 0) {
            const trunk_parent = ports.filter(p => p.id === selectedRow.port_id)
            if (trunk_parent.length > 0) {
                const sub_ports = trunk_parent[0].trunk_details.sub_ports
                setTrunkSubPorts(sub_ports)
            }
        }
    },[
        selectedRow,
        ports
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setTimeout(() => setIsCardLoading(false), 600)
    },[])

    return (
        <React.Fragment>
        {selectedRow !== null && 
        <WrapperBox>
            <ServiceContentHeader 
                service_menu={trunkSubMenu}
                service_menu_titles={defaultTexts}
                onClick={handleTrunkDetailTabChange}
            />
        </WrapperBox>}
        {currentTab === "/trunk-details" &&
            <TrunkSpecsV20
                trunkData={getFormattedTrunkData(selectedRow)}
            />
        }
        {currentTab === "/trunk-subports" && 
            <div>
                {selectedRow.sub_ports.length > 0 ?
                    <TrunkSubPortsV20
                        subports={trunkSubPorts}
                    />
                :
                    <NoDataNote text={defaultTexts.noSubPortsNoteText} />}
            </div>
        }
        {!isCardLoading && <Paper sx={{ 
            position: 'fixed', 
            bottom: 0, 
            height: FOOTER_HEIGHT,
            left: FOOTER_WIDTH, 
            right: 12,
            zIndex: 3000
            }} 
            elevation={24}
            square={true}
        >
            <Grid 
                container 
                alignItems="center"  
                justifyContent="space-between"
            >
                <Grid item>
                    <CustomSelectField 
                        items={trunkActions} 
                        currentValue={currentAction}
                        setCurrentValue={setCurrentAction}
                        item_titles={defaultTexts}
                        label={defaultTexts.actionsDropdownLabelText}
                        empty={true}
                        size="small"
                        sx={{m: 1}}
                    />
                    {currentAction.length > 0 && 
                        <Button 
                                variant="contained"
                                color="secondary"
                                sx={{m: 1, height: '70%'}}
                                onClick={trunkActions.filter(
                                    action => action.value === currentAction)[0].action
                                }
                            >
                            {defaultTexts[trunkActions.filter(
                                action => action.value === currentAction)[0].button_text]}
                        </Button>
                    }
                </Grid>
                <Grid item>
                    {selectedRow !== null && 
                        <IconButton onClick={handleConfirmDeleteDialogOpen}>
                            <DeleteIcon 
                                color="primary"
                            />
                        </IconButton>}
                </Grid>
            </Grid>
        </Paper>} 
        <CustomDialog
            open={updateTrunkDialogOpen}
            onClose={handleUpdateTrunkDialogClose}
            dialogTitle={{
                title: defaultTexts.updateTrunkActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onTrunkUpdate, 
                sx: {color: 'primary.main'}}]}
        >
            {getDataForm(
                trunkDataUpdateForm,
                {},
                trunkUpdateData,
                handleTrunkUpdateDataChange
            )}
        </CustomDialog>
        <CustomDialog
            open={trunkAddSubportDialogOpen}
            onClose={handleTrunkAddSubportDialogClose}
            dialogTitle={{
                title: defaultTexts.trunkAddSubportActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onTrunkAddSubport, 
                sx: {color: 'primary.main'}}]}
        >
            {getSubportDataForm(
                trunkSubportForm,
                {},
                trunkSubportData,
                handleTrunkSubportDataChange
            )}
        </CustomDialog>
        <CustomDialog
            open={trunkRemoveSubportDialogOpen}
            onClose={handleTrunkRemoveSubportDialogClose}
            dialogTitle={{
                title: defaultTexts.trunkRemoveSubportActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onTrunkRemoveSubport, 
                sx: {color: 'primary.main'}}]}
        >
            {getSubportRemoveDataForm(
                trunkSubportRemoveForm,
                {},
                trunkSubportRemoveData,
                handleTrunkSubportRemoveDataChange
            )}
        </CustomDialog>
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts.failedActionErrorDialogTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts.failedActionErrorDialogMessage}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
        </React.Fragment>
    )
};

export default TrunkDetailV20;