import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import CustomDialog from '../../../../_common/CustomDialog';
import useWindowDimensions from '../../../../_common/WindowDimensions';
import Dimensions from '../../../../../config/dimensions';
import CustomSideDrawer from '../../../../_common/CustomSideDrawer';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import { openStackServices, 
        blockStorageCinderConstants,
        identityKeystonConstants 
} from '../../../../../config/openStackConstants';
import GroupTypesSubheaderV3 from './groupTypesSubheaderV3';
import GroupTypesTableV3 from './groupTypesTableV3';
import { groupTypeFilterMenu } 
from '../../../../../_data/openstack/cinder/group_types/v3';
import { 
    volumeCinderRequest, 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../_network/openstack_request';
import { groupTypesUrl as groupTypesUrlResponses } 
from '../../../../../_api_responses/openstack/cinder/group_types/v3';
import GroupTypeDetailV3 from './groupTypeDetailV3';
import { usersUrl as usersUrlResponses } from 
'../../../../../_api_responses/openstack/identity/users/v3';
import Box from '@mui/material/Box';

const SERVICE_NAME = openStackServices.volumeService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const getWidthWeight = (width) => {
    if (width < Dimensions.tablet_mini.width) {
        return 0.9
    } else if (width < Dimensions.tablet.width) {
        return 0.6
    } else {
        return 0.4
    }
}

const GroupTypesWrapperV3 = (props) => {
    const { navigate, location } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [currentTab, setCurrentTab] = useState("group_types")
    const [groupTypesData, setGroupTypesData] = useState([])
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = getWidthWeight(width)
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [currentAction, setCurrentAction] = useState("");
    const [groupTypeDeleteConfirmDialogOpen, setGroupTypeDeleteConfirmDialogOpen] = useState(false);
    const [selectedGroupTypes, setSelectedGroupTypes] = useState([])
    const [groupTypesSortParams, setGroupTypesSortParams] = useState("")
    const [groupTypeFilterQueryParams, setGroupTypeFilterQueryParams] = useState("")
    const [selectedGroupTypeFilter, setSelectedGroupTypeFilter] = useState(groupTypeFilterMenu[0].value)
    const [selectedGroupTypeFilterValue, setSelectedGroupTypeFilterValue] = useState("")
    const [projects, setProjects] = useState([])

    const cinderServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const cinderServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const groupTypesUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.groupTypesUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    
    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleTabChange = (tab_name) => {
        setCurrentTab(tab_name)
    }

    const handleGroupTypeFilteredSearch = () => {
        if (selectedGroupTypeFilter && selectedGroupTypeFilterValue) {
            setGroupTypeFilterQueryParams(`?${selectedGroupTypeFilter}=${selectedGroupTypeFilterValue}`)
        } else {
            setGroupTypeFilterQueryParams("")
        }
        handleDataFetch()
    }

    const handleGroupTypeFilterReset = () => {
        setSelectedGroupTypeFilter(groupTypeFilterMenu[0].value)
        setSelectedGroupTypeFilterValue("")
        setGroupTypeFilterQueryParams("")
        handleDataFetch()
    }

    const handleDetailCardOpen = useCallback((index) => {
        setSelectedRow(groupTypesData[index])
        setTimeout(() => setDetailCardOpen(true),100)
    },[groupTypesData]);

    const handleDetailCardClose = () => {
        setTimeout(() => setDetailCardOpen(false),100)
        setSelectedRow(null)
        navigate(location.path,{})
    };

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleGroupTypeSorting = (field,direction) => {
        const sort_param = `&&sort=${field}:${direction}`
        setGroupTypesSortParams(sort_param)
        handleDataFetch()
    }

    const handleGroupTypeDelete = async (vt_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupTypesUrl}/${vt_id}`
            const method = "DELETE"
            
            const vt_response = await volumeCinderRequest({
                url:url, 
                method:method,
                token: project_token
            })
            if (vt_response.status_code === groupTypesUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return vt_response.error
            }
        }
    };

    const onGroupTypeDelete = async () => {
        handleGroupTypeDeleteConfirmDialogClose()
        setIsLoading(true)
        let err = []
        for (let v in selectedGroupTypes) {
            const resp = await handleGroupTypeDelete(selectedGroupTypes[v].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDetailCardClose()
        handleDataFetch()
        setIsLoading(false)
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onGroupTypeDeleteConfirm = (vt_list) => {
        const selected_vt_list = groupTypesData.filter(vt => 
            vt_list.includes(vt.id))
        setSelectedGroupTypes([...selected_vt_list])
        setGroupTypeDeleteConfirmDialogOpen(true)
    }

    const handleGroupTypeDeleteConfirmDialogClose = () => {
        setGroupTypeDeleteConfirmDialogOpen(false)
    }

    const getGroupTypesActionsList = () => {
        let group_types_actions = []
        let new_action = {}
        new_action["value"] = "group_type_delete"
        new_action["action"] = onGroupTypeDeleteConfirm
        new_action["keyword"] = "groupTypeDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        group_types_actions.push({...new_action})
        
        return group_types_actions
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let all_group_types = []
                    let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupTypesUrl}${groupTypeFilterQueryParams}${groupTypesSortParams}`
                    const method = "GET"
        
                    const group_types_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                    if (group_types_response.status_code === groupTypesUrlResponses.get.success_response.status_code) {
                        all_group_types = [...all_group_types, ...group_types_response.data.group_types]
                    }
                    if (groupTypeFilterQueryParams.length === 0) {
                        url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupTypesUrl}?is_public=false`
                        const response = await volumeCinderRequest({url:url, method:method, token: project_token})
                        if (response.status_code === groupTypesUrlResponses.get.success_response.status_code) {
                            const private_vt = response.data.group_types.filter(vt => !all_group_types.map(i => i.id).includes(vt.id))
                            all_group_types = [...private_vt, ...all_group_types]
                        }
                    }
                    setGroupTypesData([...all_group_types])
                    if (selectedRow) {
                        let new_data = all_group_types.filter(vt => vt.id === selectedRow.id)
                        if (new_data.length > 0) {
                            setSelectedRow(new_data[0])
                        }
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        cinderServiceDomain, 
        cinderServiceVersion, 
        groupTypesUrl, 
        groupTypeFilterQueryParams,
        dataFetchingRequired,
        selectedRow,
        defaultAdminProject,
        groupTypesSortParams
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === usersUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        selectedRow,
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        if (!dataFetchingRequired && location.state ) {
            const group_type = location.state ? location.state.group_type : null
                const group_type_index = groupTypesData.findIndex(gt => gt.id === group_type);
                if (group_type_index !== -1) {
                    setTimeout(() => handleDetailCardOpen(group_type_index), 600)
                }
        }
    },[
        dataFetchingRequired,
        groupTypesData,
        handleDetailCardOpen,
        location
    ])

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
        {currentTab === "group_types" && 
            <GroupTypesSubheaderV3 
                selectedFilter={selectedGroupTypeFilter} 
                setSelectedFilter={setSelectedGroupTypeFilter}
                selectedFilterValue={selectedGroupTypeFilterValue}
                setSelectedFilterValue={setSelectedGroupTypeFilterValue}
                handleDataFetch={handleDataFetch}
                filterMenu={groupTypeFilterMenu}
                handleFilteredSearch={handleGroupTypeFilteredSearch}
                handleFilterReset={handleGroupTypeFilterReset}
                currentTab={currentTab}
                handleTabChange={handleTabChange}
            />
        }
        {isLoading && <CustomBackdrop open={isLoading} />}
        {!isLoading && currentTab === "group_types" &&
            <GroupTypesTableV3 
                groupTypesData={groupTypesData}
                setGroupTypesData={setGroupTypesData}
                handleRowSelection={handleDetailCardOpen}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getGroupTypesActionsList()}
                sortHandler={handleGroupTypeSorting}
            />
        }
        {selectedRow !== null && currentTab === "group_types" && 
            <CustomSideDrawer 
                open={detailCardOpen}
                widthWeight={WIDTH_WEIGHT}
                handleDrawerOpen={handleDetailCardOpen}
                handleDrawerClose={handleDetailCardClose}
            > 
                <GroupTypeDetailV3
                    selectedRow={selectedRow}
                    widthWeight={WIDTH_WEIGHT}
                    handleFetchData={handleDataFetch}
                    handleGroupTypeDelete={onGroupTypeDeleteConfirm}
                    projects={projects}
                />        
            </CustomSideDrawer>
        }
        <CustomDialog
            open={groupTypeDeleteConfirmDialogOpen}
            onClose={handleGroupTypeDeleteConfirmDialogClose}
            dialogTitle={{
                title: defaultTexts.groupTypeDeleteConfirmTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `${defaultTexts.groupTypeDeleteConfirmText}: [${selectedGroupTypes.map(v => v.name).toString()}]`, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onGroupTypeDelete, 
                sx: {color: 'primary.main'}}]}
        />
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts[error.error_title], 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts[error.error_message]}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
    </Box>
    )
};

export default GroupTypesWrapperV3;