export const filterMenu = [
    {
        keyword: "statusFormFieldLabel", 
        value: "account_status", 
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "activeStatusChartLegent", 
                value: "active", 
                default: false
            },
            {
                keyword: "inactiveStatusChartLegent", 
                value: "inactive", 
                default: false
            },
            {
                keyword: "suspendedStatusChartLegent", 
                value: "suspended", 
                default: false
            },
            {
                keyword: "deletedStatusChartLegent", 
                value: "deleted", 
                default: false
            }
        ]
    },
    {
        keyword: "billingTypeFormFieldLabel", 
        value: "billing_type", 
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "prepaidDepositTypeText", 
                value: "prepaid", 
                default: false
            },
            {
                keyword: "postpaidDepositTypeText", 
                value: "postpaid", 
                default: false
            }
        ]
    },
    {
        keyword: "invoiceTypeFilterMenuChoiceText", 
        value: "postpaid_invoice", 
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "autoInvoiceTypeText", 
                value: "auto", 
                default: false
            },
            {
                keyword: "manualInvoiceTypeText", 
                value: "manual", 
                default: false
            }
        ]
    },
    {
        keyword: "projectFormFieldLabel", 
        value: "project_id", 
        type: "select", 
        multiple: false,
        items: []
    },
]