import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Constants from '../../../../../config/constants';
import AddButton from '../../../../_common/AddButton';
import FilterButton from '../../../../_common/FilterButton';
import WrapperBox from '../../../../_common/WrapperBox';
import { FormGroup } from '@mui/material';
import { projectDataForm } from 
'../../../../../_data/openstack/identity/projects/v3';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { identityKeystonConstants } from 
'../../../../../config/openStackConstants';
import { openstackRequest } from 
'../../../../../_network/openstack_request';
import { projectsUrl as projectsUrlResponses } from 
'../../../../../_api_responses/openstack/identity/projects/v3';
import CustomDialog from '../../../../_common/CustomDialog';
import { handleEmptyFields } from '../../../helpers/v3/identity_helpers'

const SERVICE_NAME = openStackServices.identityService

const IdentityKeystoneProjectsSubheaderV3 = (props) => {
    const [error, setError] = useState()
    const [successProjectAdd, setSuccessProjectAdd] = useState()
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const { domains } = props
    const { domainProjects } = props
    const { selectedFilter, setSelectedFilter } = props
    const { selectedFilterValue, setSelectedFilterValue } = props
    const { handleFetchData } = props
    const { filterMenu } = props
    const { handleFilteredSearch, handleFilterReset } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [formData, setFormData] = useState({})
    const [formDataOptions, setFormDataOptions] = useState({});
    
    const identityServiceDomain = useSelector(state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)

    const handleFormDataValidation = () => {
        let validation_faild = true
        let updatedDataFormOptions = {...formDataOptions}
        for (let n in projectDataForm) {
            if (projectDataForm[n].required && !formData[projectDataForm[n].field_key]) {
                validation_faild = false
                updatedDataFormOptions[projectDataForm[n].field_key] = {}
                updatedDataFormOptions[projectDataForm[n].field_key]["error"] = true
                updatedDataFormOptions[projectDataForm[n].field_key]["errorText"] = defaultTexts[projectDataForm[n].error_label]
            }
        }
        setFormDataOptions(updatedDataFormOptions)
        return validation_faild
    }

    const onAddProject = async () => {
        const validateFormData = handleFormDataValidation()
        if (validateFormData) {
            let normalized_data = {...formData}
            
            if (Object.keys(normalized_data).includes("tags") && normalized_data.tags.length > 0 && typeof(normalized_data.tags) === "string") {
                const normalized_tags = formData.tags.trim().split(",").filter(
                    item => item.trim().length > 0).map(item => item.trim())
                    normalized_data.tags = normalized_tags
            } else {
                normalized_data.tags = []
            }
            const request_data = handleEmptyFields(projectDataForm, normalized_data)
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "POST"
            
            const project_response = await openstackRequest({url:url, method:method, data: {project: request_data}})
            if (project_response.status_code === projectsUrlResponses.post.success_response.status_code) {
                handleAddProjectFormReset()
                handleFetchData()
                setSuccessProjectAdd({
                    success_title: projectsUrlResponses.post.success_response.response_title, 
                    success_message: projectsUrlResponses.post.success_response.response_message
                })
            } else {
                const error_response = projectsUrlResponses.post.error_response.filter(
                    error_item => error_item.status_code === project_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: project_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = projectsUrlResponses.post.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: project_response.error
                    }
                    setError(errorObject)
                }
            }
        }

        return validateFormData
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleSuccessDialogClose = () => {
        setSuccessProjectAdd(null);
        setSuccessDialogOpen(false);
    }

    const handleAddProjectFormReset = () => {
        setFormDataOptions({})
        let new_form_data = {}
        for (const n in projectDataForm) {
            if (
                projectDataForm[n].field_type === "string" || 
                projectDataForm[n].field_type === "select" ||
                projectDataForm[n].field_type === "list"
                ) {
                new_form_data[projectDataForm[n].field_key] = ""
            } else if (projectDataForm[n].field_type === "bool") {
                new_form_data[projectDataForm[n].field_key] = projectDataForm[n].default_value ? 
                projectDataForm[n].default_value : 
                false
            }
        }
        setFormData(new_form_data)
    }

    const handleFormDataChange = (event,field_key) => {
        let new_form_data = {...formData}
        if (projectDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (projectDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setFormData(new_form_data)
    }

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessDialogOpen(true)
    },[successProjectAdd]);

    useEffect(() => {
        if (Object.keys(formData).length === 0) {
            let new_form_data = {}
            for (const n in projectDataForm) {
                if (
                    projectDataForm[n].field_type === "string" || 
                    projectDataForm[n].field_type === "select" ||
                    projectDataForm[n].field_type === "list"
                    ) {
                    new_form_data[projectDataForm[n].field_key] = ""
                } else if (projectDataForm[n].field_type === "bool") {
                    new_form_data[projectDataForm[n].field_key] = projectDataForm[n].default_value ? 
                    projectDataForm[n].default_value : 
                    false
                }
            }
            setFormData(new_form_data)
        }
    },[formData]);

    const getDataForm = () => {
        return (
            <FormGroup>
                {projectDataForm.map(field => {
                    let form_field_options = {...formDataOptions[field.field_key]}
                    const disabled = formData.is_domain
                    if (field.field_key === "domain_id") {
                        const domains_filter = domains.map(domain => {
                            return {keyword: domain.name, value: domain.id, default: false}
                        })
                        form_field_options["items"] = [...domains_filter]
                        form_field_options["empty"] = field.default_empty
                        form_field_options["self_item_titles"] = field.self_item_titles
                        form_field_options["disabled"] = disabled
                    } else if (field.field_key === "parent_id") {
                        const parent_filter = domainProjects.map(project => {
                            return {keyword: project.name, value: project.id, default: false}
                        })
                        
                        form_field_options["items"] = [...parent_filter]
                        form_field_options["empty"] = field.default_empty
                        form_field_options["self_item_titles"] = field.self_item_titles
                        form_field_options["disabled"] = disabled
                        
                    } else if (field.field_key === "is_domain") {
                        if (formData.domain_id || formData.parent_id) {
                            form_field_options["disabled"] = true
                        } else {
                            form_field_options["disabled"] = false
                        }
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            formData,
                            handleFormDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    return (
        <WrapperBox 
            sx={{
                flexDirection: "row", 
                justifyContent: 'space-between',
                mt: 1
            }}>
            <AddButton 
                getDataForm={getDataForm}               
                onSubmit={onAddProject}
                formReset={handleAddProjectFormReset}
                customTexts={{
                    title: defaultTexts.addProjectDialogTitle,
                    description: defaultTexts.addProjectDialogDescriptionText
                }}
            />
            <FilterButton 
                choice_mode={Constants.simple_filter_type}
                currentFilter={selectedFilter}
                setCurrentFilter={setSelectedFilter}
                filter_menu_titles={defaultTexts}
                filter_menu={filterMenu}
                currentFilterValue={selectedFilterValue}
                setCurrentFilterValue={setSelectedFilterValue}
                onFilterSubmit={handleFilteredSearch}
                onFilterReset={handleFilterReset}
            />
            {successProjectAdd && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[successProjectAdd.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successProjectAdd.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>Details:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </WrapperBox>
    )
};

export default IdentityKeystoneProjectsSubheaderV3;