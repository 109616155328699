import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import NoDataNote from '../../../../_common/NoDataNote';
import CustomText from '../../../../_common/CustomText';
import WrapperBox from '../../../../_common/WrapperBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOffIcon from '@mui/icons-material/EditOff';
import Paper from '@mui/material/Paper';
import useWindowDimensions from 
'../../../../_common/WindowDimensions';
import Constants from '../../../../../config/constants';
import { IconButton }  from '@mui/material';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { 
    volumeCinderRequest, 
    getXAuthTokenProjectScope
 } from 
'../../../../../_network/openstack_request';
import { snapshotsUrl as snapshotUrlResponses } from 
'../../../../../_api_responses/openstack/cinder/snapshots/v3';
import { 
    openStackServices, 
    blockStorageCinderConstants
} from 
'../../../../../config/openStackConstants';
import CustomDialog from 
'../../../../_common/CustomDialog';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import { FormGroup } from '@mui/material';
import { 
    snapshotUpdateDataForm, 
    snapshotDataSchema
} from '../../../../../_data/openstack/cinder/snapshots/v3';
import { getDetailDataComponent } from 
'../../../../_common/common_helpers';
import ServiceContentHeader from '../../../../_common/ServiceContentHeader';
import { Divider } from '@mui/material';
import CustomSelectField from 
'../../../../_common/_form_fields/CustomSelectField';
import PlusButton from '../../../../_common/PlusButton';
import MinusButton from '../../../../_common/MinusButton';
import CustomTextField from '../../../../_common/_form_fields/CustomTextField';
import CloseIcon from '@mui/icons-material/Close';
import { handleProjectScopeRoleAssignment } 
from '../../../../identity_keystone/helpers/rolesHelpers';

const SERVICE_NAME = openStackServices.volumeService
const FOOTER_HEIGHT = Constants.actions_bar_height + 20
const ALLOWED_ROLE = "admin"

const SnapshotDetailV3 = (props) => {
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const user = useSelector(state => state.profile.userID)
    const { selectedRow, selectedSnapshot } = props
    const AdminProjectId = useSelector(state => state.profile.defaultAdminProject.id)
    const adminProjects = useSelector(state => state.profile.adminProjects)
    const defaultAdminProject = adminProjects.filter(p => p.id === selectedRow[blockStorageCinderConstants.snapshotProjectIdField]).length > 0 ? 
    adminProjects.filter(p => p.id === selectedRow[blockStorageCinderConstants.snapshotProjectIdField])[0].id : AdminProjectId
    const { widthWeight } = props
    const { width } = useWindowDimensions();
    const FOOTER_WIDTH = width - (width * widthWeight)
    const { 
        handleFetchData,
        handleNavigateToVolume,
        handleNavigateToBackup,
        handleStatusUpdate,
        handleDelete
    } = props
    
    const [editMode, setEditMode] = useState(false);
    const [updateMetadataMode, setUpdateMetadataMode] = useState(false)
    const [snapshotBackups, setSnapshotBackups] = useState([])
    
    const [error, setError] = useState()
    const [successSnapshotUpdate, setSuccessSnapshotUpdate] = useState()
    const [successUpdateDialogOpen, setSuccessUpdateDialogOpen] = useState(false);
    
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [createBackupDialogOpen, setCreateBackupDialogOpen] = useState(false)
    const [backupData, setBackupData] = useState({})
    const [warningDialogOpen, setWarningDialogOpen] = useState(false)
    const [warning, setWarning] = useState("")
    const [warningMessage, setWarningMessage] = useState("")

    const [updateFormData, setUpdateFormData] = useState({})
    const [snapshotActions, setSnapshotActions] = useState([]);
    const [currentAction, setCurrentAction] = useState("");
    const [formMetadata, setFormMetadata] = useState([]);
    const [snapshotSubMenu, setSnapshotSubMenu] = useState([
        {keyword: "submenuDetails", navigation: "/sn-details", is_active: true},
        {keyword: "submenuMetadata", navigation: "/sn-metadata", is_active: false},
        {keyword: "submenuBackups", navigation: "/sn-backups", is_active: false}
    ])
    
    const [currentTab, setCurrentTab] = useState("/sn-details")

    const cinderServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const cinderServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const snapshotsUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.snapshotsUrl)[0].url)
    const backupsUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.backupsUrl)[0].url)
    
    const handleFormDataChange = (event,field_key) => {
        let new_form_data = {...updateFormData}
        if (snapshotUpdateDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (snapshotUpdateDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setUpdateFormData(new_form_data)
    }

    const handleCreateBackupDialogClose = () => {
        setBackupData({})
        setCreateBackupDialogOpen(false)
    }

    const handleBackupDataChange = (event,field_key) => {
        let new_form_data = {...backupData}
        if (snapshotUpdateDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else {
            new_form_data[field_key] = event.target.value
        }
        setBackupData(new_form_data)
    }

    const handleCreateBackupDialogOpen = useCallback(() => {
        setCreateBackupDialogOpen(true)
    },[])

    const handleRoleAssignment = useCallback(async () => {
        const project_id = selectedRow[blockStorageCinderConstants.volumeProjectIdField]
        const response = await handleProjectScopeRoleAssignment(project_id,user,ALLOWED_ROLE)
        if (response) {
            setWarningDialogOpen(false)
        }
    },[
        selectedRow,
        user
    ])

    const handleSnapshotWarningMessageOpen = useCallback(() => {
        const warning_message = `<span>${defaultTexts.nonAllowedActionExecutionWarning}: <strong>${selectedRow[blockStorageCinderConstants.snapshotProjectIdField]}</strong></span>
                                    <br>
                                    <br> 
                                <span>${defaultTexts.notRequiredRole}: <strong>${ALLOWED_ROLE}</strong></span>`
       
        const warning = <Box 
            onClick={handleRoleAssignment} 
            sx={{
                cursor: "pointer",
                color: "info.main",
                textAlign: "start"
            }}
            >
            {`${defaultTexts.getAdminRoleToGivenProject}: ${ALLOWED_ROLE} -> ${selectedRow[blockStorageCinderConstants.snapshotProjectIdField]}`}
            </Box>
        setWarningMessage(warning_message)
        setWarning(warning)
        setWarningDialogOpen(true)
    },[
        defaultTexts,
        handleRoleAssignment,
        selectedRow
    ])

    const handleWarningDialogClose = () => {
        setWarningDialogOpen(false)
    }

    const getBackupDataForm = (form,form_options,data,onDataChange) => {
        return (
            <FormGroup>
                {form.map(field => {
                    return (
                        getFormFieldComponent(
                            field,
                            data,
                            onDataChange,
                            defaultTexts[field.label],
                            {...form_options[field.field_key]}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const getDataForm = () => {
        return (
            <FormGroup>
                {snapshotUpdateDataForm.map(field => {
                    let form_field_options = {}
                return (
                    getFormFieldComponent(
                        field,
                        updateFormData,
                        handleFormDataChange,
                        defaultTexts[field.label],
                        {
                            size:"medium",
                            sx: {
                                my: 1, 
                                width: width * widthWeight * 0.8
                            },
                        ...form_field_options
                        }
                    )
                )
            })}
        </FormGroup>)
    }

    const handleEditModeChange = () => {
        handleSnapshotDetailTabChange("/sn-details")
        setEditMode(true)
    }

    const handleEditModeReset = () => {
        setUpdateFormData({...selectedRow})
        handleSnapshotDetailTabChange("/sn-details")
        setEditMode(false)
    }
    
    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }
    
    const handleSuccessUpdateDialogClose = () => {
        setSuccessSnapshotUpdate(null)
        setUpdateFormData({...selectedRow})
        setSuccessUpdateDialogOpen(false);
    }
    const handleConfirmDeleteDialogOpen = () => {
        handleDelete([selectedRow.id])
        setCurrentAction("")
    }

    const onBackupCreate = async () => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const method = "POST"
            const backup_response = await volumeCinderRequest({
                url: `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${backupsUrl}`, 
                method: method, 
                data: {backup: {
                    ...backupData,
                    volume_id: selectedRow.volume_id,
                    snapshot_id: selectedRow.id,
                    force: true
                }},
                token: project_token
            })
            if (backup_response.status_code === snapshotUrlResponses.post_async.success_response.status_code) {
                setCurrentAction("")
                handleCreateBackupDialogClose()
                handleFetchData()
            } else {
                setError(backup_response.error)
            }
        }
    }

    const handleEditSnapshot = async () => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${snapshotsUrl}/${selectedRow.id}`
            const method = "PUT"

            const sn_response = await volumeCinderRequest({
                url: url, 
                method: method, 
                data: {snapshot: updateFormData},
                token: project_token
            })
            if (sn_response.status_code === snapshotUrlResponses.put.success_response.status_code) {
                handleFetchData()
                handleEditModeReset()
                setSuccessSnapshotUpdate({
                    success_title: snapshotUrlResponses.put.success_response.response_title, 
                    success_message: snapshotUrlResponses.put.success_response.response_message
                })
            } else {
                const error_response = snapshotUrlResponses.put.error_response.filter(
                    error_item => error_item.status_code === sn_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: sn_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = snapshotUrlResponses.put.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: sn_response.error
                    }
                    setError(errorObject)
                }
            }
        }
    }

    const formatMetadata = () => {
        let new_data = {}
        for (let i in formMetadata) {
            new_data[formMetadata[i].field_key] = formMetadata[i].field_value
        }
        const keys_to_delete = Object.keys(selectedRow.metadata).filter(key => !Object.keys(new_data).includes(key))
        return {keys_to_add: new_data, keys_to_delete: keys_to_delete}
    }

    const handleUpdateMetadata = async () => {
        const data = formatMetadata()
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${snapshotsUrl}/${selectedRow.id}/metadata`
            let method = "POST"

            const response = await volumeCinderRequest({
                url: url, 
                method: method, 
                data: {metadata: data.keys_to_add},
                token: project_token
            })
            for (let d in data.keys_to_delete) {
                url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${snapshotsUrl}/${selectedRow.id}/metadata/${data.keys_to_delete[d]}`
                method = "DELETE"

                await volumeCinderRequest({
                    url: url, 
                    method: method, 
                    token: project_token
                })
            }
            if (response.status_code === snapshotUrlResponses.post.success_response.status_code) {
                setCurrentAction("")
                handleFetchData()
                handleUpdateMetadataModeReset()
            }
        }
    }

    const handleSnapshotDetailTabChange = useCallback((navigation) => {
        let newVTSubmenuData = snapshotSubMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
            } else {
                item.is_active = false
            }
            return item
        })
        setSnapshotSubMenu(newVTSubmenuData)
        setCurrentTab(navigation)
    },[
        snapshotSubMenu,
        setSnapshotSubMenu
    ])

    const handleUpdateMetadataModeChange = useCallback(() => {
        handleSnapshotDetailTabChange("/sn-metadata")
        setUpdateMetadataMode(true)
    },[handleSnapshotDetailTabChange])

    const handleSnapshotStatusUpdate = useCallback(() => {
        handleStatusUpdate([selectedRow.id])
        setCurrentAction("")
    },[
        handleStatusUpdate,
        selectedRow
    ])


    const handleMetadataChange = (event,field_key_list) => {
        let new_extra_data = [...formMetadata]
        if (field_key_list[1] === "key") {
            new_extra_data[field_key_list[0]].field_key = event.target.value
        } else {
            new_extra_data[field_key_list[0]].field_value = event.target.value
        }
        setFormMetadata(new_extra_data)
    }

    const handleMetadataRemove = (index) => {
        if (formMetadata.length > 1) {
            let updated_data = [...formMetadata]
            updated_data.splice(index, 1)
            setFormMetadata(updated_data)
        } else {
            setFormMetadata([])
        }
    }

    const handleUpdateMetadataModeReset = () => {
        if (Object.keys(selectedRow.metadata).length > 0) {
            const metadata_form = Object.keys(selectedRow.metadata).map(key => {
                let new_item = {}
                new_item["field_key"] = key
                new_item["field_value"] = selectedRow.metadata[key]
                return new_item
            })
            setFormMetadata(metadata_form)
        }
        setUpdateMetadataMode(false)
    }

    const getMetadataFormComponents = () => {
        return (
            <WrapperBox
                sx={{
                    m: 2, 
                    alignItems: 'start'
                }}
            >
                <IconButton
                    onClick={handleUpdateMetadataModeReset}
                    sx={{
                    position: 'absolute',
                    right: 8,
                    top: 130,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Stack 
                    direction="row" 
                    spacing={2} 
                    alignItems="center"
                    sx={{my: 3}}
                >
                    <CustomText size="h6">
                        {defaultTexts.metadataFormFieldLabel}
                    </CustomText>
                    <PlusButton 
                        onClick={() => setFormMetadata([
                            ...formMetadata,
                            {field_key: "",field_value: ""}
                        ])} 
                    />
                </Stack>
                {formMetadata.map((item,index) => {
                    return (
                        <Stack
                            key={index}
                            direction="row" 
                            spacing={2} 
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{my: 1}}
                        >
                            <CustomTextField
                                currentValue={item.field_key} 
                                setCurrentValue={handleMetadataChange}
                                field_key={[index,"key"]}
                                label={defaultTexts.keyFormFieldLabel}
                            />
                            <CustomTextField
                                currentValue={item.field_value} 
                                setCurrentValue={handleMetadataChange}
                                field_key={[index,"value"]}
                                label={defaultTexts.valueFormFieldLabel}
                            />
                            <MinusButton 
                                sx={{width: 90}} 
                                onClick={() => handleMetadataRemove(index)}
                            />
                        </Stack>
                    )
                })}
                <Button 
                    variant="contained"
                    color="secondary"
                    sx={{mt: 5}}
                    onClick={handleUpdateMetadata}
                >
                    {defaultTexts.saveButtonText}
                </Button>
            </WrapperBox>
        )
    }

    useEffect(() => {
        if (Object.keys(selectedRow.metadata).length > 0) {
            const metadata_form = Object.keys(selectedRow.metadata).map(key => {
                let new_item = {}
                new_item["field_key"] = key
                new_item["field_value"] = selectedRow.metadata[key]
                return new_item
            })
            setFormMetadata(metadata_form)
        }
    },[
        selectedRow
    ]);

    useEffect(() => {
        if (Object.keys(updateFormData).length === 0) {
            let new_form_data = {}
            for (const n in snapshotUpdateDataForm) {
                new_form_data[snapshotUpdateDataForm[n].field_key] = selectedRow[snapshotUpdateDataForm[n].field_key]
            }
            setUpdateFormData(new_form_data)
        }
    },[updateFormData, selectedRow]);


    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessUpdateDialogOpen(true)
    },[successSnapshotUpdate]);

    useEffect(() => {
        const scoped_actions_allowed = adminProjects.map(
            p => p.id).includes(
                selectedRow[blockStorageCinderConstants.snapshotProjectIdField])
        if (selectedRow !== null) {
            let sn_actions = []
            let new_action = {}
            new_action["value"] = "volume_status_update"
            new_action["action"] = handleSnapshotStatusUpdate
            new_action["keyword"] = "snapshotStatusUpdateActionTitle"
            new_action["button_text"] = "selectButtonTitleText"
            sn_actions.push({...new_action})
            new_action = {}
            new_action["value"] = "update_metadata"
            new_action["action"] = handleUpdateMetadataModeChange
            new_action["keyword"] = "createUpdateMetadataActionTitle"
            new_action["button_text"] = "selectButtonTitleText"
            sn_actions.push({...new_action})
            if (selectedRow.status === "available") {
                new_action = {}
                new_action["value"] = "create_backup"
                new_action["keyword"] = "createBackupActionTitle"
                new_action["button_text"] = "selectButtonTitleText"
                if (scoped_actions_allowed) {
                    new_action["action"] = handleCreateBackupDialogOpen
                } else {
                    new_action["action"] = handleSnapshotWarningMessageOpen
                }
                sn_actions.push({...new_action})
            }
           
            setSnapshotActions(sn_actions)
        }
    },[
        adminProjects,
        selectedRow,
        defaultTexts,
        handleStatusUpdate,
        handleSnapshotStatusUpdate,
        handleUpdateMetadataModeChange,
        handleSnapshotWarningMessageOpen,
        handleCreateBackupDialogOpen
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${backupsUrl}/detail?all_tenants=true`
                const method = "GET"
    
                const backups_response = await volumeCinderRequest({
                    url:url, 
                    method:method, 
                    token: project_token
                })
                if (backups_response.status_code === snapshotUrlResponses.get.success_response.status_code) {
                    const snapshot_backups = backups_response.data.backups.filter(item => item.snapshot_id === selectedRow.id)
                    setSnapshotBackups(snapshot_backups)
                }
            }
        })();
    },[
        cinderServiceDomain, 
        cinderServiceVersion,
        defaultAdminProject,
        backupsUrl,
        selectedRow
    ]);

    return (
        <React.Fragment>
        {selectedRow !== null && 
            <WrapperBox>
                <ServiceContentHeader 
                    service_menu={snapshotSubMenu}
                    service_menu_titles={defaultTexts}
                    onClick={handleSnapshotDetailTabChange}
                />
            </WrapperBox>}
            {currentTab === "/sn-details" &&
                <React.Fragment>
                    {!editMode ? 
                    <Stack 
                        spacing={2} 
                        sx={{ p: 2, mt: 1, flexWrap: "wrap"}}
                    >
                        {snapshotDataSchema.map((field) => {
                            let value = selectedSnapshot ? 
                            selectedSnapshot[field.field_key] : ""
                            if (field.field_key === "volume_id") {
                                value = <Box 
                                        sx={{cursor: "pointer", color: "primary.main"}}
                                        onClick={() => 
                                            handleNavigateToVolume(selectedRow.volume_id,"/volumes")
                                        }
                                    >
                                    {selectedSnapshot[field.field_key]}
                                </Box>
                            }
                            return (
                                getDetailDataComponent({
                                    fieldType: field.field_type,
                                    fieldKey: field.field_key,
                                    label: defaultTexts[field.label],
                                    value: value,
                                    textOnTrue: defaultTexts[field.value_on_true] ? 
                                    defaultTexts[field.value_on_true] : 
                                    defaultTexts.formValueYes,
                                    textOnFalse: defaultTexts[field.value_on_false] ? 
                                    defaultTexts[field.value_on_false] : 
                                    defaultTexts.formValueNo,
                                    defaultTexts: defaultTexts
                                })
                            )
                        })}
                        </Stack> : 
                        <Stack 
                            sx={{
                                m: 2, 
                                alignItems: 'start'
                            }}>
                        <CustomText 
                            size="h6" 
                            sx={{
                                color: "primary.main", 
                                mb: 2
                            }}>
                            {defaultTexts.updateSnapshotFormTitle}
                        </CustomText>
                        {getDataForm()}
                        <Button 
                            variant="contained"
                            color="secondary"
                            sx={{mt: 5}}
                            onClick={handleEditSnapshot}
                        >
                            {defaultTexts.saveButtonText}
                        </Button>
                    </Stack>}
                </React.Fragment>}
                {currentTab === "/sn-metadata" && 
                !updateMetadataMode &&  
                    <div>
                        {Object.keys(selectedRow.metadata).length > 0 ? 
                            <Stack 
                                spacing={2} 
                                sx={{ p: 2, mt: 1}}
                            >
                                {Object.keys(selectedRow.metadata).map(key => {
                                    return (
                                        <Stack key={key} spacing={1} >
                                            <Stack 
                                                direction="row" 
                                                justifyContent="space-between" 
                                                alignItems="center"
                                            >
                                                <CustomText>{key}</CustomText>
                                                <CustomText>{selectedRow.metadata[key]}</CustomText>
                                            </Stack>
                                            <Divider />
                                        </Stack>
                                    )
                                    
                                })}
                            </Stack> : 
                            <NoDataNote text={defaultTexts.noMetadataNoteText} />}
                    </div>
                }
                {currentTab === "/sn-metadata" && 
                updateMetadataMode &&  
                    getMetadataFormComponents()
                }
                {currentTab === "/sn-backups" && <div>
                    {snapshotBackups.length > 0 ?
                    snapshotBackups.map(item => {
                        return (
                            <Stack key={item.id} spacing={1} sx={{m: 2}}>
                                <Stack 
                                    direction="row" 
                                    justifyContent="space-between" 
                                    alignItems="center"
                                    onClick={() => handleNavigateToBackup(item.id,"/backups")}
                                    sx={{cursor: "pointer", color: "primary.main"}}
                                >
                                    <CustomText sx={{color: "text.secondary"}}>{defaultTexts.nameFormFieldLabel}</CustomText>
                                    <CustomText>{item.name ? item.name : item.id}</CustomText>
                                </Stack>
                                <Stack 
                                    direction="row" 
                                    justifyContent="space-between" 
                                    alignItems="center"
                                >
                                    <CustomText sx={{color: "text.secondary"}}>{defaultTexts.sizeFormFieldLabel}</CustomText>
                                    <CustomText>{`${item.size}GB`}</CustomText>
                                </Stack>
                                <Stack 
                                    direction="row" 
                                    justifyContent="space-between" 
                                    alignItems="center"
                                >
                                    <CustomText sx={{color: "text.secondary"}}>{defaultTexts.statusFormFieldLabel}</CustomText>
                                    <CustomText>{item.status}</CustomText>
                                </Stack>
                                <Stack 
                                    direction="row" 
                                    justifyContent="space-between" 
                                    alignItems="center"
                                >
                                    <CustomText sx={{color: "text.secondary"}}>{defaultTexts.incrementalFormFieldLabel}</CustomText>
                                    <CustomText>{item.incremental ? defaultTexts.formValueYes : defaultTexts.formValueNo}</CustomText>
                                </Stack>
                                <Stack 
                                    direction="row" 
                                    justifyContent="space-between" 
                                    alignItems="center"
                                >
                                    <CustomText sx={{color: "text.secondary"}}>{defaultTexts.createdFormFieldLabel}</CustomText>
                                    <CustomText>{new Date(item.created_at).toLocaleString()}</CustomText>
                                </Stack>
                                <Stack 
                                    direction="row" 
                                    justifyContent="space-between" 
                                    alignItems="center"
                                >
                                    <CustomText sx={{color: "text.secondary"}}>{defaultTexts.updatedAtFormFieldLabel}</CustomText>
                                    <CustomText>{new Date(item.updated_at).toLocaleString()}</CustomText>
                                </Stack>
                                <Divider />
                            </Stack>
                        )
                    })
                    :
                    <NoDataNote text={defaultTexts.noBackupsNoteText} />}
                    </div>
                }
            <Paper sx={{ 
                position: 'fixed', 
                bottom: 0, 
                height: FOOTER_HEIGHT,
                left: FOOTER_WIDTH, 
                right: 12,
                zIndex: 3000
                }} 
                elevation={24}
                square={true}
            >
                <Grid 
                    container 
                    alignItems="center"  
                    justifyContent="space-between"
                >
                    <Grid item>
                        <CustomSelectField 
                            items={snapshotActions} 
                            currentValue={currentAction}
                            setCurrentValue={setCurrentAction}
                            item_titles={defaultTexts}
                            label={defaultTexts.actionsDropdownLabelText}
                            empty={true}
                            size="small"
                            sx={{m: 1}}
                        />
                        {currentAction.length > 0 && 
                            <Button 
                                    variant="contained"
                                    color="secondary"
                                    sx={{m: 1, height: '70%'}}
                                    onClick={snapshotActions.filter(
                                        action => action.value === currentAction)[0].action
                                    }
                                >
                                {defaultTexts[snapshotActions.filter(
                                    action => action.value === currentAction)[0].button_text]}
                            </Button>
                        }
                    </Grid>
                    <Grid item>
                        {!editMode ? <IconButton onClick={handleEditModeChange}>
                                <EditIcon color="primary" />
                            </IconButton> :
                            <IconButton onClick={handleEditModeReset}>
                                <EditOffIcon color="primary"/>
                            </IconButton>
                        }
                        {selectedRow !== null && 
                            <IconButton onClick={handleConfirmDeleteDialogOpen}>
                                <DeleteIcon 
                                    color="primary"
                                />
                            </IconButton>}
                    </Grid>
                </Grid>
            </Paper> 
            <CustomDialog
                open={createBackupDialogOpen}
                onClose={handleCreateBackupDialogClose}
                dialogTitle={{
                    title: defaultTexts.createBackupActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onBackupCreate, 
                    sx: {color: 'primary.main'}}]}
            >
                {getBackupDataForm(
                    snapshotUpdateDataForm,
                    {},
                    backupData,
                    handleBackupDataChange
                )}
            </CustomDialog>
            {warningDialogOpen && <CustomDialog
                open={warningDialogOpen}
                onClose={handleWarningDialogClose}
                dialogTitle={{
                    title: defaultTexts.warningDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${warningMessage}</span>
                    <br>
                    <br>`, 
                    sx: {color: 'text.primary'}}}
            >
                {warning}
            </CustomDialog>
            }
            {successSnapshotUpdate && <CustomDialog
                open={successUpdateDialogOpen}
                onClose={handleSuccessUpdateDialogClose}
                dialogTitle={{
                    title: defaultTexts[successSnapshotUpdate.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: successSnapshotUpdate.success_details ?
                        `<span>${defaultTexts[successSnapshotUpdate.success_message]}</span>
                            <br>
                            <br>
                            <span>Secret Key:</span> 
                            <span style="width: 100px; color: orange; word-wrap: break-word;">
                                ${successSnapshotUpdate.success_details}
                            </span>` :
                        `<span>${defaultTexts[successSnapshotUpdate.success_message]}</span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </React.Fragment>
    )

};

export default SnapshotDetailV3;