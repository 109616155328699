export const billingServiceDataSchema = [
    {
        field_key: "service_name",
        is_id_field: false,
        field_type: "string", 
        label: "serviceFormFieldLabel"
    },
    {
        field_key: "region_name",
        is_id_field: false,
        field_type: "string", 
        label: "regionFormFieldLabel"
    },
    {
        field_key: "service_domain",
        is_id_field: false,
        field_type: "string", 
        label: "serviceDomainFormFieldLabel"
    },
    {
        field_key: "service_version",
        is_id_field: false,
        field_type: "string", 
        label: "serviceVersionFormFieldLabel"
    },
    {
        field_key: "resource_suspend_period",
        is_id_field: false,
        field_type: "string", 
        label: "resourceSuspendDelayFormFieldLabel"
    },
    {
        field_key: "resource_suspend_policy",
        is_id_field: false,
        field_type: "string", 
        label: "resourceSuspendPolicyFormFieldLabel"
    },
    {
        field_key: "resource_delete_period",
        is_id_field: false,
        field_type: "string", 
        label: "resourceDeleteDelayFormFieldLabel"
    },
    {
        field_key: "resource_delete_policy",
        is_id_field: false,
        field_type: "string", 
        label: "resourceDeletePolicyFormFieldLabel"
    },
    {
        field_key: "is_identity_provider",
        is_id_field: false,
        field_type: "bool", 
        label: "isIdentityProviderFormFieldLabel",
        value_on_true: "formValueYes",
        value_on_false: "formValueNo",
    }
]