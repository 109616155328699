import { configureStore, combineReducers } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';

import drawerReducer from './reducers/drawerSlice';
import profileReducer from './reducers/profileSlice';
import settingsReducer from './reducers/settingsSlice';
import openStackReducer from './reducers/openStackSlice';
import textsReducer from './reducers/textsSlice';
import identityKeystoneReducer from './reducers/identityKeystoneSlice';
import computeNovaReducer from './reducers/computeNovaSlice';
import networkNeutronReducer from './reducers/networkNeutronSlice';
import blockStorageCinderReducer from './reducers/blockStorageCinderSlice';
import imageGlanceReducer from './reducers/imageGlanceSlice';

const persistConfig = {
  key: 'root',
  storage,
}

const rootReducer = combineReducers({ 
  drawer: drawerReducer,
  profile: profileReducer,
  settings: settingsReducer,
  openstack: openStackReducer,
  texts: textsReducer,
  identityKeystone: identityKeystoneReducer,
  computeNova: computeNovaReducer,
  networkNeutron: networkNeutronReducer,
  blockStorageCinder: blockStorageCinderReducer,
  imageGlance: imageGlanceReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [thunk]
})

export const persistor = persistStore(store)