import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CustomDialog from '../../../../../_common/CustomDialog';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import Dimensions from '../../../../../../config/dimensions';
import CustomSideDrawer from '../../../../../_common/CustomSideDrawer';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, 
        networkNeutronConstants, 
        identityKeystonConstants
} from '../../../../../../config/openStackConstants';
import SubnetsSubheaderV20 from './subnetsSubheaderV2.0';
import SubnetsTableV20 from './subnetsTableV2.0';
import SubnetDetailV20 from './subnetDetailV2.0';
import { subnetsFilterMenu } 
from '../../../../../../_data/openstack/neutron/subnets/v2.0';
import { 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../../_network/openstack_request';
import { subnetsUrl as subnetUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/subnets/v2.0';
import { projectsUrl as projectUrlResponses} from 
'../../../../../../_api_responses/openstack/identity/projects/v3';

const SERVICE_NAME = openStackServices.networkService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const SubnetsWrapperV20 = (props) => {
    const { navigate, location, changeMenuActiveTab } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const { virtualIPsSupported } = props
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const {currentTab, setCurrentTab} = props
    const [subnetsData, setSubnetsData] = useState([])
    const [subnets, setSubnets] = useState([])
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = width < Dimensions.tablet_mini.width ? 0.9 : 0.8
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedSubnet, setSelectedSubnet] = useState(null);
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [currentAction, setCurrentAction] = useState("");
    const [subnetDeleteConfirmDialogOpen, setSubnetDeleteConfirmDialogOpen] = useState(false);
    const [selectedSubnets, setSelectedSubnets] = useState([])
    const [subnetsSortParams, setSubnetsSortParams] = useState("")
    
    const [subnetFilterQueryParams, setSubnetFilterQueryParams] = useState("")
    const [selectedSubnetFilter, setSelectedSubnetFilter] = useState(subnetsFilterMenu[0].value)
    const [selectedSubnetFilterValue, setSelectedSubnetFilterValue] = useState("")
    const [subnetsFilter, setSubnetsFilter] = useState([...subnetsFilterMenu])

    const [projects, setProjects] = useState([])
    const [networks, setNetworks] = useState([])

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const subnetsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.subnetsUrl)[0].url)
    const networksUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.networksUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleTabChange = (tab_name) => {
        setCurrentTab(tab_name)
    }

    const handleSubnetFilteredSearch = () => {
        if (selectedSubnetFilter && selectedSubnetFilterValue) {
            setSubnetFilterQueryParams(`${selectedSubnetFilter}=${selectedSubnetFilterValue}`)
        } else {
            setSubnetFilterQueryParams("")
        }
        handleDataFetch()
    }

    const handleSubnetsDataFormatting = useCallback(() => {
        const formatted_data = subnetsData.map((item) => {
            let new_item = {...item}
            const project = projects.filter(p => p.id === item.project_id)
            if (project.length > 0) {
                new_item.project_id = project[0].name
            } else {
                new_item.project_id = "-"
            }
            const network = networks.filter(n => n.id === item.network_id)
            if (network.length > 0) {
                new_item.network_id = network[0].name
            } else {
                new_item.network_id = "-"
            }
            return new_item
        })
        setSubnets(formatted_data)
    },[
        subnetsData,
        projects,
        networks
    ])

    const handleSubnetFilterReset = () => {
        setSelectedSubnetFilter(subnetsFilterMenu[0].value)
        setSelectedSubnetFilterValue("")
        setSubnetFilterQueryParams("")
        handleDataFetch()
    }

    const handleNavigateToNetwork = (network_id,tab_name) => {
        handleDetailCardClose()
        changeMenuActiveTab(tab_name)
        navigate("/networks",{state: {network_id: network_id}})
    }

    const handleDetailCardOpen = useCallback((index) => {
        setSelectedSubnet(subnetsData[index].id)
        setSelectedRow(subnetsData[index])
        setTimeout(() => setDetailCardOpen(true),100)
    },[subnetsData]);

    const handleDetailCardClose = () => {
        setTimeout(() => setDetailCardOpen(false),100)
        setSelectedRow(null)
        setSelectedSubnet(null)
        navigate(location.path,{})
    };

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleSubnetsSorting = (field,direction) => {
        const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
        setSubnetsSortParams(sort_param)
        handleDataFetch()
    }

    const handleSubnetDelete = async (n_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${subnetsUrl}/${n_id}`
            const method = "DELETE"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                token: project_token
            })

            if (nt_response.status_code === subnetUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    };

    const onSubnetDelete = async () => {
        handleSubnetDeleteConfirmDialogClose()
        let err = []
        for (let n in selectedSubnets) {
            const resp = await handleSubnetDelete(selectedSubnets[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDetailCardClose()
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onSubnetDeleteConfirm = (n_list) => {
        const selected_n_list = subnetsData.filter(n => 
            n_list.includes(n.id))
        setSelectedSubnets([...selected_n_list])
        setSubnetDeleteConfirmDialogOpen(true)
    }

    const handleSubnetDeleteConfirmDialogClose = () => {
        setSubnetDeleteConfirmDialogOpen(false)
    }

    const getSubnetsActionsList = () => {
        let subnet_actions = []
        let new_action = {}
        new_action["value"] = "subnet_delete"
        new_action["action"] = onSubnetDeleteConfirm
        new_action["keyword"] = "subnetDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        subnet_actions.push({...new_action})
        
        return subnet_actions
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${neutronServiceDomain}/${neutronServiceVersion}/${subnetsUrl}?${subnetFilterQueryParams}${subnetsSortParams}`
                    const method = "GET"
                    const subnet_response = await openstackRequest({url:url, method:method, token: project_token})
                    if (subnet_response.status_code === subnetUrlResponses.get.success_response.status_code) {
                        setSubnetsData(subnet_response.data.subnets)
                        if (selectedSubnet) {
                            const selected_subnet = subnet_response.data.subnets.filter(item => item.id === selectedSubnet)
                            if (selected_subnet.length > 0) {
                                setSelectedRow(selected_subnet[0])
                            }
                        }
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        subnetsUrl, 
        subnetFilterQueryParams,
        dataFetchingRequired,
        defaultAdminProject,
        subnetsSortParams,
        selectedSubnet
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${neutronServiceDomain}/${neutronServiceVersion}/${networksUrl}`
                const method = "GET"
                const network_response = await openstackRequest({url:url, method:method, token: project_token})
                if (network_response.status_code === subnetUrlResponses.get.success_response.status_code) {
                    setNetworks(network_response.data.networks)
                }
            }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        networksUrl, 
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === projectUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        if (subnetsData.length > 0) {
            handleSubnetsDataFormatting()
        }
    },[
        subnetsData,
        handleSubnetsDataFormatting
    ])

    useEffect(() => {
        let projects_filter = []
        if (projects.length > 0) {
            projects_filter = projects.map(project => {
                return {keyword: project.name, value: project.id, default: false}
            })
        }

        let networks_filter = []
        if (networks.length > 0) {
            networks_filter = networks.map(network => {
                return {keyword: network.name, value: network.id, default: false}
            })
        }
        
        let subnet_filter_menu = subnetsFilterMenu.map(nt => {
            let new_item = {...nt}
            if (nt.value === "project_id") {
                new_item.items = [...projects_filter]
            }
            if (nt.value === "network_id") {
                new_item.items = [...networks_filter]
            }
            return new_item
        })

        setSubnetsFilter(subnet_filter_menu)
    },[
        subnetsData,
        projects,
        networks
    ])

    useEffect(() => {
        if (!dataFetchingRequired && location.state ) {
            const subnet_id = location.state ? location.state.subnet_id : null
                const subnet_index = subnetsData.findIndex(v => v.id === subnet_id);
                if (subnet_index !== -1) {
                    setTimeout(() => handleDetailCardOpen(subnet_index), 600)
                }
        }
    },[
        dataFetchingRequired,
        subnetsData,
        handleDetailCardOpen,
        location
    ])

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <SubnetsSubheaderV20 
                virtualIPsSupported={virtualIPsSupported}
                selectedFilter={selectedSubnetFilter} 
                setSelectedFilter={setSelectedSubnetFilter}
                selectedFilterValue={selectedSubnetFilterValue}
                setSelectedFilterValue={setSelectedSubnetFilterValue}
                handleDataFetch={handleDataFetch}
                filterMenu={subnetsFilter}
                handleFilteredSearch={handleSubnetFilteredSearch}
                handleFilterReset={handleSubnetFilterReset}
                currentTab={currentTab}
                handleTabChange={handleTabChange}
                networks={networks}
            />
        {isLoading && <CustomBackdrop open={isLoading} />}
        {!isLoading &&
            <SubnetsTableV20 
                subnetsData={subnets}
                setSubnetsData={setSubnets}
                handleRowSelection={handleDetailCardOpen}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getSubnetsActionsList()}
                sortHandler={handleSubnetsSorting}
            />
        }
        {selectedRow !== null && 
            <CustomSideDrawer 
                open={detailCardOpen}
                widthWeight={WIDTH_WEIGHT}
                handleDrawerOpen={handleDetailCardOpen}
                handleDrawerClose={handleDetailCardClose}
            > 
                <SubnetDetailV20
                    selectedRow={selectedRow}
                    widthWeight={WIDTH_WEIGHT}
                    handleDataFetch={handleDataFetch}
                    handleNavigateToNetwork={handleNavigateToNetwork}
                    handleDelete={onSubnetDeleteConfirm}
                    projects={projects}
                    networks={networks}
                />
            </CustomSideDrawer>
        }
        <CustomDialog
            open={subnetDeleteConfirmDialogOpen}
            onClose={handleSubnetDeleteConfirmDialogClose}
            dialogTitle={{
                title: defaultTexts.subnetDeleteConfirmTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `${defaultTexts.subnetDeleteConfirmText}: [${selectedSubnets.map(v => v.name).toString()}]`, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onSubnetDelete, 
                sx: {color: 'primary.main'}}]}
        />
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts[error.error_title], 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts[error.error_message]}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
    </Box>
    )
};

export default SubnetsWrapperV20;