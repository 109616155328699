import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  supportedRegions: [],
  purchasedServices: [],
  purchasedServicesAll: []
}

const openStackSlice = createSlice({
  name: 'openstack',
  initialState,
  reducers: {
    setPurchasedServices: (state, action) => {
        state.purchasedServices = action.payload.purchasedServices
    },
    setPurchasedServicesAll: (state, action) => {
      state.purchasedServicesAll = action.payload.purchasedServicesAll
    },
    setSupportedRegions: (state, action) => {
      state.supportedRegions = action.payload.supportedRegions
    },
    setIdentityServiceDomain: (state, action) => {
      state.identityServiceDomain = action.payload.identityServiceDomain
    },
    setIdentityServiceConfig: (state, action) => {
      state.identityServiceConfig = action.payload.identityServiceConfig
    },
    setIdentityAuthTokenApiJson: (state, action) => {
      state.identityAuthTokenApiJson = action.payload.identityAuthTokenApiJson
    },
    setidentityRoleAssignmentApiQueryParams: (state, action) => {
      state.identityRoleAssignmentApiQueryParams = action.payload.identityRoleAssignmentApiQueryParams
    },
    openStackConfigReset: () => {
      return {...initialState}
    }
  }
})

export const { 
    setPurchasedServices,
    setPurchasedServicesAll,
    setSupportedRegions,
    setIdentityAuthTokenApiJson,
    setIdentityServiceDomain,
    setIdentityServiceConfig,
    setidentityRoleAssignmentApiQueryParams,
    openStackConfigReset
} = openStackSlice.actions

export default openStackSlice.reducer