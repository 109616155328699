import React from 'react';
import { useSelector } from 'react-redux';
import CustomText from '../../../../../_common/CustomText';
import WrapperBox from '../../../../../_common/WrapperBox';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { Divider } from '@mui/material';
import { networkNeutronConstants } from 
'../../../../../../config/openStackConstants';


const detailTabCardConfig = {
    border: 0.3,
    borderRadius: 2,
    borderStyle: "dashed",
    borderColor: "text.secondary",
    minHeight: 450
}

const FloatingIPSpecsV20 = (props) => {
    const { floatingIPData, selectedRow, handleNavigateToNetwork } = props
    const { handleNavigateToServer } = props
    const { handleNavigateToRouter } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);

    const handleNavigationToDevice = () => {
        if (floatingIPData.port_details && floatingIPData.port_details.device_owner === networkNeutronConstants.computeNovaOwner) {
            handleNavigateToServer(selectedRow.port_details.device_id)
        } else if (floatingIPData.port_details &&  floatingIPData.port_details.device_owner.startsWith(networkNeutronConstants.routerInterfaceOwner)) {
            handleNavigateToRouter(selectedRow.port_details.device_id, "/l3-networks")
        }
    }

    return (
        <React.Fragment>
            <Grid container spacing={2} sx={{mt: 1, mb: 4}}>
                <Grid item xs={12} lg={6}>
                    <WrapperBox
                        sx={detailTabCardConfig}
                    >
                        <Stack 
                            direction="column" 
                            spacing={1} 
                            sx={{width: "90%"}}
                        >
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.idFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {floatingIPData ? floatingIPData.id : ""}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.ipAddressFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {floatingIPData.floating_ip_address}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.projectFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {floatingIPData.project_id ? 
                                        floatingIPData.project_id : "-"}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                                onClick={() => handleNavigateToNetwork(selectedRow.floating_network_id,"/l2-networks")}
                                sx={{cursor: "pointer"}}
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.networkFormFieldLabel}
                                </CustomText>
                                <CustomText size="p" sx={{color: "primary.main"}}>
                                    {floatingIPData.floating_network_id ? 
                                        floatingIPData.floating_network_id : "-"}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.statusFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {floatingIPData.status}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.createTimeHeaderLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {new Date(floatingIPData.created_at).toLocaleString()}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.updatedAtFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {new Date(floatingIPData.updated_at).toLocaleString()}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.descriptionFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {floatingIPData.description ? floatingIPData.description : "-"}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.revisionNumberFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {floatingIPData.revision_number}
                                </CustomText>
                            </Stack>
                            <Divider />
                        </Stack>
                    </WrapperBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <WrapperBox
                        sx={detailTabCardConfig}
                    >
                        <Stack 
                            direction="column" 
                            spacing={1} 
                            sx={{width: "90%"}}
                        >
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.dnsDomainFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {floatingIPData.dns_domain ? floatingIPData.dns_domain : "-"}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.dnsNameFormFieldLabel}
                                </CustomText>
                                <Stack alignItems="end">
                                    {floatingIPData.dns_name ? floatingIPData.dns_name : "-"} 
                                </Stack>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.qosPolicyFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {floatingIPData.qos_policy_id ? 
                                        floatingIPData.qos_policy_id : 
                                        "-"}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                                onClick={handleNavigationToDevice}
                                sx={{cursor: floatingIPData.port_details ? "pointer" : undefined}}
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.associatedWithFormFieldLabel}
                                </CustomText>
                                <CustomText size="p" sx={{color: floatingIPData.port_details ? 
                                    "primary.main" : "text.primary"}}>
                                    {floatingIPData.port_details  && floatingIPData.port_details.device_name ? 
                                        floatingIPData.port_details.device_name : 
                                        "-"
                                    }
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.mappedLocalIPFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {floatingIPData.fixed_ip_address  ? 
                                        floatingIPData.fixed_ip_address : 
                                        "-"
                                    }
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.macAddressFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {floatingIPData.port_details  ? 
                                        floatingIPData.port_details.mac_address : 
                                        "-"
                                    }
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.portStatusFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {floatingIPData.port_details  ? 
                                        floatingIPData.port_details.status : 
                                        "-"
                                    }
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.portFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {floatingIPData.port_id ? floatingIPData.port_id : "-"}
                                </CustomText>
                            </Stack>
                            <Divider />
                        </Stack>
                    </WrapperBox>
                </Grid>
            </Grid>
        </React.Fragment>
    )
};

export default FloatingIPSpecsV20;