import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import Constants from '../../../config/constants'
import Box from '@mui/material/Box';
import CustomDialog from '../../_common/CustomDialog';
import { setBillingPaymentsTableRows } from 
'../../../store/reducers/settingsSlice';

const drawerWidth = Constants.drawerWidth;

const BillingPaymentsTable = (props) => {
    const { billingPayments } = props
    const { openstackProjects } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const drawerOpen = useSelector(state => state.drawer.drawerOpened);
    const billingPaymentsTableRows = useSelector(state => state.settings.billingPaymentsTableRows)
    const [page, setPage] = useState(0);
    const [startItem, setStartItem] = useState(0);
    const [endItem, setEndItem] = useState(billingPayments.length);
    const [selectedPaymentDetails, setSelectedPaymentDetails] = useState({})
    const [transactionDetailsDialogOpen, setTransactionDetailsDialogOpen] = useState(false)
    
    const ROWS_PER_PAGE_LIST = billingPayments.length > 100 ? 
    [5, 10, 25, 50, 100, billingPayments.length] : 
    [5, 10, 25, 50, 100]

    const dispatch = useDispatch();

    const handleRowsPerPageChange = (event) => {
        const new_rows_per_page = parseInt(event.target.value)
        setPage(0);
        dispatch(setBillingPaymentsTableRows({
            billingPaymentsTableRows: new_rows_per_page
        }))
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleTransactionDetailsDialogOpen = (transaction) => {
        setSelectedPaymentDetails(transaction)
        setTransactionDetailsDialogOpen(true)
    }

    const handleTransactionDetailsDialogClose = () => {
        setSelectedPaymentDetails({})
        setTransactionDetailsDialogOpen(false)
    }

    useEffect(() => {
        if (!billingPaymentsTableRows) {
            dispatch(setBillingPaymentsTableRows({
                billingPaymentsTableRows: 10
            }))
        }
    },[billingPaymentsTableRows,dispatch])

    
    useEffect(() => {
          const start_item = page*billingPaymentsTableRows
          const end_item = page*billingPaymentsTableRows+billingPaymentsTableRows
          setStartItem(start_item)
          setEndItem(end_item)
        },[
        page,
        billingPaymentsTableRows
      ]);

    return (
        <React.Fragment>
            {<Box sx={{
                maxWidth: drawerOpen ? 
                `calc(100vw - ${drawerWidth}px)` : 
                `calc(100vw - ${110}px)`,
                mt: 2
            }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 300}} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell  align="left">
                                    {defaultTexts.projectFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.timeStampFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.paymentMethodFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.invoiceIDTypeFormFieldLabel}
                                </TableCell>
                                <TableCell  align="right">
                                    {defaultTexts.amountFormFieldLabel}
                                </TableCell>
                                <TableCell  align="center">
                                    {defaultTexts.transactionDetailsFormFieldLabel}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {billingPayments.slice(startItem, endItem).map((row) => (
                                <TableRow 
                                    key={row.id}
                                >
                                    <TableCell align="left">{
                                        openstackProjects.filter(
                                            p => p.id === row.project_id).length > 0 ?
                                        openstackProjects.filter(
                                            p => p.id === row.project_id)[0].name :
                                        row.project_id}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.time_stamp.toLocaleString()}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.payment_method.toUpperCase()}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.invoice_id ? row.invoice_id : "-"}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.amount} {row.currency.toUpperCase()}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Button onClick={() => handleTransactionDetailsDialogOpen(row.transaction_data)}>
                                            {defaultTexts.showButtonText}
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {billingPayments.length > 0 && <TablePagination
                        rowsPerPageOptions={ROWS_PER_PAGE_LIST}
                        component="div"
                        count={billingPayments.length}
                        rowsPerPage={billingPaymentsTableRows}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        labelRowsPerPage={defaultTexts.rowsPerPageLabel}
                    />}
                </TableContainer>
                <CustomDialog
                    open={transactionDetailsDialogOpen}
                    onClose={handleTransactionDetailsDialogClose}
                    maxWidth={Object.keys(selectedPaymentDetails).length > 0 ? "lg" : "sm"}
                    dialogTitle={{
                        title: defaultTexts.billingTransactionDetailsDialogTitle, 
                        sx: {color: 'primary.main'}}}
                    dialogBody={{
                        text: Object.keys(selectedPaymentDetails).length > 0 ? "" : defaultTexts.noDataNoteMessage, 
                        sx: {color: 'text.primary'}}}
                >
                    {Object.keys(selectedPaymentDetails).length > 0 && <div>
                        <pre>
                            {JSON.stringify(selectedPaymentDetails, null, 2)}
                        </pre>
                    </div>}
                </CustomDialog>
            </Box>}
        </React.Fragment>
    )
};

export default BillingPaymentsTable;