import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ServiceContentHeader from '../../../_common/ServiceContentHeader';
import Box from '@mui/material/Box';
import ImagesWrapperV2 from './images/imagesWrapperV2';

const ImagesGlanceContentV2 = (props) => {
    //const { serviceName } = props
    const { navigate, location } = props
    const { serviceTitle } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)

    const [serviceMenu, setServiceMenu] = useState([
        {keyword: "submenuImages", navigation: "/images", is_active: true, component: ImagesWrapperV2},
        //{keyword: "submenuLimits", navigation: "/image-limits", is_active: false, component: () => <Box />}
    ])

    const getCurrentTab = () => {
        const currentItem = serviceMenu.filter(item => item.is_active)[0]
        return currentItem
    }

    const [currentTab, setCurrentTab] = useState(getCurrentTab())

    const changeMenuActiveTab = (navigation) => {
        const updated_menu = serviceMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
                setCurrentTab(item)
            } else {
                item.is_active = false
            }
            return item
        })
        setServiceMenu(updated_menu)
    }

    return (
        <Box>
            <ServiceContentHeader 
                service_title={serviceTitle} 
                service_menu={serviceMenu}
                service_menu_titles={defaultTexts}
                onClick={changeMenuActiveTab}
            />
            <currentTab.component 
                navigate={navigate} 
                location={location}
            />
        </Box>
    )
};

export default ImagesGlanceContentV2;