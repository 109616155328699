import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ServiceContentHeader from '../../../_common/ServiceContentHeader';
import Box from '@mui/material/Box';
import L2NetworkingWrapperV20 from './l2Networking/l2NetworkingWrapperV2_0';
import L3NetworkingWrapperV20 from './l3Networking/l3NetworkingWrapperV2_0';
import SecurityWrapperV20 from './security/securityWrapperV2_0';
import FirewallWrapperV20 from './fwaas/firewallWrapperV2.0';
import VPNWrapperV20 from './vpnaas/vpnWrapperV2_0';
import NeutronLimitsWrapperV20 from './limits/neutronLimitsWrapperV2.0'

const NetworksNeutronContentV2_0 = (props) => {
    const { navigate, location } = props
    const { serviceTitle } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)

    const [serviceMenu, setServiceMenu] = useState([
        {keyword: "submenuL2Networks", navigation: "/l2-networks", is_active: true, component: L2NetworkingWrapperV20},
        {keyword: "submenuL3Networks", navigation: "/l3-networks", is_active: false, component: L3NetworkingWrapperV20},
        {keyword: "submenuSecurity", navigation: "/network-security", is_active: false, component: SecurityWrapperV20},
        {keyword: "submenuFWaaS", navigation: "/network-fwaas", is_active: false, component: FirewallWrapperV20},
        {keyword: "submenuVPNaaS", navigation: "/network-vpnaas", is_active: false, component: VPNWrapperV20},
        //{keyword: "submenuQoS", navigation: "/network-qos", is_active: false, component: () => <Box />},
        {keyword: "submenuNetworkLimits", navigation: "/network-limits", is_active: false, component: NeutronLimitsWrapperV20},
        //{keyword: "submenuNetworkTopology", navigation: "/network-topology", is_active: false, component: () => <Box />}
    ])

    const getCurrentTab = () => {
        const currentItem = serviceMenu.filter(item => item.is_active)[0]
        return currentItem
}
const [currentTab, setCurrentTab] = useState(getCurrentTab())


const changeMenuActiveTab = (navigation) => {
    const updated_menu = serviceMenu.map(item => {
        if (item.navigation === navigation) {
            item.is_active = true
            setCurrentTab(item)
        } else {
            item.is_active = false
        }
        return item
    })
    setServiceMenu(updated_menu)
}

    return (
        <Box>
            <ServiceContentHeader 
                service_title={serviceTitle} 
                service_menu={serviceMenu}
                service_menu_titles={defaultTexts}
                onClick={changeMenuActiveTab}
            />
            <currentTab.component 
                navigate={navigate} 
                location={location}
                changeMenuActiveTab={changeMenuActiveTab}
            />
        </Box>
    )
};

export default NetworksNeutronContentV2_0;