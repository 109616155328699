import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CustomDialog from '../../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, 
        networkNeutronConstants, 
        identityKeystonConstants
} from '../../../../../../config/openStackConstants';
import DefaultRulesSubheaderV20 from './defaultRulesSubheaderV2.0';
import DefaultRulesTableV20 from './defaultRulesTableV2.0';
import { defaultRulesFilterMenu } 
from '../../../../../../_data/openstack/neutron/security/v2.0';
import { 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../../_network/openstack_request';
import { securityUrl as securityUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/security/v2.0';
import { projectsUrl as projectUrlResponses} from 
'../../../../../../_api_responses/openstack/identity/projects/v3';

const SERVICE_NAME = openStackServices.networkService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const DefaultRulesWrapperV20 = (props) => {
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const {currentTab, setCurrentTab} = props
    const [defaultRulesData, setDefaultRulesData] = useState([])
    const [defaultRules, setDefaultRules] = useState([])
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [currentAction, setCurrentAction] = useState("");
    const [defaultRuleDeleteConfirmDialogOpen, setDefaultRuleDeleteConfirmDialogOpen] = useState(false);
    const [selectedDefaultRules, setSelectedDefaultRules] = useState([])
    const [defaultRulesSortParams, setDefaultRulesSortParams] = useState("")
    
    const [defaultRuleFilterQueryParams, setDefaultRuleFilterQueryParams] = useState("")
    const [selectedDefaultRuleFilter, setSelectedDefaultRuleFilter] = useState(defaultRulesFilterMenu[0].value)
    const [selectedDefaultRuleFilterValue, setSelectedDefaultRuleFilterValue] = useState("")
    const [defaultRulesFilter, setDefaultRulesFilter] = useState([...defaultRulesFilterMenu])
    const [addressGroups, setAddressGroups] = useState([])
    const [securityGroups, setSecurityGroups] = useState([])

    const [projects, setProjects] = useState([])

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const defaultRulesUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.defaultSecGroupRulesUrl)[0].url)
    const securityGroupsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.securityGroupsUrl)[0].url)
    const addressGroupsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.addressGroupsUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleTabChange = (tab_name) => {
        setCurrentTab(tab_name)
    }

    const handleDefaultRuleFilteredSearch = () => {
        if (selectedDefaultRuleFilter && selectedDefaultRuleFilterValue) {
            setDefaultRuleFilterQueryParams(`${selectedDefaultRuleFilter}=${selectedDefaultRuleFilterValue}`)
        } else {
            setDefaultRuleFilterQueryParams("")
        }
        handleDataFetch()
    }

    const handleDefaultRulesDataFormatting = useCallback(() => {
        const formatted_data = defaultRulesData.map((item) => {
            let new_item = {...item}
            const project = projects.filter(p => p.id === item.project_id)
            if (project.length > 0) {
                new_item.project_id = project[0].name
            } else {
                new_item.project_id = "-"
            }
            let remote = ""
            if (item.remote_group_id) {
                const sec_group = securityGroups.filter(sg => sg.id === item.remote_group_id)[0]
                remote = `${defaultTexts.securityGroupFormFieldLabel}: ${sec_group ? sec_group.name : item.remote_group_id}`
            } else if (item.remote_address_group_id) {
                const address_group = addressGroups.filter(sg => sg.id === item.remote_address_group_id)[0]
                remote = `${defaultTexts.addressGroupFormFieldLabel}: ${address_group.name}`
            } else if (item.remote_ip_prefix) {
                remote = `${item.remote_ip_prefix}`
            } else {
                if (item.ethertype.toLowerCase() === "ipv4") {
                    remote = "0.0.0.0/0"
                } else {
                    remote = "::/0"
                }
            }
            let protocol = ""
            if (item.protocol  && parseInt(item.protocol) !== 0) {
                protocol = `${item.protocol.toUpperCase()}`
            } else {
                protocol = "Any"
            }
            let ports = ""
            if (item.port_range_min) {
                ports = `${item.port_range_min}`
            } 
            if (item.port_range_max > -1 && item.port_range_max !== item.port_range_min) {
                ports = `${ports} - ${item.port_range_max}`
            }
            if (!item.port_range_min && !item.port_range_max) {
                ports = "Any"
            }
            new_item.remote = remote
            new_item.protocol = protocol
            new_item.ports = ports
            return new_item
        })
        setDefaultRules(formatted_data)
    },[
        defaultRulesData,
        projects,
        securityGroups,
        addressGroups,
        defaultTexts
    ])

    const handleDefaultRuleFilterReset = () => {
        setSelectedDefaultRuleFilter(defaultRulesFilterMenu[0].value)
        setSelectedDefaultRuleFilterValue("")
        setDefaultRuleFilterQueryParams("")
        handleDataFetch()
    }


    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleDefaultRulesSorting = (field,direction) => {
        const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
        setDefaultRulesSortParams(sort_param)
        handleDataFetch()
    }

    const handleDefaultRuleDelete = async (n_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${defaultRulesUrl}/${n_id}`
            const method = "DELETE"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                token: project_token
            })

            if (nt_response.status_code === securityUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    };

    const onDefaultRuleDelete = async () => {
        handleDefaultRuleDeleteConfirmDialogClose()
        let err = []
        for (let n in selectedDefaultRules) {
            const resp = await handleDefaultRuleDelete(selectedDefaultRules[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }


    const onDefaultRuleDeleteConfirm = useCallback((n_list) => {
        const selected_n_list = defaultRulesData.filter(n => 
            n_list.includes(n.id))
        setSelectedDefaultRules([...selected_n_list])
        setDefaultRuleDeleteConfirmDialogOpen(true)
    },[defaultRulesData])

    const handleDefaultRuleDeleteConfirmDialogClose = () => {
        setDefaultRuleDeleteConfirmDialogOpen(false)
    }

    const getDefaultRulesActionsList = useCallback(() => {
        let defaultRule_actions = []
        let new_action = {}
        new_action["value"] = "defaultRule_delete"
        new_action["action"] = onDefaultRuleDeleteConfirm
        new_action["keyword"] = "defaultRuleDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        defaultRule_actions.push({...new_action})
        
        return defaultRule_actions
    },[
        onDefaultRuleDeleteConfirm
    ])

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${neutronServiceDomain}/${neutronServiceVersion}/${defaultRulesUrl}?${defaultRuleFilterQueryParams}${defaultRulesSortParams}`
                    const method = "GET"
                    const defaultRule_response = await openstackRequest({url:url, method:method, token: project_token})
                    if (defaultRule_response.status_code === securityUrlResponses.get.success_response.status_code) {
                        setDefaultRulesData(defaultRule_response.data.default_security_group_rules)
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        defaultRulesUrl, 
        defaultRuleFilterQueryParams,
        dataFetchingRequired,
        defaultAdminProject,
        defaultRulesSortParams
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === projectUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const method = "GET"
                const securityGroup_response = await openstackRequest({
                    url: `${neutronServiceDomain}/${neutronServiceVersion}/${securityGroupsUrl}`, 
                    method: method, 
                    token: project_token
                })
                if (securityGroup_response.status_code === securityUrlResponses.get.success_response.status_code) {
                    setSecurityGroups(securityGroup_response.data.security_groups)
                }
            }
        })();
    },[
        neutronServiceDomain,
        neutronServiceVersion,
        securityGroupsUrl,
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const method = "GET"
                const securityGroup_response = await openstackRequest({
                    url: `${neutronServiceDomain}/${neutronServiceVersion}/${addressGroupsUrl}`, 
                    method: method, 
                    token: project_token
                })
                if (securityGroup_response.status_code === securityUrlResponses.get.success_response.status_code) {
                    setAddressGroups(securityGroup_response.data.address_groups)
                }
            }
        })();
    },[
        neutronServiceDomain,
        neutronServiceVersion,
        addressGroupsUrl,
        defaultAdminProject
    ]);

    useEffect(() => {
        if (defaultRulesData.length > 0) {
            handleDefaultRulesDataFormatting()
        }
    },[
        defaultRulesData,
        handleDefaultRulesDataFormatting
    ])

    useEffect(() => {
        let sec_groups_filter = []
        if (securityGroups.length > 0) {
            sec_groups_filter = securityGroups.map(sg => {
                const project = projects.filter(pj => pj.id === sg.project_id)
                return {keyword: `${project[0].name}: ${sg.name}`, value: sg.id, default: false}
            })
        }

        let addr_groups_filter = []
        if (addressGroups.length > 0) {
            addr_groups_filter = addressGroups.map(ag => {
                return {keyword: ag.name, value: ag.id, default: false}
            })
        }

        
        let defaultRule_filter_menu = defaultRulesFilterMenu.map(nt => {
            let new_item = {...nt}
            if (nt.value === "remote_group_id") {
                new_item.items = [...sec_groups_filter]
            } else if (nt.value === "remote_address_group_id") {
                new_item.items = [...addr_groups_filter]
            }
            return new_item
        })

        setDefaultRulesFilter(defaultRule_filter_menu)
    },[
        defaultRulesData,
        securityGroups,
        addressGroups,
        projects
    ])

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <DefaultRulesSubheaderV20 
                selectedFilter={selectedDefaultRuleFilter} 
                setSelectedFilter={setSelectedDefaultRuleFilter}
                selectedFilterValue={selectedDefaultRuleFilterValue}
                setSelectedFilterValue={setSelectedDefaultRuleFilterValue}
                handleDataFetch={handleDataFetch}
                filterMenu={defaultRulesFilter}
                handleFilteredSearch={handleDefaultRuleFilteredSearch}
                handleFilterReset={handleDefaultRuleFilterReset}
                currentTab={currentTab}
                handleTabChange={handleTabChange}
                projects={projects}
            />
        {isLoading && <CustomBackdrop open={isLoading} />}
        {!isLoading &&
            <DefaultRulesTableV20 
                defaultRulesData={defaultRules}
                setDefaultRulesData={setDefaultRules}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getDefaultRulesActionsList()}
                sortHandler={handleDefaultRulesSorting}
                projects={projects}
            />
        }
        <CustomDialog
            open={defaultRuleDeleteConfirmDialogOpen}
            onClose={handleDefaultRuleDeleteConfirmDialogClose}
            dialogTitle={{
                title: defaultTexts.defaultRuleDeleteConfirmTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `${defaultTexts.defaultRuleDeleteConfirmText}`, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onDefaultRuleDelete, 
                sx: {color: 'primary.main'}}]}
        />
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts[error.error_title], 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts[error.error_message]}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
    </Box>
    )
};

export default DefaultRulesWrapperV20;