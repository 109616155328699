export const networksNeutronTexts = {
    submenuL2Networks: "L2 Networking",
    submenuL3Networks: "L3 Networking",
    submenuSecurity: "Security",
    submenuFWaaS: "FWaaS",
    submenuVPNaaS: "VPNaaS",
    submenuQoS: "QoS",
    submenuNetworkFlavors: "Network Flavors",
    submenuNetworkLogging: "Logging",
    submenuNetworkLimits: "Limits",
    submenuNetworkTopology: "Topology",
    adminStatusFormFieldLabel: "Admin State",
    upStateFormFieldLabel: "Up",
    downStateFormFieldLabel: "Down",
    addressScopesFormFieldLabel: "Address Scopes",
    networkTypeFormFieldLabel: "Network Type",
    segmentFormFieldLabel: "Segment (VLAN,GRE)",
    externalFormFieldLabel: "External",
    sharedFormFieldLabel: "Shared",
    isDefaultNetworkFormFieldLabel: "Is Default",
    mtuFormFieldLabel: "MTU",
    portSecurityEnabledFormFieldLabel: "Port Security Enabled",
    revisionNumberFormFieldLabel: "Revision Number",
    subportsNumberFormFieldLabel: "Subports",
    qosPolicyFormFieldLabel: "QoS Policy",
    addNetworkDialogTitle: "Add New Network",
    networksTabButtonText: "Networks",
    segmentsTabButtonText: "Segments",
    portsTabButtonText: "Ports",
    trunksTabButtonText: "Trunks",
    flatNetworkChoiceTitle: "Flat Network",
    vlanChoiceTitle: "VLAN",
    vxlanChoiceTitle: "VXLAN",
    greChoiceTitle: "GRE",
    localChoiceTitle: "Local",
    geneveChoiceTitle: "Geneve",
    dnsDomainFormFieldLabel: "DNS Domain",
    defaultNetworkFormFieldLabel: "Default Network",
    adminStateUpFormFieldLabel: "Admin Up",
    externalNetworkFormFieldLabel: "External Network",
    sharedNetworkFormFieldLabel: "Shared Network",
    vlanTransparentFormFieldLabel: "Vlan Transparent",
    segmentationIDFormFieldLabel: "Segmentation ID",
    adminStateFormFieldLabel: "Admin Up",
    networkDeleteConfirmTitle: "Delete Network",
    networkDeleteConfirmText: "Are you sure you want to delete selected networks? This action can not be reverted. Selected networks",
    networkDeleteActionTitle: "Delete network",
    supportSegmentationCheckbox: "Support Segmentation",
    segmentFormFieldTitle: "Segment",
    networkSegmentsTitleTexts: "Network Segments",
    physicalNetworkFormFieldLabel: "Physical Network",
    networkUpdateAdminStateActionTitle: "Update admin state",
    networkUpdateTitle: "Update Network",
    networkUpdatePortSecurityActionTitle: "Enable/disable port security",
    networkUpdateSharedStatusActionTitle: "Update shared status",
    networkUpdateExternalStatusActionTitle: "Update external status",
    networkUpdateDefaultStatusActionTitle: "Update default status",
    networkUpdateMTUActionTitle: "Update MTU",
    noSegmentsFormFieldLabel: "No Segment",
    networkUpdateActionTitle: "Update network",
    noSubnetsNoteText: "No Subnets",
    noSegmentsNoteText: "No Segments",
    l2AdjacencyFormFieldLabel: "L2 Adjacency",
    submenuSubnets: "Subnets",
    submenuNetworkSegments: "Segments",
    updateNetworkActionTitle: "Update Network Details",
    networkSubnetsTitleTexts: "Network Subnets",
    ipVersionFormFieldLabel: "IP Version",
    cidrFormFieldLabel: "CIDR",
    gatewayFormFieldLabel: "Gateway IP",
    dhcpEnabledFormFieldLabel: "DHCP Enabled",
    propagateUplinkStatusFormFieldLabel: "Propagate Uplink Status",
    macLearningEnabledFormFieldLabel: "MAC Learning Enabled",
    ipAddressOrSubnetFormFieldLabel: "Specify IP address or subnet",
    addPortDialogTitle: "Add New Port",
    dnsNameFormFieldLabel: "DNS Name",
    securityGroupsFormFieldLabel: "Security Groups",
    invalidIPAddressErrorText: "Invalid IP Address",
    normalVnicTypeChoiceText: "Normal",
    directVnicTypeChoiceText: "Direct",
    directPhysicalVnicTypeChoiceText: "Direct Physical",
    macVTapVnicTypeChoiceText: "MacVTap",
    bareMetalVnicTypeChoiceText: "Bare Metal",
    virtioForwarderVnicTypeChoiceText: "Virtio Forwarder",
    smartNicVnicTypeChoiceText: "Smart NIC",
    remoteManagedVnicTypeChoiceText: "Remote Managed",
    vnicTypeFormFieldLabel: "VNIC Type",
    portUpdateAdminStateActionTitle: "Update admin state",
    portUpdatePortSecurityActionTitle: "Update port security",
    portDeleteActionTitle: "Delete port",
    portUpdateSecurityGroupsActionTitle: "Update security groups",
    portDeleteConfirmTitle: "Confirm Port Delete",
    portDeleteConfirmText: "Are you sure, you want to delete selected ports? This action can not be reverted",
    portUpdateTitle: "Update Port Details",
    updatePortActionTitle: "Update port",
    deviceOwnerFormFieldLabel: "Device Owner",
    submenuSecGroups: "Security Groups",
    submenuAllowedAddresses: "Allowed Addresses",
    portUpdateActionTitle: "Update Port",
    noSecGroupsNoteText: "No Security Groups",
    noAddressPairsNoteText: "No Address Pairs",
    updateAllowedAddressesActionTitle: "Update Allowed Addresses",
    allowedAddressesFormFieldLabel: "Add Allowed Address Pairs",
    allowedAddressPairsTableTitle: "Allowed Address Pairs",
    updateIPAddressActionTitle: "Update IP Address",
    gatewayIpFormFieldLabel: "Gateway IP",
    enableDhcpFormFieldLabel: "Enable DHCP",
    dnsNameserversFormFieldLabel: "DNS Nameservers",
    addSubnetDialogTitle: "Add New Subnet",
    subnetsTabButtonText: "Subnets",
    floatingIPsTabButtonText: "Floating IPs",
    routersTabButtonText: "Routers",
    virtualIPsTabButtonText: "Virtual IPs",
    ipv4FormFieldLabel: "IPv4",
    ipv6FormFieldLabel: "IPv6",
    ipv6AddressModeFormFieldLabel: "IPv6 Address Mode",
    notSelectedFormFieldLabel: "None",
    slaacFormFieldLabel: "SLAAC",
    dhcpv6StatefullFormFieldLabel: "DHCPv6 Statefule",
    dhcpv6StatelessFormFieldLabel: "DHCPv6 Stateless",
    ipv6RouterModeFormFieldLabel: "IPv6 Router Mode",
    destinationFormFieldLabel: "Destination CIDR",
    nextHopFormFieldLabel: "Next Hop",
    startFormFieldLabel: "Start Address",
    endFormFieldLabel: "End Address",
    allocationPoolFormFieldLabel: "Allocation Pool",
    addAllocationPoolFormFieldLabel: "Add Allocation Pool",
    dnsNameserverFormFieldLabel: "DNS Nameserver",
    addDnsNameserverFormFieldLabel: "Add DNS Nameserver",
    hostRouteFormFieldLabel: "Host Route",
    hostRoutesFormFieldLabel: "Host Routes",
    addHostRouteFormFieldLabel: "Add Host Route",
    disableGatewayFormFieldLabel: "Disable Gateway",
    subnetDeleteActionTitle: "Delete subnet",
    subnetDeleteConfirmTitle: "Confirm Subnet Delete",
    subnetDeleteConfirmText: "Are you sure, you want to delete selected subnets? This action can not be reverted. Selected subnets",
    fullSubnetFormFieldLabel: "Full Subnet",
    allocationPoolsFormFieldLabel: "Allocation Pools",
    subnetUpdateActionTitle: "Update subnet",
    subnetUpdateAllocationPoolsActionTitle: "Update allocation pools",
    subnetUpdateDNSNameserversActionTitle: "Update DNS nameservers",
    subnetUpdateHostRoutesActionTitle: "Update host routes",
    subnetDisableDhcpActionTitle: "Disable DHCP",
    subnetEnableDhcpActionTitle: "Enable DHCP",
    subnetUpdateGatewayIPActionTitle: "Update gateway IP",
    updateSubnetActionTitle: "Update Subnet Details",
    updateAllocationPoolsActionTitle: "Update Allocation Pools",
    disableGatewayIPFormFieldLabel: "Disable Gateway IP",
    invalidCIDRErrorText: "Invalid CIDR provided",
    invalidPrefixLengthErrorText: "Invalid Prefix Length for selecte IP Version",
    mappedLocalIPFormFieldLabel: "Mapped Local IP Address",
    portForwardingFormFieldLabel: "Port Forwarding",
    poolFormFieldLabel: "Pool",
    specificAddressFormFieldLabel: "Specific Address",
    subnetFormFieldLabel: "Subnet",
    activeStatusText: "Active",
    downStatusText: "Down",
    errorStatusText: "Error",
    floatingIPDeleteConfirmTitle: "Confirm Floating IP Release",
    floatingIPDeleteConfirmText: "Are you sure, you want to release selected floating IPs? Selected floating IPs",
    floatingIPDeleteActionTitle: "Release Floating IP",
    associatedWithFormFieldLabel: "Associated With",
    mappedLocalIPSortFieldLabel: "Mapped IP Address",
    portStatusFormFieldLabel: "Port Status",
    addFloatingIPDialogTitle: "Allocation Floating IP to Project",
    submenuPortForwardings: "Port Forwardings",
    noPortForwardingsNoteText: "No Port Forwardings",
    tcpFormFieldLabel: "TCP",
    udpFormFieldLabel: "UDP",
    startInternalPortFormFieldLabel: "Start Internal Port",
    endInternalPortFormFieldLabel: "End Internal Port",
    startExternalPortFormFieldLabel: "Start External Port",
    endExternalPortFormFieldLabel: "End External Port",
    createPortForwardingActionTitle: "Creat Port Forwarding",
    internalIPAddressFormFieldLabel: "Internal IP Address",
    protocolFormFieldLabel: "Protocol",
    disassociateFloatingIPActionTitle: "Disassociate Floating IP",
    associateFloatingIPActionTitle: "Associate Floating IP",
    enableSNatFormFieldLabel: "Enable SNAT",
    externalFixedIPFormFieldLabel: "External Fixed IP",
    routerDeleteConfirmText: "Are you sure, you want to delete selected routers? This action can not be reverted. Selected routers",
    routerDeleteConfirmTitle:"Confirm Router Delete",
    routerDeleteActionTitle: "Delete Router",
    addRouterDialogTitle: "Add New Router",
    staticRouteFormFieldLabel: "Static Route",
    addStaticRouteFormFieldLabel: "Add Static Route",
    changeAdminStateActionTitle: "Change Admin State",
    updateRouterAdminStateActionTitle: "Change Router Admin State",
    routerAdminStateFormFieldLabel: "Admin State",
    upFormFieldLabel: "Up",
    downFormFieldLabel: "Down",
    routerInterfacesTitleText: "Router Interfaces",
    submenuStaticRoutes: "Static Routes",
    staticRoutesTitleText: "Static Routes",
    routerUpdateActionTitle: "Update Router",
    routerUpdateStaticRoutesActionTitle: "Update Static Routes",
    updateRouterActionTitle: "Update Router Details",
    addRouterInterfaceActionTitle: "Add Router Interface",
    statefulFormFieldLabel: "Stateful",
    securityGroupsNumberFormFieldLabel: "Security Groups",
    securityGroupFormFieldLabel: "Security Group",
    directionFormFieldLabel: "Direction",
    icmpFormFieldLabel: "ICMP",
    anyFormFieldLabel: "ANY",
    otherProtocolFormFieldLabel: "Other Protocol",
    protocolNumberFormFieldLabel: "Protocol Number",
    fromPortFormFieldLabel: "From Port",
    toPortFormFieldLabel: "To Port",
    remoteFormFieldLabel: "Remote",
    addressGroupFormFieldLabel: "Address Group",
    ipPrefixFormFieldLabel: "IP Prefix",
    remoteSecurityGroupFormFieldLabel: "Remote Security Group",
    remoteAddressGroupFormFieldLabel: "Remote Address Group",
    remoteIPPrefixFormFieldLabel: "Remote IP Prefix CIDR",
    usedInDefSGFormFieldLabel: "In Default Groups",
    usedInNonDefSGFormFieldLabel: "In Non Default Groups",
    portsFormFieldLabel: "Ports",
    addressesFormFieldLabel: "Addresses",
    addSecurityGroupDialogTitle: "Add Security Group",
    securityGroupsTabButtonText: "Security Groups",
    addressGroupsTabButtonText: "Address Groups",
    defaultRulesTabButtonText: "Default Rules",
    submenuSecGroupRules: "Rules",
    securityGroupDeleteConfirmTitle: "Confirm Security Group Delete",
    securityGroupDeleteConfirmText: "Are you sure, you want to delete selected security groups? This action can not be reverted. Selected security groups",
    securityGroupDeleteActionTitle: "Delete Security Group",
    changeStatefulStateTitle: "Change Stateful State",
    changeStatefulStateActionTitle: "Change Stateful State",
    statefuleFormFieldLabel: "Stateful",
    securityGroupRulesTitleText: "Security Group Rules",
    ethertypeFormFieldLabel: "Ethertype",
    securityGroupUpdateActionTitle: "Update security group",
    updateSecurityGroupActionTitle: "Update Security Group",
    addSecGroupRuleActionTitle: "Add rule",
    addSecurityGroupRuleActionTitle: "Add Security Group Rule",
    ingressFormFieldLabel: "Ingress",
    egressFormFieldLabel: "Egress",
    icmpTypeFormFieldLabel: "ICMP Type",
    icmpCodeFormFieldLabel: "ICMP Code",
    invalidPortRangeErrorText: "Invalid Port Range Provided",
    addAddressGroupDialogTitle: "Add New Address Group",
    addAddressFormFieldLabel: "Add Address",
    addressFormFieldLabel: "Address CIDR",
    addressGroupDeleteConfirmTitle: "Confirm Address Group Delete",
    addressGroupDeleteConfirmText: "Are you sure, you want to delete selected address groups?",
    addressGroupUpdateActionTitle: "Update Address Group",
    addressRemoveActionTitle: "Remove Address",
    removeAddressFromAGActionTitle: "Remove Address from Group",
    addressGroupDeleteActionTitle: "Delete Address Group",
    parentSecurityGroupFormFieldLabel: "PARENT",
    addDefaultRuleDialogTitle: "Add New Default Rule",
    defaultRuleDeleteConfirmText: "Are you sure, you want to delete selected rules?",
    defaultRuleDeleteConfirmTitle: "Confirm Delete Default Rule",
    defaultRuleDeleteActionTitle: "Delete default rule",
    ingressFWPolicyFormFieldLabel: "Ingress Policy",
    egressFWPolicyFormFieldLabel: "Egress Policy",
    portsCountFormFieldLabel: "Ports Count",
    addFirewallGroupDialogTitle: "Add New Firewall Group",
    firewallGroupsTabButtonText: "Firewall Groups",
    firewallPoliciesTabButtonText: "Firewall Policies",
    firewallRulesTabButtonText: "Firewall Rules",
    firewallGroupDeleteConfirmText: "Are you sure you want to delete selected firewall groups?",
    firewallGroupDeleteConfirmTitle: "Delete Firewall Group",
    firewallGroupDeleteActionTitle: "Delete Firewall Group",
    submenuFWIngressRules: "Ingress Rules",
    submenuFWEgressRules: "Egress Rules",
    submenuFWPorts: "Ports",
    updateFirewallGroupActionTitle: "Update Firewall Group",
    firewallGroupUpdateActionTitle: "Update FW Group",
    fwSourceFormFieldLabel: "Source",
    fwDestinationFormFieldLabel: "Destination",
    actionFormFieldLabel: "Action",
    sourcePortsFormFieldLabel: "Source Ports",
    destinationPortsFormFieldLabel: "Destination Ports",
    firewallGroupIngressRulesTitleText: "Firewall Group Ingress Rules",
    firewallGroupEgressRulesTitleText: "Firewall Group Egress Rules",
    fwGroupAppliedPortsTitleText: "Ports Using Firewall Group",
    adminStateChangeActionTitle: "Admin State Update",
    shareStateChangeActionTitle: "Shared State Update",
    changeAdminStateTitle: "Change Admin State",
    changeSharedStateTitle: "Change Shared State",
    updateFWGroupPoliciesActionTitle: "Update FW Group Policies",
    noPolicyFormFieldLabel: "No Policy",
    fwGroupAddToPortActionTitle: "Apply FW Group to Port",
    auditedFormFieldLabel: "Audited",
    changeAuditedStateTitle: "Change Audited State",
    auditedStateChangeActionTitle: "Change Audited State",
    auditedStateFormFieldLabel: "Audited",
    fwPolicypDeleteConfirmTitle: "Confirm FW Policy Delete",
    fwPolicyDeleteConfirmText: "Are you sure, you want to delete seleted policies? Selected policies",
    firewallPolicyDeleteActionTitle: "Delete FW Policy",
    addFirewallPolicyDialogTitle: "Add New FW Policy",
    submenuFWPolicyRules: "Policy Rules",
    firewallPolicyRulesTitleText: "Firewall Policy Rules",
    firewallPolicyUpdateActionTitle: "Update FW Policy",
    updateFirewallPolicyActionTitle: "Update FW Policy Details",
    fwPolicyAddRuleActionTitle: "Add FW Rules to Policy",
    firewallPoicyFormFieldLabel: "FW Policy",
    allowActionFormFieldLabel: "Allow",
    denyActionFormFieldLabel: "Deny",
    sourceAddressFormFieldLabel: "Source Address",
    sourceFWGroupFormFieldLabel: "Source Firewall Group",
    destinationAddressFormFieldLabel: "Destination Address",
    destinationFWGroupFormFieldLabel: "Destination Firewall Group",
    sourcePortFormFieldLabel: "Source Port",
    destinationPortFormFieldLabel: "Destination Port",
    addFirewallRuleDialogTitle: "Add New Firewall Rule",
    firewallGroupFormFieldLabel: "Firewall Group",
    sourceFirewallGroupFormFieldLabel: "Source Firewall Group",
    sourceFromPortFormFieldLabel: "Source From Port",
    sourceToPortFormFieldLabel: "Source To Port",
    destinationTypeFormFieldLabel: "Destination",
    destinationFirewallGroupFormFieldLabel: "Destination Firewall Group",
    destinationIPAddressFormFieldLabel: "Destination IP Address",
    destinationFromPortFormFieldLabel: "Destination From Port",
    destinationToPortFormFieldLabel: "Destination To Port",
    allowFormFieldLabel: "Allow",
    denyFormFieldLabel: "Deny",
    sourceIPAddressFormFieldLabel: "Source IP Address",
    enableDisableRuleTitle: "Enable/Disable Rule",
    enableDisableRuleActionTitle: "Enable/Disable Rule",
    firewallRuleDeleteActionTitle: "Delete Rule",
    firewallRuleDeleteConfirmTitle: "Confirm Rule Delete",
    firewallRuleDeleteConfirmText: "Are you sure, you want to delete selected firewall rules?",
    firewallRuleUpdateActionTitle: "Update FW Rule",
    updateFirewallRuleActionTitle: "Update Firewall Rule",
    fwGRAddPortNoteText: "Note, Firewall Group can be applied only to router ports",
    ikeVersionFormFieldLabel: "IKE Version",
    authAlgorithmFormFieldLabel: "Auth Algorithm",
    encryptionAlgorithmFormFieldLabel: "Encryption Algorithm",
    pfsGroupFormFieldLabel: "PFS Group",
    negotiationModeFormFieldLabel: "Negotiation Mode",
    lifetimeFormFieldLabel: "Lifetime",
    transformProtocolFormFieldLabel: "Transform Protocol",
    encapsulationModeFormFieldLabel: "Encapsulation Mode",
    mainModeFormFieldLabel: "Main",
    agrassiveModeFormFieldLabel: "Aggrasive",
    externalIPv4AddressFormFieldLabel: "External IPv4 Address",
    externalIPv6AddressFormFieldLabel: "External IPv6 Address",
    endpointsFormFieldLabel: "Endpoints",
    endpointFormFieldLabel: "Endpoint",
    peerAddressFormFieldLabel: "Peer Address",
    ikePolicyFormFieldLabel: "IKE Policy",
    ipsecPolicyFormFieldLabel: "IPsec Policy",
    vpnServiceFormFieldLabel: "VPN Service",
    localEndpointGroupFormFieldLabel: "Local Endpoint Group",
    localIDFormFieldLabel: "Local ID",
    peerIDFormFieldLabel: "Peer ID",
    pskFormFieldLabel: "PSK",
    initiatorFormFieldLabel: "Initiator",
    responseOnlyFormFieldLable: "Response Only",
    dpdActionFormFieldLabel: "DPD Action",
    holdActionFormFieldLable: "Hold",
    clearActionFormFieldLable: "Clear",
    restartActionFormFieldLable: "Restart",
    disabledActionFormFieldLable: "Disabled",
    restartByPeerActionFormFieldLable: "Restart by peer",
    ikePolicyDeleteConfirmTitle: "Confirm IKE Policy Delete",
    ikePolicyDeleteConfirmText: "Are you sure, you want to delete selected IKE Policies?",
    ikePolicyDeleteActionTitle: "Delete IKE Policy",
    ikePoliciesTabButtonText: "IKE Policies",
    ipsecPoliciesTabButtonText: "IPsec Policies",
    vpnServicesTabButtonText: "VPN Services",
    endpointGroupsTabButtonText: "Endpoint Groups",
    siteConnectionsTabButtonText: "Site Connections",
    addIkePolicyDialogTitle: "Add IKE Policy",
    ipsecPolicyDeleteActionTitle: "Delete IPsec Policy",
    ipsecPolicyDeleteConfirmTitle: "Confirm IPsec Policy Delete",
    ipsecPolicyDeleteConfirmText: "Are you sure, you want to delete selected IPsec Policies?",
    addIPsecPolicyDialogTitle: "Add New IPsec Policy",
    vpnServiceDeleteConfirmTitle: "Confirm VPN Service Delete",
    vpnServiceDeleteConfirmText: "Are you sure, you want to delete selected VPN services?",
    vpnServiceDeleteActionTitle: "Delete VPN Service",
    addVPNServiceDialogTitle: "Add New VPN Service",
    routerFormFieldLabel: "Router",
    addCidrFormFieldLabel: "Add CIDR to list",
    addVPNEndpointGroupDialogTitle: "Add New Endpoint Group",
    addSubnetFormFieldLabel: "Add Subnet",
    vpnEndpointGroupDeleteConfirmTitle: "Delete Endpoint Group",
    vpnEndpointGroupDeleteConfirmText: "Are you sure you want to delete selected endpoint groups?",
    vpnEndpointGroupDeleteActionTitle: "Delete Endpoint Group",
    vpnEndpointGroupUpdateActionTitle: "Update Endpoint Group",
    updateEndpointGroupNameTitle: "Update Endpoint Group Name",
    vpnEndpointGroupUpdateNameActionTitle: "Update Name",
    updateEndpointGroupDescriptionTitle: "Update Endpoint Group Description",
    vpnEndpointGroupUpdateDescriptionActionTitle: "Update Description",
    updateIkePolicyNameTitle: "Update IKE Policy Name",
    ikePolicyUpdateNameActionTitle: "Update Name",
    ikePolicyUpdateDescriptionActionTitle: "Update Description",
    updateIkePolicyDescriptionTitle: "Update IKE Policy Description",
    ikePolicyUpdateAuthAlgActionTitle: "Update Auth Algorithm",
    updateIkePolicyAuthAlgTitle: "Update Auth Algorithm",
    updateIkePolicyEncrAlgTitle: "Update Encryption Algorithm",
    updateIkePolicyPFSGroupTitle: "Update PFS Group",
    updateIkePolicyIKEVersionTitle: "Update IKE Version",
    ikePolicyUpdateEncrAlgActionTitle: "Update Encryption Algorithm",
    ikePolicyUpdatePFSGroupActionTitle: "Update PFS Group",
    ikePolicyUpdateIKEVersionActionTitle: "Update IKE Version",
    updateIPsecPolicyEncapModeTitle: "Update Encapsulation Mode",
    ipsecPolicyUpdateEncapModeActionTitle: "Update Encapsulation Mode",
    ipsecPolicyUpdateTransProtoActionTitle: "Update Transform Protocol",
    ipsecPolicyUpdateNameActionTitle: "Update Name",
    ipsecPolicyUpdateDescriptionActionTitle: "Update Description",
    ipsecPolicyUpdateAuthAlgActionTitle: "Update Auth Algorithm",
    ipsecPolicyUpdateEncrAlgActionTitle: "Update Encryption Algorithm",
    ipsecPolicyUpdatePFSGroupActionTitle: "Update PFS Group",
    updateIPsecPolicyNameTitle: "Update IPsec Policy Name",
    updateIPsecPolicyDescriptionTitle: "Update IPsec Policy Description",
    updateIPsecPolicyAuthAlgTitle: "Update IPsec Policy Auth Algorithm",
    updateIPsecPolicyEncrAlgTitle: "Update IPsec Policy Encryption Algorithm",
    updateIPsecPolicyPFSGroupTitle: "Update IPsec Policy PFs Group",
    updateVPNServiceNameTitle: "Update VPN Service Name",
    updateVPNServiceDescriptionTitle: "Update VPN Service Description",
    vpnServiceUpdateNameActionTitle: "Update Name",
    vpnServiceUpdateDescriptionActionTitle: "Update Description",
    vpnServiceUpdateAdminUpActionTitle: "Update Admin State",
    updateVPNServiceAdminUpTitle: "Update Admin State",
    bidirectionalFormFieldLable: "Bi-directional",
    peerEndpointGroupFormFieldLabel: "Peer Endpoint Group",
    addVPNSiteConnectionDialogTitle: "Add New VPN Site Connection",
    peerCIDRFormFieldLabel: "Peer CIDR comma-separated (Deprecated)",
    peerCIDRsFormFieldLabel: "Peer CIDRs",
    dpdFormFieldLabel: "DPD",
    dpdIntervalFormFieldLabel: "Interval",
    dpdTimeoutFormFieldLabel: "Timeout",
    authModeFormFieldLabel: "Auth Mode",
    routeModeFormFieldLabel: "Route Mode",
    vpnSiteConnectionUpdateActionTitle: "Update Site Connection",
    vpnSiteConnectionDeleteActionTitle: "Delete Site Connection",
    vpnSiteConnectionUpdateTitle: "Update Site Connection Details",
    vpnSiteConnectionDeleteConfirmTitle: "Confirm Site Connection Delete",
    vpnSiteConnectionDeleteConfirmText: "Are you sure, you want to delete selected site connections?",
    ipModeFormFieldLabel: "IP Mode",
    translateModeChoiceFormField: "Translate (DNAT)",
    passthroughModeChoiceFormField: "Passthrough (no DNAT)",
    addVirtualIPDialogTitle: "Add New Virtual IP",
    virtualIPDeleteConfirmText: "Are you sure, you want to delete selected virtual IPs?",
    virtualIPDeleteConfirmTitle: "Confirm Virtual IPs Delete",
    virtualIPDeleteActionTitle: "Delete Virtual IP",
    disassociateVirtualIPActionTitle: "Disassociate from port",
    associateVirtualIPActionTitle: "Associate with Port",
    updateVirtualIPActionTitle: "Update details",
    submenuPortAssociations: "Port Associations",
    noVirtualIPPortAssociationsText: "No Associations",
    localPortFormFieldLabel: "Local Port",
    virtualIPAssociationsTitleText: "Virtual IP Associations",
    portTypeFormFieldLabel: "Port Type",
    serverInstanceFormFieldLabel: "Server Instance",
    fixedIPAddressFormFieldLabel: "Fixed IP Address",
    portFormFieldLabel: "Port",
    vlanFormFieldLabel: "Vlan",
    vxlanFormFieldLabel: "Vxlan",
    flatFormFieldLabel: "Flat",
    greFormFieldLabel: "GRE",
    vlanIDFormFieldLabel: "Vlan ID",
    greKeyFormFieldLabel: "GRE Key",
    parentPortFormFieldLabel: "Parent Port",
    activeStatusFormChoiceText: "Active",
    downStatusFormChoiceText: "Down",
    buildStatusFormChoiceText: "Build",
    degradedStatusFormChoiceText: "Degraded",
    errorStatusFormChoiceText: "Error",
    segmentDeleteConfirmTitle: "Confirm Segment Delete",
    segmentDeleteConfirmText: "Are you sure, you want to delete selected segments?",
    segmentUpdateTitle: "Update Segment Details",
    segmentUpdateAdminStateActionTitle: "Update Admin State",
    segmentDeleteActionTitle: "Delete Segment",
    addSegmentDialogTitle: "Add New Segment",
    segmentUpdateActionTitle: "Update Segment Details",
    updateSegmentActionTitle: "Update Segment",
    trunkDeleteConfirmTitle: "Confirm Trunk Delete",
    trunkDeleteConfirmText: "Are you sure, you want to delete selected trunks?",
    addTrunkDialogTitle: "Add New Trunk",
    submenuSubPorts: "Subports",
    noSubPortsNoteText: "No Subports",
    trunkUpdateActionTitle: "Update Trunk",
    subportsTableTitle: "Subports",
    segmentationTypeFormFieldLabel: "Segmentation Type",
    subPortsFormFieldLabel: "Subports",
    trunkUpdateAdminStateActionTitle: "Update Admin State",
    trunkDeleteActionTitle: "Delete Trunk",
    trunkAdminStateUpdateTitle: "Update Trunk Admin State",
    updateTrunkActionTitle: "Update Trunk Details",
    trunkAddSubportActionTitle: "Add Subport to Trunk",
    trunkRemoveSubportActionTitle: "Remove Subport from Trunk",
    unlimitedValueText: "Unlimited",
    resourceNameFormFieldLabel: "Resource Name",
    usedResourceQuotaFormFieldLabel: "Used",
    availableResourceQuotaFormFieldLabel: "Available",
    maxResourceQuotaFormFieldLabel: "Max",
    updateLimitsButtonText: "Update Limits",
    updateLimitsActionTitle: "Update Limits",
    updateNeutronLimitsActionText: "Provide project quota limits for below network resources. If you want to specify unlimited quota to any resource, provide -1 in appropriate field.",
    trunkFormFieldLabel: "Trunk",
    floatingIPFormFieldLabel: "Floating IP",
    securityGroupRuleFormFieldLabel: "Security Group Rule",
    firewallPolicyFormFieldLabel: "Firewall Policy",
    firewallRuleFormFieldLabel: "Firewall Rule",
    endpointGroupFormFieldLabel: "Endpoint Group",
    ipsecSiteConnectionFormFieldLabel: "IPsec Site Connection",
    resetLimitsDefaultButtonText: "Reset to Default",
    resetToDefaultLimitsConfirmTitle: "Confirm Limits Reset to Default",
    resetToDefaultLimitsConfirmText: "Are you sure, you want to reset all quota limits to system defaults?",
    invalidDNSDomainErrorText: "Invalid DNS domain provided. It should end with '.' f.e. example.com."
}