import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import MuiStack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import CustomDrawer from '../_common/CustomDrawer';
import CustomText from '../_common/CustomText';
import CustomCard from '../_common/CustomCard';
import Constants from '../../config/constants';
import request from '../../_network/request';
import { coreServiceURLs } from '../../_network/apiUrls';
import Stack from '@mui/material/Stack';
import { Divider } from '@mui/material';

const CustomersSupportWrapper = (props) => {
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const clientID = useSelector(state => state.profile.clientAccountID)
    const [projectRequestAlerts, setProjectRequestAlerts] = useState([]);
    const [limitsUpdateAlerts, setLimitsUpdateAlerts] = useState([]);
    const [issueReportAlerts, setIssueReportAlerts] = useState([]);
    const [projectRequestAlertsLoading, setProjectRequestAlertsLoading] = useState(true)
    const [limitsUpdateAlertsLoading, setLimitsUpdateAlertsLoading] = useState(true)
    const [issueReportAlertsLoading, setIssueReportAlertsLoading] = useState(true)

    const theme = useTheme();
    const CARD_HEIGHT = "80vh"

    const handleProjectRequestAlertsLoading = (mode) => {
        setProjectRequestAlertsLoading(mode)
    }

    const handleLimitsUpdateAlertsLoading = (mode) => {
        setLimitsUpdateAlertsLoading(mode)
    }

    const handleIssueReportAlertsLoading = (mode) => {
        setIssueReportAlertsLoading(mode)
    }

    const CustomSkeleton = () => {
        return (
            <Grid container spacing={2} 
                justifyContent="space-around" 
                alignItems="center" 
                sx={{mt: 3}}
            >
                <Grid item>
                    <MuiStack>
                        <Skeleton width={260} height={40}>
                            <Typography>.</Typography>
                        </Skeleton>
                        <Skeleton width={260} height={40}>
                            <Typography>.</Typography>
                        </Skeleton>
                        <Skeleton width={260} height={40}>
                            <Typography>.</Typography>
                        </Skeleton>
                        <Skeleton width={260} height={40}>
                            <Typography>.</Typography>
                        </Skeleton>
                    </MuiStack>
                </Grid>
            </Grid>
        )
    }

    useEffect(() => {
        (async () => {
            handleProjectRequestAlertsLoading(true)
            const url = `${Constants.core_service_domain}/${coreServiceURLs.getOpenstackAlerts}/?type=${Constants.alert_type_project_request}`
            let headers = {}
            headers[Constants.core_service_account_id_header] = clientID
            let request_data = {
                url: url,
                method: "GET",
                headers: headers
            }
            const res = await request(request_data)
            if (res.status_code === 200) {
                setProjectRequestAlerts(res.data)
            }
            setTimeout(()=>{handleProjectRequestAlertsLoading(false)},200)
        })()
    },[clientID]);

    useEffect(() => {
        (async () => {
            handleLimitsUpdateAlertsLoading(true)
            const url = `${Constants.core_service_domain}/${coreServiceURLs.getOpenstackAlerts}/?type=${Constants.alert_type_limits_update}`
            let headers = {}
            headers[Constants.core_service_account_id_header] = clientID
            let request_data = {
                url: url,
                method: "GET",
                headers: headers
            }
            const res = await request(request_data)
            if (res.status_code === 200) {
                setLimitsUpdateAlerts(res.data)
            }
            setTimeout(()=>{handleLimitsUpdateAlertsLoading(false)},200)
        })()
    },[clientID]);

    useEffect(() => {
        (async () => {
            handleIssueReportAlertsLoading(true)
            const url = `${Constants.core_service_domain}/${coreServiceURLs.getOpenstackAlerts}/?type=${Constants.alert_type_issue_report}`
            let headers = {}
            headers[Constants.core_service_account_id_header] = clientID
            let request_data = {
                url: url,
                method: "GET",
                headers: headers
            }
            const res = await request(request_data)
            if (res.status_code === 200) {
                setIssueReportAlerts(res.data)
            }
            setTimeout(()=>{handleIssueReportAlertsLoading(false)},200)
        })()
    },[clientID]);

    const DashboardCard = (props) => {
        const { cardTitle } = props
        return (
            <CustomCard
                cardWidth={'100%'} 
                cardHeight={CARD_HEIGHT}
                cardTitle={cardTitle}
                titleSize="p"
                titleColor="primary.main"
                card_sx={{
                    border: theme.palette.mode === "dark" ? 0 : 1, 
                    boxShadow: theme.palette.mode === "dark" ? 0 : 1, 
                    backgroundColor: 
                        theme.palette.mode === "dark" ? 
                        undefined :
                        "vLightGray"
                }}
                box_sx={{pr: 2}}
            >
                {props.children}
            </CustomCard>
        )
    }

    return (
        <CustomDrawer>
            <Box>
                <Box
                    sx={{
                        borderBottomColor: theme.palette.divider,
                        borderBottom: 0.5,
                        pb: 0.5,
                        mb: 2
                    }}
                >
                    <CustomText 
                        sx={{
                            color: theme.palette.text.primary,
                            fontSize: {xs: 16, sm: 18}
                        }}>
                        {defaultTexts.customersNotificationsHeaderTitle}
                    </CustomText>
                </Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                        <DashboardCard>
                            <CustomText 
                                sx={{
                                    color: theme.palette.mode === "dark" ?
                                    theme.palette.customBlueDark :
                                    theme.palette.customBlue,
                                    fontSize: 18
                                }}>
                                {defaultTexts.issueReportAlertHeaderTitle}: {issueReportAlerts.length}
                            </CustomText>
                            {!issueReportAlertsLoading ? 
                                issueReportAlerts.length > 0 ? issueReportAlerts.map(alert => {
                                    return (
                                        <Stack spacing={2} sx={{mt: 1}} key={alert.id}>
                                            <CustomText 
                                                sx={{fontSize: 18, color: "text.secondary"}}>
                                                {alert.title}
                                            </CustomText>
                                            <CustomText 
                                                sx={{fontSize: 16}}>
                                                {alert.message}
                                            </CustomText>
                                            {Object.keys(alert.data).map(k => {
                                                return (
                                                    <Stack spacing={1} direction="row" alignItems="center" key={k}>
                                                    <CustomText 
                                                        sx={{fontSize: 16, color: "text.secondary"}}>
                                                        {k}:
                                                    </CustomText>
                                                    <CustomText 
                                                        sx={{fontSize: 16}}>
                                                        {alert.data[k]}
                                                    </CustomText>
                                                    </Stack>
                                                )
                                            })}
                                            <Divider />
                                        </Stack>
                                    )
                                }) :
                                <Box sx={{
                                    width: "100%", 
                                    height: "100%",
                                    display: "flex", 
                                    alignItems: "center", 
                                    justifyContent: "center",
                                    mt: 1
                                }}>
                                    <Box
                                        sx={{
                                            width: "80%",
                                            height: "80%",
                                            border: "1px dashed",
                                            borderRadius: 2,
                                            borderColor: "customBlue",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                    <CustomText sx={{fontSize: 20, color: "customBlue"}}>
                                        {defaultTexts.noDataNoteTitle}
                                    </CustomText>
                                    </Box>
                                </Box> :
                                <CustomSkeleton />
                            }
                        </DashboardCard>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <DashboardCard>
                            <CustomText 
                                sx={{
                                    color: theme.palette.mode === "dark" ?
                                    theme.palette.dashboardGreenDark :
                                    theme.palette.dashboardGreen,
                                    fontSize: 18
                                }}>
                                {defaultTexts.projectRequestAlertHeaderTitle}: {projectRequestAlerts.length}
                            </CustomText>
                            {!projectRequestAlertsLoading ? 
                                projectRequestAlerts.length > 0 ? projectRequestAlerts.map(alert => {
                                    return (
                                        <Stack spacing={2} sx={{mt: 1}} key={alert.id}>
                                            <CustomText 
                                                sx={{fontSize: 18, color: "text.secondary"}}>
                                                {alert.title}
                                            </CustomText>
                                            <CustomText 
                                                sx={{fontSize: 16}}>
                                                {alert.message}
                                            </CustomText>
                                            <Divider />
                                        </Stack>
                                    )
                                }) : 
                                <Box sx={{
                                    width: "100%", 
                                    height: "100%",
                                    display: "flex", 
                                    alignItems: "center", 
                                    justifyContent: "center",
                                    mt: 1
                                }}>
                                    <Box
                                        sx={{
                                            width: "80%",
                                            height: "80%",
                                            border: "1px dashed",
                                            borderRadius: 2,
                                            borderColor: "customBlue",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                    <CustomText sx={{fontSize: 20, color: "customBlue"}}>
                                        {defaultTexts.noDataNoteTitle}
                                    </CustomText>
                                    </Box>
                                </Box> :
                                <CustomSkeleton />
                            }
                        </DashboardCard>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <DashboardCard>
                            <CustomText 
                                sx={{
                                    color: theme.palette.mode === "dark" ?
                                    theme.palette.dashboardPinkDark :
                                    theme.palette.dashboardPink,
                                    fontSize: 18
                                }}>
                                {defaultTexts.limitsUpdateAlertHeaderTitle}: {limitsUpdateAlerts.length}
                            </CustomText>
                            {!limitsUpdateAlertsLoading ? 
                                limitsUpdateAlerts.length > 0 ? limitsUpdateAlerts.map(alert => {
                                    return (
                                        <Stack spacing={2} sx={{mt: 1}} key={alert.id}>
                                            <CustomText 
                                                sx={{fontSize: 18, color: "text.secondary"}}>
                                                {alert.title}
                                            </CustomText>
                                            <CustomText 
                                                sx={{fontSize: 16}}>
                                                {alert.message}
                                            </CustomText>
                                            {Object.keys(alert.data).map(k => {
                                                return (
                                                    <Stack spacing={1} direction="row" alignItems="center" key={k}>
                                                    <CustomText 
                                                        sx={{fontSize: 16,color: "text.secondary"}}>
                                                        {k}:
                                                    </CustomText>
                                                    <CustomText 
                                                        sx={{fontSize: 16}}>
                                                        {alert.data[k]}
                                                    </CustomText>
                                                    </Stack>
                                                )
                                            })}
                                            <Divider/>
                                        </Stack>
                                    )
                                }) : 
                                <Box sx={{
                                    width: "100%", 
                                    height: "100%",
                                    display: "flex", 
                                    alignItems: "center", 
                                    justifyContent: "center",
                                    mt: 1
                                }}>
                                    <Box
                                        sx={{
                                            width: "80%",
                                            height: "80%",
                                            border: "1px dashed",
                                            borderRadius: 2,
                                            borderColor: "customBlue",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                    <CustomText sx={{fontSize: 20, color: "customBlue"}}>
                                        {defaultTexts.noDataNoteTitle}
                                    </CustomText>
                                    </Box>
                                </Box> :
                                <CustomSkeleton />
                            }
                        </DashboardCard>
                    </Grid>
                </Grid>
            </Box>
        </CustomDrawer>
    )
};

export default CustomersSupportWrapper;