import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import IconButton from '@mui/material/IconButton';
import { MdDarkMode } from "react-icons/md";
import { MdLightMode } from "react-icons/md";
import { setUIMode } from '../../store/reducers/settingsSlice';

const UIModeButton = (props) => {
    const { sx } = props
    const uiMode = useSelector(state => state.settings.uiMode)
    const dispatch = useDispatch();

    const changeUIModeHandler = () => {
        if (uiMode === 'light') {
            dispatch(setUIMode({uiMode: 'dark'}))
        } else {
            dispatch(setUIMode({uiMode: 'light'}))
        }
    }

    return (
        <IconButton 
            onClick={changeUIModeHandler} 
            sx={{ 
                p: 1, 
                color: "white", 
                m: 1,
                ...sx
            }}
        >
            {uiMode === 'light' && <MdDarkMode size={28} />}
            {uiMode === 'dark' && <MdLightMode size={28} />}
        </IconButton>
    );
}

export default UIModeButton;