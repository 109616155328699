import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../../../_common/CustomTable';
import { backupDataSchema, backupSortFields } from 
'../../../../../_data/openstack/cinder/backups/v3';
import { setBackupsTableConfig } from 
'../../../../../store/reducers/blockStorageCinderSlice';

const handleTableColumnsConfig = (columns_schema,api_version) => {
    let current_api_version = api_version
    if (!api_version) {
        current_api_version = "3.0"
    }

    let schema = columns_schema.filter(f => 
        (!f.min_api_version ||  
            parseFloat(f.min_api_version) <= parseFloat(current_api_version))
    )
    return schema
}

const BackupsTableV3 = (props) => {
    const { data, setData } = props
    const { handleRowSelection } = props
    const {currentAction} = props
    const {setCurrentAction}  = props
    const { actionsTexts }  = props
    const { actionsList } = props
    const { sortHandler } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);
    
    const tableConfig = useSelector(
        state => state.blockStorageCinder.backupsTableConfig)
    const rowsPerPage = useSelector(
        state => state.blockStorageCinder.backupsTableConfig.filter(
        versions => versions.api_version === "v3")[0].rows_per_page)
    const tableHeaders = useSelector(
        state => state.blockStorageCinder.backupsTableConfig.filter(
            versions => versions.api_version === "v3")[0].columns)
    const cinderMaxApiVersion = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            versions => versions.api_version === "v3")[0].max_api_version)

    const tableColumns = handleTableColumnsConfig(backupDataSchema,cinderMaxApiVersion)
    const identityField = tableColumns.filter(
        item => item.is_id_field)[0].field_key

    const dispatch = useDispatch();

    const handleTableConfig = (value_list) => {
        let schema = []
        if (data.length > 0) {
            const v_keys = Object.keys(data[0])
            schema = backupDataSchema.filter(
                n => v_keys.includes(n.field_key))
        } else {
            schema = [...backupDataSchema]
        }
        let newTableConfig = schema.filter(
            n => value_list.includes(n.field_key))
        const new_data = tableConfig.map(version => {
            if (version.api_version === "v3") {
                let new_item = {...version}
                new_item.columns = [...newTableConfig]
                return new_item
            } else {
                return version
            }
        })
        dispatch(setBackupsTableConfig({
            backupsTableConfig: new_data
        }))
    }
    
    const handleRowsPerPageChange = (rows_num) => {
        const new_data = tableConfig.map(version => {
            if (version.api_version === "v3") {
                let new_item = {...version}
                new_item.rows_per_page = rows_num
                return new_item
            } else {
                return version
            }
        })
        dispatch(setBackupsTableConfig({
            backupsTableConfig: new_data
        }))
    }

    return (
        <React.Fragment>
            <CustomTable
                withPagination={true}
                withCheckbox={true}
                withActions={true}
                withSorting={true}
                defaultDataTexts={{...defaultTexts}} 
                tableHeaders={
                    tableHeaders.length > 0 ? 
                    tableHeaders : 
                    [...tableColumns]}
                tableHeadersConfigHandler={handleTableConfig}
                identityField={identityField}
                columns={[...tableColumns]}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                dataRows={data}
                setDataRows={setData}
                handleRowSelection={handleRowSelection}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={actionsTexts}
                actionsList={actionsList}
                sortFields={backupSortFields}
                sortHandler={sortHandler}
                selfSorting={true}
            />
        </React.Fragment>
    )
};

export default BackupsTableV3;