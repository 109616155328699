import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import CustomSelectField from 
'../../../_common/_form_fields/CustomSelectField';
import CustomTextField from 
'../../../_common/_form_fields/CustomTextField';
import CustomCheckboxField from 
'../../../_common/_form_fields/CustomCheckboxField';
import Constants from '../../../../config/constants';
import billingRequest from '../../../../_network/billing_request';
import { billingServiceURLs } from '../../../../_network/apiUrls';
import { regionsUrl as regionsUrlResponses } from 
'../../../../_api_responses/openstack/identity/regions/v3';
import { 
    openStackServices,
    openStackServiceVersions
 } from '../../../../config/openStackConstants';
import { identityKeystonConstants } from 
'../../../../config/openStackConstants';
import { openstackRequest } from 
'../../../../_network/openstack_request';
import {
    servicesSuspendPolicy,
    servicesDeletePolicy
} from '../billing_constants';

const IDENTITY_SERVICE_NAME = openStackServices.identityService

const BillingServiceForm = (props) => {
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const { data, options, handleDataUpdate, updateForm } = props
    const [supportedServices, setSupportedServices] = useState([])
    const [supportedFilteredServices, setSupportedFilteredServices] = useState([])
    const [openstackRegions, setOpenstackRegions] = useState([]);
    const [currentServices, setCurrentServices] = useState([])
    const [supportedVersions, setSupportedVersions] = useState([])

    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const regionsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.regionsUrl)[0].url)

    useEffect(() => {
        const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingSupportedServicesEP}`
            let request_data = {
                url: url,
                method: "GET"
            }
            billingRequest(request_data).then((response) => {
                if (response.status_code === 200) {
                    const supported_services = response.data.map(service => {
                        return {keyword: service.display_name, value: service.service_name, default: false}
                    })
                    setSupportedServices(supported_services)
                }
            }).catch((err) => {
                console.log(err)
            })
    },[]);

    useEffect(() => {
        const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingServicesEP}`
            let request_data = {
                url: url,
                method: "GET"
            }
            billingRequest(request_data).then((response) => {
                if (response.status_code === 200) {
                    setCurrentServices(response.data)
                }
            }).catch((err) => {
                console.log(err)
            })
    },[]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${regionsUrl}`
            const method = "GET"

            const regions_response = await openstackRequest({url:url, method:method})
            if (regions_response.status_code === regionsUrlResponses.get.success_response.status_code) {
                const regions_list = regions_response.data.regions.map(region => {
                    return {keyword: region.id, value: region.id, default: false}
                })
                setOpenstackRegions(regions_list)
            }
            }
        )();
    },[
        identityServiceDomain, 
        identityServiceVersion, 
        regionsUrl
    ]);

    useEffect(() => {
        if (openstackRegions.length > 0) {
            if (data.region_name.length > 0) {
                const current_services = Object.groupBy(currentServices, ({region_name}) => region_name)
                let region_services = []
                if (Object.keys(current_services).includes(data.region_name)) {
                    region_services = current_services[data.region_name]
                }
                const region_service_names = region_services.map(rs => rs.service_name)
                const left_unconfigured_services = supportedServices.filter(service => !region_service_names.includes(service.value))
                setSupportedFilteredServices(left_unconfigured_services)
            } else {
                setSupportedFilteredServices([])
            }
        } else {
            const current_services = currentServices.map(s => s.service_name)
            const left_unconfigured_services = supportedServices.filter(service => !current_services.includes(service.value))
            setSupportedFilteredServices(left_unconfigured_services)
        }   
    },[
        data,
        currentServices,
        openstackRegions,
        supportedServices
    ])

    useEffect(() => {
        if (data.service_name.length > 0) {
            const supported_versions = openStackServiceVersions[data.service_name].map(v => {
                return {keyword: v.toUpperCase(), value: v, default: false}
            })
            setSupportedVersions(supported_versions)
        }
    },[
        data
    ]);

    return (
        <FormGroup>
            {!updateForm && <CustomSelectField 
                field_key="region_name"
                currentValue={
                    data ? 
                    data.region_name : 
                    ""
                }
                setCurrentValue={handleDataUpdate}
                label={defaultTexts.regionFormFieldLabel}
                required={false}
                items={openstackRegions}
                self_item_titles={true}
                withHelp={true}
                helpText={defaultTexts.billingRegionFormFieldHelpText}
                zIndex={1500}
                sx={{my: 1, width: '100%'}}
                {...options.region_name}
            />}
            {!updateForm && <CustomSelectField 
                field_key="service_name"
                currentValue={
                    data ? 
                    data.service_name : 
                    ""
                }
                setCurrentValue={handleDataUpdate}
                label={defaultTexts.serviceFormFieldLabel}
                required={true}
                requiredText={defaultTexts.requiredFormFieldError}
                items={supportedFilteredServices}
                self_item_titles={true}
                withHelp={true}
                helpText={defaultTexts.billingServiceFormFieldHelpText}
                zIndex={1500}
                sx={{my: 1, width: '100%'}}
                {...options.service_name}
            />}
            <CustomTextField 
                field_key="service_domain"
                currentValue={
                    data ? 
                    data.service_domain : 
                    ""
                }
                setCurrentValue={handleDataUpdate}
                label={defaultTexts.serviceDomainFormFieldLabel}
                required={true}
                withHelp={true}
                helpText={defaultTexts.billingServiceDomainFormFieldHelpText}
                helpTextWidth={300}
                sx={{my: 1}}
                {...options.service_domain}
            />
            <CustomSelectField 
                field_key="service_version"
                currentValue={
                    data ? 
                    data.service_version : 
                    ""
                }
                setCurrentValue={handleDataUpdate}
                label={defaultTexts.serviceVersionFormFieldLabel}
                required={true}
                requiredText={defaultTexts.requiredFormFieldError}
                items={supportedVersions}
                self_item_titles={true}
                withHelp={true}
                helpText={defaultTexts.billingServiceVersionFormFieldHelpText}
                zIndex={1500}
                sx={{my: 1, width: '100%'}}
                {...options.service_version}
            />
            <CustomTextField 
                field_key="resource_suspend_period"
                currentValue={
                    data ? 
                    data.resource_suspend_period : 
                    0
                }
                setCurrentValue={handleDataUpdate}
                label={defaultTexts.resourceSuspendDelayFormFieldLabel}
                required={true}
                withHelp={true}
                type="number"
                helpText={defaultTexts.billingSuspendPeriodFormFieldHelpText}
                helpTextWidth={300}
                sx={{my: 1}}
                {...options.resources_suspend_period}
            />
            <CustomSelectField 
                field_key="resource_suspend_policy"
                currentValue={
                    data ? 
                    data.resource_suspend_policy : 
                    ""
                }
                setCurrentValue={handleDataUpdate}
                label={defaultTexts.resourceSuspendPolicyFormFieldLabel}
                required={true}
                requiredText={defaultTexts.requiredFormFieldError}
                items={data.service_name.length > 0 ? servicesSuspendPolicy[data.service_name] : []}
                self_item_titles={false}
                item_titles={defaultTexts}
                withHelp={true}
                helpText={defaultTexts.billingSuspendActionFormFieldHelpText}
                zIndex={1500}
                helpTextWidth={300}
                sx={{my: 1, width: '100%'}}
                {...options.resource_suspend_policy}
            />
            <CustomTextField 
                field_key="resource_delete_period"
                currentValue={
                    data ? 
                    data.resource_delete_period : 
                    0
                }
                setCurrentValue={handleDataUpdate}
                label={defaultTexts.resourceDeleteDelayFormFieldLabel}
                required={true}
                withHelp={true}
                type="number"
                helpText={defaultTexts.billingDeletePeriodFormFieldHelpText}
                helpTextWidth={300}
                sx={{my: 1}}
                {...options.resources_delete_period}
            />
            <CustomSelectField 
                field_key="resource_delete_policy"
                currentValue={
                    data ? 
                    data.resource_delete_policy : 
                    ""
                }
                setCurrentValue={handleDataUpdate}
                label={defaultTexts.resourceDeletePolicyFormFieldLabel}
                required={true}
                requiredText={defaultTexts.requiredFormFieldError}
                items={data.service_name.length > 0 ? servicesDeletePolicy[data.service_name] : []}
                self_item_titles={false}
                item_titles={defaultTexts}
                withHelp={true}
                helpText={defaultTexts.billingDeleteActionFormFieldHelpText}
                zIndex={1500}
                helpTextWidth={300}
                sx={{my: 1, width: '100%'}}
                {...options.resource_delete_policy}
            />
            <CustomCheckboxField
                field_key="is_identity_provider"
                currentValue={data.is_identity_provider}
                setCurrentValue={handleDataUpdate}
                label={defaultTexts.isIdentityProviderFormFieldLabel}
                required={false}
                withHelp={true}
                helpText={defaultTexts.serviceIsIdentityProviderFormFieldHelpText}
                zIndex={1500}
                sx={{my: 1}}
                {...options.is_identity_provider}
            />
            {data.is_identity_provider && <CustomTextField 
                field_key="credentials_id"
                currentValue={
                    data ? 
                    data.credentials_id : 
                    ""
                }
                setCurrentValue={handleDataUpdate}
                label={defaultTexts.appCredentialsIDFormFieldLabel}
                required={false}
                withHelp={true}
                helpText={defaultTexts.appCredentialsIDFormFieldHelpText}
                helpTextWidth={300}
                zIndex={1500}
                sx={{my: 1}}
                {...options.credentials_id}
            />}
            {data.is_identity_provider && <CustomTextField 
                field_key="credentials_secret"
                currentValue={
                    data ? 
                    data.credentials_secret : 
                    ""
                }
                setCurrentValue={handleDataUpdate}
                label={defaultTexts.appCredentialsSecretFormFieldLabel}
                required={false}
                withHelp={true}
                helpText={defaultTexts.appCredentialsSecretFormFieldHelpText}
                helpTextWidth={300}
                zIndex={1500}
                sx={{my: 1}}
                {...options.credentials_secret}
            />}
        </FormGroup>
    )
};

export default BillingServiceForm;