export const trunkDataSchema = [
    {
        field_key: "project_id",
        is_id_field: false,
        field_type: "string", 
        label: "projectFormFieldLabel"
    },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "status",
        is_id_field: false,
        field_type: "string", 
        label: "statusFormFieldLabel"
    },
    {
        field_key: "admin_state_up",
        is_id_field: false,
        field_type: "bool", 
        label: "adminStateFormFieldLabel",
        textOnTrue: "upStateFormFieldLabel",
        textOnFalse: "downStateFormFieldLabel"
    },
    {
        field_key: "port_id",
        is_id_field: false,
        field_type: "string", 
        label: "parentPortFormFieldLabel"
    },
    {
        field_key: "sub_ports",
        is_id_field: false,
        field_type: "string", 
        label: "subportsNumberFormFieldLabel"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "revision_number",
        is_id_field: false,
        field_type: "string", 
        label: "revisionNumberFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const trunkDataForm = [
    {
        field_key: "project_id",
        field_type: "select",
        required: true,
        label: "projectFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "port_id",
        field_type: "select",
        required: true,
        label: "portFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    },
    {
        field_key: "admin_state_up",
        field_type: "bool", 
        required: false,
        label: "adminStateFormFieldLabel",
        default_value: true
    }
]

export const trunksSortFields = [
    {
        field_key: "project_id",
        label: "projectFormFieldLabel"
    },
    {
        field_key: "name",
        label: "nameFormFieldLabel"
    },
    {
        field_key: "admin_state_up",
        label: "adminStateFormFieldLabel"
    },
    {
        field_key: "status",
        label: "statusFormFieldLabel"
    }
]


export const trunksFilterMenu = [
    {
        keyword: "nameFormFieldLabel",
        value: "name",
        type: "text"
    },
    {
        keyword: "projectFormFieldLabel",
        value: "project_id",
        type: "select", 
        multiple: false,
        self_item_titles: true,
        items: []
    },
    {
        keyword: "statusFormFieldLabel",
        value: "status",
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "activeStatusFormChoiceText",
                value: "ACTIVE",
                default: true
            },
            {
                keyword: "downStatusFormChoiceText",
                value: "DOWN",
                default: false
            },
            {
                keyword: "buildStatusFormChoiceText",
                value: "BUILD",
                default: false
            },
            {
                keyword: "degradedStatusFormChoiceText",
                value: "DEGRADED",
                default: false
            },
            {
                keyword: "errorStatusFormChoiceText",
                value: "ERROR",
                default: false
            },
        ]
    },
    {
        keyword: "adminStateFormFieldLabel",
        value: "admin_state_up",
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "formValueYes",
                value: "true",
                default: true
            },
            {
                keyword: "formValueNo",
                value: "false",
                default: false
            }
        ]
    }
]

export const trunkDataUpdateForm = [
    {
        field_key: "name",
        field_type: "string",
        required: false,
        label: "nameFormFieldLabel"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    }
]

export const trunkAdminStateUpdateForm = [
    {
        field_key: "admin_state_up",
        field_type: "bool", 
        required: false,
        label: "adminStateFormFieldLabel",
        default_value: true
    }
]

export const trunkSubportForm = [
    {
        field_key: "port_id",
        field_type: "select",
        required: true,
        label: "portFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "segmentation_id",
        field_type: "integer",
        required: true,
        label: "segmentationIDFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "segmentation_type",
        field_type: "select",
        required: true,
        label: "segmentationTypeFormFieldLabel",
        self_item_titles: false,
        default_empty: false,
        error_label: "requiredFormFieldError",
        default_value: "vlan",
        items: [
            {
                keyword: "vlanFormFieldLabel",
                value: "vlan",
                default: true
            }
        ]
    },
]

export const trunkSubportRemoveForm = [
    {
        field_key: "port_id",
        field_type: "select",
        required: true,
        label: "portFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        error_label: "requiredFormFieldError"
    }
]