import React from 'react';
import { useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Constants from '../../../config/constants'
import Box from '@mui/material/Box';
import { 
    openStackServiceLabels,
    openStackResourceLabels
 } from '../../../config/openStackConstants';

const drawerWidth = Constants.drawerWidth;

const BillingDeletedResources = (props) => {
    const drawerOpen = useSelector(state => state.drawer.drawerOpened);
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const { deletedResources, billingConfigs } = props
    
    return (
        <Box sx={{
            maxWidth: drawerOpen ? 
            `calc(100vw - ${drawerWidth}px)` : 
            `calc(100vw - ${110}px)`,
            mt: 2
        }}>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300}} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell  align="left">
                                {defaultTexts.serviceFormFieldLabel}
                            </TableCell>
                            <TableCell  align="left">
                                {defaultTexts.regionFormFieldLabel}
                            </TableCell>
                            <TableCell  align="left">
                                {defaultTexts.resourceFormFieldLabel}
                            </TableCell>
                            <TableCell  align="left">
                                {defaultTexts.descriptionFormFieldLabel}
                            </TableCell>
                            <TableCell  align="left">
                                {defaultTexts.priceFormFieldLabel}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {deletedResources.map((row) => (
                            <TableRow key={`${row.service_name}_${row.region_name}_${row.resource_name}_${row.id}`}>
                                <TableCell>{openStackServiceLabels[row.service_name]}</TableCell>
                                <TableCell>{row.region_name}</TableCell>
                                <TableCell>{openStackResourceLabels[row.resource_name]}</TableCell>
                                {row.resource_name === "instance" && <TableCell>{row.props.name} {`(${row.resource_status.toUpperCase()})`}</TableCell>}
                                {row.resource_name !== "instance" && <TableCell>{row.props.name}</TableCell>}
                                <TableCell>{row.price} {billingConfigs.currency ? billingConfigs.currency.name.toUpperCase() : ""}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );

}

export default BillingDeletedResources;