import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../../../../_common/CustomTable';
import { hypervisorDataSchema } from 
'../../../../../../_data/openstack/compute/infrastructure/v2.1';
import { setHypervisorsTableConfig } from 
'../../../../../../store/reducers/computeNovaSlice';

const handleTableColumnsConfig = (columns_schema,api_version) => {
    let current_api_version = api_version
    if (!api_version) {
        current_api_version = "2.1"
    }

    let schema = columns_schema.filter(f => 
        (!f.min_api_version ||  parseFloat(f.min_api_version) <= parseFloat(current_api_version))
    )

    schema = schema.filter(f => 
        (!f.max_api_version || parseFloat(f.max_api_version) > parseFloat(current_api_version))
    )
    return schema
}

const HypervisorsTableV21 = (props) => {
    const { hypervisorsData, setHypervisorsData } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);
    
    const hypervisorsTableConfig = useSelector(
        state => state.computeNova.hypervisorsTableConfig)
    const rowsPerPage = useSelector(
        state => state.computeNova.hypervisorsTableConfig.filter(
        versions => versions.api_version === "v2.1")[0].rows_per_page)
    const hypervisorsTableHeaders = useSelector(
        state => state.computeNova.hypervisorsTableConfig.filter(
            versions => versions.api_version === "v2.1")[0].columns)
    const computeMaxApiVersion = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            versions => versions.api_version === "v2.1")[0].max_api_version)

    const hypervisorsTableColumns = handleTableColumnsConfig(hypervisorDataSchema,computeMaxApiVersion)
    
    const identityField = hypervisorsTableColumns.filter(
        item => item.is_id_field)[0].field_key
    
    const dispatch = useDispatch();

    const handleHypervisorTableConfig = (value_list) => {
        let schema = []
        if (hypervisorsData.length > 0) {
            const v_keys = Object.keys(hypervisorsData[0])
            schema = hypervisorDataSchema.filter(
                n => v_keys.includes(n.field_key))
        } else {
            schema = [...hypervisorDataSchema]
        }
        let newTableConfig = schema.filter(
            n => value_list.includes(n.field_key))
        const new_data = hypervisorsTableConfig.map(version => {
            if (version.api_version === "v2.1") {
                let new_item = {...version}
                new_item.columns = [...newTableConfig]
                return new_item
            } else {
                return version
            }
        })
        dispatch(setHypervisorsTableConfig({
            hypervisorsTableConfig: new_data
        }))
    }
    
    const handleRowsPerPageChange = (rows_num) => {
        const new_data = hypervisorsTableConfig.map(version => {
            if (version.api_version === "v2.1") {
                let new_item = {...version}
                new_item.rows_per_page = rows_num
                return new_item
            } else {
                return version
            }
        })
        dispatch(setHypervisorsTableConfig({
            hypervisorsTableConfig: new_data
        }))
    }

    return (
        <React.Fragment>
            <CustomTable
                withPagination={true}
                defaultDataTexts={{...defaultTexts}} 
                tableHeaders={
                    hypervisorsTableHeaders.length > 0 ? 
                    hypervisorsTableHeaders : 
                    [...hypervisorsTableColumns]}
                tableHeadersConfigHandler={handleHypervisorTableConfig}
                identityField={identityField}
                columns={[...hypervisorsTableColumns]}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                dataRows={hypervisorsData}
                setDataRows={setHypervisorsData}
                handleRowSelection={()=>{}}
            />
        </React.Fragment>
    )
};

export default HypervisorsTableV21;