import React from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { 
    networkNeutronConstants,
    openStackServices
} from '../../../../config/openStackConstants';
import WrapperBox from '../../../_common/WrapperBox';
import NotPurchasedService from '../../../_common/NotPurchasedService';
import Grid from "@mui/material/Grid";
import RoutersGraphContentV20 from "./routersGraphContentV20";
import L2NetworksGraphContentV20 from "./l2networksGraphContentV20";
import FloatingIPsGraphContentV20 from "./floatingIPsGraphContentV20";
import SecurityGroupsGraphContentV20 from "./securityGroupsGraphContentV20";
import FirewallsGraphContentV20 from "./firewallsGraphContentV20";
import VPNsGraphContentV20 from "./vpnsGraphContentV20";

const SERVICE_NAME = openStackServices.networkService
const SERVICE_TITLE = 'Networks (Neutron)'

const NetworksGraphWrapper = (props) => {
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
    const navigate = useNavigate();

    if (!isAuthenticated) {
        navigate('/');
    }

    const purchasedServices = useSelector(state => 
        state.openstack.purchasedServices);

    const purchasedService = purchasedServices.filter(
        item => item.service === SERVICE_NAME)
    if (purchasedService.length === 0) {
        return (
            <NotPurchasedService service={SERVICE_TITLE} />
        )
    }
    if (!networkNeutronConstants.supported_api_versions.includes(
        purchasedService[0].config_params.api_version)) {
        return (
            <WrapperBox>
                Configured API Version for {SERVICE_TITLE} is not supported!
            </WrapperBox>
        )
    }

    if (purchasedService[0].config_params.api_version === "v2.0") {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <L2NetworksGraphContentV20 />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <RoutersGraphContentV20 />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FloatingIPsGraphContentV20 />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <SecurityGroupsGraphContentV20 />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FirewallsGraphContentV20 />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <VPNsGraphContentV20 />
                </Grid>
            </Grid>
        )
    }
};

export default NetworksGraphWrapper;