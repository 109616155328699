import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import WrapperBox from '../../../../_common/WrapperBox';
import AddButton from '../../../../_common/AddButton';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CustomText from '../../../../_common/CustomText';
import PlusButton from '../../../../_common/PlusButton';
import MinusButton from '../../../../_common/MinusButton';
import FormGroup from '@mui/material/FormGroup';
import CustomTextField from 
'../../../../_common/_form_fields/CustomTextField';
import CustomDialog from '../../../../_common/CustomDialog';
import { openStackServices, blockStorageCinderConstants } from 
'../../../../../config/openStackConstants';
import { qosSpecsDataForm } from 
'../../../../../_data/openstack/cinder/volume_types/v3';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import { volumeCinderRequest, getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { volumesUrl as volumeUrlResponses } 
from '../../../../../_api_responses/openstack/cinder/volumes/v3';
import Popper from '@mui/material/Popper';
import { ClickAwayListener } from '@mui/material';
import parse from 'html-react-parser';
import HelpIcon from '@mui/icons-material/Help';
import IconButton from '@mui/material/IconButton';
import Fade from '@mui/material/Fade';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid'

const SERVICE_NAME = openStackServices.volumeService

const QoSSpecsSubheaderV3 = (props) => {
    const { currentTab, handleTabChange } = props
    const { handleFetchData } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [error, setError] = useState()
    const [successQoSSpecsAdd, setSuccessQoSSpecsAdd] = useState()
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [formData, setFormData] = useState({})
    const [formDataOptions, setFormDataOptions] = useState({});
    const [formExtraFields, setFormExtraFields] = useState([]);

    const [anchorEl, setAnchorEl] = useState(null);
    const [helpOpen, setHelpOpen] = useState(false);
    const [placement, setPlacement] = useState();

    const handleHelpTextClose = () => {
        setHelpOpen(false)
    }
  
      const handleClick = (newPlacement) => (event) => {
        setAnchorEl(event.currentTarget);
        setHelpOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
      };

    const theme = useTheme()

    const cinderServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const cinderServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const qosSpecsUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.qosSpecsUrl)[0].url)

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleSuccessDialogClose = () => {
        setSuccessQoSSpecsAdd(null);
        setSuccessDialogOpen(false);
    }

    const handleAddQoSSpecsFormReset = () => {
        setFormDataOptions({})
        let new_form_data = {}
        for (const n in qosSpecsDataForm) {
            if (qosSpecsDataForm[n].field_type === "string" || 
            qosSpecsDataForm[n].field_type === "select") {
                new_form_data[qosSpecsDataForm[n].field_key] = ""
            } else if (qosSpecsDataForm[n].field_type === "bool") {
                new_form_data[qosSpecsDataForm[n].field_key] = qosSpecsDataForm[n].default_value ? 
                qosSpecsDataForm[n].default_value : 
                false
            }
        }
        setFormData(new_form_data)
        setFormExtraFields([])
    }

    const handleFormDataChange = (event,field_key) => {
        setFormDataOptions({})
        let new_form_data = {...formData}
        if (qosSpecsDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (qosSpecsDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setFormData(new_form_data)
    }

    const handleFormExtraFieldsChange = (event,field_key_list) => {
        let new_extra_data = [...formExtraFields]
        if (field_key_list[1] === "key") {
            new_extra_data[field_key_list[0]].field_key = event.target.value
        } else {
            new_extra_data[field_key_list[0]].field_value = event.target.value
        }
        setFormExtraFields(new_extra_data)
    }

    const handleFormExtraFieldsRemove = (index) => {
        if (formExtraFields.length > 1) {
            let updated_data = [...formExtraFields]
            updated_data.splice(index, 1)
            setFormExtraFields(updated_data)
        } else {
            setFormExtraFields([])
        }
    }

    const handleFormExtraFieldsAddToVolumeType = (data) => {
        let updated_data = {...data}
        for (let i in formExtraFields) {
            if (formExtraFields[i].field_key.length > 0) {
                updated_data[formExtraFields[i].field_key] = formExtraFields[i].field_value
            }
        }
        return updated_data
    }

    const getDataForm = () => {
        return (
            <FormGroup>
                {qosSpecsDataForm.map(field => {
                    let form_field_options = {...formDataOptions[field.field_key]}
                    if (field.field_key === "consumer") {
                        form_field_options["items"] = [...field.items]
                        form_field_options["self_item_titles"] = field.self_item_titles
                        form_field_options["default_empty"] = field.default_empty
                        form_field_options["withHelp"] = field.with_help_text
                        form_field_options["helpText"] = defaultTexts[field.help_text]
                        form_field_options["zIndex"] = 3000
                        form_field_options["helpTextWidth"] = 400
                        form_field_options["helpTextHeight"] = 330
                        form_field_options["sx"] = {width: "100%", my: 1}
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            formData,
                            handleFormDataChange,
                            defaultTexts[field.label],
                            {
                                sx: {width: {xs: "83%", sm: "91%"}, my: 1},
                                ...form_field_options
                            }
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleFormDataValidation = () => {
        let validation_faild = true
        let updatedDataFormOptions = {...formDataOptions}
        for (let n in qosSpecsDataForm) {
            if (qosSpecsDataForm[n].required && !formData[qosSpecsDataForm[n].field_key]) {
                validation_faild = false
                updatedDataFormOptions[qosSpecsDataForm[n].field_key] = {}
                updatedDataFormOptions[qosSpecsDataForm[n].field_key]["error"] = true
                updatedDataFormOptions[qosSpecsDataForm[n].field_key]["errorText"] = defaultTexts[qosSpecsDataForm[n].error_label]
            }
        }

        setFormDataOptions(updatedDataFormOptions)
        return validation_faild
    }

    const onAddQosSpecs = async () => {
        const validateFormData = handleFormDataValidation()
        if (validateFormData) {
            const request_data = handleFormExtraFieldsAddToVolumeType(formData)
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${qosSpecsUrl}`
                const method = "POST"
                const response = await volumeCinderRequest({
                    url: url, 
                    method: method, 
                    data: {qos_specs: request_data},
                    token: project_token
                })
                if (response.status_code === volumeUrlResponses.post.success_response.status_code) {
                    handleAddQoSSpecsFormReset()
                    setFormDataOptions({})
                    handleFetchData()
                    setSuccessQoSSpecsAdd({
                        success_title: volumeUrlResponses.post.success_response.response_title, 
                        success_message: volumeUrlResponses.post.success_response.response_message
                    })
                } else {
                    const error_response = volumeUrlResponses.post.error_response.filter(
                        error_item => error_item.status_code === response.status_code)
                    if (error_response.length > 0) {
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: response.error
                        }
                        setError(errorObject)
                    } else {
                        const error_response = volumeUrlResponses.post.error_response.filter(
                            error_item => error_item.status_code === "unknown")
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: response.error
                        }
                        setError(errorObject)
                    }
                }
            }
        }

        return validateFormData
    }

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessDialogOpen(true)
    },[successQoSSpecsAdd]);

    useEffect(() => {
        if (Object.keys(formData).length === 0) {
            let new_form_data = {}
            for (const n in qosSpecsDataForm) {
                if (
                    qosSpecsDataForm[n].field_type === "string" || 
                    qosSpecsDataForm[n].field_type === "select"
                    ) {
                    new_form_data[qosSpecsDataForm[n].field_key] = ""
                } else if (qosSpecsDataForm[n].field_type === "bool") {
                    new_form_data[qosSpecsDataForm[n].field_key] = qosSpecsDataForm[n].default_value ? 
                    qosSpecsDataForm[n].default_value : 
                    false
                }
            }
            setFormData(new_form_data)
        }
    },[formData]);

    return (
        <WrapperBox 
            sx={{
                flexDirection: "row", 
                justifyContent: 'space-between',
                mt: 3,
                mb: 2
            }}>
            <Grid container justifyContent='space-between' alignItems="center">
                <Grid item>
                <AddButton 
                    getDataForm={getDataForm}               
                    onSubmit={onAddQosSpecs}
                    formReset={handleAddQoSSpecsFormReset}
                    customTexts={{
                        title: defaultTexts.addQoSSpecsDialogTitle
                    }}
                >
                    <Stack 
                        direction="row" 
                        spacing={2} 
                        alignItems="center"
                        sx={{my: 3}}
                    >
                        <CustomText size="h6">
                            {defaultTexts.extraSpecsFormFieldLabel}
                        </CustomText>
                        <PlusButton onClick={() => setFormExtraFields([
                            ...formExtraFields,
                            {field_key: "", field_value: ""}
                        ])} />
                        <ClickAwayListener onClickAway={handleHelpTextClose}>
                            <IconButton onClick={handleClick('top-start')}>
                                <HelpIcon />
                            </IconButton>
                        </ClickAwayListener>
                        <Popper
                            sx={{ zIndex: 3000 }}
                            open={helpOpen}
                            anchorEl={anchorEl}
                            placement={placement}
                            transition
                        >
                            {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={350}>
                                <Paper 
                                    sx={{
                                        width: 400, 
                                        height: 350,
                                        p: 2, 
                                        backgroundColor: "paper",
                                        overflowY: "scroll"
                                    }}
                                >
                                    <CustomText 
                                        size="p" 
                                        sx={{color: "text.primary"}}
                                    >
                                    {parse(defaultTexts.qosSpecsPropertiesHelpText)}
                                    </CustomText>
                                </Paper>
                            </Fade>
                            )}
                        </Popper>
                    </Stack>
                    {formExtraFields.map((item,index) => {
                        return (
                            <Stack
                                key={index}
                                direction="row" 
                                spacing={2} 
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{my: 1}}
                            >
                                <CustomTextField
                                    currentValue={item.field_key} 
                                    setCurrentValue={handleFormExtraFieldsChange}
                                    field_key={[index,"key"]}
                                    label={defaultTexts.keyFormFieldLabel}
                                />
                                <CustomTextField
                                    currentValue={item.field_value} 
                                    setCurrentValue={handleFormExtraFieldsChange}
                                    field_key={[index,"value"]}
                                    label={defaultTexts.valueFormFieldLabel}
                                />
                                <MinusButton sx={{width: 90}} onClick={
                                    () => handleFormExtraFieldsRemove(index)}
                                />
                            </Stack>
                            
                        )
                    })}
                </AddButton>
                </Grid>
                <Grid item sx={{flexGrow: 1}}>
                <Button
                    onClick={() => handleTabChange("volume_types")}
                    sx={{
                        color: currentTab === "volume_types" ? 
                        "lightBlue" : 
                        theme.palette.text.primary,
                        "&:hover": {
                            backgroundColor: "lightBlue",
                            color: "white"
                        }
                    }}
                    size="small"
                >
                    {defaultTexts.volumeTypesTabButtonText}
                </Button>
                <Button
                    onClick={() => handleTabChange("qos_specs")}
                    sx={{
                        color: currentTab === "qos_specs" ? 
                        "lightBlue" : 
                        theme.palette.text.primary,
                        "&:hover": {
                            backgroundColor: "lightBlue",
                            color: "white"
                        }
                    }}
                    size="small"
                >
                    {defaultTexts.qosSpecsTabButtonText}
                </Button>
                <Button
                    onClick={() => handleTabChange("default_vt")}
                    sx={{
                        color: currentTab === "default_vt" ? 
                        "lightBlue" : 
                        theme.palette.text.primary,
                        "&:hover": {
                            backgroundColor: "lightBlue",
                            color: "white"
                        }
                    }}
                    size="small"
                >
                    {defaultTexts.defaultVolumeTypesTabButtonText}
                </Button>
        </Grid>
        </Grid>
            {successQoSSpecsAdd && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[successQoSSpecsAdd.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successQoSSpecsAdd.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </WrapperBox>
    )
};

export default QoSSpecsSubheaderV3;