import React from "react";
import Typography from '@mui/material/Typography';

const CustomText = (props) => {
    const { size } = props
    const { sx } = props

    return (
        <Typography
            variant={size ? size : "p"}
            sx={{...sx}}
        >
            {props.children}
        </Typography>
    )
};

export default CustomText;