import React from 'react';
import { useTheme } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import WrapperBox from '../../../../_common/WrapperBox';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import { ImDatabase } from "react-icons/im";

const detailTabCardConfig = {
    border: 0.3,
    borderRadius: 2,
    borderStyle: "dashed",
    borderColor: "text.secondary",
    minHeight: 120
}

const ComputeServerVolumesV21 = (props) => {
    const { attachedVolumes } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)

    const theme = useTheme();

    return (
        <React.Fragment>
            <Grid container spacing={2} sx={{mt: 1, mb: 4}}>
                <Grid item xs={12} md={4}>
                    <WrapperBox
                        sx={detailTabCardConfig}
                    >
                        <Stack
                            direction="row" 
                            alignItems="start" 
                            justifyContent="center"
                            spacing={0}
                        >
                        <Stack
                            direction="column" 
                            alignItems="center" 
                            justifyContent="flex-start"
                            spacing={0}
                        >
                            <CustomText 
                                size="h4" 
                                sx={{
                                    color: theme.palette.dashboardPink
                                }}
                            >
                                {attachedVolumes.length}
                            </CustomText>
                            <CustomText 
                                size="p" 
                                sx={{fontSize: {xs: 16, lg: 18}}}
                            >
                                {defaultTexts.attachedVolumesTitleText}
                            </CustomText>
                        </Stack>
                        <ImDatabase
                            size={28}
                        />
                        </Stack>
                    </WrapperBox>
                </Grid>
                <Grid item xs={12} md={8}>
                    <TableContainer>
                        <Table sx={{ width: "100%" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{defaultTexts.volumeIDTitleText}</TableCell>
                                    <TableCell align="right">{defaultTexts.attachementDeviceTitleText}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {attachedVolumes.map((row) => (
                                <TableRow
                                    key={row.volumeId}
                                >
                                <TableCell 
                                    component="th" 
                                    scope="row"
                                >
                                    {row.volumeId}
                                </TableCell>
                                <TableCell 
                                    align="right"
                                >
                                    {row.device}
                                </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
            </Grid>
        </Grid>
        </React.Fragment>
    )
};

export default ComputeServerVolumesV21;