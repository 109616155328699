import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import CustomDialog from '../../../_common/CustomDialog';
import CustomText from '../../../_common/CustomText';
import logo_light from "../../../../_assets/imgs/PayPal-Log.png";
import logo_dark from "../../../../_assets/imgs/PayPal-Log-Dark.png";

const PayPalCard = (props) => {
    const { client_id } = props
    const [detailsOpen, setDetailsOpen] = useState(false);
    const defaultTexts = useSelector(state => state.texts.langTexts)

    const theme = useTheme();

    const handleDetailsOpen = () => {
        setDetailsOpen(true)
    }

    const handleDetailsClose = () => {
        setDetailsOpen(false)
    }

    return (
        <Box>
            {theme.palette.mode === "dark" && <img alt="PayPal" src={logo_dark} />}
            {theme.palette.mode === "light" && <img alt="PayPal" src={logo_light} />}
            <CustomText size="h6" sx={{ml: 1}}>
                {defaultTexts.paypalCardTitle}
            </CustomText>
            <Button color="info" onClick={handleDetailsOpen}>
                {defaultTexts.paymentMethodSeeDetails}
            </Button>
            <CustomDialog
                open={detailsOpen}
                onClose={handleDetailsClose}
                dialogTitle={{
                    title: defaultTexts.paypalCardTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.paypalCardText, 
                    sx: {color: 'text.primary'}}}
            >
                <Divider sx={{my: 2}}/>
                <CustomText size="h6" sx={{color: "primary.main", fontSize: 16}}>
                    {defaultTexts.configurationOptionsTitle}
                </CustomText>
                <CustomText sx={{wordWrap: "break-word"}}>
                    {defaultTexts.clientIDHelpText}: {client_id}
                </CustomText>
            </CustomDialog>
        </Box>
    )
}

export default PayPalCard;