
import Constants from '../config/constants';

const userLogsInOptions = () => {

    const formData = new URLSearchParams();
    formData.append('grant_type', 'client_credentials');
    formData.append('client_id', Constants.client_credential_id);
    formData.append('client_secret', Constants.client_credential_secret);
    return (
        {
            method: 'POST',
            headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            },
            body: formData.toString(),
            json: true,
        }
    );
};

export const getAccessToken = async () => {
    const response = await fetch(
        Constants.auth_server_url,
        userLogsInOptions(),
    );
    const responseJson = await response.json();
    
    if (responseJson.error) {
        console.error('error', responseJson.error);
        return null
    }
    
    return responseJson.access_token;
};