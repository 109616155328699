import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import IdentityKeystoneUsersSubheaderV3 from 
'./identityKeystoneUsersSubheaderV3';
import IdentityUsersTableV3 from './identityUsersTableV3';
import { identityKeystonConstants } from 
'../../../../../config/openStackConstants';
import { openstackRequest } from 
'../../../../../_network/openstack_request';
import { usersUrl as usersUrlResponses } from 
'../../../../../_api_responses/openstack/identity/users/v3';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { usersfilterMenu } from 
'../../../../../_data/openstack/identity/users/v3';
import CustomDialog from '../../../../_common/CustomDialog';
import { Stack } from '@mui/material';
import PlusButton from '../../../../_common/PlusButton';
import MinusButton from '../../../../_common/MinusButton';
import CustomText from '../../../../_common/CustomText';
import CustomSideDrawer from '../../../../_common/CustomSideDrawer';
import IdentityUserDetailV3 from './identityUserDetailV3';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import useWindowDimensions from '../../../../_common/WindowDimensions';
import Dimensions from '../../../../../config/dimensions';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import CustomTextField from '../../../../_common/_form_fields/CustomTextField';
import Box from '@mui/material/Box';

const SERVICE_NAME = openStackServices.identityService

const IdentityUsersWrapperV3 = (props) => {
    const [isLoading, setIsLoading ] = useState(true);
    const [filterMenu, setFilterMenu] = useState(usersfilterMenu)
    const [usersData, setUsersData] = useState([])
    const [users, setUsers] = useState([])
    const [domains, setDomains] = useState([]);
    const [projects, setProjects] = useState([]);
    const [error, setError] = useState()
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [successNote, setSuccessNote] = useState()
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = width < Dimensions.tablet_mini.width ? 0.9 : 0.6
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const [selectedFilter, setSelectedFilter ] = useState(filterMenu[0].value)
    const [selectedFilterValue, setSelectedFilterValue] = useState("");
    const [filterQueryParams, setFilterQueryParams] = useState("")
    const defaultTexts = useSelector(state => state.texts.langTexts)

    const [fetchDataRequired, setFetchDataRequired] = useState(true);

    const [userPasswordData, setUserPasswordData] = useState({
        password: "",
        original_password: ""
    });

    const [fieldPasswordError, setFieldPasswordError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);

    const [fieldOriginalPasswordError, setFieldOriginalPasswordError] = useState(false);
    const [showOriginalPassword, setShowOriginalPassword] = useState(false);
    
    const [changePasswordDialogOpen, setChangePasswordDialogOpen] = useState(false);

    const [currentAction, setCurrentAction] = useState("");

    const [addExtraParamsMode, setAddExtraParamsMode] = useState(false);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [formExtraFields, setFormExtraFields] = useState([]);

    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const usersUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.usersUrl)[0].url)

    const domainsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.domainsUrl)[0].url)


    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)


    const handleSuccessDialogClose = () => {
        setUserPasswordData({
            password: "",
            original_password: ""
        });
        setSuccessDialogOpen(false);
    };

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleClickShowOriginalPassword = () => {
        setShowOriginalPassword(!showOriginalPassword)
    };
    
    const handleMouseDownOriginalPassword = (event) => {
        event.preventDefault();
    };

    const handeUserPasswordDataChange = (event,keyword) => {
        if (keyword === "password") {
            setFieldPasswordError(false)
            let new_data = {...userPasswordData}
            new_data.password = event.target.value
            setUserPasswordData(new_data)
        } else {
            setFieldOriginalPasswordError(false)
            let new_data = {...userPasswordData}
            new_data.original_password = event.target.value
            setUserPasswordData(new_data)
        }
    };

    const handleDataFetch = () => {
        setFetchDataRequired(true)
    }

    const handleFilteredSearch = () => {
        if (selectedFilter && selectedFilterValue) {
            setFilterQueryParams(`/?${selectedFilter}=${selectedFilterValue}`)
        } else {
            setFilterQueryParams("")
        }
    }

    const handleChangePasswordDialogClose = () => {
        setUserPasswordData({
            password: "",
            original_password: ""
        })
        setChangePasswordDialogOpen(false)
    }

    const handleChangePasswordDialogOpen = () => {
        setChangePasswordDialogOpen(true)
    }

    const handleFilterReset = () => {
        setSelectedFilter(filterMenu[0].value)
        setSelectedFilterValue("")
        setFilterQueryParams("")
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setUserPasswordData({
            password: "",
            original_password: ""
        });
        setErrorDialogOpen(false);
    }

    const handleDetailCardOpen = (index) => {
        setSelectedRow(users[index])
        setTimeout(() => setDetailCardOpen(true), 100) 
    };

    const handleDetailCardClose = () => {
        setSelectedRow(null)
        setTimeout(() => setDetailCardOpen(false), 100) 
    };

    const handleUserStatusChange = async (user_id, mode) => {
        let update_data = {}
        update_data["enabled"] = mode
        const url = `${identityServiceDomain}/${identityServiceVersion}/${usersUrl}/${user_id}`
        const method = "PATCH"
        const users_response = await openstackRequest({
            url:url, 
            method:method,
            data: {user: update_data}
        })
        if (users_response.status_code === usersUrlResponses.patch.success_response.status_code) {
            return users_response.data.user
        } else {
            return null
        }
    };

    const handleAddExtraParameters = async (user_id, data) => {

        const handleFormExtraFieldsAddToUser = () => {
            let updated_data = {}
            for (let i in data) {
                if (data[i].field_key.length > 0) {
                    updated_data[data[i].field_key.toLowerCase()] = data[i].field_value
                }
            }
            return updated_data
        }
        const url = `${identityServiceDomain}/${identityServiceVersion}/${usersUrl}/${user_id}`
        const method = "PATCH"

        const updated_data = handleFormExtraFieldsAddToUser()
        const user_response = await openstackRequest({
            url: url, 
            method: method, 
            data: {user: updated_data}
        })
        return user_response
    }

    const handleUserPasswordChange = async () => {
        handleChangePasswordDialogClose()
        const url = `${identityServiceDomain}/${identityServiceVersion}/${usersUrl}/${usersData[selectedRow].id}/password`
        const method = "POST"
        const users_response = await openstackRequest({
            url:url, 
            method:method,
            data: {user: userPasswordData}
        })
        if (users_response.status_code === usersUrlResponses.delete.success_response.status_code) {
            setSuccessNote({
                success_title: defaultTexts.userPasswordChangeSuccessTitle, 
                success_message: defaultTexts.userPasswordChangeSuccessText
            })
            setSuccessDialogOpen(true)
        } else {
            const error_response = usersUrlResponses.post.error_response.filter(
                error_item => error_item.status_code === users_response.status_code)
            if (error_response.length > 0) {
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: users_response.error
                }
                setError(errorObject)
            } else {
                const error_response = usersUrlResponses.get.error_response.filter(
                    error_item => error_item.status_code === "unknown")
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: users_response.error
                }
                setError(errorObject)
            }
        }
    };

    const onUserDisable = async (user_list) => {
        setIsLoading(true)
        for (let u in user_list) {
            await handleUserStatusChange(user_list[u],false)
        }
        setIsLoading(false)
        handleDataFetch()
    }

    const onUserEnable = async (user_list) => {
        setIsLoading(true)
        for (let u in user_list) {
            await handleUserStatusChange(user_list[u],true)
        }
        setIsLoading(false)
        handleDataFetch()
    }

    const handleAddExtraParamsModeChange = (user_list) => {
        setSelectedUsers(user_list)
        setAddExtraParamsMode(true)
    }

    const handleAddExtraParametersToUserList = async () => {
        setIsLoading(true)
        handleAddExtraParametersDialogClose()
        for (let u in selectedUsers) {
            await handleAddExtraParameters(selectedUsers[u],formExtraFields)
        }
        setIsLoading(false)
        handleDataFetch()
    }

    const handleAddExtraParametersDialogClose = () => {
        setSelectedUsers([])
        setFormExtraFields([])
        setAddExtraParamsMode(false)
    }

    const handleFormExtraFieldsChange = (event,field_key_list) => {
        let new_extra_data = [...formExtraFields]
        if (field_key_list[1] === "key") {
            new_extra_data[field_key_list[0]].field_key = event.target.value
        } else {
            new_extra_data[field_key_list[0]].field_value = event.target.value
        }
        
        setFormExtraFields(new_extra_data)
    }

    const handleFormExtraFieldsRemove = (index) => {
        if (formExtraFields.length > 1) {
            let updated_data = [...formExtraFields]
            updated_data.splice(index, 1)
            setFormExtraFields(updated_data)
        } else {
            setFormExtraFields([])
        }
    }

    const getUsersActionsList = () => {
        let user_actions = []
        let new_action = {}
        new_action["value"] = "user_disable"
        new_action["action"] = onUserDisable
        new_action["keyword"] = "userDisableActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        user_actions.push({...new_action})
    
        new_action["value"] = "user_enable"
        new_action["action"] = onUserEnable
        new_action["keyword"] = "userEnableActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        user_actions.push({...new_action})
        
        new_action = {}
        new_action["value"] = "user_add_parameters"
        new_action["action"] = handleAddExtraParamsModeChange
        new_action["keyword"] = "userAddParametersActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        user_actions.push({...new_action})
        
        return user_actions
    }
   

    useEffect(() => {
        setFetchDataRequired(true)
    },[filterQueryParams]);

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    useEffect(() => {
        if (fetchDataRequired) {
        (async () => {
            handleLoading(true)
            const url = `${identityServiceDomain}/${identityServiceVersion}/${usersUrl}${filterQueryParams}`
            const method = "GET"

            const users_response = await openstackRequest({url:url, method:method})
            if (users_response.status_code === usersUrlResponses.get.success_response.status_code) {
                setUsersData([...users_response.data.users])
                if (selectedRow) {
                    let new_data = users_response.data.users.filter(user => user.id === selectedRow.id)
                    if (new_data.length > 0) {
                        setSelectedRow(new_data[0])
                    } else {
                        setSelectedRow(null)
                    }
                }
            } else {
                const error_response = usersUrlResponses.get.error_response.filter(
                    error_item => error_item.status_code === users_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: users_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = usersUrlResponses.get.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: users_response.error
                    }
                    setError(errorObject)
                }
            }
        })();
        setFetchDataRequired(false)
        }
        setTimeout(()=>{handleLoading(false)},700)
    },[
        identityServiceDomain, 
        identityServiceVersion, 
        usersUrl, 
        filterQueryParams,
        fetchDataRequired,
        selectedRow
    ]);

    useEffect(() => {
        const domains_filter = domains.map(domain => {
            return {keyword: domain.name, value: domain.id, default: false}
        })

        const projects_filter = projects.map(project => {
            return {keyword: project.name, value: project.id, default: false}
        })

        const new_filter_menu = usersfilterMenu.map(item => {
            if (item.value === "domain_id") {
                item.items = domains_filter
            } else if (item.value === "default_project_id") {
                item.items = projects_filter
            }
            return item
        })
        setFilterMenu(new_filter_menu)

        let updated_users_data = usersData.map(user => {
            const domain = domains.filter(item => item.id === user.domain_id)
            const project = projects.filter(item => item.id === user.default_project_id)
            let updated_data = {...user}
            if (domain.length > 0) {
                updated_data.domain_id = domain[0].name
            }
            if (project.length > 0) {
                updated_data.default_project_id = project[0].name
            }
            return updated_data
        })
        setUsers(updated_users_data)
        setIsLoading(false)
    },[
        domains,
        projects,
        usersData
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${domainsUrl}`
            const method = "GET"

            const domains_response = await openstackRequest({url:url, method:method})
            if (domains_response.status_code === usersUrlResponses.get.success_response.status_code) {
                setDomains([...domains_response.data.domains])
            } else {
                setDomains([])
            }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        domainsUrl
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({url:url, method:method})
            
            if (projects_response.status_code === usersUrlResponses.get.success_response.status_code) {
                setProjects([...projects_response.data.projects])
            } else {
                setProjects([])
            }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <IdentityKeystoneUsersSubheaderV3 
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                selectedFilterValue={selectedFilterValue}
                setSelectedFilterValue={setSelectedFilterValue}
                filterMenu={filterMenu}
                handleFilteredSearch={handleFilteredSearch}
                handleFilterReset={handleFilterReset}
                handleFetchData={handleDataFetch}
                domains={domains}
                projects={projects}
            />
            {isLoading && <CustomBackdrop open={isLoading} />}
            <IdentityUsersTableV3 
                usersData={users}
                setUsersData={setUsers}
                handleRowSelection={handleDetailCardOpen}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getUsersActionsList()}
            />
            {selectedRow !== null && <CustomSideDrawer 
                open={detailCardOpen}
                widthWeight={WIDTH_WEIGHT}
                handleDrawerOpen={handleDetailCardOpen}
                handleDrawerClose={handleDetailCardClose}
            > 
                <IdentityUserDetailV3 
                    selectedRow={selectedRow}
                    widthWeight={WIDTH_WEIGHT}
                    handleFetchData={handleDataFetch}
                    handleDrawerClose={handleDetailCardClose}
                    usersRecords={usersData}
                    handleUserStatusChange={handleUserStatusChange}
                    handleUserPasswordChange={handleChangePasswordDialogOpen}
                    handleAddExtraParameters={handleAddExtraParameters}
                />         
            </CustomSideDrawer>}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>Details:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            <CustomDialog
                open={changePasswordDialogOpen}
                onClose={handleChangePasswordDialogClose}
                dialogTitle={{
                    title: defaultTexts.userChangePasswordActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.applyButtonTitleText, 
                    onClick: handleUserPasswordChange, 
                    variant: "contained",
                    size: "medium"
                }]}
            >
                <CustomTextField 
                    required={true}
                    size="medium"
                    error={fieldPasswordError}
                    errorText={defaultTexts.requiredFormFieldError}
                    variant="outlined"
                    label={defaultTexts.passwordFormFieldLabel}
                    currentValue={userPasswordData.password}
                    setCurrentValue={(event) => handeUserPasswordDataChange(event,"password")}
                    type={showPassword ? 'text' : 'password'}
                    EndIcon={
                        <IconButton
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                        >
                        {showPassword ? 
                            <VisibilityOff /> : 
                            <Visibility />}
                        </IconButton>
                    }
                    sx={{ m: 1}}
                />
                <CustomTextField 
                    required={true}
                    size="medium"
                    error={fieldOriginalPasswordError}
                    errorText={defaultTexts.requiredFormFieldError}
                    variant="outlined"
                    label={defaultTexts.originalPasswordFormFieldLabel}
                    currentValue={userPasswordData.original_password}
                    setCurrentValue={(event) => handeUserPasswordDataChange(event,"original_password")}
                    type={showOriginalPassword ? 'text' : 'password'}
                    EndIcon={
                        <IconButton
                            onClick={handleClickShowOriginalPassword}
                            onMouseDown={handleMouseDownOriginalPassword}
                            edge="end"
                        >
                        {showOriginalPassword ? 
                            <VisibilityOff /> : 
                            <Visibility />}
                        </IconButton>
                    }
                    sx={{ m: 1}}
                />
            </CustomDialog>
            <CustomDialog
                open={addExtraParamsMode}
                onClose={handleAddExtraParametersDialogClose}
                dialogTitle={{
                    title: "", 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.applyButtonTitleText, 
                    onClick: handleAddExtraParametersToUserList, 
                    variant: "text",
                    size: "medium",
                    disabled: formExtraFields.length === 0
                }]}
            >
                <Stack 
                    direction="row" 
                    spacing={2} 
                    alignItems="center"
                    sx={{my: 3}}
                >
                    <CustomText size="h6">
                        {defaultTexts.extraFormFieldLabel}
                    </CustomText>
                    <PlusButton onClick={() => 
                        setFormExtraFields([
                            ...formExtraFields,
                            {field_key: "",field_value: ""}])
                        } />
                </Stack>
                {formExtraFields.map((item,index) => {
                    return (
                        <Stack
                            key={index}
                            direction="row" 
                            spacing={2} 
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{my: 1}}
                        >
                            <CustomTextField
                                currentValue={item.field_key} 
                                setCurrentValue={handleFormExtraFieldsChange}
                                field_key={[index,"key"]}
                                label={defaultTexts.keyFormFieldLabel}
                            />
                            <CustomTextField
                                currentValue={item.field_value} 
                                setCurrentValue={handleFormExtraFieldsChange}
                                field_key={[index,"value"]}
                                label={defaultTexts.valueFormFieldLabel}
                            />
                            <MinusButton sx={{width: 90}} onClick={() => handleFormExtraFieldsRemove(index)}/>
                        </Stack>
                        
                    )
                })}
            </CustomDialog>
            {successNote && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: successNote.success_title, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: successNote.success_message, 
                    sx: {color: 'text.primary'}}}
            />}
        </Box>
    )
};

export default IdentityUsersWrapperV3;