
export const handleEmptyFields = (field_configs, data) => {
    let updated_data = {...data}
    for (let n in field_configs) {
        
        if (
            field_configs[n].on_empty_null &&
            data[field_configs[n].field_key] !== null &&
            data[field_configs[n].field_key].length === 0
        ) {
            updated_data[field_configs[n].field_key] = null
        }
    }
    return updated_data
}