import React, { useState } from 'react';
import Box from '@mui/material/Box';
import AggregatesWrapperV21 from './aggregates/aggregatesWrapperV2.1';
import HypervisorsWrapperV21 from './hypervisors/hypervisorsWrapperV2.1';
import AvailabilityZonesWrapperV21 from './availability_zones/availabilityZonesWrapperV2.1';


const InfrastructureWrapperV21 = (props) => {
    const { navigate, location, changeMenuActiveTab } = props
    const [currentTab, setCurrentTab] = useState("aggregates")


    return (
        <Box>
            {currentTab === "aggregates" && 
                <AggregatesWrapperV21
                    navigate={navigate}
                    location={location}
                    changeMenuActiveTab={changeMenuActiveTab}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                />
            }
            {currentTab === "availability_zones" && 
                <AvailabilityZonesWrapperV21
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                />
            }
            {currentTab === "hypervisors" &&
                <HypervisorsWrapperV21
                    navigate={navigate}
                    location={location}
                    changeMenuActiveTab={changeMenuActiveTab}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                />
            }
        </Box>
    )
};

export default InfrastructureWrapperV21;