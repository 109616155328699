import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import WrapperBox from '../../../../../_common/WrapperBox';
import AddButton from '../../../../../_common/AddButton';
import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/FormGroup';
import Constants from '../../../../../../config/constants';
import FilterButton from '../../../../../_common/FilterButton';
import CustomDialog from '../../../../../_common/CustomDialog';
import { 
    openStackServices, 
    networkNeutronConstants
} from '../../../../../../config/openStackConstants';
import { 
    vpnEndpointGroupDataForm,
    vpnEGCidrDataForm,
    vpnEGSubnetDataForm
} from '../../../../../../_data/openstack/neutron/vpn/v2.0';
import { getFormFieldComponent } from 
'../../../../../_common/_form_fields/form_helpers';
import { 
    getXAuthTokenProjectScope,
    openstackRequest 
} from '../../../../../../_network/openstack_request';
import { vpnUrl as vpnUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/vpn/v2.0';
import Grid from '@mui/material/Grid';
import PlusButton from '../../../../../_common/PlusButton';
import MinusButton from '../../../../../_common/MinusButton';
import CustomText from '../../../../../_common/CustomText';

const SERVICE_NAME = openStackServices.networkService

const VPNEndpointGroupsSubheaderV20 = (props) => {
    const { currentTab, handleTabChange } = props
    const { selectedFilter, setSelectedFilter } = props
    const { selectedFilterValue, setSelectedFilterValue } = props
    const { handleDataFetch } = props
    const { filterMenu, projects, subnets } = props
    const { handleFilteredSearch, handleFilterReset } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [error, setError] = useState()
    const [successVPNEndpointGroupAdd, setSuccessVPNEndpointGroupAdd] = useState()
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [formData, setFormData] = useState({})
    const [formDataOptions, setFormDataOptions] = useState({});
    const [cidrFormData, setCidrFormData] = useState([]);
    const [subnetFormData, setSubnetFormData] = useState({subnet: []});

    const theme = useTheme();

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const vpnEndpointGroupsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.vpnEndpointGroupsUrl)[0].url)

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleSuccessDialogClose = () => {
        setSuccessVPNEndpointGroupAdd(null);
        setSuccessDialogOpen(false);
    }

    const handleAddVPNEndpointGroupFormReset = () => {
        setFormDataOptions({})
        setFormData({})
    }

    const handleFormDataChange = (event,field_key) => {
        setFormDataOptions({})
        let new_form_data = {...formData}
        if (vpnEndpointGroupDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (vpnEndpointGroupDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setFormData(new_form_data)
    }

    const handleCidrFormDataChange = (event, field_key, index) => {
        let new_form_data = [...cidrFormData]
        new_form_data[index][field_key] = event.target.value.trim()
        setCidrFormData(new_form_data)
    }

    const handleSubnetFormDataChange = (event,field_key) => {
        let new_form_data = {...subnetFormData}
        if (vpnEGSubnetDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setSubnetFormData(new_form_data)
    }

    const handleCidrAdd = () => {
        let new_form_data = {}
        new_form_data["cidr"] = ""
        const updated_data = [...cidrFormData]
        updated_data.push(new_form_data)
        setCidrFormData(updated_data)
    }

    const handleCidrRemove = () => {
        let new_data = [...cidrFormData]
        new_data.pop()
        setCidrFormData(new_data)
    }

    const getDataForm = () => {
        let form = [...vpnEndpointGroupDataForm]
        return (
            <FormGroup>
                {form.map(field => {
                    let form_field_options = {...formDataOptions[field.field_key]}
                    form_field_options = {...form_field_options, ...field}
                    delete form_field_options["label"]
                    form_field_options["item_titles"] = defaultTexts
                    if (field.field_key === "project_id") {
                        const project_list = projects.map(p => {
                            return {keyword: p.name, value: p.id, default: false}
                        })
                        form_field_options["items"] = [...project_list]
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = false
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            formData,
                            handleFormDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
                {formData.type === "cidr" &&
                    <FormGroup>
                    {cidrFormData.map((cidr,index) => {
                        return (
                            <div key={index}>
                            <CustomText>{defaultTexts.cidrFormFieldLabel} {index + 1}</CustomText>
                                {vpnEGCidrDataForm.map(item => {
                                    return (
                                        getFormFieldComponent(
                                            item,
                                            cidr,
                                            handleCidrFormDataChange,
                                            defaultTexts[item.label],
                                            {index: index}
                                        )
                                    )
                                })}
                            </div>
                        )
                        })}
                        {cidrFormData.length > 0 && <MinusButton 
                            style={{ transform: 'scale(0.7)' }}
                            sx={{height: 5}}
                            onClick={handleCidrRemove}
                        />}
                        {<Stack 
                            direction="row" 
                            spacing={2} 
                            alignItems="center"
                            onClick={handleCidrAdd}
                            sx={{cursor: "pointer"}}
                        >
                            <PlusButton 
                                style={{ transform: 'scale(0.7)' }}
                                sx={{height: 5}}
                                onClick={handleCidrAdd}
                            />
                            <CustomText>
                                {defaultTexts.addCidrFormFieldLabel}
                            </CustomText>
                        </Stack>}
                </FormGroup>
                }
                {formData.type === "subnet" && formData.project_id&&
                    <FormGroup>
                    {vpnEGSubnetDataForm.map(field => {
                        let options = {...field}
                        const subnet_list = subnets.filter(s => s.project_id === formData.project_id)
                        const subnet_filter = subnet_list.map(i => {
                                return {keyword: `${i.name}: ${i.cidr}`, value: i.id, default: false}
                            })
                        options["items"] = [...subnet_filter]
                        options["empty"] = true
                        delete options.label
                        return (
                            getFormFieldComponent(
                                field,
                                subnetFormData,
                                handleSubnetFormDataChange,
                                defaultTexts[field.label],
                                {...options}
                            )
                        )
                    })}
                </FormGroup>
                }
            </FormGroup>
        )
    }

    const handleFormDataValidation = () => {
        let validation_faild = true
        let updatedDataFormOptions = {...formDataOptions}
        for (let n in vpnEndpointGroupDataForm) {
            if (vpnEndpointGroupDataForm[n].required && !formData[vpnEndpointGroupDataForm[n].field_key]) {
                validation_faild = false
                updatedDataFormOptions[vpnEndpointGroupDataForm[n].field_key] = {}
                updatedDataFormOptions[vpnEndpointGroupDataForm[n].field_key]["error"] = true
                updatedDataFormOptions[vpnEndpointGroupDataForm[n].field_key]["errorText"] = defaultTexts[vpnEndpointGroupDataForm[n].error_label]
            }
        }

        setFormDataOptions(updatedDataFormOptions)
        return validation_faild
    }

    const formatRuleData = () => {
        let updated_data = {...formData}
        if (formData.type === "cidr") {
            const cidr_list = cidrFormData.filter(i => i.cidr.trim().length > 0)
            const cidrs = cidr_list.map(i => i.cidr)
            updated_data["endpoints"] = cidrs
        } else {
            updated_data["endpoints"] = subnetFormData.subnet
        }
        return updated_data
    }

    const onAddVPNEndpointGroup = async () => {
        const data_is_valid = handleFormDataValidation()
        if (data_is_valid) {
            const formatted_data = formatRuleData()
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const method = "POST"
                const vpnGroup_response = await openstackRequest({
                    url: `${neutronServiceDomain}/${neutronServiceVersion}/${vpnEndpointGroupsUrl}`, 
                    method: method, 
                    data: {endpoint_group: formatted_data},
                    token: project_token
                })
                if (vpnGroup_response.status_code === vpnUrlResponses.post.success_response.status_code) {
                    handleAddVPNEndpointGroupFormReset()
                    handleDataFetch()
                } else {
                    const error_response = vpnUrlResponses.post.error_response.filter(
                        error_item => error_item.status_code === vpnGroup_response.status_code)
                    if (error_response.length > 0) {
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: vpnGroup_response.error
                        }
                        setError(errorObject)
                    } else {
                        const error_response = vpnUrlResponses.post.error_response.filter(
                            error_item => error_item.status_code === "unknown")
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: vpnGroup_response.error
                        }
                        setError(errorObject)
                    }
                }
            }
        } 
        return data_is_valid
    }

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessDialogOpen(true)
    },[successVPNEndpointGroupAdd]);

    useEffect(() => {
        if (Object.keys(formData).length === 0) {
            let new_form_data = {}
            for (const n in vpnEndpointGroupDataForm) {
                if (
                    vpnEndpointGroupDataForm[n].field_type === "string" || 
                    vpnEndpointGroupDataForm[n].field_type === "select"
                    ) {
                    new_form_data[vpnEndpointGroupDataForm[n].field_key] = vpnEndpointGroupDataForm[n].default_value ? 
                    vpnEndpointGroupDataForm[n].default_value : 
                    ""
                } else if (vpnEndpointGroupDataForm[n].field_type === "integer") {
                    new_form_data[vpnEndpointGroupDataForm[n].field_key] = 3600
                } else if (vpnEndpointGroupDataForm[n].field_type === "bool") {
                    new_form_data[vpnEndpointGroupDataForm[n].field_key] = vpnEndpointGroupDataForm[n].default_value ? 
                    vpnEndpointGroupDataForm[n].default_value : 
                    false
                }
            }
            setFormData(new_form_data)
        }
    },[
        formData
    ]);

    return (
        <WrapperBox 
            sx={{
                flexDirection: "row", 
                justifyContent: 'space-between',
                mt: 1
            }}>
            <Grid 
                container 
                justifyContent='space-between' 
                alignItems="center">
                <Grid item>
            <AddButton 
                getDataForm={getDataForm}               
                onSubmit={onAddVPNEndpointGroup}
                formReset={handleAddVPNEndpointGroupFormReset}
                customTexts={{
                    title: defaultTexts.addVPNEndpointGroupDialogTitle
                }}
            />
            </Grid>
            <Grid item sx={{flexGrow: 1}}>
            <Button
                onClick={() => handleTabChange("ike_policies")}
                sx={{
                    color: currentTab === "ike_policies" ? 
                    "lightBlue" : 
                    theme.palette.text.primary,
                    "&:hover": {
                        backgroundColor: "lightBlue",
                        color: "white"
                    }
                }}
            >
                {defaultTexts.ikePoliciesTabButtonText}
            </Button>
            <Button
                onClick={() => handleTabChange("ipsec_policies")}
                sx={{
                    color: currentTab === "ipsec_policies" ? 
                    "lightBlue" : 
                    theme.palette.text.primary,
                    "&:hover": {
                        backgroundColor: "lightBlue",
                        color: "white"
                    }
                }}
            >
                {defaultTexts.ipsecPoliciesTabButtonText}
            </Button>
            <Button
                onClick={() => handleTabChange("vpn_services")}
                sx={{
                    color: currentTab === "vpn_services" ? 
                    "lightBlue" : 
                    theme.palette.text.primary,
                    "&:hover": {
                        backgroundColor: "lightBlue",
                        color: "white"
                    }
                }}
            >
                {defaultTexts.vpnServicesTabButtonText}
            </Button>
            <Button
                onClick={() => handleTabChange("endpoint_groups")}
                sx={{
                    color: currentTab === "endpoint_groups" ? 
                    "lightBlue" : 
                    theme.palette.text.primary,
                    "&:hover": {
                        backgroundColor: "lightBlue",
                        color: "white"
                    }
                }}
            >
                {defaultTexts.endpointGroupsTabButtonText}
            </Button>
            <Button
                onClick={() => handleTabChange("site_connections")}
                sx={{
                    color: currentTab === "site_connections" ? 
                    "lightBlue" : 
                    theme.palette.text.primary,
                    "&:hover": {
                        backgroundColor: "lightBlue",
                        color: "white"
                    }
                }}
            >
                {defaultTexts.siteConnectionsTabButtonText}
            </Button>
                </Grid>
                <Grid item>
                <FilterButton 
                    choice_mode={Constants.simple_filter_type}
                    currentFilter={selectedFilter}
                    setCurrentFilter={setSelectedFilter}
                    filter_menu_titles={defaultTexts}
                    filter_menu={filterMenu}
                    currentFilterValue={selectedFilterValue}
                    setCurrentFilterValue={setSelectedFilterValue}
                    onFilterSubmit={handleFilteredSearch}
                    onFilterReset={handleFilterReset}
                />
                </Grid>
            </Grid>
            {successVPNEndpointGroupAdd && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[successVPNEndpointGroupAdd.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successVPNEndpointGroupAdd.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </WrapperBox>
    )
};

export default VPNEndpointGroupsSubheaderV20