import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomDrawer from '../../_common/CustomDrawer';
import DNSDesignateContent from './dnsDesignateContent';

const DNSDesignateWrapper = (props) => {
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
    const navigate = useNavigate();

    if (!isAuthenticated) {
        navigate('/');
    }

    return (
        <CustomDrawer>
            <DNSDesignateContent />
        </CustomDrawer>
    )
}

export default DNSDesignateWrapper;