import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import IdentityKeystoneProjectsSubheaderV3 from 
'./identityKeystoneProjectsSubheaderV3';
import IdentityProjectsTableV3 from './identityProjectsTableV3';
import { identityKeystonConstants } from 
'../../../../../config/openStackConstants';
import { openstackRequest } from 
'../../../../../_network/openstack_request';
import { projectsUrl as projectsUrlResponses } from 
'../../../../../_api_responses/openstack/identity/projects/v3';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { projectsfilterMenu } from 
'../../../../../_data/openstack/identity/projects/v3';
import CustomDialog from '../../../../_common/CustomDialog';
import CustomSideDrawer from '../../../../_common/CustomSideDrawer';
import IdentityProjectDetailV3 from './identityProjectDetailV3';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import useWindowDimensions from '../../../../_common/WindowDimensions';
import Dimensions from '../../../../../config/dimensions';
import Box from '@mui/material/Box';

const SERVICE_NAME = openStackServices.identityService

const getWidthWeight = (width) => {
    if (width < Dimensions.tablet_mini.width) {
        return 0.9
    } else if (width < Dimensions.tablet.width) {
        return 0.6
    } else {
        return 0.4
    }
}

const IdentityProjectsWrapperV3 = (props) => {
    const [isLoading, setIsLoading ] = useState(true);
    const [filterMenu, setFilterMenu] = useState(projectsfilterMenu)
    const [projectsData, setProjectsData] = useState([])
    const [projects, setProjects] = useState([])
    const [domains, setDomains] = useState([]);
    const [domainProjects, setDomainProjects] = useState([]);
    const [error, setError] = useState()
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = getWidthWeight(width)
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const [selectedFilter, setSelectedFilter ] = useState(filterMenu[0].value)
    const [selectedFilterValue, setSelectedFilterValue] = useState("");
    const [selectedTagsFilterValue, setSelectedTagsFilterValue] = useState({});
    const [filterQueryParams, setFilterQueryParams] = useState("")
    const apiResponseTexts = useSelector(state => state.texts.langTexts)

    const [fetchDataRequired, setFetchDataRequired] = useState(true);

    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)

    const domainsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.domainsUrl)[0].url)

    const handleDataFetch = () => {
        setFetchDataRequired(true)
    }

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleFilteredSearch = () => {
        if (selectedFilter && selectedFilterValue) {
            setFilterQueryParams(`/?${selectedFilter}=${selectedFilterValue}`)
        } else {
            setFilterQueryParams("")
        }
    }

    const handleTagsFilteredSearch = () => {
        
        if (selectedFilter && selectedTagsFilterValue) {
            let query_params = "/?"
            for (let n in selectedTagsFilterValue) {
                
                if (selectedTagsFilterValue[n] && selectedTagsFilterValue[n].length > 0) {
                    let normalized_value = selectedTagsFilterValue[n].trim()
                    if (normalized_value.length > 0) {
                        let re = new RegExp("\\s*,\\s*", "g")
                        query_params = query_params + `${n}=${encodeURI(normalized_value.replace(re,","))}&&`
                    }
                }
            }
            
            setFilterQueryParams(query_params)
        } else {
            setFilterQueryParams("")
        }
    }

    const handleFilterReset = () => {
        setSelectedFilter(filterMenu[0].value)
        setSelectedFilterValue("")
        setSelectedTagsFilterValue({})
        setFilterQueryParams("")
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleDetailCardOpen = (index) => {
        setSelectedRow(index)
        setTimeout(() => setDetailCardOpen(true), 100) 
    };

    const handleDetailCardClose = () => {
        setSelectedRow(null)
        setTimeout(() => setDetailCardOpen(false), 100) 
    };

    const handleSelectedTagsFilterValueChange = (event,field_key) => {
        
        let update_values = {...selectedTagsFilterValue}
        update_values[field_key] = event.target.value
        
        
        setSelectedTagsFilterValue(update_values)
    }

    useEffect(() => {
        setFetchDataRequired(true)
    },[filterQueryParams]);

    useEffect(() => {
        const domains_filter = domains.map(domain => {
            return {keyword: domain.name, value: domain.id, default: false}
        })

        const parent_filter = domainProjects.map(project => {
            return {keyword: project.name, value: project.id, default: false}
        })

        const new_filter_menu = projectsfilterMenu.map(item => {
            if (item.value === "domain_id") {
                item.items = domains_filter
            } else if (item.value === "parent_id") {
                item.items = parent_filter
            }
            return item
        })
        setFilterMenu(new_filter_menu)

        let updated_projects_data = projectsData.map(project => {
            const domain = domains.filter(item => item.id === project.domain_id)
            const parent = domainProjects.filter(item => item.id === project.parent_id)
            
            if (domain.length > 0) {
                project.domain_id = domain[0].name
            }

            if (parent.length > 0) {
                project.parent_id = parent[0].name
            }
            return project
        })
        setProjects(updated_projects_data)
        setIsLoading(false)
    },[
        domains, 
        projectsData, 
        domainProjects
    ]);

    useEffect(() => {
        if (Object.keys(selectedTagsFilterValue).length === 0) {
            const tags_filter = projectsfilterMenu.filter(f => f.value === "tags_filter")
            let current_tag_filter_values = {}
            if (tags_filter.length > 0) {
                for (let n in tags_filter[0].fields) {
                    current_tag_filter_values[tags_filter[0].fields[n].value] = ""
                }
            }
            
            setSelectedTagsFilterValue(current_tag_filter_values)
        }
    },[selectedTagsFilterValue]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${domainsUrl}`
            const method = "GET"

            const domains_response = await openstackRequest({url:url, method:method})
            if (domains_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                setDomains([...domains_response.data.domains])
            } else {
                setDomains([])
            }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        domainsUrl,
        projectsData
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}/?is_domain=true`
            const method = "GET"

            const domain_projects_response = await openstackRequest({url:url, method:method})
            
            if (domain_projects_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                setDomainProjects([...domain_projects_response.data.projects])
            } else {
                setDomainProjects([])
            }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl,
        projectsData
    ]);

    useEffect(() => {
        handleLoading(true)
        if (fetchDataRequired) {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}${filterQueryParams}`
            const method = "GET"

            const projects_response = await openstackRequest({url:url, method:method})
            if (projects_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                setProjectsData([...projects_response.data.projects])
            } else {
                const error_response = projectsUrlResponses.get.error_response.filter(
                    error_item => error_item.status_code === projects_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: projects_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = projectsUrlResponses.get.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: projects_response.error
                    }
                    setError(errorObject)
                }
            }
        })();
        setFetchDataRequired(false)
        }
        setTimeout(()=>{handleLoading(false)},700)
    },[
        identityServiceDomain, 
        identityServiceVersion, 
        projectsUrl, 
        filterQueryParams,
        fetchDataRequired
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <IdentityKeystoneProjectsSubheaderV3 
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                selectedFilterValue={selectedFilter === "tags_filter" ? selectedTagsFilterValue : selectedFilterValue}
                setSelectedFilterValue={selectedFilter === "tags_filter" ? handleSelectedTagsFilterValueChange : setSelectedFilterValue}
                filterMenu={filterMenu}
                handleFilteredSearch={selectedFilter === "tags_filter" ? handleTagsFilteredSearch : handleFilteredSearch}
                handleFilterReset={handleFilterReset}
                handleFetchData={handleDataFetch}
                domains={domains}
                domainProjects={domainProjects}
            />
            {isLoading && <CustomBackdrop open={isLoading}/>}
            {!isLoading && <IdentityProjectsTableV3 
                projectsData={projects}
                handleRowSelection={handleDetailCardOpen}
            />}
            {selectedRow !== null && <CustomSideDrawer 
                open={detailCardOpen}
                widthWeight={WIDTH_WEIGHT}
                handleDrawerOpen={handleDetailCardOpen}
                handleDrawerClose={handleDetailCardClose}
            > 
                <IdentityProjectDetailV3 
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRow}
                    widthWeight={WIDTH_WEIGHT}
                    handleFetchData={handleDataFetch}
                    handleDrawerClose={handleDetailCardClose}
                    projectsData={projects}
                />            
            </CustomSideDrawer>}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: apiResponseTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${apiResponseTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>Details:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`,
                    sx: {color: 'text.primary'}}}
            />}
        </Box>
    )
};

export default IdentityProjectsWrapperV3;