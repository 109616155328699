import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../../../_common/CustomTable';
import { groupDataSchema } from 
'../../../../../_data/openstack/identity/groups/v3';
import { setIdentityGroupsTableConfig } from 
'../../../../../store/reducers/identityKeystoneSlice';

const IdentityGroupsTableV3 = (props) => {
    const { groupsData } = props
    const { handleRowSelection } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);
    
    const groupsTableConfig = useSelector(
        state => state.identityKeystone.identityGroupsTableConfig)
    const rowsPerPage = useSelector(
        state => state.identityKeystone.identityGroupsTableConfig.filter(
        versions => versions.api_version === "v3")[0].rows_per_page)
    const groupsTableColumns = useSelector(
        state => state.identityKeystone.identityGroupsTableConfig.filter(
            versions => versions.api_version === "v3")[0].columns)
    
    const identityField = groupsTableColumns.filter(
        item => item.is_id_field)[0].field_key

    const dispatch = useDispatch();

    const handleGroupTableConfig = (value_list) => {
        const newTableConfig = groupDataSchema.filter(
            n => value_list.includes(n.field_key))
        const new_data = groupsTableConfig.map(version => {
            if (version.api_version === "v3") {
                let new_item = {...version}
                new_item.columns = [...newTableConfig]
                return new_item
            } else {
                return version
            }
        })
        dispatch(setIdentityGroupsTableConfig({
            identityGroupsTableConfig: new_data
        }))
    }
    
    const handleRowsPerPageChange = (rows_num) => {
        const new_data = groupsTableConfig.map(version => {
            if (version.api_version === "v3") {
                let new_item = {...version}
                new_item.rows_per_page = rows_num
                return new_item
            } else {
                return version
            }
        })
        dispatch(setIdentityGroupsTableConfig({
            identityGroupsTableConfig: new_data
        }))
    }

    return (
        <React.Fragment>
            <CustomTable
                withPagination={true}
                defaultDataTexts={defaultTexts} 
                tableHeaders={groupsTableColumns}
                tableHeadersConfigHandler={handleGroupTableConfig}
                identityField={identityField}
                columns={groupDataSchema}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                dataRows={groupsData}
                handleRowSelection={handleRowSelection}
            />
        </React.Fragment>
    )
};

export default IdentityGroupsTableV3;