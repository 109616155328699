import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import CustomDismissCard from '../../../../_common/CustomDismissCard';
import CustomCard from '../../../../_common/CustomCard';
import { Divider, Grid } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import {Box} from '@mui/material';
import { Stack } from '@mui/material';
import { Button }  from '@mui/material';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { BsCpu } from "react-icons/bs";
import { GrMemory } from "react-icons/gr";
import { RiComputerLine } from "react-icons/ri";
import { HiMiniServerStack } from "react-icons/hi2";
import { GiKeyCard } from "react-icons/gi";
import { LuFileJson2 } from "react-icons/lu";
import { LuGroup } from "react-icons/lu";
import { RxReset } from "react-icons/rx";
import useWindowDimensions from '../../../../_common/WindowDimensions';
import Dimensions from '../../../../../config/dimensions';
import CustomTextField from '../../../../_common/_form_fields/CustomTextField';
import { openstackRequest, getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { computeNovaConstants } from 
'../../../../../config/openStackConstants';
import { flavorsUrl as flavorsUrlResponses } from 
'../../../../../_api_responses/openstack/compute/flavors/v2.1';
import IconButton from '@mui/material/IconButton';

const SERVICE_NAME = openStackServices.computeService

const ComputeLimitsDetailV21 = (props) => {
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const { data, setData, projectName } = props
    const { width } = useWindowDimensions();
    const { onCardClose } = props
    const [editMode, setEditMode] = useState(false)
    const [editedData, setEditedData] = useState({...data})
    const defaultTexts = useSelector(state => state.texts.langTexts)

    const pieChartTexts = [
        {
            field_key: "maxTotalCores", 
            used_key: "totalCoresUsed", 
            icon: BsCpu, 
            title: defaultTexts.cpuCoresTitleText, 
            legend: [
                defaultTexts.availableCPUCoresLegendText,
                defaultTexts.totalUsedCPUCoresLegendText
            ]
        },
        {
            field_key: "maxTotalRAMSize", 
            used_key: "totalRAMUsed", 
            icon: GrMemory, 
            title: defaultTexts.ramTitleText, 
            legend: [
                defaultTexts.availableRAMGBLegendText,
                defaultTexts.totalUsedRAMGBLegendText
            ]
        },
        {
            field_key: "maxTotalInstances", 
            used_key: "totalInstancesUsed", 
            icon: RiComputerLine, 
            title: defaultTexts.serverInstancesTitleText, 
            legend: [
                defaultTexts.availableInstancesLegendText,
                defaultTexts.totalUsedInstancesLegendText
            ]
        },
        {
            field_key: "maxServerGroups", 
            used_key: "totalServerGroupsUsed", 
            icon: HiMiniServerStack, 
            title: defaultTexts.serverGroupsTitleText, 
            legend: [
                defaultTexts.availableServerGroupsLegendText,
                defaultTexts.totalUsedServerGroupsLegendText
            ]
        }
    ]

    const sideCardTexts = [
        {
            field_key: "maxTotalKeypairs", 
            icon: GiKeyCard, 
            title: defaultTexts.totalAllowedKeypairsTitleText
        },
        {
            field_key: "maxServerMeta", 
            icon: LuFileJson2, 
            title: defaultTexts.totalAllowedServerMetadataTitleText
        },
        {
            field_key: "maxServerGroupMembers", 
            icon: LuGroup, 
            title: defaultTexts.totalAllowedServerGroupMembers
        }
    ]

    const theme = useTheme();

    const computeServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const computeServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const quotaSetsUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.quotaSetsUrl)[0].url)

    const onLimitsEdit = (event,field_key) => {
        let updated_data = {...editedData}
        updated_data[field_key] = event.target.value
        setEditedData(updated_data)
    }

    const handleLimitsEdit = async () => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${computeServiceDomain}/${computeServiceVersion}/${quotaSetsUrl}/${data.id}`
            const method = "PUT"
            let updated_data = {}
            updated_data["cores"] = editedData.maxTotalCores
            updated_data["instances"] = editedData.maxTotalInstances
            updated_data["key_pairs"] = editedData.maxTotalKeypairs
            updated_data["metadata_items"] = editedData.maxServerMeta
            updated_data["ram"] = editedData.maxTotalRAMSize
            updated_data["server_groups"] = editedData.maxServerGroups
            updated_data["server_group_members"] = editedData.maxServerGroupMembers
            const data_to_send = {"quota_set": updated_data}
            const limits_response = await openstackRequest({
                url:url, 
                method:method, 
                data: data_to_send, 
                token: project_token
            })
            if ( limits_response.status_code === flavorsUrlResponses.put.success_response.status_code) {
                setData(editedData,data.id)
                setEditMode(false)
            }
        }
    }

    return (
        <CustomDismissCard onClose={onCardClose} cardID={data.id}>
            <Stack sx={{m: 2}} direction="row" justifyContent="space-between" alignItems="center">
                <CustomText size="h6" sx={{color: "dashboardPurple"}}>{projectName}</CustomText>
                <div>
                {!editMode && <Button 
                    color="primary" 
                    variant="outlined"
                    onClick={() => setEditMode(true)}
                >
                    {defaultTexts.editButtonText}
                </Button>}
                {editMode && 
                    <IconButton sx={{mr: 1}} onClick={() => setEditMode(false)}>
                        <RxReset 
                            color={theme.palette.text.primary}
                            fontSize="large"
                            
                        />
                    </IconButton>
                }
                {editMode && <Button 
                    color="primary" 
                    variant="outlined"
                    onClick={handleLimitsEdit}
                >
                    {defaultTexts.saveButtonText}
                </Button>}
                </div>
            </Stack>
            <Box sx={{display: "flex", m: 1, mr: 2}}>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={8}>
                    <Grid container spacing={2}>
                    {pieChartTexts.map((item) => {
                        return (
                            <Grid item xs={12} md={6} key={item.field_key}>
                                <CustomCard 
                                    cardWidth={'100%'} 
                                    cardHeight={220} 
                                    card_sx={{
                                        border: theme.palette.mode === "dark" ? 0 : 1, 
                                        boxShadow: 0, 
                                        backgroundColor: 
                                            theme.palette.mode === "dark" ? 
                                            undefined :
                                            "vLightGray"
                                    }}
                                    box_sx={{px: 1}}
                                >
                                <Stack 
                                    direction="row" 
                                    alignItems="center" 
                                    justifyContent="center"
                                    spacing={2}
                                >
                                    <item.icon 
                                        size={
                                            width < Dimensions.tablet.width ? 
                                            22 : 
                                            28
                                        } 
                                    />
                                    <CustomText 
                                        size="p" 
                                        sx={{fontSize: {xs: 14, lg: 16}}}
                                    >
                                        {item.title}
                                    </CustomText>
                                </Stack>
                                {!editMode && <CustomText 
                                    size="h6" 
                                    sx={{
                                        mt: 2, 
                                        textAlign: "center", 
                                        color: "primary.main",
                                    }}>
                                        Max {item.field_key === "maxTotalRAMSize" ? 
                                        `${Math.round(data[item.field_key] / 1024)} GB` :
                                         data[item.field_key]}
                                </CustomText>}
                                {editMode && 
                                    <CustomTextField 
                                        currentValue={editedData[item.field_key]}
                                        setCurrentValue={onLimitsEdit}
                                        field_key={item.field_key}
                                        label={item.title}
                                        type="number"
                                        size="large"
                                        min={1}
                                        sx={{ 
                                            m: 3, 
                                            mt: 5, 
                                            width: "85%"
                                        }}
                                    />
                                }
                                {!editMode && <Stack
                                    direction="row" 
                                    alignItems="center" 
                                    justifyContent="center"
                                    spacing={0}
                                >
                                    <PieChart
                                        series={[
                                            {
                                                arcLabel: (i) => `${i.value}`,
                                                arcLabelMinAngle: 25,
                                                data: [
                                                    { 
                                                        id: 0, 
                                                        value: item.field_key === "maxTotalRAMSize" ? 
                                                            Math.round((data[item.field_key] - data[item.used_key]) / 1024) : 
                                                            data[item.field_key] - data[item.used_key], 
                                                        color: theme.palette.dashboardPurple 
                                                    },
                                                    { 
                                                        id: 1, 
                                                        value: item.field_key === "maxTotalRAMSize" ? data[item.used_key] / 1024 : data[item.used_key], 
                                                        color:  theme.palette.dashboardPink
                                                    }
                                                ],
                                            },
                                        ]}
                                        sx={{
                                            [`& .${pieArcLabelClasses.root}`]: {
                                            fill: 'white',
                                            fontWeight: 'bold',
                                            },
                                        }}
                                        width={220}
                                        height={100}
                                    />
                                <Stack
                                    direction="column" 
                                    alignItems="start" 
                                    justifyContent="center"
                                    spacing={1}
                                    sx={{width: "50%"}}
                                >       
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                    spacing={1}
                                >
                                    <Box 
                                        sx={{
                                            backgroundColor: "dashboardPurple",
                                            width: 15,
                                            height: 15
                                        }}
                                    />
                                    <CustomText 
                                        size="p" 
                                        sx={{fontSize: {xs: 12, lg: 14}}}
                                    >
                                        {item.legend[0]}
                                    </CustomText>
                                </Stack>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                    spacing={1}
                                >
                                    <Box 
                                        sx={{
                                            backgroundColor: "dashboardPink",
                                            width: 15,
                                            height: 15
                                        }}
                                    />
                                    <CustomText 
                                        size="p"
                                        sx={{fontSize: {xs: 12, lg: 14}}}
                                    >
                                    {item.legend[1]}
                                    </CustomText>
                                </Stack>
                            </Stack>
                            </Stack>}
                            </CustomCard>
                        </Grid>
                        )
                    })}
                    </Grid>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <CustomCard 
                        cardWidth={'100%'} 
                        cardHeight={455} 
                        card_sx={{
                            border: theme.palette.mode === "dark" ? 0 : 1, 
                            boxShadow: 0, 
                            backgroundColor: 
                                theme.palette.mode === "dark" ? 
                                undefined : 
                                "vLightGray"
                        }}
                    >
                        <Stack
                            direction="column"
                            spacing={1}
                            sx={{m: 3, mt:5}}
                        >
                        {sideCardTexts.map(object => {
                            return (
                                !editMode ? <React.Fragment key={object.field_key}>
                                    <Grid container
                                        alignItems="center"
                                        justifyContent="center"
                                    >
                                        <Grid item xs={3}>
                                            <object.icon size={38} />
                                        </Grid>
                                        <Grid item xs={9}>
                                            <Stack 
                                                direction="column"
                                                justifyContent="center"
                                                alignItems="center"
                                                spacing={2}
                                            >
                                                <CustomText 
                                                    size="p" 
                                                    sx={{
                                                        color: "primary.main", 
                                                        fontSize: {xs: 20, lg: 24}
                                                    }}>
                                                        {data[object.field_key]}
                                                </CustomText>
                                                <CustomText 
                                                    size="p"
                                                    sx={{fontSize: {xs: 14, lg: 16}}}
                                                >
                                                    {object.title}
                                                </CustomText>
                                            </Stack>
                                        </Grid>
                                    </Grid>
                                    <Divider />
                                </React.Fragment> :
                                <div key={object.field_key}>
                                    <CustomTextField 
                                        currentValue={editedData[object.field_key]}
                                        setCurrentValue={onLimitsEdit}
                                        field_key={object.field_key}
                                        label={object.title}
                                        type="number"
                                        size="large"
                                        min={1}
                                        sx={{ 
                                            my: 2,
                                            width: "95%"
                                        }}
                                    />
                                </div>
                            )})}
                        </Stack>
                    </CustomCard>
                </Grid>
            </Grid>
            </Box>
        </CustomDismissCard>
    )
};

export default ComputeLimitsDetailV21;