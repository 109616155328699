import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CustomDialog from '../../../../_common/CustomDialog';
import useWindowDimensions from '../../../../_common/WindowDimensions';
import Dimensions from '../../../../../config/dimensions';
import CustomSideDrawer from '../../../../_common/CustomSideDrawer';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import { openStackServices, 
        blockStorageCinderConstants, 
        identityKeystonConstants
} from '../../../../../config/openStackConstants';
import GroupsSubheaderV3 from './groupsSubheaderV3';
import GroupsTableV3 from './groupsTableV3';
import { volumeGroupFilterMenu } 
from '../../../../../_data/openstack/cinder/groups/v3';
import { 
    volumeCinderRequest, 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../_network/openstack_request';
import { volumeGroupsUrl as groupUrlResponses } 
from '../../../../../_api_responses/openstack/cinder/groups/v3';
import GroupDetailV3 from './groupDetailV3';
import { projectsUrl as projectsUrlResponses } from 
'../../../../../_api_responses/openstack/identity/projects/v3';
import { volumesUrl as volumeUrlResponses } from 
'../../../../../_api_responses/openstack/cinder/volumes/v3';
import CustomCheckboxField from '../../../../_common/_form_fields/CustomCheckboxField';

const SERVICE_NAME = openStackServices.volumeService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const getWidthWeight = (width) => {
    if (width < Dimensions.tablet_mini.width) {
        return 0.9
    } else if (width < Dimensions.tablet.width) {
        return 0.6
    } else {
        return 0.4
    }
}

const GroupsWrapperV3 = (props) => {
    const { navigate, location, changeMenuActiveTab } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [groupsData, setGroupsData] = useState([])
    const [groups, setGroups] = useState([])
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = getWidthWeight(width)
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [currentAction, setCurrentAction] = useState("");
    const [groupDeleteConfirmDialogOpen, setGroupDeleteConfirmDialogOpen] = useState(false);
    const [selectedGroups, setSelectedGroups] = useState([])
    const [groupsSortParams, setGroupsSortParams] = useState("")
    const [volumeTypes, setVolumeTypes] = useState([]);
    const [volumeTypesList, setVolumeTypesList] = useState([]);
    const [groupTypes, setGroupTypes] = useState([]);
    const [groupTypesList, setGroupTypesList] = useState([]);
    
    const [groupFilterQueryParams, setGroupFilterQueryParams] = useState("?all_tenants=true")
    const [selectedGroupFilter, setSelectedGroupFilter] = useState(volumeGroupFilterMenu[0].value)
    const [selectedGroupFilterValue, setSelectedGroupFilterValue] = useState("")
    const [groupsFilter, setGroupsFilter] = useState([...volumeGroupFilterMenu])
    const [volumeDeleteCascade, setVolumeDeleteCascade] = useState(false)

    const [projects, setProjects] = useState([])
    const [volumes, setVolumes] = useState([])
    const [snapshots, setSnapshots] = useState([])

    const cinderServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const cinderServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const groupsUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.groupsUrl)[0].url)
    const volumesUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.volumesUrl)[0].url)
    const volumeTypesUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.volumeTypesUrl)[0].url)
    const groupTypesUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.groupTypesUrl)[0].url)
    const snapshotsUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.snapshotsUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleGroupFilteredSearch = () => {
        if (selectedGroupFilter && selectedGroupFilterValue) {
            setGroupFilterQueryParams(`?${selectedGroupFilter}=${selectedGroupFilterValue}&&all_tenants=true`)
        } else {
            setGroupFilterQueryParams("?all_tenants=true")
        }
        handleDataFetch()
    }

    const handleGroupsDataFormatting = useCallback(() => {
        const formatted_data = groupsData.map((item) => {
            let new_item = {...item}
            const project = projects.filter(p => p.id === item.project_id)
            if (project.length > 0) {
                new_item.project_id = project[0].name
            }
            const group_type = groupTypes.filter(g => g.id === item.group_type)
            if (group_type.length > 0) {
                new_item.group_type = group_type[0].name ? group_type[0].name : group_type[0].id
            }
            return new_item
        })
        setGroups(formatted_data)
    },[
        groupsData,
        groupTypes,
        projects
    ])

    const handleGroupFilterReset = () => {
        setSelectedGroupFilter(volumeGroupFilterMenu[0].value)
        setSelectedGroupFilterValue("")
        setGroupFilterQueryParams("?all_tenants=true")
        handleDataFetch()
    }

    const handleNavigateToVolume = (volume_id,tab_name) => {
        handleDetailCardClose()
        changeMenuActiveTab(tab_name)
        navigate("/block-storage",{state: {volume_id: volume_id}})
    }

    const handleNavigateToGroupType = (group_type,tab_name) => {
        handleDetailCardClose()
        changeMenuActiveTab(tab_name)
        navigate("/block-storage",{state: {group_type: group_type}})
    }

    const handleNavigateToVolumeType = (volume_type_id,tab_name) => {
        handleDetailCardClose()
        changeMenuActiveTab(tab_name)
        navigate("/block-storage",{state: {volume_type_id: volume_type_id}})
    }

    const handleDetailCardOpen = useCallback((index) => {
        setSelectedGroup(groupsData[index].id)
        setSelectedRow(groupsData[index])
        setTimeout(() => setDetailCardOpen(true),100)
    },[groupsData]);

    const handleDetailCardClose = () => {
        setTimeout(() => setDetailCardOpen(false),100)
        setSelectedRow(null)
        setSelectedGroup(null)
        navigate(location.path,{})
    };

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleGroupsSorting = (field,direction) => {
        const sort_param = `&&sort=${field}:${direction}`
        setGroupsSortParams(sort_param)
        handleDataFetch()
    }

    const handleGroupDelete = async (s_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupsUrl}/${s_id}/action`
            const method = "POST"
            const vt_response = await volumeCinderRequest({
                url:url, 
                method:method,
                data: {"delete": {"delete-volumes": volumeDeleteCascade}},
                token: project_token,
                has_response: false
            })
            if (vt_response.status_code === groupUrlResponses.post.success_response.status_code) {
                return null
            } else {
                return vt_response.error
            }
        }
    };

    const onGroupDelete = async () => {
        handleGroupDeleteConfirmDialogClose()
        let err = []
        for (let s in selectedGroups) {
            const resp = await handleGroupDelete(selectedGroups[s].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDetailCardClose()
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onGroupDeleteConfirm = (s_list) => {
        const selected_s_list = groupsData.filter(s => 
            s_list.includes(s.id))
        setSelectedGroups([...selected_s_list])
        setGroupDeleteConfirmDialogOpen(true)
    }

    const handleGroupDeleteConfirmDialogClose = () => {
        setGroupDeleteConfirmDialogOpen(false)
    }

    const getGroupsActionsList = () => {
        let group_actions = []
        let new_action = {}
        new_action["value"] = "group_delete"
        new_action["action"] = onGroupDeleteConfirm
        new_action["keyword"] = "groupDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        group_actions.push({...new_action})
        
        return group_actions
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleVolumeDeleteCascadeChange = (event) => {
        setVolumeDeleteCascade(event.target.checked)
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupsUrl}/detail${groupFilterQueryParams}${groupsSortParams}&&list_volume=true`
                    const method = "GET"
                    const groups_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                    if (groups_response.status_code === groupUrlResponses.get.success_response.status_code) {
                        setGroupsData(groups_response.data.groups)
                        if (selectedGroup) {
                            const selected_group = groups_response.data.groups.filter(item => item.id === selectedGroup)
                            if (selected_group.length > 0) {
                                setSelectedRow(selected_group[0])
                            }
                        }
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        cinderServiceDomain, 
        cinderServiceVersion, 
        groupsUrl, 
        groupFilterQueryParams,
        dataFetchingRequired,
        defaultAdminProject,
        groupsSortParams,
        selectedGroup
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumesUrl}/detail?all_tenants=true`
                const method = "GET"

                const volume_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                if (volume_response.status_code === volumeUrlResponses.get.success_response.status_code) {
                    setVolumes(volume_response.data.volumes)
                }
            }
        })();
    },[
        cinderServiceDomain,
        cinderServiceVersion,
        defaultAdminProject,
        volumesUrl
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${snapshotsUrl}/detail?all_tenants=true`
                const method = "GET"

                const snapshot_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                if (snapshot_response.status_code === volumeUrlResponses.get.success_response.status_code) {
                    setSnapshots(snapshot_response.data.snapshots)
                }
            }
        })();
    },[
        cinderServiceDomain,
        cinderServiceVersion,
        defaultAdminProject,
        snapshotsUrl
    ]);


    useEffect(() => {
        if (groupsData.length > 0) {
            handleGroupsDataFormatting()
        }
    },[
        groupsData,
        handleGroupsDataFormatting
    ])

    useEffect(() => {
        let projects_filter = []
        if (projects.length > 0) {
            projects_filter = projects.map(project => {
                return {keyword: project.name, value: project.id, default: false}
            })
        }

        let group_filter_menu = volumeGroupFilterMenu.map(fl => {
            let new_item = {...fl}
            if (fl.value === "project_id") {
                new_item.items = [...projects_filter]
            } else if (fl.value === "group_type") {
                new_item.items = [...groupTypesList]
            }
            return new_item
        })
        setGroupsFilter(group_filter_menu)
    },[
        groupsData,
        projects,
        volumes,
        snapshots,
        groupTypesList
    ])

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let all_volume_types = []
                let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}`
                const method = "GET"

                const volume_types_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                if (volume_types_response.status_code === volumeUrlResponses.get.success_response.status_code) {
                    all_volume_types = [...all_volume_types, ...volume_types_response.data.volume_types]
                }
                url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}?is_public=false`
                const response = await volumeCinderRequest({url:url, method:method, token: project_token})
                if (response.status_code === volumeUrlResponses.get.success_response.status_code) {
                    const private_vt = response.data.volume_types.filter(vt => !all_volume_types.map(i => i.id).includes(vt.id))
                    all_volume_types = [...private_vt, ...all_volume_types]
                }
                setVolumeTypes([...all_volume_types])
                const volume_types_list = all_volume_types.map(item => {
                    return {keyword: item.name, value: item.id, default: false}
                })
                setVolumeTypesList(volume_types_list)
            }
        })();
    },[
        cinderServiceDomain,
        cinderServiceVersion,
        defaultAdminProject,
        volumeTypesUrl
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let all_group_types = []
                let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupTypesUrl}`
                const method = "GET"

                const group_types_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                if (group_types_response.status_code === volumeUrlResponses.get.success_response.status_code) {
                    all_group_types = [...all_group_types, ...group_types_response.data.group_types]
                }
                url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupTypesUrl}?is_public=false`
                const response = await volumeCinderRequest({url:url, method:method, token: project_token})
                if (response.status_code === volumeUrlResponses.get.success_response.status_code) {
                    const private_vt = response.data.group_types.filter(vt => !all_group_types.map(i => i.id).includes(vt.id))
                    all_group_types = [...private_vt, ...all_group_types]
                }
                setGroupTypes([...all_group_types])
                const group_types_list = all_group_types.map(item => {
                    return {keyword: item.name, value: item.id, default: false}
                })
                setGroupTypesList(group_types_list)
            }
        })();
    },[
        cinderServiceDomain,
        cinderServiceVersion,
        defaultAdminProject,
        groupTypesUrl
    ]);

    useEffect(() => {
        if (!dataFetchingRequired && location.state ) {
            const group_id = location.state ? location.state.group_id : null
                const group_index = groupsData.findIndex(g => g.id === group_id);
                if (group_index !== -1) {
                    setTimeout(() => handleDetailCardOpen(group_index), 600)
                }
        }
    },[
        dataFetchingRequired,
        groupsData,
        handleDetailCardOpen,
        location
    ])

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <GroupsSubheaderV3 
                selectedFilter={selectedGroupFilter} 
                setSelectedFilter={setSelectedGroupFilter}
                selectedFilterValue={selectedGroupFilterValue}
                setSelectedFilterValue={setSelectedGroupFilterValue}
                handleDataFetch={handleDataFetch}
                filterMenu={groupsFilter}
                handleFilteredSearch={handleGroupFilteredSearch}
                handleFilterReset={handleGroupFilterReset}
                volumeTypesList={volumeTypesList}
                groupTypesList={groupTypesList}
            />
            {isLoading && <CustomBackdrop open={isLoading} />}
            {!isLoading &&
                <GroupsTableV3 
                    data={groups}
                    setData={setGroups}
                    handleRowSelection={handleDetailCardOpen}
                    currentAction={currentAction}
                    setCurrentAction={setCurrentAction}
                    actionsTexts={defaultTexts}
                    actionsList={getGroupsActionsList()}
                    sortHandler={handleGroupsSorting}
                />
            }
            {selectedRow !== null && 
                <CustomSideDrawer 
                    open={detailCardOpen}
                    widthWeight={WIDTH_WEIGHT}
                    handleDrawerOpen={handleDetailCardOpen}
                    handleDrawerClose={handleDetailCardClose}
                > 
                    <GroupDetailV3
                        selectedRow={selectedRow}
                        selectedGroup={groups.filter(s => s.id === selectedRow.id)[0]}
                        widthWeight={WIDTH_WEIGHT}
                        handleFetchData={handleDataFetch}
                        handleNavigateToVolume={handleNavigateToVolume}
                        handleNavigateToGroupType={handleNavigateToGroupType}
                        handleNavigateToVolumeType={handleNavigateToVolumeType}
                        handleDelete={onGroupDeleteConfirm}
                        volumeTypes={volumeTypes}
                        volumes={volumes.filter(
                            v => v[blockStorageCinderConstants.volumeProjectIdField] === selectedRow.project_id &&
                            ["available", "in-use"].includes(v.status)
                        )}
                    />  
                </CustomSideDrawer>
            }
            <CustomDialog
                open={groupDeleteConfirmDialogOpen}
                onClose={handleGroupDeleteConfirmDialogClose}
                dialogTitle={{
                    title: defaultTexts.groupDeleteConfirmTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `${defaultTexts.groupDeleteConfirmText}: [${selectedGroups.map(v => v.name).toString()}]`, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: onGroupDelete, 
                    sx: {color: 'primary.main'}}]}
            >
                <CustomCheckboxField
                    currentValue={volumeDeleteCascade}
                    setCurrentValue={handleVolumeDeleteCascadeChange}
                    label={defaultTexts.deleteVolumesFormFieldLabel}
                    sx={{my: 1}}
                />
            </CustomDialog>
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </Box>
    )
};

export default GroupsWrapperV3;