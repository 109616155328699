import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup'
import CustomSelectField from 
'../../../../_common/_form_fields/CustomSelectField';
import { projectsUrl as projectsUrlResponses} from
'../../../../../_api_responses/openstack/identity/projects/v3';
import { openstackRequest, getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { networkNeutronConstants, identityKeystonConstants } from 
'../../../../../config/openStackConstants';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import { 
    limitsDataSchema,
    limitsUpdateDataForm
} from '.././../../../../_data/openstack/neutron/limits/v2.0';
import CustomTable from '../../../../_common/CustomTable';
import { getFormFieldComponent } from 
'../../../../../components/_common/_form_fields/form_helpers';
import CustomDialog from '../../../../_common/CustomDialog';
import { limitsUrl as limitUrlResponses } 
from '../../../../../_api_responses/openstack/neutron/limits/v2.0';


const SERVICE_NAME = openStackServices.networkService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const NeutronLimitsWrapperV20 = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [projects, setProjects] = useState([])
    const [selectedProject, setSelectedProject] = useState([defaultAdminProject])
    const [limitsData, setLimitsData] = useState({})
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [updateLimitsDialogOpen, setUpdateLimitsDialogOpen] = useState(false)
    const [limitsUpdateData, setLimitsUpdateData] = useState({})
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [resetToDefaultConfirmDialogOpen, setResetToDefaultConfirmDialogOpen] = useState(false)

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const limitsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.limitsUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
            service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
            service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)

    const handleProjectChange = (project_id) => {
        setSelectedProject(project_id)
        handleDataFetch()
    }

    const handleLoading = () => {
        setIsLoading(true)
    }

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleResetToDefaultConfirmDialogOpen = () => {
        setResetToDefaultConfirmDialogOpen(true)
    }

    const handleResetToDefaultConfirmDialogClose = () => {
        setResetToDefaultConfirmDialogOpen(false)
    }

    const handleUpdateLimitsDialogOpen = () => {
        setUpdateLimitsDialogOpen(true)
    }

    const handleUpdateLimitsDialogClose = () => {
        setUpdateLimitsDialogOpen(false)
    }

    const handleUpdateLimitsDataChange = (event, field_key) => {
        let updated_data = {...limitsUpdateData}
        updated_data[field_key] = event.target.value
        
        setLimitsUpdateData(updated_data)
    }

    const getLimitsDataForm = () => {
        let form = [...limitsUpdateDataForm]
        return (
            <FormGroup sx={{my: 3}}>
                {form.map(field => {
                    let form_field_options = {...field}
                    delete form_field_options.label
                    return (
                        getFormFieldComponent(
                            field,
                            limitsUpdateData,
                            handleUpdateLimitsDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleLimitsDataFormatting = (data) => {
        const keys = limitsUpdateDataForm.map(field => field.field_key)
        const formatted_data = keys.map((item) => {
            let new_item = {...data[item]}
            new_item["available"] = data[item].limit === -1 ? 
            defaultTexts.unlimitedValueText : new_item.limit - new_item.used
            new_item.limit = data[item].limit === -1 ? defaultTexts.unlimitedValueText : data[item].limit
            new_item["resource_name"] = item.toUpperCase()
            return new_item
        })
        return formatted_data
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleResetLimitsDefault = async () => {
        handleResetToDefaultConfirmDialogClose()
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            let method = "GET"
            const limits_response = await openstackRequest({
                url: `${neutronServiceDomain}/${neutronServiceVersion}/${limitsUrl}/${selectedProject}/default`, 
                method: method, 
                token: project_token
            })
            if (limits_response.status_code === limitUrlResponses.put.success_response.status_code) {
                method = "PUT"
                const default_response = await openstackRequest({
                    url: `${neutronServiceDomain}/${neutronServiceVersion}/${limitsUrl}/${selectedProject}`, 
                    method: method, 
                    data: limits_response.data,
                    token: project_token
                })
                if (default_response.status_code === limitUrlResponses.put.success_response.status_code) {
                    handleDataFetch()
                } else {
                    const error_response = limitUrlResponses.put.error_response.filter(
                        error_item => error_item.status_code === default_response.status_code)
                    if (error_response.length > 0) {
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: default_response.error
                        }
                        setError(errorObject)
                    } else {
                        const error_response = limitUrlResponses.put.error_response.filter(
                            error_item => error_item.status_code === "unknown")
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: default_response.error
                        }
                        setError(errorObject)
                    }
                }
            } else {
                const error_response = limitUrlResponses.put.error_response.filter(
                    error_item => error_item.status_code === limits_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: limits_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = limitUrlResponses.put.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: limits_response.error
                    }
                    setError(errorObject)
                }
            }
        }
    }

    const onLimitsUpdate = async () => {
        let data = {...limitsUpdateData}
        
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const method = "PUT"
            const limits_response = await openstackRequest({
                url: `${neutronServiceDomain}/${neutronServiceVersion}/${limitsUrl}/${selectedProject}`, 
                method: method, 
                data: {quota: data},
                token: project_token
            })
            if (limits_response.status_code === limitUrlResponses.put.success_response.status_code) {
                handleUpdateLimitsDialogClose()
                handleDataFetch()
            } else {
                const error_response = limitUrlResponses.put.error_response.filter(
                    error_item => error_item.status_code === limits_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: limits_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = limitUrlResponses.put.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: limits_response.error
                    }
                    setError(errorObject)
                }
            }
        }
    }

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"
            const projects_response = await openstackRequest({url:url, method:method})
            
            if ( projects_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                const projects_list = projects_response.data.projects.map(project => {
                    return {keyword: project.name, value: project.id, default: false}
                })
                setProjects(projects_list)
            }
        })()
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading()
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    const url = `${neutronServiceDomain}/${neutronServiceVersion}/${limitsUrl}/${selectedProject}/details.json`
                    const method = "GET"
                    const limits_response = await openstackRequest({url:url, method:method, token: project_token})
                    if ( limits_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                        setLimitsData(limits_response.data.quota)
                    }
                }
            })()
        }
        setDataFetchingRequired(false)
        setIsLoading(false)
    },[
        neutronServiceDomain,
        neutronServiceVersion,
        limitsUrl,
        defaultAdminProject,
        selectedProject,
        dataFetchingRequired,
    ])

    useEffect(() => {
        if (Object.keys(limitsData).length > 0) {
            let new_data = {}
            for (let i in limitsUpdateDataForm) {
                new_data[limitsUpdateDataForm[i].field_key] = limitsData[limitsUpdateDataForm[i].field_key].limit
            }
            setLimitsUpdateData(new_data)
        }
    },[
        limitsData,
        selectedProject
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);
    
    return (
        <React.Fragment>
            {isLoading && <CustomBackdrop open={isLoading} />}
            <Grid container alignItems="center" justifyContent="space-between" sx={{m: 1}}>
                <Grid item sx={{flexGrow: 1}}>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{height: 40, width: {xs: 250, sm: 180}, m: 1}}
                        onClick={handleUpdateLimitsDialogOpen}
                    >
                        {defaultTexts.updateLimitsButtonText}
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        sx={{height: 40, width: {xs: 250, sm: 180}, m: 1}}
                        onClick={handleResetToDefaultConfirmDialogOpen}
                    >
                        {defaultTexts.resetLimitsDefaultButtonText}
                    </Button>
                </Grid>
                <Grid item>
                    <CustomSelectField 
                        items={projects}
                        self_item_titles={true}
                        label={defaultTexts.projectFormFieldLabel}
                        size="small"
                        currentValue={selectedProject}
                        setCurrentValue={handleProjectChange}
                        sx={{m: 1, my: 3, width: 250}}
                    />
                </Grid>
            </Grid>
            {!isLoading && Object.keys(limitsData).length > 0 &&
                <CustomTable
                    withRowCount={true}
                    withoutColumnsHide={true}
                    defaultDataTexts={defaultTexts} 
                    tableHeaders={limitsDataSchema}
                    columns={limitsDataSchema}
                    handleRowSelection={()=>{}}
                    dataRows={handleLimitsDataFormatting(limitsData)}
                />
            }
            <CustomDialog
                open={updateLimitsDialogOpen}
                onClose={handleUpdateLimitsDialogClose}
                dialogTitle={{
                    title: defaultTexts.updateLimitsActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.updateNeutronLimitsActionText, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onLimitsUpdate, 
                    variant: "contained"
                    }]}
            >
                {getLimitsDataForm()}
            </CustomDialog>
            <CustomDialog
                open={resetToDefaultConfirmDialogOpen}
                onClose={handleResetToDefaultConfirmDialogClose}
                dialogTitle={{
                    title: defaultTexts.resetToDefaultLimitsConfirmTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `${defaultTexts.resetToDefaultLimitsConfirmText}`, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: handleResetLimitsDefault, 
                    sx: {color: 'primary.main'}}]}
            />
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts.failedActionErrorDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts.failedActionErrorDialogMessage}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </React.Fragment>
    )
};

export default NeutronLimitsWrapperV20;