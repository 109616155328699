export const servicesCatalogDataSchema = [
    {
        field_key: "name",
        is_id_field: true,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "type",
        is_id_field: false,
        field_type: "string", 
        label: "typeFormFieldLabel"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "enabled",
        is_id_field: false,
        field_type: "bool", 
        label: "enabledFormFieldLabel",
        value_on_true: "formValueYes",
        value_on_false: "formValueNo"
    },
    {
        field_key: "id",
        is_id_field: false,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const servicesCatalogDataForm = [
    {
        field_key: "name",
        field_type: "string", 
        required: true, 
        label: "nameFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string", 
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true,
        on_empty_null: false
    },
    {
        field_key: "type",
        field_type: "select", 
        required: true, 
        label: "typeFormFieldLabel", 
        error_label: "requiredFormFieldError",
        on_empty_null: false,
        self_item_titles: true,
        default_empty: true,
        items: [
            {
                keyword: "compute", 
                value: "compute", 
                default: false
            },
            {
                keyword: "identity", 
                value: "identity", 
                default: false
            },
            {
                keyword: "image", 
                value: "image", 
                default: false
            },
            {
                keyword: "volume", 
                value: "volume", 
                default: false
            },
            {
                keyword: "network", 
                value: "network", 
                default: false
            },
            {
                keyword: "ec2", 
                value: "ec2", 
                default: false
            }
        ]
    }
]


export const servicesCatalogDataUpdateForm = [
    {
        field_key: "name",
        field_type: "string", 
        required: false, 
        label: "nameFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string", 
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true,
        on_empty_null: false
    },
    {
        field_key: "type",
        field_type: "select", 
        required: false, 
        label: "typeFormFieldLabel", 
        error_label: "requiredFormFieldError",
        on_empty_null: false,
        self_item_titles: true,
        default_empty: true,
        items: [
            {
                keyword: "compute", 
                value: "compute", 
                default: false
            },
            {
                keyword: "identity", 
                value: "identity", 
                default: false
            },
            {
                keyword: "image", 
                value: "image", 
                default: false
            },
            {
                keyword: "volume", 
                value: "volume", 
                default: false
            },
            {
                keyword: "network", 
                value: "network", 
                default: false
            },
            {
                keyword: "ec2", 
                value: "ec2", 
                default: false
            }
        ]
    },
    {
        field_key: "enabled",
        field_type: "bool", 
        required: false,
        label: "enabledFormFieldLabel",
        default_value: true
    }
]


export const servicesCatalogFilterMenu = [
    {
        keyword: "filterName", 
        value: "name", 
        type: "text"
    },
    {
        keyword: "filterType", 
        value: "type", 
        type: "select",
        items: [
            {
                keyword: "compute", 
                value: "compute", 
                default: false
            },
            {
                keyword: "compute_legacy", 
                value: "compute_legacy", 
                default: false
            },
            {
                keyword: "identity", 
                value: "identity", 
                default: false
            },
            {
                keyword: "image", 
                value: "image", 
                default: false
            },
            {
                keyword: "volumev3", 
                value: "volumev3", 
                default: false
            },
            {
                keyword: "block-storage", 
                value: "block-storage", 
                default: false
            },
            {
                keyword: "network", 
                value: "network", 
                default: false
            },
            {
                keyword: "ec2", 
                value: "ec2", 
                default: false
            }
        ],
        self_item_titles: true,
        default_empty: true
    }
]

export const endpointDataForm = [
    {
        field_key: "url",
        field_type: "string", 
        required: true, 
        label: "urlFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "interface",
        field_type: "select", 
        required: true,
        label: "interfaceFormFieldLabel",
        error_label: "requiredFormFieldError",
        self_item_titles: false,
        default_empty: true,
        items: [
            {
                keyword: "publicInterfaceFormFieldLabel",
                value: "public",
                default: false
            },
            {
                keyword: "internalInterfaceFormFieldLabel",
                value: "internal",
                default: false
            },
            {
                keyword: "adminInterfaceFormFieldLabel",
                value: "admin",
                default: false
            }
        ]
    },
    {
        field_key: "region_id",
        field_type: "select", 
        required: true, 
        label: "regionFormFieldLabel",
        error_label: "requiredFormFieldError", 
        on_empty_null: true,
        self_item_titles: true,
        default_empty: true
    }
]