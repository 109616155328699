export const routerDataSchema = [
    {
        field_key: "project_id",
        is_id_field: false,
        field_type: "string", 
        label: "projectFormFieldLabel"
    },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "status",
        is_id_field: false,
        field_type: "string", 
        label: "statusFormFieldLabel"
    },
    {
        field_key: "admin_state_up",
        is_id_field: false,
        field_type: "bool", 
        label: "adminStateFormFieldLabel",
        textOnTrue: "upStateFormFieldLabel",
        textOnFalse: "downStateFormFieldLabel"
    },
    {
        field_key: "external_network",
        is_id_field: false,
        field_type: "string", 
        label: "externalNetworkFormFieldLabel"
    },
    {
        field_key: "revision_number",
        is_id_field: false,
        field_type: "string", 
        label: "revisionNumberFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const routerDataForm = [
    {
        field_key: "project_id",
        field_type: "select",
        required: true,
        label: "projectFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    },
    {
        field_key: "admin_state_up",
        field_type: "bool", 
        required: false,
        label: "adminStateFormFieldLabel",
        default_value: true
    },
    {
        field_key: "network_id",
        field_type: "select",
        required: false,
        label: "externalNetworkFormFieldLabel",
        self_item_titles: true,
        default_empty: false
    },
    {
        field_key: "enable_snat",
        field_type: "bool", 
        required: false,
        label: "enableSNatFormFieldLabel",
        default_value: true
    }
]

export const routerStaticRouteForm = [
    {
        field_key: "destination",
        field_type: "string",
        required: false,
        label: "destinationFormFieldLabel"
    },
    {
        field_key: "nexthop",
        field_type: "string",
        required: false,
        label: "nextHopFormFieldLabel"
    }
]

export const routersSortFields = [
    {
        field_key: "name",
        label: "nameFormFieldLabel"
    },
    {
        field_key: "project_id",
        label: "projectFormFieldLabel"
    },
    {
        field_key: "status",
        label: "statusFormFieldLabel"
    },
    {
        field_key: "admin_state_up",
        label: "adminStateFormFieldLabel"
    }
]


export const routersFilterMenu = [
    {
        keyword: "nameFormFieldLabel",
        value: "name",
        type: "text"
    },
    {
        keyword: "projectFormFieldLabel",
        value: "project_id",
        type: "select", 
        multiple: false,
        self_item_titles: true,
        items: []
    },
    {
        keyword: "adminStateFormFieldLabel", 
        value: "admin_state_up", 
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "filterChoiceYes", 
                value: "true", 
                default: true
            },
            {
                keyword: "filterChoiceNo", 
                value: "false", 
                default: false
            }
        ]
    }
]

export const routerDataUpdateForm = [
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    }
]