export const volumeGroupDataForm = [
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
    },
    {
        field_key: "group_type",
        field_type: "select",
        required: true,
        label: "volumeGroupTypeFormFieldLabel",
        error_label: "requiredFormFieldError",
        self_item_titles: true,
        default_empty: true
    },
    {
        field_key: "availability_zone",
        field_type: "select",
        required: true,
        error_label: "requiredFormFieldError",
        label: "availabilityZoneFormFieldLabel",
        self_item_titles: true,
        default_empty: true
    },
    {
        field_key: "volume_types",
        field_type: "select",
        required: true,
        label: "volumeTypesListFormFieldLabel",
        error_label: "requiredFormFieldError",
        self_item_titles: true,
        default_empty: true,
        multiple: true
    }
]

export const volumeGroupSortFields = [
    {
        field_key: "name",
        label: "nameFormFieldLabel"
    }
]

export const volumeGroupFilterMenu = [
    {
        keyword: "nameFormFieldLabel",
        value: "name",
        type: "text"
    },
    {
        keyword: "projectFormFieldLabel",
        value: "project_id",
        type: "select", 
        multiple: false,
        self_item_titles: true,
        items: []
    }
]

export const volumeGroupDataSchema = [
    {
        field_key: "project_id",
        is_id_field: false,
        field_type: "string", 
        label: "projectFormFieldLabel"
    },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "status",
        is_id_field: false,
        field_type: "string", 
        label: "statusFormFieldLabel"
    },
    {
        field_key: "group_type",
        is_id_field: false,
        field_type: "string", 
        label: "volumeGroupTypeFormFieldLabel"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "availability_zone",
        is_id_field: false,
        field_type: "string", 
        label: "availabilityZoneFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const volumeGroupUpdateDataForm = [
    {
        field_key: "name",
        field_type: "string", 
        required: false, 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "description",
        field_type: "string", 
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true,
        on_empty_null: false
    }
]