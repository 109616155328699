import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import WrapperBox from '../../../../_common/WrapperBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOffIcon from '@mui/icons-material/EditOff';
import Paper from '@mui/material/Paper';
import useWindowDimensions from 
'../../../../_common/WindowDimensions';
import Constants from '../../../../../config/constants';
import { IconButton }  from '@mui/material';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { openstackRequest, getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { usersUrl as usersUrlResponses } from 
'../../../../../_api_responses/openstack/identity/users/v3';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { identityKeystonConstants } from 
'../../../../../config/openStackConstants';
import CustomDialog from 
'../../../../_common/CustomDialog';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import { FormGroup } from '@mui/material';
import { 
    userDataUpdateForm, 
    userDataSchema, 
    userOptionsForm,
    userAppCredentialsDataForm,
    userAccessRuleDataForm
} from '../../../../../_data/openstack/identity/users/v3';
import { getDetailDataComponent } from 
'../../../../_common/common_helpers';
import ServiceContentHeader from '../../../../_common/ServiceContentHeader';
import CustomCard from '../../../../_common/CustomCard';
import { Divider } from '@mui/material';
import CustomSelectField from 
'../../../../_common/_form_fields/CustomSelectField';
import PlusButton from '../../../../_common/PlusButton';
import MinusButton from '../../../../_common/MinusButton';
import CustomTextField from '../../../../_common/_form_fields/CustomTextField';
import CloseIcon from '@mui/icons-material/Close';
import IdentityRoleAssignmentsV3 from '../roles/identityRoleAssignmentsV3';

const SERVICE_NAME = openStackServices.identityService

const IdentityUserDetailV3 = (props) => {
    const { selectedRow } = props
    const { usersRecords } = props
    const userRecord = usersRecords.filter(user => user.id === selectedRow.id)[0]
    const { widthWeight } = props
    const { 
        handleFetchData, 
        handleDrawerClose,
        handleUserStatusChange,
        handleUserPasswordChange,
        handleAddExtraParameters
    } = props
    const { width } = useWindowDimensions();
    const [editMode, setEditMode] = useState(false);
    const [addExtraParamsMode, setAddExtraParamsMode] = useState(false);
    const [editExtraParamsMode, setEditExtraParamsMode] = useState(false)
    const [footerIsLoading, setFooterIsLoading] = useState(true);

    const login = useSelector(state => state.profile.username)

    const [formExtraFields, setFormExtraFields] = useState([]);
    const [formFieldOptions, setFormFieldOptions] = useState({})
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const [error, setError] = useState()
    const [successUserDelete, setSuccessUserDelete] = useState()
    const [successUserUpdate, setSuccessUserUpdate] = useState()
    
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [successUpdateDialogOpen, setSuccessUpdateDialogOpen] = useState(false);
    
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

    const [updateFormData, setUpdateFormData] = useState(
        {...userRecord}
    )
    const [appCredentialForm, setAppCredentialForm] = useState({})
    const [appCredentialFormOptions, setAppCredentialFormOptions] = useState({})
    const [accessRulesForm, setAccessRulesForm] = useState([])
    const [accessRulesFormOptions, setAccessRulesFormOptions] = useState([])
    const [serviceCatalog, setServiceCatalog] = useState([])

    const [customUserSchemaFields, setCustomUserSchemaFields] = useState([])
    const [userGroups, setUserGroups] = useState([])
    const [userProjects, setUserProjects] = useState([])
    const [userRoles, setUserRoles] = useState([])
    const [userAccessRules, setUserAccessRules] = useState([])
    const [userApplicationCredentials, setUserApplicationCredentials] = useState([])

    const [userDomain, setUserDomain] = useState({})
    const [projects, setProjects] = useState([]);
    const [userActions, setUserActions] = useState([]);
    const [currentAction, setCurrentAction] = useState("");

    const [customUserParamsFormFields, setCustomUserParamsFormFields] = useState({})

    const [roleAssignmentsDialogOpenMode, setRoleAssignmentsDialogOpenMode] = useState(false);
    const [selectedRoleAssignments, setSelectedRoleAssignments] = useState([])

    const [userSubMenu, setUserSubMenu] = useState(login === selectedRow.name ? [
        {keyword: "submenuDetails", navigation: "/user-details", is_active: true},
        {keyword: "submenuCustomParams", navigation: "/user-custom-params", is_active: false},
        {keyword: "submenuGroups", navigation: "/user-groups", is_active: false},
        {keyword: "submenuProjects", navigation: "/user-projects", is_active: false},
        {keyword: "submenuAccessRules", navigation: "/user-access-rules", is_active: false},
        {keyword: "submenuApplicationCredentials", navigation: "/user-application-credentials", is_active: false},
        {keyword: "submenuRoles", navigation: "/user-roles", is_active: false}
    ] : [
        {keyword: "submenuDetails", navigation: "/user-details", is_active: true},
        {keyword: "submenuCustomParams", navigation: "/user-custom-params", is_active: false},
        {keyword: "submenuGroups", navigation: "/user-groups", is_active: false},
        {keyword: "submenuProjects", navigation: "/user-projects", is_active: false},
        {keyword: "submenuRoles", navigation: "/user-roles", is_active: false}
    ]
    )

    const [currentTab, setCurrentTab] = useState("/user-details")
    const [addAppCredentialsDialogOpen, setAddAppCredentialsDialogOpen] = useState(false);
    
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const usersUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.usersUrl)[0].url)
    const domainsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.domainsUrl)[0].url)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    const roleAssignmentsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.roleAssignmentsUrl)[0].url)
    const servicesUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.servicesUrl)[0].url)

    const handleRoleAssignmentsDialogClose = () => {
        setSelectedRoleAssignments([])
        setRoleAssignmentsDialogOpenMode(false)
    }

    const handleRoleAssignmentsPopupOpen = useCallback(async () => {
        let role_assignments = []
        let url = ""
        
        url = `${identityServiceDomain}/${identityServiceVersion}/${roleAssignmentsUrl}/?user.id=${selectedRow.id}&&include_names=true`
        const method = "GET"

        const role_assignments_response = await openstackRequest({url: url, method: method})
        role_assignments = role_assignments_response.data.role_assignments
        
        setSelectedRoleAssignments(role_assignments)
        setRoleAssignmentsDialogOpenMode(true)
    },[
        identityServiceDomain,
        identityServiceVersion,
        roleAssignmentsUrl,
        selectedRow
    ])

    const handleFormDataChange = (event,field_key) => {
        let new_form_data = {...updateFormData}
        if (userDataUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (userDataUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setUpdateFormData(new_form_data)
    }

    const handleAppCredentialDataChange = (event,field_key) => {
        let new_form_data = {...appCredentialForm}
        if (userAppCredentialsDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (userAppCredentialsDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select" ||
            userAppCredentialsDataForm.filter(
                item => item.field_key === field_key)[0].field_type === "datetime" 
            ) {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setAppCredentialForm(new_form_data)
    }

    const handleAccessRulesDataChange = (event,field_key,index) => {
        let new_form_data = {...accessRulesForm[index]}
        if (userAccessRuleDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (userAccessRuleDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        let new_list = [...accessRulesForm]
        new_list[index] = new_form_data
        console.log(new_list[index])
        setAccessRulesForm(new_list)
    }

    const appCredentialDataValidation = () => {
        let validation_faild = true
        let options = {...appCredentialFormOptions}
        for (let n in userAppCredentialsDataForm) {
            if (userAppCredentialsDataForm[n].required && !appCredentialForm[userAppCredentialsDataForm[n].field_key]) {
                validation_faild = false
                options[userAppCredentialsDataForm[n].field_key] = {}
                options[userAppCredentialsDataForm[n].field_key]["error"] = true
                options[userAppCredentialsDataForm[n].field_key]["errorText"] = defaultTexts[userAppCredentialsDataForm[n].error_label]
            }
        }
        setAppCredentialFormOptions(options)
        let rule_options = [...accessRulesFormOptions]
        for (let r in accessRulesForm) {
            rule_options[r] = {}
            for (let n in userAccessRuleDataForm) {
                if (userAccessRuleDataForm[n].required && !accessRulesForm[r][userAccessRuleDataForm[n].field_key]) {
                    validation_faild = false
                    rule_options[r][userAccessRuleDataForm[n].field_key] = {}
                    rule_options[r][userAccessRuleDataForm[n].field_key]["error"] = true
                    rule_options[r][userAccessRuleDataForm[n].field_key]["errorText"] = defaultTexts[userAccessRuleDataForm[n].error_label]
                }
            }
        }
        setAccessRulesFormOptions(rule_options)
        return validation_faild
    }

    const handleAppCredentialAdd = async () => {
        const validateFormData = appCredentialDataValidation()
        if (validateFormData) {
            handleAddAppCredentialsDialogClose()

            const project_scope_token = await getXAuthTokenProjectScope(appCredentialForm.project_id)
            const url = `${identityServiceDomain}/${identityServiceVersion}/${usersUrl}/${selectedRow.id}/application_credentials`
            const method = "POST"

            let data = {...appCredentialForm}
            data["roles"] = []
            for (let r in appCredentialForm.roles) {
                let new_item = {}
                new_item["id"] = appCredentialForm.roles[r]
                data["roles"].push(new_item)
            }
            data["access_rules"] = [...accessRulesForm]

            const credential_response = await openstackRequest({
                url: url, 
                method: method, 
                data: {application_credential: data},
                token: project_scope_token
            })
            if (credential_response.status_code === usersUrlResponses.post.success_response.status_code) {
                setSuccessUserUpdate({
                    success_title: usersUrlResponses.post.success_response.response_title, 
                    success_message: "appCredentialAddSuccessMessage",
                    success_details: credential_response.data.application_credential.secret
                })
                setAccessRulesFormOptions([])
                setAccessRulesForm([])
                setAppCredentialForm({})
                setAppCredentialFormOptions({})
                handleFetchData()
            } else {
                const error_response = usersUrlResponses.delete.error_response.filter(
                    error_item => error_item.status_code === credential_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: credential_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = usersUrlResponses.delete.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: credential_response.error
                    }
                    setError(errorObject)
                }
            }
        }
        return validateFormData
    }

    const getEmptyListNoteComponent = (text) => {
        return (
            <WrapperBox>
                <CustomCard 
                    overflow="hidden" 
                    cardHeight={220}
                    cardWidth={300}
                    card_sx={{
                        mt: 15, 
                        opacity: 0.5, 
                        borderRadius: 50,
                        
                    }}
                    box_sx={{
                        display: "flex", 
                        alignItems: "center", 
                        justifyContent: "center",
                        overflow: "hidden",
                        right: 0
                    }}
                >
                    <CustomText 
                        size="h5" 
                        sx={{textAlign: "center"}}
                    >
                        {text}
                    </CustomText>
                </CustomCard>
            </WrapperBox>
        )
    }

    const getDataForm = () => {
        return (
            <FormGroup>
                {userDataUpdateForm.map(field => {
                    let form_field_options = {}
                if (field.field_key === "default_project_id") {
                    if (projects.length > 0) {
                        const project_filter = projects.map(project => {
                            return {keyword: project.name, value: project.id, default: false}
                        })
                        form_field_options["items"] = [...project_filter]
                        form_field_options["empty"] = field.default_empty
                        form_field_options["self_item_titles"] = field.self_item_titles
                    } else {
                        field.field_type = "string"
                    }
                }
                return (
                    getFormFieldComponent(
                        field,
                        updateFormData,
                        handleFormDataChange,
                        defaultTexts[field.label],
                        {size:"medium",
                        withHelp: field.with_help_text,
                        helpText: defaultTexts[field.help_text],
                        sx: {
                            my: 1, 
                            width: width * widthWeight * 0.8
                        },
                        ...form_field_options}
                    )
                )
            })}
        </FormGroup>)
    }

    const getAppCredentialsDataForm = () => {
        return (
            <FormGroup>
                {userAppCredentialsDataForm.map(field => {
                    let form_field_options = {...appCredentialFormOptions[field.field_key]}
                    
                    if (field.field_key === "roles") {
                        const role_filter = userRoles.filter(item => 
                            Object.keys(item.scope).includes("project") && 
                            item.scope.project.id === appCredentialForm.project_id &&
                            !Object.keys(item.role).includes("domain")).map(role => {
                            return {keyword: role.role.name, value: role.role.id, default: false}
                        })
                        form_field_options["items"] = [...role_filter]
                        form_field_options["empty"] = field.default_empty
                        form_field_options["self_item_titles"] = field.self_item_titles
                        form_field_options["multiple"] = field.multiple
                        form_field_options["withHelp"] = true
                        form_field_options["helpText"] = defaultTexts.appCredentialRoleFormFieldHelpText
                        form_field_options["zIndex"] = 3200
                        form_field_options["sx"] = {
                            my: 1, 
                            width: '98%'
                        }
                    } else if (field.field_key === "project_id") {
                        const project_filter = userProjects.map(project => {
                            return {keyword: project.name, value: project.id, default: false}
                        })
                        form_field_options["items"] = [...project_filter]
                        form_field_options["empty"] = field.default_empty
                        form_field_options["self_item_titles"] = field.self_item_titles
                    } else if (field.field_key === "unrestricted") {
                        form_field_options["withHelp"] = true
                        form_field_options["helpText"] = defaultTexts.appCredentialUnrestrectedFieldHelpText
                        form_field_options["zIndex"] = 3200
                        form_field_options["sx"] = {
                            my: 1, 
                            width: '98%'
                        }
                    } else if (field.field_key === "secret") {
                        form_field_options["withHelp"] = true
                        form_field_options["helpText"] = defaultTexts.appCredentialSecretFormFieldHelpText
                        form_field_options["zIndex"] = 3200
                        form_field_options["sx"] = {
                            my: 1, 
                            width: '98%'
                        }
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            appCredentialForm,
                            handleAppCredentialDataChange,
                            defaultTexts[field.label],
                            {size:"medium",
                            sx: {
                                my: 1, 
                                width: '90%'
                            },
                            ...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const getUserAccessRuleDataForm = (index) => {
        return (
            <FormGroup>
                {userAccessRuleDataForm.map(field => {
                    let form_field_options = {}
                    if (accessRulesFormOptions.length > index) {
                        form_field_options = {...accessRulesFormOptions[index][field.field_key]}
                    } 
                    if (field.field_key === "method") {
                        form_field_options["items"] = field.items
                        form_field_options["empty"] = field.default_empty
                        form_field_options["self_item_titles"] = field.self_item_titles
                    } else if (field.field_key === "service") {
                        const service_list = serviceCatalog.map(service => {
                            return {keyword: `${service.name} (${service.type})`, value: service.type, default: false}
                        })
                        form_field_options["items"] = service_list
                        form_field_options["empty"] = field.default_empty
                        form_field_options["self_item_titles"] = field.self_item_titles
                    } else if (field.field_key === "path") {
                        form_field_options["withHelp"] = true
                        form_field_options["helpText"] = defaultTexts.accessRulePathFieldHelpText
                        form_field_options["zIndex"] = 3200
                        form_field_options["helpTextWidth"] = 400
                        form_field_options["sx"] = {
                            my: 1, 
                            width: '98%'
                        }
                    }
                    form_field_options["index"] = index
                    return (
                        getFormFieldComponent(
                            field,
                            accessRulesForm[index],
                            handleAccessRulesDataChange,
                            defaultTexts[field.label],
                            {size:"medium",
                            sx: {
                                my: 1, 
                                width: '90%'
                            },
                            ...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleEditModeChange = () => {
        handleUserDetailTabChange("/user-details")
        setEditMode(true)
    }

    const handleEditModeReset = () => {
        setUpdateFormData({...selectedRow})
        handleUserDetailTabChange("/user-details")
        setEditMode(false)
    }

    const handleAddExtraParamsModeReset = () => {
        setFormExtraFields([])
        setAddExtraParamsMode(false)
    }

    const handleEditExtraParamsModeReset = () => {
        setEditExtraParamsMode(false)
    }

    
    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }
    const handleSuccessDialogClose = () => {
        handleDrawerClose()
        setSuccessUserDelete(null);
        setSuccessDialogOpen(false);
    }
    const handleSuccessUpdateDialogClose = () => {
        setSuccessUserUpdate(null)
        setUpdateFormData({...selectedRow})
        setSuccessUpdateDialogOpen(false);
    }
    const handleConfirmDeleteDialogOpen = () => {
        setConfirmDeleteDialogOpen(true)
    }
    const handleConfirmDeleteDialogClose = () => {
        setConfirmDeleteDialogOpen(false)
    }

    const handleDeleteUser = async () => {
        handleConfirmDeleteDialogClose()
        const url = `${identityServiceDomain}/${identityServiceVersion}/${usersUrl}/${selectedRow.id}`
        const method = "DELETE"

        const user_response = await openstackRequest({url: url, method: method})
        if (user_response.status_code === usersUrlResponses.delete.success_response.status_code) {
            setSuccessUserDelete({
                success_title: usersUrlResponses.delete.success_response.response_title, 
                success_message: usersUrlResponses.delete.success_response.response_message
            })
            handleFetchData()
        } else {
            const error_response = usersUrlResponses.delete.error_response.filter(
                error_item => error_item.status_code === user_response.status_code)
            if (error_response.length > 0) {
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: user_response.error
                }
                setError(errorObject)
            } else {
                const error_response = usersUrlResponses.delete.error_response.filter(
                    error_item => error_item.status_code === "unknown")
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: user_response.error
                }
                setError(errorObject)
            }
        }
    }

    const handleEditUser = async () => {
        let updated_data = {...updateFormData}
        updated_data = addOptionsToUserData(updated_data)
        const url = `${identityServiceDomain}/${identityServiceVersion}/${usersUrl}/${selectedRow.id}`
        const method = "PATCH"

        const user_response = await openstackRequest({
            url: url, 
            method: method, 
            data: {user: updated_data}
        })
        if (user_response.status_code === usersUrlResponses.patch.success_response.status_code) {
            handleFetchData()
            handleEditModeReset()
            setSuccessUserUpdate({
                success_title: usersUrlResponses.patch.success_response.response_title, 
                success_message: usersUrlResponses.patch.success_response.response_message
            })
        } else {
            const error_response = usersUrlResponses.patch.error_response.filter(
                error_item => error_item.status_code === user_response.status_code)
            if (error_response.length > 0) {
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: user_response.error
                }
                setError(errorObject)
            } else {
                const error_response = usersUrlResponses.patch.error_response.filter(
                    error_item => error_item.status_code === "unknown")
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: user_response.error
                }
                setError(errorObject)
            }
        }
    }

    const handleUserDetailTabChange = useCallback((navigation) => {
        if (navigation === "/user-roles") {
            handleRoleAssignmentsPopupOpen()
        } else {
            let newUserSubmenuData = userSubMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                } else {
                    item.is_active = false
                }
                return item
            })
            setUserSubMenu(newUserSubmenuData)
            setCurrentTab(navigation)
        }
    },[
        userSubMenu,
        handleRoleAssignmentsPopupOpen
    ])

    const handleFormExtraFieldsChange = (event,field_key_list) => {
        let new_extra_data = [...formExtraFields]
        if (field_key_list[1] === "key") {
            new_extra_data[field_key_list[0]].field_key = event.target.value
        } else {
            new_extra_data[field_key_list[0]].field_value = event.target.value
        }
        setFormExtraFields(new_extra_data)
    }

    const handleFormExtraFieldsRemove = (index) => {
        if (formExtraFields.length > 1) {
            let updated_data = [...formExtraFields]
            updated_data.splice(index, 1)
            setFormExtraFields(updated_data)
        } else {
            setFormExtraFields([])
        }
    }

    const handleFormAccessRuleRemove = (index) => {
        if (accessRulesForm.length > 1) {
            let updated_data = [...accessRulesForm]
            let updated_options = [...accessRulesFormOptions]
            updated_data.splice(index, 1)
            updated_options.splice(index, 1)
            setAccessRulesForm(updated_data)
            setAccessRulesFormOptions(updated_options)
        } else {
            setAccessRulesForm([])
            setAccessRulesFormOptions([])
        }
    }

    const handleAddExtraParamsModeChange = useCallback(() => {
        handleUserDetailTabChange("/user-custom-params")
        setEditExtraParamsMode(false)
        setAddExtraParamsMode(true)
    },[handleUserDetailTabChange])


    const handleEditExtraParamsModeChange = useCallback(() => {
        handleUserDetailTabChange("/user-custom-params")
        setAddExtraParamsMode(false)
        setEditExtraParamsMode(true)
    },[handleUserDetailTabChange])

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessDialogOpen(true)
    },[successUserDelete]);

    useEffect(() => {
        setSuccessUpdateDialogOpen(true)
    },[successUserUpdate]);

    useEffect(() => {
        if (selectedRow !== null ) {
            let userSchemaFields = userDataSchema.map(item => item.field_key)
            let uniqueSchemaFields = []
            let customSchemaFields = []
        
            const customFields = Object.keys(selectedRow).filter(
                f => userSchemaFields.indexOf(f) === -1 && 
                f !== "links")
            uniqueSchemaFields = [
                ...new Set([
                    ...uniqueSchemaFields,
                    ...customFields])]
            
            if (uniqueSchemaFields.length > 0) {
                for (let c in uniqueSchemaFields) {
                    let newCustomField = {}
                    newCustomField["field_key"] = uniqueSchemaFields[c]
                    newCustomField["is_id_field"] = false
                    newCustomField["field_type"] = "string"
                    newCustomField["label"] = uniqueSchemaFields[c]
                    customSchemaFields.push(newCustomField)
                }
            }
            setCustomUserSchemaFields(customSchemaFields)
            let extra_user_fields = {}
            for (let i in customSchemaFields) {
                extra_user_fields[customSchemaFields[i].field_key] = selectedRow[customSchemaFields[i].field_key]
            }
            setCustomUserParamsFormFields(extra_user_fields)
        }
    },[selectedRow]);

    useEffect(() => {
        if (selectedRow !== null) {
            (async () => {
                const url = `${identityServiceDomain}/${identityServiceVersion}/${usersUrl}/${selectedRow.id}/groups`
                const method = "GET"

                const user_groups = await openstackRequest({
                    url: url, 
                    method: method
                })
                if (user_groups.status_code === usersUrlResponses.get.success_response.status_code) {
                    setUserGroups(user_groups.data.groups)
                } else {
                    setUserGroups([])
                    }
                }
            )();
        }
    },[ 
        selectedRow,
        identityServiceDomain,
        identityServiceVersion,
        usersUrl
    ])

    useEffect(() => {
        if (selectedRow !== null) {
            (async () => {
                const url = `${identityServiceDomain}/${identityServiceVersion}/${usersUrl}/${selectedRow.id}/projects`
                const method = "GET"

                const user_projects = await openstackRequest({
                    url: url, 
                    method: method
                })
                if (user_projects.status_code === usersUrlResponses.get.success_response.status_code) {
                    setUserProjects(user_projects.data.projects)
                } else {
                    setUserProjects([])
                    }
                }
            )();
        }
    },[
        selectedRow,
        identityServiceDomain,
        identityServiceVersion,
        usersUrl
    ])

    
    useEffect(() => {
        if (selectedRow !== null) {
            (async () => {
                const url = `${identityServiceDomain}/${identityServiceVersion}/${usersUrl}/${selectedRow.id}/access_rules`
                const method = "GET"

                const user_access_rules = await openstackRequest({
                    url: url, 
                    method: method
                })
                if (user_access_rules.status_code === usersUrlResponses.get.success_response.status_code) {
                    setUserAccessRules(user_access_rules.data.access_rules)
                } else {
                    setUserAccessRules([])
                    }
                }
            )();
        }
    },[
        selectedRow,
        identityServiceDomain,
        identityServiceVersion,
        usersUrl
    ])

    useEffect(() => {
        if (selectedRow !== null) {
            (async () => {
                const url = `${identityServiceDomain}/${identityServiceVersion}/${usersUrl}/${selectedRow.id}/application_credentials`
                const method = "GET"

                const user_application_credentials = await openstackRequest({
                    url: url, 
                    method: method
                })
                if (user_application_credentials.status_code === usersUrlResponses.get.success_response.status_code) {
                    setUserApplicationCredentials(user_application_credentials.data.application_credentials)
                } else {
                        setUserApplicationCredentials([])
                    }
                }
            )();
        }
    },[
        selectedRow,
        identityServiceDomain,
        identityServiceVersion,
        usersUrl
    ])

    useEffect(() => {
        if (selectedRow !== null) {
            (async () => {
                const url = `${identityServiceDomain}/${identityServiceVersion}/${roleAssignmentsUrl}/?user.id=${selectedRow.id}&&include_names=true`
                const method = "GET"

                const user_roles = await openstackRequest({
                    url: url, 
                    method: method
                })
                
                if (user_roles.status_code === usersUrlResponses.get.success_response.status_code) {
                    setUserRoles(user_roles.data.role_assignments)
                } else {
                    setUserRoles([])
                    }
                }
            )();
        }
    },[
        selectedRow,
        identityServiceDomain,
        identityServiceVersion,
        roleAssignmentsUrl,
        userRecord
    ])

    useEffect(() => {
        if (selectedRow !== null) {
            (async () => {
                const url = `${identityServiceDomain}/${identityServiceVersion}/${domainsUrl}/${userRecord.domain_id}`
                const method = "GET"

                const user_domain = await openstackRequest({
                    url: url, 
                    method: method
                })
                
                if (user_domain.status_code === usersUrlResponses.get.success_response.status_code) {
                    setUserDomain(user_domain.data.domain)
                } else {
                    setUserDomain({})
                    }
            })();
        }
    },[
        selectedRow,
        identityServiceDomain,
        identityServiceVersion,
        domainsUrl,
        userRecord
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === usersUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        selectedRow,
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${servicesUrl}`
            const method = "GET"

            const services_response = await openstackRequest({url:url, method:method})
            if (services_response.status_code === usersUrlResponses.get.success_response.status_code) {
                setServiceCatalog([...services_response.data.services])
            } else {
                setServiceCatalog([])
            }
        })();
    },[
        identityServiceDomain, 
        identityServiceVersion, 
        servicesUrl
    ]);
    
    const onUserDisable = useCallback(async () => {
        const updated_data = await handleUserStatusChange(
            selectedRow.id,
            false
        )
        if (updated_data) {
            setCurrentAction("")
        }
        handleFetchData()
    }, [
        selectedRow,
        handleFetchData,
        handleUserStatusChange
    ]);

    const onUserEnable = useCallback(async () => {
        const updated_data = await handleUserStatusChange(
            selectedRow.id,
            true
        )
        if (updated_data) {
            setCurrentAction("")
        }
        handleFetchData()
    }, [
        selectedRow,
        handleFetchData,
        handleUserStatusChange
    ]);

    const onUserPasswordChange = useCallback(() => {
        setCurrentAction("")
        handleUserPasswordChange();
    },[
        handleUserPasswordChange
    ])

    const handleAddAppCredentialsDialogOpen = useCallback(() => {
        setAddAppCredentialsDialogOpen(true)
    },[
        setAddAppCredentialsDialogOpen
    ])

    const handleAppCredentialFormReset = () => {
        setAppCredentialForm({})
        setAccessRulesForm([])
        setAppCredentialFormOptions({})
        setAccessRulesFormOptions([])
    }

    const handleAddAppCredentialsDialogClose = () => {
        handleAppCredentialFormReset()
        setAddAppCredentialsDialogOpen(false)
    }

    const handleAddAppCredentialsModeChange = useCallback(() => {
        setCurrentAction("")
        handleAddAppCredentialsDialogOpen();
    },[
        handleAddAppCredentialsDialogOpen
    ])

    const handleAddExtraParams = async () => {
        const user_response = await handleAddExtraParameters(selectedRow.id,formExtraFields)
        if (user_response.status_code === usersUrlResponses.patch.success_response.status_code) {
            handleFetchData()
            handleAddExtraParamsModeReset()
            setSuccessUserUpdate({
                success_title: usersUrlResponses.patch.success_response.response_title, 
                success_message: usersUrlResponses.patch.success_response.response_message
            })
        } else {
            const error_response = usersUrlResponses.patch.error_response.filter(
                error_item => error_item.status_code === user_response.status_code)
            if (error_response.length > 0) {
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: user_response.error
                }
                setError(errorObject)
            } else {
                const error_response = usersUrlResponses.patch.error_response.filter(
                    error_item => error_item.status_code === "unknown")
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: user_response.error
                }
                setError(errorObject)
            }
        }
    }

    const handleEditExtraParameters = async () => {
        const url = `${identityServiceDomain}/${identityServiceVersion}/${usersUrl}/${selectedRow.id}`
        const method = "PATCH"

        const user_response = await openstackRequest({
            url: url, 
            method: method, 
            data: {user: customUserParamsFormFields}
        })
        if (user_response.status_code === usersUrlResponses.patch.success_response.status_code) {
            handleFetchData()
            handleEditExtraParamsModeReset()
            setSuccessUserUpdate({
                success_title: usersUrlResponses.patch.success_response.response_title, 
                success_message: usersUrlResponses.patch.success_response.response_message
            })
        } else {
            const error_response = usersUrlResponses.patch.error_response.filter(
                error_item => error_item.status_code === user_response.status_code)
            if (error_response.length > 0) {
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: user_response.error
                }
                setError(errorObject)
            } else {
                const error_response = usersUrlResponses.patch.error_response.filter(
                    error_item => error_item.status_code === "unknown")
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: user_response.error
                }
                setError(errorObject)
            }
        }
    }

    const handleFormFieldOptionsChange = (event,field_key) => {
        let new_form_data = {...formFieldOptions}
        if (userOptionsForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (userOptionsForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setFormFieldOptions(new_form_data)
    }

    const addOptionsToUserData = (data) => {
        let updated_data = {...data}
        
        for (let n in formFieldOptions) {
            if (formFieldOptions[n]) {
                updated_data["options"][n] = formFieldOptions[n]
            } else if (Object.keys(updated_data["options"]).includes(n)) {
                updated_data["options"][n] = formFieldOptions[n]
            }
        }
        return updated_data
    }

    const getOptionsForm = () => {
        return (
            <FormGroup>
                {userOptionsForm.map(field => {
                    let form_field_options = {}
                    form_field_options["required"] = field.required
                    return (
                        getFormFieldComponent(
                            field,
                            formFieldOptions,
                            handleFormFieldOptionsChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    useEffect(() => {
        if (Object.keys(formFieldOptions).length === 0) {
            let new_form_data = {}
            for (const n in userOptionsForm) {
                if (
                    userOptionsForm[n].field_type === "string" || 
                    userOptionsForm[n].field_type === "select" ||
                    userOptionsForm[n].field_type === "list"
                    ) {
                    new_form_data[userOptionsForm[n].field_key] = ""
                } else if (userOptionsForm[n].field_type === "bool") {
                    new_form_data[userOptionsForm[n].field_key] = selectedRow.options[userOptionsForm[n].field_key] ? 
                    selectedRow.options[userOptionsForm[n].field_key] : 
                    false
                }
            }
            setFormFieldOptions(new_form_data)
        }
    },[
        formFieldOptions,
        selectedRow
    ]);

    useEffect(() => {
        if (Object.keys(appCredentialForm).length === 0) {
            let new_form_data = {}
            for (const n in userAppCredentialsDataForm) {
                if (
                    userAppCredentialsDataForm[n].field_type === "string" || 
                    userAppCredentialsDataForm[n].field_type === "list"
                    ) {
                    new_form_data[userAppCredentialsDataForm[n].field_key] = ""
                } else if (userAppCredentialsDataForm[n].field_type === "bool") {
                    new_form_data[userAppCredentialsDataForm[n].field_key] = userAppCredentialsDataForm[n].default_value ? 
                    userAppCredentialsDataForm[n].default_value : 
                    false
                } else if (userAppCredentialsDataForm[n].field_key === "roles") {
                    new_form_data[userAppCredentialsDataForm[n].field_key] = []
                } else if (userAppCredentialsDataForm[n].field_type === "select") {
                    new_form_data[userAppCredentialsDataForm[n].field_key] = ""
                }
            }
            setAppCredentialForm(new_form_data)
        }
    },[appCredentialForm]);

    useEffect(() => {
        setTimeout(() => setFooterIsLoading(false), 600)
    },[]);

    useEffect(() => {
        if (selectedRow !== null) {
            let user_actions = []
            let new_action = {}
            if (selectedRow.enabled) {
                new_action["value"] = "user_disable"
                new_action["action"] = onUserDisable
                new_action["keyword"] = "userDisableActionTitle"
                new_action["button_text"] = "applyButtonTitleText"
                user_actions.push({...new_action})
            } else {
                new_action["value"] = "user_enable"
                new_action["action"] = onUserEnable
                new_action["keyword"] = "userEnableActionTitle"
                new_action["button_text"] = "applyButtonTitleText"
                user_actions.push({...new_action})
            }
            new_action = {}
            new_action["value"] = "user_add_parameters"
            new_action["action"] = handleAddExtraParamsModeChange
            new_action["keyword"] = "userAddParametersActionTitle"
            new_action["button_text"] = "selectButtonTitleText"
            user_actions.push({...new_action})
            if (customUserSchemaFields.length > 0) {
                new_action = {}
                new_action["value"] = "user_change_parameters"
                new_action["action"] = handleEditExtraParamsModeChange
                new_action["keyword"] = "userChangeParametersActionTitle"
                new_action["button_text"] = "selectButtonTitleText"
                user_actions.push({...new_action})
            }
            if (!selectedRow.options.lock_password) {
                new_action = {}
                new_action["value"] = "user_change_password"
                new_action["action"] = onUserPasswordChange
                new_action["keyword"] = "userChangePasswordActionTitle"
                new_action["button_text"] = "selectButtonTitleText"
                user_actions.push({...new_action})
            }
            if (selectedRow.name === login) {
                new_action = {}
                new_action["value"] = "user_add_app_credentials"
                new_action["action"] = handleAddAppCredentialsModeChange
                new_action["keyword"] = "userAddAppCredentialsActionTitle"
                new_action["button_text"] = "selectButtonTitleText"
                user_actions.push({...new_action})
            }
            setUserActions(user_actions)
        }
    },[
        selectedRow,
        defaultTexts,
        userGroups,
        userProjects,
        login,
        userRoles,
        customUserSchemaFields,
        onUserDisable,
        onUserEnable,
        onUserPasswordChange,
        handleAddExtraParamsModeChange,
        handleEditExtraParamsModeChange,
        handleAddAppCredentialsModeChange
    ]);

    const handleAccessRuleDelete = async (rule_id) => {
        const url = `${identityServiceDomain}/${identityServiceVersion}/${usersUrl}/${selectedRow.id}/access_rules/${rule_id}`
        const method = "DELETE"

        const user_response = await openstackRequest({url: url, method: method})
        if (user_response.status_code === usersUrlResponses.delete.success_response.status_code) {
            handleFetchData()
        } else {
            const error_response = usersUrlResponses.delete.error_response.filter(
                error_item => error_item.status_code === user_response.status_code)
            if (error_response.length > 0) {
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: user_response.error
                }
                setError(errorObject)
            } else {
                const error_response = usersUrlResponses.delete.error_response.filter(
                    error_item => error_item.status_code === "unknown")
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: user_response.error
                }
                setError(errorObject)
            }
        }
    }

    const handleAppCredentialDelete = async (cred_id) => {
        const url = `${identityServiceDomain}/${identityServiceVersion}/${usersUrl}/${selectedRow.id}/application_credentials/${cred_id}`
        const method = "DELETE"

        const user_response = await openstackRequest({url: url, method: method})
        if (user_response.status_code === usersUrlResponses.delete.success_response.status_code) {
            handleFetchData()
        } else {
            const error_response = usersUrlResponses.delete.error_response.filter(
                error_item => error_item.status_code === user_response.status_code)
            if (error_response.length > 0) {
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: user_response.error
                }
                setError(errorObject)
            } else {
                const error_response = usersUrlResponses.delete.error_response.filter(
                    error_item => error_item.status_code === "unknown")
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: user_response.error
                }
                setError(errorObject)
            }
        }
    }

    const getCustomParamsFormComponents = () => {
        return (
            <div>
                {addExtraParamsMode && 
                    !editExtraParamsMode && 
                    <WrapperBox
                        sx={{
                            m: 2, 
                            alignItems: 'start'
                        }}
                    >
                    <IconButton
                        onClick={handleAddExtraParamsModeReset}
                        sx={{
                        position: 'absolute',
                        right: 8,
                        top: 130,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Stack 
                        direction="row" 
                        spacing={2} 
                        alignItems="center"
                        sx={{my: 3}}
                    >
                        <CustomText size="h6">
                            {defaultTexts.extraFormFieldLabel}
                        </CustomText>
                        <PlusButton 
                            onClick={() => setFormExtraFields([
                                ...formExtraFields,
                                {field_key: "",field_value: ""}
                            ])} 
                        />
                    </Stack>
                    {formExtraFields.map((item,index) => {
                        return (
                            <Stack
                                key={index}
                                direction="row" 
                                spacing={2} 
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{my: 1}}
                            >
                                <CustomTextField
                                    currentValue={item.field_key} 
                                    setCurrentValue={handleFormExtraFieldsChange}
                                    field_key={[index,"key"]}
                                    label={defaultTexts.keyFormFieldLabel}
                                />
                                <CustomTextField
                                    currentValue={item.field_value} 
                                    setCurrentValue={handleFormExtraFieldsChange}
                                    field_key={[index,"value"]}
                                    label={defaultTexts.valueFormFieldLabel}
                                />
                                <MinusButton 
                                    sx={{width: 90}} 
                                    onClick={() => handleFormExtraFieldsRemove(index)}
                                />
                            </Stack>
                        )
                    })}
                    {formExtraFields.length > 0 && <Button 
                        variant="contained"
                        color="secondary"
                        sx={{mt: 5}}
                        onClick={handleAddExtraParams}
                    >
                        {defaultTexts.saveButtonText}
                    </Button>}
                </WrapperBox>}
                {!addExtraParamsMode && editExtraParamsMode && <WrapperBox
                    sx={{
                        m: 2, 
                        alignItems: 'start'
                    }}
                >
                    <IconButton
                        onClick={handleEditExtraParamsModeReset}
                        sx={{
                        position: 'absolute',
                        right: 8,
                        top: 130,
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    {customUserSchemaFields.map((item,index) => {
                        return (
                            <CustomTextField
                                key={index}
                                currentValue={customUserParamsFormFields[item.field_key]} 
                                setCurrentValue={(event) => {
                                    let new_data = {...customUserParamsFormFields}
                                    new_data[item.field_key] = event.target.value
                                    setCustomUserParamsFormFields(new_data)
                                }}
                                field_key={item.field_key}
                                label={item.field_key}
                                sx={{width: '80%', m:1}}
                            />
                        )
                    })}
                    <Button 
                        variant="contained"
                        color="secondary"
                        sx={{mt: 5}}
                        onClick={handleEditExtraParameters}
                    >
                        {defaultTexts.saveButtonText}
                    </Button>
                </WrapperBox>}
            </div>
        )
    }

    return (
        <React.Fragment>
        {selectedRow !== null && 
        <WrapperBox>
            <ServiceContentHeader 
                service_menu={userSubMenu}
                service_menu_titles={defaultTexts}
                onClick={handleUserDetailTabChange}
            />
        </WrapperBox>}
        {currentTab === "/user-details" &&
        <React.Fragment>
            {!editMode ? <Stack 
                spacing={2} 
                sx={{ p: 2, mt: 1, flexWrap: "wrap"}}
            >
                    {userDataSchema.map((field) => {
                        let value = selectedRow ? 
                        selectedRow[field.field_key] : ""
                        if (field.field_key === "domain_id") {
                            value = userDomain.name
                        } else if (field.field_key === "default_project_id") {
                            const userProject = projects.filter(project => project.id === value) 
                            if (userProject.length > 0) {
                                value = userProject[0].name
                            }
                        }
                        return (
                            getDetailDataComponent({
                                fieldType: field.field_type,
                                fieldKey: field.field_key,
                                label: defaultTexts[field.label],
                                value: value,
                                textOnTrue: defaultTexts[field.value_on_true] ? 
                                defaultTexts[field.value_on_true] : 
                                defaultTexts.formValueYes,
                                textOnFalse: defaultTexts[field.value_on_false] ? 
                                defaultTexts[field.value_on_false] : 
                                defaultTexts.formValueNo,
                                defaultTexts: defaultTexts,
                                formFields: userOptionsForm
                            })
                        )
                    })}
                </Stack> : 
                <Stack 
                    sx={{
                        m: 2, 
                        alignItems: 'start'
                    }}>
                <CustomText 
                    size="h6" 
                    sx={{
                        color: "primary.main", 
                        mb: 2
                    }}>
                    {defaultTexts.updateUserFormTitle}
                </CustomText>
                {getDataForm()}
                {getOptionsForm()}
                <Button 
                    variant="contained"
                    color="secondary"
                    sx={{mt: 5}}
                    onClick={handleEditUser}
                >
                    {defaultTexts.saveButtonText}
                </Button>
            </Stack>}
            </React.Fragment>}
            {currentTab === "/user-custom-params" && 
            customUserSchemaFields.length > 0 &&
                <div>
                {!addExtraParamsMode && 
                    !editExtraParamsMode ? 
                    <Stack 
                        spacing={2} 
                        sx={{ p: 2, mt: 1}}
                    >
                    {customUserSchemaFields.map((field) => {
                        return (
                            getDetailDataComponent({
                                fieldType: field.field_type,
                                fieldKey: field.field_key,
                                label: field.label.toUpperCase(),
                                value: selectedRow ? 
                                `${selectedRow[field.field_key]}` : "",
                                textOnTrue: defaultTexts[field.value_on_true] ? 
                                defaultTexts[field.value_on_true] : 
                                defaultTexts.formValueYes,
                                textOnFalse: defaultTexts[field.value_on_false] ? 
                                defaultTexts[field.value_on_true] : 
                                defaultTexts.formValueNo,
                                defaultTexts: defaultTexts,
                                formFields: userOptionsForm
                            })
                        )
                    })}
                </Stack> :
                    getCustomParamsFormComponents()}
                </div>
            }
            {currentTab === "/user-custom-params" && 
                customUserSchemaFields.length === 0 &&
                <div>
                    {!addExtraParamsMode && !editExtraParamsMode ? 
                        getEmptyListNoteComponent(defaultTexts.noCustomParamsNoteText) :
                        getCustomParamsFormComponents()
                    }
                </div>
            }
            {currentTab === "/user-groups" && 
            userGroups.length > 0 &&
                <Stack 
                    spacing={2} 
                    sx={{ p: 2, mt: 1}}
                >
                    {userGroups.map(group => {
                        return (
                            <Stack key={group.id}>
                                <CustomText size="h6">{group.name}</CustomText>
                                <CustomText size="p">{group.description}</CustomText>
                                <Divider />
                            </Stack>
                        )
                        
                    })}
                </Stack>
            } 
            {currentTab === "/user-groups" && 
            userGroups.length === 0 &&
                getEmptyListNoteComponent(defaultTexts.noGroupsNoteText)
            }
        {currentTab === "/user-projects" &&
        userProjects.length > 0 &&
            <Stack 
                spacing={2} 
                sx={{ p: 2, mt: 1}}
            >
                {userProjects.map(project => {
                    return (
                        <Stack key={project.id}>
                            <CustomText size="p">{defaultTexts.nameFormFieldLabel}: {project.name}</CustomText>
                            <CustomText size="p">{defaultTexts.descriptionFormFieldLabel}: {project.description}</CustomText>
                            <Divider />
                        </Stack>
                    )
                    
                })}
            </Stack>
        }
        {currentTab === "/user-projects" &&
            userProjects.length === 0 &&
                getEmptyListNoteComponent(defaultTexts.noProjectsNoteText)
        }
        {currentTab === "/user-access-rules" && 
            userAccessRules.length > 0 &&
            <Stack 
                spacing={2} 
                sx={{ p: 2, mt: 1}}
            >
                {userAccessRules.map(rule => {
                    return (
                        <Stack key={rule.id} >
                            <Stack                                
                                direction="row"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <Stack key={rule.id}>
                                    <CustomText size="p">{defaultTexts.pathFormFieldLabel}: {rule.path}</CustomText>
                                    <CustomText size="p">{defaultTexts.methodFormFieldLabel}: {rule.method}</CustomText>
                                    <CustomText size="p">{defaultTexts.serviceFormFieldLabel}: {rule.service}</CustomText>
                                </Stack>
                                <IconButton
                                    color="error"
                                    onClick={() => handleAccessRuleDelete(rule.id)}
                                >
                                    <DeleteIcon/>
                                </IconButton>
                            </Stack>
                            <Divider />
                        </Stack>
                    )
                })}
            </Stack>
        } 
        {currentTab === "/user-access-rules" && 
            userAccessRules.length === 0 &&
                getEmptyListNoteComponent(defaultTexts.noAccessRulesNoteText)
        }
        {currentTab === "/user-application-credentials" && 
            userApplicationCredentials.length > 0 &&
            <Stack 
                spacing={2} 
                sx={{ p: 2, mt: 1}}
            >
                {userApplicationCredentials.map(cred => {
                    
                    return (
                        <Stack key={cred.id} >
                            <Stack 
                                direction="row"
                                justifyContent="space-between"
                                alignItems="start"
                            >
                                <Grid container spacing={3}>
                                    <Grid item xs={12} lg={6}>
                                        <Stack>
                                            <CustomText size="p" sx={{color: "secondary.main"}}>{defaultTexts.submenuDetails}:</CustomText>
                                            <CustomText>{defaultTexts.idFormFieldLabel}: {cred.id}</CustomText>
                                            <CustomText size="p">{defaultTexts.nameFormFieldLabel}: {cred.name}</CustomText>
                                            <CustomText size="p">{defaultTexts.descriptionFormFieldLabel}: {cred.description}</CustomText>
                                            <CustomText size="p">{defaultTexts.projectFormFieldLabel}: {userProjects.filter(
                                                item => item.id === cred.project_id)[0].name}</CustomText>
                                            <CustomText size="p">{defaultTexts.expiresAtFormFieldLable}: {cred.expires_at ? 
                                                new Date(cred.expires_at).toString() : 
                                                defaultTexts.neverFormValue}</CustomText>
                                            <CustomText size="p">{defaultTexts.unrestrictedFormFieldLabel}: {cred.unrestricted ? 
                                                defaultTexts.formValueYes : 
                                                defaultTexts.formValueNo}</CustomText>
                                        </Stack>
                                        <Stack sx={{mt: 3}}>
                                            <CustomText size="p" sx={{color: "secondary.main"}}>{defaultTexts.submenuRoles}:</CustomText>
                                            {cred.roles.map((role, ind) => {
                                                let last_ind = cred.roles.length - 1 === ind
                                                return (<React.Fragment>
                                                    <CustomText size="p">{role.name}</CustomText>
                                                    {!last_ind && <Divider />}
                                                </React.Fragment>)
                                            })}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} lg={6}>
                                        <Stack>
                                            <CustomText size="p" sx={{color: "secondary.main"}}>{defaultTexts.submenuAccessRules}:</CustomText>
                                            {cred.access_rules && cred.access_rules.map((rule,ind) => {
                                                console.log(rule)
                                                console.log(serviceCatalog.filter(
                                                    item => item.type === rule.service))
                                                let last_ind = cred.access_rules.length - 1 === ind
                                                return (
                                                    <React.Fragment>
                                                        <CustomText size="p">{defaultTexts.pathFormFieldLabel}: {rule.path}</CustomText>
                                                        <CustomText size="p">{defaultTexts.methodFormFieldLabel}: {rule.method}</CustomText>
                                                        <CustomText size="p">{defaultTexts.serviceFormFieldLabel}: {rule.service}</CustomText>
                                                            {!last_ind && <Divider />}
                                                    </React.Fragment>
                                                )
                                                
                                            })}
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <IconButton
                                    color="error"
                                    onClick={() => handleAppCredentialDelete(cred.id)}
                                >
                                    <DeleteIcon/>
                                </IconButton>
                            </Stack>
                            <Divider />
                        </Stack>
                    )
                })}
            </Stack>
        } 
        {currentTab === "/user-application-credentials" && 
        userApplicationCredentials.length === 0 &&
                getEmptyListNoteComponent(defaultTexts.noApplicationCredentialsNoteText)
        }
            { !footerIsLoading && <Paper sx={{ 
                position: 'fixed', 
                bottom: 0, 
                height: Constants.actions_bar_height + 20,
                left: width - (width * widthWeight), 
                right: 12
                }} 
                elevation={24}
                square={true}
            >
                <Grid 
                    container 
                    alignItems="center"  
                    justifyContent="space-between"
                >
                    <Grid item>
                        <CustomSelectField 
                            items={userActions} 
                            currentValue={currentAction}
                            setCurrentValue={setCurrentAction}
                            item_titles={defaultTexts}
                            label={defaultTexts.actionsDropdownLabelText}
                            empty={true}
                            size="small"
                            sx={{m: 1}}
                        />
                        {currentAction.length > 0 && 
                            <Button 
                                    variant="contained"
                                    color="secondary"
                                    sx={{m: 1, height: '70%'}}
                                    onClick={userActions.filter(
                                        action => action.value === currentAction)[0].action
                                    }
                                >
                                {defaultTexts[userActions.filter(
                                    action => action.value === currentAction)[0].button_text]}
                            </Button>
                        }
                    </Grid>
                    <Grid item>
                        {!editMode ? <IconButton onClick={handleEditModeChange}>
                                <EditIcon color="primary" />
                            </IconButton> :
                            <IconButton onClick={handleEditModeReset}>
                                <EditOffIcon color="primary"/>
                            </IconButton>
                        }
                        {selectedRow !== null && 
                            <IconButton onClick={handleConfirmDeleteDialogOpen}>
                                <DeleteIcon 
                                    color="primary"
                                />
                            </IconButton>}
                    </Grid>
                </Grid>
            </Paper>} 
            <CustomDialog
                open={roleAssignmentsDialogOpenMode}
                onClose={handleRoleAssignmentsDialogClose}
                dialogTitle={{
                    title: defaultTexts.roleAssignmentsTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                maxWidth="lg"
            >
                <IdentityRoleAssignmentsV3
                    roleAssignments={selectedRoleAssignments}
                    target={`${selectedRow.name}`}
                    handleRoleAssignmentChange={handleRoleAssignmentsPopupOpen}
                />
            </CustomDialog>
            <CustomDialog
                open={addAppCredentialsDialogOpen}
                onClose={handleAddAppCredentialsDialogClose}
                dialogTitle={{
                    title: defaultTexts.userAddAppCredentialsDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.appCredentialFormDescriptionText, 
                    sx: {color: 'text.primary', mb:3}}}
                actionButtons={[{
                    title: defaultTexts.applyButtonTitleText, 
                    onClick: handleAppCredentialAdd, 
                    variant: "contained",
                    size: "medium"
                }]}
            >
                {getAppCredentialsDataForm()}
                <CustomText 
                    size="p"
                >
                    {defaultTexts.submenuAccessRules}
                </CustomText>
                <PlusButton 
                    onClick={() => setAccessRulesForm([
                        ...accessRulesForm,
                        {path: "",method: "",service:""}
                    ])} 
                />
                {accessRulesForm.map((item,index) => {
                    return (
                        <div key={index}>
                            <CustomText 
                                size="p"
                            >
                                {`${defaultTexts.ruleFormFieldLabel} ${index + 1}`}
                            </CustomText>
                            <MinusButton 
                                onClick={() => handleFormAccessRuleRemove(index)}
                            />
                            {getUserAccessRuleDataForm(index)}
                        </div>
                    )
                })}
            </CustomDialog>
            {successUserDelete && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[successUserDelete.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successUserDelete.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {successUserUpdate && <CustomDialog
                open={successUpdateDialogOpen}
                onClose={handleSuccessUpdateDialogClose}
                dialogTitle={{
                    title: defaultTexts[successUserUpdate.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: successUserUpdate.success_details ?
                        `<span>${defaultTexts[successUserUpdate.success_message]}</span>
                            <br>
                            <br>
                            <span>Secret Key:</span> 
                            <span style="width: 100px; color: orange; word-wrap: break-word;">
                                ${successUserUpdate.success_details}
                            </span>` :
                        `<span>${defaultTexts[successUserUpdate.success_message]}</span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>Details:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            {confirmDeleteDialogOpen && <CustomDialog
                open={confirmDeleteDialogOpen}
                onClose={handleConfirmDeleteDialogClose}
                dialogTitle={{
                    title: defaultTexts.deleteConfirmationDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.deleteUserConfirmationDialogMessage, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: handleDeleteUser, 
                    sx: {color: 'primary.main'}}]}
            />}
        </React.Fragment>
    )
};

export default IdentityUserDetailV3;