export const filterMenu = [
    {
        keyword: "invoiceIDFilterMenuChoiceText", 
        value: "invoice_id", 
        type: "text"
    },
    {
        keyword: "projectFormFieldLabel", 
        value: "project_id", 
        type: "select", 
        multiple: false,
        items: []
    }
]