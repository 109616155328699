import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ServiceContentHeader from '../../../_common/ServiceContentHeader';
import Box from '@mui/material/Box';
import VolumeTypesWrapperV3 from './volume_types/volumeTypesWrapperV3';
import VolumesWrapperV3 from './volumes/volumesWrapperV3';
import SnapshotsWrapperV3 from './snapshots/snapshotsWrapperV3';
import BackupsWrapperV3 from './backups/backupsWrapperV3';
import GroupsWrapperV3 from './groups/groupsWrapperV3';
import GroupSnapshotsWrapperV3 from './group_snapshots/groupSnapshotsWrapperV3';
import GroupTypesWrapperV3 from './group_types/groupTypesWrapperV3';
import LimitsWrapperV3 from './limits/limitsWrapperV3';

const VolumesCinderContentV3 = (props) => {
    const { navigate, location } = props
    const { serviceTitle } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)

    const [serviceMenu, setServiceMenu] = useState([
        {keyword: "submenuVolumes", navigation: "/volumes", is_active: true, component: VolumesWrapperV3},
        {keyword: "submenuVolumeTypes", navigation: "/volume-types", is_active: false, component: VolumeTypesWrapperV3},
        {keyword: "submenuSnapshots", navigation: "/snapshots", is_active: false, component: SnapshotsWrapperV3},
        {keyword: "submenuBackups", navigation: "/backups", is_active: false, component: BackupsWrapperV3},
        {keyword: "submenuGroups", navigation: "/volume-groups", is_active: false, component: GroupsWrapperV3},
        {keyword: "submenuGroupSnapshots", navigation: "/volume-group-snapshots", is_active: false, component: GroupSnapshotsWrapperV3},
        {keyword: "submenuGroupTypes", navigation: "/volume-group-types", is_active: false, component: GroupTypesWrapperV3},
        //{keyword: "submenuClusters", navigation: "/clusters", is_active: false, component: () => <Box />},
        {keyword: "submenuLimits", navigation: "/volume-limits", is_active: false, component: LimitsWrapperV3}
    ])

    const getCurrentTab = () => {
            const currentItem = serviceMenu.filter(item => item.is_active)[0]
            return currentItem
    }
    const [currentTab, setCurrentTab] = useState(getCurrentTab())
    

    const changeMenuActiveTab = (navigation) => {
        const updated_menu = serviceMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
                setCurrentTab(item)
            } else {
                item.is_active = false
            }
            return item
        })
        setServiceMenu(updated_menu)
    }

    return (
        <Box>
            <ServiceContentHeader 
                service_title={serviceTitle} 
                service_menu={serviceMenu}
                service_menu_titles={defaultTexts}
                onClick={changeMenuActiveTab}
            />
            <currentTab.component 
                navigate={navigate} 
                location={location}
                changeMenuActiveTab={changeMenuActiveTab}
            />
        </Box>
    )
};

export default VolumesCinderContentV3;