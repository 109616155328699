import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import NoDataNote from '../../../../_common/NoDataNote';
import CustomText from '../../../../_common/CustomText';
import WrapperBox from '../../../../_common/WrapperBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOffIcon from '@mui/icons-material/EditOff';
import Paper from '@mui/material/Paper';
import useWindowDimensions from 
'../../../../_common/WindowDimensions';
import Constants from '../../../../../config/constants';
import { IconButton }  from '@mui/material';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { 
    volumeCinderRequest, 
    getXAuthTokenProjectScope
 } from 
'../../../../../_network/openstack_request';
import { groupTypesUrl as groupTypesUrlResponses } from 
'../../../../../_api_responses/openstack/cinder/group_types/v3';
import { 
    openStackServices, 
    blockStorageCinderConstants
} from 
'../../../../../config/openStackConstants';
import CustomDialog from 
'../../../../_common/CustomDialog';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import { FormGroup } from '@mui/material';
import { 
    groupTypeDataForm, 
    groupTypeDataSchema
} from '../../../../../_data/openstack/cinder/group_types/v3';
import { getDetailDataComponent } from 
'../../../../_common/common_helpers';
import ServiceContentHeader from '../../../../_common/ServiceContentHeader';
import { Divider } from '@mui/material';
import CustomSelectField from 
'../../../../_common/_form_fields/CustomSelectField';
import PlusButton from '../../../../_common/PlusButton';
import MinusButton from '../../../../_common/MinusButton';
import CustomTextField from '../../../../_common/_form_fields/CustomTextField';
import CloseIcon from '@mui/icons-material/Close';

const SERVICE_NAME = openStackServices.volumeService
const FOOTER_HEIGHT = Constants.actions_bar_height + 20

const GroupTypeDetailV3 = (props) => {
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const { selectedRow } = props
    const { widthWeight } = props
    const { width } = useWindowDimensions();
    const FOOTER_WIDTH = width - (width * widthWeight)
    const { 
        handleFetchData,
        handleGroupTypeDelete
    } = props
    
    const [editMode, setEditMode] = useState(false);
    const [updateExtraSpecsMode, setUpdateExtraSpecsMode] = useState(false)
    
    const [error, setError] = useState()
    const [successGroupTypeUpdate, setSuccessGroupTypeUpdate] = useState()
    const [successUpdateDialogOpen, setSuccessUpdateDialogOpen] = useState(false);
    
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

    const [updateFormData, setUpdateFormData] = useState(
        {...selectedRow}
    )
    const [groupTypeActions, setGroupTypeActions] = useState([]);
    const [currentAction, setCurrentAction] = useState("");
    const [formExtraFields, setFormExtraFields] = useState([]);

    const [groupTypeSubMenu, setGroupTypeSubMenu] = useState([
        {keyword: "submenuDetails", navigation: "/gt-details", is_active: true},
        {keyword: "submenuExtraSpecs", navigation: "/gt-extra-specs", is_active: false}
    ])
    
    const [currentTab, setCurrentTab] = useState("/gt-details")

    const cinderServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const cinderServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const groupTypesUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.groupTypesUrl)[0].url)
    
    const handleFormDataChange = (event,field_key) => {
        let new_form_data = {...updateFormData}
        if (groupTypeDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (groupTypeDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setUpdateFormData(new_form_data)
    }


    const getDataForm = () => {
        return (
            <FormGroup>
                {groupTypeDataForm.map(field => {
                    let form_field_options = {}
                return (
                    getFormFieldComponent(
                        field,
                        updateFormData,
                        handleFormDataChange,
                        defaultTexts[field.label],
                        {
                            size:"medium",
                            withHelp: field.with_help_text,
                            helpText: defaultTexts[field.help_text],
                            sx: {
                                my: 1, 
                                width: width * widthWeight * 0.8
                            },
                        ...form_field_options
                        }
                    )
                )
            })}
        </FormGroup>)
    }

    const handleEditModeChange = () => {
        handleGroupTypeDetailTabChange("/gt-details")
        setEditMode(true)
    }

    const handleEditModeReset = () => {
        setUpdateFormData({...selectedRow})
        handleGroupTypeDetailTabChange("/gt-details")
        setEditMode(false)
    }
    
    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }
    
    const handleSuccessUpdateDialogClose = () => {
        setSuccessGroupTypeUpdate(null)
        setUpdateFormData({...selectedRow})
        setSuccessUpdateDialogOpen(false);
    }
    const handleConfirmDeleteDialogOpen = () => {
        handleGroupTypeDelete([selectedRow.id])
    }
    const handleConfirmDeleteDialogClose = () => {
        setConfirmDeleteDialogOpen(false)
    }

    const dataFormatting = (data) => {
        let update_data = {}
        for (let i in groupTypeDataForm) {
            if (groupTypeDataForm[i].field_key !== "is_public") {
                update_data[groupTypeDataForm[i].field_key] = data[groupTypeDataForm[i].field_key]
            } else {
                update_data["is_public"] = data.is_public
            }
        }
        return update_data
    }

    const formatSpecsData = () => {
        let new_data = {}
        for (let i in formExtraFields) {
            new_data[formExtraFields[i].field_key] = formExtraFields[i].field_value
        }
        const keys_to_delete = Object.keys(selectedRow.group_specs).filter(key => !Object.keys(new_data).includes(key))
        return {keys_to_add: new_data, keys_to_delete: keys_to_delete}
    }

    const handleUpdateExtraSpecs = async () => {
        const data = formatSpecsData()
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupTypesUrl}/${selectedRow.id}/group_specs`
            let method = "POST"

            const response = await volumeCinderRequest({
                url: url, 
                method: method, 
                data: {group_specs: data.keys_to_add},
                token: project_token
            })
            for (let d in data.keys_to_delete) {
                url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupTypesUrl}/${selectedRow.id}/group_specs/${data.keys_to_delete[d]}`
                method = "DELETE"

                await volumeCinderRequest({
                    url: url, 
                    method: method, 
                    token: project_token
                })
            }
            if (response.status_code === groupTypesUrlResponses.post.success_response.status_code) {
                setCurrentAction("")
                handleFetchData()
                handleAddExtraSpecsModeReset()
            }
        }
    }

    const handleGroupTypeDetailTabChange = useCallback((navigation) => {
        let newVTSubmenuData = groupTypeSubMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
            } else {
                item.is_active = false
            }
            return item
        })
        setGroupTypeSubMenu(newVTSubmenuData)
        setCurrentTab(navigation)
    },[
        groupTypeSubMenu,
        setGroupTypeSubMenu
    ])
    
    const handleUpdateExtraSpecsModeChange = useCallback(() => {
        handleGroupTypeDetailTabChange("/gt-extra-specs")
        setUpdateExtraSpecsMode(true)
    },[handleGroupTypeDetailTabChange])


    const handleFormExtraFieldsChange = (event,field_key_list) => {
        let new_extra_data = [...formExtraFields]
        if (field_key_list[1] === "key") {
            new_extra_data[field_key_list[0]].field_key = event.target.value
        } else {
            new_extra_data[field_key_list[0]].field_value = event.target.value
        }
        setFormExtraFields(new_extra_data)
    }

    const handleFormExtraFieldsRemove = (index) => {
        if (formExtraFields.length > 1) {
            let updated_data = [...formExtraFields]
            updated_data.splice(index, 1)
            setFormExtraFields(updated_data)
        } else {
            setFormExtraFields([])
        }
    }

    const handleAddExtraSpecsModeReset = () => {
        if (Object.keys(selectedRow.group_specs).length > 0) {
            const group_specs_form = Object.keys(selectedRow.group_specs).map(key => {
                let new_item = {}
                new_item["field_key"] = key
                new_item["field_value"] = selectedRow.group_specs[key]
                return new_item
            })
            setFormExtraFields(group_specs_form)
        }
        setUpdateExtraSpecsMode(false)
    }

    const handleEditGroupType = async () => {
        let updated_data = {...updateFormData}
        updated_data = dataFormatting(updated_data)
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupTypesUrl}/${selectedRow.id}`
            const method = "PUT"

            const vt_response = await volumeCinderRequest({
                url: url, 
                method: method, 
                data: {group_type: updated_data},
                token: project_token
            })
            if (vt_response.status_code === groupTypesUrlResponses.put.success_response.status_code) {
                handleFetchData()
                handleEditModeReset()
                setSuccessGroupTypeUpdate({
                    success_title: groupTypesUrlResponses.put.success_response.response_title, 
                    success_message: groupTypesUrlResponses.put.success_response.response_message
                })
            } else {
                const error_response = groupTypesUrlResponses.put.error_response.filter(
                    error_item => error_item.status_code === vt_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: vt_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = groupTypesUrlResponses.put.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: vt_response.error
                    }
                    setError(errorObject)
                }
            }
        }
    }

    const getExtraSpecsFormComponents = () => {
        return (
            <WrapperBox
                sx={{
                    m: 2, 
                    alignItems: 'start'
                }}
            >
                <IconButton
                    onClick={handleAddExtraSpecsModeReset}
                    sx={{
                    position: 'absolute',
                    right: 8,
                    top: 130,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Stack 
                    direction="row" 
                    spacing={2} 
                    alignItems="center"
                    sx={{my: 3}}
                >
                    <CustomText size="h6">
                        {defaultTexts.extraFormFieldLabel}
                    </CustomText>
                    <PlusButton 
                        onClick={() => setFormExtraFields([
                            ...formExtraFields,
                            {field_key: "",field_value: ""}
                        ])} 
                    />
                </Stack>
                {formExtraFields.map((item,index) => {
                    return (
                        <Stack
                            key={index}
                            direction="row" 
                            spacing={2} 
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{my: 1}}
                        >
                            <CustomTextField
                                currentValue={item.field_key} 
                                setCurrentValue={handleFormExtraFieldsChange}
                                field_key={[index,"key"]}
                                label={defaultTexts.keyFormFieldLabel}
                            />
                            <CustomTextField
                                currentValue={item.field_value} 
                                setCurrentValue={handleFormExtraFieldsChange}
                                field_key={[index,"value"]}
                                label={defaultTexts.valueFormFieldLabel}
                            />
                            <MinusButton 
                                sx={{width: 90}} 
                                onClick={() => handleFormExtraFieldsRemove(index)}
                            />
                        </Stack>
                    )
                })}
                <Button 
                    variant="contained"
                    color="secondary"
                    sx={{mt: 5}}
                    onClick={handleUpdateExtraSpecs}
                >
                    {defaultTexts.saveButtonText}
                </Button>
            </WrapperBox>
        )
    }

    useEffect(() => {
        if (Object.keys(selectedRow.group_specs).length > 0) {
            const group_specs_form = Object.keys(selectedRow.group_specs).map(key => {
                let new_item = {}
                new_item["field_key"] = key
                new_item["field_value"] = selectedRow.group_specs[key]
                return new_item
            })
            setFormExtraFields(group_specs_form)
        }
    },[
        selectedRow
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessUpdateDialogOpen(true)
    },[successGroupTypeUpdate]);

    useEffect(() => {
        if (selectedRow !== null) {
            let vt_actions = []
            let new_action = {}
            new_action["value"] = "update_group_specs"
            new_action["action"] = handleUpdateExtraSpecsModeChange
            new_action["keyword"] = "updateExtraSpecsActionTitle"
            new_action["button_text"] = "selectButtonTitleText"
            vt_actions.push({...new_action})
            setGroupTypeActions(vt_actions)
        }
    },[
        selectedRow,
        defaultTexts,
        handleUpdateExtraSpecsModeChange
    ]);

    return (
        <React.Fragment>
        {selectedRow !== null && 
            <WrapperBox>
                <ServiceContentHeader 
                    service_menu={groupTypeSubMenu}
                    service_menu_titles={defaultTexts}
                    onClick={handleGroupTypeDetailTabChange}
                />
            </WrapperBox>}
            {currentTab === "/gt-details" &&
                <React.Fragment>
                    {!editMode ? 
                    <Stack 
                        spacing={2} 
                        sx={{ p: 2, mt: 1, flexWrap: "wrap"}}
                    >
                        {groupTypeDataSchema.map((field) => {
                            let value = selectedRow ? 
                            selectedRow[field.field_key] : ""
                            return (
                                getDetailDataComponent({
                                    fieldType: field.field_type,
                                    fieldKey: field.field_key,
                                    label: defaultTexts[field.label],
                                    value: value,
                                    textOnTrue: defaultTexts[field.value_on_true] ? 
                                    defaultTexts[field.value_on_true] : 
                                    defaultTexts.formValueYes,
                                    textOnFalse: defaultTexts[field.value_on_false] ? 
                                    defaultTexts[field.value_on_false] : 
                                    defaultTexts.formValueNo,
                                    defaultTexts: defaultTexts
                                })
                            )
                        })}
                        </Stack> : 
                        <Stack 
                            sx={{
                                m: 2, 
                                alignItems: 'start'
                            }}>
                        <CustomText 
                            size="h6" 
                            sx={{
                                color: "primary.main", 
                                mb: 2
                            }}>
                            {defaultTexts.updateGroupTypeFormTitle}
                        </CustomText>
                        {getDataForm()}
                        <Button 
                            variant="contained"
                            color="secondary"
                            sx={{mt: 5}}
                            onClick={handleEditGroupType}
                        >
                            {defaultTexts.saveButtonText}
                        </Button>
                    </Stack>}
                </React.Fragment>}
                {currentTab === "/gt-extra-specs" && 
                !updateExtraSpecsMode &&  
                    <div>
                        {Object.keys(selectedRow.group_specs).length > 0 ? 
                            <Stack 
                                spacing={2} 
                                sx={{ p: 2, mt: 1}}
                            >
                                {Object.keys(selectedRow.group_specs).map(key => {
                                    return (
                                        <Stack key={key} spacing={1} >
                                            <Stack 
                                                direction="row" 
                                                justifyContent="space-between" 
                                                alignItems="center"
                                            >
                                                <CustomText>{key}</CustomText>
                                                <CustomText>{selectedRow.group_specs[key]}</CustomText>
                                            </Stack>
                                            <Divider />
                                        </Stack>
                                    )
                                    
                                })}
                            </Stack> : 
                            <NoDataNote text={defaultTexts.noExtraSpecsNoteText} />}
                    </div>
                }
                {currentTab === "/gt-extra-specs" && 
                    updateExtraSpecsMode &&  
                    getExtraSpecsFormComponents()
                }
            <Paper sx={{ 
                position: 'fixed', 
                bottom: 0, 
                height: FOOTER_HEIGHT,
                left: FOOTER_WIDTH, 
                right: 12,
                zIndex: 3000
                }} 
                elevation={24}
                square={true}
            >
                <Grid 
                    container 
                    alignItems="center"  
                    justifyContent="space-between"
                >
                    <Grid item>
                        <CustomSelectField 
                            items={groupTypeActions} 
                            currentValue={currentAction}
                            setCurrentValue={setCurrentAction}
                            item_titles={defaultTexts}
                            label={defaultTexts.actionsDropdownLabelText}
                            empty={true}
                            size="small"
                            sx={{m: 1}}
                        />
                        {currentAction.length > 0 && 
                            <Button 
                                    variant="contained"
                                    color="secondary"
                                    sx={{m: 1, height: '70%'}}
                                    onClick={groupTypeActions.filter(
                                        action => action.value === currentAction)[0].action
                                    }
                                >
                                {defaultTexts[groupTypeActions.filter(
                                    action => action.value === currentAction)[0].button_text]}
                            </Button>
                        }
                    </Grid>
                    <Grid item>
                        {!editMode ? <IconButton onClick={handleEditModeChange}>
                                <EditIcon color="primary" />
                            </IconButton> :
                            <IconButton onClick={handleEditModeReset}>
                                <EditOffIcon color="primary"/>
                            </IconButton>
                        }
                        {selectedRow !== null && 
                            <IconButton onClick={handleConfirmDeleteDialogOpen}>
                                <DeleteIcon 
                                    color="primary"
                                />
                            </IconButton>}
                    </Grid>
                </Grid>
            </Paper> 
            {successGroupTypeUpdate && <CustomDialog
                open={successUpdateDialogOpen}
                onClose={handleSuccessUpdateDialogClose}
                dialogTitle={{
                    title: defaultTexts[successGroupTypeUpdate.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: successGroupTypeUpdate.success_details ?
                        `<span>${defaultTexts[successGroupTypeUpdate.success_message]}</span>
                            <br>
                            <br>
                            <span>Secret Key:</span> 
                            <span style="width: 100px; color: orange; word-wrap: break-word;">
                                ${successGroupTypeUpdate.success_details}
                            </span>` :
                        `<span>${defaultTexts[successGroupTypeUpdate.success_message]}</span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            {confirmDeleteDialogOpen && <CustomDialog
                open={confirmDeleteDialogOpen}
                onClose={handleConfirmDeleteDialogClose}
                dialogTitle={{
                    title: defaultTexts.deleteConfirmationDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.deleteVTConfirmationDialogMessage, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: () => {}, 
                    sx: {color: 'primary.main'}}]}
            />}
        </React.Fragment>
    )

};

export default GroupTypeDetailV3;