import React, { useState } from 'react';
import Box from '@mui/material/Box';
import FirewallGroupsWrapperV20 from './fw_groups/firewallGroupsWrapperV2.0';
import FirewallPoliciesWrapperV20 from './fw_policies/firewallPoliciesWrapperV2.0';
import FirewallRulesWrapperV20 from './fw_rules/firewallRulesWrapperV2.0';


const FirewallWrapperV20 = (props) => {
    const { navigate, location, changeMenuActiveTab } = props
    const [currentTab, setCurrentTab] = useState("firewall_groups")


    return (
        <Box>
            {currentTab === "firewall_groups" && 
                <FirewallGroupsWrapperV20 
                    navigate={navigate}
                    location={location}
                    changeMenuActiveTab={changeMenuActiveTab}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                />
            }
            {currentTab === "firewall_policies" && 
                <FirewallPoliciesWrapperV20
                    navigate={navigate}
                    location={location}
                    changeMenuActiveTab={changeMenuActiveTab}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                />
            }
            {currentTab === "firewall_rules" &&
                <FirewallRulesWrapperV20
                    navigate={navigate}
                    location={location}
                    changeMenuActiveTab={changeMenuActiveTab}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                />
            }
        </Box>
    )
};

export default FirewallWrapperV20;