import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import CustomDismissCard from '../../../../_common/CustomDismissCard';
import CustomCard from '../../../../_common/CustomCard';
import { Grid } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import {Box} from '@mui/material';
import { Stack } from '@mui/material';
import { Button }  from '@mui/material';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';
import { RxReset } from "react-icons/rx";
import useWindowDimensions from '../../../../_common/WindowDimensions';
import Dimensions from '../../../../../config/dimensions';
import CustomTextField from '../../../../_common/_form_fields/CustomTextField';
import { volumeCinderRequest, getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { blockStorageCinderConstants } from 
'../../../../../config/openStackConstants';
import IconButton from '@mui/material/IconButton';
import { volumesUrl as volumesUrlResponses} from
'../../../../../_api_responses/openstack/cinder/volumes/v3';
import { BsHddNetwork } from "react-icons/bs";
import { IoServerSharp } from "react-icons/io5";
import { LuDatabaseBackup } from "react-icons/lu";
import { GoCopy } from "react-icons/go";
import { MdOutlineSettingsBackupRestore } from "react-icons/md";
import { FaLayerGroup } from "react-icons/fa6";

const SERVICE_NAME = openStackServices.volumeService

const LimitsDetailV3 = (props) => {
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const { data, setData, projectName } = props
    const { width } = useWindowDimensions();
    const { onCardClose } = props
    const [editMode, setEditMode] = useState(false)
    const [editedData, setEditedData] = useState({...data})
    const defaultTexts = useSelector(state => state.texts.langTexts)

    const pieChartTexts = [
        {
            field_key: "maxTotalVolumes", 
            used_key: "totalVolumesUsed", 
            icon: BsHddNetwork, 
            title: defaultTexts.volumesLimitTitleText, 
            legend: [
                defaultTexts.availableVolumesLegendText,
                defaultTexts.totalUsedVolumesLegendText
            ]
        },
        {
            field_key: "maxTotalVolumeGigabytes", 
            used_key: "totalVolumeGigabytesUsed", 
            icon: IoServerSharp, 
            title: defaultTexts.storageLimitTitleText, 
            legend: [
                defaultTexts.availableStorageGBLegendText,
                defaultTexts.totalUsedStorageGBLegendText
            ]
        },
        {
            field_key: "maxTotalSnapshots", 
            used_key: "totalSnapshotsUsed", 
            icon: GoCopy, 
            title: defaultTexts.snapshotsLimitTitleText, 
            legend: [
                defaultTexts.availableSnapshotsLegendText,
                defaultTexts.totalUsedSnapshotsLegendText
            ]
        },
        {
            field_key: "maxTotalBackups", 
            used_key: "totalBackupsUsed", 
            icon: MdOutlineSettingsBackupRestore, 
            title: defaultTexts.backupsLimitTitleText, 
            legend: [
                defaultTexts.availableBackupsLegendText,
                defaultTexts.totalUsedBackupsLegendText
            ]
        },
        {
            field_key: "maxTotalBackupGigabytes", 
            used_key: "totalBackupGigabytesUsed", 
            icon: LuDatabaseBackup, 
            title: defaultTexts.backupStorageLimitTitleText, 
            legend: [
                defaultTexts.availableBackupsGBLegendText,
                defaultTexts.totalUsedBackupsGBLegendText
            ]
        },
        {
            field_key: "maxTotalGroups", 
            used_key: "totalGroupsUsed", 
            icon: FaLayerGroup, 
            title: defaultTexts.volumeGroupsLimitTitleText, 
            legend: [
                defaultTexts.availableVolumeGroupsLegendText,
                defaultTexts.totalUsedVolumeGroupsLegendText
            ]
        }
    ]

    const theme = useTheme();

    const cinderServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const cinderServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const limitsUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.limitsUrl)[0].url)

    const onLimitsEdit = (event,field_key) => {
        let updated_data = {...editedData}
        updated_data[field_key] = parseInt(event.target.value)
        setEditedData(updated_data)
    }

    const handleLimitsEdit = async () => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${limitsUrl}/${data.id}`
            const method = "PUT"
            let updated_data = {}
            updated_data["volumes"] = editedData.maxTotalVolumes
            updated_data["snapshots"] = editedData.maxTotalSnapshots
            updated_data["backups"] = editedData.maxTotalBackups
            updated_data["groups"] = editedData.maxTotalGroups
            updated_data["gigabytes"] = editedData.maxTotalVolumeGigabytes
            updated_data["backup_gigabytes"] = editedData.maxTotalBackupGigabytes
            const data_to_send = {"quota_set": updated_data}
            const limits_response = await volumeCinderRequest({
                url:url, 
                method:method, 
                data: data_to_send, 
                token: project_token
            })
            if (limits_response.status_code === volumesUrlResponses.put.success_response.status_code) {
                setData(editedData,data.id)
                setEditMode(false)
            }
        }
    }

    return (
        <CustomDismissCard onClose={onCardClose} cardID={data.id}>
            <Stack sx={{m: 2}} direction="row" justifyContent="space-between" alignItems="center">
                <CustomText size="h6" sx={{color: "dashboardPurple"}}>{projectName}</CustomText>
                <div>
                {!editMode && <Button 
                    color="primary" 
                    variant="outlined"
                    onClick={() => setEditMode(true)}
                >
                    {defaultTexts.editButtonText}
                </Button>}
                {editMode && 
                    <IconButton sx={{mr: 1}} onClick={() => setEditMode(false)}>
                        <RxReset 
                            color={theme.palette.text.primary}
                            fontSize="large"
                            
                        />
                    </IconButton>
                }
                {editMode && <Button 
                    color="primary" 
                    variant="outlined"
                    onClick={handleLimitsEdit}
                >
                    {defaultTexts.saveButtonText}
                </Button>}
                </div>
            </Stack>
            <Box sx={{display: "flex", m: 1, mr: 2}}>
                <Grid container spacing={2}>
                    {pieChartTexts.map((item) => {
                        return (
                            <Grid item xs={12} md={6} lg={4} key={item.field_key}>
                                <CustomCard 
                                    cardWidth={'100%'} 
                                    cardHeight={220} 
                                    card_sx={{
                                        border: theme.palette.mode === "dark" ? 0 : 1, 
                                        boxShadow: 0, 
                                        backgroundColor: 
                                            theme.palette.mode === "dark" ? 
                                            undefined :
                                            "vLightGray"
                                    }}
                                    box_sx={{px: 1}}
                                >
                                <Stack 
                                    direction="row" 
                                    alignItems="center" 
                                    justifyContent="center"
                                    spacing={2}
                                >
                                    <item.icon 
                                        size={
                                            width < Dimensions.tablet.width ? 
                                            22 : 
                                            28
                                        } 
                                    />
                                    <CustomText 
                                        size="p" 
                                        sx={{fontSize: {xs: 14, lg: 16}}}
                                    >
                                        {item.title}
                                    </CustomText>
                                </Stack>
                                {!editMode && <CustomText 
                                    size="h6" 
                                    sx={{
                                        mt: 2, 
                                        textAlign: "center", 
                                        color: "primary.main",
                                    }}>
                                        Max {data[item.field_key]}
                                </CustomText>}
                                {editMode && 
                                    <CustomTextField 
                                        currentValue={editedData[item.field_key]}
                                        setCurrentValue={onLimitsEdit}
                                        field_key={item.field_key}
                                        label={item.title}
                                        type="number"
                                        size="large"
                                        min={1}
                                        sx={{ 
                                            m: 3, 
                                            mt: 5, 
                                            width: "85%"
                                        }}
                                    />
                                }
                                {!editMode && <Stack
                                    direction="row" 
                                    alignItems="center" 
                                    justifyContent="center"
                                    spacing={0}
                                >
                                    <PieChart
                                        series={[
                                            {
                                                arcLabel: (i) => `${i.value}`,
                                                arcLabelMinAngle: 1,
                                                data: [
                                                    { 
                                                        id: 0, 
                                                        value:  data[item.field_key] - data[item.used_key], 
                                                        color: theme.palette.dashboardPurple 
                                                    },
                                                    { 
                                                        id: 1, 
                                                        value: data[item.used_key], 
                                                        color:  theme.palette.dashboardPink
                                                    }
                                                ],
                                            },
                                        ]}
                                        sx={{
                                            [`& .${pieArcLabelClasses.root}`]: {
                                            fill: 'white',
                                            fontWeight: 'bold',
                                            },
                                        }}
                                        width={220}
                                        height={100}
                                    />
                                <Stack
                                    direction="column" 
                                    alignItems="start" 
                                    justifyContent="center"
                                    spacing={1}
                                >       
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                    spacing={1}
                                >
                                    <Box 
                                        sx={{
                                            backgroundColor: "dashboardPurple",
                                            width: 15,
                                            height: 15
                                        }}
                                    />
                                    <CustomText 
                                        size="p" 
                                        sx={{fontSize: {xs: 12, lg: 14}}}
                                    >
                                        {item.legend[0]}
                                    </CustomText>
                                </Stack>
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent="center"
                                    spacing={1}
                                >
                                    <Box 
                                        sx={{
                                            backgroundColor: "dashboardPink",
                                            width: 15,
                                            height: 15
                                        }}
                                    />
                                    <CustomText 
                                        size="p"
                                        sx={{fontSize: {xs: 12, lg: 14}}}
                                    >
                                    {item.legend[1]}
                                    </CustomText>
                                </Stack>
                            </Stack>
                            </Stack>}
                            </CustomCard>
                        </Grid>
                        )
                    })}
                </Grid>
            </Box>
        </CustomDismissCard>
    )
};

export default LimitsDetailV3;