import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Constants from '../../../../../config/constants';
import AddButton from '../../../../_common/AddButton';
import FilterButton from '../../../../_common/FilterButton';
import WrapperBox from '../../../../_common/WrapperBox';
import { FormGroup } from '@mui/material';
import { Stack } from '@mui/material';
import { Divider } from '@mui/material';
import { userDataForm, userOptionsForm } from 
'../../../../../_data/openstack/identity/users/v3';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { identityKeystonConstants } from 
'../../../../../config/openStackConstants';
import { openstackRequest } from 
'../../../../../_network/openstack_request';
import { usersUrl as usersUrlResponses } from 
'../../../../../_api_responses/openstack/identity/users/v3';
import CustomDialog from '../../../../_common/CustomDialog';
import CustomText from '../../../../_common/CustomText';
import PlusButton from '../../../../_common/PlusButton';
import MinusButton from '../../../../_common/MinusButton';
import CustomTextField from '../../../../_common/_form_fields/CustomTextField';
import { handleEmptyFields } from '../../../helpers/v3/identity_helpers'
import CustomCheckboxField from 
'../../../../_common/_form_fields/CustomCheckboxField';

const SERVICE_NAME = openStackServices.identityService

const IdentityKeystoneUsersSubheaderV3 = (props) => {
    const [error, setError] = useState()
    const [successUserAdd, setSuccessUserAdd] = useState()
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const { domains, projects } = props
    const { selectedFilter, setSelectedFilter } = props
    const { selectedFilterValue, setSelectedFilterValue } = props
    const { handleFetchData } = props
    const { filterMenu } = props
    const { handleFilteredSearch, handleFilterReset } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const [formData, setFormData] = useState({})
    const [formFieldOptions, setFormFieldOptions] = useState({})
    const [formDataOptions, setFormDataOptions] = useState({});
    const [formExtraFields, setFormExtraFields] = useState([]);
    const [addCreateDate, setAddCreateDate] = useState(true)

    const identityServiceDomain = useSelector(state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const usersUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.usersUrl)[0].url)


    const handleAddCreateDateValueChange = (event) => {
        setAddCreateDate(event.target.checked)
    }

    const handleFormDataValidation = () => {
        let validation_faild = true
        let updatedDataFormOptions = {...formDataOptions}
        for (let n in userDataForm) {
            if (userDataForm[n].required && !formData[userDataForm[n].field_key]) {
                validation_faild = false
                updatedDataFormOptions[userDataForm[n].field_key] = {}
                updatedDataFormOptions[userDataForm[n].field_key]["error"] = true
                updatedDataFormOptions[userDataForm[n].field_key]["errorText"] = defaultTexts[userDataForm[n].error_label]
            }
        }

        if (formData.email && formData.email.length > 0) {
            const validEmail = /^[^@\s]+@[^@\s]+\.[^@\s]+$/.test(formData.email.trim())
            if (!validEmail) {
                validation_faild = false
                updatedDataFormOptions["email"] = {}
                updatedDataFormOptions["email"]["error"] = true
                updatedDataFormOptions["email"]["errorText"] = defaultTexts[userDataForm.filter(
                    f => f.field_key === "email")[0].field_type_mismatch]
            }
        }

        if (formData.password !== formData.confirm_password) {
            validation_faild = false
            updatedDataFormOptions["password"] = {}
            updatedDataFormOptions["confirm_password"] = {}
            updatedDataFormOptions["password"]["error"] = true
            updatedDataFormOptions["password"]["errorText"] = defaultTexts[userDataForm.filter(
                f => f.field_key === "password")[0].error_mismatch]
            updatedDataFormOptions["confirm_password"]["error"] = true
            updatedDataFormOptions["confirm_password"]["errorText"] = defaultTexts[userDataForm.filter(
                f => f.field_key === "password")[0].error_mismatch]
        } else {
            if (validation_faild) {
                delete formData.confirm_password
            }
        }

        setFormDataOptions(updatedDataFormOptions)
        return validation_faild
    }

    const addOptionsToUserData = (data) => {
        let updated_data = {...data}
        updated_data["options"] = {}
        for (let n in formFieldOptions) {
            if (formFieldOptions[n]) {
                updated_data["options"][n] = formFieldOptions[n]
            }
        }
        return updated_data
    }

    const onAddUser = async () => {
        const validateFormData = handleFormDataValidation()
        if (validateFormData) {
            let request_data = handleEmptyFields(userDataForm, formData)
            request_data = addOptionsToUserData(request_data)
            request_data = handleFormExtraFieldsAddToUser(request_data)
            const url = `${identityServiceDomain}/${identityServiceVersion}/${usersUrl}`
            const method = "POST"
            const user_response = await openstackRequest({
                url:url, 
                method:method, 
                data: {user: request_data}
            })
            if (user_response.status_code === usersUrlResponses.post.success_response.status_code) {
                handleAddUserFormReset()
                setFormDataOptions({})
                handleFetchData()
                setSuccessUserAdd({
                    success_title: usersUrlResponses.post.success_response.response_title, 
                    success_message: usersUrlResponses.post.success_response.response_message
                })
            } else {
                const error_response = usersUrlResponses.post.error_response.filter(
                    error_item => error_item.status_code === user_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: user_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = usersUrlResponses.post.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: user_response.error
                    }
                    setError(errorObject)
                }
            }
        }

        return validateFormData
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleSuccessDialogClose = () => {
        setSuccessUserAdd(null);
        setSuccessDialogOpen(false);
    }

    const handleAddUserFormReset = () => {
        setFormDataOptions({})
        let new_form_data = {}
        for (const n in userDataForm) {
            if (userDataForm[n].field_type === "string" || 
                userDataForm[n].field_type === "select" ||
                userDataForm[n].field_type === "email" ||
                userDataForm[n].field_type === "list") {
                new_form_data[userDataForm[n].field_key] = ""
            } else if (userDataForm[n].field_type === "bool") {
                new_form_data[userDataForm[n].field_key] = userDataForm[n].default_value ? 
                userDataForm[n].default_value : 
                false
            }
        }
        setFormData(new_form_data)
        setFormFieldOptions({})
        setFormExtraFields([])
    }

    const handleFormDataChange = (event,field_key) => {
        setFormDataOptions({})
        let new_form_data = {...formData}
        if (userDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (userDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setFormData(new_form_data)
    }

    const handleFormFieldOptionsChange = (event,field_key) => {
        let new_form_data = {...formFieldOptions}
        if (userOptionsForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (userOptionsForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setFormFieldOptions(new_form_data)
    }

    const handleFormExtraFieldsChange = (event,field_key_list) => {
        let new_extra_data = [...formExtraFields]
        if (field_key_list[1] === "key") {
            new_extra_data[field_key_list[0]].field_key = event.target.value
        } else {
            new_extra_data[field_key_list[0]].field_value = event.target.value
        }
        setFormExtraFields(new_extra_data)
    }

    const handleFormExtraFieldsRemove = (index) => {
        if (formExtraFields.length > 1) {
            let updated_data = [...formExtraFields]
            updated_data.splice(index, 1)
            setFormExtraFields(updated_data)
        } else {
            setFormExtraFields([])
        }
    }

    const handleFormExtraFieldsAddToUser = (data) => {
        let updated_data = {...data}
        for (let i in formExtraFields) {
            if (formExtraFields[i].field_key.length > 0) {
                updated_data[formExtraFields[i].field_key] = formExtraFields[i].field_value
            }
        }
        if (addCreateDate) {
            updated_data["created_at"] = new Date().toISOString()
        }
        return updated_data
    }

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessDialogOpen(true)
    },[successUserAdd]);

    useEffect(() => {
        if (Object.keys(formData).length === 0) {
            let new_form_data = {}
            for (const n in userDataForm) {
                if (
                    userDataForm[n].field_type === "string" || 
                    userDataForm[n].field_type === "select" ||
                    userDataForm[n].field_type === "list"
                    ) {
                    new_form_data[userDataForm[n].field_key] = ""
                } else if (userDataForm[n].field_type === "bool") {
                    new_form_data[userDataForm[n].field_key] = userDataForm[n].default_value ? 
                    userDataForm[n].default_value : 
                    false
                }
            }
            setFormData(new_form_data)
        }
    },[formData]);


    useEffect(() => {
        if (Object.keys(formFieldOptions).length === 0) {
            let new_form_data = {}
            for (const n in userOptionsForm) {
                if (
                    userOptionsForm[n].field_type === "string" || 
                    userOptionsForm[n].field_type === "select" ||
                    userOptionsForm[n].field_type === "list"
                    ) {
                    new_form_data[userOptionsForm[n].field_key] = ""
                } else if (userOptionsForm[n].field_type === "bool") {
                    new_form_data[userOptionsForm[n].field_key] = userOptionsForm[n].default_value ? 
                    userOptionsForm[n].default_value : 
                    false
                }
            }
            setFormFieldOptions(new_form_data)
        }
    },[formFieldOptions]);

    const getDataForm = () => {
        return (
            <FormGroup>
                {userDataForm.map(field => {
                    let form_field_options = {...formDataOptions[field.field_key]}
                    if (field.field_key === "domain_id") {
                        const domains_filter = domains.map(domain => {
                            return {keyword: domain.name, value: domain.id, default: false}
                        })
                        form_field_options["items"] = [...domains_filter]
                        form_field_options["empty"] = field.default_empty
                        form_field_options["self_item_titles"] = field.self_item_titles
                    } else if (field.field_key === "default_project_id") {
                        let project_filter = []
                        if (formData.domain_id) {
                            let projects_allowed = projects.filter(p => p.domain_id === formData.domain_id)
                            project_filter = projects_allowed.map(project => {
                                return {keyword: project.name, value: project.id, default: false}
                            })
                        }
                        form_field_options["items"] = [...project_filter]
                        form_field_options["empty"] = field.default_empty
                        form_field_options["self_item_titles"] = field.self_item_titles
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            formData,
                            handleFormDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const getOptionsForm = () => {
        return (
            <FormGroup>
                {userOptionsForm.map(field => {
                    let form_field_options = {}
                    form_field_options["required"] = field.required
                    return (
                        getFormFieldComponent(
                            field,
                            formFieldOptions,
                            handleFormFieldOptionsChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    return (
        <WrapperBox 
            sx={{
                flexDirection: "row", 
                justifyContent: 'space-between',
                mt: 1
            }}>
            <AddButton 
                getDataForm={getDataForm}               
                onSubmit={onAddUser}
                formReset={handleAddUserFormReset}
                customTexts={{
                    title: defaultTexts.addUserDialogTitle
                }}
            >
                <Stack 
                    direction="row" 
                    spacing={2} 
                    alignItems="center"
                    sx={{my: 3}}
                >
                    <CustomText size="h6">
                        {defaultTexts.extraFormFieldLabel}
                    </CustomText>
                    <PlusButton onClick={() => setFormExtraFields([
                        ...formExtraFields,
                        {field_key: "",field_value: ""}
                    ])} />
                </Stack>
                {formExtraFields.map((item,index) => {
                    return (
                        <Stack
                            key={index}
                            direction="row" 
                            spacing={2} 
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{my: 1}}
                        >
                            <CustomTextField
                                currentValue={item.field_key} 
                                setCurrentValue={handleFormExtraFieldsChange}
                                field_key={[index,"key"]}
                                label={defaultTexts.keyFormFieldLabel}
                            />
                            <CustomTextField
                                currentValue={item.field_value} 
                                setCurrentValue={handleFormExtraFieldsChange}
                                field_key={[index,"value"]}
                                label={defaultTexts.valueFormFieldLabel}
                            />
                            <MinusButton sx={{width: 90}} onClick={
                                () => handleFormExtraFieldsRemove(index)}
                            />
                        </Stack>
                        
                    )
                })}
                <Divider sx={{my: 2}}/>
                <CustomText size="h6" sx={{mt: 4}}>
                    {defaultTexts.optionsFormFieldLabel}
                </CustomText>
                {getOptionsForm()}
                <Divider sx={{my: 2}}/>
                <CustomCheckboxField
                    currentValue={addCreateDate}
                    setCurrentValue={handleAddCreateDateValueChange}
                    label={defaultTexts.createTimeFormFieldLabel}
                />
            </AddButton>
            <FilterButton 
                choice_mode={Constants.simple_filter_type}
                currentFilter={selectedFilter}
                setCurrentFilter={setSelectedFilter}
                filter_menu_titles={defaultTexts}
                filter_menu={filterMenu}
                currentFilterValue={selectedFilterValue}
                setCurrentFilterValue={setSelectedFilterValue}
                onFilterSubmit={handleFilteredSearch}
                onFilterReset={handleFilterReset}
            />
            {successUserAdd && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[successUserAdd.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successUserAdd.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>Details:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </WrapperBox>
    )
};

export default IdentityKeystoneUsersSubheaderV3;