import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import WrapperBox from '../../../../../_common/WrapperBox';
import Paper from '@mui/material/Paper';
import NoDataNote from '../../../../../_common/NoDataNote';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import PortSpecsV20 from './portSpecsV2.0';
import PortSecGroupsV20 from './portSecGroupsV2.0';
//import PortAllowedAddressesV20 from './portAllowedAddressesV2.0';
import useWindowDimensions from 
'../../../../../_common/WindowDimensions';
import { getFormFieldComponent } from 
'../../../../../_common/_form_fields/form_helpers';
import Constants from '../../../../../../config/constants';
import { Grid }  from '@mui/material';
import { 
    openstackRequest,
    getXAuthTokenProjectScope} from 
'../../../../../../_network/openstack_request';
import { portsUrl as portUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/ports/v2.0';
import { openStackServices } from 
'../../../../../../config/openStackConstants';
import { 
    networkNeutronConstants
} from '../../../../../../config/openStackConstants';
import ServiceContentHeader from 
'../../../../../_common/ServiceContentHeader';
import CustomSelectField from 
'../../../../../_common/_form_fields/CustomSelectField';
import CustomDialog from 
'../../../../../_common/CustomDialog';
import { 
    portDataUpdateForm,
    portIPAddressForm
} from '../../../../../../_data/openstack/neutron/ports/v2.0';
import CustomText from '../../../../../_common/CustomText';
import Stack from '@mui/material/Stack';
import CustomTextField from '../../../../../_common/_form_fields/CustomTextField';
import PlusButton from '../../../../../_common/PlusButton';
import MinusButton from '../../../../../_common/MinusButton';
import PortAllowedAddressesV20 from './portAllowedAddressesV2.0';

const SERVICE_NAME = openStackServices.networkService
const FOOTER_HEIGHT = Constants.actions_bar_height + 20

const PortDetailV20 = (props) => {
    const [isCardLoading, setIsCardLoading] = useState(true)
    const [error, setError] = useState();
    const { selectedRow, handleDataFetch } = props
    const { widthWeight } = props
    const { handleDelete } = props
    const { 
        projects, 
        networks, 
        secGroups, 
        subnets,
        servers
    } = props
    const { handleNavigateToSecGroup } = props
    const { handleNavigateToNetwork } = props
    const { handleNavigateToServer } = props
    const { handleNavigateToRouter } = props
    const { handleNavigateToSubnet } = props
    const { width } = useWindowDimensions();
    const FOOTER_WIDTH =  width - (width * widthWeight)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);

    const [portActions, setPortActions] = useState([]);
    const [currentAction, setCurrentAction] = useState("");

    const [portUpdateData, setPortUpdateData] = useState({})
    const [updatePortDialogOpen, setUpdatePortDialogOpen] = useState(false)
    const [updateAllowedAddressesDialogOpen, setUpdateAllowedAddressesDialogOpen] = useState(false)
    const [formAllowedAddresses, setFormAllowedAddresses] = useState([...selectedRow.allowed_address_pairs])
    const [updateIPAddressDialogOpen, setUpdateIPAddressDialogOpen] = useState(false)
    const [ipAddressData, setIpAddressData] = useState([...selectedRow.fixed_ips])

    const [portSubMenu, setPortSubMenu] = useState([
        {keyword: "submenuDetails", navigation: "/port-details", is_active: true},
        {keyword: "submenuSecGroups", navigation: "/port-sec-groups", is_active: false},
        {keyword: "submenuAllowedAddresses", navigation: "/port-allowed-addresses", is_active: false}
    ])

    const [currentTab, setCurrentTab] = useState("/port-details")
    
    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const portsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.portsUrl)[0].url)

    const common_url = `${neutronServiceDomain}/${neutronServiceVersion}/${portsUrl}/${selectedRow.id}`

    const getFormattedPortData = useCallback((data) => {
        let formatted_data = {...data}
        const project = projects.filter(p => p.id === data.project_id)[0]
        formatted_data.project_id = project ? 
        project.name : 
        data.project_id
        const network = networks.filter(n => n.id === data.network_id)[0]
        formatted_data.network_id = network ? 
        network.name : 
        data.network_id
        if (data.device_owner === networkNeutronConstants.computeNovaOwner) {
            const server = servers.filter(srv => srv.id === data.device_id)
            if (server.length > 0) {
                formatted_data.device_id = server[0].name
            }
            
        }
        let ip_addresses = []
        for (let ip in data.fixed_ips) {
            let new_ip = {...data.fixed_ips[ip]}
            const subnet = subnets.filter(sub => sub.id === data.fixed_ips[ip].subnet_id)
            if (subnet.length > 0) {
                new_ip["subnet_name"] = subnet[0].name
            }
            ip_addresses.push(new_ip)
        }
        formatted_data.fixed_ips = ip_addresses

        return formatted_data
    },[projects, networks, servers, subnets])


    const handlePortDetailTabChange = useCallback((navigation) => {
        let newPortSubmenuData = portSubMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
            } else {
                item.is_active = false
            }
            return item
        })
        setPortSubMenu(newPortSubmenuData)
        setCurrentTab(navigation)
    },[
        portSubMenu
    ])

    const handleConfirmDeleteDialogOpen = () => {
        handleDelete([selectedRow.id])
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const getDataForm = (form,form_options,data,onDataChange) => {
        let form_data = [...form]
        if (!data.port_security_enabled) {
            form_data = form.filter(f => f.field_key !== "security_groups")
        }
        return (
            <FormGroup>
                {form_data.map(field => {
                    let form_field_options = {}
                    if (field.field_key === "security_groups") {
                        const project = networks.filter(n => n.id === selectedRow.network_id)
                        let sec_groups_list = []
                        if (project.length > 0) {
                            sec_groups_list = secGroups.filter(s => s.project_id === project[0].project_id)
                        }
                        const secgroups_filter = sec_groups_list.map(s => {
                            return {keyword: s.name, value: s.id, default: false}
                        })
                        form_field_options["items"] = [...secgroups_filter]
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = true
                        form_field_options["multiple"] = true
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            data,
                            onDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleIPAddressDataChange = (event,field_key,index) => {
        let new_form_data = [...ipAddressData]
        if (portIPAddressForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[index][field_key] = event.target.checked
        } else if (portIPAddressForm.filter(
            item => item.field_key === field_key)[0].field_type === "select")  {
            new_form_data[index][field_key] = event
        } else {
            new_form_data[index][field_key] = event.target.value
        }
        setIpAddressData(new_form_data)
    }

    const handleIPAddressFormAdd = () => {
        let new_form_data = {}
        for (const n in portIPAddressForm) {
            if (
                portIPAddressForm[n].field_type === "string" || 
                portIPAddressForm[n].field_type === "select"
                ) {
                new_form_data[portIPAddressForm[n].field_key] = ""
            } else if (portIPAddressForm[n].field_type === "bool") {
                new_form_data[portIPAddressForm[n].field_key] = portIPAddressForm[n].default_value ? 
                portIPAddressForm[n].default_value : 
                false
            }
        }
        const updated_data = [...ipAddressData]
        updated_data.push(new_form_data)
        setIpAddressData(updated_data)
    }

    const handleIPAddressFormRemove = (index) => {
        let new_data = [...ipAddressData]
        new_data.splice(index,1)
        setIpAddressData(new_data)
    }

    const getIPAddressDataForm = (form,data,index) => {
        return (
            <FormGroup>
                {form.map(field => {
                    let form_field_options = {}
                    if (field.field_key === "subnet_id") {
                        const subnets_list = subnets.filter(s => s.network_id === selectedRow.network_id)
                        const subnets_filter = subnets_list.map(s => {
                            return {keyword: `${s.name} ${s.cidr}`, value: s.id, default: false}
                        })
                        form_field_options["items"] = [...subnets_filter]
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = false
                    }
                    form_field_options["index"] = index
                    return (
                        getFormFieldComponent(
                            field,
                            data,
                            handleIPAddressDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
                {ipAddressData.length > 1 && <MinusButton 
                    style={{ transform: 'scale(0.7)' }}
                    sx={{height: 5}}
                    onClick={() => handleIPAddressFormRemove(index)}
                />}
            </FormGroup>
        )
    }

    const handlePortUpdateDataChange = (event,field_key) => {
        let new_form_data = {...portUpdateData}
        if (portDataUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (portDataUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
                new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setPortUpdateData(new_form_data)
    }

    const handleUpdatePortDialogOpen = () => {
        setUpdatePortDialogOpen(true)
    }

    const handleUpdatePortDialogClose = () => {
        setUpdatePortDialogOpen(false)
    }

    const  handleUpdateAllowedAddresseOpen = () => {
        setUpdateAllowedAddressesDialogOpen(true)
    }

    const  handleUpdateAllowedAddressesClose = () => {
        setUpdateAllowedAddressesDialogOpen(false)
    }

    const handleUpdateIPAddressDialogOpen = () => {
        setUpdateIPAddressDialogOpen(true)
    }

    const handleUpdateIPAddressDialogClose = () => {
        setIpAddressData([...selectedRow.fixed_ips])
        setUpdateIPAddressDialogOpen(false)
    }

    const onUpdateAllowedAddresses = async () => {
        const data = {}
        data["port"] = {}
        data["port"]["allowed_address_pairs"] = [...formAllowedAddresses]
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const method = "PUT"
            const port_response = await openstackRequest({
                url: `${common_url}/add_allowed_address_pairs`, 
                method: method, 
                data: data,
                token: project_token
            })
            if (port_response.status_code === portUrlResponses.put.success_response.status_code) {
                setCurrentAction("")
                handleUpdatePortDialogClose()
                handleDataFetch()
            } else {
                const error_response = portUrlResponses.put.error_response.filter(
                    error_item => error_item.status_code === port_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: port_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = portUrlResponses.put.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: port_response.error
                    }
                    setError(errorObject)
                }
            }
        }
    }

    const onUpdateIPAddresses = async () => {
        const data = {}
        data["port"] = {}
        data["port"]["fixed_ips"] = [...ipAddressData]
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const method = "PUT"
            const port_response = await openstackRequest({
                url: common_url, 
                method: method, 
                data: data,
                token: project_token
            })
            if (port_response.status_code === portUrlResponses.put.success_response.status_code) {
                setCurrentAction("")
                handleUpdateIPAddressDialogClose()
                handleDataFetch()
            } else {
                const error_response = portUrlResponses.put.error_response.filter(
                    error_item => error_item.status_code === port_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: port_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = portUrlResponses.put.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: port_response.error
                    }
                    setError(errorObject)
                }
            }
        }
    }

    const handleAllowedAddressesChange = (event,field_key_list) => {
        let new_extra_data = [...formAllowedAddresses]
        if (field_key_list[1] === "ip_address") {
            new_extra_data[field_key_list[0]].ip_address = event.target.value
        } else {
            new_extra_data[field_key_list[0]].mac_address = event.target.value
        }
        setFormAllowedAddresses(new_extra_data)
    }

    const handleAllowedAddressesRemove = (index) => {
        if (formAllowedAddresses.length > 1) {
            let updated_data = [...formAllowedAddresses]
            updated_data.splice(index, 1)
            setFormAllowedAddresses(updated_data)
        } else {
            setFormAllowedAddresses([])
        }
    }

    const onPortUpdate = async () => {
        let updated_data = {...portUpdateData}
        if (!portUpdateData.port_security_enabled) {
            updated_data.security_groups = []
        }
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const method = "PUT"
            const port_response = await openstackRequest({
                url: common_url, 
                method: method, 
                data: {port: updated_data},
                token: project_token
            })
            if (port_response.status_code === portUrlResponses.put.success_response.status_code) {
                setCurrentAction("")
                handleUpdatePortDialogClose()
                handleDataFetch()
            } else {
                const error_response = portUrlResponses.put.error_response.filter(
                    error_item => error_item.status_code === port_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: port_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = portUrlResponses.put.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: port_response.error
                    }
                    setError(errorObject)
                }
            }
        }
    }

    useEffect(() => {
        let port_actions = []
        let new_action = {}
        new_action["value"] = "update_port"
        new_action["action"] = handleUpdatePortDialogOpen
        new_action["keyword"] = "portUpdateActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        port_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "update_allowed_addresses"
        new_action["action"] = handleUpdateAllowedAddresseOpen
        new_action["keyword"] = "updateAllowedAddressesActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        port_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "update_ip_address"
        new_action["action"] = handleUpdateIPAddressDialogOpen
        new_action["keyword"] = "updateIPAddressActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        port_actions.push({...new_action})
        setPortActions(port_actions)
    },[
        selectedRow
    ])

    useEffect(() => {
        if (Object.keys(portUpdateData).length === 0) {
            let new_form_data = {}
            for (const n in portDataUpdateForm) {
                if (portDataUpdateForm[n].field_key === "security_groups") {
                    new_form_data[portDataUpdateForm[n].field_key] = [...selectedRow[portDataUpdateForm[n].field_key]]
                } else {
                    new_form_data[portDataUpdateForm[n].field_key] = selectedRow[portDataUpdateForm[n].field_key]
                }
            }
            setPortUpdateData(new_form_data)
        }
    },[portUpdateData, selectedRow]);


    useEffect(() => {
        if (Object.keys(formAllowedAddresses).length === 0) {
            let new_form_data = {}
            new_form_data["ip_address"] = ""
            new_form_data["mac_address"] = ""
            setFormAllowedAddresses([new_form_data])
        }
    },[formAllowedAddresses]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setTimeout(() => setIsCardLoading(false), 600)
    },[])

    return (
        <React.Fragment>
        {selectedRow !== null && 
        <WrapperBox>
            <ServiceContentHeader 
                service_menu={portSubMenu}
                service_menu_titles={defaultTexts}
                onClick={handlePortDetailTabChange}
            />
        </WrapperBox>}
        {currentTab === "/port-details" &&
            <PortSpecsV20
                portData={getFormattedPortData(selectedRow)}
                selectedRow={selectedRow}
                handleNavigateToNetwork={handleNavigateToNetwork}
                handleNavigateToServer={handleNavigateToServer}
                handleNavigateToRouter={handleNavigateToRouter}
                handleNavigateToSubnet={handleNavigateToSubnet}
            />
        }
        {currentTab === "/port-sec-groups" && 
            <div>
                {selectedRow.security_groups.length > 0 ?
                    <PortSecGroupsV20
                        secGroupsList={secGroups}
                        secGroups={secGroups.filter(sg => selectedRow.security_groups.includes(sg.id))}
                        handleNavigateToSecGroup={handleNavigateToSecGroup}
                    />
                :
                    <NoDataNote text={defaultTexts.noSecGroupsNoteText} />}
            </div>
        }
        {currentTab === "/port-allowed-addresses" && 
            <PortAllowedAddressesV20
                allowedAddresses={[...selectedRow.allowed_address_pairs]} 
            />  
        }
        {!isCardLoading && <Paper sx={{ 
            position: 'fixed', 
            bottom: 0, 
            height: FOOTER_HEIGHT,
            left: FOOTER_WIDTH, 
            right: 12,
            zIndex: 3000
            }} 
            elevation={24}
            square={true}
        >
            <Grid 
                container 
                alignItems="center"  
                justifyContent="space-between"
            >
                <Grid item>
                    <CustomSelectField 
                        items={portActions} 
                        currentValue={currentAction}
                        setCurrentValue={setCurrentAction}
                        item_titles={defaultTexts}
                        label={defaultTexts.actionsDropdownLabelText}
                        empty={true}
                        size="small"
                        sx={{m: 1}}
                    />
                    {currentAction.length > 0 && 
                        <Button 
                                variant="contained"
                                color="secondary"
                                sx={{m: 1, height: '70%'}}
                                onClick={portActions.filter(
                                    action => action.value === currentAction)[0].action
                                }
                            >
                            {defaultTexts[portActions.filter(
                                action => action.value === currentAction)[0].button_text]}
                        </Button>
                    }
                </Grid>
                <Grid item>
                    {selectedRow !== null && 
                        <IconButton onClick={handleConfirmDeleteDialogOpen}>
                            <DeleteIcon 
                                color="primary"
                            />
                        </IconButton>}
                </Grid>
            </Grid>
        </Paper>} 
        <CustomDialog
            open={updatePortDialogOpen}
            onClose={handleUpdatePortDialogClose}
            dialogTitle={{
                title: defaultTexts.updatePortActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onPortUpdate, 
                sx: {color: 'primary.main'}}]}
        >
            {getDataForm(
                portDataUpdateForm,
                {},
                portUpdateData,
                handlePortUpdateDataChange
            )}
        </CustomDialog>
        <CustomDialog
            open={updateAllowedAddressesDialogOpen}
            onClose={handleUpdateAllowedAddressesClose}
            dialogTitle={{
                title: defaultTexts.updateAllowedAddressesActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onUpdateAllowedAddresses,
                sx: {color: 'primary.main'}}]}
        >
                <Stack 
                    direction="row" 
                    spacing={2} 
                    alignItems="center"
                    sx={{my: 3}}
                >
                    <CustomText size="h6">
                        {defaultTexts.allowedAddressesFormFieldLabel}
                    </CustomText>
                    <PlusButton 
                        onClick={() => setFormAllowedAddresses([
                            ...formAllowedAddresses,
                            {ip_address: "", mac_address: ""}
                        ])} 
                    />
                </Stack>
                {formAllowedAddresses.map((item,index) => {
                    return (
                        <Stack
                            key={index}
                            direction="row" 
                            spacing={2} 
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{my: 1}}
                        >
                            <CustomTextField
                                currentValue={item.field_key} 
                                setCurrentValue={handleAllowedAddressesChange}
                                field_key={[index,"ip_address"]}
                                label={defaultTexts.ipAddressFormFieldLabel}
                            />
                            <CustomTextField
                                currentValue={item.field_value} 
                                setCurrentValue={handleAllowedAddressesChange}
                                field_key={[index,"mac_address"]}
                                label={defaultTexts.macAddressFormFieldLabel}
                            />
                            <MinusButton 
                                sx={{width: 90}} 
                                onClick={() => handleAllowedAddressesRemove(index)}
                            />
                        </Stack>
                    )
                })}
        </CustomDialog>
        <CustomDialog
            open={updateIPAddressDialogOpen}
            onClose={handleUpdateIPAddressDialogClose}
            dialogTitle={{
                title: defaultTexts.updateIPAddressActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onUpdateIPAddresses,
                sx: {color: 'primary.main'}}]}
        >
            {ipAddressData.map((ip,index) => {
                return (
                    <div>
                        <CustomText>{defaultTexts.ipAddressFormFieldLabel} {index + 1}</CustomText>
                        {getIPAddressDataForm(portIPAddressForm, ip, index)}
                    </div>
                )
            })}
            {<PlusButton 
                style={{ transform: 'scale(0.7)' }}
                sx={{height: 5}}
                onClick={handleIPAddressFormAdd}
            />}
        </CustomDialog>
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts.failedActionErrorDialogTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts.failedActionErrorDialogMessage}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
        </React.Fragment>
    )
};

export default PortDetailV20;