import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import Constants from '../../../config/constants'
import billingRequest from '../../../_network/billing_request';
import { billingServiceURLs } from '../../../_network/apiUrls';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import MuiStack from '@mui/material/Stack';
import CustomText from '../../_common/CustomText';
import CustomCard from '../../_common/CustomCard';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { supportedPaymentMethods } from '../_common/payment_methods/helpers';

const paymentsSkeleton = ["one","two","three"]

const innerCardStyle = {
    border: 0.3,
    borderRadius: 2,
    borderStyle: "dashed",
    borderColor: "text.secondary",
    p: 3
}

const textStyle = {
    fontSize: {xs: 16, lg: 18}
}

const CustomStack = styled(MuiStack)(({ theme }) => ({
    flexDirection: "row" ,
    alignItems: "start",
    justifyContent: "space-between",
    marginTop: "9px"
}));


const GeneralConfigsContent = (props) => {
    const [isLoading, setIsLoading] = useState(true)
    const [billingConfigs, setBillingConfigs] = useState([]);
    const defaultTexts = useSelector(state => state.texts.langTexts)

    const theme = useTheme();

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    useEffect(() => {
        const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingConfigsEP}`
        let request_data = {
            url: url,
            method: "GET"
        }
        billingRequest(request_data).then((response) => {
            if (response.status_code === 200) {
                setBillingConfigs(response.data)
            }
            handleLoading(false)
        }).catch((err) => {
            handleLoading(false)
    })
        
    },[]);
    
    return (
        <Box>
            <Grid container spacing={2} sx={{mt: 0.1}}>
                <Grid item xs={12} md={6}>
                    <CustomCard
                        cardWidth={'100%'} 
                        cardHeight={235} 
                        card_sx={{
                            border: theme.palette.mode === "dark" ? 0 : 1, 
                            boxShadow: theme.palette.mode === "dark" ? 0 : 1, 
                            backgroundColor: 
                                theme.palette.mode === "dark" ? 
                                undefined :
                                "vLightGray"
                        }}
                        box_sx={{pr: 2}}
                    >
                        <Box sx={{...innerCardStyle}}>
                        <CustomStack>
                            <CustomText 
                                sx={{...textStyle, color: theme.palette.text.secondary}}
                            >
                                {defaultTexts.billingServiceSubscriptionLabel}
                            </CustomText>
                            {isLoading ? 
                                <Skeleton width="50%">
                                    <Typography>.</Typography>
                                </Skeleton> :
                                <CustomText 
                                    sx={{
                                        ...textStyle, 
                                        textTransform: "capitalize",
                                        color: theme.palette.success.light
                                    }}
                                >
                                    {billingConfigs.status}
                                </CustomText>
                            }
                            
                        </CustomStack>
                        <Divider />
                        <CustomStack>
                            <CustomText sx={{...textStyle, color: theme.palette.text.secondary}}>
                                {defaultTexts.billingDefaultDepositTypeLabel}
                            </CustomText>
                            {isLoading ? 
                                <Skeleton width="50%">
                                    <Typography>.</Typography>
                                </Skeleton> :
                            <CustomText sx={{...textStyle, textTransform: "capitalize"}}>
                                {billingConfigs.default_billing_type}
                            </CustomText>}
                        </CustomStack>
                        <Divider />
                        <CustomStack>
                            <CustomText sx={{...textStyle, color: theme.palette.text.secondary}}>
                                {defaultTexts.billingInvoiceTypeLabel}
                            </CustomText>
                            {isLoading ? 
                                <Skeleton width="50%">
                                    <Typography>.</Typography>
                                </Skeleton> :
                            <CustomText sx={{...textStyle, textTransform: "capitalize"}}>
                                {billingConfigs.postpaid_invoice}
                            </CustomText>}
                        </CustomStack>
                        <Divider />
                        <CustomStack>
                            <CustomText sx={{...textStyle, color: theme.palette.text.secondary}}>
                                {defaultTexts.billingDefaultInvoicePayPeriodLabel}
                            </CustomText>
                            {isLoading ? 
                                <Skeleton width="50%">
                                    <Typography>.</Typography>
                                </Skeleton> :
                            <CustomText sx={{...textStyle}}>
                                {
                                    billingConfigs.postpaid_invoice === "auto" ?
                                    billingConfigs.postpaid_invoice_pay_period :
                                    undefined
                                }
                            </CustomText>}
                        </CustomStack>
                        </Box>
                    </CustomCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    <CustomCard 
                        cardWidth={'100%'} 
                        cardHeight={235} 
                        card_sx={{
                            border: theme.palette.mode === "dark" ? 0 : 1, 
                            boxShadow: theme.palette.mode === "dark" ? 0 : 1, 
                            backgroundColor: 
                                theme.palette.mode === "dark" ? 
                                undefined :
                                "vLightGray"
                        }}
                        box_sx={{pr: 2}}
                    >
                    <Box sx={{...innerCardStyle}}>
                        <CustomStack>
                            <CustomText sx={{...textStyle, color: theme.palette.text.secondary}}>
                                {defaultTexts.currencyLabel}
                            </CustomText>
                            {isLoading ? 
                                <Skeleton width="50%">
                                    <Typography>.</Typography>
                                </Skeleton> :
                            <CustomText sx={{...textStyle}}>
                                {billingConfigs.currency ? billingConfigs.currency.name.toUpperCase() : ""}
                            </CustomText>}
                        </CustomStack>
                        <Divider />
                        <CustomStack>
                            <CustomText sx={{...textStyle, color: theme.palette.text.secondary}}>
                                {defaultTexts.billingUseVATTaxLabel}
                            </CustomText>
                            {isLoading ? 
                                <Skeleton width="50%">
                                    <Typography>.</Typography>
                                </Skeleton> :
                            <CustomText sx={{...textStyle}}>
                                {billingConfigs.use_vat_tax ? 
                                    defaultTexts.formValueYes : 
                                    defaultTexts.formValueNo
                                }
                            </CustomText>}
                        </CustomStack>
                        <Divider />
                        <CustomStack>
                            <CustomText sx={{...textStyle, color: theme.palette.text.secondary}}>
                                {defaultTexts.billingVATIncludedLabel}
                            </CustomText>
                            {isLoading ? 
                                <Skeleton width="50%">
                                    <Typography>.</Typography>
                                </Skeleton> :
                            <CustomText sx={{...textStyle}}>
                                {billingConfigs.price_includes_vat ?
                                    defaultTexts.formValueYes : 
                                    defaultTexts.formValueNo
                                }
                            </CustomText>}
                        </CustomStack>
                        <Divider />
                        <CustomStack>
                            <CustomText sx={{...textStyle, color: theme.palette.text.secondary}}>
                                {defaultTexts.billingVATPercentLabel}
                            </CustomText>
                            {isLoading ? 
                                <Skeleton width="50%">
                                    <Typography>.</Typography>
                                </Skeleton> :
                            <CustomText sx={{...textStyle}}>
                                {
                                    billingConfigs.vat
                                } %
                            </CustomText>}
                        </CustomStack>
                        </Box>
                    </CustomCard>
                </Grid>
                {!billingConfigs.payment_methods ?
                    <div>
                        {isLoading ? paymentsSkeleton.map((item) => <Grid item xs={12} md={6} lg={4} key={item}>  
                            <Skeleton 
                                variant="rounded" 
                                width="100%" 
                                height={240} 
                                sx={{
                                    bgcolor: theme.palette.mode === "dark" ? 
                                    theme.palette.background.paper :
                                    theme.palette.vLightGray
                                }}
                            />
                        </Grid>) : <div></div>} 
                    </div> :
                    billingConfigs.payment_methods.map((item, index) => {
                    const component = supportedPaymentMethods.filter(payment => 
                        payment.name === item.name
                    )[0]
                    const payment_prperties = {}
                    for (let c in component.props) {
                        payment_prperties[component.props[c]] = item[component.props[c]]
                    }
                    return (<Grid item xs={12} md={6} lg={4} key={`${item.name}_${index}`}>
                    <CustomCard 
                        cardWidth='100%'
                        cardHeight={240} 
                        card_sx={{
                            border: theme.palette.mode === "dark" ? 0 : 1, 
                            boxShadow: theme.palette.mode === "dark" ? 0 : 1, 
                            backgroundColor: 
                                theme.palette.mode === "dark" ? 
                                undefined :
                                "vLightGray"
                        }}
                        box_sx={{pr: 2}}
                    >
                    <Box sx={{...innerCardStyle}}>
                        <component.component {...payment_prperties} />
                    </Box>
                    </CustomCard>
                </Grid>)})}
            </Grid>
        </Box>
    )
}

export default GeneralConfigsContent;