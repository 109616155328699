import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import WrapperBox from '../../../../_common/WrapperBox';
import NoDataNote from '../../../../_common/NoDataNote';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import CustomText from '../../../../_common/CustomText';
import useWindowDimensions from 
'../../../../_common/WindowDimensions';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import { getDetailDataComponent } from 
'../../../../_common/common_helpers';
import Constants from '../../../../../config/constants';
import { Grid }  from '@mui/material';
import { 
    computeNovaRequest,
    openstackRequest,
    getXAuthTokenProjectScope} from 
'../../../../../_network/openstack_request';
import { flavorsUrl as flavorsUrlResponses } from 
'../../../../../_api_responses/openstack/compute/flavors/v2.1';
import { 
    openStackServices,
    computeNovaConstants
} from '../../../../../config/openStackConstants';
import ServiceContentHeader from 
'../../../../_common/ServiceContentHeader';
import CustomSelectField from 
'../../../../_common/_form_fields/CustomSelectField';
import CustomDialog from 
'../../../../_common/CustomDialog';
import { 
    flavorUpdateDataForm,
    flavorDetailSchema
} from '../../../../../_data/openstack/compute/flavors/v2.1';
import CustomTransferList from 
'../../../../_common/custom_transfer_list/CustomTransferList';

const SERVICE_NAME = openStackServices.computeService
const IMAGE_SERVICE_NAME = openStackServices.imageService
const FOOTER_HEIGHT = Constants.actions_bar_height + 20
const RESOURCE_NAME = "OS::Nova::Flavor"

const FlavorDetailV21 = (props) => {
    const [isCardLoading, setIsCardLoading] = useState(true)
    const [error, setError] = useState();
    const { selectedRow, handleDataFetch } = props
    const { widthWeight } = props
    const { handleDelete } = props
    const { handleFlavorAccessProjectSelect } = props
    const { handleFlavorAccessProjectSelectForRemove } = props
    const { width } = useWindowDimensions();
    const FOOTER_WIDTH =  width - (width * widthWeight)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [metadataNamespaces, setMetadataNamespaces] = useState([])
    const [metadataCatalog, setMetadataCatalog] = useState([])
    const [flavorMetadataDialogOpen, setFlavorMetadataDialogOpen] = useState(false);
    const [updatedMetadata, setUpdatedMetadata] = useState({})
    const [flavorExtraSpecs, setFlavorExtraSpecs] = useState({})

    const [flavorActions, setFlavorActions] = useState([]);
    const [currentAction, setCurrentAction] = useState("");

    const [flavorUpdateData, setFlavorUpdateData] = useState({})
    const [updateFlavorDialogOpen, setUpdateFlavorDialogOpen] = useState(false)
     
    const [flavorSubMenu, setFlavorSubMenu] = useState([
        {keyword: "submenuDetails", navigation: "/flavor-details", is_active: true},
        {keyword: "submenuExtraSpecs", navigation: "/flavor-extra-specs", is_active: false}
    ])

    const [currentTab, setCurrentTab] = useState("/flavor-details")
    
    const computeServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const computeServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const flavorsUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.flavorsUrl)[0].url)

    const imageServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IMAGE_SERVICE_NAME)[0].config_params.service_domain)
    const imageServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IMAGE_SERVICE_NAME)[0].config_params.api_version)

    const common_url = `${computeServiceDomain}/${computeServiceVersion}/${flavorsUrl}/${selectedRow.id}`

    const handleFlavorDetailTabChange = useCallback((navigation) => {
        let newFlavorSubmenuData = flavorSubMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
            } else {
                item.is_active = false
            }
            return item
        })
        setFlavorSubMenu(newFlavorSubmenuData)
        setCurrentTab(navigation)
    },[
        flavorSubMenu
    ])

    const handleFlavorMetadataDialogOpen = () => {
        setFlavorMetadataDialogOpen(true)
    }

    const handleFlavorMetadataSelectionDialogClose = () => {
        setUpdatedMetadata([])
        setFlavorMetadataDialogOpen(false)
    }

    const handleConfirmDeleteDialogOpen = () => {
        handleDelete([selectedRow.id])
    }

    const handleAddProjectAccessDialogOpen = useCallback(() => {
        handleFlavorAccessProjectSelect([selectedRow.id])
    },[handleFlavorAccessProjectSelect,selectedRow])

    const handleRemoveProjectAccessDialogOpen = useCallback(() => {
        handleFlavorAccessProjectSelectForRemove([selectedRow.id])
    },[handleFlavorAccessProjectSelectForRemove,selectedRow])

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const getDataForm = (form,form_options,data,onDataChange) => {
        let form_data = [...form]
        return (
            <FormGroup>
                {form_data.map(field => {
                    let form_field_options = {}
                    return (
                        getFormFieldComponent(
                            field,
                            data,
                            onDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleFlavorUpdateDataChange = (event,field_key) => {
        let new_form_data = {...flavorUpdateData}
        if (flavorUpdateDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (flavorUpdateDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
                new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setFlavorUpdateData(new_form_data)
    }

    const handleExtraSpecDelete = async (spec_key) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const method = "DELETE"
            const flavor_response = await computeNovaRequest({
                url: `${common_url}/os-extra_specs/${spec_key}`, 
                method: method, 
                token: project_token
            })
            if (flavor_response.status_code === flavorsUrlResponses.get.success_response.status_code) {
                handleDataFetch()
                setCurrentAction("")
            } else {
                const error_response = flavorsUrlResponses.get.error_response.filter(
                    error_item => error_item.status_code === flavor_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: flavor_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = flavorsUrlResponses.get.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: flavor_response.error
                    }
                    setError(errorObject)
                }
            }
        }
    }

    const onFlavorMetadataUpdate = async () => {
        let metadata = {...updatedMetadata}
        const keys_to_delete = Object.keys(flavorExtraSpecs).filter(key => !Object.keys(updatedMetadata).includes(key))
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (keys_to_delete.length > 0 && project_token) {
            const delete_method = "DELETE"
            for (let i in keys_to_delete) {
                try {
                    await computeNovaRequest({
                        url: `${common_url}/os-extra_specs/${keys_to_delete[i]}`, 
                        method: delete_method, 
                        token: project_token
                    })
                } catch {

                }
                
            }
        } 
        let data = {}
        data["extra_specs"] = metadata
        if (project_token) {
            const method = "POST"
            const flavor_response = await computeNovaRequest({
                url: `${common_url}/os-extra_specs`, 
                method: method, 
                data: data,
                token: project_token
            })
            if (flavor_response.status_code === flavorsUrlResponses.post.success_response.status_code) {
                handleDataFetch()
                handleFlavorMetadataSelectionDialogClose()
                setCurrentAction("")
            } else {
                const error_response = flavorsUrlResponses.post.error_response.filter(
                    error_item => error_item.status_code === flavor_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: flavor_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = flavorsUrlResponses.post.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: flavor_response.error
                    }
                    setError(errorObject)
                }
            }
        }
    }

    const handleUpdateFlavorDialogOpen = () => {
        setUpdateFlavorDialogOpen(true)
    }

    const handleUpdateFlavorDialogClose = () => {
        setUpdateFlavorDialogOpen(false)
    }

    const onFlavorUpdate = useCallback(async (event,data) => {
        let updated_data = {...flavorUpdateData}
        if (data) {
            updated_data = data
        }
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const method = "PUT"
            const flavor_response = await computeNovaRequest({
                url: common_url, 
                method: method, 
                data: {flavor: updated_data},
                token: project_token
            })
            if (flavor_response.status_code === flavorsUrlResponses.put.success_response.status_code) {
                setCurrentAction("")
                handleUpdateFlavorDialogClose()
                handleDataFetch()
            } else {
                const error_response = flavorsUrlResponses.put.error_response.filter(
                    error_item => error_item.status_code === flavor_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: flavor_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = flavorsUrlResponses.put.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: flavor_response.error
                    }
                    setError(errorObject)
                }
            }
        }
    },[
        common_url,
        defaultAdminProject,
        flavorUpdateData,
        handleDataFetch
    ])

    useEffect(() => {
        let flavor_actions = []
        let new_action = {}
        new_action["value"] = "update_flavor"
        new_action["action"] = handleUpdateFlavorDialogOpen
        new_action["keyword"] = "flavorUpdateActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        flavor_actions.push({...new_action})
        new_action["value"] = "project_add_access"
        new_action["action"] = handleAddProjectAccessDialogOpen
        new_action["keyword"] = "privateFlavorAddProjectAccessActionLabel"
        new_action["button_text"] = "applyButtonTitleText"
        flavor_actions.push({...new_action})
        new_action["value"] = "project_remove_access"
        new_action["action"] = handleRemoveProjectAccessDialogOpen
        new_action["keyword"] = "privateFlavorRemoveProjectAccessActionLabel"
        new_action["button_text"] = "applyButtonTitleText"
        flavor_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "flavor_manage_metadata"
        new_action["action"] = handleFlavorMetadataDialogOpen
        new_action["keyword"] = "createUpdateMetadataActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        flavor_actions.push({...new_action})
        
        setFlavorActions(flavor_actions)
    },[
        selectedRow,
        handleAddProjectAccessDialogOpen,
        handleRemoveProjectAccessDialogOpen
    ])

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${common_url}/os-extra_specs`
                const method = "GET"

                const flavors_response = await openstackRequest({url:url, method:method, token: project_token})
                if (flavors_response.status_code === flavorsUrlResponses.get.success_response.status_code) {
                    setFlavorExtraSpecs(flavors_response.data.extra_specs)
                } 
            }
        })();
    },[
        common_url,
        flavorsUrl,
        defaultAdminProject,
        selectedRow
    ]);

    useEffect(() => {
        if (Object.keys(flavorUpdateData).length === 0) {
            let new_form_data = {}
            for (const n in flavorUpdateDataForm) {
                new_form_data[flavorUpdateDataForm[n].field_key] = selectedRow[flavorUpdateDataForm[n].field_key]
            }
            setFlavorUpdateData(new_form_data)
        }
    },[flavorUpdateData, selectedRow]);

    const handleMetadataFormatting = useCallback((objs,props) => {
        const metadata_catalog = metadataNamespaces.map(ns => {
            let new_item = {...ns}
            const resource_type_association = ns.resource_type_associations.filter(rs => rs.name === RESOURCE_NAME)[0]
            new_item["value"] = ns.namespace
            new_item["title"] = ns.display_name
            const prefix = resource_type_association.prefix ? resource_type_association.prefix : ""
            new_item["prefix"] = prefix
            new_item["objects"] = objs[ns.namespace].map((obj,index) => {
                let new_object = {...obj}
                new_object["title"] = obj.name
                new_object["value"] = `${ns.namespace}_${prefix}object${index}`
                new_object["properties"] = []
                const obj_props = Object.keys(obj.properties).map(key => {
                    let new_prop = {}
                    new_prop["value"] = `${prefix}${key}`
                    for (const [k,v] of Object.entries(obj.properties[key])) {
                        if (Array.isArray(v)) {
                            new_prop[k] = [...v]
                        } else if (v instanceof Object) {
                            new_prop[k] = {...v}
                        } else {
                            new_prop[k] = v
                        }
                    }
                    return new_prop
                })
                new_object["properties"] = obj_props
                return new_object
            })
            new_item["properties"] = Object.keys(props[ns.namespace]).map(pr_key => {
                let new_pr = {}
                new_pr["value"] = `${prefix}${pr_key}`
                for (let k in props[ns.namespace][pr_key]) {
                    if (k === "items") {
                        new_pr["enum"] = props[ns.namespace][pr_key][k]["enum"]
                    } else if (Array.isArray(props[ns.namespace][pr_key][k])) {
                        new_pr[k] = [...props[ns.namespace][pr_key][k]]
                    } else if (props[ns.namespace][pr_key][k] instanceof Object) {
                        new_pr[k] = {...props[ns.namespace][pr_key][k]}
                    } else {
                        new_pr[k] = props[ns.namespace][pr_key][k]
                    }
                }
                return  new_pr
            })
            return new_item
        })
        setMetadataCatalog(metadata_catalog)
    },[metadataNamespaces])

    useEffect(() => {
        (async () => {
            const url = `${imageServiceDomain}/${imageServiceVersion}/metadefs/namespaces`
            const method = "GET"
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let namespace_list = []
                const metadata_response = await openstackRequest({
                    url:url, 
                    method:method, 
                    token: project_token
                })
                if (metadata_response.status_code === flavorsUrlResponses.get.success_response.status_code) {
                    const namespaces = metadata_response.data.namespaces.filter(item => Object.keys(item).includes("resource_type_associations") &&
                        item.resource_type_associations.filter(resource => resource.name === RESOURCE_NAME).length > 0
                    )
                    namespace_list = [...namespace_list, ...namespaces]
                    if (Object.keys(metadata_response.data).includes("next")) {
                        let next_url = `${imageServiceDomain}${metadata_response.data.next}`
                        while (next_url) {
                            const next_request = await openstackRequest({
                                url:next_url, 
                                method:method, 
                                token: project_token
                            })
                            const next_namespaces = next_request.data.namespaces.filter(item => Object.keys(item).includes("resource_type_associations") &&
                                item.resource_type_associations.filter(resource => resource.name === RESOURCE_NAME).length > 0
                            )
                            namespace_list = [...namespace_list, ...next_namespaces]
                            if (Object.keys(next_request.data).includes("next")) {
                                next_url = `${imageServiceDomain}${next_request.data.next}`
                            } else {
                                next_url = null
                            }
                        }
                    }
                    const compare = ( a, b ) => {
                        if ( a["display_name"].toLowerCase() < b["display_name"].toLowerCase() ){
                          return -1;
                        }
                        if ( a["display_name"].toLowerCase() > b["display_name"].toLowerCase() ){
                          return 1;
                        }
                        return 0;
                    }
                    namespace_list.sort(compare)
                    setMetadataNamespaces(namespace_list)
                } else {
                    setMetadataNamespaces([])
                }
            }
        })();
    },[
        selectedRow,
        imageServiceDomain,
        imageServiceVersion,
        defaultAdminProject
    ]);

    useEffect(() => {
        if (metadataNamespaces.length > 0) {
            (async () => {
                let total_objects = {}
                let total_properties = {}
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                const method = "GET"
                for (let i in metadataNamespaces) {
                    let namespace_objects = []
                    let namespace_properties = {}
                    if (project_token) {
                        const object_url = `${imageServiceDomain}/${imageServiceVersion}/metadefs/namespaces/${metadataNamespaces[i].namespace}/objects`
                        const object_response = await openstackRequest({
                            url:object_url, 
                            method:method, 
                            token: project_token
                        })
                        if (object_response.status_code === flavorsUrlResponses.get.success_response.status_code) {
                            namespace_objects = [...namespace_objects, ...object_response.data.objects]
                        }
                        const props_url = `${imageServiceDomain}/${imageServiceVersion}/metadefs/namespaces/${metadataNamespaces[i].namespace}/properties`
                        const props_response = await openstackRequest({
                            url:props_url, 
                            method:method, 
                            token: project_token
                        })
                        if (props_response.status_code === flavorsUrlResponses.get.success_response.status_code) {
                            namespace_properties = {...namespace_properties, ...props_response.data.properties}
                        }
                    }
                    total_objects[metadataNamespaces[i].namespace] = namespace_objects
                    total_properties[metadataNamespaces[i].namespace] = namespace_properties
                }
                handleMetadataFormatting(total_objects, total_properties)
            })();
        }
    },[
        metadataNamespaces,
        imageServiceDomain,
        imageServiceVersion,
        selectedRow,
        handleMetadataFormatting,
        defaultAdminProject
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setTimeout(() => setIsCardLoading(false), 600)
    },[])

    return (
        <React.Fragment>
        {selectedRow !== null && 
        <WrapperBox>
            <ServiceContentHeader 
                service_menu={flavorSubMenu}
                service_menu_titles={defaultTexts}
                onClick={handleFlavorDetailTabChange}
            />
        </WrapperBox>}
        {currentTab === "/flavor-details" &&
            <Stack 
                spacing={5} 
                sx={{ px: 1, my: 5}}
            >
                {flavorDetailSchema.map((field) => {
                    return (
                        getDetailDataComponent({
                            fieldType: field.field_type,
                            fieldKey: field.field_key,
                            label: defaultTexts[field.label],
                            value: selectedRow ? selectedRow[field.field_key] : "",
                            textOnTrue: defaultTexts[field.value_on_true],
                            textOnFalse: defaultTexts[field.value_on_false]
                        })
                    )
                })}
            </Stack>
        }
        {currentTab === "/flavor-extra-specs" && 
            Object.keys(flavorExtraSpecs).length > 0 &&
            <Stack 
                spacing={5} 
                sx={{ px: 1, my: 5 }}
            >
                {Object.keys(flavorExtraSpecs).map((key) => {
                    return (
                        <div>
                        <Stack 
                            key={key}
                            direction="row"
                            alignItems="center" 
                            justifyContent="space-between"
                        >
                            <div>
                                <Stack 
                                    direction="row" 
                                    alignItems="center" 
                                    justifyContent="flex-start"
                                >
                                    <CustomText 
                                        sx={{
                                            p: 1, 
                                            color: "text.secondary"
                                        }}
                                    >
                                        {defaultTexts.keyFormFieldLabel}:
                                    </CustomText>
                                    <CustomText 
                                        sx={{p: 1}}
                                    >
                                        {key}
                                    </CustomText>
                                </Stack>
                                <Stack 
                                    direction="row" 
                                    alignItems="center" 
                                    justifyContent="flex-start"
                                >
                                    <CustomText 
                                        sx={{
                                            p: 1, 
                                            color: "text.secondary"
                                        }}
                                    >
                                        {defaultTexts.valueFormFieldLabel}:
                                    </CustomText>
                                    <CustomText 
                                        sx={{p: 1}}
                                    >
                                        {flavorExtraSpecs[key]}
                                    </CustomText>
                                    
                                </Stack>
                                
                            </div>
                            <IconButton 
                                onClick={() => handleExtraSpecDelete(key)}
                                color="primary"
                            >
                                <DeleteIcon />
                            </IconButton>
                        </Stack>
                        <Divider />
                    </div>
                    ) 
                })}
            </Stack>}
        {currentTab === "/flavor-extra-specs" && 
            Object.keys(flavorExtraSpecs).length === 0 &&
            <NoDataNote text={defaultTexts.noMetadataNoteText} />
        }
        {!isCardLoading && <Paper sx={{ 
            position: 'fixed', 
            bottom: 0, 
            height: FOOTER_HEIGHT,
            left: FOOTER_WIDTH, 
            right: 12,
            zIndex: 3000
            }} 
            elevation={24}
            square={true}
        >
            <Grid 
                container 
                alignItems="center"  
                justifyContent="space-between"
            >
                <Grid item>
                    <CustomSelectField 
                        items={flavorActions} 
                        currentValue={currentAction}
                        setCurrentValue={setCurrentAction}
                        item_titles={defaultTexts}
                        label={defaultTexts.actionsDropdownLabelText}
                        empty={true}
                        size="small"
                        sx={{m: 1}}
                    />
                    {currentAction.length > 0 && 
                        <Button 
                                variant="contained"
                                color="secondary"
                                sx={{m: 1, height: '70%'}}
                                onClick={flavorActions.filter(
                                    action => action.value === currentAction)[0].action
                                }
                            >
                            {defaultTexts[flavorActions.filter(
                                action => action.value === currentAction)[0].button_text]}
                        </Button>
                    }
                </Grid>
                <Grid item>
                    {selectedRow !== null && 
                        <IconButton onClick={handleConfirmDeleteDialogOpen}>
                            <DeleteIcon 
                                color="primary"
                            />
                        </IconButton>}
                </Grid>
            </Grid>
        </Paper>} 
        <CustomDialog
            open={updateFlavorDialogOpen}
            onClose={handleUpdateFlavorDialogClose}
            dialogTitle={{
                title: defaultTexts.updateFlavorActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onFlavorUpdate, 
                sx: {color: 'primary.main'}}]}
        >
            {getDataForm(
                flavorUpdateDataForm,
                {},
                flavorUpdateData,
                handleFlavorUpdateDataChange
            )}
        </CustomDialog>
        <CustomDialog
            open={flavorMetadataDialogOpen}
            onClose={handleFlavorMetadataSelectionDialogClose}
            dialogTitle={{
                title: defaultTexts.flavorMetadataUpdateActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onFlavorMetadataUpdate, 
                sx: {color: 'primary.main'}}]}
            maxWidth="lg"
        >
            <CustomTransferList 
                withCustomList={true}
                withInstructions={true}
                catalogList={metadataCatalog}
                appliedFieldList={flavorExtraSpecs}
                onDataUpdate={setUpdatedMetadata}
            />
        </CustomDialog>
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts.failedActionErrorDialogTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts.failedActionErrorDialogMessage}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
        </React.Fragment>
    )
};

export default FlavorDetailV21;