import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import NetworksWrapperV20 from './networks/networksWrapperV2.0';
import SegmentsWrapperV20 from './segments/segmentsWrapperV2.0';
import PortsWrapperV20 from './ports/portsWrapperV2.0';
import TrunksWrapperV20 from './trunks/trunksWrapperV2.0';
import { openStackServices, 
    networkNeutronConstants
} from '../../../../../config/openStackConstants';
import { 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../_network/openstack_request';
import { networksUrl as networkUrlResponses } 
from '../../../../../_api_responses/openstack/neutron/networks/v2.0';

const SERVICE_NAME = openStackServices.networkService

const L2NetworkingWrapperV20 = (props) => {
    const { navigate, location, changeMenuActiveTab } = props
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [currentTab, setCurrentTab] = useState("networks")
    const [segmentsSupported, setSegmentsSupported] = useState(false)
    const [trunksSupported, setTrunksSupported] = useState(false)

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const segmentsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.segmentsUrl)[0].url)
    const trunksUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.trunksUrl)[0].url)

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${neutronServiceDomain}/${neutronServiceVersion}/${segmentsUrl}`
                const method = "GET"
                const segment_response = await openstackRequest({url:url, method:method, token: project_token})

                if (segment_response.status_code === networkUrlResponses.get.success_response.status_code) {
                    setSegmentsSupported(true)
                } else if (segment_response.status_code === 404) {
                    setSegmentsSupported(false)
                }
            }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        segmentsUrl, 
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${neutronServiceDomain}/${neutronServiceVersion}/${trunksUrl}`
                const method = "GET"
                const trunk_response = await openstackRequest({url:url, method:method, token: project_token})
                if (trunk_response.status_code === networkUrlResponses.get.success_response.status_code) {
                    setTrunksSupported(true)
                } else if (trunk_response.status_code === 404) {
                    setTrunksSupported(false)
                }
            }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        trunksUrl, 
        defaultAdminProject
    ]);

    return (
        <Box>
            {currentTab === "networks" && 
                <NetworksWrapperV20 
                    navigate={navigate}
                    location={location}
                    changeMenuActiveTab={changeMenuActiveTab}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    trunksSupported={trunksSupported}
                    segmentsSupported={segmentsSupported}
                />
            }
            {currentTab === "ports" && 
                <PortsWrapperV20
                    navigate={navigate}
                    location={location}
                    changeMenuActiveTab={changeMenuActiveTab}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    trunksSupported={trunksSupported}
                    segmentsSupported={segmentsSupported}
                />
            }
            {currentTab === "segments" && segmentsSupported &&
                <SegmentsWrapperV20
                    navigate={navigate}
                    location={location}
                    changeMenuActiveTab={changeMenuActiveTab}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    trunksSupported={trunksSupported}
                    segmentsSupported={segmentsSupported}
                />
            }
            {currentTab === "trunks" && trunksSupported &&
                <TrunksWrapperV20
                    navigate={navigate}
                    location={location}
                    changeMenuActiveTab={changeMenuActiveTab}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    trunksSupported={trunksSupported}
                    segmentsSupported={segmentsSupported}
                />
            }
        </Box>
    )
};

export default L2NetworkingWrapperV20;