import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import IdentityKeystoneRegionsSubheaderV3 from 
'./identityKeystoneRegionsSubheaderV3';
import IdentityRegionsTableV3 from './identityRegionsTableV3';
import { identityKeystonConstants } from 
'../../../../../config/openStackConstants';
import { openstackRequest } from 
'../../../../../_network/openstack_request';
import { regionsUrl as regionsUrlResponses } from 
'../../../../../_api_responses/openstack/identity/regions/v3';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { regionsfilterMenu } from 
'../../../../../_data/openstack/identity/regions/v3';
import CustomDialog from '../../../../_common/CustomDialog';
import CustomSideDrawer from '../../../../_common/CustomSideDrawer';
import IdentityRegionDetailV3 from './identityRegionDetailV3';
import useWindowDimensions from '../../../../_common/WindowDimensions';
import Dimensions from '../../../../../config/dimensions';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import Box from '@mui/material/Box';

const filterMenu = regionsfilterMenu

const SERVICE_NAME = openStackServices.identityService

const getWidthWeight = (width) => {
    if (width < Dimensions.tablet_mini.width) {
        return 0.9
    } else if (width < Dimensions.tablet.width) {
        return 0.6
    } else {
        return 0.4
    }
}

const IdentityRegionsWrapperV3 = (props) => {
    const [isLoading, setIsLoading] = useState(true)
    const [regionsData, setRegionsData] = useState([])
    const [error, setError] = useState()
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = getWidthWeight(width)
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const [selectedFilter, setSelectedFilter ] = useState(filterMenu[0].value)
    const [selectedFilterValue, setSelectedFilterValue] = useState("");
    const [filterQueryParams, setFilterQueryParams] = useState("")
    const apiResponseTexts = useSelector(state => state.texts.langTexts)

    const [fetchDataRequired, setFetchDataRequired] = useState(true);

    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const regionsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.regionsUrl)[0].url)

    const handleDataFetch = () => {
        setFetchDataRequired(true)
    }

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleFilteredSearch = () => {
        if (selectedFilter && selectedFilterValue) {
            setFilterQueryParams(`/?${selectedFilter}=${selectedFilterValue}`)
        } else {
            setFilterQueryParams("")
        }
    }

    const handleFilterReset = () => {
        setSelectedFilter(filterMenu[0].value)
        setSelectedFilterValue("")
        setFilterQueryParams("")
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleDetailCardOpen = (index) => {
        setSelectedRow(index)
        setTimeout(() => setDetailCardOpen(true), 100) 
    };

    const handleDetailCardClose = () => {
        setSelectedRow(null)
        setTimeout(() => setDetailCardOpen(false), 100) 
    };

    const handleDeleteRegion = async () => {
        handleDetailCardClose()
        const url = `${identityServiceDomain}/${identityServiceVersion}/${regionsUrl}/${regionsData[selectedRow].id}`
        const method = "DELETE"

        const region_response = await openstackRequest({url: url, method: method})
        if (region_response.status_code === regionsUrlResponses.delete.success_response.status_code) {
            //setSuccessRegionDelete({
            //    success_title: regionsUrlResponses.delete.success_response.response_title, 
            //    success_message: regionsUrlResponses.delete.success_response.response_message
            //})
            handleDataFetch()
        } else {
            const error_response = regionsUrlResponses.delete.error_response.filter(
                error_item => error_item.status_code === region_response.status_code)
            if (error_response.length > 0) {
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: region_response.error
                }
                setError(errorObject)
            } else {
                const error_response = regionsUrlResponses.delete.error_response.filter(
                    error_item => error_item.status_code === "unknown")
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: region_response.error
                }
                setError(errorObject)
            }
        }
    }

    useEffect(() => {
        setFetchDataRequired(true)
    },[filterQueryParams]);

    useEffect(() => {
        handleLoading(true)
        if (fetchDataRequired) {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${regionsUrl}${filterQueryParams}`
            const method = "GET"

            const regions_response = await openstackRequest({url:url, method:method})
            if (regions_response.status_code === regionsUrlResponses.get.success_response.status_code) {
                setRegionsData([...regions_response.data.regions])
            } else {
                const error_response = regionsUrlResponses.get.error_response.filter(
                    error_item => error_item.status_code === regions_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: regions_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = regionsUrlResponses.get.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: regions_response.error
                    }
                    setError(errorObject)
                }
            }
        })();
        setFetchDataRequired(false)
        }
        setTimeout(()=>{handleLoading(false)},700)
    },[
        identityServiceDomain, 
        identityServiceVersion, 
        regionsUrl, 
        filterQueryParams,
        fetchDataRequired
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <IdentityKeystoneRegionsSubheaderV3 
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                selectedFilterValue={selectedFilterValue}
                setSelectedFilterValue={setSelectedFilterValue}
                filterMenu={filterMenu}
                handleFilteredSearch={handleFilteredSearch}
                handleFilterReset={handleFilterReset}
                handleFetchData={handleDataFetch}
            />
            {isLoading && <CustomBackdrop open={isLoading} />}
            {!isLoading && <IdentityRegionsTableV3 
                regionsData={regionsData}
                handleRowSelection={handleDetailCardOpen}
            />}
            {selectedRow !== null && <CustomSideDrawer 
                open={detailCardOpen}
                widthWeight={WIDTH_WEIGHT}
                handleDrawerOpen={handleDetailCardOpen}
                handleDrawerClose={handleDetailCardClose}
            > 
                <IdentityRegionDetailV3 
                    selectedRow={selectedRow}
                    widthWeight={WIDTH_WEIGHT}
                    handleFetchData={handleDataFetch}
                    handleDrawerClose={handleDetailCardClose}
                    regionsData={regionsData}
                    handleDeleteRegion={handleDeleteRegion}
                />            
            </CustomSideDrawer>}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: apiResponseTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${apiResponseTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>Details:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </Box>
    )
};

export default IdentityRegionsWrapperV3;