import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomDrawer from '../../_common/CustomDrawer';
import IncorrectServiceVersionNote from '../../_common/IncorrectServiceVersionNote';
import NotPurchasedService from '../../_common/NotPurchasedService';
import { backupsDRConstants } from '../../../config/openStackConstants';
import BackupsDRContentV2 from './v2/backupsDRContentV2';
import { openStackServices } from '../../../config/openStackConstants';

const SERVICE_NAME = openStackServices.backupService
const SERVICE_TITLE = 'Backups (DR)'

const BackupsDRWrapper = (props) => {
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
    const navigate = useNavigate();

    if (!isAuthenticated) {
        navigate('/');
    }
    const purchasedServices = useSelector(state => 
        state.openstack.purchasedServices);
    
    const purchasedService = purchasedServices.filter(
        item => item.service === SERVICE_NAME)
    if (purchasedService.length === 0) {
        return (
            <CustomDrawer>
                <NotPurchasedService service={SERVICE_TITLE} />
            </CustomDrawer>
        )
    }
    if (!backupsDRConstants.supported_api_versions.includes(
        purchasedService[0].config_params.api_version)) {
        return (
            <CustomDrawer>
                <IncorrectServiceVersionNote 
                    service_title={SERVICE_TITLE} 
                    service_version={purchasedService[0].config_params.api_version}
                />
            </CustomDrawer>
        )
    }

    if (purchasedService[0].config_params.api_version === "v2") {
        return (
            <CustomDrawer>
                <BackupsDRContentV2
                    serviceTitle={SERVICE_TITLE} 
                    serviceName={SERVICE_NAME} 
                />
            </CustomDrawer>
        )
    }
};

export default BackupsDRWrapper;