import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import WrapperBox from '../../../../_common/WrapperBox';
import AddButton from '../../../../_common/AddButton';
import FormGroup from '@mui/material/FormGroup';
import Constants from '../../../../../config/constants';
import FilterButton from '../../../../_common/FilterButton';
import CustomDialog from '../../../../_common/CustomDialog';
import { openStackServices, blockStorageCinderConstants } from 
'../../../../../config/openStackConstants';
import { getFormFieldComponent } from '../../../../_common/_form_fields/form_helpers';
import { volumeCinderRequest, getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { backupsUrl as backupUrlResponses } 
from '../../../../../_api_responses/openstack/cinder/backups/v3';
import Grid from '@mui/material/Grid';
import { backupDataForm } from '../../../../../_data/openstack/cinder/backups/v3';

const SERVICE_NAME = openStackServices.volumeService

const BackupsSubheaderV3 = (props) => {
    const { selectedFilter, setSelectedFilter } = props
    const { selectedFilterValue, setSelectedFilterValue } = props
    const { handleDataFetch } = props
    const { filterMenu, volumes } = props
    const { handleFilteredSearch, handleFilterReset } = props
    const adminProjects = useSelector(state => state.profile.adminProjects)
    const defaultTexts = useSelector(state => state.texts.langTexts)
    //const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [error, setError] = useState()
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [formData, setFormData] = useState({})
    const [formDataOptions, setFormDataOptions] = useState({});

    const cinderServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const cinderServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const backupsUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.backupsUrl)[0].url)

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleAddBackupFormReset = () => {
        setFormDataOptions({})
        let new_form_data = {}
        for (const n in backupDataForm) {
            if (backupDataForm[n].field_type === "string" || 
            backupDataForm[n].field_type === "select") {
                new_form_data[backupDataForm[n].field_key] = ""
            } else if (backupDataForm[n].field_type === "bool") {
                new_form_data[backupDataForm[n].field_key] = backupDataForm[n].default_value ? 
                backupDataForm[n].default_value : 
                false
            }
        }
        setFormData(new_form_data)
    }

    const handleFormDataChange = (event,field_key) => {
        setFormDataOptions({})
        let new_form_data = {...formData}
        if (backupDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (backupDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setFormData(new_form_data)
    }


    const getDataForm = () => {
        return (
            <FormGroup>
                {backupDataForm.map(field => {
                    let form_field_options = {...formDataOptions[field.field_key]}
                    if (field.field_key === "volume_id") {
                        const admin_projects_ids = adminProjects.map(p => p.id)
                        const allowed_volumes = volumes.filter(
                            v => admin_projects_ids.includes(v[blockStorageCinderConstants.volumeProjectIdField]))
                        const volume_selection = allowed_volumes.map(v => {
                            return {keyword: `${v.name} (${v.id})`, value: v.id, default: false}
                        })
                        form_field_options["items"] = [...volume_selection]
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = true
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            formData,
                            handleFormDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleFormDataValidation = () => {
        let validation_faild = true
        let updatedDataFormOptions = {...formDataOptions}
        for (let n in backupDataForm) {
            if (backupDataForm[n].required && !formData[backupDataForm[n].field_key]) {
                validation_faild = false
                updatedDataFormOptions[backupDataForm[n].field_key] = {}
                updatedDataFormOptions[backupDataForm[n].field_key]["error"] = true
                updatedDataFormOptions[backupDataForm[n].field_key]["errorText"] = defaultTexts[backupDataForm[n].error_label]
            }
        }

        setFormDataOptions(updatedDataFormOptions)
        return validation_faild
    }

    const onAddBackup = async () => {
        const validateFormData = handleFormDataValidation()
        if (validateFormData) {
            const volume = volumes.filter(v => v.id === formData.volume_id)
            const project_id = adminProjects.filter(p => p.id === volume[0][blockStorageCinderConstants.volumeProjectIdField])
            const project_token = await getXAuthTokenProjectScope(project_id[0].id)
            if (project_token) {
                const url = `${cinderServiceDomain}/${cinderServiceVersion}/${project_id[0].id}/${backupsUrl}`
                const method = "POST"
                const backup_response = await volumeCinderRequest({
                    url: url, 
                    method: method, 
                    data: {backup: formData},
                    token: project_token
                })
                if (backup_response.status_code === backupUrlResponses.post_async.success_response.status_code) {
                    handleDataFetch()
                    handleAddBackupFormReset()
                    setFormDataOptions({})
                } else {
                    const error_response = backupUrlResponses.post_async.error_response.filter(
                        error_item => error_item.status_code === backup_response.status_code)
                    if (error_response.length > 0) {
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: backup_response.error
                        }
                        setError(errorObject)
                    } else {
                        const error_response = backupUrlResponses.post_async.error_response.filter(
                            error_item => error_item.status_code === "unknown")
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: backup_response.error
                        }
                        setError(errorObject)
                    }
                }
            }
        }
        return validateFormData
    }

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        if (Object.keys(formData).length === 0) {
            let new_form_data = {}
            for (const n in backupDataForm) {
                if (
                    backupDataForm[n].field_type === "string" || 
                    backupDataForm[n].field_type === "select"
                    ) {
                    new_form_data[backupDataForm[n].field_key] = ""
                } else if (backupDataForm[n].field_type === "bool") {
                    new_form_data[backupDataForm[n].field_key] = backupDataForm[n].default_value ? 
                    backupDataForm[n].default_value : 
                    false
                }
            }
            setFormData(new_form_data)
        }
    },[formData]);

    return (
        <WrapperBox 
            sx={{
                flexDirection: "row", 
                justifyContent: 'space-between',
                mt: 1
            }}>
            <Grid container justifyContent='space-between' alignItems="center">
                <Grid item>
                    <AddButton 
                        getDataForm={getDataForm}               
                        onSubmit={onAddBackup}
                        formReset={handleAddBackupFormReset}
                        customTexts={{
                            title: defaultTexts.addBackupDialogTitle
                        }}
                    />
                </Grid>
                <Grid item>
                    <FilterButton 
                        choice_mode={Constants.simple_filter_type}
                        currentFilter={selectedFilter}
                        setCurrentFilter={setSelectedFilter}
                        filter_menu_titles={defaultTexts}
                        filter_menu={filterMenu}
                        currentFilterValue={selectedFilterValue}
                        setCurrentFilterValue={setSelectedFilterValue}
                        onFilterSubmit={handleFilteredSearch}
                        onFilterReset={handleFilterReset}
                    />
                </Grid>
            </Grid>
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            
        </WrapperBox>
    )
};

export default BackupsSubheaderV3