import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Constants from '../../../../../config/constants';
import AddButton from '../../../../_common/AddButton';
import FilterButton from '../../../../_common/FilterButton';
import WrapperBox from '../../../../_common/WrapperBox';
import { FormGroup } from '@mui/material';
import { servicesCatalogDataForm } from 
'../../../../../_data/openstack/identity/services_catalog/v3';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { identityKeystonConstants } from 
'../../../../../config/openStackConstants';
import { openstackRequest } from 
'../../../../../_network/openstack_request';
import { servicesUrl as servicesUrlResponses } from 
'../../../../../_api_responses/openstack/identity/services_catalog/v3';
import CustomDialog from '../../../../_common/CustomDialog';

const SERVICE_NAME = openStackServices.identityService

const IdentityServicesCatalogSubheaderV3 = (props) => {
    const [error, setError] = useState()
    const [successServiceAdd, setSuccessServiceAdd] = useState()
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const { selectedFilter, setSelectedFilter } = props
    const { selectedFilterValue, setSelectedFilterValue } = props
    const { handleFetchData } = props
    const { filterMenu } = props
    const { handleFilteredSearch, handleFilterReset } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [formData, setFormData] = useState({})
    const [formDataOptions, setFormDataOptions] = useState({});

    const identityServiceDomain = useSelector(state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const servicesUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.servicesUrl)[0].url)

    const handleFormDataValidation = () => {
        let validation_faild = true
        let updatedDataFormOptions = {...formDataOptions}
        for (let n in servicesCatalogDataForm) {
            if (servicesCatalogDataForm[n].required && !formData[servicesCatalogDataForm[n].field_key]) {
                validation_faild = false
                updatedDataFormOptions[servicesCatalogDataForm[n].field_key] = {}
                updatedDataFormOptions[servicesCatalogDataForm[n].field_key]["error"] = true
                updatedDataFormOptions[servicesCatalogDataForm[n].field_key]["errorText"] = defaultTexts[servicesCatalogDataForm[n].error_label]
            }
        }
        setFormDataOptions(updatedDataFormOptions)
        return validation_faild
    }

    const onAddService = async () => {
        const validateFormData = handleFormDataValidation()
        if (validateFormData) {
            
            const url = `${identityServiceDomain}/${identityServiceVersion}/${servicesUrl}`
            const method = "POST"
            
            const service_response = await openstackRequest({url:url, method:method, data: {service: formData}})
            if (service_response.status_code === servicesUrlResponses.post.success_response.status_code) {
                handleAddServiceFormReset()
                handleFetchData()
                setSuccessServiceAdd({
                    success_title: servicesUrlResponses.post.success_response.response_title, 
                    success_message: servicesUrlResponses.post.success_response.response_message
                })
            } else {
                const error_response = servicesUrlResponses.post.error_response.filter(
                    error_item => error_item.status_code === service_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: service_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = servicesUrlResponses.post.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: service_response.error
                    }
                    setError(errorObject)
                }
            }
        }

        return validateFormData
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleSuccessDialogClose = () => {
        setSuccessServiceAdd(null);
        setSuccessDialogOpen(false);
    }

    const handleAddServiceFormReset = () => {
        setFormDataOptions({})
        let new_form_data = {}
        for (const n in servicesCatalogDataForm) {
            if (
                servicesCatalogDataForm[n].field_type === "string" || 
                servicesCatalogDataForm[n].field_type === "select" ||
                servicesCatalogDataForm[n].field_type === "list"
                ) {
                new_form_data[servicesCatalogDataForm[n].field_key] = ""
            } else if (servicesCatalogDataForm[n].field_type === "bool") {
                new_form_data[servicesCatalogDataForm[n].field_key] = servicesCatalogDataForm[n].default_value ? 
                servicesCatalogDataForm[n].default_value : 
                false
            }
        }
        setFormData(new_form_data)
    }

    const handleFormDataChange = (event,field_key) => {
        let new_form_data = {...formData}
        if (servicesCatalogDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (servicesCatalogDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setFormData(new_form_data)
    }

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessDialogOpen(true)
    },[successServiceAdd]);

    useEffect(() => {
        if (Object.keys(formData).length === 0) {
            let new_form_data = {}
            for (const n in servicesCatalogDataForm) {
                if (
                    servicesCatalogDataForm[n].field_type === "string" || 
                    servicesCatalogDataForm[n].field_type === "select" ||
                    servicesCatalogDataForm[n].field_type === "list"
                    ) {
                    new_form_data[servicesCatalogDataForm[n].field_key] = ""
                } else if (servicesCatalogDataForm[n].field_type === "bool") {
                    new_form_data[servicesCatalogDataForm[n].field_key] = servicesCatalogDataForm[n].default_value ? 
                    servicesCatalogDataForm[n].default_value : 
                    false
                }
            }
            setFormData(new_form_data)
        }
    },[formData]);

    const getDataForm = () => {
        return (
            <FormGroup>
                {servicesCatalogDataForm.map(field => {
                    let form_field_options = {...formDataOptions[field.field_key]}
                    if (field.field_key === "type") {
                        form_field_options["items"] = field.items
                        form_field_options["on_empty_null"] = field.on_empty_null
                        form_field_options["self_item_titles"] = field.self_item_titles
                        form_field_options["default_empty"] = field.default_empty
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            formData,
                            handleFormDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    return (
        <WrapperBox 
            sx={{
                flexDirection: "row", 
                justifyContent: 'space-between',
                mt: 1
            }}>
            <AddButton 
                getDataForm={getDataForm}               
                onSubmit={onAddService}
                formReset={handleAddServiceFormReset}
                customTexts={{
                    title: defaultTexts.addServiceDialogTitle,
                    description: ""
                }}
            />
            <FilterButton 
                choice_mode={Constants.simple_filter_type}
                currentFilter={selectedFilter}
                setCurrentFilter={setSelectedFilter}
                filter_menu_titles={defaultTexts}
                filter_menu={filterMenu}
                currentFilterValue={selectedFilterValue}
                setCurrentFilterValue={setSelectedFilterValue}
                onFilterSubmit={handleFilteredSearch}
                onFilterReset={handleFilterReset}
            />
            {successServiceAdd && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[successServiceAdd.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successServiceAdd.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>Details:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </WrapperBox>
    )
};

export default IdentityServicesCatalogSubheaderV3;