import { createSlice } from '@reduxjs/toolkit'
import Constants from '../../config/constants';

const initialState = {
    defaultLang: 'en',
    currentLang: 'en',
    supportedLangs: [
      {value: 'en', label: 'EN'}
    ],
    uiMode: 'dark',
    primaryColor: null,
    secondaryColor: null,
    logo: null,
    regionName: null,
    captchaConfig: {
      site_key: Constants.google_reCapcha_site_key, 
      secret_key: Constants.google_reCapcha_secret_key
    },
    billingDepositsTableRows: 10,
    billingPaymentsTableRows: 10,
    billingInvoicesTableRows: 10
}

const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setCurrentLang: (state, action) => {
        state.currentLang = action.payload.currentLang
    },
    setSupportedLangs: (state, action) => {
      state.supportedLangs = action.payload.supportedLangs
    },
    setUIColors: (state, action) => {
      state.primaryColor = action.payload.primaryColor
      state.secondaryColor = action.payload.secondaryColor
    },
    setLogo: (state, action) => {
      state.logo = action.payload.logo
    },
    setUIMode: (state, action) => {
        state.uiMode = action.payload.uiMode
    },
    setCaptchaConfig: (state, action) => {
      state.captchaConfig = action.payload.captchaConfig
    },
    setRegionName: (state, action) => {
      state.regionName = action.payload.regionName
    },
    setBillingDepositsTableRows: (state, action) => {
      state.billingDepositsTableRows = action.payload.billingDepositsTableRows
    },
    setBillingPaymentsTableRows: (state, action) => {
      state.billingPaymentsTableRows = action.payload.billingPaymentsTableRows
    },
    setBillingInvoicesTableRows: (state, action) => {
      state.billingInvoicesTableRows = action.payload.billingInvoicesTableRows
    },
    settingsReset: () => {
      return {...initialState}
    }
  }
})

export const { 
    setCurrentLang, 
    setSupportedLangs,
    setUIColors,
    setUIMode,
    setCaptchaConfig,
    setLogo,
    setRegionName,
    setBillingDepositsTableRows,
    setBillingPaymentsTableRows,
    setBillingInvoicesTableRows,
    settingsReset
} = settingsSlice.actions

export default settingsSlice.reducer