import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Constants from '../../../config/constants'
import billingRequest from '../../../_network/billing_request';
import { billingServiceURLs } from '../../../_network/apiUrls';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import BillingInvoicesTable from './billingInvoicesTable';
import CustomTableSkeleton from '../../_common/CustomTableSkeleton';
import { DatePicker } from '@mui/x-date-pickers';
import { FaSearch } from "react-icons/fa";
import FilterButton from '../../_common/FilterButton';
import { openStackServices } from 
'../../../config/openStackConstants';
import { identityKeystonConstants } from 
'../../../config/openStackConstants';
import { openstackRequest } from 
'../../../_network/openstack_request';
import { projectsUrl as projectsUrlResponses } from 
'../../../_api_responses/openstack/identity/projects/v3';
import { filterMenu, dateFilterMenu } from "./invoicesData";
import CustomSelectField from 
'../../_common/_form_fields/CustomSelectField';
import CustomText from '../../_common/CustomText';
import { Paper } from '@mui/material';

const drawerWidth = Constants.drawerWidth;
const IDENTITY_SERVICE_NAME = openStackServices.identityService
let CURRENT_DATE = new Date()
CURRENT_DATE.setDate(1)

const BillingInvoices = (props) => {
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
    const drawerOpen = useSelector(state => state.drawer.drawerOpened);
    const [isTableLoading, setIsTableLoading] = useState(true)
    const [dataFetchRequired, setDataFetchRequired] = useState(true)
    const [openstackProjects, setOpenstackProjects] = useState([])
    const [invoicesFilterMenu, setInvoicesFilterMenu] = useState([...filterMenu]);
    const [selectedFilter, setSelectedFilter ] = useState(filterMenu[0].value)
    const [selectedFilterValue, setSelectedFilterValue] = useState("");
    const [filterQueryParams, setFilterQueryParams] = useState("")
    const [invoiceStartDate, setInvoiceStartDate] = useState(new Date(CURRENT_DATE));
    const [invoiceEndDate, setInvoiceEndDate] = useState(new Date())
    const [invoiceDueStartDate, setInvoiceDueStartDate] = useState(new Date(CURRENT_DATE));
    const [invoiceDueEndDate, setInvoiceDueEndDate] = useState(new Date())
    const [billingInvoices, setBillingInvoices] = useState([]);
    const [dateFilter, setDateFilter] = useState("time_stamp");

    const navigate = useNavigate();

    if (!isAuthenticated) {
        navigate('/');
    }

    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)


    const handleTableLoading = (mode) => {
        setIsTableLoading(mode)
    }

    const handleDataFetch = () => {
        setDataFetchRequired(true)
    }

    const handleFilteredSearch = () => {
        if (selectedFilter && selectedFilterValue) {
            setFilterQueryParams(`${selectedFilter}=${selectedFilterValue}`)
        } else {
            setFilterQueryParams("")
        }
    }

    const handleFilterReset = () => {
        setSelectedFilter(filterMenu[0].value)
        setSelectedFilterValue("")
        setFilterQueryParams("")
    }

    useEffect(() => {
        if (dataFetchRequired) {
            handleTableLoading(true)
            let query_params = ""
            if (dateFilter === "time_stamp") {
                const start_date_param = `${invoiceStartDate.getFullYear()}-${invoiceStartDate.getMonth()+1}-${invoiceStartDate.getDate()} 00:00:00`
                const end_date_param = `${invoiceEndDate.getFullYear()}-${invoiceEndDate.getMonth()+1}-${invoiceEndDate.getDate()} 23:59:59`
                query_params = `?start_time=${encodeURIComponent(start_date_param)}&end_time=${encodeURIComponent(end_date_param)}`
            } else {
                const start_date_param = `${invoiceDueStartDate.getFullYear()}-${invoiceDueStartDate.getMonth()+1}-${invoiceDueStartDate.getDate()} 00:00:00`
                const end_date_param = `${invoiceDueEndDate.getFullYear()}-${invoiceDueEndDate.getMonth()+1}-${invoiceDueEndDate.getDate()} 23:59:59`
                query_params = `?due_start_time=${encodeURIComponent(start_date_param)}&due_end_time=${encodeURIComponent(end_date_param)}`
            }
            
            const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingInvoicesEP}${query_params}&${filterQueryParams}`
            let request_data = {
                url: url,
                method: "GET"
            }
            billingRequest(request_data).then((response) => {
                if (response.status_code === 200) {
                    const formatted_data = response.data.map(item => {
                        let new_item = {...item}
                        new_item.time_stamp = new Date(item.time_stamp)
                        new_item.due_date = new Date(item.due_date)
                        return new_item
                    })
                    setBillingInvoices(formatted_data)
                }
                setDataFetchRequired(false)
                handleTableLoading(false)
            }).catch((err) => {
                setDataFetchRequired(false)
                handleTableLoading(false)
                console.log(err)
            })
        }
    },[
        dataFetchRequired,
        invoiceStartDate,
        invoiceEndDate,
        invoiceDueStartDate,
        invoiceDueEndDate,
        filterQueryParams,
        dateFilter
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({url:url, method:method})
            if (projects_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                setOpenstackProjects(projects_response.data.projects)
            }
        })();
    },[
        identityServiceDomain, 
        identityServiceVersion, 
        projectsUrl
    ]);


    useEffect(() => {
        if (openstackProjects.length > 0) {
            const project_filter = openstackProjects.map(p => {
                return {keyword: p.name, value: p.id, default: false}
            })
            const new_filter = filterMenu.map(f => {
                let new_item = {...f}
                if (f.value === "project_id") {
                    new_item.items = project_filter
                    new_item["self_item_titles"] = true
                }
                return new_item
            })
            setInvoicesFilterMenu(new_filter)
        }
    },[openstackProjects])

    useEffect(() => {
        handleDataFetch()
    },[filterQueryParams])

    return (
        <Box>
            <Box sx={{
                minHeight: 40
            }}>
                <Grid 
                    container 
                    spacing={2}
                    alignItems="end"
                    justifyContent="space-between"
                    sx={{
                        maxWidth: drawerOpen ? 
                        `calc(100vw - ${drawerWidth}px)` : 
                        `calc(100vw - ${110}px)`,
                        my: {xs: 1, sm: 0}
                    }}
                >
                    <Grid item>
                        <Grid 
                            container 
                            spacing={1} 
                            alignItems="center" 
                            justifyContent="start"
                        >
                            <Grid item>
                                {dateFilter === "time_stamp" ? <DatePicker 
                                    value={invoiceStartDate}
                                    onChange={(newValue) => setInvoiceStartDate(newValue)}
                                    maxDate={invoiceEndDate}
                                /> :
                                <DatePicker 
                                    value={invoiceDueStartDate}
                                    onChange={(newValue) => setInvoiceDueStartDate(newValue)}
                                    maxDate={invoiceDueEndDate}
                                />
                                }
                            </Grid>
                            <Grid item>
                                {dateFilter === "time_stamp" ? <DatePicker 
                                    value={invoiceEndDate}
                                    onChange={(newValue) => setInvoiceEndDate(newValue)}
                                    minDate={invoiceStartDate}
                                /> :
                                <DatePicker 
                                    value={invoiceDueEndDate}
                                    onChange={(newValue) => setInvoiceDueEndDate(newValue)}
                                    minDate={invoiceDueStartDate}
                                />
                                }
                            </Grid>
                            <Grid item>
                                <CustomSelectField 
                                    currentValue={dateFilter}
                                    setCurrentValue={setDateFilter}
                                    label={defaultTexts.dateFilterFormFieldLabel}
                                    required={false}
                                    items={dateFilterMenu}
                                    item_titles={defaultTexts}
                                    size="large"
                                    sx={{width: 260}}
                                />
                            </Grid>
                            <Grid item>
                                <IconButton 
                                    onClick={handleDataFetch}
                                >
                                    <FaSearch />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <FilterButton
                            choice_mode={Constants.simple_filter_type}
                            currentFilter={selectedFilter}
                            setCurrentFilter={setSelectedFilter}
                            filter_menu_titles={defaultTexts}
                            filter_menu={invoicesFilterMenu}
                            currentFilterValue={selectedFilterValue}
                            setCurrentFilterValue={setSelectedFilterValue}
                            onFilterSubmit={handleFilteredSearch}
                            onFilterReset={handleFilterReset}
                        />
                    </Grid>
                </Grid>
            </Box>
            {isTableLoading ? 
                <CustomTableSkeleton sx={{mt: 1}}/> :
                <Box>
                    {billingInvoices.length > 0 ? 
                        <BillingInvoicesTable 
                        billingInvoices={billingInvoices}
                        openstackProjects={openstackProjects}
                    /> : 
                    <Paper
                        sx={{
                            border: 1,
                            borderColor: "customBlue",
                            borderRadius: 2,
                            maxWidth: drawerOpen ? 
                            `calc(100vw - ${drawerWidth}px)` : 
                            `calc(100vw - ${110}px)`,
                            height: "60%",
                            alignItems: 'center',
                            justifyContent: 'center',
                            p: 3,
                            mt: 3,
                            display: "flex"
                        }}
                    >
                        <CustomText 
                            size="h5" 
                            sx={{color: "customBlue"}}
                        >
                            {defaultTexts.noDataToDisplayText}
                        </CustomText>
                    </Paper>
                }
                </Box>
            }
        </Box>
    )

};

export default BillingInvoices;