import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../../../_common/CustomTable';
import { serverDataSchema } from 
'../../../../../_data/openstack/compute/servers/v2.1';
import { setComputeServersTableConfig } from 
'../../../../../store/reducers/computeNovaSlice';
import { serverSortFields } from 
'../../../../../_data/openstack/compute/servers/v2.1';


const ComputeServersTableV21 = (props) => {
    const { serversData, setServersData } = props
    const { handleRowSelection, handleCellClick } = props
    const { currentAction } = props
    const { setCurrentAction }  = props
    const { actionsTexts }  = props
    const { actionsList } = props
    const { sortHandler } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);
    
    const serversTableConfig = useSelector(
        state => state.computeNova.computeServersTableConfig)
    const rowsPerPage = useSelector(
        state => state.computeNova.computeServersTableConfig.filter(
        versions => versions.api_version === "v2.1")[0].rows_per_page)
    const serversTableColumns = useSelector(
        state => state.computeNova.computeServersTableConfig.filter(
            versions => versions.api_version === "v2.1")[0].columns)
    const identityField = serversTableColumns.filter(
        item => item.is_id_field)[0].field_key

    const dispatch = useDispatch();

    const handleServerTableConfig = (value_list) => {
        let newTableConfig = serverDataSchema.filter(
            n => value_list.includes(n.field_key))
        const new_data = serversTableConfig.map(version => {
            if (version.api_version === "v2.1") {
                let new_item = {...version}
                new_item.columns = [...newTableConfig]
                return new_item
            } else {
                return version
            }
        })
        dispatch(setComputeServersTableConfig({
            computeServersTableConfig: new_data
        }))
    }
    
    const handleRowsPerPageChange = (rows_num) => {
        const new_data = serversTableConfig.map(version => {
            if (version.api_version === "v2.1") {
                let new_item = {...version}
                new_item.rows_per_page = rows_num
                return new_item
            } else {
                return version
            }
        })
        dispatch(setComputeServersTableConfig({
            computeServersTableConfig: new_data
        }))
    }

    return (
        <React.Fragment>
            <CustomTable
                withActions={true}
                withPagination={true}
                withCheckbox={true}
                defaultDataTexts={{...defaultTexts}} 
                tableHeaders={serversTableColumns}
                tableHeadersConfigHandler={handleServerTableConfig}
                identityField={identityField}
                columns={serverDataSchema}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                dataRows={serversData}
                setDataRows={setServersData}
                handleRowSelection={handleRowSelection}
                handleCellClick={handleCellClick}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={actionsTexts}
                actionsList={actionsList}
                withSorting={true}
                sortHandler={sortHandler}
                selfSorting={true}
                sortFields={serverSortFields}
            />
        </React.Fragment>
    )
};

export default ComputeServersTableV21;