import React, { useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux'; 
import Button from '@mui/material/Button';
import CustomText from '../../../../_common/CustomText';
import PlusButton from '../../../../_common/PlusButton';
import MinusButton from '../../../../_common/MinusButton';
import { Stack } from '@mui/material';
import Grid from "@mui/material/Grid";
import CustomSelectField from 
'../../../../_common/_form_fields/CustomSelectField';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { 
    openstackRequest, 
    computeNovaRequest, 
    getXAuthTokenProjectScope 
} from '../../../../../_network/openstack_request';
import { computeNovaConstants } from 
'../../../../../config/openStackConstants';
import CustomDialog from 
'../../../../_common/CustomDialog';
import CustomTextField from 
'../../../../_common/_form_fields/CustomTextField';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import { FormGroup } from '@mui/material';
import { 
    serverUpdateForm,
    serverInterfaceForm,
    serverVolumeForm,
    serverResizeForm
 } from '../../../../../_data/openstack/compute/servers/v2.1';
import { handleEmptyFields } from '../../../helpers/v2.1/compute_helpers';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import { serversUrl as serversUrlResponses } from 
'../../../../../_api_responses/openstack/compute/servers/v2.1';

const SERVER_ACTIONS = "server_actions"
const SERVER_CHANGE = "server_change"
const SERVER_VOLUMES = "server_volumes"
const SERVER_INTERFACES = "server_interfaces"
const SERVER_BACKUP = "server_backup"
//const SERVER_TAGS = "server_tags"
const SERVER_METADATA = "server_metadata"

const SERVICE_NAME = openStackServices.computeService

const SUCCESS_STATUS_CODE = serversUrlResponses.delete.success_response.status_code

const ComputeServerActionsV21 = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const { serverData } = props
    const { serverSecGroups } = props
    const { handleDataFetch } = props
    const { secGroups } = props
    const { networks, subnets } = props 
    const { attachedInterfaces } = props
    const { volumes, attachedVolumes } = props
    const { flavors } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [currentAction, setCurrentAction] = useState("")
    const [serverActions, setServerActions] = useState({})
    const [currentActionCategory, setCurrentActionCategory] = useState(SERVER_ACTIONS)
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [error, setError] = useState();
    const [successResponse, setSuccessResponse] = useState();

    const computeServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const computeServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const serversUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.serversUrl)[0].url)

    const serverActionCategories = [
        {
            value: SERVER_ACTIONS,
            keyword: "serverActionCategoryTitle"
        },
        {
            value: SERVER_CHANGE,
            keyword: "serverChangeActionCategoryTitle"
        },
        {
            value: SERVER_BACKUP,
            keyword: "serverBackupActionCategoryTitle"
        },
        {
            value: SERVER_VOLUMES,
            keyword: "serverVolumesActionCategoryTitle"
        },
        {
            value: SERVER_INTERFACES,
            keyword: "serverInterfacesActionCategoryTitle"
        },
        //{
        //    value: SERVER_TAGS,
        //    keyword: "serverTagsActionCategoryTitle"
        //},
        {
            value: SERVER_METADATA,
            keyword: "serverMetadataActionCategoryTitle"
        }
    ]

    const [addSecGroupSelectionDialogOpen, setAddSecGroupSelectionDialogOpen] = useState(false);
    const [removeSecGroupSelectionDialogOpen, setRemoveSecGroupSelectionDialogOpen] = useState(false);
    const [selectedSecGroup, setSelectedSecGroup] = useState("");
    const [addSecGroups, setAddSecGroups] = useState([])
    const [removeSecGroups, setRemoveSecGroups] = useState([])
    const [newAdminPass, setNewAdminPass] = useState("")
    const [changeAdminPassDialogOpen, setChangeAdminPassDialogOpen] = useState(false)
    const [createSnapshotDialogOpen, setCreateSnapshotDialogOpen] = useState(false)
    const [newSnapshotName, setNewSnapshotName] = useState("")
    const [updateServerSelectionDialogOpen, setUpdateServerSelectionDialogOpen] = useState(false);
    const [serverUpdateOptions, setServerUpdateOptions] = useState({})
    const [serverUpdateData, setServerUpdateData] = useState({})
    const [serverInterfaceSelectionDialogOpen, setServerInterfaceSelectionDialogOpen] = useState(false)
    const [serverInterfaceOptions, setServerInterfaceOptions] = useState({})
    const [serverInterfaceData, setServerInterfaceData] = useState({})
    const [serverInterfaceRemoveDialogOpen, setServerInterfaceRemoveDialogOpen] = useState(false)
    const [selectedInterface, setSelectedInterface] = useState("")
    const [attachedServerInterfaces, setAttachedServerInterfaces] = useState([])
    const [serverVolumeSelectionDialogOpen, setServerVolumeSelectionDialogOpen] = useState(false)
    const [serverVolumeOptions, setServerVolumeOptions] = useState({})
    const [serverVolumeData, setServerVolumeData] = useState({})
    const [serverVolumeRemoveDialogOpen, setServerVolumeRemoveDialogOpen] = useState(false)
    const [selectedVolume, setSelectedVolume] = useState("")
    const [attachedServerVolumes, setAttachedServerVolumes] = useState([])
    const [serverResizeSelectionDialogOpen, setServerResizeSelectionDialogOpen] = useState(false)
    const [serverResizeOptions, setServerResizeOptions] = useState({})
    const [serverResizeData, setServerResizeData] = useState({})
    const [selectedMetadata, setSelectedMetadata] = useState([])
    const [serverMetadataDialogOpen, setServerMetadataDialogOpen] = useState(false);

    const handleAddSecGroupSelectDialogOpen = () => {
        setAddSecGroupSelectionDialogOpen(true)
    }

    const handleRemoveSecGroupSelectDialogOpen = () => {
        setRemoveSecGroupSelectionDialogOpen(true)
    }

    const handleAddSecGroupSelectDialogClose = () => {
        setSelectedSecGroup("")
        setAddSecGroupSelectionDialogOpen(false)
    }

    const handleRemoveSecGroupSelectDialogClose = () => {
        setSelectedSecGroup("")
        setRemoveSecGroupSelectionDialogOpen(false)
    }

    const handleChangeAdminPassDialogOpen = () => {
        setChangeAdminPassDialogOpen(true)
    }

    const handleChangeAdminPassDialogClose = () => {
        setNewAdminPass("")
        setChangeAdminPassDialogOpen(false)
    }

    const handleCreateSnapshotDialogOpen = () => {
        setCreateSnapshotDialogOpen(true)
    }

    const handleCreateSnapshotDialogClose = () => {
        setNewSnapshotName("")
        setCreateSnapshotDialogOpen(false)
    }

    const handleAdminPassValueChange = (event) => {
        setNewAdminPass(event.target.value)
    }

    const handleNewSnapshotNameChange = (event) => {
        setNewSnapshotName(event.target.value)
    }

    const handleUpdateServerSelectDialogOpen = () => {
        setUpdateServerSelectionDialogOpen(true)
    }

    const handleUpdateServerSelectionDialogClose = () => {
        setServerUpdateData({})
        setServerUpdateOptions({})
        setUpdateServerSelectionDialogOpen(false)
    }

    const handleServerInterfaceSelectDialogOpen = () => {
        setServerInterfaceSelectionDialogOpen(true)
    }

    const handleServerInterfaceSelectionDialogClose = () => {
        setServerInterfaceData({})
        setServerInterfaceOptions({})
        setServerInterfaceSelectionDialogOpen(false)
    }

    const handleServerInterfaceRemoveDialogOpen = () => {
        setServerInterfaceRemoveDialogOpen(true)
    }

    const handleServerInterfaceRemoveDialogClose = () => {
        setSelectedInterface("")
        setServerInterfaceRemoveDialogOpen(false)
    }

    const handleServerVolumeSelectDialogOpen = () => {
        setServerVolumeSelectionDialogOpen(true)
    }

    const handleServerVolumeSelectionDialogClose = () => {
        setServerVolumeData({})
        setServerVolumeOptions({})
        setServerVolumeSelectionDialogOpen(false)
    }

    const handleServerVolumeRemoveDialogOpen = () => {
        setServerVolumeRemoveDialogOpen(true)
    }

    const handleServerVolumeRemoveDialogClose = () => {
        setSelectedVolume("")
        setServerVolumeRemoveDialogOpen(false)
    }

    const handleServerResizeSelectDialogOpen = () => {
        setServerResizeSelectionDialogOpen(true)
    }

    const handleServerResizeSelectionDialogClose = () => {
        setServerResizeData({})
        setServerResizeOptions({})
        setServerResizeSelectionDialogOpen(false)
    }

    const handleServerMetadataDialogOpen = () => {
        setServerMetadataDialogOpen(true)
    }

    const handleServerMetadataSelectionDialogClose = () => {
        setSelectedMetadata([])
        setServerMetadataDialogOpen(false)
    }

    const handleSelectedAction = useCallback(async (data,has_response) => {
        setIsLoading(true)
        const url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/${serverData.id}/action`
        const method = "POST"

        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const server_response = await openstackRequest({
                url:url, 
                method:method, 
                data: data,
                token: project_token,
                has_response: has_response
            })
            setIsLoading(false)
            if (server_response.status_code === SUCCESS_STATUS_CODE) {
                if (has_response) {
                    setSuccessResponse(JSON.stringify(server_response.data))
                }
                setCurrentAction("")
                handleSuccessDialogOpen()
                handleDataFetch()
            } else {
                setError(server_response.error)
            }
        }
        setIsLoading(false)
    },[
        computeServiceDomain,
        computeServiceVersion,
        serversUrl,
        serverData,
        handleDataFetch,
        defaultAdminProject
    ])

    const onUnshelveServer = useCallback(async () => {
        let data = {}
        data["unshelve"] = null
        await handleSelectedAction(data,false)
    },[handleSelectedAction])

    useEffect(() => {
        const onServerResizeConfirm = async () => {
            let data = {}
            data["confirmResize"] = null
            await handleSelectedAction(data,false)
        }
        let server_actions = {}
        server_actions[SERVER_ACTIONS] = []
        let new_action = {}
        new_action["value"] = "admin_pass_change"
        new_action["action"] = handleChangeAdminPassDialogOpen
        new_action["keyword"] = "adminPassChangeActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        server_actions[SERVER_ACTIONS].push({...new_action})
        new_action = {}
        new_action["value"] = "add_sec_group"
        new_action["action"] = handleAddSecGroupSelectDialogOpen
        new_action["keyword"] = "addSecGroupActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        server_actions[SERVER_ACTIONS].push({...new_action})
        new_action = {}
        new_action["value"] = "remove_sec_group"
        new_action["action"] = handleRemoveSecGroupSelectDialogOpen
        new_action["keyword"] = "removeSecGroupActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        server_actions[SERVER_ACTIONS].push({...new_action})
        server_actions[SERVER_CHANGE] = []
        new_action = {}
        new_action["value"] = "edit_server"
        new_action["action"] = handleUpdateServerSelectDialogOpen
        new_action["keyword"] = "editServerActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        server_actions[SERVER_CHANGE].push({...new_action})
        new_action = {}
        new_action["value"] = "resize_server"
        new_action["action"] = handleServerResizeSelectDialogOpen
        new_action["keyword"] = "resizeServerActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        server_actions[SERVER_CHANGE].push({...new_action})
        new_action = {}
        new_action["value"] = "confirm_resize_server"
        new_action["action"] = onServerResizeConfirm
        new_action["keyword"] = "confirmResizeServerActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        server_actions[SERVER_CHANGE].push({...new_action})
        //new_action = {}
        //new_action["value"] = "lock_server"
        //new_action["action"] = () => {}
        //new_action["keyword"] = "lockServerActionTitle"
        //new_action["button_text"] = "selectButtonTitleText"
        //server_actions[SERVER_CHANGE].push({...new_action})
        //new_action = {}
        //new_action["value"] = "unlock_server"
        //new_action["action"] = () => {}
        //new_action["keyword"] = "unlockServerActionTitle"
        //new_action["button_text"] = "selectButtonTitleText"
        //server_actions[SERVER_CHANGE].push({...new_action})
        //new_action = {}
        //new_action["value"] = "rebuild_server"
        //new_action["action"] = () => {}
        //new_action["keyword"] = "rebuildServerActionTitle"
        //new_action["button_text"] = "selectButtonTitleText"
        //server_actions[SERVER_CHANGE].push({...new_action})
        //new_action = {}
        //new_action["value"] = "restore_soft_delete"
        //new_action["action"] = () => {}
        //new_action["keyword"] = "restoreSoftDeleteActionTitle"
        //new_action["button_text"] = "selectButtonTitleText"
        //server_actions[SERVER_CHANGE].push({...new_action})
        //new_action = {}
        //new_action["value"] = "force_delete"
        //new_action["action"] = () => {}
        //new_action["keyword"] = "forceDeleteActionTitle"
        //new_action["button_text"] = "selectButtonTitleText"
        //server_actions[SERVER_CHANGE].push({...new_action})
        //new_action = {}
        //new_action["value"] = "shelve_server"
        //new_action["action"] = () => {}
        //new_action["keyword"] = "shelveServerActionTitle"
        //new_action["button_text"] = "selectButtonTitleText"
        //server_actions[SERVER_CHANGE].push({...new_action})
        if (serverData.status === "shelved") {
            new_action = {}
            new_action["value"] = "unshelve_server"
            new_action["action"] = onUnshelveServer
            new_action["keyword"] = "unshelveServerActionTitle"
            new_action["button_text"] = "selectButtonTitleText"
            server_actions[SERVER_CHANGE].push({...new_action})
        }
        //new_action = {}
        //new_action["value"] = "shelf_offload_server"
        //new_action["action"] = () => {}
        //new_action["keyword"] = "shelfOffloadServerActionTitle"
        //new_action["button_text"] = "selectButtonTitleText"
        //server_actions[SERVER_CHANGE].push({...new_action})
        //new_action = {}
        //new_action["value"] = "migrate_server"
        //new_action["action"] = () => {}
        //new_action["keyword"] = "migrateServerActionTitle"
        //new_action["button_text"] = "selectButtonTitleText"
        //server_actions[SERVER_CHANGE].push({...new_action})
        //new_action = {}
        //new_action["value"] = "live_migrate_server"
        //new_action["action"] = () => {}
        //new_action["keyword"] = "liveMigrateServerActionTitle"
        //new_action["button_text"] = "selectButtonTitleText"
        //server_actions[SERVER_CHANGE].push({...new_action})
        server_actions[SERVER_BACKUP] = []
        new_action = {}
        new_action["value"] = "create_snapshot"
        new_action["action"] = handleCreateSnapshotDialogOpen
        new_action["keyword"] = "serverCreateSnapshotActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        server_actions[SERVER_BACKUP].push({...new_action})
        server_actions[SERVER_VOLUMES] = []
        new_action = {}
        new_action["value"] = "attach_volume"
        new_action["action"] = handleServerVolumeSelectDialogOpen
        new_action["keyword"] = "attachVolumeActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        server_actions[SERVER_VOLUMES].push({...new_action})
        new_action = {}
        new_action["value"] = "detach_volume"
        new_action["action"] = handleServerVolumeRemoveDialogOpen
        new_action["keyword"] = "detachVolumeActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        server_actions[SERVER_VOLUMES].push({...new_action})
        server_actions[SERVER_INTERFACES] = []
        new_action = {}
        new_action["value"] = "attach_interface"
        new_action["action"] = handleServerInterfaceSelectDialogOpen
        new_action["keyword"] = "attachIntefaceActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        server_actions[SERVER_INTERFACES].push({...new_action})
        new_action = {}
        new_action["value"] = "detach_interface"
        new_action["action"] = handleServerInterfaceRemoveDialogOpen
        new_action["keyword"] = "detachInerfaceActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        server_actions[SERVER_INTERFACES].push({...new_action})
        //server_actions[SERVER_TAGS] = []
        //new_action = {}
        //new_action["value"] = "add_tag"
        //new_action["action"] = () => {}
        //new_action["keyword"] = "addTagActionTitle"
        //new_action["button_text"] = "selectButtonTitleText"
        //server_actions[SERVER_TAGS].push({...new_action})
        //new_action = {}
        //new_action["value"] = "delete_tag"
        //new_action["action"] = () => {}
        //new_action["keyword"] = "deleteTagActionTitle"
        //new_action["button_text"] = "selectButtonTitleText"
        //server_actions[SERVER_TAGS].push({...new_action})
        server_actions[SERVER_METADATA] = []
        new_action = {}
        new_action["value"] = "create_update_metadata"
        new_action["action"] = handleServerMetadataDialogOpen
        new_action["keyword"] = "createUpdateMetadataActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        server_actions[SERVER_METADATA].push({...new_action})

        setServerActions(server_actions)
    },[
        serverData,
        handleSelectedAction,
        onUnshelveServer
    ])

    const handleSelectCurrentActionCategory = (value) => {
        setCurrentAction("")
        setCurrentActionCategory(value)
    }

    const getDataForm = (form,form_options,data,onDataChange) => {
        return (
            <FormGroup>
                {form.map(field => {
                    return (
                        getFormFieldComponent(
                            field,
                            data,
                            onDataChange,
                            defaultTexts[field.label],
                            {...form_options[field.field_key]}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const getServerInterfaceDataForm = () => {
        return (
            <FormGroup>
                {serverInterfaceForm.map(field => {
                    let form_field_options = {...serverInterfaceOptions[field.field_key]}
                    if (field.field_key === "net_id") {
                            const net_filter = networks.map(net => {
                                const filtered_subnets = subnets.filter(item => item.network_id === net.id)
                                const only_cidr = filtered_subnets.map(item => item.cidr)
                                const sub_string = only_cidr.join(", ")
                                return {keyword: `${net.name} (${sub_string})`, value: net.id, default: false}
                            })
                            form_field_options["items"] = [...net_filter]
                            form_field_options["empty"] = field.default_empty
                            form_field_options["self_item_titles"] = field.self_item_titles
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            serverInterfaceData,
                            handleServerInterfaceDataChange,
                            defaultTexts[field.label],
                            {size:"medium",
                                sx: {
                                    my: 1,
                                    width: '100%'
                                },
                                ...form_field_options
                            }
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const getServerVolumeDataForm = () => {
        return (
            <FormGroup>
                {serverVolumeForm.map(field => {
                    let form_field_options = {...serverVolumeOptions[field.field_key]}
                    if (field.field_key === "volumeId") {
                            const vol_filtered = volumes.filter(vol => vol.status === "available" && vol.attachments.length === 0)
                            const volume_list = vol_filtered.map(v => {
                                return {keyword: v.name.length > 0 ? v.name : v.id, value: v.id, default: false}
                            })
                            form_field_options["items"] = [...volume_list]
                            form_field_options["empty"] = field.default_empty
                            form_field_options["self_item_titles"] = field.self_item_titles
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            serverVolumeData,
                            handleServerVolumeDataChange,
                            defaultTexts[field.label],
                            {size:"medium",
                                sx: {
                                    my: 1,
                                    width: '100%'
                                },
                                ...form_field_options
                            }
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const getServerResizeDataForm = () => {
        return (
            <FormGroup>
                {serverResizeForm.map(field => {
                    let form_field_options = {...serverResizeOptions[field.field_key]}
                    if (field.field_key === "OS-DCF:diskConfig") {
                            form_field_options["items"] = [...field.items]
                            form_field_options["empty"] = field.default_empty
                            form_field_options["self_item_titles"] = false
                            form_field_options["item_titles"] = defaultTexts
                    } else if (field.field_key === "flavorRef") {
                        const used_flavor = flavors.filter(fl => fl.id === serverData.flavor.id)[0]
                        const filtered_flavors = flavors.filter(fl => fl.id !== serverData.flavor.id && fl.disk >= used_flavor.disk)
                        const flavor_list = filtered_flavors.map(f => {
                            return {keyword: `${f.name} (vCPUs: ${f.vcpus}, RAM: ${f.ram} MB, Disk: ${f.disk})`, value: f.id, default: false}
                        })
                        form_field_options["items"] = [...flavor_list]
                        form_field_options["empty"] = field.default_empty
                        form_field_options["self_item_titles"] = field.self_item_titles
                }
                    return (
                        getFormFieldComponent(
                            field,
                            serverResizeData,
                            handleServerResizeDataChange,
                            defaultTexts[field.label],
                            {size:"medium",
                                sx: {
                                    my: 1,
                                    width: '100%'
                                },
                                ...form_field_options
                            }
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleServerMetadataFieldsChange = (event,field_key_list) => {
        let new_extra_data = [...selectedMetadata]
        if (field_key_list[1] === "key") {
            new_extra_data[field_key_list[0]].field_key = event.target.value
        } else {
            new_extra_data[field_key_list[0]].field_value = event.target.value
        }
        setSelectedMetadata(new_extra_data)
    }

    const handleServerMetadataFieldsRemove = (index) => {
        if (selectedMetadata.length > 1) {
            let updated_data = [...selectedMetadata]
            updated_data.splice(index, 1)
            setSelectedMetadata(updated_data)
        } else {
            setSelectedMetadata([])
        }
    }

    const onServerMetadataAdd = async () => {
        let updated_data = {}
        for (let i in selectedMetadata) {
            if (selectedMetadata[i].field_key.length > 0) {
                updated_data[selectedMetadata[i].field_key] = selectedMetadata[i].field_value
            }
        }
        if (updated_data) {
            setIsLoading(true)
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/${serverData.id}/metadata`
                const method = "POST"
        
                const metadata_response = await openstackRequest({
                    url: url, 
                    method: method, 
                    data: {metadata: updated_data},
                    token: project_token
                })
                setIsLoading(false)
                handleServerMetadataSelectionDialogClose()
                if (metadata_response.status_code === serversUrlResponses.post.success_response.status_code) {
                    setSuccessResponse(defaultTexts.serverUpdateSuccessResponseMessage)
                    setCurrentAction("")
                    
                    handleSuccessDialogOpen()
                    handleDataFetch()
                } else {
                    setError(metadata_response.error)
                }
            }
            setIsLoading(false)
        }
    }

    const getMetadataFormComponents = () => {
        return (
            <React.Fragment>
                <Stack 
                    direction="row" 
                    spacing={2} 
                    alignItems="center"
                    sx={{my: 3}}
                >
                    <CustomText size="h6">
                        {defaultTexts.addMetadataFormFieldLabel}
                    </CustomText>
                    <PlusButton 
                        onClick={() => setSelectedMetadata([
                            ...selectedMetadata,
                            {field_key: "",field_value: ""}
                        ])} 
                    />
                </Stack>
                {selectedMetadata.map((item,index) => {
                    return (
                        <Stack
                            key={index}
                            direction="row" 
                            spacing={2} 
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{my: 1}}
                        >
                            <CustomTextField
                                currentValue={item.field_key} 
                                setCurrentValue={handleServerMetadataFieldsChange}
                                field_key={[index,"key"]}
                                label={defaultTexts.keyFormFieldLabel}
                            />
                            <CustomTextField
                                currentValue={item.field_value} 
                                setCurrentValue={handleServerMetadataFieldsChange}
                                field_key={[index,"value"]}
                                label={defaultTexts.valueFormFieldLabel}
                            />
                            <MinusButton 
                                sx={{width: 90}} 
                                onClick={() => handleServerMetadataFieldsRemove(index)}
                            />
                        </Stack>
                    )
                })}
            </React.Fragment>
        )
    }

    const handleSuccessDialogOpen = () => {
        setSuccessDialogOpen(true);
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }
    const handleSuccessDialogClose = () => {
        setSuccessResponse(null);
        setSuccessDialogOpen(false);
    }

    const onAddSecGroup = async () => {
        let data = {}
        data["addSecurityGroup"] = {}
        data["addSecurityGroup"]["name"] = selectedSecGroup
        handleAddSecGroupSelectDialogClose()
        await handleSelectedAction(data,false)
    }

    const onRemoveSecGroup = async () => {
        let data = {}
        data["removeSecurityGroup"] = {}
        data["removeSecurityGroup"]["name"] = selectedSecGroup
        handleRemoveSecGroupSelectDialogClose()
        await handleSelectedAction(data,false)
    }

    const onAdminPassChange = async () => {
        let data = {}
        data["changePassword"] = {}
        data["changePassword"]["adminPass"] = newAdminPass
        handleChangeAdminPassDialogClose()
        await handleSelectedAction(data,false)
    }

    const onSnapshotCreate = async () => {
        let data = {}
        data["createImage"] = {}
        data["createImage"]["name"] = newSnapshotName
        handleCreateSnapshotDialogClose()
        await handleSelectedAction(data,false)
    }

    const onServerResize = async () => {
        let data = {}
        data["resize"] = {...serverResizeData}
        handleServerResizeSelectionDialogClose()
        await handleSelectedAction(data,false)
    }

    const handleServerUpdateValidation = () => {
        const checkIPAddress = (ip) => { 
            const ipv4Pattern =  
                /^(\d{1,3}\.){3}\d{1,3}$/; 
            const ipv6Pattern =  
                /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/; 
            return ipv4Pattern.test(ip) || ipv6Pattern.test(ip); 
        } 
        let validation_faild = true
        let updatedDataFormOptions = {...serverUpdateOptions}
        for (let n in serverUpdateForm) {
            if (serverUpdateForm[n].field_key === "accessIPv4" || serverUpdateForm[n].field_key === "accessIPv6") {
                if (serverUpdateData[serverUpdateForm[n].field_key].length > 0) {
                    const valid_ip = checkIPAddress(serverUpdateData[serverUpdateForm[n].field_key])
                    if (!valid_ip) {
                        validation_faild = validation_faild && valid_ip
                        updatedDataFormOptions[serverUpdateForm[n].field_key] = {}
                        updatedDataFormOptions[serverUpdateForm[n].field_key]["error"] = true
                        updatedDataFormOptions[serverUpdateForm[n].field_key]["errorText"] = defaultTexts.invalidIPAddressFormError
                    }
                }
            }
        }
        setServerUpdateOptions(updatedDataFormOptions)
        return validation_faild
    }

    const onServerUpdate = async () => {
        const validateFormData = handleServerUpdateValidation()
        if (validateFormData) {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const update_data =  handleEmptyFields(serverUpdateForm, serverUpdateData)
                const url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/${serverData.id}`
                const method = "PUT"
                const server_response = await openstackRequest({
                    url:url, 
                    method:method, 
                    data: {server: update_data},
                    token: project_token
                })
                if (server_response.status_code === serversUrlResponses.post.success_response.status_code) {
                    setSuccessResponse(defaultTexts.serverUpdateSuccessResponseMessage)
                    setCurrentAction("")
                    handleUpdateServerSelectionDialogClose()
                    handleSuccessDialogOpen()
                    handleDataFetch()
                } else {
                    setError(server_response.error)
                }
            }
        }
        return validateFormData
    }

    const handleServerInterfaceValidation = () => {
        const checkIPAddress = (ip) => { 
            const ipv4Pattern =  
                /^(\d{1,3}\.){3}\d{1,3}$/; 
            const ipv6Pattern =  
                /^([0-9a-fA-F]{1,4}:){7}[0-9a-fA-F]{1,4}$/; 
            return ipv4Pattern.test(ip) || ipv6Pattern.test(ip); 
        } 
        let validation_faild = true
        let updatedDataFormOptions = {...serverInterfaceOptions}
        for (let n in serverInterfaceForm) {
            if (serverInterfaceForm[n].required && serverInterfaceData[serverInterfaceForm[n].field_key].length === 0) {
                validation_faild = false
                updatedDataFormOptions[serverInterfaceForm[n].field_key] = {}
                updatedDataFormOptions[serverInterfaceForm[n].field_key]["error"] = true
                updatedDataFormOptions[serverInterfaceForm[n].field_key]["errorText"] = defaultTexts[serverInterfaceForm[n].error_label]
            }
            if (serverInterfaceForm[n].field_key === "fixed_ips" && serverInterfaceData.fixed_ips.length > 0) {
                const valid_ip = checkIPAddress(serverInterfaceData[serverInterfaceForm[n].field_key])
                if (!valid_ip) {
                    validation_faild = false
                    updatedDataFormOptions["fixed_ips"] = {}
                    updatedDataFormOptions["fixed_ips"]["error"] = true
                    updatedDataFormOptions["fixed_ips"]["errorText"] = defaultTexts.invalidIPAddressFormError
                }
            }
        }
        setServerInterfaceOptions(updatedDataFormOptions)
        return validation_faild
    }

    const onServerInterfaceAttach = async () => {
        const validateFormData = handleServerInterfaceValidation()
        if (validateFormData) {
            handleServerInterfaceSelectionDialogClose()
            setIsLoading(true)
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let update_data =  {
                    ...serverInterfaceData
                }
                if (serverInterfaceData.fixed_ips.length > 0) {
                    update_data["fixed_ips"] = [{ip_address: serverInterfaceData.fixed_ips}]
                } else {
                    delete update_data["fixed_ips"]
                }
                const url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/${serverData.id}/os-interface`
                const method = "POST"
                const server_response = await openstackRequest({
                    url:url, 
                    method:method, 
                    data: {interfaceAttachment: update_data},
                    token: project_token
                })
                setIsLoading(false)
                if (server_response.status_code === serversUrlResponses.post.success_response.status_code) {
                    setSuccessResponse(defaultTexts.serverUpdateSuccessResponseMessage)
                    setCurrentAction("")
                    handleSuccessDialogOpen()
                    handleDataFetch()
                } else {
                    setError(server_response.error)
                }
            }
            setIsLoading(false)
        }
        return validateFormData
    }

    const onServerInterfaceRemove = async () => {
        if (selectedInterface.length > 0) {
            setIsLoading(true)
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                handleServerInterfaceRemoveDialogClose()
                const url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/${serverData.id}/os-interface/${selectedInterface}`
                const method = "DELETE"
                const server_response = await openstackRequest({
                    url:url, 
                    method:method, 
                    token: project_token
                })
                setIsLoading(false)
                if (server_response.status_code === serversUrlResponses.delete.success_response.status_code) {
                    setSuccessResponse(defaultTexts.serverUpdateSuccessResponseMessage)
                    setCurrentAction("")
                    handleSuccessDialogOpen()
                    handleDataFetch()
                } else {
                    setError(server_response.error)
                }
            }
            setIsLoading(false)
        }
    }

    const handleServerVolumeValidation = () => {
        let validation_faild = true
        let updatedDataFormOptions = {...serverVolumeOptions}
        for (let n in serverVolumeForm) {
            if (serverVolumeForm[n].required && serverVolumeData[serverVolumeForm[n].field_key].length === 0) {
                validation_faild = false
                updatedDataFormOptions[serverVolumeForm[n].field_key] = {}
                updatedDataFormOptions[serverVolumeForm[n].field_key]["error"] = true
                updatedDataFormOptions[serverVolumeForm[n].field_key]["errorText"] = defaultTexts[serverVolumeForm[n].error_label]
            }
        }
        setServerVolumeOptions(updatedDataFormOptions)
        return validation_faild
    }

    const onServerVolumeAttach = async () => {
        const validateFormData = handleServerVolumeValidation()
        if (validateFormData) {
            handleServerVolumeSelectionDialogClose()
            setIsLoading(true)
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/${serverData.id}/os-volume_attachments`
                const method = "POST"
                const server_response = await computeNovaRequest({
                    url:url, 
                    method:method, 
                    data: {volumeAttachment: serverVolumeData},
                    token: project_token
                })
                setIsLoading(false)
                if (server_response.status_code === serversUrlResponses.post.success_response.status_code) {
                    setSuccessResponse(defaultTexts.serverUpdateSuccessResponseMessage)
                    setCurrentAction("")
                    handleSuccessDialogOpen()
                    handleDataFetch()
                } else {
                    setError(server_response.error)
                }
            }
            setIsLoading(false)
        }
        return validateFormData
    }

    const onServerVolumeRemove = async () => {
        if (selectedVolume.length > 0) {
            setIsLoading(true)
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                handleServerVolumeRemoveDialogClose()
                const url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/${serverData.id}/os-volume_attachments/${selectedVolume}`
                const method = "DELETE"
                const server_response = await openstackRequest({
                    url:url, 
                    method:method, 
                    token: project_token
                })
                setIsLoading(false)
                if (server_response.status_code === serversUrlResponses.delete.success_response.status_code) {
                    setSuccessResponse(defaultTexts.serverUpdateSuccessResponseMessage)
                    setCurrentAction("")
                    handleSuccessDialogOpen()
                    handleDataFetch()
                } else {
                    setError(server_response.error)
                }
            }
            setIsLoading(false)
        }
    }

    const handleServerUpdateDataChange = (event,field_key) => {
        let new_form_data = {...serverUpdateData}
        if (serverUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else {
            new_form_data[field_key] = event.target.value
        }
        setServerUpdateData(new_form_data)
    }

    const handleServerInterfaceDataChange = (event,field_key) => {
        let new_form_data = {...serverInterfaceData}
        if (serverInterfaceForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (serverInterfaceForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setServerInterfaceData(new_form_data)
    }

    const handleServerVolumeDataChange = (event,field_key) => {
        let new_form_data = {...serverVolumeData}
        if (serverVolumeForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (serverVolumeForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setServerVolumeData(new_form_data)
    }

    const handleServerResizeDataChange = (event,field_key) => {
        let new_form_data = {...serverResizeData}
        if (serverResizeForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (serverResizeForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setServerResizeData(new_form_data)
    }

    useEffect(() => {
        if (Object.keys(serverUpdateData).length === 0) {
            let new_form_data = {}
            for (const n in serverUpdateForm) {
                if (serverUpdateForm[n].field_type === "string") {
                    new_form_data[serverUpdateForm[n].field_key] = ""
                } else if (serverUpdateForm[n].field_type === "bool") {
                    new_form_data[serverUpdateForm[n].field_key] = serverUpdateForm[n].default_value ? 
                    serverUpdateForm[n].default_value : 
                    false
                }
            }
            setServerUpdateData(new_form_data)
        }
    },[serverUpdateData]);

    useEffect(() => {
        if (Object.keys(serverInterfaceData).length === 0) {
            let new_form_data = {}
            for (const n in serverInterfaceForm) {
                if (serverInterfaceForm[n].field_type === "string" ||
                    serverInterfaceForm[n].field_type === "select"
                    ) {
                    new_form_data[serverInterfaceForm[n].field_key] = ""
                } else if (serverInterfaceForm[n].field_type === "bool") {
                    new_form_data[serverInterfaceForm[n].field_key] = serverInterfaceForm[n].default_value ? 
                    serverInterfaceForm[n].default_value : 
                    false
                }
            }
            setServerInterfaceData(new_form_data)
        }
    },[serverInterfaceData]);

    useEffect(() => {
        if (Object.keys(serverVolumeData).length === 0) {
            let new_form_data = {}
            for (const n in serverVolumeForm) {
                if (serverVolumeForm[n].field_type === "string" ||
                    serverVolumeForm[n].field_type === "select"
                    ) {
                    new_form_data[serverVolumeForm[n].field_key] = ""
                } else if (serverVolumeForm[n].field_type === "bool") {
                    new_form_data[serverVolumeForm[n].field_key] = serverVolumeForm[n].default_value ? 
                    serverVolumeForm[n].default_value : 
                    false
                }
            }
            setServerVolumeData(new_form_data)
        }
    },[serverVolumeData]);

    useEffect(() => {
        const sec_group_ids = serverSecGroups.map(gr => gr.id)
        const add_sec_groups = secGroups.filter(
            item => !sec_group_ids.includes(item.id))
        const add_sec_groups_list = add_sec_groups.map(gr => 
            {
                return {keyword: gr.name, value: gr.name, default: false}
            }
        )
        setAddSecGroups(add_sec_groups_list)
    },[
        secGroups,
        serverSecGroups,
        serverData
    ]);

    useEffect(() => {
        const remove_sec_groups_list = serverSecGroups.map(gr => 
            {
                return {keyword: gr.name, value: gr.name, default: false}
            }
        )
        setRemoveSecGroups(remove_sec_groups_list)
    },[
        serverSecGroups
    ])

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error,serverData]);

    useEffect(() => {
        if (attachedInterfaces && attachedInterfaces.length > 0) {
            const updated_data = attachedInterfaces.map(item => {
                const fixed_ips = item.fixed_ips.map(p => p.ip_address)
                return {keyword: `${fixed_ips.join(", ")}`, value: item.port_id, default: false}
            })
            setAttachedServerInterfaces(updated_data)
        }
    },[
        attachedInterfaces
    ]);
    
    useEffect(() => {
        if (attachedVolumes && attachedVolumes.length > 0) {
            const updated_data = attachedVolumes.map(item => {
                return {keyword: item.device, value: item.volumeId, default: false}
            })
            setAttachedServerVolumes(updated_data)
        }
    },[
        attachedVolumes
    ]);

    return (
        <Grid container alignItems="center" justifyContent="flex-start">
            {isLoading && <CustomBackdrop open={isLoading} />}
            <Grid item>
            <CustomSelectField 
                items={serverActionCategories}
                currentValue={currentActionCategory}
                setCurrentValue={handleSelectCurrentActionCategory}
                item_titles={defaultTexts}
                label={defaultTexts.actionCategoryDropdownLabelText}
                empty={true}
                size="small"
                sx={{m: 1}}
            />
            </Grid>
            <Grid item>
            {serverActions[currentActionCategory] && <CustomSelectField 
                items={serverActions[currentActionCategory]}
                currentValue={currentAction}
                setCurrentValue={setCurrentAction}
                item_titles={defaultTexts}
                label={defaultTexts.actionsDropdownLabelText}
                empty={true}
                size="small"
                sx={{m: 1}}
            />}
            </Grid>
            <Grid item>
            {currentAction.length > 0 && 
                <Button 
                        variant="contained"
                        color="secondary"
                        sx={{m: 1}}
                        onClick={serverActions[currentActionCategory].filter(
                            action => action.value === currentAction)[0].action
                        }
                    >
                    {defaultTexts[serverActions[currentActionCategory].filter(
                        action => action.value === currentAction)[0].button_text]}
                </Button>
            }
            </Grid>
            <CustomDialog
                open={addSecGroupSelectionDialogOpen}
                onClose={handleAddSecGroupSelectDialogClose}
                dialogTitle={{
                    title: defaultTexts.addSecGroupActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.addSecGroupActionDialogMessage, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onAddSecGroup, 
                    sx: {color: 'primary.main'}}]}
            >
                <CustomSelectField
                    items={addSecGroups} 
                    required={true}
                    currentValue={selectedSecGroup}
                    setCurrentValue={setSelectedSecGroup}
                    label={defaultTexts.securityGroupFormFieldLabel}
                    self_item_titles={true}
                    empty={true}
                    size="large"
                    sx={{my: 1, width: '90%'}}
                />
            </CustomDialog>
            <CustomDialog
                open={removeSecGroupSelectionDialogOpen}
                onClose={handleRemoveSecGroupSelectDialogClose}
                dialogTitle={{
                    title: defaultTexts.removeSecGroupActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.removeSecGroupActionDialogMessage, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onRemoveSecGroup, 
                    sx: {color: 'primary.main'}}]}
            >
                <CustomSelectField
                    items={removeSecGroups} 
                    required={true}
                    currentValue={selectedSecGroup}
                    setCurrentValue={setSelectedSecGroup}
                    label={defaultTexts.securityGroupFormFieldLabel}
                    self_item_titles={true}
                    empty={true}
                    size="large"
                    sx={{my: 1, width: '90%'}}
                />
            </CustomDialog>
            <CustomDialog
                open={changeAdminPassDialogOpen}
                onClose={handleChangeAdminPassDialogClose}
                dialogTitle={{
                    title: defaultTexts.changeAdminPassActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onAdminPassChange, 
                    sx: {color: 'primary.main'}}]}
            >
                <CustomTextField
                    required={true}
                    currentValue={newAdminPass}
                    setCurrentValue={handleAdminPassValueChange}
                    label={defaultTexts.adminPassFormFieldLabel}
                    size="large"
                    sx={{my: 1, width: '90%'}}
                />
            </CustomDialog>
            <CustomDialog
                open={createSnapshotDialogOpen}
                onClose={handleCreateSnapshotDialogClose}
                dialogTitle={{
                    title: defaultTexts.serverCreateSnapshotActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onSnapshotCreate, 
                    sx: {color: 'primary.main'}}]}
            >
                <CustomTextField
                    required={true}
                    currentValue={newSnapshotName}
                    setCurrentValue={handleNewSnapshotNameChange}
                    label={defaultTexts.nameFormFieldLabel}
                    size="large"
                    sx={{my: 1, width: '90%'}}
                />
            </CustomDialog>
            <CustomDialog
                open={updateServerSelectionDialogOpen}
                onClose={handleUpdateServerSelectionDialogClose}
                dialogTitle={{
                    title: defaultTexts.updateServerActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onServerUpdate, 
                    sx: {color: 'primary.main'}}]}
            >
                {getDataForm(
                    serverUpdateForm,
                    serverUpdateOptions,
                    serverUpdateData,
                    handleServerUpdateDataChange
                )}
            </CustomDialog>
            <CustomDialog
                open={serverInterfaceSelectionDialogOpen}
                onClose={handleServerInterfaceSelectionDialogClose}
                dialogTitle={{
                    title: defaultTexts.serverInterfaceAttachActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onServerInterfaceAttach, 
                    sx: {color: 'primary.main'}}]}
            >
                {getServerInterfaceDataForm()}
            </CustomDialog>
            <CustomDialog
                open={serverInterfaceRemoveDialogOpen}
                onClose={handleServerInterfaceRemoveDialogClose}
                dialogTitle={{
                    title: defaultTexts.removeNetworkInterfaceActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.removeNetworkInterfaceActionDialogMessage, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onServerInterfaceRemove, 
                    sx: {color: 'primary.main'}}]}
            >
                <CustomSelectField
                    items={attachedServerInterfaces} 
                    required={true}
                    currentValue={selectedInterface}
                    setCurrentValue={setSelectedInterface}
                    label={defaultTexts.networkInterfaceFormFieldLabel}
                    self_item_titles={true}
                    empty={true}
                    size="large"
                    sx={{my: 1, width: '90%'}}
                />
            </CustomDialog>
            <CustomDialog
                open={serverVolumeSelectionDialogOpen}
                onClose={handleServerVolumeSelectionDialogClose}
                dialogTitle={{
                    title: defaultTexts.serverVolumeAttachActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onServerVolumeAttach, 
                    sx: {color: 'primary.main'}}]}
            >
                {getServerVolumeDataForm()}
            </CustomDialog>
            <CustomDialog
                open={serverVolumeRemoveDialogOpen}
                onClose={handleServerVolumeRemoveDialogClose}
                dialogTitle={{
                    title: defaultTexts.removeVolumeActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.removeVolumeActionDialogMessage, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onServerVolumeRemove, 
                    sx: {color: 'primary.main'}}]}
            >
                <CustomSelectField
                    items={attachedServerVolumes} 
                    required={true}
                    currentValue={selectedVolume}
                    setCurrentValue={setSelectedVolume}
                    label={defaultTexts.volumeFormFieldLabel}
                    self_item_titles={true}
                    empty={true}
                    size="large"
                    sx={{my: 1, width: '90%'}}
                />
            </CustomDialog>
            <CustomDialog
                open={serverResizeSelectionDialogOpen}
                onClose={handleServerResizeSelectionDialogClose}
                dialogTitle={{
                    title: defaultTexts.serverResizeActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onServerResize, 
                    sx: {color: 'primary.main'}}]}
            >
                {getServerResizeDataForm()}
            </CustomDialog>
            <CustomDialog
                open={serverMetadataDialogOpen}
                onClose={handleServerMetadataSelectionDialogClose}
                dialogTitle={{
                    title: defaultTexts.serverMetadataAddActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onServerMetadataAdd, 
                    sx: {color: 'primary.main'}}]}
            >
                {getMetadataFormComponents()}
            </CustomDialog>
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts.failedActionErrorDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts.failedActionErrorDialogMessage}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                            <span style="color: orange">
                                ${error}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts.successActionDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: successResponse ?
                        `<span>${defaultTexts.successActionDialogMessage}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.responseNoteDialogText}:</span> 
                            <span style="width: 100px; color: orange; word-wrap: break-word;">
                                ${successResponse}
                            </span>` :
                        `<span>${defaultTexts.successActionDialogMessage}</span>`, 
                    sx: {color: 'text.primary'}
                }}
            />
        </Grid>
    )
};

export default ComputeServerActionsV21;
