import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import CustomCard from '../../../_common/CustomCard';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { HiOutlineArrowsUpDown } from "react-icons/hi2";
import { 
    openstackRequest,
    getXAuthTokenProjectScope } from 
'../../../../_network/openstack_request';
import CustomText from '../../../_common/CustomText';
import { routersUrl as routerUrlResponses } from 
'../../../../_api_responses/openstack/neutron/routers/v2.0';
import { 
    networkNeutronConstants,
    openStackServices
} from '../../../../config/openStackConstants';

const SERVICE_NAME = openStackServices.networkService

const RoutersGraphContentV20 = (props) => {
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [routersData, setRoutersData] = useState([]);

    const navigate = useNavigate();
    const theme = useTheme();

    if (!isAuthenticated) {
        navigate('/');
    }

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const routersUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === neutronServiceVersion)[0].urls.filter(
                url => url.keyword === networkNeutronConstants.routersUrl)[0].url)

    const purchasedServices = useSelector(state => 
        state.openstack.purchasedServices);

    const purchasedService = purchasedServices.filter(
        item => item.service === SERVICE_NAME)


    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${neutronServiceDomain}/${neutronServiceVersion}/${routersUrl}`
                    const method = "GET"
                    const router_response = await openstackRequest({url:url, method:method, token: project_token})
                    if (router_response.status_code === routerUrlResponses.get.success_response.status_code) {
                        setRoutersData(router_response.data.routers)
                    }
                }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        routersUrl, 
        defaultAdminProject
    ]);

    if (purchasedService[0].config_params.api_version === "v2.0") {
        return (
            <CustomCard 
                cardWidth={'100%'} 
                cardHeight={120} 
                card_sx={{
                    border: theme.palette.mode === "dark" ? 0 : 1, 
                    boxShadow: theme.palette.mode === "dark" ? 0 : 1, 
                    backgroundColor: 
                        theme.palette.mode === "dark" ? 
                        undefined :
                        "vLightGray"
                }}
                box_sx={{pr: 2}}
            >
                <Stack
                    direction="row" 
                    alignItems="center" 
                    justifyContent="space-between"
                    spacing={0}
                >
                <Box sx={{
                    display: "flex", 
                    alignItems: "center", 
                    justifyContent: "center", 
                    borderRadius: 80, 
                    border: 1, 
                    py: 2, 
                    px: 2, 
                    m: 1}}
                >
                    <HiOutlineArrowsUpDown  
                        size={28}
                    />
                </Box>
                <Stack
                    direction="column" 
                    alignItems="end" 
                    justifyContent="center"
                    spacing={0}
                >
                    <CustomText 
                        size="h6" 
                        sx={{
                            fontSize: 28,
                            color: theme.palette.mode === "dark" ? 
                            theme.palette.dashboardPinkDark :
                            theme.palette.dashboardPink
                        }}
                    >
                        {routersData.length}
                    </CustomText>
                    <CustomText 
                        size="p" 
                        sx={{fontSize: 18}}
                    >
                        {defaultTexts.totalRoutersGraphTitle}
                    </CustomText>
                </Stack>
                </Stack>
            </CustomCard>
        )
    }
};

export default RoutersGraphContentV20;