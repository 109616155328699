import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import CustomDialog from '../../../../_common/CustomDialog';
import useWindowDimensions from '../../../../_common/WindowDimensions';
import Dimensions from '../../../../../config/dimensions';
import CustomSideDrawer from '../../../../_common/CustomSideDrawer';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import { openStackServices, 
        blockStorageCinderConstants,
        identityKeystonConstants 
} from '../../../../../config/openStackConstants';
import VolumeTypesSubheaderV3 from './volumeTypesSubheaderV3';
import QoSSpecsSubheaderV3 from './qosSpecsSubheaderV3';
import DefaultVolumeTypesSubheaderV3 from './defaultVolumeTypesSubhedarV3';
import VolumeTypesTableV3 from './volumeTypesTableV3';
import QoSSpecsTableV3 from './qosSpecsTableV3';
import DefaultVolumeTypesTableV3 from './defaultVolumeTypesTableV3';
import { volumeTypesFilterMenu, defaultVolumeTypesFilterMenu } 
from '../../../../../_data/openstack/cinder/volume_types/v3';
import { volumeCinderRequest, getXAuthTokenProjectScope, openstackRequest } from 
'../../../../../_network/openstack_request';
import { volumesUrl as volumeUrlResponses } 
from '../../../../../_api_responses/openstack/cinder/volumes/v3';
import VolumeTypeDetailV3 from './volumeTypeDetailV3';
import QoSSpecsDetailV3 from './qosSpecsDetailV3';
import { encryptionDataForm } from 
'../../../../../_data/openstack/cinder/volume_types/v3';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import FormGroup from '@mui/material/FormGroup'
import { usersUrl as usersUrlResponses } from 
'../../../../../_api_responses/openstack/identity/users/v3';
import CustomSelectField from '../../../../_common/_form_fields/CustomSelectField';
import Box from '@mui/material/Box';

const SERVICE_NAME = openStackServices.volumeService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const getWidthWeight = (width) => {
    if (width < Dimensions.tablet_mini.width) {
        return 0.9
    } else if (width < Dimensions.tablet.width) {
        return 0.6
    } else {
        return 0.4
    }
}

const VolumeTypesWrapperV3 = (props) => {
    const {navigate, location} = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [currentTab, setCurrentTab] = useState("volume_types")
    const [volumeTypesData, setVolumeTypesData] = useState([])
    const [volumeTypes, setVolumeTypes] = useState([])
    const [qosSpecsData, setQoSSpecsData] = useState([])
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = getWidthWeight(width)
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [detailQoSCardOpen, setDetailQoSCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [fetchQoSDataRequired, setFetchQoSDataRequired] = useState(true);
    const [fetchDefaultsDataRequired, setFetchDefaultsDataRequired] = useState(true);
    const [currentAction, setCurrentAction] = useState("");
    const [selectedQoSRow, setSelectedQoSRow] = useState(null);
    const [currentQoSAction, setCurrentQoSAction] = useState("");
    const [currentDefaultsAction, setCurrentDefaultsAction] = useState("");
    const [volumeTypeDeleteConfirmDialogOpen, setVolumeTypeDeleteConfirmDialogOpen] = useState(false);
    const [qosSpecsDeleteConfirmDialogOpen, setQoSSpecsDeleteConfirmDialogOpen] = useState(false);
    const [selectedVolumeTypes, setSelectedVolumeTypes] = useState([])
    const [selectedQoSSpecs, setSelectedQoSSpecs] = useState([])
    const [createEncryptionDialogOpen, setCreateEncryptionDialogOpen] = useState(false)
    const [encryptionFormData, setEncryptionFormData] = useState({})
    const [encryptionFormOptions, setEncryptionFormOptions] = useState({})

    const [selectedVolumeType, setSelectedVolumeType] = useState([])
    const [selectedQoSSpec, setSelectedQoSSpec] = useState([])
    
    const [volumeTypesSortParams, setVolumeTypesSortParams] = useState("")
    const [qosSpecsSortParams, setQoSSpecsSortParams] = useState("")

    const [volumeTypeFilterQueryParams, setVolumeTypeFilterQueryParams] = useState("")
    const [selectedVolumeTypeFilter, setSelectedVolumeTypeFilter] = useState(volumeTypesFilterMenu[0].value)
    const [selectedVolumeTypeFilterValue, setSelectedVolumeTypeFilterValue] = useState("")
    const [qosSpecsDisassociateAllConfirmDialogOpen, setQoSSpecsDisassociateAllConfirmDialogOpen] = useState(false)

    const [defaultVolumeTypesFilters, setDefaultVolumeTypesFilters] = useState([...defaultVolumeTypesFilterMenu])
    const [selectedDefaultVolumeTypeFilter, setSelectedDefaultVolumeTypeFilter] = useState(defaultVolumeTypesFilters[0].value)
    const [selectedDefaultVolumeTypeFilterValue, setSelectedDefaultVolumeTypeFilterValue] = useState("")
    const [projects, setProjects] = useState([])

    const [defaultVolumeTypesData, setDefaultVolumeTypesData] = useState([])
    const [defaultVolumeTypes, setDefaultVolumeTypes] = useState([])

    const [defaultVolumeTypeDialogOpen, setDefaultVolumeTypeDialogOpen] = useState(false)
    const [selectedDefaultVolumeType, setSelectedDefaultVolumeType] = useState("")
    const [volumeTypesList, setVolumeTypesList] = useState([])
    const [selectedProjects, setSelectedProjects] = useState([])

    const cinderServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const cinderServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const volumeTypesUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.volumeTypesUrl)[0].url)
    const defaultVolumeTypesUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.defaultVolumeTypesUrl)[0].url)
    const qosSpecsUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.qosSpecsUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleQoSDataFetch = () => {
        setFetchQoSDataRequired(true)
    }

    const handleDefaultsDataFetch = () => {
        setFetchDefaultsDataRequired(true)
    }

    const handleTabChange = (tab_name) => {
        setCurrentTab(tab_name)
    }

    const handleSetDefaultVolumeTypeDialogOpen = (project_list) => {
        setDefaultVolumeTypeDialogOpen(true)
        setSelectedProjects(project_list)
    }

    const handleSetDefaultVolumeTypeDialogClose = () => {
        setSelectedDefaultVolumeType("")
        setDefaultVolumeTypeDialogOpen(false)
    }

    const handleVolumeTypeFilteredSearch = () => {
        if (selectedVolumeTypeFilter && selectedVolumeTypeFilterValue) {
            setVolumeTypeFilterQueryParams(`?${selectedVolumeTypeFilter}=${selectedVolumeTypeFilterValue}`)
        } else {
            setVolumeTypeFilterQueryParams("")
        }
        handleDataFetch()
    }

    const handleDefaultVolumeTypeFilteredSearch = () => {
        let formatted_data = projects.map(item => {
            let new_item = {}
            new_item["project_id"] = item.id
            new_item["project_name"] = item.name
            const volume_type = defaultVolumeTypesData.filter(i => i.project_id === item.id)
            new_item["volume_type_id"] = volume_type.length > 0 ? volume_type[0].volume_type_id : ""
            new_item["volume_type_name"] = volume_type.length > 0 ? volumeTypesData.filter(v => v.id === volume_type[0].volume_type_id)[0].name : ""
            return new_item
        })
        if (selectedDefaultVolumeTypeFilter && selectedDefaultVolumeTypeFilterValue) {
            if (selectedDefaultVolumeTypeFilter === "project_id") {
                const new_list = formatted_data.filter(item => item.project_id === selectedDefaultVolumeTypeFilterValue)
                setDefaultVolumeTypes(new_list)
            } else if (selectedDefaultVolumeTypeFilter === "volume_type_id") {
                const new_list = formatted_data.filter(item => item.volume_type_id === selectedDefaultVolumeTypeFilterValue)
                setDefaultVolumeTypes(new_list)
            }
        } else {
            setDefaultVolumeTypes(formatted_data)
        }
    }

    const handleVolumeTypesDataFormatting = useCallback(() => {
        const formatted_data = volumeTypesData.map((item) => {
            if (item.qos_specs_id) {
                let new_item = {...item}
                new_item["qos_specs_id"] = qosSpecsData.filter(q => q.id === item.qos_specs_id)[0].name
                return new_item
            } else {
                return item
            }
        })
        setVolumeTypes(formatted_data)
    },[
        qosSpecsData,
        volumeTypesData
    ])

    const handleVolumeTypeFilterReset = () => {
        setSelectedVolumeTypeFilter(volumeTypesFilterMenu[0].value)
        setSelectedVolumeTypeFilterValue("")
        setVolumeTypeFilterQueryParams("")
        handleDataFetch()
    }

    const handleDefaultVolumeTypeFilterReset = () => {
        setSelectedDefaultVolumeTypeFilter(defaultVolumeTypesFilters[0].value)
        setSelectedDefaultVolumeTypeFilterValue("")
        let formatted_data = projects.map(item => {
            let new_item = {}
            new_item["project_id"] = item.id
            new_item["project_name"] = item.name
            const volume_type = defaultVolumeTypesData.filter(i => i.project_id === item.id)
            new_item["volume_type_id"] = volume_type.length > 0 ? volume_type[0].volume_type_id : ""
            new_item["volume_type_name"] = volume_type.length > 0 ? volumeTypesData.filter(v => v.id === volume_type[0].volume_type_id)[0].name : ""
            return new_item
        })
        setDefaultVolumeTypes(formatted_data)
    }

    const handleNavigateToQoSSpecs = (qos_id) => {
        const selected_qos_spec_index = qosSpecsData.findIndex(item => item.id === qos_id)
        handleDetailCardClose()
        handleTabChange("qos_specs")
        handleQoSDetailCardOpen(selected_qos_spec_index)
    }

    const handleNavigateToVolumeTypes = (vt_id) => {
        const selected_vt_index = volumeTypesData.findIndex(item => item.id === vt_id)
        handleQoSDetailCardClose()
        handleTabChange("volume_types")
        handleDetailCardOpen(selected_vt_index)
    }

    const handleDetailCardOpen = useCallback((index) => {
        setSelectedRow(volumeTypesData[index])
        setTimeout(() => setDetailCardOpen(true),100)
    },[volumeTypesData]);

    const handleQoSDetailCardOpen = (index) => {
        setSelectedQoSRow(qosSpecsData[index])
        setTimeout(() => setDetailQoSCardOpen(true),100)
    }

    const handleDetailCardClose = () => {
        setTimeout(() => setDetailCardOpen(false),100)
        setSelectedRow(null)
        navigate(location.path,{})
    };

    const handleQoSDetailCardClose = () => {
        setDetailQoSCardOpen(false)
        setSelectedQoSRow(null)
    }

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleVolumeTypeSorting = (field,direction) => {
        const sort_param = `&&sort=${field}:${direction}`
        setVolumeTypesSortParams(sort_param)
        handleDataFetch()
    }

    const handleQoSSpecsSorting = (field,direction) => {
        const sort_param = `&&sort=${field}:${direction}`
        setQoSSpecsSortParams(sort_param)
        handleQoSDataFetch()
    }

    const handleVolumeTypeDelete = async (vt_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}/${vt_id}`
            const method = "DELETE"
            
            const vt_response = await volumeCinderRequest({
                url:url, 
                method:method,
                token: project_token
            })

            if (vt_response.status_code === volumeUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return vt_response.error
            }
        }
    };

    const onVolumeTypeDelete = async () => {
        handleVolumeTypeDeleteConfirmDialogClose()
        setIsLoading(true)
        let err = []
        for (let v in selectedVolumeTypes) {
            const resp = await handleVolumeTypeDelete(selectedVolumeTypes[v].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDetailCardClose()
        handleDataFetch()
        setIsLoading(false)
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const handleQoSSpecDelete = async (qos_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${qosSpecsUrl}/${qos_id}`
            const method = "DELETE"
            
            const vt_response = await volumeCinderRequest({
                url: url, 
                method: method,
                token: project_token
            })

            if (vt_response.status_code === volumeUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return vt_response.error
            }
        }
    }

    const handleQoSSpecDisassociateAll = async (qos_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${qosSpecsUrl}/${qos_id}/disassociate_all`
            const method = "GET"
            
            try {
                await volumeCinderRequest({
                    url: url, 
                    method: method,
                    token: project_token,
                    has_response: false
                })
                return true
            } catch {
                return false
            }
        }
    }

    const onQoSSpecDelete = async () => {
        handleQoSSpecsDeleteConfirmDialogClose()
        setIsLoading(true)
        let err  = []
        for (let q in selectedQoSSpecs) {
            const resp = await handleQoSSpecDelete(selectedQoSSpecs[q].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleQoSDetailCardClose()
        handleQoSDataFetch()
        setIsLoading(false)
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onQoSSpecDisassociateAll = async () => {
        handleQoSSpecsDisassociateAllConfirmDialogClose()
        setIsLoading(true)
        for (let q in selectedQoSSpecs) {
            await handleQoSSpecDisassociateAll(selectedQoSSpecs[q].id)
        }
        handleQoSDetailCardClose()
        handleDataFetch()
        handleQoSDataFetch()
        setIsLoading(false)
    }

    const onVolumeTypeDeleteConfirm = (vt_list) => {
        const selected_vt_list = volumeTypesData.filter(vt => 
            vt_list.includes(vt.id))
        setSelectedVolumeTypes([...selected_vt_list])
        setVolumeTypeDeleteConfirmDialogOpen(true)
    }

    const onQoSSpecsDeleteConfirm = (qos_list) => {
        const selected_qos_list = qosSpecsData.filter(qs => 
            qos_list.includes(qs.id))
        setSelectedQoSSpecs([...selected_qos_list])
        setQoSSpecsDeleteConfirmDialogOpen(true)
    }

    const onQoSSpecsDisassociateAll = (qos_list) => {
        const selected_qos_list = qosSpecsData.filter(qs => 
            qos_list.includes(qs.id))
        setSelectedQoSSpecs([...selected_qos_list])
        setQoSSpecsDisassociateAllConfirmDialogOpen(true)
    }

    const handleVolumeTypeDeleteConfirmDialogClose = () => {
        setVolumeTypeDeleteConfirmDialogOpen(false)
    }

    const handleQoSSpecsDeleteConfirmDialogClose = () => {
        setQoSSpecsDeleteConfirmDialogOpen(false)
    }

    const handleQoSSpecsDisassociateAllConfirmDialogClose = () => {
        setQoSSpecsDisassociateAllConfirmDialogOpen(false)
    }

    const handleCreateEncryptionDialogOpen = (vt_list) => {
        setSelectedVolumeTypes(vt_list)
        setCreateEncryptionDialogOpen(true)
    }

    const validateEncryptionFormData = () => {
        let validation_faild = true
        let updatedDataFormOptions = {...encryptionFormOptions}
        for (let n in encryptionDataForm) {
            if (encryptionDataForm[n].required && !encryptionFormData[encryptionDataForm[n].field_key]) {
                validation_faild = false
                updatedDataFormOptions[encryptionDataForm[n].field_key] = {}
                updatedDataFormOptions[encryptionDataForm[n].field_key]["error"] = true
                updatedDataFormOptions[encryptionDataForm[n].field_key]["errorText"] = defaultTexts[encryptionDataForm[n].error_label]
            }
        }
        setEncryptionFormOptions(updatedDataFormOptions)
        return validation_faild
    }

    const getEncryptionDataForm = () => {
        return (
            <FormGroup>
                {encryptionDataForm.map(field => {
                    let form_field_options = {...encryptionFormOptions[field.field_key]}
                    if (field.field_key === "provider" || field.field_key === "control_location") {
                        form_field_options["items"] = [...field.items]
                        form_field_options["empty"] = field.default_empty
                        form_field_options["self_item_titles"] = field.self_item_titles
                        form_field_options["default_value"] = field.default_value
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            encryptionFormData,
                            handleEncryptionFormDataChange,
                            defaultTexts[field.label],
                            {
                                ...form_field_options,
                                item_titles: defaultTexts
                            }
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleEncryptionCreate = async (vt_id) => {
        let data_validation = validateEncryptionFormData()
        if (data_validation) {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}/${vt_id}/encryption`
                const method = "POST"
                
                const vt_response = await volumeCinderRequest({
                    url: url, 
                    method: method,
                    data: {encryption: encryptionFormData},
                    token: project_token
                })
        
                if (vt_response.status_code === volumeUrlResponses.post.success_response.status_code) {
                    return null
                } else {
                    return vt_response.error
                }
            }
        }
    };

    const handleDefaultVolumeTypeUnset = async (volume_type_list) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            for (let v in volume_type_list) {
                const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultVolumeTypesUrl}/${volume_type_list[v]}`
                const method = "DELETE"

                await volumeCinderRequest({
                    url: url, 
                    method: method,
                    token: project_token
                })
            }
            handleDefaultsDataFetch()
        }
    }

    const onDefaultVolumeTypeSet = async () => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            for (let v in selectedProjects) {
                const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultVolumeTypesUrl}/${selectedProjects[v]}`
                const method = "PUT"

                await volumeCinderRequest({
                    url: url, 
                    method: method,
                    data: {default_type: {volume_type: selectedDefaultVolumeType}},
                    token: project_token
                })
            }
            handleSetDefaultVolumeTypeDialogClose()
            handleDefaultsDataFetch()
        }
    }

    const onEncryptionCreate = async () => {
        let data_validation = validateEncryptionFormData()
        if (data_validation) {
            setCreateEncryptionDialogOpen(false)
            setIsLoading(true)
            let err = []
            for (let v in selectedVolumeTypes) {
                const resp = await handleEncryptionCreate(selectedVolumeTypes[v])
                if (resp !== null) {
                    err = [...err, resp]
                }
            }
            handleDataFetch()
            handleCreateEncryptionDialogClose()
            setIsLoading(false)
            if (err.length > 0) {
                let error_object = {}
                error_object["error_title"] = "errorDeleteRecordTitle"
                error_object["error_message"] = "errorDeleteRecordMessage"
                error_object["error_details"] = err.toString()
                setError(error_object)
                setErrorDialogOpen(true)
            }
        }
    }

    const handleCreateEncryptionDialogClose = () => {
        setEncryptionFormData({})
        setEncryptionFormOptions({})
        setCreateEncryptionDialogOpen(false)
    }

    const handleEncryptionFormDataChange = (event,field_key) => {
        setEncryptionFormOptions({})
        let new_form_data = {...encryptionFormData}
        if (encryptionDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (encryptionDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setEncryptionFormData(new_form_data)
    }

    const getVolumeTypesActionsList = () => {
        let volume_types_actions = []
        let new_action = {}
        new_action["value"] = "volume_type_delete"
        new_action["action"] = onVolumeTypeDeleteConfirm
        new_action["keyword"] = "volumeTypeDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        volume_types_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "encryption_create"
        new_action["action"] = handleCreateEncryptionDialogOpen
        new_action["keyword"] = "encryptionCreateActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        volume_types_actions.push({...new_action})
        
        return volume_types_actions
    }

    const getQoSSpecsActionsList = () => {
        let qos_specs_actions = []
        let new_action = {}
        new_action["value"] = "qos_specs_delete"
        new_action["action"] = onQoSSpecsDeleteConfirm
        new_action["keyword"] = "qosSpecsDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        qos_specs_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "qos_specs_disassociate_all"
        new_action["action"] = onQoSSpecsDisassociateAll
        new_action["keyword"] = "qosSpecsDisassociateAllActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        qos_specs_actions.push({...new_action})
        
        return qos_specs_actions
    }

    const getDefaultVolumeTypesActionsList = () => {
        let default_vt_actions = []
        let new_action = {}
        new_action["value"] = "set_default_volume_type"
        new_action["action"] = handleSetDefaultVolumeTypeDialogOpen
        new_action["keyword"] = "setDefaultVolumeTypeActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        default_vt_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "unset_default_volume_type"
        new_action["action"] = handleDefaultVolumeTypeUnset
        new_action["keyword"] = "unsetDefaultVolumeTypeActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        default_vt_actions.push({...new_action})
        
        return default_vt_actions
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        const project_filters = projects.map(project => {
            return {keyword: project.name, value: project.id, default: false}
        })
        const volume_types_filters = volumeTypesData.map(vt => {
            return {keyword: vt.name, value: vt.id, default: false}
        })

        let default_volume_types_filter = defaultVolumeTypesFilterMenu.map(item => {
            let new_item = {...item}
            if (item.value === "project_id") {
                new_item["items"] = [...project_filters]
                new_item["self_item_titles"] = true
            } else if (item.value === "volume_type_id") {
                new_item["items"] = [...volume_types_filters]
                new_item["self_item_titles"] = true
            }
            return new_item
        })

        setDefaultVolumeTypesFilters(default_volume_types_filter)

        let formatted_data = projects.map(item => {
            let new_item = {}
            new_item["project_id"] = item.id
            new_item["project_name"] = item.name
            const volume_type = defaultVolumeTypesData.filter(i => i.project_id === item.id)
            new_item["volume_type_id"] = volume_type.length > 0 ? volume_type[0].volume_type_id : ""
            if (volume_type.length > 0) {
                const vol = volumeTypesData.filter(v => v.id === volume_type[0].volume_type_id)
                new_item["volume_type_name"] = vol.length > 0 ? vol[0].name : ""
            }
            return new_item
        })
        setDefaultVolumeTypes(formatted_data)
    },[
        projects,
        volumeTypesData,
        defaultVolumeTypesData
    ])

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let all_volume_types = []
                    let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}${volumeTypeFilterQueryParams}${volumeTypesSortParams}`
                    const method = "GET"
        
                    const volume_types_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                    if (volume_types_response.status_code === volumeUrlResponses.get.success_response.status_code) {
                        all_volume_types = [...all_volume_types, ...volume_types_response.data.volume_types]
                    }
                    if (volumeTypeFilterQueryParams.length === 0) {
                        url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}?is_public=false`
                        const response = await volumeCinderRequest({url:url, method:method, token: project_token})
                        if (response.status_code === volumeUrlResponses.get.success_response.status_code) {
                            const private_vt = response.data.volume_types.filter(vt => !all_volume_types.map(i => i.id).includes(vt.id))
                            all_volume_types = [...private_vt, ...all_volume_types]
                        }
                    }
                    setVolumeTypesData([...all_volume_types])
                    const volume_type_list = all_volume_types.map(item => {
                        return {keyword: item.name, value: item.id, default: false}
                    })
                    setVolumeTypesList(volume_type_list)
                    if (selectedRow) {
                        let new_data = all_volume_types.filter(vt => vt.id === selectedRow.id)
                        if (new_data.length > 0) {
                            setSelectedRow(new_data[0])
                        }
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        cinderServiceDomain, 
        cinderServiceVersion, 
        volumeTypesUrl, 
        volumeTypeFilterQueryParams,
        dataFetchingRequired,
        selectedRow,
        defaultAdminProject,
        volumeTypesSortParams
    ]);

    useEffect(() => {
        if (fetchDefaultsDataRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultVolumeTypesUrl}`
                    const method = "GET"
        
                    const volume_types_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                    if (volume_types_response.status_code === volumeUrlResponses.get.success_response.status_code) {
                        setDefaultVolumeTypesData(volume_types_response.data.default_types)
                    }
                }
            })();
        }
        setFetchDefaultsDataRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        cinderServiceDomain, 
        cinderServiceVersion, 
        defaultVolumeTypesUrl,
        fetchDefaultsDataRequired,
        defaultAdminProject
    ]);

    useEffect(() => {
        if (fetchQoSDataRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${qosSpecsUrl}${qosSpecsSortParams}`
                    const method = "GET"
        
                    const qos_specs_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                    if (qos_specs_response.status_code === volumeUrlResponses.get.success_response.status_code) {
                        setQoSSpecsData(qos_specs_response.data.qos_specs)
                    }
                    if (selectedQoSRow) {
                        let new_data = qos_specs_response.data.qos_specs.filter(qs => qs.id === selectedQoSRow.id)
                        if (new_data.length > 0) {
                            setSelectedQoSRow(new_data[0])
                        }
                    }
                }
                
            })();
            setFetchQoSDataRequired(false)
        }
        setTimeout(()=>{handleLoading(false)},700)
    },[
        cinderServiceDomain, 
        cinderServiceVersion, 
        qosSpecsUrl, 
        fetchQoSDataRequired,
        selectedQoSRow,
        defaultAdminProject,
        qosSpecsSortParams
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === usersUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        selectedRow,
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        handleVolumeTypesDataFormatting()
    },[
        volumeTypesData,
        qosSpecsData,
        handleVolumeTypesDataFormatting
    ])

    useEffect(() => {
        if (selectedRow && volumeTypes.length > 0) {
            setSelectedVolumeType(volumeTypes.filter(item => item.id === selectedRow.id)[0])
        } else {
            setSelectedVolumeType(null)
        }
    },[
        selectedRow,
        volumeTypes
    ])

    useEffect(() => {
        if (selectedRow && qosSpecsData.length > 0) {
            setSelectedQoSSpec(qosSpecsData.filter(item => item.id === selectedRow.qos_specs_id)[0])
        } else {
            setSelectedQoSSpec(null)
        }
    },[
        selectedRow,
        qosSpecsData
    ])

    useEffect(() => {
        if (!dataFetchingRequired && location.state ) {
            const volume_type_id = location.state ? location.state.volume_type_id : null
                const vt_index = volumeTypesData.findIndex(vt => vt.id === volume_type_id);
                if (vt_index !== -1) {
                    setTimeout(() => handleDetailCardOpen(vt_index), 600)
                }
        }
    },[
        dataFetchingRequired,
        volumeTypesData,
        handleDetailCardOpen,
        location
    ])

    return (
        <Box>
        {currentTab === "volume_types" && 
            <VolumeTypesSubheaderV3 
                selectedFilter={selectedVolumeTypeFilter} 
                setSelectedFilter={setSelectedVolumeTypeFilter}
                selectedFilterValue={selectedVolumeTypeFilterValue}
                setSelectedFilterValue={setSelectedVolumeTypeFilterValue}
                handleDataFetch={handleDataFetch}
                filterMenu={volumeTypesFilterMenu}
                handleFilteredSearch={handleVolumeTypeFilteredSearch}
                handleFilterReset={handleVolumeTypeFilterReset}
                currentTab={currentTab}
                handleTabChange={handleTabChange}
            />
        }
        {currentTab === "qos_specs" && 
            <QoSSpecsSubheaderV3 
                handleFetchData={handleQoSDataFetch}
                currentTab={currentTab}
                handleTabChange={handleTabChange}
            />
        }
        {currentTab === "default_vt" && 
            <DefaultVolumeTypesSubheaderV3 
                handleFetchData={handleDefaultsDataFetch}
                currentTab={currentTab}
                handleTabChange={handleTabChange}
                filterMenu={defaultVolumeTypesFilters}
                selectedFilter={selectedDefaultVolumeTypeFilter} 
                setSelectedFilter={setSelectedDefaultVolumeTypeFilter}
                selectedFilterValue={selectedDefaultVolumeTypeFilterValue}
                setSelectedFilterValue={setSelectedDefaultVolumeTypeFilterValue}
                handleFilteredSearch={handleDefaultVolumeTypeFilteredSearch}
                handleFilterReset={handleDefaultVolumeTypeFilterReset}
            />
        }
        {isLoading && <CustomBackdrop open={isLoading} />}
        {!isLoading && currentTab === "volume_types" &&
            <VolumeTypesTableV3 
                volumeTypesData={volumeTypes}
                setVolumeTypesData={setVolumeTypes}
                handleRowSelection={handleDetailCardOpen}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getVolumeTypesActionsList()}
                sortHandler={handleVolumeTypeSorting}
            />
        }
        {!isLoading && currentTab === "qos_specs" && 
            <QoSSpecsTableV3 
                qosSpecsData={qosSpecsData}
                setQoSSpecsData={setQoSSpecsData}
                handleRowSelection={handleQoSDetailCardOpen}
                currentAction={currentQoSAction}
                setCurrentAction={setCurrentQoSAction}
                actionsTexts={defaultTexts}
                actionsList={getQoSSpecsActionsList()}
                sortHandler={handleQoSSpecsSorting}
            />
        }
        {!isLoading && currentTab === "default_vt" &&
            <DefaultVolumeTypesTableV3 
                defaultVolumeTypesData={defaultVolumeTypes}
                setDefaultVolumeTypesData={setDefaultVolumeTypes}
                actionsTexts={defaultTexts}
                currentAction={currentDefaultsAction}
                setCurrentAction={setCurrentDefaultsAction}
                actionsList={getDefaultVolumeTypesActionsList()}
            />
        }
        {selectedRow !== null && currentTab === "volume_types" && 
            <CustomSideDrawer 
                open={detailCardOpen}
                widthWeight={WIDTH_WEIGHT}
                handleDrawerOpen={handleDetailCardOpen}
                handleDrawerClose={handleDetailCardClose}
            > 
                <VolumeTypeDetailV3
                    selectedRow={selectedRow}
                    selectedVolumeType={selectedVolumeType}
                    widthWeight={WIDTH_WEIGHT}
                    selectedVTQoSSpecs={selectedQoSSpec}
                    handleFetchData={handleDataFetch}
                    handleNavigateToQoSSpecs={handleNavigateToQoSSpecs}
                    handleEncryptionCreate={handleCreateEncryptionDialogOpen}
                    handleVolumeTypeDelete={onVolumeTypeDeleteConfirm}
                    projects={projects}
                />        
            </CustomSideDrawer>
        }
        {selectedQoSRow !== null && currentTab === "qos_specs" && 
            <CustomSideDrawer 
                open={detailQoSCardOpen}
                widthWeight={WIDTH_WEIGHT}
                handleDrawerOpen={handleQoSDetailCardOpen}
                handleDrawerClose={handleQoSDetailCardClose}
            > 
                <QoSSpecsDetailV3 
                    selectedRow={selectedQoSRow}
                    widthWeight={WIDTH_WEIGHT}
                    handleNavigateToVolumeTypes={handleNavigateToVolumeTypes}
                    handleQoSFetchData={handleQoSDataFetch}
                    handleFetchData={handleDataFetch}
                    handleQoSSpecsDelete={onQoSSpecsDeleteConfirm}
                    handleDisassociateAll={handleQoSSpecDisassociateAll}
                    volumeTypes={volumeTypesData}
                />
            </CustomSideDrawer>
        }
        <CustomDialog
            open={volumeTypeDeleteConfirmDialogOpen}
            onClose={handleVolumeTypeDeleteConfirmDialogClose}
            dialogTitle={{
                title: defaultTexts.volumeTypeDeleteConfirmTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `${defaultTexts.volumeTypeDeleteConfirmText}: [${selectedVolumeTypes.map(v => v.name).toString()}]`, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onVolumeTypeDelete, 
                sx: {color: 'primary.main'}}]}
        />
        <CustomDialog
            open={qosSpecsDeleteConfirmDialogOpen}
            onClose={handleQoSSpecsDeleteConfirmDialogClose}
            dialogTitle={{
                title: defaultTexts.qosSpecsDeleteConfirmTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `${defaultTexts.qosSpecsDeleteConfirmText}: [${selectedQoSSpecs.map(q => q.name).toString()}]`, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onQoSSpecDelete, 
                sx: {color: 'primary.main'}}]}
        />
        <CustomDialog
            open={qosSpecsDisassociateAllConfirmDialogOpen}
            onClose={handleQoSSpecsDisassociateAllConfirmDialogClose}
            dialogTitle={{
                title: defaultTexts.qosSpecsDisassociateAllConfirmTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `${defaultTexts.qosSpecsDisassociateAllConfirmText}: [${selectedQoSSpecs.map(q => q.name).toString()}]`, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick:  onQoSSpecDisassociateAll, 
                sx: {color: 'primary.main'}}]}
        />
        <CustomDialog
            open={createEncryptionDialogOpen}
            onClose={handleCreateEncryptionDialogClose}
            dialogTitle={{
                title: defaultTexts.encryptionCreateDialogTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: defaultTexts.encryptionCreateDialogText, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onEncryptionCreate, 
                sx: {color: 'primary.main'}}]}
        >
            {getEncryptionDataForm()}
        </CustomDialog>
        <CustomDialog
            open={defaultVolumeTypeDialogOpen}
            onClose={handleSetDefaultVolumeTypeDialogClose}
            dialogTitle={{
                title: defaultTexts.setDefaultVolumeTypeActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onDefaultVolumeTypeSet, 
                sx: {color: 'primary.main'}}]}
        >
            <CustomSelectField
                items={volumeTypesList}
                required={true}
                currentValue={selectedDefaultVolumeType}
                setCurrentValue={setSelectedDefaultVolumeType}
                label={defaultTexts.volumeTypeFormFieldLabel}
                self_item_titles={true}
                empty={true}
                size="large"
                sx={{my: 1, width: '90%'}}
            />
        </CustomDialog>
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts[error.error_title], 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts[error.error_message]}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
    </Box>
    )
};

export default VolumeTypesWrapperV3;