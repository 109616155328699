import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import FilterListIcon from '@mui/icons-material/FilterList';
import Paper from '@mui/material/Paper';
import Collapse from '@mui/material/Collapse';
import { getFormFieldComponent } from './_form_fields/form_helpers';
import Divider from '@mui/material/Divider';
import CustomText from './CustomText';
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import { RxReset } from "react-icons/rx";

const SideFilter = (props) => {
    const { filterGroups } = props
    const { filters } = props
    const { color, sx } = props
    const { currentValues, setCurrentValue } = props
    const { handleSearch, handleFilterReset } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [filterOpen, setFilterOpen] = useState(false)

    const theme = useTheme();

    const handleFilterOpen = () => {
        setFilterOpen(true)
    }

    const handleFilterClose = () => {
        setFilterOpen(false)
    }

    return (
        <React.Fragment>
            {!filterOpen && <IconButton
                onClick={handleFilterOpen}
            >
                <FilterListIcon 
                    color={color ? color : theme.palette.text.primary}
                    sx={{...sx}}
                />
            </IconButton>}
            {filterOpen && 
                <Collapse orientation="horizontal" in={filterOpen}>
                    <Paper 
                        sx={{ 
                            width: 350,
                            maxHeight: 500,
                            p: 1,
                            overflowY: 'scroll',
                            ...sx
                        }} 
                        elevation={24}
                        square={false}
                    >
                        <Stack 
                            direction="row" 
                            alignItems="center" 
                            justifyContent="space-between"
                        >
                            <IconButton onClick={handleFilterClose}>
                                <ArrowForwardIosIcon />
                            </IconButton>
                            <Stack 
                                direction="row" 
                                spacing={1}
                                alignItems="center"
                            >
                                <IconButton onClick={handleFilterReset}>
                                    <RxReset 
                                        color={theme.palette.text.primary}
                                        fontSize='large'
                                    />
                                </IconButton>
                                <Button onClick={handleSearch}>
                                    {defaultTexts.searchButtonText}
                                </Button>
                            </Stack>
                        </Stack>
                        {filterGroups.length > 0 ?
                            filterGroups.map(group => {
                                return (
                                    <React.Fragment key={group.name}>
                                        <CustomText>{group.title}</CustomText>
                                        {filters[group.name].map(fl => {
                                        return (
                                            getFormFieldComponent(
                                                fl,
                                                currentValues,
                                                setCurrentValue,
                                                defaultTexts[fl.label],
                                                {...fl.options,
                                                    sx: {
                                                        width: '90%',
                                                        m: 1
                                                    }
                                                }
                                            ))
                                        })}
                                        <Divider sx={{my: 2}} />
                                    </React.Fragment>
                                )
                            })
                            :
                            filters.map(fl =>  
                                getFormFieldComponent(
                                    fl,
                                    currentValues,
                                    setCurrentValue,
                                    defaultTexts[fl.label],
                                    fl.options
                                )
                            )
                        }
                        <Stack 
                            direction="row" 
                            alignItems="center" 
                            justifyContent="space-between"
                            sx={{mb: 1}}
                        >
                            <IconButton onClick={handleFilterClose}>
                                <ArrowForwardIosIcon />
                            </IconButton>
                            <Stack 
                                direction="row" 
                                spacing={1}
                                alignItems="center"
                            >
                                <IconButton onClick={handleFilterReset}>
                                    <RxReset 
                                        color={theme.palette.text.primary}
                                        fontSize='large'
                                    />
                                </IconButton>
                                <Button onClick={handleSearch}>
                                    {defaultTexts.searchButtonText}
                                </Button>
                            </Stack>
                        </Stack>
                    </Paper>
                </Collapse>
            }
        </React.Fragment>
    )
};

export default SideFilter