import React, { useState, useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled, useTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import NavBar from './NavBar';
import { GrVirtualMachine } from "react-icons/gr";
import { LuNetwork } from "react-icons/lu";
import { ImDatabase } from "react-icons/im";
import { IoCopyOutline } from "react-icons/io5";
import { RiListSettingsLine } from "react-icons/ri";
import { MdAccountBalanceWallet } from "react-icons/md";
import { IoPricetags } from "react-icons/io5";
import { FaChartBar } from "react-icons/fa";
//import { LuDatabaseBackup } from "react-icons/lu";
import { MdSwitchAccount } from "react-icons/md";
//import { MdAlternateEmail } from "react-icons/md";
//import { TbLoadBalancer } from "react-icons/tb";
import { CgMenuGridO } from "react-icons/cg";
import { LiaHandsHelpingSolid } from "react-icons/lia";
import { setDrawerOpened } from '../../store/reducers/drawerSlice';
import Constants from '../../config/constants';
import Tooltip from '@mui/material/Tooltip';


const serviceList = [
  {service: 'Compute (Nova)', icon: GrVirtualMachine, navigation: '/compute'},
  {service: 'Block Storage (Cinder)', icon: ImDatabase, navigation: '/block-storage'},
  {service: 'Images (Glance)', icon: IoCopyOutline, navigation: '/images'},
  {service: 'Networks (Neutron)', icon: LuNetwork, navigation: '/networks'},
  //{service: 'Backup and DR', icon: LuDatabaseBackup, navigation: '/backups'},
  //{service: 'DNS (Designate)', icon: MdAlternateEmail, navigation: '/dns'},
  {service: 'Identity (Keystone)', icon: MdSwitchAccount, navigation: '/identity'},
  //{service: 'Loadbalancers (Octavia)', icon: TbLoadBalancer, navigation: '/loadbalancers'},
]

const drawerWidth = Constants.drawerWidth;

const listItemButtonStyle = {
  minHeight: 48,
  px: 2.5
}

const listItemIconStyle = {
  minWidth: 0,
  py: 1,
  justifyContent: 'center',
  color: 'primary.main',
  '&:hover': {
    color: 'primary.light'
  }
}

const listItemTextStyle = {
  color: 'text.secondary',
  '&:hover': {
    color: 'primary.main'
  }
}

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden'
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const CustomDrawer = (props) => {
  const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
  const defaultTexts = useSelector(state => state.texts.langTexts);
  const open = useSelector(state => state.drawer.drawerOpened);
  const theme = useTheme();
  const purchasedService = useSelector(
    state => state.openstack.purchasedServicesAll)
  const [manageBilling, setManageBilling] = useState(false)
  const [billingNavList, setBillingNavList] = useState([])
  
  const navigate = useNavigate();
  const dispatch = useDispatch();

  
  const customersSupportNavList = [
    {title: defaultTexts.customersSupportNavLink, icon: LiaHandsHelpingSolid, navigation: '/customers-support'}
  ]
  
  useEffect(() => {
    const defaultBillingNavList = [
      {title: defaultTexts.billingConfigsNavLink, icon: RiListSettingsLine, section: Constants.billing_sections.billing_configs, navigation: '/billing-configs'},
      {title: defaultTexts.billingPricesNavLink, icon: IoPricetags, section: Constants.billing_sections.billing_prices, navigation: '/billing-prices'},
      {title: defaultTexts.billingDepositsNavLink, icon: MdAccountBalanceWallet, section: Constants.billing_sections.billing_deposits, navigation: '/billing-deposits'},
      {title: defaultTexts.billingAnalyticsNavLink, icon: FaChartBar, section: Constants.billing_sections.billing_analytics, navigation: '/billing-analytics'}
    ]
    if (isAuthenticated && purchasedService.length > 0) {
      const billingService = purchasedService.filter(
        service => service.service === Constants.config_service_billing_name)
      const allowBilling = purchasedService.filter(
        service => service.service === Constants.config_service_billing_name)[0].config_params.visible_to_service.includes(
        Constants.config_service_custom_admin_ui)
      setManageBilling(allowBilling)
      if (allowBilling) {
        const billingConfiguration = billingService[0].config_params[Constants.config_service_custom_admin_ui]
        const updatedBillingNavList = defaultBillingNavList.filter(
          component => billingConfiguration.filter(
            section => section.section === component.section && 
            section.permissions.includes(Constants.permission_read)))
        setBillingNavList(updatedBillingNavList)
      }
    }
  },[
    isAuthenticated, 
    purchasedService,
    defaultTexts
  ])

  const handleDrawerToggle = () => {
    dispatch(setDrawerOpened({drawerOpened: !open}));
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar 
        position="fixed" 
        open={open} 
        enableColorOnDark 
        //sx={{ zIndex: 1500 }}
      >
        <Toolbar>
        {isAuthenticated && <IconButton
            color="inherit"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <CgMenuGridO />
          </IconButton>}
          <NavBar />
        </Toolbar>
      </AppBar>
      {isAuthenticated && <Drawer variant="permanent" open={open}>
        <DrawerHeader sx={{backgroundColor: "primary.main", pb: 1 }}>
          <IconButton
            onClick={handleDrawerToggle}
            edge="start"
            sx={{
              marginRight: 20,
              color: 'white',
              ...(!open && { display: 'none' }),
            }}
          >
            <CgMenuGridO />
          </IconButton>
          <IconButton onClick={handleDrawerToggle}>
            {theme.direction === 'rtl' ? 
              <ChevronRightIcon sx={{color: 'white'}} fontSize="large" /> : 
              <ChevronLeftIcon sx={{color: 'white'}} fontSize="large" />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <Box 
          sx={{height: '100%',
            overflow: 'hidden', 
            position: 'relative'
          }}
        >
          <Box 
            sx={{
              height: '100%', 
              position: 'absolute', 
              top: 0, 
              bottom: 0, 
              left: 0, 
              right: -19, 
              overflowY: 'scroll',
              overflowX: 'hidden'
            }}
          >
            <List>
              {serviceList.map((item, index) => 
                <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  onClick={(event) => {navigate(item.navigation)}}
                  sx={{...listItemButtonStyle,justifyContent: open ? 'initial' : 'center'}}
                >
                  <Tooltip title={open ? "" : item.service} placement="right">
                    <ListItemIcon
                      sx={{...listItemIconStyle, mr: open ? 3 : 'auto'}}
                    >
                      <item.icon size={30} />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText 
                    primary={item.service}
                    primaryTypographyProps={{fontWeight: '400'}} 
                    sx={{ 
                      opacity: open ? 1 : 0,
                      ...listItemTextStyle
                    }} 
                  />
                </ListItemButton>
              </ListItem>)}
            </List>
            {manageBilling && <Divider />}
            {manageBilling && <List>
              {billingNavList.map((item, index) => 
                <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  onClick={(event) => {navigate(item.navigation)}}
                  sx={{...listItemButtonStyle,justifyContent: open ? 'initial' : 'center'}}
                >
                  <Tooltip title={open ? "" : item.title} placement="right">
                    <ListItemIcon
                      sx={{...listItemIconStyle, mr: open ? 3 : 'auto'}}
                    >
                      <item.icon size={30} />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText 
                    primary={item.title}
                    primaryTypographyProps={{fontWeight: '400'}} 
                    sx={{ 
                      opacity: open ? 1 : 0,
                      ...listItemTextStyle
                    }} 
                  />
                </ListItemButton>
              </ListItem>)}
            </List>}
              {purchasedService.find(s => 
                s.service_type === Constants.config_service_type_frontend &&
                s.service === Constants.config_service_custom_client_ui
              ) && <Divider />}
              {purchasedService.find(s => 
                s.service_type === Constants.config_service_type_frontend &&
                s.service === Constants.config_service_custom_client_ui
              ) && <List>
                {customersSupportNavList.map((item, index) => 
                <ListItem key={index} disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  onClick={(event) => {navigate(item.navigation)}}
                  sx={{...listItemButtonStyle,justifyContent: open ? 'initial' : 'center'}}
                >
                  <Tooltip title={open ? "" : item.title} placement="right">
                    <ListItemIcon
                      sx={{...listItemIconStyle, mr: open ? 3 : 'auto'}}
                    >
                      <item.icon size={30} />
                    </ListItemIcon>
                  </Tooltip>
                  <ListItemText 
                    primary={item.title}
                    primaryTypographyProps={{fontWeight: '400'}} 
                    sx={{ 
                      opacity: open ? 1 : 0,
                      ...listItemTextStyle
                    }} 
                  />
                </ListItemButton>
              </ListItem>)}
            </List>}
          </Box>
        </Box>
      </Drawer>}
      <Box component="main" sx={{ flexGrow: 1, p: 3, maxWidth: open ? `calc(100vw - ${drawerWidth}px)` : undefined}}>
        <DrawerHeader />
        {props.children}
      </Box>
    </Box>
  );
}
export default CustomDrawer;