import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import CustomDialog from '../../../../../_common/CustomDialog';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import Dimensions from '../../../../../../config/dimensions';
import CustomSideDrawer from '../../../../../_common/CustomSideDrawer';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, 
        networkNeutronConstants, 
        identityKeystonConstants
} from '../../../../../../config/openStackConstants';
import TrunksSubheaderV20 from './trunksSubheaderV2.0';
import TrunksTableV20 from './trunksTableV2.0';
import TrunkDetailV20 from './trunkDetailV2.0';
import { trunksFilterMenu, trunkAdminStateUpdateForm } 
from '../../../../../../_data/openstack/neutron/trunks/v2.0';
import { 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../../_network/openstack_request';
import { trunksUrl as trunkUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/trunks/v2.0';
import { networksUrl as networkUrlResponses} from 
'../../../../../../_api_responses/openstack/neutron/networks/v2.0'
import { projectsUrl as projectUrlResponses} from 
'../../../../../../_api_responses/openstack/identity/projects/v3';
import { getFormFieldComponent } from 
'../../../../../_common/_form_fields/form_helpers';

const SERVICE_NAME = openStackServices.networkService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const TrunksWrapperV20 = (props) => {
    const { navigate, location } = props //, changeMenuActiveTab
    const { segmentsSupported, trunksSupported} = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const {currentTab, setCurrentTab} = props
    const [trunksData, setTrunksData] = useState([])
    const [trunks, setTrunks] = useState([])
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = width < Dimensions.tablet_mini.width ? 0.9 : 0.8
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedTrunk, setSelectedTrunk] = useState(null);
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [currentAction, setCurrentAction] = useState("");
    const [trunkDeleteConfirmDialogOpen, setTrunkDeleteConfirmDialogOpen] = useState(false);
    const [selectedTrunks, setSelectedTrunks] = useState([])
    const [trunksSortParams, setTrunksSortParams] = useState("")
    
    const [trunkFilterQueryParams, setTrunkFilterQueryParams] = useState("")
    const [selectedTrunkFilter, setSelectedTrunkFilter] = useState(trunksFilterMenu[0].value)
    const [selectedTrunkFilterValue, setSelectedTrunkFilterValue] = useState("")
    const [trunksFilter, setTrunksFilter] = useState([...trunksFilterMenu])
    const [trunkUpdateData, setTrunkUpdateData] = useState({})
    const [selectedUpdateField, setSelectedUpdateField] = useState("")
    const [trunkUpdateDialogOpen, setTrunkUpdateDialogOpen] = useState(false)

    const [projects, setProjects] = useState([])
    const [networks, setNetworks] = useState([])
    const [subnets, setSubnets] = useState([])
    const [ports, setPorts] = useState([])

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const trunksUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.trunksUrl)[0].url)
    const networksUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.networksUrl)[0].url)
    const subnetsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.subnetsUrl)[0].url)
    const portsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.portsUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleTabChange = (tab_name) => {
        setCurrentTab(tab_name)
    }

    const handleTrunkFilteredSearch = () => {
        if (selectedTrunkFilter && selectedTrunkFilterValue) {
            setTrunkFilterQueryParams(`${selectedTrunkFilter}=${selectedTrunkFilterValue}`)
        } else {
            setTrunkFilterQueryParams("")
        }
        handleDataFetch()
    }

    const handleTrunksDataFormatting = useCallback(() => {
        const formatted_data = trunksData.map((item) => {
            let new_item = {...item}
            const project = projects.filter(p => p.id === item.project_id)
            if (project.length > 0) {
                new_item.project_id = project[0].name
            } else {
                new_item.project_id = "-"
            }
            new_item.sub_ports = item.sub_ports.length
            
            return new_item
        })

        setTrunks(formatted_data)
    },[
        trunksData,
        projects
    ])

    const handleTrunkFilterReset = () => {
        setSelectedTrunkFilter(trunksFilterMenu[0].value)
        setSelectedTrunkFilterValue("")
        setTrunkFilterQueryParams("")
        handleDataFetch()
    }

    const handleDetailCardOpen = useCallback((index) => {
        setSelectedTrunk(trunksData[index].id)
        setSelectedRow(trunksData[index])
        setTimeout(() => setDetailCardOpen(true),100)
    },[trunksData]);

    const handleDetailCardClose = () => {
        setTimeout(() => setDetailCardOpen(false),100)
        setSelectedRow(null)
        setSelectedTrunk(null)
        navigate(location.path,{})
    };

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleTrunksSorting = (field,direction) => {
        const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
        setTrunksSortParams(sort_param)
        handleDataFetch()
    }

    const handleTrunkUpdate = async (p_id,data) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${trunksUrl}/${p_id}`
            const method = "PUT"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                data: {trunk: data},
                token: project_token
            })

            if (nt_response.status_code === trunkUrlResponses.put.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    };

    const onTrunkUpdate = async () => {
        handleTrunkUpdateDialogClose()
        let err = []
        const data = {}
        data[selectedUpdateField] = trunkUpdateData[selectedUpdateField]
        for (let n in selectedTrunks) {
            const resp = await handleTrunkUpdate(selectedTrunks[n].id, data)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorUpdateRecordTitle"
            error_object["error_message"] = "errorUpdateRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const handleTrunkDelete = async (p_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${trunksUrl}/${p_id}`
            const method = "DELETE"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                token: project_token
            })

            if (nt_response.status_code === trunkUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    };

    const onTrunkDelete = async () => {
        handleTrunkDeleteConfirmDialogClose()
        let err = []
        for (let n in selectedTrunks) {
            const resp = await handleTrunkDelete(selectedTrunks[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDetailCardClose()
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onTrunkDeleteConfirm = (n_list) => {
        const selected_n_list = trunksData.filter(n => 
            n_list.includes(n.id))
        setSelectedTrunks([...selected_n_list])
        setTrunkDeleteConfirmDialogOpen(true)
    }

    const handleTrunkDeleteConfirmDialogClose = () => {
        setTrunkDeleteConfirmDialogOpen(false)
    }

    const onTrunkUpdateAdminState = (n_list) => {
        const selected_n_list = trunksData.filter(n => 
            n_list.includes(n.id))
        setSelectedTrunks([...selected_n_list])
        setSelectedUpdateField("admin_state_up")
        setTrunkUpdateDialogOpen(true)
    }

    const handleTrunkUpdateDialogClose = () => {
        setTrunkUpdateDialogOpen(false)
    }

    const handleTrunkUpdateDataChange = (event,field_key) => {
        let updated_data = {...trunkUpdateData}
        const field_type = trunkAdminStateUpdateForm.filter(field => field.field_key === field_key)[0].field_type
        if (field_type === "bool") {
            updated_data[field_key] = event.target.checked
        } else if (field_type === "select") {
            updated_data[field_key] = event
        } else {
            updated_data[field_key] = event.target.value
        }
        setTrunkUpdateData(updated_data)        
    }

    const getUpdateForm = () => {
        let form = trunkAdminStateUpdateForm.filter(field => field.field_key === selectedUpdateField)
        return (
            <FormGroup>
                {form.map(field => {
                    let form_field_options = {}
                    return (
                        getFormFieldComponent(
                            field,
                            trunkUpdateData,
                            handleTrunkUpdateDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const getTrunksActionsList = () => {
        let trunk_actions = []
        let new_action = {}
        new_action["value"] = "update_admin_state"
        new_action["action"] = onTrunkUpdateAdminState
        new_action["keyword"] = "trunkUpdateAdminStateActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        trunk_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "trunk_delete"
        new_action["action"] = onTrunkDeleteConfirm
        new_action["keyword"] = "trunkDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        trunk_actions.push({...new_action})
        
        return trunk_actions
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${neutronServiceDomain}/${neutronServiceVersion}/${trunksUrl}?${trunkFilterQueryParams}${trunksSortParams}`
                    const method = "GET"
                    const trunk_response = await openstackRequest({url:url, method:method, token: project_token})
                    if (trunk_response.status_code === trunkUrlResponses.get.success_response.status_code) {
                        setTrunksData(trunk_response.data.trunks)
                        if (selectedTrunk) {
                            const selected_trunk = trunk_response.data.trunks.filter(item => item.id === selectedTrunk)
                            if (selected_trunk.length > 0) {
                                setSelectedRow(selected_trunk[0])
                            }
                        }
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        trunksUrl, 
        trunkFilterQueryParams,
        dataFetchingRequired,
        defaultAdminProject,
        trunksSortParams,
        selectedTrunk
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${neutronServiceDomain}/${neutronServiceVersion}/${subnetsUrl}`
                const method = "GET"
                const subnets_response = await openstackRequest({url:url, method:method, token: project_token})
                if (subnets_response.status_code === trunkUrlResponses.get.success_response.status_code) {
                    setSubnets(subnets_response.data.subnets)
                }
            }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        subnetsUrl, 
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${neutronServiceDomain}/${neutronServiceVersion}/${portsUrl}`
                const method = "GET"
                const ports_response = await openstackRequest({url:url, method:method, token: project_token})
                if (ports_response.status_code === trunkUrlResponses.get.success_response.status_code) {
                    setPorts(ports_response.data.ports)
                }
            }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        portsUrl, 
        selectedRow,
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === projectUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${neutronServiceDomain}/${neutronServiceVersion}/${networksUrl}`
                const method = "GET"
                const network_response = await openstackRequest({url:url, method:method, token: project_token})
                if (network_response.status_code === networkUrlResponses.get.success_response.status_code) {
                    setNetworks(network_response.data.networks)
                }
            }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        networksUrl,
        defaultAdminProject
    ]);

    useEffect(() => {
        if (trunksData.length > 0) {
            handleTrunksDataFormatting()
        }
    },[
        trunksData,
        handleTrunksDataFormatting
    ])

    useEffect(() => {
        let projects_filter = []
        if (projects.length > 0) {
            projects_filter = projects.map(project => {
                return {keyword: project.name, value: project.id, default: false}
            })
        }

        let networks_filter = []
        if (networks.length > 0) {
            networks_filter = networks.map(network => {
                return {keyword: network.name, value: network.id, default: false}
            })
        }
        
        let trunk_filter_menu = trunksFilterMenu.map(nt => {
            let new_item = {...nt}
            if (nt.value === "project_id") {
                new_item.items = [...projects_filter]
            }
            if (nt.value === "network_id") {
                new_item.items = [...networks_filter]
            }
            return new_item
        })

        setTrunksFilter(trunk_filter_menu)
    },[
        trunksData,
        projects,
        networks
    ])

    useEffect(() => {
        if (!dataFetchingRequired && location.state ) {
            const trunk_id = location.state ? location.state.trunk_id : null
                const trunk_index = trunksData.findIndex(v => v.id === trunk_id);
                if (trunk_index !== -1) {
                    setTimeout(() => handleDetailCardOpen(trunk_index), 600)
                }
        }
    },[
        dataFetchingRequired,
        trunksData,
        handleDetailCardOpen,
        location
    ])

    useEffect(() => {
        if (Object.keys(trunkUpdateData).length === 0) {
            let new_form_data = {}
            for (const n in trunkAdminStateUpdateForm) {
                if (
                    trunkAdminStateUpdateForm[n].field_type === "string" || 
                    trunkAdminStateUpdateForm[n].field_type === "select"
                    ) {
                    new_form_data[trunkAdminStateUpdateForm[n].field_key] = ""
                } else if (trunkAdminStateUpdateForm[n].field_type === "bool") {
                    new_form_data[trunkAdminStateUpdateForm[n].field_key] = trunkAdminStateUpdateForm[n].default_value ? 
                    trunkAdminStateUpdateForm[n].default_value : 
                    false
                }
            }
            setTrunkUpdateData(new_form_data)
        }
    },[
        trunkUpdateData
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <TrunksSubheaderV20 
                selectedFilter={selectedTrunkFilter} 
                setSelectedFilter={setSelectedTrunkFilter}
                selectedFilterValue={selectedTrunkFilterValue}
                setSelectedFilterValue={setSelectedTrunkFilterValue}
                handleDataFetch={handleDataFetch}
                filterMenu={trunksFilter}
                handleFilteredSearch={handleTrunkFilteredSearch}
                handleFilterReset={handleTrunkFilterReset}
                currentTab={currentTab}
                handleTabChange={handleTabChange}
                subnets={subnets}
                projects={projects}
                segmentsSupported={segmentsSupported}
                trunksSupported={trunksSupported}
            />
        {isLoading && <CustomBackdrop open={isLoading} />}
        {!isLoading &&
            <TrunksTableV20 
            trunksData={trunks}
                setTrunksData={setTrunks}
                handleRowSelection={handleDetailCardOpen}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getTrunksActionsList()}
                sortHandler={handleTrunksSorting}
            />
        }
        {selectedRow !== null && 
            <CustomSideDrawer 
                open={detailCardOpen}
                widthWeight={WIDTH_WEIGHT}
                handleDrawerOpen={handleDetailCardOpen}
                handleDrawerClose={handleDetailCardClose}
            > 
                <TrunkDetailV20
                    selectedRow={selectedRow}
                    widthWeight={WIDTH_WEIGHT}
                    handleDataFetch={handleDataFetch}
                    handleDelete={onTrunkDeleteConfirm}
                    handleAdminStateUpdate={onTrunkUpdateAdminState}
                    projects={projects}
                    ports={ports}
                    subnets={subnets}
                />
            </CustomSideDrawer>
        }
        <CustomDialog
            open={trunkDeleteConfirmDialogOpen}
            onClose={handleTrunkDeleteConfirmDialogClose}
            dialogTitle={{
                title: defaultTexts.trunkDeleteConfirmTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `${defaultTexts.trunkDeleteConfirmText}`, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onTrunkDelete, 
                sx: {color: 'primary.main'}}]}
        />
        <CustomDialog
            open={trunkUpdateDialogOpen}
            onClose={handleTrunkUpdateDialogClose}
            dialogTitle={{
                title: defaultTexts.trunkAdminStateUpdateTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onTrunkUpdate, 
                sx: {color: 'primary.main'}}]}
        >
                {getUpdateForm()}
        </CustomDialog>
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts[error.error_title], 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts[error.error_message]}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
    </Box>
    )
};

export default TrunksWrapperV20;