import React from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { 
    identityKeystonConstants,
    openStackServices
} from '../../../../config/openStackConstants';
import WrapperBox from '../../../_common/WrapperBox';
import NotPurchasedService from '../../../_common/NotPurchasedService';
import Grid from "@mui/material/Grid";
import DomainsGraphContentV3 from "./domainsGraphContentV3";
import RegionsGraphContentV3 from "./regionsGraphContentV3";
import ProjectsGraphContentV3 from "./projectsGraphContentV3";
import UsersGraphContentV3 from "./usersGraphContentV3";

const SERVICE_NAME = openStackServices.identityService
const SERVICE_TITLE = 'Identity (Keystone)'

const IdentityGraphWrapper = (props) => {
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
    const navigate = useNavigate();

    if (!isAuthenticated) {
        navigate('/');
    }

    const purchasedServices = useSelector(state => 
        state.openstack.purchasedServices);

    const purchasedService = purchasedServices.filter(
        item => item.service === SERVICE_NAME)
    if (purchasedService.length === 0) {
        return (
            <NotPurchasedService service={SERVICE_TITLE} />
        )
    }
    if (!identityKeystonConstants.supported_api_versions.includes(
        purchasedService[0].config_params.api_version)) {
        return (
            <WrapperBox>
                Configured API Version for {SERVICE_TITLE} is not supported!
            </WrapperBox>
        )
    }

    if (purchasedService[0].config_params.api_version === "v3") {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                    <DomainsGraphContentV3 />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <RegionsGraphContentV3 />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <ProjectsGraphContentV3 />
                </Grid>
                <Grid item xs={12} lg={6}>
                    <UsersGraphContentV3 />
                </Grid>
            </Grid>
        )
    }
};

export default IdentityGraphWrapper;