
import React from "react";
import { useSelector } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Constants from "../../config/constants";

const drawerWidth = Constants.drawerWidth;

const CustomTableSkeleton = (props) => {
    const { sx } = props
    const drawerOpen = useSelector(state => state.drawer.drawerOpened);
    const theme = useTheme();

    return (
        <Grid 
            container
            alignItems="center"
            spacing={1}
            sx={{
                maxWidth: drawerOpen ? 
                `calc(100vw - ${drawerWidth}px)` : 
                `calc(100vw - ${110}px)`,
                mt: 8,
                ...sx
            }}
        >
            <Grid item xs={12}>  
                <Skeleton 
                    variant="rounded" 
                    width="100%" 
                    height={30} 
                    sx={{
                        bgcolor: theme.palette.mode === "dark" ? 
                        theme.palette.background.paper :
                        theme.palette.vLightGray
                    }}
                />
            </Grid>
            {[1,2,3,4,5].map((item,index) => 
                <Grid item key={index} xs={12}>
                    <Grid container alignItems="center" spacing={1}>
                        <Grid item>  
                            <Skeleton 
                                variant="circular" 
                                height={30} 
                                width={30}
                                sx={{
                                    bgcolor: theme.palette.mode === "dark" ? 
                                    theme.palette.background.paper :
                                    theme.palette.vLightGray
                                }}
                            />
                        </Grid>
                        <Grid item sx={{flexGrow: 1}}>  
                            <Skeleton 
                                variant="rounded" 
                                width="100%" 
                                height={30} 
                                sx={{
                                    bgcolor: theme.palette.mode === "dark" ? 
                                    theme.palette.background.paper :
                                    theme.palette.vLightGray
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    )
};

export default CustomTableSkeleton;