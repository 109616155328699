import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import SubnetsWrapperV20 from './subnets/subnetsWrapperV2.0';
import FloatingIPsWrapperV20 from './floating_ips/floatingIPsWrapperV2.0';
import VirtualIPsWrapperV20 from './virtual_ips/virtualIPsWrapperV2.0';
import { openStackServices, 
    networkNeutronConstants
} from '../../../../../config/openStackConstants';
import { 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../_network/openstack_request';
import { networksUrl as networkUrlResponses } 
from '../../../../../_api_responses/openstack/neutron/networks/v2.0';
import RoutersWrapperV20 from './routers/routersWrapperV2.0';

const SERVICE_NAME = openStackServices.networkService

const L3NetworkingWrapperV20 = (props) => {
    const { navigate, location, changeMenuActiveTab } = props
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [currentTab, setCurrentTab] = useState("subnets")
    const [virtualIPsSupported, setVirtualIPsSupported] = useState(false)

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const virtualIPsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.virtualIPsUrl)[0].url)

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${neutronServiceDomain}/${neutronServiceVersion}/${virtualIPsUrl}`
                const method = "GET"
                const vip_response = await openstackRequest({url:url, method:method, token: project_token})

                if (vip_response.status_code === networkUrlResponses.get.success_response.status_code) {
                    setVirtualIPsSupported(true)
                } else if (vip_response.status_code === 404) {
                    setVirtualIPsSupported(false)
                }
            }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        virtualIPsUrl, 
        defaultAdminProject
    ]);

    return (
        <Box>
            {currentTab === "subnets" && 
                <SubnetsWrapperV20 
                    navigate={navigate}
                    location={location}
                    changeMenuActiveTab={changeMenuActiveTab}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    virtualIPsSupported={virtualIPsSupported}
                />
            }
            {currentTab === "floating_ips" && 
                <FloatingIPsWrapperV20
                    navigate={navigate}
                    location={location}
                    changeMenuActiveTab={changeMenuActiveTab}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    virtualIPsSupported={virtualIPsSupported}
                />
            }
            {currentTab === "virtual_ips" &&
                <VirtualIPsWrapperV20
                    navigate={navigate}
                    location={location}
                    changeMenuActiveTab={changeMenuActiveTab}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    virtualIPsSupported={virtualIPsSupported}
                />
            }
            {currentTab === "routers" &&
                <RoutersWrapperV20
                    navigate={navigate}
                    location={location}
                    changeMenuActiveTab={changeMenuActiveTab}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                    virtualIPsSupported={virtualIPsSupported}
                />
            }
        </Box>
    )
};

export default L3NetworkingWrapperV20;