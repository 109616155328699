import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import WrapperBox from '../../../../_common/WrapperBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOffIcon from '@mui/icons-material/EditOff';
import Paper from '@mui/material/Paper';
import useWindowDimensions from 
'../../../../_common/WindowDimensions';
import Constants from '../../../../../config/constants';
import { IconButton }  from '@mui/material';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { openstackRequest } from 
'../../../../../_network/openstack_request';
import { groupsUrl as groupsUrlResponses } from 
'../../../../../_api_responses/openstack/identity/groups/v3';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { identityKeystonConstants } from 
'../../../../../config/openStackConstants';
import CustomDialog from 
'../../../../_common/CustomDialog';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import { FormGroup } from '@mui/material';
import { groupDataUpdateForm, groupDataSchema } from 
'../../../../../_data/openstack/identity/groups/v3';
import { getDetailDataComponent } from 
'../../../../_common/common_helpers';
import ServiceContentHeader from '../../../../_common/ServiceContentHeader';
import CustomCard from '../../../../_common/CustomCard';
import { Divider } from '@mui/material';
import CustomSelectField from 
'../../../../_common/_form_fields/CustomSelectField';
import IdentityRoleAssignmentsV3 from '../roles/identityRoleAssignmentsV3';

const SERVICE_NAME = openStackServices.identityService

const IdentityGroupDetailV3 = (props) => {
    const { selectedRow } = props
    const { groupsRecords } = props
    const groupRecord = groupsRecords.filter(group => group.id === selectedRow.id)[0]
    const { widthWeight } = props
    const { 
        handleFetchData, 
        handleDrawerClose
    } = props
    const { width } = useWindowDimensions();
    const [editMode, setEditMode] = useState(false);
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const [error, setError] = useState()
    const [successGroupDelete, setSuccessGroupDelete] = useState()
    const [successGroupUpdate, setSuccessGroupUpdate] = useState()
    
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [successUpdateDialogOpen, setSuccessUpdateDialogOpen] = useState(false);
    
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

    const [updateFormData, setUpdateFormData] = useState(
        {...groupRecord}
    )

    const [groupUsers, setGroupUsers] = useState([])

    const [groupDomain, setGroupDomain] = useState({})
    const [nonGroupDomainUsers, setNonGroupDomainUsers] = useState([])
    
    const [groupActions, setGroupActions] = useState([]);
    const [currentAction, setCurrentAction] = useState("");

    const [roleAssignmentsDialogOpenMode, setRoleAssignmentsDialogOpenMode] = useState(false);
    const [selectedRoleAssignments, setSelectedRoleAssignments] = useState([])

    const [groupSubMenu, setGroupSubMenu] = useState([
        {keyword: "submenuDetails", navigation: "/group-details", is_active: true},
        {keyword: "submenuUsers", navigation: "/group-users", is_active: false},
        {keyword: "submenuRoles", navigation: "/group-roles", is_active: false}
    ])

    const [currentTab, setCurrentTab] = useState("/group-details")

    const [addUserMode, setAddUserMode] = useState(false);
    const [removeUserMode, setRemoveUserMode] = useState(false);
    const [selectedUser, setSelectedUser] = useState("");
    const [userRemoveList, setUserRemoveList] = useState([]);
    
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const groupsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.groupsUrl)[0].url)
    const domainsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.domainsUrl)[0].url)

    const usersUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.usersUrl)[0].url)

    const roleAssignmentsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.roleAssignmentsUrl)[0].url)


    const handleRoleAssignmentsDialogClose = () => {
        setSelectedRoleAssignments([])
        setRoleAssignmentsDialogOpenMode(false)
    }

    const handleRoleAssignmentsPopupOpen = useCallback(async () => {
        let role_assignments = []
        let url = ""
        
        url = `${identityServiceDomain}/${identityServiceVersion}/${roleAssignmentsUrl}/?group.id=${selectedRow.id}&&include_names=true`
        const method = "GET"

        const role_assignments_response = await openstackRequest({url: url, method: method})
        role_assignments = role_assignments_response.data.role_assignments
        
        setSelectedRoleAssignments(role_assignments)
        setRoleAssignmentsDialogOpenMode(true)
    },[
        identityServiceDomain,
        identityServiceVersion,
        roleAssignmentsUrl,
        selectedRow
    ])

    const handleAddUserDialogClose = () => {
        setSelectedUser("")
        setAddUserMode(false)
    }

    const handleRemoveUserDialogClose = () => {
        setSelectedUser("")
        setRemoveUserMode(false)
    }

    const handleAddUserDialogOpen = useCallback(() => {
        setAddUserMode(true)
    },[])

    const handleRemoveUserDialogOpen = useCallback(() => {
        setRemoveUserMode(true)
    },[])

    const handleFormDataChange = (event,field_key) => {
        let new_form_data = {...updateFormData}
        if (groupDataUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (groupDataUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setUpdateFormData(new_form_data)
    }

    const getEmptyListNoteComponent = (text) => {
        return (
            <WrapperBox>
                <CustomCard 
                    overflow="hidden" 
                    cardHeight={220}
                    cardWidth={300}
                    card_sx={{
                        mt: 15, 
                        opacity: 0.5, 
                        borderRadius: 50,
                    }}
                    box_sx={{
                        display: "flex", 
                        alignItems: "center", 
                        justifyContent: "center",
                        overflow: "hidden",
                        right: 0
                    }}
                >
                    <CustomText 
                        size="h5" 
                        sx={{
                            textAlign: "center",
                        }}
                    >
                        {text}
                    </CustomText>
                </CustomCard>
            </WrapperBox>
        )
    }

    const getDataForm = () => {
        return (
            <FormGroup>
                {groupDataUpdateForm.map(field => 
                    getFormFieldComponent(
                        field,
                        updateFormData,
                        handleFormDataChange,
                        defaultTexts[field.label],
                        {size:"medium",
                        withHelp: field.with_help_text,
                        helpText: defaultTexts[field.help_text],
                        sx: {
                            my: 1, 
                            width: width * widthWeight * 0.8
                        }}
                    )
            )}
        </FormGroup>)
    }

    const handleEditModeChange = () => {
        handleGroupDetailTabChange("/group-details")
        setEditMode(true)
    }

    const handleEditModeReset = () => {
        setUpdateFormData({...selectedRow})
        handleGroupDetailTabChange("/group-details")
        setEditMode(false)
    }
    
    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }
    const handleSuccessDialogClose = () => {
        handleDrawerClose()
        setSuccessGroupDelete(null);
        setSuccessDialogOpen(false);
    }
    const handleSuccessUpdateDialogClose = () => {
        setSuccessGroupUpdate(null)
        setUpdateFormData({...selectedRow})
        setSuccessUpdateDialogOpen(false);
    }
    const handleConfirmDeleteDialogOpen = () => {
        setConfirmDeleteDialogOpen(true)
    }
    const handleConfirmDeleteDialogClose = () => {
        setConfirmDeleteDialogOpen(false)
    }

    const handleDeleteGroup = async () => {
        handleConfirmDeleteDialogClose()
        const url = `${identityServiceDomain}/${identityServiceVersion}/${groupsUrl}/${selectedRow.id}`
        const method = "DELETE"

        const group_response = await openstackRequest({url: url, method: method})
        if (group_response.status_code === groupsUrlResponses.delete.success_response.status_code) {
            setSuccessGroupDelete({
                success_title: groupsUrlResponses.delete.success_response.response_title, 
                success_message: groupsUrlResponses.delete.success_response.response_message
            })
            handleFetchData()
        } else {
            const error_response = groupsUrlResponses.delete.error_response.filter(
                error_item => error_item.status_code === group_response.status_code)
            if (error_response.length > 0) {
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: group_response.error
                }
                setError(errorObject)
            } else {
                const error_response = groupsUrlResponses.delete.error_response.filter(
                    error_item => error_item.status_code === "unknown")
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: group_response.error
                }
                setError(errorObject)
            }
        }
    }

    const handleEditGroup = async () => {
        const url = `${identityServiceDomain}/${identityServiceVersion}/${groupsUrl}/${selectedRow.id}`
        const method = "PATCH"

        const group_response = await openstackRequest({
            url: url, 
            method: method, 
            data: {group: updateFormData}
        })
        if (group_response.status_code === groupsUrlResponses.patch.success_response.status_code) {
            handleFetchData()
            handleEditModeReset()
            setSuccessGroupUpdate({
                success_title: groupsUrlResponses.patch.success_response.response_title, 
                success_message: groupsUrlResponses.patch.success_response.response_message
            })
        } else {
            const error_response = groupsUrlResponses.patch.error_response.filter(
                error_item => error_item.status_code === group_response.status_code)
            if (error_response.length > 0) {
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: group_response.error
                }
                setError(errorObject)
            } else {
                const error_response = groupsUrlResponses.patch.error_response.filter(
                    error_item => error_item.status_code === "unknown")
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: group_response.error
                }
                setError(errorObject)
            }
        }
    }

    const handleGroupDetailTabChange = useCallback((navigation) => {
        if (navigation === "/group-roles") {
            handleRoleAssignmentsPopupOpen()
        } else {
            let newGroupSubmenuData = groupSubMenu.map(item => {
                if (item.navigation === navigation) {
                    item.is_active = true
                } else {
                    item.is_active = false
                }
                return item
            })
            setGroupSubMenu(newGroupSubmenuData)
            setCurrentTab(navigation)
        }
    },[
        groupSubMenu,
        handleRoleAssignmentsPopupOpen
    ])

    const onUserAddToGroup = async () => {
        const url = `${identityServiceDomain}/${identityServiceVersion}/${groupsUrl}/${selectedRow.id}/users/${selectedUser}`
        const method = "PUT"

        const group_response = await openstackRequest({
            url: url, 
            method: method, 
            data: {}
        })
        if (group_response.status_code === groupsUrlResponses.delete.success_response.status_code) {
            handleAddUserDialogClose()
            handleFetchData()
            setSelectedUser("")
            setSuccessGroupUpdate({
                success_title: groupsUrlResponses.patch.success_response.response_title, 
                success_message: groupsUrlResponses.patch.success_response.response_message
            })
        } else {
            const error_response = groupsUrlResponses.patch.error_response.filter(
                error_item => error_item.status_code === group_response.status_code)
            if (error_response.length > 0) {
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: group_response.error
                }
                setError(errorObject)
            } else {
                const error_response = groupsUrlResponses.patch.error_response.filter(
                    error_item => error_item.status_code === "unknown")
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: group_response.error
                }
                setError(errorObject)
            }
        }
    }

    const onUserRemoveFromGroup = async () => {
        const url = `${identityServiceDomain}/${identityServiceVersion}/${groupsUrl}/${selectedRow.id}/users/${selectedUser}`
        const method = "DELETE"

        const group_response = await openstackRequest({
            url: url, 
            method: method
        })
        if (group_response.status_code === groupsUrlResponses.delete.success_response.status_code) {
            handleRemoveUserDialogClose()
            handleFetchData()
            setSuccessGroupUpdate({
                success_title: groupsUrlResponses.delete.success_response.response_title, 
                success_message: groupsUrlResponses.patch.success_response.response_message
            })
        } else {
            const error_response = groupsUrlResponses.patch.error_response.filter(
                error_item => error_item.status_code === group_response.status_code)
            if (error_response.length > 0) {
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: group_response.error
                }
                setError(errorObject)
            } else {
                const error_response = groupsUrlResponses.patch.error_response.filter(
                    error_item => error_item.status_code === "unknown")
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: group_response.error
                }
                setError(errorObject)
            }
        }
    }

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessDialogOpen(true)
    },[successGroupDelete]);

    useEffect(() => {
        setSuccessUpdateDialogOpen(true)
    },[successGroupUpdate]);

    useEffect(() => {
        if (selectedRow !== null) {
            (async () => {
                const url = `${identityServiceDomain}/${identityServiceVersion}/${groupsUrl}/${selectedRow.id}/users`
                const method = "GET"

                const group_users = await openstackRequest({
                    url: url, 
                    method: method
                })
                if (group_users.status_code === groupsUrlResponses.get.success_response.status_code) {
                    setGroupUsers(group_users.data.users)
                    const user_remove_list = group_users.data.users.map(item => {return {keyword: item.name, value: item.id}})
                    setUserRemoveList(user_remove_list)
                } else {
                    setGroupUsers([])
                    setUserRemoveList([])
                    }
                }
            )();
        }
    },[ 
        selectedRow,
        identityServiceDomain,
        identityServiceVersion,
        groupsUrl
    ])

    useEffect(() => {
        if (selectedRow !== null) {
            (async () => {
                const url = `${identityServiceDomain}/${identityServiceVersion}/${domainsUrl}/${groupRecord.domain_id}`
                const method = "GET"

                const group_domain = await openstackRequest({
                    url: url, 
                    method: method
                })
                
                if (group_domain.status_code === groupsUrlResponses.get.success_response.status_code) {
                    setGroupDomain(group_domain.data.domain)
                } else {
                    setGroupDomain({})
                    }
            })();
        }
    },[
        selectedRow,
        identityServiceDomain,
        identityServiceVersion,
        domainsUrl,
        groupRecord
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${usersUrl}/?domain_id=${groupRecord.domain_id}`
                const method = "GET"

                const domain_users = await openstackRequest({
                    url: url, 
                    method: method
                })
                
                if (domain_users.status_code === groupsUrlResponses.get.success_response.status_code) {
                    const group_user_ids = groupUsers.map(u => u.id)
                    const non_group_users = domain_users.data.users.filter(user => 
                        !group_user_ids.includes(user.id)
                    ).map(item => {return {keyword: item.name, value: item.id}})
                    setNonGroupDomainUsers(non_group_users)
                } else {
                    setNonGroupDomainUsers([])
                }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        usersUrl,
        groupRecord,
        groupUsers
    ]);

    useEffect(() => {
        if (selectedRow !== null) {
            let group_actions = []
            let new_action = {}
            new_action = {}
            new_action["value"] = "group_add_user"
            new_action["action"] = handleAddUserDialogOpen
            new_action["keyword"] = "groupAddUserActionTitle"
            new_action["button_text"] = "selectButtonTitleText"
            group_actions.push({...new_action})
            new_action = {}
            new_action["value"] = "group_remove_user"
            new_action["action"] = handleRemoveUserDialogOpen
            new_action["keyword"] = "groupRemoveUserActionTitle"
            new_action["button_text"] = "selectButtonTitleText"
            group_actions.push({...new_action})
            
            setGroupActions(group_actions)
        }
    },[
        selectedRow,
        defaultTexts,
        handleAddUserDialogOpen,
        handleRemoveUserDialogOpen
    ]);

    return (
        <React.Fragment>
        {selectedRow !== null && 
        <WrapperBox>
            <ServiceContentHeader 
                service_menu={groupSubMenu}
                service_menu_titles={defaultTexts}
                onClick={handleGroupDetailTabChange}
            />
        </WrapperBox>}
        {currentTab === "/group-details" &&
        <React.Fragment>
            {!editMode ? <Stack 
                spacing={2} 
                sx={{ p: 2, mt: 1, flexWrap: "wrap"}}
            >
                    {groupDataSchema.map((field) => {
                        let value = selectedRow ? 
                        selectedRow[field.field_key] : ""
                        if (field.field_key === "domain_id") {
                            value = groupDomain.name
                        }
                        return (
                            getDetailDataComponent({
                                fieldType: field.field_type,
                                fieldKey: field.field_key,
                                label: defaultTexts[field.label],
                                value: value,
                                textOnTrue: defaultTexts[field.value_on_true] ? 
                                defaultTexts[field.value_on_true] : 
                                defaultTexts.formValueYes,
                                textOnFalse: defaultTexts[field.value_on_false] ? 
                                defaultTexts[field.value_on_false] : 
                                defaultTexts.formValueNo,
                                defaultTexts: defaultTexts
                            })
                        )
                    })}
                </Stack> : 
                <Stack 
                    sx={{
                        m: 2, 
                        alignItems: 'start'
                    }}>
                <CustomText 
                    size="h6" 
                    sx={{
                        color: "primary.main", 
                        mb: 2
                    }}>
                    {defaultTexts.updateGroupFormTitle}
                </CustomText>
                {getDataForm()}
                <Button 
                    variant="contained"
                    color="secondary"
                    sx={{mt: 5}}
                    onClick={handleEditGroup}
                >
                    {defaultTexts.saveButtonText}
                </Button>
            </Stack>}
            </React.Fragment>}
            
            {currentTab === "/group-users" && 
            groupUsers.length > 0 &&
                <Stack 
                    spacing={2} 
                    sx={{ p: 2, mt: 1}}
                >
                    {groupUsers.map(user => {
                        return (
                            <Stack key={user.id}>
                                <CustomText size="h6">{user.name}</CustomText>
                                <CustomText size="p">{user.description}</CustomText>
                                <Divider />
                            </Stack>
                        )
                        
                    })}
                </Stack>
            } 
            {currentTab === "/group-users" && 
            groupUsers.length === 0 &&
                getEmptyListNoteComponent(defaultTexts.noUsersNoteText)
            }
            <Paper sx={{ 
                position: 'fixed', 
                bottom: 0, 
                height: Constants.actions_bar_height + 20,
                left: width - (width * widthWeight), 
                right: 12
                }} 
                elevation={24}
                square={true}
            >
                <Grid 
                    container 
                    alignItems="center"  
                    justifyContent="space-between"
                >
                    <Grid item>
                        <CustomSelectField 
                            items={groupActions} 
                            currentValue={currentAction}
                            setCurrentValue={setCurrentAction}
                            item_titles={defaultTexts}
                            label={defaultTexts.actionsDropdownLabelText}
                            empty={true}
                            size="small"
                            sx={{m: 1, width: 280}}
                        />
                        {currentAction.length > 0 && 
                            <Button 
                                    variant="contained"
                                    color="secondary"
                                    sx={{m: 1, height: '70%'}}
                                    onClick={groupActions.filter(
                                        action => action.value === currentAction)[0].action
                                    }
                                >
                                {defaultTexts[groupActions.filter(
                                    action => action.value === currentAction)[0].button_text]}
                            </Button>
                        }
                    </Grid>
                    <Grid item>
                        {!editMode ? <IconButton onClick={handleEditModeChange}>
                                <EditIcon color="primary" />
                            </IconButton> :
                            <IconButton onClick={handleEditModeReset}>
                                <EditOffIcon color="primary"/>
                            </IconButton>
                        }
                        {selectedRow !== null && 
                            <IconButton onClick={handleConfirmDeleteDialogOpen}>
                                <DeleteIcon 
                                    color="primary"
                                />
                            </IconButton>}
                    </Grid>
                </Grid>
            </Paper>
            <CustomDialog
                open={roleAssignmentsDialogOpenMode}
                onClose={handleRoleAssignmentsDialogClose}
                dialogTitle={{
                    title: defaultTexts.roleAssignmentsTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                maxWidth="lg"
            >
                <IdentityRoleAssignmentsV3
                    roleAssignments={selectedRoleAssignments}
                    target={`${selectedRow.name}`}
                    handleRoleAssignmentChange={handleRoleAssignmentsPopupOpen}
                />
            </CustomDialog>
            {successGroupDelete && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[successGroupDelete.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successGroupDelete.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {successGroupUpdate && <CustomDialog
                open={successUpdateDialogOpen}
                onClose={handleSuccessUpdateDialogClose}
                dialogTitle={{
                    title: defaultTexts[successGroupUpdate.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successGroupUpdate.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>Details:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            {confirmDeleteDialogOpen && <CustomDialog
                open={confirmDeleteDialogOpen}
                onClose={handleConfirmDeleteDialogClose}
                dialogTitle={{
                    title: defaultTexts.deleteConfirmationDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.deleteGroupConfirmationDialogMessage, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: handleDeleteGroup, 
                    sx: {color: 'primary.main'}}]}
            />}
            <CustomDialog
                open={addUserMode}
                onClose={handleAddUserDialogClose}
                dialogTitle={{
                    title: defaultTexts.addUserToGroupDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.addRemoveUserToGroupDialogText, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onUserAddToGroup, 
                    sx: {color: 'primary.main'}}]}
            >
                <CustomSelectField
                    items={nonGroupDomainUsers} 
                    currentValue={selectedUser}
                    setCurrentValue={setSelectedUser}
                    label={defaultTexts.userFormFieldLabel}
                    self_item_titles={true}
                    empty={true}
                    size="large"
                    sx={{my: 1, width: '90%'}}
                />
            </CustomDialog>
            <CustomDialog
                open={removeUserMode}
                onClose={handleRemoveUserDialogClose}
                dialogTitle={{
                    title: defaultTexts.removeUserToGroupDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.addRemoveUserToGroupDialogText, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onUserRemoveFromGroup, 
                    sx: {color: 'primary.main'}}]}
            >
                <CustomSelectField
                    items={userRemoveList} 
                    currentValue={selectedUser}
                    setCurrentValue={setSelectedUser}
                    label={defaultTexts.userFormFieldLabel}
                    self_item_titles={true}
                    empty={true}
                    size="large"
                    sx={{my: 1, width: '90%'}}
                />
            </CustomDialog>
        </React.Fragment>
    )
};

export default IdentityGroupDetailV3;