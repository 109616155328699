import React from 'react';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

const CustomDismissCard = (props) => {
    const { cardWidth, cardHeight, minWidth} = props
    const { sx } = props
    const { cardID, onClose } = props

    const handleCloseCard = () => {
        if ( onClose ) {
            onClose(cardID)
        }
    }

    return (
        <Box 
            sx={{
                position: "relative",
                border: 1,
                borderRadius: 2,
                borderColor: "vLightGray",
                width: cardWidth ? cardWidth: '100%',
                minWidth: minWidth,
                height: cardHeight ? cardHeight : '100%',
                p: 1,
                pr: 0,
                m: 1,
                mt: 2,
                boxShadow: 3,
                ...sx
            }}
        >
            <Box 
                sx={{
                    backgroundColor: "vLightGray",
                    boxShadow: 3,
                    borderRadius: 50,
                    width: 35,
                    height: 35,
                    position: "absolute",
                    top: -7,
                    left: -7
                }}
            >
                <IconButton onClick={handleCloseCard}>
                    <CloseIcon fontSize="small" sx={{color: "mediumGray"}} />
                </IconButton>
            </Box>
            {props.children}
        </Box>
    )
};

export default CustomDismissCard;