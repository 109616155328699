import { openStackServiceLabels } 
from '../../../config/openStackConstants';
import { Stack } from '@mui/material';
import { Divider } from '@mui/material';
import CustomText from 
'../../_common/CustomText';

export const getServiceDetailDataComponent = (options) => {
    let data_comp = null
    switch (options.fieldKey) {
        case "service_name": 
            data_comp = (
                <Stack 
                    key={options.fieldKey}
                    spacing={1}
                >
                    <Stack 
                        direction="row" 
                        justifyContent="space-between"
                    >
                        <CustomText 
                            sx={{ 
                                color: "text.secondary"
                            }}
                        >
                            {options.defaultTexts[options.label]}
                        </CustomText>
                        <CustomText 
                            size="p"
                            sx={{ 
                                wordWrap: "break-word",
                                maxWidth: 350
                            }}
                        >
                            {openStackServiceLabels[options.value]}
                        </CustomText>
                    </Stack>
                    <Divider />
                </Stack>);
            break;
        case "resource_suspend_period": 
            var value_label = options.defaultTexts.minutesUnitLabel
            var data_value = options.value
            data_comp = (
            <Stack 
                key={options.fieldKey}
                spacing={1} 
            >
                <Stack
                    direction="row" 
                    justifyContent="space-between"
                >
                    <CustomText 
                        sx={{ 
                            color: "text.secondary"
                        }}
                    >
                        {options.defaultTexts[options.label]}
                    </CustomText>
                    <CustomText 
                        sx={{ 
                            color: "text.primary"
                        }}
                    >
                        {data_value} {value_label}
                    </CustomText>
                </Stack>
                <Divider />
            </Stack>);
            break;
            case "resource_delete_period": 
                value_label = options.defaultTexts.minutesUnitLabel
                data_value = options.value
                data_comp = (
                <Stack 
                    key={options.fieldKey}
                    spacing={1} 
                >
                    <Stack
                        direction="row" 
                        justifyContent="space-between"
                    >
                        <CustomText 
                            sx={{ 
                                color: "text.secondary"
                            }}
                        >
                            {options.defaultTexts[options.label]}
                        </CustomText>
                        <CustomText 
                            sx={{ 
                                color: "text.primary"
                            }}
                        >
                            {data_value} {value_label}
                        </CustomText>
                    </Stack>
                    <Divider />
                </Stack>);
                break;
            case "resource_suspend_policy": 
            data_comp = (
            <Stack 
                key={options.fieldKey}
                spacing={1} 
            >
                <Stack
                    direction="row" 
                    justifyContent="space-between"
                >
                    <CustomText 
                        sx={{ 
                            color: "text.secondary"
                        }}
                    >
                        {options.defaultTexts[options.label]}
                    </CustomText>
                    <CustomText sx={{textTransform: "capitalize"}}>
                        {options.value}
                    </CustomText>
                </Stack>
                <Divider />
            </Stack>);
            break;
            case "resource_delete_policy": 
            data_comp = (
            <Stack 
                key={options.fieldKey}
                spacing={1} 
            >
                <Stack
                    direction="row" 
                    justifyContent="space-between"
                >
                    <CustomText 
                        sx={{ 
                            color: "text.secondary"
                        }}
                    >
                        {options.defaultTexts[options.label]}
                    </CustomText>
                    <CustomText sx={{textTransform: "capitalize"}}>
                        {options.value}
                    </CustomText>
                </Stack>
                <Divider />
            </Stack>);
            break;
            case "is_identity_provider": 
            data_comp = (
            <Stack 
                key={options.fieldKey}
                spacing={1} 
            >
                <Stack
                    direction="row" 
                    justifyContent="space-between"
                >
                    <CustomText 
                        sx={{ 
                            color: "text.secondary"
                        }}
                    >
                        {options.defaultTexts[options.label]}
                    </CustomText>
                    <CustomText
                        sx={{ 
                            color: options.value ? 
                            "success.main" : 
                            "warning.main"
                        }}
                    >
                        {options.value ?
                            options.textOnTrue :
                            options.textOnFalse
                        }
                    </CustomText>
                </Stack>
                <Divider />
            </Stack>);
            break;
        default:
            data_comp = (
                <Stack 
                    key={options.fieldKey}
                    spacing={1} 
                >
                    <Stack 
                        direction="row" 
                        justifyContent="space-between"
                    >
                        <CustomText 
                            sx={{ 
                                color: "text.secondary"
                            }}
                        >
                            {options.defaultTexts[options.label]}
                        </CustomText>
                        <CustomText 
                            sx={{ 
                                wordWrap: "break-word",
                                maxWidth: 350
                            }}
                        >
                            {options.value}
                        </CustomText>
                    </Stack>
                    <Divider />
                </Stack>);
    }
    return data_comp
}

export const timeToAgeConverter = (datetime) => {
    const today = new Date()
    // Calculate difference in total milliseconds
    const utc = new Date(today.getTime() + today.getTimezoneOffset() * 60000);
    let diffMs = utc - datetime;

    // Calculate difference in total seconds
    let diffSec = Math.floor(diffMs / 1000);

    // Calculate minutes
    let diffMin = Math.floor(diffSec / 60);
    diffSec %= 60;

    // Calculate hours
    let diffHrs = Math.floor(diffMin / 60);
    diffMin %= 60;

    // Calculate days
    //let diffDays = Math.floor(diffHrs / 24);
    diffHrs %= 24;

    // Calculate years
    let years = utc.getFullYear() - datetime.getFullYear();
    let months = utc.getMonth() - datetime.getMonth();

    if (months < 0) {
        years--;
        months += 12;
    }

    // Calculate days
    let days = utc.getDate() - datetime.getDate();
    if (days < 0) {
        months--;
        if (months < 0) {
            years--;
            months += 12;
        }
        const prevMonth = (utc.getMonth() - 1 + 12) % 12;
        days += new Date(utc.getFullYear(), prevMonth + 1, 0).getDate();
    }

    // Return result
    if (years > 0 || months > 0 || days > 0) {
        const years_string = years > 0 ? `${years} year(s) ` : ""
        const months_string = months > 0 ? `${months} month(s) ` : ""
        const days_string = days > 0 ? `${days} day(s)` : ""
        return years_string + months_string + days_string;
    } else {
        const hours_string = diffHrs > 0 ? `${diffHrs} hour(s) ` : ""
        const minutes_string = diffMin > 0 ? `${diffMin} minute(s) ` : ""
        const seconds_string = diffMin > 0 ? `${diffSec} second(s) ` : ""

        return hours_string + minutes_string + seconds_string
    }
}