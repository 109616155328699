export const filterMenu = [
    {
        keyword: "projectFormFieldLabel", 
        value: "project_id", 
        type: "select", 
        multiple: false,
        items: []
    },
    {
        keyword: "statusFormFieldLabel", 
        value: "invoice_status", 
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "pendingStatusChartLegent", 
                value: "pending", 
                default: false
            },
            {
                keyword: "paidStatusChartLegent", 
                value: "paid", 
                default: false
            },
            {
                keyword: "obsoleteStatusChartLegent", 
                value: "obsolete", 
                default: false
            }
        ]
    }
]

export const dateFilterMenu = [
    {
        keyword: "timeStampFilterChoice",
        value: "time_stamp",
        default: "true"
    },
    {
        keyword: "dueDateFilterChoice",
        value: "due_date",
        default: "false"
    }
]