import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../../../_common/CustomTable';
import { serverGroupsDataSchema } from 
'../../../../../_data/openstack/compute/servers/v2.1';
import { setComputeServerGroupsTableConfig } from 
'../../../../../store/reducers/computeNovaSlice';


const handleTableColumnsConfig = (columns_schema,api_version) => {
    let current_api_version = api_version
    if (!api_version) {
        current_api_version = "2.1"
    }

    let schema = columns_schema.filter(f => 
        (!f.min_api_version ||  parseFloat(f.min_api_version) <= parseFloat(current_api_version)) &&
        (!f.max_api_version ||  parseFloat(f.max_api_version) >= parseFloat(current_api_version))
    )
    return schema
}

const ComputeServerGroupsTableV21 = (props) => {
    const { serverGroupsData, setServerGroupsData } = props
    const { currentAction } = props
    const { setCurrentAction }  = props
    const { actionsTexts }  = props
    const { actionsList } = props

    const defaultTexts = useSelector(state => state.texts.langTexts);
    
    const serverGroupsTableConfig = useSelector(
        state => state.computeNova.computeServerGroupsTableConfig)
    const rowsPerPage = useSelector(
        state => state.computeNova.computeServerGroupsTableConfig.filter(
        versions => versions.api_version === "v2.1")[0].rows_per_page)
    const serverGroupsTableHeaders = useSelector(
        state => state.computeNova.computeServerGroupsTableConfig.filter(
            versions => versions.api_version === "v2.1")[0].columns)
    const computeNovaMaxApiVersion = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            versions => versions.api_version === "v2.1")[0].max_api_version)
    

    const dispatch = useDispatch();

    const serverGroupsTableColumns = handleTableColumnsConfig(serverGroupsDataSchema,computeNovaMaxApiVersion)

    const identityField = serverGroupsTableColumns.filter(
        item => item.is_id_field)[0].field_key

    const handleServerGroupsTableConfig = (value_list) => {
        let schema = []
        if (serverGroupsData.length > 0) {
            const sg_keys = Object.keys(serverGroupsData[0])
            schema = serverGroupsDataSchema.filter(
                n => sg_keys.includes(n.field_key))
        } else {
            schema = [...serverGroupsDataSchema]
        }
        let newTableConfig = schema.filter(
            n => value_list.includes(n.field_key))
        const new_data = serverGroupsTableConfig.map(version => {
            if (version.api_version === "v2.1") {
                let new_item = {...version}
                new_item.columns = [...newTableConfig]
                return new_item
            } else {
                return version
            }
        })
        dispatch(setComputeServerGroupsTableConfig({
            computeServerGroupsTableConfig: new_data
        }))
    }
    
    const handleRowsPerPageChange = (rows_num) => {
        const new_data = serverGroupsTableConfig.map(version => {
            if (version.api_version === "v2.1") {
                let new_item = {...version}
                new_item.rows_per_page = rows_num
                return new_item
            } else {
                return version
            }
        })
        dispatch(setComputeServerGroupsTableConfig({
            computeServerGroupsTableConfig: new_data
        }))
    }

    return (
        <React.Fragment>
            <CustomTable
                withPagination={true}
                withCheckbox={true}
                withActions={true}
                defaultDataTexts={{...defaultTexts}} 
                tableHeaders={
                    serverGroupsTableHeaders.length > 0 ? 
                    serverGroupsTableHeaders : 
                    [...serverGroupsTableColumns]
                }
                tableHeadersConfigHandler={handleServerGroupsTableConfig}
                identityField={identityField}
                columns={serverGroupsTableColumns}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                dataRows={serverGroupsData}
                setDataRows={setServerGroupsData}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={actionsTexts}
                actionsList={actionsList}
            />
        </React.Fragment>
    )
};

export default ComputeServerGroupsTableV21;