import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../../../_common/CustomTable';
import { roleDataSchema } from 
'../../../../../_data/openstack/identity/roles/v3';
import { setIdentityRolesTableConfig } from 
'../../../../../store/reducers/identityKeystoneSlice';

const IdentityRolesTableV3 = (props) => {
    const { rolesData } = props
    const { handleRowSelection } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const rolesTableConfig = useSelector(
        state => state.identityKeystone.identityRolesTableConfig)
    const rowsPerPage = useSelector(
        state => state.identityKeystone.identityRolesTableConfig.filter(
        versions => versions.api_version === "v3")[0].rows_per_page)
    const rolesTableColumns = useSelector(
        state => state.identityKeystone.identityRolesTableConfig.filter(
            versions => versions.api_version === "v3")[0].columns)
    
    const identityField = rolesTableColumns.filter(
        item => item.is_id_field)[0].field_key

    const dispatch = useDispatch();

    const handleRoleTableConfig = (value_list) => {
        const newTableConfig = roleDataSchema.filter(
            n => value_list.includes(n.field_key))
        const new_data = rolesTableConfig.map(version => {
            if (version.api_version === "v3") {
                let new_item = {...version}
                new_item.columns = [...newTableConfig]
                return new_item
            } else {
                return version
            }
        })
        dispatch(setIdentityRolesTableConfig({
            identityRolesTableConfig: new_data
        }))
    }
    
    const handleRowsPerPageChange = (rows_num) => {
        const new_data = rolesTableConfig.map(version => {
            if (version.api_version === "v3") {
                let new_item = {...version}
                new_item.rows_per_page = rows_num
                return new_item
            } else {
                return version
            }
        })
        dispatch(setIdentityRolesTableConfig({
            identityRolesTableConfig: new_data
        }))
    }

    return (
        <React.Fragment>
            <CustomTable
                withPagination={true}
                defaultDataTexts={defaultTexts} 
                tableHeaders={rolesTableColumns}
                tableHeadersConfigHandler={handleRoleTableConfig}
                identityField={identityField}
                columns={roleDataSchema}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                dataRows={rolesData}
                handleRowSelection={handleRowSelection}
            />
        </React.Fragment>
    )
};

export default IdentityRolesTableV3;