import React from 'react';
import { useSelector} from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import useWindowDimensions from './WindowDimensions';
import Constants from '../../config/constants';


const drawerWidth = Constants.drawerWidth;

const CustomSideDrawer = (props) => {
    const drawerOpen = useSelector(state => state.drawer.drawerOpened);
    const { widthWeight } = props
    const { width, height } = useWindowDimensions();
    const WIDTH_WEIGHT = widthWeight ? widthWeight : 0.8
    const { open } = props
    const { handleDrawerOpen, handleDrawerClose } = props

    const handleOpen = () => {
        handleDrawerOpen();
    }

    const handleClose = () => {
        handleDrawerClose();
    }

    return (
            <SwipeableDrawer
                anchor='right'
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                SlideProps={{timeout: {enter: 500, exit: 500}}}
                PaperProps={{sx: {
                    mt: 5,
                    p: 1,
                    pb: 5, 
                    width:  !drawerOpen ? width * WIDTH_WEIGHT : 
                        window.innerWidth - width * WIDTH_WEIGHT > drawerWidth ? 
                        width * WIDTH_WEIGHT :  
                        width * WIDTH_WEIGHT  - drawerWidth,
                    height: height - Constants.actions_bar_height + 10,
                    overflow: 'scroll',
                    backgroundColor: "background.default",
                    scrollbarWidth: "thin"
                }}}
            >
                <Box>
                    <IconButton
                        onClick={handleClose}
                        sx={{mt: 3}}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                {props.children}
            </SwipeableDrawer>
    )
}

export default CustomSideDrawer;