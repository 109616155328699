import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CustomDialog from '../../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, 
        networkNeutronConstants, 
        identityKeystonConstants
} from '../../../../../../config/openStackConstants';
import IPsecPoliciesSubheaderV20 from './ipsecPoliciesSubheaderV2.0';
import IPsecPoliciesTableV20 from './ipsecPoliciesTableV2.0';
import { vpnIPsecPoliciesFilterMenu, vpnIPsecPolicyDataForm } 
from '../../../../../../_data/openstack/neutron/vpn/v2.0';
import { 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../../_network/openstack_request';
import { vpnUrl as vpnUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/vpn/v2.0';
import { projectsUrl as projectUrlResponses} from 
'../../../../../../_api_responses/openstack/identity/projects/v3';
import FormGroup from '@mui/material/FormGroup';
import { getFormFieldComponent } from 
'../../../../../_common/_form_fields/form_helpers';

const SERVICE_NAME = openStackServices.networkService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const IPsecPoliciesWrapperV20 = (props) => {
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const {currentTab, setCurrentTab} = props
    const [ipsecPoliciesData, setIPsecPoliciesData] = useState([])
    const [ipsecPolicies, setIPsecPolicies] = useState([])
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [currentAction, setCurrentAction] = useState("");
    const [ipsecPolicyDeleteConfirmDialogOpen, setIPsecPolicyDeleteConfirmDialogOpen] = useState(false);
    const [selectedIPsecPolicies, setSelectedIPsecPolicies] = useState([])
    
    const [ipsecPolicyFilterQueryParams, setIPsecPolicyFilterQueryParams] = useState("")
    const [selectedIPsecPolicyFilter, setSelectedIPsecPolicyFilter] = useState(vpnIPsecPoliciesFilterMenu[0].value)
    const [selectedIPsecPolicyFilterValue, setSelectedIPsecPolicyFilterValue] = useState("")
    const [ipsecPoliciesFilter, setIPsecPoliciesFilter] = useState([...vpnIPsecPoliciesFilterMenu])

    const [projects, setProjects] = useState([])
    const [updateDataOptions, setUpdateDataOptions] = useState({});
    const [updateFormData, setUpdateFormData] = useState({});
    const [ipsecPolicyUpdateDialogOpen, setIPsecPolicyUpdateDialogOpen] = useState(false)
    const [selectedFieldKey, setSelectedFieldKey] = useState("")
    const [selectedFieldTitleText, setSelectedFieldTitleText] = useState("")

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const vpnIPsecPoliciesUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.vpnIPsecPoliciesUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleTabChange = (tab_name) => {
        setCurrentTab(tab_name)
    }

    const handleIPsecPolicyFilteredSearch = () => {
        if (selectedIPsecPolicyFilter && selectedIPsecPolicyFilterValue) {
            setIPsecPolicyFilterQueryParams(`${selectedIPsecPolicyFilter}=${selectedIPsecPolicyFilterValue}`)
        } else {
            setIPsecPolicyFilterQueryParams("")
        }
        handleDataFetch()
    }

    const handleIPsecPoliciesDataFormatting = useCallback(() => {
        const formatted_data = ipsecPoliciesData.map((item) => {
            let new_item = {...item}
            const project = projects.filter(p => p.id === item.project_id)
            if (project.length > 0) {
                new_item.project_id = project[0].name
            } else {
                new_item.project_id = "-"
            }
            new_item.lifetime = `${item.lifetime.value} ${item.lifetime.units}`
            return new_item
        })
        setIPsecPolicies(formatted_data)
    },[
        ipsecPoliciesData,
        projects
    ])

    const handleIPsecPolicyFilterReset = () => {
        setSelectedIPsecPolicyFilter(vpnIPsecPoliciesFilterMenu[0].value)
        setSelectedIPsecPolicyFilterValue("")
        setIPsecPolicyFilterQueryParams("")
        handleDataFetch()
    }


    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleFormDataChange = (event,field_key) => {
        setUpdateDataOptions({})
        let new_form_data = {...updateFormData}
        if (vpnIPsecPolicyDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (vpnIPsecPolicyDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setUpdateFormData(new_form_data)
    }

    const getDataForm = () => {
        let form = vpnIPsecPolicyDataForm.filter(i => i.field_key === selectedFieldKey)
        return (
            <FormGroup>
                {form.map(field => {
                    let form_field_options = {...updateDataOptions[field.field_key]}
                    form_field_options = {...form_field_options, ...field}
                    delete form_field_options["label"]
                    form_field_options["item_titles"] = defaultTexts
                    return (
                        getFormFieldComponent(
                            field,
                            updateFormData,
                            handleFormDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleUpdateDataValidation = (field_key, data) => {
        if (data[field_key].length === 0) {
            let options = {}
            options[field_key] = {}
            options[field_key]["error"] = true
            options[field_key]["errorText"] = defaultTexts.requiredFormFieldError
            setUpdateDataOptions(options)
            return false
        } else {
            return true
        }
    }

    const handleIPsecPolicyUpdate = async (eg_id,field_key,data) => {
        const data_is_valid = handleUpdateDataValidation(field_key,data)
        let update_data = {}
        update_data[field_key] = data[field_key]
        if (data_is_valid) {
            handleIPsecPolicyUpdateDialogClose()
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const method = "PUT"
                const vpnGroup_response = await openstackRequest({
                    url: `${neutronServiceDomain}/${neutronServiceVersion}/${vpnIPsecPoliciesUrl}/${eg_id}`, 
                    method: method, 
                    data: {ipsecpolicy: update_data},
                    token: project_token
                })
                if (vpnGroup_response.status_code === vpnUrlResponses.put.success_response.status_code) {
                    return null
                } else {
                    return vpnGroup_response.error
                }
            }
        } 
    }

    const onIPsecPolicyUpdate = async () => {
        let err = []
        for (let n in selectedIPsecPolicies) {
            const resp = await handleIPsecPolicyUpdate(
                selectedIPsecPolicies[n].id,
                selectedFieldKey,
                updateFormData)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDataFetch()
        setUpdateDataOptions({})
        setUpdateFormData({})
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onIPsecPolicyUpdateNameDialogOpen = useCallback((n_list) => {
        const selected_n_list = ipsecPoliciesData.filter(n => 
            n_list.includes(n.id))
        setSelectedIPsecPolicies([...selected_n_list])
        setSelectedFieldKey("name")
        setSelectedFieldTitleText(defaultTexts.updateIPsecPolicyNameTitle)
        setIPsecPolicyUpdateDialogOpen(true)
    },[ipsecPoliciesData, defaultTexts])

    const onIPsecPolicyUpdateDescriptionDialogOpen = useCallback((n_list) => {
        const selected_n_list = ipsecPoliciesData.filter(n => 
            n_list.includes(n.id))
        setSelectedIPsecPolicies([...selected_n_list])
        setSelectedFieldKey("description")
        setSelectedFieldTitleText(defaultTexts.updateIPsecPolicyDescriptionTitle)
        setIPsecPolicyUpdateDialogOpen(true)
    },[ipsecPoliciesData, defaultTexts])

    const onIPsecPolicyUpdateAuthAlgDialogOpen  = useCallback((n_list) => {
        const selected_n_list = ipsecPoliciesData.filter(n => 
            n_list.includes(n.id))
        setSelectedIPsecPolicies([...selected_n_list])
        setSelectedFieldKey("auth_algorithm")
        setSelectedFieldTitleText(defaultTexts.updateIPsecPolicyAuthAlgTitle)
        setIPsecPolicyUpdateDialogOpen(true)
    },[ipsecPoliciesData, defaultTexts])

    const onIPsecPolicyUpdateEncrAlgDialogOpen  = useCallback((n_list) => {
        const selected_n_list = ipsecPoliciesData.filter(n => 
            n_list.includes(n.id))
        setSelectedIPsecPolicies([...selected_n_list])
        setSelectedFieldKey("encryption_algorithm")
        setSelectedFieldTitleText(defaultTexts.updateIPsecPolicyEncrAlgTitle)
        setIPsecPolicyUpdateDialogOpen(true)
    },[ipsecPoliciesData, defaultTexts])

    const onIPsecPolicyUpdatePFSGroupDialogOpen  = useCallback((n_list) => {
        const selected_n_list = ipsecPoliciesData.filter(n => 
            n_list.includes(n.id))
        setSelectedIPsecPolicies([...selected_n_list])
        setSelectedFieldKey("pfs")
        setSelectedFieldTitleText(defaultTexts.updateIPsecPolicyPFSGroupTitle)
        setIPsecPolicyUpdateDialogOpen(true)
    },[ipsecPoliciesData, defaultTexts])

    const onIPsecPolicyUpdateEncapModeDialogOpen  = useCallback((n_list) => {
        const selected_n_list = ipsecPoliciesData.filter(n => 
            n_list.includes(n.id))
        setSelectedIPsecPolicies([...selected_n_list])
        setSelectedFieldKey("encapsulation_mode")
        setSelectedFieldTitleText(defaultTexts.updateIPsecPolicyEncapModeTitle)
        setIPsecPolicyUpdateDialogOpen(true)
    },[ipsecPoliciesData, defaultTexts])

    const onIPsecPolicyUpdateTransProtoDialogOpen  = useCallback((n_list) => {
        const selected_n_list = ipsecPoliciesData.filter(n => 
            n_list.includes(n.id))
        setSelectedIPsecPolicies([...selected_n_list])
        setSelectedFieldKey("transform_protocol")
        setSelectedFieldTitleText(defaultTexts.updateIPsecPolicyTransProtoTitle)
        setIPsecPolicyUpdateDialogOpen(true)
    },[ipsecPoliciesData, defaultTexts])

    const handleIPsecPolicyUpdateDialogClose = () => {
        setIPsecPolicyUpdateDialogOpen(false)
    }

    const handleIPsecPolicyDelete = async (n_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${vpnIPsecPoliciesUrl}/${n_id}`
            const method = "DELETE"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                token: project_token
            })

            if (nt_response.status_code === vpnUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    };

    const onIPsecPolicyDelete = async () => {
        handleIPsecPolicyDeleteConfirmDialogClose()
        let err = []
        for (let n in selectedIPsecPolicies) {
            const resp = await handleIPsecPolicyDelete(selectedIPsecPolicies[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onIPsecPolicyDeleteConfirm = useCallback((n_list) => {
        const selected_n_list = ipsecPoliciesData.filter(n => 
            n_list.includes(n.id))
        setSelectedIPsecPolicies([...selected_n_list])
        setIPsecPolicyDeleteConfirmDialogOpen(true)
    },[ipsecPoliciesData])

    const handleIPsecPolicyDeleteConfirmDialogClose = () => {
        setIPsecPolicyDeleteConfirmDialogOpen(false)
    }

    const getIPsecPoliciesActionsList = useCallback(() => {
        let ipsecPolicy_actions = []
        let new_action = {}
        new_action["value"] = "ipsec_policy_update_name"
        new_action["action"] = onIPsecPolicyUpdateNameDialogOpen
        new_action["keyword"] = "ipsecPolicyUpdateNameActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        ipsecPolicy_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "ipsec_policy_update_description"
        new_action["action"] = onIPsecPolicyUpdateDescriptionDialogOpen
        new_action["keyword"] = "ipsecPolicyUpdateDescriptionActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        ipsecPolicy_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "ipsec_policy_update_auth_alg"
        new_action["action"] = onIPsecPolicyUpdateAuthAlgDialogOpen
        new_action["keyword"] = "ipsecPolicyUpdateAuthAlgActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        ipsecPolicy_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "ipsec_policy_update_encr_alg"
        new_action["action"] = onIPsecPolicyUpdateEncrAlgDialogOpen
        new_action["keyword"] = "ipsecPolicyUpdateEncrAlgActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        ipsecPolicy_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "ipsec_policy_update_pfs"
        new_action["action"] = onIPsecPolicyUpdatePFSGroupDialogOpen
        new_action["keyword"] = "ipsecPolicyUpdatePFSGroupActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        ipsecPolicy_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "ipsec_policy_update_encap_mode"
        new_action["action"] = onIPsecPolicyUpdateEncapModeDialogOpen
        new_action["keyword"] = "ipsecPolicyUpdateEncapModeActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        ipsecPolicy_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "ipsec_policy_update_trans_proto"
        new_action["action"] = onIPsecPolicyUpdateTransProtoDialogOpen
        new_action["keyword"] = "ipsecPolicyUpdateTransProtoActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        ipsecPolicy_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "ipsecPolicy_delete"
        new_action["action"] = onIPsecPolicyDeleteConfirm
        new_action["keyword"] = "ipsecPolicyDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        ipsecPolicy_actions.push({...new_action})
        
        return ipsecPolicy_actions
    },[
        onIPsecPolicyDeleteConfirm,
        onIPsecPolicyUpdateNameDialogOpen,
        onIPsecPolicyUpdateDescriptionDialogOpen,
        onIPsecPolicyUpdateAuthAlgDialogOpen,
        onIPsecPolicyUpdateEncrAlgDialogOpen,
        onIPsecPolicyUpdatePFSGroupDialogOpen,
        onIPsecPolicyUpdateEncapModeDialogOpen,
        onIPsecPolicyUpdateTransProtoDialogOpen
    ])

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${neutronServiceDomain}/${neutronServiceVersion}/${vpnIPsecPoliciesUrl}?${ipsecPolicyFilterQueryParams}`
                    const method = "GET"
                    const ipsecPolicy_response = await openstackRequest({url:url, method:method, token: project_token})
                    if (ipsecPolicy_response.status_code === vpnUrlResponses.get.success_response.status_code) {
                        setIPsecPoliciesData(ipsecPolicy_response.data.ipsecpolicies)
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        vpnIPsecPoliciesUrl, 
        ipsecPolicyFilterQueryParams,
        dataFetchingRequired,
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === projectUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        if (Object.keys(updateFormData).length === 0) {
            let new_form_data = {}
            for (const n in vpnIPsecPolicyDataForm) {
                if (
                    vpnIPsecPolicyDataForm[n].field_type === "string" || 
                    vpnIPsecPolicyDataForm[n].field_type === "select"
                    ) {
                    new_form_data[vpnIPsecPolicyDataForm[n].field_key] = vpnIPsecPolicyDataForm[n].default_value ? 
                    vpnIPsecPolicyDataForm[n].default_value : 
                    ""
                } else if (vpnIPsecPolicyDataForm[n].field_type === "integer") {
                    new_form_data[vpnIPsecPolicyDataForm[n].field_key] = 3600
                } else if (vpnIPsecPolicyDataForm[n].field_type === "bool") {
                    new_form_data[vpnIPsecPolicyDataForm[n].field_key] = vpnIPsecPolicyDataForm[n].default_value ? 
                    vpnIPsecPolicyDataForm[n].default_value : 
                    false
                }
            }
            setUpdateFormData(new_form_data)
        }
    },[
        updateFormData
    ]);

    useEffect(() => {
        if (ipsecPoliciesData.length > 0) {
            handleIPsecPoliciesDataFormatting()
        }
    },[
        ipsecPoliciesData,
        handleIPsecPoliciesDataFormatting
    ])

    useEffect(() => {
        let projects_filter = []
        if (projects.length > 0) {
            projects_filter = projects.map(project => {
                return {keyword: project.name, value: project.id, default: false}
            })
        }
        let ipsecPolicy_filter_menu = vpnIPsecPoliciesFilterMenu.map(nt => {
            let new_item = {...nt}
            if (nt.value === "project_id") {
                new_item.items = [...projects_filter]
            }
            return new_item
        })

        setIPsecPoliciesFilter(ipsecPolicy_filter_menu)
    },[
        ipsecPoliciesData,
        projects
    ])

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <IPsecPoliciesSubheaderV20 
                selectedFilter={selectedIPsecPolicyFilter} 
                setSelectedFilter={setSelectedIPsecPolicyFilter}
                selectedFilterValue={selectedIPsecPolicyFilterValue}
                setSelectedFilterValue={setSelectedIPsecPolicyFilterValue}
                handleDataFetch={handleDataFetch}
                filterMenu={ipsecPoliciesFilter}
                handleFilteredSearch={handleIPsecPolicyFilteredSearch}
                handleFilterReset={handleIPsecPolicyFilterReset}
                currentTab={currentTab}
                handleTabChange={handleTabChange}
                projects={projects}
            />
        {isLoading && <CustomBackdrop open={isLoading} />}
        {!isLoading &&
            <IPsecPoliciesTableV20 
                ipsecPoliciesData={ipsecPolicies}
                setIPsecPoliciesData={setIPsecPolicies}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getIPsecPoliciesActionsList()}
                projects={projects}
            />
        }
        <CustomDialog
            open={ipsecPolicyDeleteConfirmDialogOpen}
            onClose={handleIPsecPolicyDeleteConfirmDialogClose}
            dialogTitle={{
                title: defaultTexts.ipsecPolicyDeleteConfirmTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `${defaultTexts.ipsecPolicyDeleteConfirmText}`, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onIPsecPolicyDelete, 
                sx: {color: 'primary.main'}}]}
        />
        <CustomDialog
            open={ipsecPolicyUpdateDialogOpen}
            onClose={handleIPsecPolicyUpdateDialogClose}
            dialogTitle={{
                title: selectedFieldTitleText, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onIPsecPolicyUpdate, 
                sx: {color: 'primary.main'}}]}
        >
            {getDataForm()}
        </CustomDialog>
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts[error.error_title], 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts[error.error_message]}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
    </Box>
    )
};

export default IPsecPoliciesWrapperV20;