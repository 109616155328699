import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import { setCurrentLang } from '../../store/reducers/settingsSlice';
import { setLangTexts } from '../../store/reducers/textsSlice';
import { getTranslatedText } from '../../lang/translator';
import { initialState, textsReset } from '../../store/reducers/textsSlice';

const LangSwitcher = (props) => {
    const defaultLang = useSelector(state => state.settings.defaultLang);
    const currentLang = useSelector(state => state.settings.currentLang);
    const supportedLangs = useSelector((state) => state.settings.supportedLangs);
    const defaultTexts = initialState.langTexts
    const [anchorElLang, setAnchorElLang] = useState(null);

    const handleOpenLangMenu = (event) => {
        setAnchorElLang(event.currentTarget);
      };
    
    const handleCloseLangMenu = () => {
        setAnchorElLang(null);
      };

    const dispatch = useDispatch();
    
    const handleLangChange = (value) => {
        if (defaultLang !== value) {
            getTranslatedText(value).then((translatedItems) => {
                let new_data = {...defaultTexts, ...translatedItems}
                dispatch(setLangTexts({langTexts: new_data}))
                dispatch(setCurrentLang({currentLang: value}));
            })
        } else {
            dispatch(textsReset())
            dispatch(setCurrentLang({currentLang: value}));
        }
        
        handleCloseLangMenu();
    };

    return (
        <Box sx={{display: 'inline-flex'}}>
                <IconButton 
                    onClick={handleOpenLangMenu} 
                    sx={{p: 1, color: 'white'}}
                >
                    <Typography textAlign="center" >
                        {supportedLangs.filter(item => item.value === currentLang)[0].label}
                    </Typography>
                </IconButton>
            <Menu
                sx={{ mt: '48px' }}
                anchorEl={anchorElLang}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElLang)}
              onClose={handleCloseLangMenu}
            >
                {supportedLangs.map((lang) => {
                    return (
                        <MenuItem 
                            onClick={() => {handleLangChange(lang.value)}} 
                            key={lang.value} 
                            value={lang.value}
                        >
                            {lang.label}
                        </MenuItem>
                    )
                })}
            </Menu>
          </Box>
    )
}
export default LangSwitcher;
