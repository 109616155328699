import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import IdentityKeystoneRolesSubheaderV3 from 
'./identityKeystoneRolesSubheaderV3';
import WrapperBox from '../../../../_common/WrapperBox';
import IdentityRolesTableV3 from './identityRolesTableV3';
import { identityKeystonConstants } from 
'../../../../../config/openStackConstants';
import { openstackRequest } from 
'../../../../../_network/openstack_request';
import { rolesUrl as rolesUrlResponses } from 
'../../../../../_api_responses/openstack/identity/roles/v3';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { rolesfilterMenu } from 
'../../../../../_data/openstack/identity/roles/v3';
import CustomDialog from '../../../../_common/CustomDialog';
import CustomSideDrawer from '../../../../_common/CustomSideDrawer';
import IdentityRoleDetailV3 from './identityRoleDetailV3';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import useWindowDimensions from '../../../../_common/WindowDimensions';
import Dimensions from '../../../../../config/dimensions';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import CustomSelectField from 
'../../../../_common/_form_fields/CustomSelectField';
import { sortObjectListAscending } from 
'../../../../../components/_common/common_helpers';
import IdentityRoleAssignmentsV3 from './identityRoleAssignmentsV3';
import Box from '@mui/material/Box';

const SERVICE_NAME = openStackServices.identityService

const getWidthWeight = (width) => {
    if (width < Dimensions.tablet_mini.width) {
        return 0.9
    } else if (width < Dimensions.tablet.width) {
        return 0.6
    } else {
        return 0.4
    }
}

const IdentityRolesWrapperV3 = (props) => {
    const [isLoading, setIsLoading ] = useState(true);
    const [filterMenu, setFilterMenu] = useState(rolesfilterMenu)
    const [rolesData, setRolesData] = useState([])
    const [roles, setRoles] = useState([])
    const [domains, setDomains] = useState([]);
    const [error, setError] = useState()
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = getWidthWeight(width)
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedFilter, setSelectedFilter ] = useState(filterMenu[0].value)
    const [selectedFilterValue, setSelectedFilterValue] = useState("");
    const [filterQueryParams, setFilterQueryParams] = useState("")
    const apiResponseTexts = useSelector(state => state.texts.langTexts)
    const [fetchDataRequired, setFetchDataRequired] = useState(true);

    const [selectedType, setSelectedType] = useState("user")
    const [roleAssignmentsFormDialogOpen, setRoleAssignmentsFormDialogOpen] = useState(false);
    const [users, setUsers] = useState([]);
    const [groups, setGroups] = useState([]);
    const [selectedUser, setSelectedUser] = useState("");
    const [selectedUserError, setSelectedUserError] = useState(false)
    const [selectedGroup, setSelectedGroup] = useState("");
    const [selectedGroupError, setSelectedGroupError] = useState(false)
    const [selectedRoleAssignments, setSelectedRoleAssignments] = useState([])
    const [selectedTarget, setSelectedTarget] = useState("");
    const [roleAssignmentsDialogOpenMode, setRoleAssignmentsDialogOpenMode] = useState(false);
    

    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const rolesUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.rolesUrl)[0].url)
    const roleAssignmentsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.roleAssignmentsUrl)[0].url)

    const domainsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.domainsUrl)[0].url)

    const usersUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.usersUrl)[0].url)
    const groupsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.groupsUrl)[0].url)

    
    const handleRoleAssignmentsDialogClose = () => {
        setSelectedRoleAssignments([])
        setSelectedTarget("")
        setSelectedUser("")
        setSelectedGroup("")
        setRoleAssignmentsDialogOpenMode(false)
    }

    const handleDataFetch = () => {
        setFetchDataRequired(true)
    }

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleFilteredSearch = () => {
        if (selectedFilter && selectedFilterValue) {
            setFilterQueryParams(`/?${selectedFilter}=${selectedFilterValue}`)
        } else {
            setFilterQueryParams("")
        }
    }

    const handleFilterReset = () => {
        setSelectedFilter(filterMenu[0].value)
        setSelectedFilterValue("")
        setFilterQueryParams("")
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleDetailCardOpen = (index) => {
        setSelectedRow(rolesData[index])
        setTimeout(() => setDetailCardOpen(true), 100) 
    };

    const handleDetailCardClose = () => {
        setSelectedRow(null)
        setTimeout(() => setDetailCardOpen(false), 100) 
    };

    const handleTypeSelection = (event) => {
        setSelectedType(event.target.value)
    }

    const handleRoleAssignmentsFormDialogOpen = () => {
        setRoleAssignmentsFormDialogOpen(true)
    }

    const handleRoleAssignmentsFormDialogClose = () => {
        setSelectedUser("")
        setSelectedGroup("")
        setSelectedUserError(false)
        setSelectedGroupError(false)
        setRoleAssignmentsFormDialogOpen(false)
    }

    const handleRoleAssignmentsPopupOpen = async () => {
        if (!selectedUser && !selectedGroup) {
            if (selectedType === "user") {
                setSelectedUserError(true)
            }
            if (selectedType === "group") {
                setSelectedGroupError(true)
            }
        } else {
            setRoleAssignmentsFormDialogOpen(false)
            setIsLoading(true)
            setSelectedUserError(false)
            setSelectedGroupError(false)
            let role_assignments = []
            let url = ""
            if (selectedType === "user") {
                url = `${identityServiceDomain}/${identityServiceVersion}/${roleAssignmentsUrl}/?user.id=${selectedUser}&&include_names=true`
                const selected_user = users.filter(item => item.value === selectedUser)
                setSelectedTarget(selected_user[0]["keyword"])
            } else {
                url = `${identityServiceDomain}/${identityServiceVersion}/${roleAssignmentsUrl}/?group.id=${selectedGroup}&&include_names=true`
                const selected_group = groups.filter(item => item.value === selectedGroup)
                setSelectedTarget(selected_group[0]["keyword"])
            }
            const method = "GET"
    
            const role_assignments_response = await openstackRequest({url: url, method: method})
            role_assignments = role_assignments_response.data.role_assignments
            setSelectedRoleAssignments(role_assignments)
            //setSelectedUser("")
            //setSelectedGroup("")
            setIsLoading(false)
            setRoleAssignmentsDialogOpenMode(true)
        }
    }

    useEffect(() => {
        setFetchDataRequired(true)
    },[filterQueryParams]);

    useEffect(() => {
        const domains_filter = domains.map(domain => {
            return {keyword: domain.name, value: domain.id, default: false}
        })

        const new_filter_menu = rolesfilterMenu.map(item => {
            if (item.value === "domain_id") {
                item.items = domains_filter
            }
            return item
        })
        setFilterMenu(new_filter_menu)

        let updated_roles_data = rolesData.map(role => {
            const domain = domains.filter(item => item.id === role.domain_id)
            
            let updated_data = {...role}
            if (domain.length > 0) {
                updated_data.domain_id = domain[0].name
            }

            return updated_data
        })
        setRoles(updated_roles_data)
        setIsLoading(false)
    },[
        domains, 
        rolesData
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${domainsUrl}`
            const method = "GET"

            const domains_response = await openstackRequest({url:url, method:method})
            if (domains_response.status_code === rolesUrlResponses.get.success_response.status_code) {
                setDomains([...domains_response.data.domains])
            } else {
                setDomains([])
            }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        domainsUrl,
        rolesData
    ]);

    useEffect(() => {
        if (fetchDataRequired) {
        (async () => {
            handleLoading(true)
            const url = `${identityServiceDomain}/${identityServiceVersion}/${rolesUrl}${filterQueryParams}`
            const method = "GET"

            const roles_response = await openstackRequest({url:url, method:method})
            if (roles_response.status_code === rolesUrlResponses.get.success_response.status_code) {
                setRolesData([...roles_response.data.roles])
                if (selectedRow) {
                    let new_data = roles_response.data.roles.filter(role => role.id === selectedRow.id)
                    if (new_data.length > 0) {
                        setSelectedRow(new_data[0])
                    } else {
                        setSelectedRow(null)
                    }
                }
            } else {
                const error_response = rolesUrlResponses.get.error_response.filter(
                    error_item => error_item.status_code === roles_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: roles_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = rolesUrlResponses.get.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: roles_response.error
                    }
                    setError(errorObject)
                }
            }
        })();
        setFetchDataRequired(false)
        }
        setTimeout(()=>{handleLoading(false)},700)
    },[
        identityServiceDomain, 
        identityServiceVersion, 
        rolesUrl, 
        filterQueryParams,
        fetchDataRequired,
        selectedRow
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${usersUrl}`
            const method = "GET"

            const users_response = await openstackRequest({url:url, method:method})
            if (users_response.status_code === rolesUrlResponses.get.success_response.status_code) {
                const user_list = users_response.data.users.map(item => {
                    return {keyword: item.name, value: item.id}
                })
                const sorted_list = sortObjectListAscending(user_list,"keyword")
                setUsers(sorted_list)
            } else {
                setUsers([])
                }
            }
        )();
    },[
        identityServiceDomain, 
        identityServiceVersion, 
        usersUrl
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${groupsUrl}`
            const method = "GET"

            const groups_response = await openstackRequest({url:url, method:method})
            if (groups_response.status_code === rolesUrlResponses.get.success_response.status_code) {
                const group_list = groups_response.data.groups.map(item => {
                    return {keyword: item.name, value: item.id}
                })
                const sorted_list = sortObjectListAscending(group_list,"keyword")
                setGroups(sorted_list)
            } else {
                setGroups([])
                }
            }
        )();
    },[
        identityServiceDomain, 
        identityServiceVersion, 
        groupsUrl
    ]);

    return (
        <Box>
            <IdentityKeystoneRolesSubheaderV3 
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                selectedFilterValue={selectedFilterValue}
                setSelectedFilterValue={setSelectedFilterValue}
                filterMenu={filterMenu}
                handleFilteredSearch={handleFilteredSearch}
                handleFilterReset={handleFilterReset}
                handleFetchData={handleDataFetch}
                domains={domains}
            />
            <WrapperBox>
            <Button 
                variant="outlined"
                sx={{py: 1, px: 3}}
                onClick={handleRoleAssignmentsFormDialogOpen}
            >
                {apiResponseTexts.roleAssignmentsTitle}
            </Button>
            </WrapperBox>
            {isLoading && <CustomBackdrop open={isLoading} />}
            {!isLoading && <IdentityRolesTableV3 
                rolesData={roles}
                handleRowSelection={handleDetailCardOpen}
            />}
            {selectedRow !== null && <CustomSideDrawer 
                open={detailCardOpen}
                widthWeight={WIDTH_WEIGHT}
                handleDrawerOpen={handleDetailCardOpen}
                handleDrawerClose={handleDetailCardClose}
            > 
                <IdentityRoleDetailV3 
                    selectedRow={selectedRow}
                    widthWeight={WIDTH_WEIGHT}
                    handleFetchData={handleDataFetch}
                    handleDrawerClose={handleDetailCardClose}
                    rolesRecords={rolesData}
                    domains={domains}
                    users={users}
                    groups={groups}
                />            
            </CustomSideDrawer>}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: apiResponseTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${apiResponseTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>Details:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`,
                    sx: {color: 'text.primary'}}}
            />}
            <CustomDialog
                open={roleAssignmentsFormDialogOpen}
                onClose={handleRoleAssignmentsFormDialogClose}
                dialogTitle={{
                    title: apiResponseTexts.roleAssignmentsForTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: apiResponseTexts.showButtonText, 
                    variant: "contained",
                    size: "small",
                    onClick: handleRoleAssignmentsPopupOpen, 
                    sx: {color: 'white'}}]}
            >
                <RadioGroup
                    value={selectedType}
                    onChange={handleTypeSelection}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel 
                                value="user" 
                                control={<Radio />} 
                                label={apiResponseTexts.userFormFieldLabel} 
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel 
                                value="group" 
                                control={<Radio />} 
                                label={apiResponseTexts.groupFormFieldLabel}
                            />
                        </Grid>
                    </Grid>
                </RadioGroup>
                {selectedType === "user" && 
                    <CustomSelectField
                        items={users} 
                        currentValue={selectedUser}
                        setCurrentValue={setSelectedUser}
                        label={apiResponseTexts.userFormFieldLabel}
                        required={true}
                        error={selectedUserError}
                        self_item_titles={true}
                        empty={true}
                        sx={{my: 2, width: '90%'}}
                    />
                }
                {selectedType === "group" && 
                    <CustomSelectField
                        items={groups} 
                        currentValue={selectedGroup}
                        setCurrentValue={setSelectedGroup}
                        label={apiResponseTexts.groupFormFieldLabel}
                        required={true}
                        error={selectedGroupError}
                        self_item_titles={true}
                        empty={true}
                        sx={{my: 2, width: '90%'}}
                    />
                }
            </CustomDialog>
            <CustomDialog
                open={roleAssignmentsDialogOpenMode}
                onClose={handleRoleAssignmentsDialogClose}
                dialogTitle={{
                    title: apiResponseTexts.roleAssignmentsTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                maxWidth="lg"
            >
                <IdentityRoleAssignmentsV3
                    roleAssignments={selectedRoleAssignments}
                    target={selectedTarget}
                    handleRoleAssignmentChange={handleRoleAssignmentsPopupOpen}
                />
            </CustomDialog>
        </Box>
    )
};

export default IdentityRolesWrapperV3;