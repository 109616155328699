import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import WrapperBox from '../../../../../_common/WrapperBox';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FirewallGroupSpecsV20 from './firewallGroupSpecsV2.0';
import FirewallGroupIngressRulesV20 from './firewallGroupIngressRulesV2.0';
import FirewallGroupEgressRulesV20 from './firewallGroupEgressRulesV2.0';
import FirewallGroupInterfacesV20 from './firewallGroupInterfacesV2.0.js'
import useWindowDimensions from 
'../../../../../_common/WindowDimensions';
import { getFormFieldComponent } from 
'../../../../../_common/_form_fields/form_helpers';
import Constants from '../../../../../../config/constants';
import { Grid }  from '@mui/material';
import { 
    openstackRequest,
    getXAuthTokenProjectScope} from 
'../../../../../../_network/openstack_request';
import { firewallUrl as firewallUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/fwaas/v2.0';
import { openStackServices } from 
'../../../../../../config/openStackConstants';
import { 
    networkNeutronConstants
} from '../../../../../../config/openStackConstants';
import ServiceContentHeader from 
'../../../../../_common/ServiceContentHeader';
import CustomSelectField from 
'../../../../../_common/_form_fields/CustomSelectField';
import CustomDialog from 
'../../../../../_common/CustomDialog';
import { 
    firewallGroupDataUpdateForm,
    firewallGroupPoliciesUpdateForm
} from '../../../../../../_data/openstack/neutron/fwaas/v2.0';

const SERVICE_NAME = openStackServices.networkService
const FOOTER_HEIGHT = Constants.actions_bar_height + 20

const FirewallGroupDetailV20 = (props) => {
    const [isCardLoading, setIsCardLoading] = useState(true)
    const [error, setError] = useState();
    const { selectedRow, handleDataFetch, adminStateChange, sharedStateChange } = props
    const { widthWeight } = props
    const { handleDelete } = props
    const { projects, firewallPolicies, firewallGroups } = props
    const { width } = useWindowDimensions();
    const FOOTER_WIDTH =  width - (width * widthWeight)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);

    const [firewallGroupActions, setFirewallGroupActions] = useState([]);
    const [currentAction, setCurrentAction] = useState("");

    const [firewallGroupUpdateData, setFirewallGroupUpdateData] = useState({})
    const [updateFirewallGroupDialogOpen, setUpdateFirewallGroupDialogOpen] = useState(false)
    const [firewallGroupIngressRules, setFirewallGroupIngressRules] = useState([]);
    const [firewallGroupEgressRules, setFirewallGroupEgressRules] = useState([]);
    const [firewallGroupPorts, setFirewallGroupPorts] = useState([]);
    const [projectPorts, setProjectPorts] = useState([]);
    const [subnets, setSubnets] = useState([]);
    const [firewallGroupPoliciesUpdateData, setFirewallGroupPoliciesUpdateData] = useState({})
    const [updateFWGroupPoliciesDialogOpen, setUpdateFWGroupPoliciesDialogOpen] = useState(false)
    const [selectedPorts, setSelectedPorts] = useState([])
    const [addToPortDialogOpen, setAddToPortDialogOpen] = useState(false)

     
    const [firewallGroupSubMenu, setFirewallGroupSubMenu] = useState([
        {keyword: "submenuDetails", navigation: "/firewall-group-details", is_active: true},
        {keyword: "submenuFWIngressRules", navigation: "/firewall-ingress-rules", is_active: false},
        {keyword: "submenuFWEgressRules", navigation: "/firewall-egress-rules", is_active: false},
        {keyword: "submenuFWPorts", navigation: "/firewall-ports", is_active: false}
    ])

    const [currentTab, setCurrentTab] = useState("/firewall-group-details")
    
    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const firewallGroupsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.firewallGroupsUrl)[0].url)
    const firewallRulesUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.firewallRulesUrl)[0].url)
    const portsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.portsUrl)[0].url)
    const subnetsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.subnetsUrl)[0].url)

    const common_url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallGroupsUrl}/${selectedRow.id}`

    const getFormattedFirewallGroupData = useCallback((data) => {
        let formatted_data = {...data}
        const project = projects.filter(p => p.id === data.project_id)[0]
        formatted_data.project_id = project ? 
        project.name : 
        data.project_id
        const in_policy = firewallPolicies.filter(p => p.id === data.ingress_firewall_policy_id)[0]
        const out_policy = firewallPolicies.filter(p => p.id === data.egress_firewall_policy_id)[0]
        formatted_data.ingress_firewall_policy_id = in_policy ? 
        in_policy.name : data.ingress_firewall_policy_id
        formatted_data.egress_firewall_policy_id = out_policy ? 
        out_policy.name : data.egress_firewall_policy_id
        return formatted_data
    },[projects, firewallPolicies])

    const handleFirewallGroupDetailTabChange = useCallback((navigation) => {
        let newFirewallGroupSubmenuData = firewallGroupSubMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
            } else {
                item.is_active = false
            }
            return item
        })
        setFirewallGroupSubMenu(newFirewallGroupSubmenuData)
        setCurrentTab(navigation)
    },[
        firewallGroupSubMenu
    ])

    const handleConfirmDeleteDialogOpen = () => {
        handleDelete([selectedRow.id])
    }

    const handleAdminStateChangeDialogOpen = useCallback(() => {
        adminStateChange([selectedRow.id])
    },[selectedRow,adminStateChange])

    const handleSharedStateChangeDialogOpen = useCallback(() => {
        sharedStateChange([selectedRow.id])
    },[selectedRow,sharedStateChange])

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const getDataForm = (form,form_options,data,onDataChange) => {
        let form_data = [...form]
        return (
            <FormGroup>
                {form_data.map(field => {
                    let form_field_options = {}
                    return (
                        getFormFieldComponent(
                            field,
                            data,
                            onDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }


    const getUpdatePoliciesDataForm = () => {
        let form_data = [...firewallGroupPoliciesUpdateForm]
        return (
            <FormGroup>
                {form_data.map(field => {
                    const items_list = firewallPolicies.filter(p => p.project_id === selectedRow.project_id)
                    const items = items_list.map(i => {
                        return {keyword: i.name, value: i.id, default: false}
                    })
                    items.unshift({keyword: defaultTexts.noPolicyFormFieldLabel, value: "no_policy", default: false})
                    let form_field_options = {}
                    form_field_options["items"] = [...items]
                    form_field_options["self_item_titles"] = true
                    form_field_options["empty"] = true
                    return (
                        getFormFieldComponent(
                            field,
                            firewallGroupPoliciesUpdateData,
                            handleFWGroupPoliciesUpdateDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleFirewallGroupUpdateDataChange = (event,field_key) => {
        let new_form_data = {...firewallGroupUpdateData}
        if (firewallGroupDataUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (firewallGroupDataUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
                new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setFirewallGroupUpdateData(new_form_data)
    }

    const handleUpdateFirewallGroupDialogOpen = () => {
        setUpdateFirewallGroupDialogOpen(true)
    }

    const handleUpdateFirewallGroupDialogClose = () => {
        setUpdateFirewallGroupDialogOpen(false)
    }

    const handleFWGroupPoliciesUpdateDataChange = (event,field_key) => {
        let new_form_data = {...firewallGroupPoliciesUpdateData}
        if (firewallGroupPoliciesUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (firewallGroupPoliciesUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
                new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setFirewallGroupPoliciesUpdateData(new_form_data)
    }

    const handleUpdateFWGroupPoliciesDialogOpen = () => {
        setUpdateFWGroupPoliciesDialogOpen(true)
    }

    const handleUpdateFWGroupPoliciesDialogClose = () => {
        setFirewallGroupPoliciesUpdateData({})
        setUpdateFWGroupPoliciesDialogOpen(false)
    }

    const handleAddToPortDialogClose = () => {
        setSelectedPorts([])
        setAddToPortDialogOpen(false)
    }

    const handleAddToPortDialogOpen = () => {
        setAddToPortDialogOpen(true)
    }

    const onFirewallGroupUpdate = useCallback(async (event,data) => {
        let updated_data = {...firewallGroupUpdateData}
        if (data) {
            updated_data = data
        }
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const method = "PUT"
            const firewallGroup_response = await openstackRequest({
                url: common_url, 
                method: method, 
                data: {firewall_group: updated_data},
                token: project_token
            })
            if (firewallGroup_response.status_code === firewallUrlResponses.put.success_response.status_code) {
                setCurrentAction("")
                handleUpdateFirewallGroupDialogClose()
                handleDataFetch()
            } else {
                const error_response = firewallUrlResponses.put.error_response.filter(
                    error_item => error_item.status_code === firewallGroup_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: firewallGroup_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = firewallUrlResponses.put.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: firewallGroup_response.error
                    }
                    setError(errorObject)
                }
            }
        }
    },[
        common_url,
        defaultAdminProject,
        firewallGroupUpdateData,
        handleDataFetch
    ])

    const onFirewallGroupPoliciesUpdate = async () => {
        handleUpdateFWGroupPoliciesDialogClose()
        let data = {...firewallGroupPoliciesUpdateData}
        if (data.ingress_firewall_policy_id === "no_policy") {
            data.ingress_firewall_policy_id = null
        }
        if (data.egress_firewall_policy_id === "no_policy") {
            data.egress_firewall_policy_id = null
        }
        await onFirewallGroupUpdate(undefined,data)
    }

    const onAddToPort = async () => {
        handleAddToPortDialogClose()
        const data = {ports: selectedPorts}
        await onFirewallGroupUpdate(undefined,data)
    }

    const onInterfaceRemove = useCallback(async (port_id) => {
        const ports_list = selectedRow.ports.filter(p => p !== port_id)
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const method = "PUT"
            const fw_response = await openstackRequest({
                url: common_url, 
                method: method, 
                data: {firewall_group: {ports: ports_list}},
                token: project_token
            })
            if (fw_response.status_code === firewallUrlResponses.put.success_response.status_code) {
                handleDataFetch()
            } else {
                const error_response = firewallUrlResponses.put.error_response.filter(
                    error_item => error_item.status_code === fw_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: fw_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = firewallUrlResponses.put.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: fw_response.error
                    }
                    setError(errorObject)
                }
            }
        }
    },[
        common_url,
        defaultAdminProject,
        handleDataFetch,
        selectedRow
    ])

    useEffect(() => {
        let firewallGroup_actions = []
        let new_action = {}
        new_action["value"] = "update_firewall_group"
        new_action["action"] = handleUpdateFirewallGroupDialogOpen
        new_action["keyword"] = "firewallGroupUpdateActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        firewallGroup_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "admin_state_update"
        new_action["action"] = handleAdminStateChangeDialogOpen
        new_action["keyword"] = "adminStateChangeActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        firewallGroup_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "share_state_update"
        new_action["action"] = handleSharedStateChangeDialogOpen
        new_action["keyword"] = "shareStateChangeActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        firewallGroup_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "fw_group_policies_update"
        new_action["action"] = handleUpdateFWGroupPoliciesDialogOpen
        new_action["keyword"] = "updateFWGroupPoliciesActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        firewallGroup_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "fw_group_port_add"
        new_action["action"] = handleAddToPortDialogOpen
        new_action["keyword"] = "fwGroupAddToPortActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        firewallGroup_actions.push({...new_action})
        
        setFirewallGroupActions(firewallGroup_actions)
    },[
        selectedRow,
        handleAdminStateChangeDialogOpen,
        handleSharedStateChangeDialogOpen
    ])

    useEffect(() => {
        if (Object.keys(firewallGroupUpdateData).length === 0) {
            let new_form_data = {}
            for (const n in firewallGroupDataUpdateForm) {
                new_form_data[firewallGroupDataUpdateForm[n].field_key] = selectedRow[firewallGroupDataUpdateForm[n].field_key]
            }
            setFirewallGroupUpdateData(new_form_data)
        }
    },[firewallGroupUpdateData, selectedRow]);


    useEffect(() => {
        if (Object.keys(firewallGroupPoliciesUpdateData).length === 0) {
            let new_form_data = {}
            for (const n in firewallGroupPoliciesUpdateForm) {
                new_form_data[firewallGroupPoliciesUpdateForm[n].field_key] = selectedRow[firewallGroupPoliciesUpdateForm[n].field_key]
            }
            setFirewallGroupPoliciesUpdateData(new_form_data)
        }
    },[firewallGroupPoliciesUpdateData, selectedRow]);


    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallRulesUrl}`
                const method = "GET"
                
                const fp_response = await openstackRequest({
                    url:url, 
                    method:method,
                    token: project_token
                })
    
                if (fp_response.status_code === firewallUrlResponses.get.success_response.status_code) {
                    const in_firewall_policy = firewallPolicies.filter(fp => fp.id === selectedRow.ingress_firewall_policy_id)[0]
                    if (in_firewall_policy) {
                        const firewall_group_in_rules = fp_response.data.firewall_rules.filter(rl =>
                            in_firewall_policy.firewall_rules.includes(rl.id)
                        )
                        setFirewallGroupIngressRules(firewall_group_in_rules)
                    }
                    const out_firewall_policy = firewallPolicies.filter(fp => fp.id === selectedRow.egress_firewall_policy_id)[0]
                    if (out_firewall_policy) {
                        const firewall_group_out_rules = fp_response.data.firewall_rules.filter(rl =>
                            out_firewall_policy.firewall_rules.includes(rl.id)
                        )
                        setFirewallGroupEgressRules(firewall_group_out_rules)
                    }
                }
            }
        })();
    },[
        neutronServiceDomain,
        neutronServiceVersion,
        firewallRulesUrl,
        defaultAdminProject,
        firewallPolicies,
        selectedRow
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const method = "GET"
                const firewall_response = await openstackRequest({
                    url: `${neutronServiceDomain}/${neutronServiceVersion}/${portsUrl}`, 
                    method: method, 
                    token: project_token
                })
                console.log(firewall_response)
                if (firewall_response.status_code === firewallUrlResponses.get.success_response.status_code) {
                    const project_ports = firewall_response.data.ports.filter(
                        p => (selectedRow.project_id === p.project_id || p.project_id === "") && 
                        p.status === "ACTIVE" && 
                        p.device_owner.startsWith(networkNeutronConstants.routerInterfaceOwner) 
                    )
                    const project_port_list = project_ports.map(p => {
                        let subnet = null
                        let ip_address = null
                        let port_name = null
                        if (p.fixed_ips.length > 0) {
                            subnet = subnets.filter(s => s.id === p.fixed_ips[0].subnet_id)[0]
                            ip_address = p.fixed_ips[0].ip_address
                        }
                        if (p.name) {
                            port_name = p.name
                        }
                        let keyword = ""
                        if (subnet && ip_address) {
                            keyword = `${subnet.name}: ${ip_address}`
                        } else if (port_name) {
                            keyword = `${port_name}`
                        } else {
                            keyword = p.id
                        }
                         
                        return {keyword: keyword, value: p.id, default: false}
                    })
                    const firewall_ports = firewall_response.data.ports.filter(p => selectedRow.ports.includes(p.id))
                    setFirewallGroupPorts(firewall_ports)
                    setProjectPorts(project_port_list)
                }
            }
        })();
    },[
        neutronServiceDomain,
        neutronServiceVersion,
        portsUrl,
        defaultAdminProject,
        selectedRow,
        subnets
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const method = "GET"
                const s_response = await openstackRequest({
                    url: `${neutronServiceDomain}/${neutronServiceVersion}/${subnetsUrl}`, 
                    method: method, 
                    token: project_token
                })
                if (s_response.status_code === firewallUrlResponses.get.success_response.status_code) {
                    setSubnets(s_response.data.subnets)
                }
            }
        })();
    },[
        neutronServiceDomain,
        neutronServiceVersion,
        subnetsUrl,
        defaultAdminProject,
        selectedRow
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setTimeout(() => setIsCardLoading(false), 600)
    },[])

    return (
        <React.Fragment>
        {selectedRow !== null && 
        <WrapperBox>
            <ServiceContentHeader 
                service_menu={firewallGroupSubMenu}
                service_menu_titles={defaultTexts}
                onClick={handleFirewallGroupDetailTabChange}
            />
        </WrapperBox>}
        {currentTab === "/firewall-group-details" &&
            <FirewallGroupSpecsV20
                firewallGroupData={getFormattedFirewallGroupData(selectedRow)}
            />
        }
        {currentTab === "/firewall-ingress-rules" &&
            <FirewallGroupIngressRulesV20
                firewallGroupRules={firewallGroupIngressRules}
                firewallGroups={firewallGroups}
            />
        }
        {currentTab === "/firewall-egress-rules" &&
            <FirewallGroupEgressRulesV20
                firewallGroupRules={firewallGroupEgressRules}
                firewallGroups={firewallGroups}
            />
        }
        {currentTab === "/firewall-ports" &&
            <FirewallGroupInterfacesV20
                attachedInterfaces={firewallGroupPorts}
                onInterfaceRemove={onInterfaceRemove}
                subnets={subnets}
            />
        }
        {!isCardLoading && <Paper sx={{ 
            position: 'fixed', 
            bottom: 0, 
            height: FOOTER_HEIGHT,
            left: FOOTER_WIDTH, 
            right: 12,
            zIndex: 3000
            }} 
            elevation={24}
            square={true}
        >
            <Grid 
                container 
                alignItems="center"  
                justifyContent="space-between"
            >
                <Grid item>
                    <CustomSelectField 
                        items={firewallGroupActions} 
                        currentValue={currentAction}
                        setCurrentValue={setCurrentAction}
                        item_titles={defaultTexts}
                        label={defaultTexts.actionsDropdownLabelText}
                        empty={true}
                        size="small"
                        sx={{m: 1}}
                    />
                    {currentAction.length > 0 && 
                        <Button 
                                variant="contained"
                                color="secondary"
                                sx={{m: 1, height: '70%'}}
                                onClick={firewallGroupActions.filter(
                                    action => action.value === currentAction)[0].action
                                }
                            >
                            {defaultTexts[firewallGroupActions.filter(
                                action => action.value === currentAction)[0].button_text]}
                        </Button>
                    }
                </Grid>
                <Grid item>
                    {selectedRow !== null && 
                        <IconButton onClick={handleConfirmDeleteDialogOpen}>
                            <DeleteIcon 
                                color="primary"
                            />
                        </IconButton>}
                </Grid>
            </Grid>
        </Paper>} 
        <CustomDialog
            open={updateFirewallGroupDialogOpen}
            onClose={handleUpdateFirewallGroupDialogClose}
            dialogTitle={{
                title: defaultTexts.updateFirewallGroupActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onFirewallGroupUpdate, 
                sx: {color: 'primary.main'}}]}
        >
            {getDataForm(
                firewallGroupDataUpdateForm,
                {},
                firewallGroupUpdateData,
                handleFirewallGroupUpdateDataChange
            )}
        </CustomDialog>
        <CustomDialog
            open={updateFWGroupPoliciesDialogOpen}
            onClose={handleUpdateFWGroupPoliciesDialogClose}
            dialogTitle={{
                title: defaultTexts.updateFWGroupPoliciesActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onFirewallGroupPoliciesUpdate, 
                sx: {color: 'primary.main'}}]}
        >
            {getUpdatePoliciesDataForm()}
        </CustomDialog>
        <CustomDialog
            open={addToPortDialogOpen}
            onClose={handleAddToPortDialogClose}
            dialogTitle={{
                title: defaultTexts.fwGroupAddToPortActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: defaultTexts.fwGRAddPortNoteText, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onAddToPort, 
                sx: {color: 'primary.main'}}]}
        >
            <CustomSelectField
                currentValue={selectedPorts}
                setCurrentValue={setSelectedPorts}
                multiple={true}
                label={defaultTexts.portFormFieldLabel}
                empty={true}
                items={projectPorts}
                self_item_titles={true}
                sx={{mt: 2, width: "90%"}}
            />
        </CustomDialog>
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts.failedActionErrorDialogTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts.failedActionErrorDialogMessage}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
        </React.Fragment>
    )
};

export default FirewallGroupDetailV20;