import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../../../_common/CustomTable';
import { qosSpecsDataSchema } from 
'../../../../../_data/openstack/cinder/volume_types/v3';
import { setQoSSpecsTableConfig } from 
'../../../../../store/reducers/blockStorageCinderSlice';
import { qosSpecsSortFields } from 
'../../../../../_data/openstack/cinder/volume_types/v3';


const QoSSpecsTableV3 = (props) => {
    const { qosSpecsData, setQoSSpecsData } = props
    const { handleRowSelection } = props
    const {currentAction} = props
    const {setCurrentAction}  = props
    const { actionsTexts }  = props
    const { actionsList } = props
    const { sortHandler } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);
    
    const qosSpecsTableConfig = useSelector(
        state => state.blockStorageCinder.qosSpecsTableConfig)
    const rowsPerPage = useSelector(
        state => state.blockStorageCinder.qosSpecsTableConfig.filter(
        versions => versions.api_version === "v3")[0].rows_per_page)
    const qosSpecsTableColumns = useSelector(
        state => state.blockStorageCinder.qosSpecsTableConfig.filter(
            versions => versions.api_version === "v3")[0].columns)
    
    const identityField = qosSpecsTableColumns.filter(
        item => item.is_id_field)[0].field_key

    const dispatch = useDispatch();

    const handleQoSSpecsTableConfig = (value_list) => {
        let newTableConfig = qosSpecsDataSchema.filter(
            n => value_list.includes(n.field_key))
        const new_data = qosSpecsTableConfig.map(version => {
            if (version.api_version === "v3") {
                let new_item = {...version}
                new_item.columns = [...newTableConfig]
                return new_item
            } else {
                return version
            }
        })
        dispatch(setQoSSpecsTableConfig({
            qosSpecsTableConfig: new_data
        }))
    }
    
    const handleRowsPerPageChange = (rows_num) => {
        const new_data = qosSpecsTableConfig.map(version => {
            if (version.api_version === "v3") {
                let new_item = {...version}
                new_item.rows_per_page = rows_num
                return new_item
            } else {
                return version
            }
        })
        dispatch(setQoSSpecsTableConfig({
            qosSpecsTableConfig: new_data
        }))
    }

    return (
        <React.Fragment>
            <CustomTable
                withPagination={true}
                withCheckbox={true}
                withActions={true}
                withSorting={true}
                defaultDataTexts={{...defaultTexts}} 
                tableHeaders={qosSpecsTableColumns}
                tableHeadersConfigHandler={handleQoSSpecsTableConfig}
                identityField={identityField}
                columns={[...qosSpecsDataSchema]}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                dataRows={qosSpecsData}
                setDataRows={setQoSSpecsData}
                handleRowSelection={handleRowSelection}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={actionsTexts}
                actionsList={actionsList}
                sortFields={qosSpecsSortFields}
                sortHandler={sortHandler}
            />
        </React.Fragment>
    )
};

export default QoSSpecsTableV3;