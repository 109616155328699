export const imageDataForm = [
    {
        field_key: "name",
        field_type: "string", 
        required: true, 
        label: "nameFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "min_disk",
        field_type: "integer", 
        required: false, 
        label: "minDiskFormFieldLabel", 
        default_value: 0
    },
    {
        field_key: "min_ram",
        field_type: "integer", 
        required: false, 
        label: "minRamFormFieldLabel", 
        default_value: 0
    },
    {
        field_key: "architecture",
        field_type: "string", 
        required: false, 
        label: "architectureFormFieldLabel"
    },
    {
        field_key: "container_format",
        field_type: "select", 
        required: false, 
        label: "containerFormatFormFieldLabel", 
        default_empty: true,
        on_empty_null: true,
        self_item_titles: false,
        items: [
            {
                keyword: "amiContainerFormatTitle",
                value: "ami",
                default: false
            },
            {
                keyword: "ariContainerFormatTitle",
                value: "ari",
                default: false
            },
            {
                keyword: "akiContainerFormatTitle",
                value: "aki",
                default: false
            },
            {
                keyword: "bareContainerFormatTitle",
                value: "bare",
                default: false
            },
            {
                keyword: "ovfContainerFormatTitle",
                value: "ovf",
                default: false
            },
            {
                keyword: "ovaContainerFormatTitle",
                value: "ova",
                default: false
            },
            {
                keyword: "dockerContainerFormatTitle",
                value: "docker",
                default: false
            },
        ]
    },
    {
        field_key: "disk_format",
        field_type: "select", 
        required: false, 
        label: "diskFormatFormFieldLabel", 
        default_empty: true,
        on_empty_null: true,
        self_item_titles: false,
        items: [
            {
                keyword: "amiContainerFormatTitle",
                value: "ami",
                default: false
            },
            {
                keyword: "ariContainerFormatTitle",
                value: "ari",
                default: false
            },
            {
                keyword: "akiContainerFormatTitle",
                value: "aki",
                default: false
            },
            {
                keyword: "vhdContainerFormatTitle",
                value: "vhd",
                default: false
            },
            {
                keyword: "vhdxContainerFormatTitle",
                value: "vhdx",
                default: false
            },
            {
                keyword: "vmdkContainerFormatTitle",
                value: "vmdk",
                default: false
            },
            {
                keyword: "rawContainerFormatTitle",
                value: "raw",
                default: false
            },
            {
                keyword: "qcow2ContainerFormatTitle",
                value: "qcow2",
                default: false
            },
            {
                keyword: "vdiContainerFormatTitle",
                value: "vdi",
                default: false
            },
            {
                keyword: "ploopContainerFormatTitle",
                value: "ploop",
                default: false
            },
            {
                keyword: "isoContainerFormatTitle",
                value: "iso",
                default: false
            }
        ]
    },
    {
        field_key: "visibility",
        field_type: "select", 
        required: true, 
        label: "visibilityFormFieldLabel", 
        default_empty: false,
        on_empty_null: false,
        self_item_titles: false,
        default_value: "shared",
        error_label: "requiredFormFieldError",
        items: [
            {
                keyword: "publicVisibilityImageText",
                value: "public",
                default: false
            },
            {
                keyword: "privateVisibilityImageText",
                value: "private",
                default: false
            },
            {
                keyword: "sharedVisibilityImageText",
                value: "shared",
                default: true
            },
            {
                keyword: "communityVisibilityImageText",
                value: "community",
                default: false
            },
        ]
    },
    {
        field_key: "protected",
        field_type: "bool", 
        required: false, 
        label: "protectedFormFieldLabel", 
        default_value: false
    },
    {
        field_key: "os_hidden",
        field_type: "bool", 
        required: false, 
        label: "hiddenFormFieldLabel", 
        default_value: false
    }
]

export const imageDataSchema = [
    {
        field_key: "owner",
        is_id_field: false,
        field_type: "string", 
        label: "projectFormFieldLabel"
    },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "type",
        is_id_field: false,
        field_type: "string", 
        label: "typeFormFieldLabel"
    },
    {
        field_key: "status",
        is_id_field: false,
        field_type: "string", 
        label: "statusFormFieldLabel"
    },
    {
        field_key: "status_icon",
        is_id_field: false,
        field_type: "string", 
        label: "readyFormFieldLabel"
    },
    {
        field_key: "visibility",
        is_id_field: false,
        field_type: "string", 
        label: "visibilityFormFieldLabel"
    },
    {
        field_key: "protected",
        is_id_field: false,
        field_type: "bool", 
        label: "protectedFormFieldLabel",
        value_on_true: "formValueYes",
        value_on_false: "formValueNo"
    },
    {
        field_key: "disk_format",
        is_id_field: false,
        field_type: "string", 
        label: "diskFormatFormFieldLabel"
    },
    {
        field_key: "size",
        is_id_field: false,
        field_type: "string", 
        label: "sizeFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const imageFilterMenu = [
    {
        keyword: "nameFormFieldLabel",
        value: "name",
        type: "text"
    },
    {
        keyword: "projectFormFieldLabel", 
        value: "owner", 
        type: "select",
        items: [],
        self_item_titles: true,
        default_empty: true
    },
    {
        keyword: "statusFormFieldLabel", 
        value: "status", 
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "activeImageStatusText", 
                value: "active", 
                default: true
            },
            {
                keyword: "queuedImageStatusText", 
                value: "queued", 
                default: false
            },
            {
                keyword: "savingImageStatusText", 
                value: "saving", 
                default: false
            },
            {
                keyword: "killedImageStatusText", 
                value: "killed", 
                default: false
            },
            {
                keyword: "deletedImageStatusText", 
                value: "deleted", 
                default: false
            },
            {
                keyword: "pendingDeleteImageStatusText", 
                value: "pending_delete", 
                default: false
            },
            {
                keyword: "deactivatedImageStatusText", 
                value: "deactivated", 
                default: false
            },
            {
                keyword: "uploadingImageStatusText", 
                value: "uploading", 
                default: false
            },
            {
                keyword: "importingImageStatusText", 
                value: "importing", 
                default: false
            }
        ]
    },
    {
        keyword: "visibilityFormFieldLabel", 
        value: "visibility", 
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "publicVisibilityImageText", 
                value: "public", 
                default: true
            },
            {
                keyword: "communityVisibilityImageText", 
                value: "community", 
                default: false
            },
            {
                keyword: "sharedVisibilityImageText", 
                value: "shared", 
                default: false
            },
            {
                keyword: "privateVisibilityImageText", 
                value: "private", 
                default: false
            }
        ]
    },
    {
        keyword: "hiddenImageStatusText", 
        value: "os_hidden", 
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "filterChoiceYes", 
                value: "true", 
                default: true
            },
            {
                keyword: "filterChoiceNo", 
                value: "false", 
                default: false
            }
        ]
    },
    {
        keyword: "minSizeFormFieldLabel",
        value: "size_min",
        type: "integer"
    },
    {
        keyword: "maxSizeFormFieldLabel",
        value: "size_max",
        type: "integer"
    },
    {
        keyword: "memberStatusImageText", 
        value: "member_status", 
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "acceptedMeberStatusImageText", 
                value: "accepted", 
                default: false
            },
            {
                keyword: "pendingMeberStatusImageText", 
                value: "pending", 
                default: true
            },
            {
                keyword: "rejectedVisibilityImageText", 
                value: "rejected", 
                default: false
            }
        ]
    }
]

export const imageSortFields = [
    {
        field_key: "name",
        label: "nameFormFieldLabel"
    },
    {
        field_key: "owner",
        label: "projectFormFieldLabel"
    },
    {
        field_key: "status",
        label: "statusFormFieldLabel"
    },
    {
        field_key: "visibility",
        label: "visibilityFormFieldLabel"
    },
    {
        field_key: "size",
        label: "sizeFormFieldLabel"
    }
]