import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CustomDialog from '../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import { openStackServices, 
        blockStorageCinderConstants, 
        identityKeystonConstants
} from '../../../../../config/openStackConstants';
import SnapshotsSubheaderV3 from './groupSnapshotsSubheaderV3';
import SnapshotsTableV3 from './groupSnapshotsTableV3';
import { snapshotsFilterMenu } 
from '../../../../../_data/openstack/cinder/group_snapshots/v3';
import { 
    volumeCinderRequest, 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../_network/openstack_request';
import { snapshotsUrl as snapshotUrlResponses } 
from '../../../../../_api_responses/openstack/cinder/snapshots/v3';
import { projectsUrl as projectsUrlResponses } from 
'../../../../../_api_responses/openstack/identity/projects/v3';
import { groupsUrl as groupUrlResponses } from 
'../../../../../_api_responses/openstack/cinder/group_snapshots/v3';

const SERVICE_NAME = openStackServices.volumeService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const SnapshotsWrapperV3 = (props) => {
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [snapshotsData, setSnapshotsData] = useState([])
    const [snapshots, setSnapshots] = useState([])
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [currentAction, setCurrentAction] = useState("");
    const [snapshotDeleteConfirmDialogOpen, setSnapshotDeleteConfirmDialogOpen] = useState(false);
    const [selectedSnapshots, setSelectedSnapshots] = useState([])
    const [snapshotsSortParams, setSnapshotsSortParams] = useState("")
    
    const [snapshotFilterQueryParams, setSnapshotFilterQueryParams] = useState("?all_tenants=true")
    const [selectedSnapshotFilter, setSelectedSnapshotFilter] = useState(snapshotsFilterMenu[0].value)
    const [selectedSnapshotFilterValue, setSelectedSnapshotFilterValue] = useState("")
    const [snapshotsFilter, setSnapshotsFilter] = useState([...snapshotsFilterMenu])

    const [projects, setProjects] = useState([])
    const [groups, setGroups] = useState([])
    const [groupTypes, setGroupTypes] = useState([]);

    const cinderServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const cinderServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const snapshotsUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.groupSnapshotsUrl)[0].url)
    const groupsUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.groupsUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    const groupTypesUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.groupTypesUrl)[0].url)
    

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleSnapshotFilteredSearch = () => {
        if (selectedSnapshotFilter && selectedSnapshotFilterValue) {
            setSnapshotFilterQueryParams(`?${selectedSnapshotFilter}=${selectedSnapshotFilterValue}&&all_tenants=true`)
        } else {
            setSnapshotFilterQueryParams("?all_tenants=true")
        }
        handleDataFetch()
    }

    const handleSnapshotsDataFormatting = useCallback(() => {
        const formatted_data = snapshotsData.map((item) => {
            let new_item = {...item}
            const project = projects.filter(p => p.id === item.project_id)
            if (project.length > 0) {
                new_item.project_id = project[0].name
            }
            const group = groups.filter(gr => gr.id === item.group_id)
            if (group.length > 0) {
                new_item.group_id = group[0].name ? group[0].name : group[0].id
            }
            const group_type = groupTypes.filter(grt => grt.id === item.group_type_id)
            if (group_type.length > 0) {
                new_item.group_type_id = group_type[0].name ? group_type[0].name : group_type[0].id
            }
            return new_item
        })
        setSnapshots(formatted_data)
    },[
        snapshotsData,
        groups,
        groupTypes,
        projects
    ])

    const handleSnapshotFilterReset = () => {
        setSelectedSnapshotFilter(snapshotsFilterMenu[0].value)
        setSelectedSnapshotFilterValue("")
        setSnapshotFilterQueryParams("?all_tenants=true")
        handleDataFetch()
    }

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleSnapshotsSorting = (field,direction) => {
        const sort_param = `&&sort=${field}:${direction}`
        setSnapshotsSortParams(sort_param)
        handleDataFetch()
    }

    const handleSnapshotDelete = async (s_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${snapshotsUrl}/${s_id}`
            const method = "DELETE"
            
            const vt_response = await volumeCinderRequest({
                url:url, 
                method:method,
                token: project_token
            })

            if (vt_response.status_code === snapshotUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return vt_response.error
            }
        }
    };

    const onSnapshotDelete = async () => {
        handleSnapshotDeleteConfirmDialogClose()
        let err = []
        for (let s in selectedSnapshots) {
            const resp = await handleSnapshotDelete(selectedSnapshots[s].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onSnapshotDeleteConfirm = (s_list) => {
        const selected_s_list = snapshotsData.filter(s => 
            s_list.includes(s.id))
        setSelectedSnapshots([...selected_s_list])
        setSnapshotDeleteConfirmDialogOpen(true)
    }

    const handleSnapshotDeleteConfirmDialogClose = () => {
        setSnapshotDeleteConfirmDialogOpen(false)
    }

    const getSnapshotsActionsList = () => {
        let snapshot_actions = []
        let new_action = {}
        new_action["value"] = "snapshot_delete"
        new_action["action"] = onSnapshotDeleteConfirm
        new_action["keyword"] = "snapshotDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        snapshot_actions.push({...new_action})
        
        return snapshot_actions
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${snapshotsUrl}/detail${snapshotFilterQueryParams}${snapshotsSortParams}`
                    const method = "GET"
                    const snapshots_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                    if (snapshots_response.status_code === snapshotUrlResponses.get.success_response.status_code) {
                        setSnapshotsData(snapshots_response.data.group_snapshots)
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        cinderServiceDomain, 
        cinderServiceVersion, 
        snapshotsUrl, 
        snapshotFilterQueryParams,
        dataFetchingRequired,
        defaultAdminProject,
        snapshotsSortParams
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let all_group_types = []
                let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupTypesUrl}`
                const method = "GET"

                const group_types_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                if (group_types_response.status_code === groupUrlResponses.get.success_response.status_code) {
                    all_group_types = [...all_group_types, ...group_types_response.data.group_types]
                }
                url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupTypesUrl}?is_public=false`
                const response = await volumeCinderRequest({url:url, method:method, token: project_token})
                if (response.status_code === groupUrlResponses.get.success_response.status_code) {
                    const private_vt = response.data.group_types.filter(vt => !all_group_types.map(i => i.id).includes(vt.id))
                    all_group_types = [...private_vt, ...all_group_types]
                }
                setGroupTypes([...all_group_types])
            }
        })();
    },[
        cinderServiceDomain,
        cinderServiceVersion,
        defaultAdminProject,
        groupTypesUrl
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupsUrl}/detail?all_tenants=true`
                const method = "GET"

                const group_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                if (group_response.status_code === groupUrlResponses.get.success_response.status_code) {
                    setGroups(group_response.data.groups)
                }
            }
        })();
    },[
        cinderServiceDomain,
        cinderServiceVersion,
        defaultAdminProject,
        groupsUrl
    ]);


    useEffect(() => {
        if (snapshotsData.length > 0) {
            handleSnapshotsDataFormatting()
        }
    },[
        snapshotsData,
        handleSnapshotsDataFormatting
    ])

    useEffect(() => {
        let projects_filter = []
        if (projects.length > 0) {
            projects_filter = projects.map(project => {
                return {keyword: project.name, value: project.id, default: false}
            })
        }
        let group_list = []
        if (groups.length > 0) {
            group_list = groups.map(vt => {
                return {keyword: `${vt.name} (${vt.id})`, value: vt.id, default: false}
            })
        }

        let snapshot_filter_menu = snapshotsFilterMenu.map(fl => {
            let new_item = {...fl}
            if (fl.value === "project_id") {
                new_item.items = [...projects_filter]
            } else if (fl.value === "group_id") {
                new_item.items = [...group_list]
            }
            return new_item
        })

        setSnapshotsFilter(snapshot_filter_menu)
    },[
        snapshotsData,
        projects,
        groups
    ])

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <SnapshotsSubheaderV3 
                selectedFilter={selectedSnapshotFilter} 
                setSelectedFilter={setSelectedSnapshotFilter}
                selectedFilterValue={selectedSnapshotFilterValue}
                setSelectedFilterValue={setSelectedSnapshotFilterValue}
                handleDataFetch={handleDataFetch}
                filterMenu={snapshotsFilter}
                handleFilteredSearch={handleSnapshotFilteredSearch}
                handleFilterReset={handleSnapshotFilterReset}
                groups={groups}
            />
            {isLoading && <CustomBackdrop open={isLoading} />}
            {!isLoading &&
                <SnapshotsTableV3 
                    data={snapshots}
                    setData={setSnapshots}
                    currentAction={currentAction}
                    setCurrentAction={setCurrentAction}
                    actionsTexts={defaultTexts}
                    actionsList={getSnapshotsActionsList()}
                    sortHandler={handleSnapshotsSorting}
                />
            }
            <CustomDialog
                open={snapshotDeleteConfirmDialogOpen}
                onClose={handleSnapshotDeleteConfirmDialogClose}
                dialogTitle={{
                    title: defaultTexts.snapshotDeleteConfirmTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `${defaultTexts.snapshotDeleteConfirmText}: [${selectedSnapshots.map(v => v.name).toString()}]`, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: onSnapshotDelete, 
                    sx: {color: 'primary.main'}}]}
            />
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </Box>
    )
};

export default SnapshotsWrapperV3;