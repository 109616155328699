import React from 'react';
import { useSelector } from 'react-redux';
import WrapperBox from '../../_common/WrapperBox';
import NotPurchasedService from '../../_common/NotPurchasedService';

const SERVICE_NAME = 'lb_octavia'
const SERVICE_TITLE = 'Loadbalancers (Octavia)'

const LBOctaviaContent = (props) => {
    const purchasedServices = useSelector(state => state.openstack.purchasedServices);

    const purchasedService = purchasedServices.filter(item => item.service === SERVICE_NAME)
    if (purchasedService.length === 0) {
        return (
            <NotPurchasedService service={SERVICE_TITLE} />
        )
    }
    return (
        <WrapperBox>
            Loadbalancers (Octavia) Service access
        </WrapperBox>
    )
};

export default LBOctaviaContent;