import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../../../../_common/CustomTable';
import { securityGroupDataSchema, securityGroupsSortFields } from 
'../../../../../../_data/openstack/neutron/security/v2.0';
import { setSecurityGroupsTableConfig } from 
'../../../../../../store/reducers/networkNeutronSlice';

const SecurityGroupsTableV20 = (props) => {
    const { securityGroupsData, setSecurityGroupsData } = props
    const { handleRowSelection } = props
    const {currentAction} = props
    const {setCurrentAction}  = props
    const { actionsTexts }  = props
    const { actionsList } = props
    const { sortHandler } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);
    
    const securityGroupsTableConfig = useSelector(
        state => state.networkNeutron.securityGroupsTableConfig)
    const rowsPerPage = useSelector(
        state => state.networkNeutron.securityGroupsTableConfig.filter(
        versions => versions.api_version === "v2.0")[0].rows_per_page)
    const securityGroupsTableHeaders = useSelector(
        state => state.networkNeutron.securityGroupsTableConfig.filter(
            versions => versions.api_version === "v2.0")[0].columns)

    
    const identityField = securityGroupsTableHeaders.filter(
        item => item.is_id_field)[0].field_key
    
    
    const dispatch = useDispatch();

    const handleSecurityGroupTableConfig = (value_list) => {
        const newTableConfig = securityGroupDataSchema.filter(
            n => value_list.includes(n.field_key))
        const new_data = securityGroupsTableConfig.map(version => {
            if (version.api_version === "v2.0") {
                let new_item = {...version}
                new_item.columns = [...newTableConfig]
                return new_item
            } else {
                return version
            }
        })
        dispatch(setSecurityGroupsTableConfig({
            securityGroupsTableConfig: new_data
        }))
    }
    
    const handleRowsPerPageChange = (rows_num) => {
        const new_data = securityGroupsTableConfig.map(version => {
            if (version.api_version === "v2.0") {
                let new_item = {...version}
                new_item.rows_per_page = rows_num
                return new_item
            } else {
                return version
            }
        })
        dispatch(setSecurityGroupsTableConfig({
            securityGroupsTableConfig: new_data
        }))
    }

    return (
        <React.Fragment>
            <CustomTable
                withPagination={true}
                withCheckbox={true}
                withActions={true}
                withSorting={true}
                defaultDataTexts={{...defaultTexts}} 
                tableHeaders={securityGroupsTableHeaders}
                tableHeadersConfigHandler={handleSecurityGroupTableConfig}
                identityField={identityField}
                columns={[...securityGroupDataSchema]}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                dataRows={securityGroupsData}
                setDataRows={setSecurityGroupsData}
                handleRowSelection={handleRowSelection}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={actionsTexts}
                actionsList={actionsList}
                sortFields={securityGroupsSortFields}
                sortHandler={sortHandler}
                selfSorting={true}
            />
        </React.Fragment>
    )
};

export default SecurityGroupsTableV20;