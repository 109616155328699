import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import CustomDialog from '../../../../../_common/CustomDialog';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import Dimensions from '../../../../../../config/dimensions';
import CustomSideDrawer from '../../../../../_common/CustomSideDrawer';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, 
        networkNeutronConstants, 
        identityKeystonConstants
} from '../../../../../../config/openStackConstants';
import NetworksSubheaderV20 from './networksSubheaderV2.0';
import NetworksTableV20 from './networksTableV2.0';
import NetworkDetailV20 from './networkDetailV2.0';
import { networksFilterMenu, networkDataUpdateForm } 
from '../../../../../../_data/openstack/neutron/networks/v2.0';
import { 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../../_network/openstack_request';
import { networksUrl as networkUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/networks/v2.0';
import { projectsUrl as projectUrlResponses} from 
'../../../../../../_api_responses/openstack/identity/projects/v3';
import { getFormFieldComponent } from 
'../../../../../_common/_form_fields/form_helpers';

const SERVICE_NAME = openStackServices.networkService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const NetworksWrapperV20 = (props) => {
    const { navigate, location, changeMenuActiveTab } = props
    const { segmentsSupported, trunksSupported} = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const {currentTab, setCurrentTab} = props
    const [networksData, setNetworksData] = useState([])
    const [networks, setNetworks] = useState([])
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = width < Dimensions.tablet_mini.width ? 0.9 : 0.8
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedNetwork, setSelectedNetwork] = useState(null);
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [currentAction, setCurrentAction] = useState("");
    const [networkDeleteConfirmDialogOpen, setNetworkDeleteConfirmDialogOpen] = useState(false);
    const [selectedNetworks, setSelectedNetworks] = useState([])
    const [networksSortParams, setNetworksSortParams] = useState("")
    
    const [networkFilterQueryParams, setNetworkFilterQueryParams] = useState("")
    const [selectedNetworkFilter, setSelectedNetworkFilter] = useState(networksFilterMenu[0].value)
    const [selectedNetworkFilterValue, setSelectedNetworkFilterValue] = useState("")
    const [networksFilter, setNetworksFilter] = useState([...networksFilterMenu])
    const [networkUpdateData, setNetworkUpdateData] = useState({})
    const [selectedUpdateField, setSelectedUpdateField] = useState("")
    const [networkUpdateDialogOpen, setNetworkUpdateDialogOpen] = useState(false)

    const [projects, setProjects] = useState([])

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const networksUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.networksUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleTabChange = (tab_name) => {
        setCurrentTab(tab_name)
    }

    const handleNetworkFilteredSearch = () => {
        if (selectedNetworkFilter && selectedNetworkFilterValue) {
            setNetworkFilterQueryParams(`${selectedNetworkFilter}=${selectedNetworkFilterValue}`)
        } else {
            setNetworkFilterQueryParams("")
        }
        handleDataFetch()
    }

    const handleNetworksDataFormatting = useCallback(() => {
        const formatted_data = networksData.map((item) => {
            let new_item = {...item}
            const project = projects.filter(p => p.id === item.project_id)
            if (project.length > 0) {
                new_item.project_id = project[0].name
            } else {
                new_item.project_id = "-"
            }
            if (Object.keys(item).includes("segments")) {
                new_item.segments = item.segments.length
            } else {
                new_item.segments = "-"
            }
            return new_item
        })

        setNetworks(formatted_data)
    },[
        networksData,
        projects
    ])

    const handleNetworkFilterReset = () => {
        setSelectedNetworkFilter(networksFilterMenu[0].value)
        setSelectedNetworkFilterValue("")
        setNetworkFilterQueryParams("")
        handleDataFetch()
    }

    const handleNavigateToSubnet = (subnet_id,tab_name) => {
        handleDetailCardClose()
        changeMenuActiveTab(tab_name)
        navigate("/networks",{state: {subnet_id: subnet_id}})
    }

    const handleDetailCardOpen = useCallback((index) => {
        setSelectedNetwork(networksData[index].id)
        setSelectedRow(networksData[index])
        setTimeout(() => setDetailCardOpen(true),100)
    },[networksData]);

    const handleDetailCardClose = () => {
        setTimeout(() => setDetailCardOpen(false),100)
        setSelectedRow(null)
        setSelectedNetwork(null)
        navigate(location.path,{})
    };

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleNetworksSorting = (field,direction) => {
        const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
        setNetworksSortParams(sort_param)
        handleDataFetch()
    }

    const handleNetworkUpdate = async (n_id,data) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${networksUrl}/${n_id}`
            const method = "PUT"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                data: {network: data},
                token: project_token
            })

            if (nt_response.status_code === networkUrlResponses.put.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    };

    const onNetworkUpdate = async () => {
        handleNetworkUpdateDialogClose()
        let err = []
        const data = {}
        data[selectedUpdateField] = networkUpdateData[selectedUpdateField]
        for (let n in selectedNetworks) {
            const resp = await handleNetworkUpdate(selectedNetworks[n].id, data)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDetailCardClose()
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorUpdateRecordTitle"
            error_object["error_message"] = "errorUpdateRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const handleNetworkDelete = async (n_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${networksUrl}/${n_id}`
            const method = "DELETE"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                token: project_token
            })

            if (nt_response.status_code === networkUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    };

    const onNetworkDelete = async () => {
        handleNetworkDeleteConfirmDialogClose()
        let err = []
        for (let n in selectedNetworks) {
            const resp = await handleNetworkDelete(selectedNetworks[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDetailCardClose()
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onNetworkDeleteConfirm = (n_list) => {
        const selected_n_list = networksData.filter(n => 
            n_list.includes(n.id))
        setSelectedNetworks([...selected_n_list])
        setNetworkDeleteConfirmDialogOpen(true)
    }

    const handleNetworkDeleteConfirmDialogClose = () => {
        setNetworkDeleteConfirmDialogOpen(false)
    }

    const onNetworkUpdateAdminState = (n_list) => {
        const selected_n_list = networksData.filter(n => 
            n_list.includes(n.id))
        setSelectedNetworks([...selected_n_list])
        setSelectedUpdateField("admin_state_up")
        setNetworkUpdateDialogOpen(true)
    }

    const onNetworkUpdatePortSecurity = (n_list) => {
        const selected_n_list = networksData.filter(n => 
            n_list.includes(n.id))
        setSelectedNetworks([...selected_n_list])
        setSelectedUpdateField("port_security_enabled")
        setNetworkUpdateDialogOpen(true)
    }

    const onNetworkUpdateSharedStatus = (n_list) => {
        const selected_n_list = networksData.filter(n => 
            n_list.includes(n.id))
        setSelectedNetworks([...selected_n_list])
        setSelectedUpdateField("shared")
        setNetworkUpdateDialogOpen(true)
    }

    const onNetworkUpdateExternalStatus = (n_list) => {
        const selected_n_list = networksData.filter(n => 
            n_list.includes(n.id))
        setSelectedNetworks([...selected_n_list])
        setSelectedUpdateField("router:external")
        setNetworkUpdateDialogOpen(true)
    }

    const onNetworkUpdateDefaultStatus = (n_list) => {
        const selected_n_list = networksData.filter(n => 
            n_list.includes(n.id))
        setSelectedNetworks([...selected_n_list])
        setSelectedUpdateField("is_default")
        setNetworkUpdateDialogOpen(true)
    }

    const onNetworkUpdateMTU = (n_list) => {
        const selected_n_list = networksData.filter(n => 
            n_list.includes(n.id))
        setSelectedNetworks([...selected_n_list])
        setSelectedUpdateField("mtu")
        setNetworkUpdateDialogOpen(true)
    }

    const handleNetworkUpdateDialogClose = () => {
        setNetworkUpdateDialogOpen(false)
    }

    const handleNetworkUpdateDataChange = (event,field_key) => {
        let updated_data = {...networkUpdateData}
        const field_type = networkDataUpdateForm.filter(field => field.field_key === field_key)[0].field_type
        if (field_type === "bool") {
            updated_data[field_key] = event.target.checked
        } else if (field_type === "select") {
            updated_data[field_key] = event
        } else {
            updated_data[field_key] = event.target.value
        }
        setNetworkUpdateData(updated_data)        
    }

    const getUpdateForm = () => {
        let form = networkDataUpdateForm.filter(field => field.field_key === selectedUpdateField)
        return (
            <FormGroup>
                {form.map(field => {
                    let form_field_options = {}
                    return (
                        getFormFieldComponent(
                            field,
                            networkUpdateData,
                            handleNetworkUpdateDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const getNetworksActionsList = () => {
        let network_actions = []
        let new_action = {}
        new_action["value"] = "update_admin_state"
        new_action["action"] = onNetworkUpdateAdminState
        new_action["keyword"] = "networkUpdateAdminStateActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        network_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "update_port_security"
        new_action["action"] = onNetworkUpdatePortSecurity
        new_action["keyword"] = "networkUpdatePortSecurityActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        network_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "update_shared_status"
        new_action["action"] = onNetworkUpdateSharedStatus
        new_action["keyword"] = "networkUpdateSharedStatusActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        network_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "update_external_status"
        new_action["action"] = onNetworkUpdateExternalStatus
        new_action["keyword"] = "networkUpdateExternalStatusActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        network_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "update_default_status"
        new_action["action"] = onNetworkUpdateDefaultStatus
        new_action["keyword"] = "networkUpdateDefaultStatusActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        network_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "update_mtu"
        new_action["action"] = onNetworkUpdateMTU
        new_action["keyword"] = "networkUpdateMTUActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        network_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "network_delete"
        new_action["action"] = onNetworkDeleteConfirm
        new_action["keyword"] = "networkDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        network_actions.push({...new_action})
        
        return network_actions
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${neutronServiceDomain}/${neutronServiceVersion}/${networksUrl}?${networkFilterQueryParams}${networksSortParams}`
                    const method = "GET"
                    const network_response = await openstackRequest({url:url, method:method, token: project_token})
                    if (network_response.status_code === networkUrlResponses.get.success_response.status_code) {
                        setNetworksData(network_response.data.networks)
                        if (selectedNetwork) {
                            const selected_network = network_response.data.networks.filter(item => item.id === selectedNetwork)
                            if (selected_network.length > 0) {
                                setSelectedRow(selected_network[0])
                            }
                        }
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        networksUrl, 
        networkFilterQueryParams,
        dataFetchingRequired,
        defaultAdminProject,
        networksSortParams,
        selectedNetwork
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === projectUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        if (networksData.length > 0) {
            handleNetworksDataFormatting()
        }
    },[
        networksData,
        handleNetworksDataFormatting
    ])

    useEffect(() => {
        let projects_filter = []
        if (projects.length > 0) {
            projects_filter = projects.map(project => {
                return {keyword: project.name, value: project.id, default: false}
            })
        }
        
        let network_filter_menu = networksFilterMenu.map(nt => {
            let new_item = {...nt}
            if (nt.value === "project_id") {
                new_item.items = [...projects_filter]
            }
            return new_item
        })

        setNetworksFilter(network_filter_menu)
    },[
        networksData,
        projects
    ])

    useEffect(() => {
        if (!dataFetchingRequired && location.state ) {
            const network_id = location.state ? location.state.network_id : null
                const network_index = networksData.findIndex(v => v.id === network_id);
                if (network_index !== -1) {
                    setTimeout(() => handleDetailCardOpen(network_index), 600)
                }
        }
    },[
        dataFetchingRequired,
        networksData,
        handleDetailCardOpen,
        location
    ])

    useEffect(() => {
        if (Object.keys(networkUpdateData).length === 0) {
            let new_form_data = {}
            for (const n in networkDataUpdateForm) {
                if (
                    networkDataUpdateForm[n].field_type === "string" || 
                    networkDataUpdateForm[n].field_type === "select"
                    ) {
                    new_form_data[networkDataUpdateForm[n].field_key] = ""
                } else if (networkDataUpdateForm[n].field_type === "bool") {
                    new_form_data[networkDataUpdateForm[n].field_key] = networkDataUpdateForm[n].default_value ? 
                    networkDataUpdateForm[n].default_value : 
                    false
                }
            }
            setNetworkUpdateData(new_form_data)
        }
    },[
        networkUpdateData
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <NetworksSubheaderV20 
                selectedFilter={selectedNetworkFilter} 
                setSelectedFilter={setSelectedNetworkFilter}
                selectedFilterValue={selectedNetworkFilterValue}
                setSelectedFilterValue={setSelectedNetworkFilterValue}
                handleDataFetch={handleDataFetch}
                filterMenu={networksFilter}
                handleFilteredSearch={handleNetworkFilteredSearch}
                handleFilterReset={handleNetworkFilterReset}
                currentTab={currentTab}
                handleTabChange={handleTabChange}
                projects={projects}
                segmentsSupported={segmentsSupported}
                trunksSupported={trunksSupported}
            />
        {isLoading && <CustomBackdrop open={isLoading} />}
        {!isLoading &&
            <NetworksTableV20 
                networksData={networks}
                setNetworksData={setNetworks}
                handleRowSelection={handleDetailCardOpen}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getNetworksActionsList()}
                sortHandler={handleNetworksSorting}
            />
        }
        {selectedRow !== null && 
            <CustomSideDrawer 
                open={detailCardOpen}
                widthWeight={WIDTH_WEIGHT}
                handleDrawerOpen={handleDetailCardOpen}
                handleDrawerClose={handleDetailCardClose}
            > 
                <NetworkDetailV20
                    selectedRow={selectedRow}
                    widthWeight={WIDTH_WEIGHT}
                    handleDataFetch={handleDataFetch}
                    handleNavigateToSubnet={handleNavigateToSubnet}
                    handleDelete={onNetworkDeleteConfirm}
                    projects={projects}
                /> 
            </CustomSideDrawer>
        }
        <CustomDialog
            open={networkDeleteConfirmDialogOpen}
            onClose={handleNetworkDeleteConfirmDialogClose}
            dialogTitle={{
                title: defaultTexts.networkDeleteConfirmTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `${defaultTexts.networkDeleteConfirmText}: [${selectedNetworks.map(v => v.name).toString()}]`, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onNetworkDelete, 
                sx: {color: 'primary.main'}}]}
        />
        <CustomDialog
            open={networkUpdateDialogOpen}
            onClose={handleNetworkUpdateDialogClose}
            dialogTitle={{
                title: defaultTexts.networkUpdateTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onNetworkUpdate, 
                sx: {color: 'primary.main'}}]}
        >
                {getUpdateForm()}
        </CustomDialog>
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts[error.error_title], 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts[error.error_message]}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
    </Box>
    )
};

export default NetworksWrapperV20;