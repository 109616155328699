import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import AddButton from '../../_common/AddButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import CustomText from '../../_common/CustomText';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import Grid from '@mui/material/Grid';
import Constants from '../../../config/constants';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { openStackServiceLabels } from '../../../config/openStackConstants';
import CustomSideDrawer from '../../_common/CustomSideDrawer';
import BillingServiceDetail from './billingServiceDetail';
import useWindowDimensions from '../../_common/WindowDimensions';
import Dimensions from '../../../config/dimensions';
import BillingServiceForm from '../_common/forms/billingServiceForm';
import billingRequest from '../../../_network/billing_request';
import { billingServiceURLs } from '../../../_network/apiUrls';

const drawerWidth = Constants.drawerWidth;

const SERVICE_DEFAULT_FORM = {
    service_name: "",
    region_name: "",
    service_domain: "",
    service_version: "",
    resource_suspend_period: 1440,
    resource_suspend_policy: "",
    resource_delete_period: 10080,
    resource_delete_policy: "",
    is_identity_provider: true,
    credentials_id: "",
    credentials_secret: ""
}

const getWidthWeight = (width) => {
    if (width < Dimensions.tablet_mini.width) {
        return 0.9
    } else if (width < Dimensions.tablet.width) {
        return 0.6
    } else {
        return 0.4
    }
}

const BillingServicesTable = (props) => {
    const [isLoading, setIsLoading] = useState(true)
    const open = useSelector(state => state.drawer.drawerOpened);
    const clientID = useSelector(state => state.profile.clientAccountID)
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = getWidthWeight(width)
    const [selectedRow, setSelectedRow] = useState(null)
    const [selectedService, setSelectedService] = useState(null)
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const { dataRows, data, handleDataFetch } = props
    const [serviceForm, setServiceForm] = useState({...SERVICE_DEFAULT_FORM})
    const [serviceFormOptions, setServiceFormOptions] = useState({})
    const purchasedService = useSelector(
        state => state.openstack.purchasedServicesAll)
    const [billingServicesChecked, setBillingServicesChecked] = useState(false)
    const [serviceCreateAllowed, setServiceCreateAllowed] = useState(false)

    const theme = useTheme();

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleServiceFormUpdate = (event, field_key) => {
        let updated_options = {...serviceFormOptions}
        if (Object.keys(updated_options).includes(field_key)) {
            updated_options[field_key] = {}
        }
        setServiceFormOptions(updated_options)
        let updated_form = {...serviceForm}
        if (field_key === "service_domain" || 
            field_key === "credentials_id" ||
            field_key === "resource_suspend_period" ||
            field_key === "resource_delete_period" ||
            field_key === "credentials_secret"
        ) {
            updated_form[field_key] = event.target.value
        } else if (field_key === "is_identity_provider") {
            updated_form[field_key] = event.target.checked
        } else {
            updated_form[field_key] = event
        }

        if (field_key === "service_name") {
            updated_form["service_version"] = ""
            updated_form["resource_suspend_policy"] = ""
            updated_form["resource_delete_policy"] = ""
        }

        if (field_key === "region_name") {
            updated_form["service_name"] = ""
        }

        if (field_key === "is_identity_provider" && event.target.checked) {
            updated_form["credentials_id"] = ""
            updated_form["credentials_secret"] = ""
        }

        setServiceForm(updated_form)
    }

    const handleDetailCardOpen = (service_id) => {
        //const selected_service = data.filter(item => item.id === service_id)
        setSelectedService(service_id)
        setTimeout(() => setDetailCardOpen(true), 100)  
    };

    const handleDetailCardClose = () => {
        setTimeout(() => setDetailCardOpen(false), 100)  
        setSelectedRow(null)
    };

    const getDataForm = () => {
        return <BillingServiceForm 
                data={serviceForm} 
                options={serviceFormOptions}
                handleDataUpdate={handleServiceFormUpdate}
            />
    }

    const newServiceDataValidation = () => {
        let is_valid = true
        let updated_options = {...serviceFormOptions}
        if (serviceForm.region_name.length === 0) {
            is_valid = false
            
            updated_options["region_name"] = {}
            updated_options["region_name"]["error"] = true
            updated_options["region_name"]["errorText"] = defaultTexts.requiredFormFieldError
        }

        if (serviceForm.service_name.length === 0) {
            is_valid = false
            updated_options["service_name"] = {}
            updated_options["service_name"]["error"] = true
            updated_options["service_name"]["errorText"] = defaultTexts.requiredFormFieldError
        }

        if (serviceForm.service_domain.length === 0) {
            is_valid = false
            updated_options["service_domain"] = {}
            updated_options["service_domain"]["error"] = true
            updated_options["service_domain"]["errorText"] = defaultTexts.requiredFormFieldError
        }

        if (serviceForm.service_version.length === 0) {
            is_valid = false
            updated_options["service_version"] = {}
            updated_options["service_version"]["error"] = true
            updated_options["service_version"]["errorText"] = defaultTexts.requiredFormFieldError
        }

        if (serviceForm.resource_suspend_period.length === 0 || serviceForm.resource_suspend_period < 0) {
            is_valid = false
            updated_options["resource_suspend_period"] = {}
            updated_options["resource_suspend_period"]["error"] = true
            updated_options["resource_suspend_period"]["errorText"] = defaultTexts.requiredAndPositiveFormFieldError
        }

        if (serviceForm.resource_delete_period.length === 0 || serviceForm.resource_delete_period < 0) {
            is_valid = false
            updated_options["resource_delete_period"] = {}
            updated_options["resource_delete_period"]["error"] = true
            updated_options["resource_delete_period"]["errorText"] = defaultTexts.requiredAndPositiveFormFieldError
        }

        if (serviceForm.resource_suspend_policy.length === 0) {
            is_valid = false
            updated_options["resource_suspend_policy"] = {}
            updated_options["resource_suspend_policy"]["error"] = true
            updated_options["resource_suspend_policy"]["errorText"] = defaultTexts.requiredFormFieldError
        }

        if (serviceForm.resource_delete_policy.length === 0) {
            is_valid = false
            updated_options["resource_delete_policy"] = {}
            updated_options["resource_delete_policy"]["error"] = true
            updated_options["resource_delete_policy"]["errorText"] = defaultTexts.requiredFormFieldError
        }

        if (serviceForm.is_identity_provider) {
            if (serviceForm.credentials_id.length === 0) {
                is_valid = false
                updated_options["credentials_id"] = {}
                updated_options["credentials_id"]["error"] = true
                updated_options["credentials_id"]["errorText"] = defaultTexts.requiredFormFieldError
            }

            if (serviceForm.credentials_secret.length === 0) {
                is_valid = false
                updated_options["credentials_secret"] = {}
                updated_options["credentials_secret"]["error"] = true
                updated_options["credentials_secret"]["errorText"] = defaultTexts.requiredFormFieldError
            }
        }
        setServiceFormOptions(updated_options)
        return is_valid
    }

    const formatServiceForm = () => {
        let new_data = {...serviceForm}
        new_data["account_id"] = clientID
        if (!new_data.is_identity_provider) {
            delete new_data.credentials_id
            delete new_data.credentials_secret
        } else {
            if (new_data.credentials_id.length > 0 && new_data.credentials_secret.length > 0) {
                new_data["credentials"] = {}
                new_data["credentials"]["app_auth_id"] = new_data.credentials_id
                new_data["credentials"]["app_auth_secret"] = new_data.credentials_secret
            }
        }
        return new_data
    }

    const onAddService = async () => {
        const is_valid = newServiceDataValidation()
        if (is_valid) {
            handleLoading(true)
            const data = formatServiceForm()
            const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingServicesEP}`
            let request_data = {
                url: url,
                method: "POST",
                data: data
            }
            const response = await billingRequest(request_data)
            if (response.status_code === 201) {
                handleDataFetch()
                handleAddServiceFormReset()
                handleLoading(false)
                return true
            } else {
                handleLoading(false)
                return false
            }
        }
    }

    const handleAddServiceFormReset = () => {
        setServiceForm({...SERVICE_DEFAULT_FORM})
        setServiceFormOptions({})
    }

    useEffect(() => {
        setTimeout(() => handleLoading(false), 1000)
    },[])

    useEffect(() => {
        if (!billingServicesChecked && purchasedService.length > 0) {
            const billingService = purchasedService.filter(
            service => service.service === Constants.config_service_billing_name)
            const billingConfiguration = billingService[0].config_params[Constants.config_service_custom_admin_ui]
            if (billingConfiguration.filter(
                section => section.section === Constants.billing_sections.billing_services && 
                section.permissions.includes(Constants.permission_create)).length > 0) {
                    setServiceCreateAllowed(true)
            }
            setBillingServicesChecked(true)
        }
    },[
        purchasedService,
        billingServicesChecked
    ])

    useEffect(() => {
        if (selectedService !== null) {
            const selected_service = data.filter(item => item.id === selectedService)
            setSelectedRow(selected_service[0])
        }
    },[
        data,
        selectedService
    ])

    const Row = (props) => {
        const { row } = props;
        const [open, setOpen] = useState(false);
  
    return (
        <React.Fragment>
            <TableRow  
                sx={{ '& > *': { 
                    borderBottom: 'unset', 
                    borderTop: 'unset' },
                    '&:hover': {
                    backgroundColor: theme.palette.mode === 'dark' ? 
                    theme.palette.mediumGray  :
                    theme.palette.vLightGray,
                    cursor: "pointer"
                    }
                }}
                onClick={() => setOpen(!open)}
            >
                <TableCell>
                    <IconButton
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? 
                            <KeyboardArrowUpIcon /> : 
                            <KeyboardArrowDownIcon />
                        }
                    </IconButton>
                </TableCell>
            <TableCell 
                component="th" 
                scope="row" 
                style={{
                    color: theme.palette.primary.main
                }}
            >
                {openStackServiceLabels[row.name]}
            </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell  style={{width: 30}}/>
                                    <TableCell  style={{width: 50}}/>
                                    <TableCell>{defaultTexts.regionFormFieldLabel}</TableCell>
                                    <TableCell>{defaultTexts.serviceDomainFormFieldLabel}</TableCell>
                                    <TableCell>{defaultTexts.serviceVersionFormFieldLabel}</TableCell>
                                    <TableCell>{defaultTexts.resourceSuspendDelayFormFieldLabel}</TableCell>
                                    <TableCell>{defaultTexts.resourceSuspendPolicyFormFieldLabel}</TableCell>
                                    <TableCell>{defaultTexts.resourceDeleteDelayFormFieldLabel}</TableCell>
                                    <TableCell>{defaultTexts.resourceDeletePolicyFormFieldLabel}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {row.regions.map((reg) => (
                                    <TableRow 
                                        key={reg.region_name} 
                                        onClick={() => handleDetailCardOpen(reg.id)}
                                        sx={{'&:hover': {
                                            backgroundColor: theme.palette.mode === 'dark' ? 
                                            theme.palette.mediumGray  :
                                            theme.palette.vLightGray,
                                            cursor: "pointer"
                                        }}}
                                    >
                                        <TableCell style={{width: 30}}/>
                                        <TableCell style={{width: 50}}/>
                                        <TableCell>
                                            {reg.region_name}
                                        </TableCell>
                                        <TableCell>{reg.service_domain}</TableCell>
                                        <TableCell>{reg.service_version}</TableCell>
                                        <TableCell>
                                                <CustomText>
                                                    {reg.resource_suspend_period} {defaultTexts.minutesUnitLabel}
                                                </CustomText>
                                        </TableCell>
                                        <TableCell>
                                            <CustomText sx={{textTransform: "capitalize"}}>
                                                {reg.resource_suspend_policy}
                                            </CustomText>
                                        </TableCell>
                                        <TableCell>
                                            <CustomText>
                                                {reg.resource_delete_period} {defaultTexts.minutesUnitLabel}
                                            </CustomText>
                                        </TableCell>
                                        <TableCell>
                                            <CustomText sx={{textTransform: "capitalize"}}>
                                                {reg.resource_delete_policy}
                                            </CustomText>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
        );
    }

    if (isLoading) {
        return (
            <Grid 
                container
                alignItems="center"
                spacing={1}
                sx={{
                    maxWidth: open ? 
                    `calc(100vw - ${drawerWidth}px)` : 
                    `calc(100vw - ${110}px)`,
                    mt: 1
                }}
            >
                <Grid item xs={12}>  
                    <Skeleton 
                        variant="rounded" 
                        width="100%" 
                        height={40} 
                        sx={{
                            bgcolor: theme.palette.mode === "dark" ? 
                            theme.palette.background.paper :
                            theme.palette.vLightGray
                        }}
                    />
                </Grid>
                {[1,2,3,4,5].map((item,index) => 
                    <Grid item key={index} xs={12}>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item>  
                                <Skeleton 
                                    variant="circular" 
                                    height={40} 
                                    width={40}
                                    sx={{
                                        bgcolor: theme.palette.mode === "dark" ? 
                                        theme.palette.background.paper :
                                        theme.palette.vLightGray
                                    }}
                                />
                            </Grid>
                            <Grid item sx={{flexGrow: 1}}>  
                                <Skeleton 
                                    variant="rounded" 
                                    width="100%" 
                                    height={40} 
                                    sx={{
                                        bgcolor: theme.palette.mode === "dark" ? 
                                        theme.palette.background.paper :
                                        theme.palette.vLightGray
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        )
    }

    if (!isLoading && dataRows.length === 0) {
        return (
            <Paper
                sx={{
                    border: 1,
                    borderColor: "customBlue",
                    borderRadius: 2,
                    maxWidth: open ? 
                    `calc(100vw - ${drawerWidth}px)` : 
                    `calc(100vw - ${110}px)`,
                    height: 400,
                    alignItems: 'center',
                    justifyContent: 'center',
                    p: 3,
                    mt: 1,
                    display: "flex"
                }}
            >
                <CustomText 
                size="h5" 
                sx={{color: "customBlue"}}
                >
                {defaultTexts.noDataToDisplayText}
                </CustomText>
            </Paper>
        )
    }

    return (
        <Box sx={{
            maxWidth: open ? 
            `calc(100vw - ${drawerWidth}px)` : 
            `calc(100vw - ${110}px)`,
            mt: 2
        }}>
            {serviceCreateAllowed && <AddButton 
                getDataForm={getDataForm}               
                onSubmit={onAddService}
                formReset={handleAddServiceFormReset}
                customTexts={{
                    title: defaultTexts.addBillingServiceDialogTitle,
                    description: defaultTexts.addBillingServiceDialogDescriptionText
                }}
            />}
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 300}} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left" style={{width: 30}}/>
                            <TableCell  align="left" colSpan={7}>
                                {defaultTexts.serviceFormFieldLabel}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {dataRows.map((row) => (
                            <Row key={row.name} row={row} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            {selectedRow !== null && 
                <CustomSideDrawer 
                    open={detailCardOpen}
                    widthWeight={WIDTH_WEIGHT}
                    handleDrawerOpen={handleDetailCardOpen}
                    handleDrawerClose={handleDetailCardClose}
                > 
                    <BillingServiceDetail
                        selectedRow={selectedRow}
                        widthWeight={WIDTH_WEIGHT}
                        handleDataFetch={handleDataFetch}
                        handleLoading={handleLoading}
                        handleDetailCardClose={handleDetailCardClose}
                    />
                </CustomSideDrawer>
            }
        </Box>
    );
};

export default BillingServicesTable;