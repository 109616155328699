export const volumeDataSchema = [
    {
        field_key: "os-vol-tenant-attr:tenant_id",
        is_id_field: false,
        field_type: "string", 
        label: "projectFormFieldLabel"
    },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "size",
        is_id_field: false,
        field_type: "string", 
        label: "sizeFormFieldLabel"
    },
    {
        field_key: "status",
        is_id_field: false,
        field_type: "string", 
        label: "statusFormFieldLabel"
    },
    {
        field_key: "attachments",
        is_id_field: false,
        field_type: "list", 
        label: "attachedToFormFieldLabel"
    },
    {
        field_key: "volume_type",
        is_id_field: false,
        field_type: "string", 
        label: "volumeTypeFormFieldLabel"
    },
    {
        field_key: "group_id",
        is_id_field: false,
        field_type: "string", 
        label: "groupFormFieldLabel",
        min_api_version: "3.13"
    },
    {
        field_key: "bootable",
        is_id_field: false,
        field_type: "bool", 
        label: "bootableFormFieldLabel",
        value_on_true: "formValueYes",
        value_on_false: "formValueNo"
    },
    {
        field_key: "encrypted",
        is_id_field: false,
        field_type: "bool", 
        label: "encryptedFormFieldLabel",
        value_on_true: "formValueYes",
        value_on_false: "formValueNo"
    },
    {
        field_key: "os-vol-host-attr:host",
        is_id_field: false,
        field_type: "string", 
        label: "hostFormFieldLabel"
    },
    {
        field_key: "availability_zone",
        is_id_field: false,
        field_type: "string", 
        label: "availabilityZoneFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const volumesSortFields = [
    {
        field_key: "name",
        label: "nameFormFieldLabel"
    },
    {
        field_key: "status",
        label: "statusFormFieldLabel"
    },
    {
        field_key: "size",
        label: "sizeFormFieldLabel"
    }
]

export const volumeDataForm = [
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "size",
        field_type: "integer",
        required: true,
        label: "sizeFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "source",
        field_type: "select",
        required: true,
        label: "sourceFormFieldLabel",
        self_item_titles: false,
        default_empty: false,
        non_openstack_field: true,
        items: [
            {
                keyword: "blankVolSourceChoiceTitle",
                value: "blank",
                default: true,
                min_api_version: null
            },
            {
                keyword: "volumeSourceChoiceTitle",
                value: "volume",
                default: false,
                min_api_version: null
            },
            {
                keyword: "imageVolSourceChoiceTitle",
                value: "image",
                default: false,
                min_api_version: null
            },
            {
                keyword: "snapshotVolSourceChoiceTitle",
                value: "snapshot",
                default: false,
                min_api_version: null
            },
            {
                keyword: "backupVolSourceChoiceTitle",
                value: "backup",
                default: false,
                min_api_version: "3.47"
            }
        ]
    },
    {
        field_key: "source_volid",
        field_type: "select",
        required: true,
        label: "volumeFormFieldLabel",
        error_label: "requiredFormFieldError",
        self_item_titles: true,
        default_empty: true
    },
    {
        field_key: "imageRef",
        field_type: "select",
        required: true,
        label: "imageFormFieldLabel",
        error_label: "requiredFormFieldError",
        self_item_titles: true,
        default_empty: true
    },
    {
        field_key: "snapshot_id",
        field_type: "select",
        required: true,
        label: "snapshotFormFieldLabel",
        error_label: "requiredFormFieldError",
        self_item_titles: true,
        default_empty: true
    },
    {
        field_key: "backup_id",
        field_type: "select",
        required: true,
        label: "backupFormFieldLabel",
        error_label: "requiredFormFieldError",
        self_item_titles: true,
        default_empty: true
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    },
    {
        field_key: "volume_type",
        field_type: "select",
        required: false,
        label: "volumeTypeFormFieldLabel",
        self_item_titles: true,
        default_empty: true
    },
    {
        field_key: "availability_zone",
        field_type: "select",
        required: false,
        label: "availabilityZoneFormFieldLabel",
        self_item_titles: true,
        default_empty: true
    },
    {
        field_key: "group_id",
        field_type: "select",
        required: false,
        label: "groupFormFieldLabel",
        self_item_titles: true,
        default_empty: true,
        min_api_version: "3.13"
    }
]

export const volumesFilterMenu = [
    {
        keyword: "statusFormFieldLabel",
        value: "status",
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "volumeStatusChoiceAvailable", 
                value: "available", 
                default: true
            },
            {
                keyword: "volumeStatusChoiceInUse", 
                value: "in-use", 
                default: false
            },
            {
                keyword: "volumeStatusChoiceError", 
                value: "error", 
                default: false
            },
            {
                keyword: "volumeStatusChoiceCreating", 
                value: "creating", 
                default: false
            },
            {
                keyword: "volumeStatusChoiceExtending", 
                value: "extending", 
                default: false
            }
        ]
    },
    {
        keyword: "projectFormFieldLabel",
        value: "project_id",
        type: "select", 
        multiple: false,
        self_item_titles: true,
        items: []
    },
    {
        keyword: "volumeTypeFormFieldLabel",
        value: "volume_type",
        type: "select", 
        multiple: false,
        self_item_titles: true,
        items: []
    }
]

export const volumeTransfersSchema = [
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "volume_id",
        is_id_field: false,
        field_type: "string", 
        label: "volumeFormFieldLabel"
    },
    {
        field_key: "source_project_id",
        is_id_field: false,
        field_type: "string", 
        label: "sourceProjectFormFieldLabel",
        min_api_version: "3.57"
    },
    {
        field_key: "destination_project_id",
        is_id_field: false,
        field_type: "string", 
        label: "destinationProjectFormFieldLabel",
        min_api_version: "3.57"
    },
    {
        field_key: "accepted",
        is_id_field: false,
        field_type: "bool", 
        label: "acceptedFormFieldLabel",
        min_api_version: "3.57",
        value_on_true: "formValueYes",
        value_on_false: "formValueNo"
    }
]

export const volumeUpdateForm = [
    {
        field_key: "name",
        field_type: "string", 
        required: false, 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "description",
        field_type: "string", 
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    }
]

export const volumeStatusList = [
    {
        keyword: "availableStatusFormFieldLabel",
        value: "available",
        default: false
    },
    {
        keyword: "inuseStatusFormFieldLabel",
        value: "in-use",
        default: false
    },
    {
        keyword: "errorStatusFormFieldLabel",
        value: "error",
        default: false
    },
    {
        keyword: "creatingStatusFormFieldLabel",
        value: "creating",
        default: false
    },
    {
        keyword: "attachingStatusFormFieldLabel",
        value: "attaching",
        default: false
    },
    {
        keyword: "detachingStatusFormFieldLabel",
        value: "detaching",
        default: false
    },
    {
        keyword: "deletingStatusFormFieldLabel",
        value: "deleting",
        default: false
    },
    {
        keyword: "errorDeletingStatusFormFieldLabel",
        value: "error_deleting",
        default: false
    },
    {
        keyword: "maintenanceStatusFormFieldLabel",
        value: "maintenance",
        default: false
    },
    {
        keyword: "reservedStatusFormFieldLabel",
        value: "reserved",
        default: false
    }
]

export const uploadImageDataForm = [
    {
        field_key: "image_name",
        field_type: "string", 
        required: true, 
        label: "nameFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "disk_format",
        field_type: "select", 
        required: false, 
        label: "diskFormatFormFieldLabel", 
        default_empty: false,
        default_value: "raw",
        self_item_titles: false,
        items: [
            {
                keyword: "rawContainerFormatTitle",
                value: "raw",
                default: true
            },
            {
                keyword: "qcow2ContainerFormatTitle",
                value: "qcow2",
                default: false
            },
            {
                keyword: "vhdContainerFormatTitle",
                value: "vhd",
                default: false
            },
            {
                keyword: "vhdxContainerFormatTitle",
                value: "vhdx",
                default: false
            },
            {
                keyword: "vmdkContainerFormatTitle",
                value: "vmdk",
                default: false
            },
            {
                keyword: "vdiContainerFormatTitle",
                value: "vdi",
                default: false
            }
        ]
    }
]