import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../../../../_common/CustomTable';
import { availabilityZoneDataSchema } from 
'../../../../../../_data/openstack/compute/infrastructure/v2.1';
import { setAvailabilityZonesTableConfig } from 
'../../../../../../store/reducers/computeNovaSlice';

const AvailabilityZonesTableV21 = (props) => {
    const { azData, setAzData } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);
    
    const availabilityZonesTableConfig = useSelector(
        state => state.computeNova.availabilityZonesTableConfig)
    const rowsPerPage = useSelector(
        state => state.computeNova.availabilityZonesTableConfig.filter(
        versions => versions.api_version === "v2.1")[0].rows_per_page)
    const availabilityZonesTableHeaders = useSelector(
        state => state.computeNova.availabilityZonesTableConfig.filter(
            versions => versions.api_version === "v2.1")[0].columns)

    
    const identityField = availabilityZonesTableHeaders.filter(
        item => item.is_id_field)[0].field_key
    
    
    const dispatch = useDispatch();

    const handleAvailabilityZoneTableConfig = (value_list) => {
        const newTableConfig = availabilityZoneDataSchema.filter(
            n => value_list.includes(n.field_key))
        const new_data = availabilityZonesTableConfig.map(version => {
            if (version.api_version === "v2.1") {
                let new_item = {...version}
                new_item.columns = [...newTableConfig]
                return new_item
            } else {
                return version
            }
        })
        dispatch(setAvailabilityZonesTableConfig({
            availabilityZonesTableConfig: new_data
        }))
    }
    
    const handleRowsPerPageChange = (rows_num) => {
        const new_data = availabilityZonesTableConfig.map(version => {
            if (version.api_version === "v2.1") {
                let new_item = {...version}
                new_item.rows_per_page = rows_num
                return new_item
            } else {
                return version
            }
        })
        dispatch(setAvailabilityZonesTableConfig({
            availabilityZonesTableConfig: new_data
        }))
    }

    return (
        <React.Fragment>
            <CustomTable
                withPagination={true}
                defaultDataTexts={{...defaultTexts}} 
                tableHeaders={availabilityZonesTableHeaders}
                tableHeadersConfigHandler={handleAvailabilityZoneTableConfig}
                identityField={identityField}
                columns={[...availabilityZoneDataSchema]}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                dataRows={azData}
                setDataRows={setAzData}
                handleRowSelection={()=>{}}
            />
        </React.Fragment>
    )
};

export default AvailabilityZonesTableV21;