import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CustomDialog from '../../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, 
        networkNeutronConstants, 
        identityKeystonConstants
} from '../../../../../../config/openStackConstants';
import VPNServicesSubheaderV20 from './vpnServicesSubheaderV2.0';
import VPNServicesTableV20 from './vpnServicesTableV2.0';
import { vpnServicesFilterMenu, vpnServiceDataForm } 
from '../../../../../../_data/openstack/neutron/vpn/v2.0';
import { 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../../_network/openstack_request';
import { vpnUrl as vpnUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/vpn/v2.0';
import { projectsUrl as projectUrlResponses} from 
'../../../../../../_api_responses/openstack/identity/projects/v3';
import FormGroup from '@mui/material/FormGroup';
import { getFormFieldComponent } from 
'../../../../../_common/_form_fields/form_helpers';

const SERVICE_NAME = openStackServices.networkService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const VPNServicesWrapperV20 = (props) => {
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const {currentTab, setCurrentTab} = props
    const [vpnServicesData, setVPNServicesData] = useState([])
    const [vpnServices, setVPNServices] = useState([])
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [currentAction, setCurrentAction] = useState("");
    const [vpnServiceDeleteConfirmDialogOpen, setVPNServiceDeleteConfirmDialogOpen] = useState(false);
    const [selectedVPNServices, setSelectedVPNServices] = useState([])
    
    const [vpnServiceFilterQueryParams, setVPNServiceFilterQueryParams] = useState("")
    const [selectedVPNServiceFilter, setSelectedVPNServiceFilter] = useState(vpnServicesFilterMenu[0].value)
    const [selectedVPNServiceFilterValue, setSelectedVPNServiceFilterValue] = useState("")
    const [vpnServicesFilter, setVPNServicesFilter] = useState([...vpnServicesFilterMenu])
    const [routers, setRouters] = useState([]);
    const [subnets, setSubnets] = useState([]);
    const [sharedSubnets, setSharedSubnets] = useState([]);

    const [projects, setProjects] = useState([])
    const [updateDataOptions, setUpdateDataOptions] = useState({});
    const [updateFormData, setUpdateFormData] = useState({});
    const [vpnServiceUpdateDialogOpen, setVPNServiceUpdateDialogOpen] = useState(false)
    const [selectedFieldKey, setSelectedFieldKey] = useState("")
    const [selectedFieldTitleText, setSelectedFieldTitleText] = useState("")

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const vpnServicesUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.vpnServicesUrl)[0].url)
    const routersUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.routersUrl)[0].url)
    const subnetsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.subnetsUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleTabChange = (tab_name) => {
        setCurrentTab(tab_name)
    }

    const handleVPNServiceFilteredSearch = () => {
        if (selectedVPNServiceFilter && selectedVPNServiceFilterValue) {
            setVPNServiceFilterQueryParams(`${selectedVPNServiceFilter}=${selectedVPNServiceFilterValue}`)
        } else {
            setVPNServiceFilterQueryParams("")
        }
        handleDataFetch()
    }

    const handleVPNServicesDataFormatting = useCallback(() => {
        const formatted_data = vpnServicesData.map((item) => {
            let new_item = {...item}
            const project = projects.filter(p => p.id === item.project_id)
            if (project.length > 0) {
                new_item.project_id = project[0].name
            } else {
                new_item.project_id = "-"
            }
            const router = routers.filter(r => r.id === item.router_id)
            if (router.length > 0) {
                new_item.router_id = router[0].name
            } else {
                new_item.router_id = "-"
            }
            return new_item
        })
        setVPNServices(formatted_data)
    },[
        vpnServicesData,
        projects,
        routers
    ])

    const handleVPNServiceFilterReset = () => {
        setSelectedVPNServiceFilter(vpnServicesFilterMenu[0].value)
        setSelectedVPNServiceFilterValue("")
        setVPNServiceFilterQueryParams("")
        handleDataFetch()
    }


    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleFormDataChange = (event,field_key) => {
        setUpdateDataOptions({})
        let new_form_data = {...updateFormData}
        if (vpnServiceDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (vpnServiceDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setUpdateFormData(new_form_data)
    }

    const getDataForm = () => {
        let form = vpnServiceDataForm.filter(i => i.field_key === selectedFieldKey)
        return (
            <FormGroup>
                {form.map(field => {
                    let form_field_options = {...updateDataOptions[field.field_key]}
                    form_field_options = {...form_field_options, ...field}
                    delete form_field_options["label"]
                    form_field_options["item_titles"] = defaultTexts
                    return (
                        getFormFieldComponent(
                            field,
                            updateFormData,
                            handleFormDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleUpdateDataValidation = (field_key, data) => {
        if (data[field_key].length === 0) {
            let options = {}
            options[field_key] = {}
            options[field_key]["error"] = true
            options[field_key]["errorText"] = defaultTexts.requiredFormFieldError
            setUpdateDataOptions(options)
            return false
        } else {
            return true
        }
    }

    const handleVPNServiceUpdate = async (eg_id,field_key,data) => {
        const data_is_valid = handleUpdateDataValidation(field_key,data)
        let update_data = {}
        update_data[field_key] = data[field_key]
        if (data_is_valid) {
            handleVPNServiceUpdateDialogClose()
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const method = "PUT"
                const vpnGroup_response = await openstackRequest({
                    url: `${neutronServiceDomain}/${neutronServiceVersion}/${vpnServicesUrl}/${eg_id}`, 
                    method: method, 
                    data: {vpnservice: update_data},
                    token: project_token
                })
                if (vpnGroup_response.status_code === vpnUrlResponses.put.success_response.status_code) {
                    return null
                } else {
                    return vpnGroup_response.error
                }
            }
        } 
    }

    const onVPNServiceUpdate = async () => {
        let err = []
        for (let n in selectedVPNServices) {
            const resp = await handleVPNServiceUpdate(
                selectedVPNServices[n].id,
                selectedFieldKey,
                updateFormData)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDataFetch()
        setUpdateDataOptions({})
        setUpdateFormData({})
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onVPNServiceUpdateNameDialogOpen = useCallback((n_list) => {
        const selected_n_list = vpnServicesData.filter(n => 
            n_list.includes(n.id))
        setSelectedVPNServices([...selected_n_list])
        setSelectedFieldKey("name")
        setSelectedFieldTitleText(defaultTexts.updateVPNServiceNameTitle)
        setVPNServiceUpdateDialogOpen(true)
    },[vpnServicesData, defaultTexts])
    
    const onVPNServiceUpdateDescriptionDialogOpen = useCallback((n_list) => {
        const selected_n_list = vpnServicesData.filter(n => 
            n_list.includes(n.id))
            setSelectedVPNServices([...selected_n_list])
        setSelectedFieldKey("description")
        setSelectedFieldTitleText(defaultTexts.updateVPNServiceDescriptionTitle)
        setVPNServiceUpdateDialogOpen(true)
    },[vpnServicesData, defaultTexts])

    const onVPNServiceUpdateAdminUpDialogOpen = useCallback((n_list) => {
        const selected_n_list = vpnServicesData.filter(n => 
            n_list.includes(n.id))
            setSelectedVPNServices([...selected_n_list])
        setSelectedFieldKey("admin_state_up")
        setSelectedFieldTitleText(defaultTexts.updateVPNServiceAdminUpTitle)
        setVPNServiceUpdateDialogOpen(true)
    },[vpnServicesData, defaultTexts])
	
	const handleVPNServiceUpdateDialogClose = () => {
        setVPNServiceUpdateDialogOpen(false)
    }

    const handleVPNServiceDelete = async (n_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${vpnServicesUrl}/${n_id}`
            const method = "DELETE"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                token: project_token
            })

            if (nt_response.status_code === vpnUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    };

    const onVPNServiceDelete = async () => {
        handleVPNServiceDeleteConfirmDialogClose()
        let err = []
        for (let n in selectedVPNServices) {
            const resp = await handleVPNServiceDelete(selectedVPNServices[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }


    const onVPNServiceDeleteConfirm = useCallback((n_list) => {
        const selected_n_list = vpnServicesData.filter(n => 
            n_list.includes(n.id))
        setSelectedVPNServices([...selected_n_list])
        setVPNServiceDeleteConfirmDialogOpen(true)
    },[vpnServicesData])

    const handleVPNServiceDeleteConfirmDialogClose = () => {
        setVPNServiceDeleteConfirmDialogOpen(false)
    }

    const getVPNServicesActionsList = useCallback(() => {
        let vpnService_actions = []
        let new_action = {}
        new_action["value"] = "vpn_service_update_name"
        new_action["action"] = onVPNServiceUpdateNameDialogOpen
        new_action["keyword"] = "vpnServiceUpdateNameActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        vpnService_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "vpn_service_update_description"
        new_action["action"] = onVPNServiceUpdateDescriptionDialogOpen
        new_action["keyword"] = "vpnServiceUpdateDescriptionActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        vpnService_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "vpn_service_update_admin_up"
        new_action["action"] = onVPNServiceUpdateAdminUpDialogOpen
        new_action["keyword"] = "vpnServiceUpdateAdminUpActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        vpnService_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "vpnService_delete"
        new_action["action"] = onVPNServiceDeleteConfirm
        new_action["keyword"] = "vpnServiceDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        vpnService_actions.push({...new_action})
        
        return vpnService_actions
    },[
        onVPNServiceDeleteConfirm,
        onVPNServiceUpdateNameDialogOpen,
        onVPNServiceUpdateDescriptionDialogOpen,
        onVPNServiceUpdateAdminUpDialogOpen
    ])

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${neutronServiceDomain}/${neutronServiceVersion}/${vpnServicesUrl}?${vpnServiceFilterQueryParams}`
                    const method = "GET"
                    const vpnService_response = await openstackRequest({url:url, method:method, token: project_token})
                    if (vpnService_response.status_code === vpnUrlResponses.get.success_response.status_code) {
                        setVPNServicesData(vpnService_response.data.vpnservices)
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        vpnServicesUrl, 
        vpnServiceFilterQueryParams,
        dataFetchingRequired,
        defaultAdminProject
    ]);


    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${neutronServiceDomain}/${neutronServiceVersion}/${routersUrl}`
                const method = "GET"
                const routers_response = await openstackRequest({url:url, method:method, token: project_token})
                if (routers_response.status_code === vpnUrlResponses.get.success_response.status_code) {
                    setRouters(routers_response.data.routers)
                }
            }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        routersUrl, 
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${neutronServiceDomain}/${neutronServiceVersion}/${subnetsUrl}`
                const method = "GET"
                const subnets_response = await openstackRequest({url:url, method:method, token: project_token})
                if (subnets_response.status_code === vpnUrlResponses.get.success_response.status_code) {
                    setSubnets(subnets_response.data.subnets)
                }
                url = `${neutronServiceDomain}/${neutronServiceVersion}/${subnetsUrl}?shared=true`
                const shared_response = await openstackRequest({url:url, method:method, token: project_token})
                if (shared_response.status_code === vpnUrlResponses.get.success_response.status_code) {
                    setSharedSubnets(shared_response.data.subnets)
                }
            }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        subnetsUrl, 
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === projectUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        if (vpnServicesData.length > 0) {
            handleVPNServicesDataFormatting()
        }
    },[
        vpnServicesData,
        handleVPNServicesDataFormatting
    ])

    useEffect(() => {
        let projects_filter = []
        if (projects.length > 0) {
            projects_filter = projects.map(project => {
                return {keyword: project.name, value: project.id, default: false}
            })
        }
        let vpnService_filter_menu = vpnServicesFilterMenu.map(nt => {
            let new_item = {...nt}
            if (nt.value === "project_id") {
                new_item.items = [...projects_filter]
            }
            return new_item
        })

        setVPNServicesFilter(vpnService_filter_menu)
    },[
        vpnServicesData,
        projects
    ])

    useEffect(() => {
        if (Object.keys(updateFormData).length === 0) {
            let new_form_data = {}
            for (const n in vpnServiceDataForm) {
                if (
                    vpnServiceDataForm[n].field_type === "string" || 
                    vpnServiceDataForm[n].field_type === "select"
                    ) {
                    new_form_data[vpnServiceDataForm[n].field_key] = vpnServiceDataForm[n].default_value ? 
                    vpnServiceDataForm[n].default_value : 
                    ""
                } else if (vpnServiceDataForm[n].field_type === "integer") {
                    new_form_data[vpnServiceDataForm[n].field_key] = 3600
                } else if (vpnServiceDataForm[n].field_type === "bool") {
                    new_form_data[vpnServiceDataForm[n].field_key] = vpnServiceDataForm[n].default_value ? 
                    vpnServiceDataForm[n].default_value : 
                    false
                }
            }
            setUpdateFormData(new_form_data)
        }
    },[
        updateFormData
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <VPNServicesSubheaderV20 
                selectedFilter={selectedVPNServiceFilter} 
                setSelectedFilter={setSelectedVPNServiceFilter}
                selectedFilterValue={selectedVPNServiceFilterValue}
                setSelectedFilterValue={setSelectedVPNServiceFilterValue}
                handleDataFetch={handleDataFetch}
                filterMenu={vpnServicesFilter}
                handleFilteredSearch={handleVPNServiceFilteredSearch}
                handleFilterReset={handleVPNServiceFilterReset}
                currentTab={currentTab}
                handleTabChange={handleTabChange}
                projects={projects}
                routers={routers}
                subnets={subnets}
                sharedSubnets={sharedSubnets}
            />
        {isLoading && <CustomBackdrop open={isLoading} />}
        {!isLoading &&
            <VPNServicesTableV20 
                vpnServicesData={vpnServices}
                setVPNServicesData={setVPNServices}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getVPNServicesActionsList()}
                projects={projects}
            />
        }
        <CustomDialog
            open={vpnServiceDeleteConfirmDialogOpen}
            onClose={handleVPNServiceDeleteConfirmDialogClose}
            dialogTitle={{
                title: defaultTexts.vpnServiceDeleteConfirmTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `${defaultTexts.vpnServiceDeleteConfirmText}`, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onVPNServiceDelete, 
                sx: {color: 'primary.main'}}]}
        />
        <CustomDialog
            open={vpnServiceUpdateDialogOpen}
            onClose={handleVPNServiceUpdateDialogClose}
            dialogTitle={{
                title: selectedFieldTitleText, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onVPNServiceUpdate, 
                sx: {color: 'primary.main'}}]}
        >
            {getDataForm()}
        </CustomDialog>
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts[error.error_title], 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts[error.error_message]}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
    </Box>
    )
};

export default VPNServicesWrapperV20;