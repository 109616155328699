import React, { useEffect, useState } from 'react';
import Constants from '../../../config/constants';
import billingRequest from '../../../_network/billing_request';
import { billingServiceURLs } from '../../../_network/apiUrls';
import BillingServicesTable from './billingServicesTable';
 
const ServiceConfigsContent = () => {
    const [billingServices, setBillingServices] = useState([]);
    const [billingServiceList, setBillingServiceList] = useState([])
    const [fetchDataRequired, setFetchDataRequired] = useState(true);

    const handleDataFetch = () => {
        setFetchDataRequired(true)
    }

    useEffect(() => {
        if (fetchDataRequired) {
            const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingServicesEP}`
            let request_data = {
                url: url,
                method: "GET"
            }
            billingRequest(request_data).then((response) => {
                if (response.status_code === 200) {
                    const service_list = Object.groupBy(response.data, ({service_name}) => service_name)
                    const billing_services = Object.keys(service_list).map(key => {
                        return {name: key, regions: service_list[key]}
                    })
                    setBillingServices(billing_services)
                    setBillingServiceList(response.data)
                }
            }).catch((err) => {
                console.log(err)
            })
            setFetchDataRequired(false)
        }
    },[fetchDataRequired]);

    return (
        <BillingServicesTable 
            dataRows={billingServices}
            data={billingServiceList}
            handleDataFetch={handleDataFetch}
        />
    )
}

export default ServiceConfigsContent;