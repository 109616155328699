import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import CustomCard from '../../../_common/CustomCard';
import Box from '@mui/material/Box';
import { BarChart } from '@mui/x-charts/BarChart';
import Dimensions from '../../../../config/dimensions';
import useWindowDimensions from '../../../_common/WindowDimensions';
import Skeleton from '@mui/material/Skeleton';
import MuiStack from '@mui/material/Stack';
import { 
    computeNovaRequest, 
    getXAuthTokenProjectScope } from 
'../../../../_network/openstack_request';
import CustomText from '../../../_common/CustomText';
import Typography from '@mui/material/Typography';
import { infrastructureUrl as infrastructureUrlResponses } from 
'../../../../_api_responses/openstack/compute/infrastructure/v2.1';
import { 
    computeNovaConstants,
    openStackServices
} from '../../../../config/openStackConstants';
import Constants from '../../../../config/constants';


const SERVICE_NAME = openStackServices.computeService

const drawerWidth = Constants.drawerWidth;

const HypervisorsGraphContentV21 = (props) => {
    const open = useSelector(state => state.drawer.drawerOpened);
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const { width } = useWindowDimensions();
    
    const [hypervisorsLoading, setHypervisorsLoading] = useState(true)
    const [hypervisorsData, setHypervisorsData] = useState([]);

    const navigate = useNavigate();
    const theme = useTheme();

    if (!isAuthenticated) {
        navigate('/');
    }

    const getCardHeight = (width) => {
        if (width - (open ? drawerWidth : 110) < Dimensions.tablet_mini.width) {
            return 600
        } else if (((width - (open ? drawerWidth : 110)) / 2) < 600) {
            return 600
        } else {
            return 400
        }
    }

    const CARD_HEIGHT = getCardHeight(width)

    const computeServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const computeServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const hypervisorsUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === computeServiceVersion)[0].urls.filter(
                url => url.keyword === computeNovaConstants.hypervisorsUrl)[0].url)

    const handleHypervisorsLoading = (mode) => {
        setHypervisorsLoading(mode)
    }

    const purchasedServices = useSelector(state => 
        state.openstack.purchasedServices);

    const purchasedService = purchasedServices.filter(
        item => item.service === SERVICE_NAME)

    const DashboardCard = (props) => {
        const { cardTitle } = props
        return (
            <CustomCard
                cardWidth={'100%'} 
                cardHeight={CARD_HEIGHT}
                cardTitle={cardTitle}
                titleSize="p"
                titleColor="primary.main"
                card_sx={{
                    border: theme.palette.mode === "dark" ? 0 : 1, 
                    boxShadow: theme.palette.mode === "dark" ? 0 : 1, 
                    backgroundColor: 
                        theme.palette.mode === "dark" ? 
                        undefined :
                        "vLightGray"
                }}
                box_sx={{pr: 2}}
            >
                {props.children}
            </CustomCard>
        )
    }

    const CustomBarChart = (props) => {
        const { data } = props
        const { colors } = props
        const { orientation } = props
        let xAxis = undefined
        let yAxis = undefined
        let series = []
        if (data.aData) {
            series = [{data: data.aData.values, label: data.aData.label, stack: 'total'}]
        }
        if (data.bData) {
            series.push({data: data.bData.values, label: data.bData.label, stack: 'total'})
        }
        if (orientation === "horizontal") {
            yAxis = [{ data: data.units, scaleType: 'band' }]
        } else {
            xAxis = [{ data: data.units, scaleType: 'band' }]
        }
        return (
            <Box sx={{
                height: CARD_HEIGHT > 400 ? 400 : 300, 
                alignItems: "center", 
                justifyContent: "center"}}
            >
                {data.aData && data.aData.values.length > 0 && <BarChart
                    slotProps={{ 
                        legend: {
                            position: { vertical: "top"} 
                    }}}
                    series={series}
                    xAxis={xAxis}
                    yAxis={yAxis}
                    colors={colors}
                    borderRadius={10}
                    layout={orientation ? orientation : "vertical"}
                />}
                {data.length === 0 && 
                    <Box sx={{
                        width: "100%", 
                        height: "100%",
                        display: "flex", 
                        alignItems: "center", 
                        justifyContent: "center",
                        mt: 1
                    }}>
                        <Box
                            sx={{
                                width: "80%",
                                height: "80%",
                                border: "1px dashed",
                                borderRadius: 2,
                                borderColor: "customBlue",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                        <CustomText sx={{fontSize: 20, color: "customBlue"}}>
                            {defaultTexts.noDataNoteTitle}
                        </CustomText>
                        </Box>
                    </Box>
                }
            </Box>
        );
    }

    const CustomSkeleton = () => {
        return (
            <Grid container spacing={2} 
                justifyContent="space-around" 
                alignItems="center" 
                sx={{mt: 3}}
            >
                <Grid item>
                    <Skeleton height={220} width={220} variant="circular" />
                </Grid>
                <Grid item>
                    <MuiStack>
                        <Skeleton width={220} height={40}>
                            <Typography>.</Typography>
                        </Skeleton>
                        <Skeleton width={220} height={40}>
                            <Typography>.</Typography>
                        </Skeleton>
                        <Skeleton width={220} height={40}>
                            <Typography>.</Typography>
                        </Skeleton>
                        <Skeleton width={220} height={40}>
                            <Typography>.</Typography>
                        </Skeleton>
                    </MuiStack>
                </Grid>
            </Grid>
        )
    }

    useEffect(() => {
        (async () => {
            handleHypervisorsLoading(true)
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${computeServiceDomain}/${computeServiceVersion}/${hypervisorsUrl}/detail?with_servers=true`
                const method = "GET"
                const hypervisors_response = await computeNovaRequest({url:url, method:method, token: project_token})
                if (hypervisors_response.status_code === infrastructureUrlResponses.get.success_response.status_code) {
                    setHypervisorsData(hypervisors_response.data.hypervisors)
                }
            }
        })();
        setTimeout(()=>{handleHypervisorsLoading(false)},700)
    },[
        computeServiceDomain, 
        computeServiceVersion, 
        hypervisorsUrl, 
        defaultAdminProject
    ]);

    if (purchasedService[0].config_params.api_version === "v2.1") {
        return (
            <DashboardCard>
                <CustomText 
                    sx={{
                        color: "primary.main", 
                        fontSize: 20
                    }}>
                        {defaultTexts.hypervisorsSummaryCardTitle}
                </CustomText>
                {!hypervisorsLoading ? 
                    <CustomBarChart 
                        data={hypervisorsData.length > 0 ? {
                            aData: {
                                values: hypervisorsData.map(item => Object.keys(item).includes("servers") ? item.servers.length : 0),
                                label: `${defaultTexts.serversNumberPieChartLabel}`
                            },
                            units: hypervisorsData.map(item => item.hypervisor_hostname)
                        } : {aData: {
                                values: [],
                                label: defaultTexts.serversNumberPieChartLabel
                            }
                        }}
                    /> : 
                    <CustomSkeleton />
                }
            </DashboardCard>
        )
    }
};

export default HypervisorsGraphContentV21;