import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import WrapperBox from "./WrapperBox";
import CustomText from "./CustomText";

const Loading = (props) => {
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [typedText, setTypedText] = useState("")
    const interval = 100
    const text_to_print = defaultTexts.loadingHeader

    const handleTextTyping = (text,updater,interval) => {
        let localTypingIndex = 0
        let localTyping = ""
        if (text) {
            let printer = setInterval(() => {
                if (localTypingIndex < text.length) {
                    updater((localTyping += text[localTypingIndex]));
                    localTypingIndex += 1;
                } else {
                    localTypingIndex = 0;
                    localTyping = "";
                    clearInterval(printer);
                }
            }, interval);
        }
    }
    useEffect(() => {
        handleTextTyping(text_to_print,setTypedText,interval)
    },[
        interval,
        text_to_print
    ])
    return (
        <WrapperBox sx={{height: "100vh", alignItems: "center", justifyContent: "center"}}>
            <CustomText size="h1" sx={{color: "customBlue"}}>
                {typedText}
            </CustomText>
        </WrapperBox>
    )
};

export default Loading