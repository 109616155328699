import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Constants from '../../config/constants';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import CustomTextField from './_form_fields/CustomTextField';
import CustomSelectField from './_form_fields/CustomSelectField';
import Button from '@mui/material/Button';
import { RxReset } from "react-icons/rx";
import { IoSearch } from "react-icons/io5";
import PopupState, 
{ bindTrigger, 
    bindMenu } 
from 'material-ui-popup-state';
import { Box } from '@mui/material';
import CustomDialog from './CustomDialog';
import { getFormFieldComponent } from './_form_fields/form_helpers';
import Grid from "@mui/material/Grid"

const FilterButton = (props) => {
    const { choice_mode } = props;
    const { currentFilter, setCurrentFilter } = props;
    const { currentFilterValue, setCurrentFilterValue } = props;
    const { onFilterSubmit } = props
    const { onFilterReset } = props
    const { filter_menu_titles } = props;
    const { filter_menu } = props;
    const { color } = props;
    const { size } = props;
    const { sx } = props;

    const [complexFilterOpen, setComplexFilterOpen] = useState(false);
    const [multifieldFilterOpen, setMultifieldFilterOpen] = useState(false);

    const defaultTexts = useSelector(state => state.texts.langTexts)

    const theme = useTheme();

    const handleSearchClick = () => {
        onFilterSubmit();
        if (filter_menu.filter(item => item.value === currentFilter)[0].type === "multifield") {
            handleMultifieldFilterClose()
        }
    }

    const handleFilterReset = () => {
        onFilterReset();
    }

    const handleCurrentFilterValueChange = (event) => {
        setCurrentFilterValue(event.target.value)
    }

    const handleFilterChange = (event) => {
        if (choice_mode === Constants.simple_filter_type) {
            if (filter_menu.filter(item => item.value === event.target.value)[0].type === "select" &&
            filter_menu.filter(item => item.value === event.target.value)[0].multiple
            ) {
                setCurrentFilterValue([])
            } else {
                setCurrentFilterValue("")
            }
            setCurrentFilter(event.target.value)
        } else {
            if (currentFilter.includes(event.target.value)) {
                setCurrentFilterValue("")
                setCurrentFilter(currentFilter.filter(
                    item => item !== event.target.value))
            } else {
                setCurrentFilterValue("")
                setCurrentFilter([
                    ...currentFilter, 
                    event.target.value])
            }
        }
    }

    const handleComplexFilterOpen = () => {
        setComplexFilterOpen(true)
    }

    const handleComplexFilterClose = () => {
        setComplexFilterOpen(false)
    }

    const handleMultifieldFilterOpen = () => {
        setMultifieldFilterOpen(true)
    }

    const handleMultifieldFilterClose = () => {
        setMultifieldFilterOpen(false)
    }

    return (
        <Grid container alignItems="center" justifyContent="flex-end">
            {currentFilterValue && 
                <Tooltip title={defaultTexts.resetAllFiltersTooltipText}>
                    <IconButton onClick={handleFilterReset}>
                        <RxReset 
                            color={color ? color : theme.palette.text.primary}
                            fontSize={size ? size : 'large'}
                            sx={{...sx}}
                        />
                    </IconButton>
                </Tooltip>}
            <PopupState variant="popover" popupId="filter-menu">
                {(popupState) => (
                    <React.Fragment>
                        <IconButton {...bindTrigger(popupState)}>
                            <FilterListIcon 
                                color={color ? color : theme.palette.text.primary}
                                fontSize={size ? size : 'large'}
                                sx={{...sx}}
                            />
                        </IconButton>
                        <Menu {...bindMenu(popupState)}>
                            {choice_mode === Constants.simple_filter_type ? 
                            <RadioGroup
                                value={currentFilter}
                                onChange={(event) => {
                                    handleFilterChange(event); 
                                    popupState.close()
                                }}
                            >
                                {filter_menu.map(item => {
                                    return (
                                        <MenuItem 
                                            key={item.value}
                                        >
                                            <FormControlLabel 
                                                value={item.value}
                                                control={<Radio />} 
                                                componentsProps={{
                                                    typography: {
                                                        fontSize: 14,
                                                        color: theme.palette.text.primary,
                                                        textTransform: 'uppercase'
                                                    }
                                                }}
                                                label={filter_menu_titles[item.keyword]}
                                            />
                                        </MenuItem>
                                    )
                                })}
                            </RadioGroup>
                            :
                            <FormGroup>
                                {filter_menu.map(item => {
                                    return (
                                        <MenuItem 
                                            key={item.value}
                                        >
                                            <FormControlLabel 
                                                control={
                                                    <Checkbox 
                                                        checked={currentFilter.includes(item.value)}
                                                        value={item.value} 
                                                        onChange={handleFilterChange} 
                                                    />} 
                                                label={filter_menu_titles[item.keyword]}
                                                componentsProps={{
                                                    typography: {
                                                        fontSize: 14,
                                                        color: theme.palette.text.primary,
                                                        textTransform: 'uppercase'
                                                    }
                                                }}
                                            />
                                        </MenuItem>
                                    )
                                })}
                            </FormGroup>
                            }
                        </Menu>
                    </React.Fragment>
                )}
            </PopupState>
            {choice_mode === Constants.simple_filter_type && 
                <React.Fragment>
                    {filter_menu.filter(item => item.value === currentFilter)[0].type === "text" && 
                        <CustomTextField 
                            currentValue={currentFilterValue}
                            setCurrentValue={handleCurrentFilterValueChange}
                            label={filter_menu_titles[
                                filter_menu.filter(
                                    item => item.value === currentFilter)[0].keyword
                                ]}
                                sx={{ m: {xs: 0, sm: 1}, width: 250 }}
                                size="small"
                        />
                    }
                    {filter_menu.filter(item => item.value === currentFilter)[0].type === "integer" && 
                        <CustomTextField 
                            type="number"
                            size="small"
                            currentValue={currentFilterValue ? currentFilterValue : 1}
                            setCurrentValue={handleCurrentFilterValueChange}
                            label={filter_menu_titles[
                                filter_menu.filter(
                                    item => item.value === currentFilter)[0].keyword
                                ]}
                                sx={{ m: {xs: 0, sm: 1}, width: 250 }}
                        />
                    }
                    {filter_menu.filter(item => item.value === currentFilter)[0].type === "select" && 
                        <CustomSelectField 
                            currentValue={currentFilterValue}
                            setCurrentValue={setCurrentFilterValue}
                            label={filter_menu_titles[
                                filter_menu.filter(
                                    item => item.value === currentFilter)[0].keyword
                                ]}
                            items={filter_menu.filter(item => item.value === currentFilter)[0].items}
                            item_titles={filter_menu_titles}
                            multiple={filter_menu.filter(item => item.value === currentFilter)[0].multiple}
                            empty={filter_menu.filter(item => item.value === currentFilter)[0].default_empty}
                            self_item_titles={filter_menu.filter(item => item.value === currentFilter)[0].self_item_titles}
                            sx={{ m: {xs: 0, sm: 1}, width: 250 }}
                            size="small"
                        />
                    }
                    {filter_menu.filter(item => item.value === currentFilter)[0].type === "multifield" && 
                        <Box 
                            onClick={handleMultifieldFilterOpen}
                            sx={{
                                m: 1, 
                                width: 250, 
                                height: 53,
                                borderRadius: 1,
                                border: 0.5,
                                p:2,
                                color: theme.palette.text.secondary,
                                borderColor: theme.palette.text.secondary,
                                '&:hover': {
                                    cursor: 'pointer',
                                    borderColor: theme.palette.text.primary,
                                    color: theme.palette.text.primary
                                }
                            }}
                            >
                            {filter_menu_titles[filter_menu.filter(item => item.value === currentFilter)[0].keyword]}
                        </Box>
                    }
                    <Button 
                        variant="contained"
                        color="secondary"
                        size="large"
                        sx={{my: {xs: 1, sm: 0}, mx: {xs: 0, sm: 1}, height: 40, px: 5, width: {xs: 250, sm: 140}}}
                        onClick={handleSearchClick}
                        startIcon={<IoSearch />}
                    >
                        {defaultTexts.searchButtonText}
                    </Button>
                </React.Fragment>
            }
            {choice_mode === Constants.complex_filter_type &&
                <Box 
                    onClick={handleComplexFilterOpen}
                    sx={{
                        mx: 1, 
                        width: 225, 
                        height: 40,
                        borderRadius: 1,
                        border: 0.5,
                        p:1,
                        color: theme.palette.text.secondary,
                        borderColor: theme.palette.text.secondary,
                        '&:hover': {
                            cursor: 'pointer',
                            borderColor: theme.palette.text.primary,
                            color: theme.palette.text.primary
                        }
                    }}
                    >
                    {defaultTexts.filterOptionsButtonText}
                </Box>
            }
            <CustomDialog
                open={complexFilterOpen}
                onClose={handleComplexFilterClose}
                dialogTitle={{
                    title: defaultTexts.filterCustomOptionsDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.searchButtonText, 
                    onClick: ()=>{}, 
                    sx: {color: 'primary.main'}}]}
            />
            {multifieldFilterOpen && <CustomDialog
                open={multifieldFilterOpen}
                onClose={handleMultifieldFilterClose}
                dialogTitle={{
                    title: filter_menu_titles[filter_menu.filter(item => item.value === currentFilter)[0].keyword], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: filter_menu_titles[filter_menu.filter(item => item.value === currentFilter)[0].help_text], 
                    sx: {color: 'text.primary', mb:3}}}
                actionButtons={[{
                    title: defaultTexts.searchButtonText, 
                    onClick: handleSearchClick, 
                    sx: {color: 'primary.main'}}]}
            >
            {filter_menu.filter(item => item.value === currentFilter)[0].fields.map(field => {
                return (
                    getFormFieldComponent(
                        field,
                        currentFilterValue,
                        setCurrentFilterValue,
                        filter_menu_titles[field.keyword],
                        {
                            withHelp: field.with_help_text,
                            helpText: filter_menu_titles[field.help_text]
                        }
                    )
                )
            })}
            </CustomDialog>}
        </Grid>
    )
};

export default FilterButton;
