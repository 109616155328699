import { createSlice } from '@reduxjs/toolkit'
import { identityKeystoneTexts} from '../../lang/en/components/identity/texts';
import { computeNovaTexts } from '../../lang/en/components/compute/texts';
import { loginFormTexts } from '../../lang/en/components/home/texts';
import { imagesGlanceTexts } from '../../lang/en/components/images/texts';
import { networksNeutronTexts } from '../../lang/en/components/networks/texts';
import { volumesCinderTexts } from '../../lang/en/components/volumes/texts';
import {commonTexts} from '../../lang/en/components/common/texts';
import { backupsDRTexts } from '../../lang/en/components/backups/texts';
import { billingTexts } from '../../lang/en/components/billing/texts';

export const initialState = {
    notPurchasedServiceWidgetTexts: null,
    langTexts: {
      ...commonTexts,
      ...identityKeystoneTexts,
      ...computeNovaTexts,
      ...backupsDRTexts,
      ...loginFormTexts,
      ...imagesGlanceTexts,
      ...networksNeutronTexts,
      ...volumesCinderTexts,
      ...billingTexts
    },
}

const textsSlice = createSlice({
  name: 'texts',
  initialState,
  reducers: {
    setNotPurchasedServiceWidgetTexts: (state, action) => {
        state.notPurchasedServiceWidgetTexts = action.payload.notPurchasedServiceWidgetTexts
    },
    setLangTexts: (state, action) => {
      state.langTexts = action.payload.langTexts
    },
    textsReset: () => {
      return {...initialState}
    }
  }
})

export const { 
    setNotPurchasedServiceWidgetTexts,
    setLangTexts,
    textsReset
} = textsSlice.actions

export default textsSlice.reducer