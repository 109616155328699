import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import WrapperBox from '../../../../_common/WrapperBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOffIcon from '@mui/icons-material/EditOff';
import Paper from '@mui/material/Paper';
import useWindowDimensions from 
'../../../../_common/WindowDimensions';
import Constants from '../../../../../config/constants';
import { IconButton }  from '@mui/material';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { openstackRequest } from 
'../../../../../_network/openstack_request';
import { rolesUrl as rolesUrlResponses } from 
'../../../../../_api_responses/openstack/identity/roles/v3';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { identityKeystonConstants } from 
'../../../../../config/openStackConstants';
import CustomDialog from 
'../../../../_common/CustomDialog';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import { FormGroup } from '@mui/material';
import { 
    roleDataUpdateForm, 
    roleDataSchema,
    roleAssignToUserDataForm,
    roleAssignToGroupDataForm 
} from 
'../../../../../_data/openstack/identity/roles/v3';
import { getDetailDataComponent } from 
'../../../../_common/common_helpers';
import ServiceContentHeader from '../../../../_common/ServiceContentHeader';
import CustomSelectField from 
'../../../../_common/_form_fields/CustomSelectField';
import { sortObjectListAscending } from 
'../../../../../components/_common/common_helpers';

const SERVICE_NAME = openStackServices.identityService

const IdentityRoleDetailV3 = (props) => {
    const { selectedRow } = props
    const { rolesRecords } = props
    const { domains, users, groups } = props
    const roleRecord = rolesRecords.filter(role => role.id === selectedRow.id)[0]
    const { widthWeight } = props
    const { 
        handleFetchData, 
        handleDrawerClose
    } = props
    const { width } = useWindowDimensions();
    const [editMode, setEditMode] = useState(false);
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [error, setError] = useState()
    const [successRoleDelete, setSuccessRoleDelete] = useState()
    const [successRoleUpdate, setSuccessRoleUpdate] = useState()
    
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [successUpdateDialogOpen, setSuccessUpdateDialogOpen] = useState(false);
    
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

    const [updateFormData, setUpdateFormData] = useState(
        {...roleRecord}
    )

    const [roleDomain, setRoleDomain] = useState({})
    const [roleDomainProjects, setRoleDomainProjects] = useState([])
    
    const [roleActions, setRoleActions] = useState([]);
    const [currentAction, setCurrentAction] = useState("");

    const [roleSubMenu, setRoleSubMenu] = useState([
        {keyword: "submenuDetails", navigation: "/role-details", is_active: true}
    ])

    const [currentTab, setCurrentTab] = useState("/role-details")

    const [assignToUserMode, setAssignToUserMode] = useState(false);
    const [unassignToUserMode, setUnassignToUserMode] = useState(false);

    const [assignToGroupMode, setAssignToGroupMode] = useState(false);
    const [unassignToGroupMode, setUnassignToGroupMode] = useState(false);

    const [roleAssignToUserForm, setRoleAssignToUserForm] = useState({})
    const [roleAssignToUserFormOptions, setRoleAssignToUserFormOptions] = useState({});
    const [roleAssignToGroupForm, setRoleAssignToGroupForm] = useState({})
    const [roleAssignToGroupFormOptions, setRoleAssignToGroupFormOptions] = useState({});
    
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const rolesUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.rolesUrl)[0].url)
    const domainsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.domainsUrl)[0].url)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)


    const handleAddUserDialogClose = () => {
        setRoleAssignToUserForm({})
        setRoleAssignToUserFormOptions({})
        setAssignToUserMode(false)
    }

    const handleRemoveUserDialogClose = () => {
        setRoleAssignToUserForm({})
        setRoleAssignToUserFormOptions({})
        setUnassignToUserMode(false)
    }

    const handleAssignUserDialogOpen = useCallback(() => {
        setAssignToUserMode(true)
    },[])

    const handleUnassignUserDialogOpen = useCallback(() => {
        setUnassignToUserMode(true)
    },[])

    const handleAddGroupDialogClose = () => {
        setRoleAssignToGroupForm({})
        setRoleAssignToGroupFormOptions({})
        setAssignToGroupMode(false)
    }

    const handleRemoveGroupDialogClose = () => {
        setRoleAssignToGroupForm({})
        setRoleAssignToGroupFormOptions({})
        setUnassignToGroupMode(false)
    }

    const handleAssignGroupDialogOpen = useCallback(() => {
        setAssignToGroupMode(true)
    },[])

    const handleUnassignGroupDialogOpen = useCallback(() => {
        setUnassignToGroupMode(true)
    },[])

    const handleFormDataChange = (event,field_key) => {
        let new_form_data = {...updateFormData}
        if (roleDataUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (roleDataUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setUpdateFormData(new_form_data)
    }

    const getDataForm = () => {
        return (
            <FormGroup>
                {roleDataUpdateForm.map(field => 
                    getFormFieldComponent(
                        field,
                        updateFormData,
                        handleFormDataChange,
                        defaultTexts[field.label],
                        {size:"medium",
                        withHelp: field.with_help_text,
                        helpText: defaultTexts[field.help_text],
                        sx: {
                            my: 1, 
                            width: width * widthWeight * 0.8
                        }}
                    )
            )}
        </FormGroup>)
    }

    const handleRoleAssignmentToUserFormDataChange = (event,field_key) => {
        let new_form_data = {...roleAssignToUserForm}
        if (roleAssignToUserDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (roleAssignToUserDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setRoleAssignToUserForm(new_form_data)
    }

    const getRoleAssignmentToUserForm = () => {
        return (
            <FormGroup sx={{mt:3}}>
                {roleAssignToUserDataForm.map((field,index) => {
                    let form_field_options = {...roleAssignToUserFormOptions[field.field_key]}
                    if (field.field_key === "scope") {
                        if (selectedRow.domain_id) {
                            form_field_options["items"] = field.items.filter(item => item.value !== "system_scope")
                        } else {
                            form_field_options["items"] = field.items
                        }
                        form_field_options["empty"] = field.default_empty
                        form_field_options["self_item_titles"] = field.self_item_titles
                        form_field_options["item_titles"] = defaultTexts
                        return (
                            getFormFieldComponent(
                                field,
                                roleAssignToUserForm,
                                handleRoleAssignmentToUserFormDataChange,
                                defaultTexts[field.label],
                                {...form_field_options}
                            )
                        )
                    } else if (field.field_key === "domain_id" && roleAssignToUserForm["scope"] === "domain_scope") {
                        const domains_list = domains.map(domain => {
                            return {keyword: domain.name, value: domain.id, default: false}
                        })
                        const sorted_list = sortObjectListAscending(domains_list,"keyword")
                        form_field_options["items"] = [...sorted_list]
                        form_field_options["empty"] = field.default_empty
                        form_field_options["self_item_titles"] = field.self_item_titles

                        return (
                            getFormFieldComponent(
                                field,
                                roleAssignToUserForm,
                                handleRoleAssignmentToUserFormDataChange,
                                defaultTexts[field.label],
                                {...form_field_options}
                            )
                        )
                    } else if (field.field_key === "project_id" && roleAssignToUserForm["scope"] === "project_scope") {
                        form_field_options["items"] = [...roleDomainProjects]
                        form_field_options["empty"] = field.default_empty
                        form_field_options["self_item_titles"] = field.self_item_titles

                        return (
                            getFormFieldComponent(
                                field,
                                roleAssignToUserForm,
                                handleRoleAssignmentToUserFormDataChange,
                                defaultTexts[field.label],
                                {...form_field_options}
                            )
                        )
                    }
                    else if (field.field_key === "user_id") {
                        form_field_options["items"] = users
                        form_field_options["empty"] = field.default_empty
                        form_field_options["self_item_titles"] = field.self_item_titles

                        return (
                            getFormFieldComponent(
                                field,
                                roleAssignToUserForm,
                                handleRoleAssignmentToUserFormDataChange,
                                defaultTexts[field.label],
                                {...form_field_options}
                            )
                        )
                    } else {
                        return <React.Fragment key={`empty_${index}`}></React.Fragment>
                    }
                })}
            </FormGroup>
        )
    }

    const handleRoleAssignmentToGroupFormDataChange = (event,field_key) => {
        let new_form_data = {...roleAssignToGroupForm}
        if (roleAssignToGroupDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (roleAssignToGroupDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setRoleAssignToGroupForm(new_form_data)
    }

    const getRoleAssignmentToGroupForm = () => {
        return (
            <FormGroup sx={{mt:3}}>
                {roleAssignToGroupDataForm.map((field,index) => {
                    let form_field_options = {...roleAssignToGroupFormOptions[field.field_key]}
                    if (field.field_key === "scope") {
                        if (selectedRow.domain_id) {
                            form_field_options["items"] = field.items.filter(item => item.value !== "system_scope")
                        } else {
                            form_field_options["items"] = field.items
                        }
                        form_field_options["empty"] = field.default_empty
                        form_field_options["self_item_titles"] = field.self_item_titles
                        form_field_options["item_titles"] = defaultTexts
                        return (
                            getFormFieldComponent(
                                field,
                                roleAssignToGroupForm,
                                handleRoleAssignmentToGroupFormDataChange,
                                defaultTexts[field.label],
                                {...form_field_options}
                            )
                        )
                    } else if (field.field_key === "domain_id" && roleAssignToGroupForm["scope"] === "domain_scope") {
                        const domains_list = domains.map(domain => {
                            return {keyword: domain.name, value: domain.id, default: false}
                        })
                        const sorted_list = sortObjectListAscending(domains_list,"keyword")
                        form_field_options["items"] = [...sorted_list]
                        form_field_options["empty"] = field.default_empty
                        form_field_options["self_item_titles"] = field.self_item_titles

                        return (
                            getFormFieldComponent(
                                field,
                                roleAssignToGroupForm,
                                handleRoleAssignmentToGroupFormDataChange,
                                defaultTexts[field.label],
                                {...form_field_options}
                            )
                        )
                    } else if (field.field_key === "project_id" && roleAssignToGroupForm["scope"] === "project_scope") {
                        form_field_options["items"] = [...roleDomainProjects]
                        form_field_options["empty"] = field.default_empty
                        form_field_options["self_item_titles"] = field.self_item_titles

                        return (
                            getFormFieldComponent(
                                field,
                                roleAssignToGroupForm,
                                handleRoleAssignmentToGroupFormDataChange,
                                defaultTexts[field.label],
                                {...form_field_options}
                            )
                        )
                    }
                    else if (field.field_key === "group_id") {
                        form_field_options["items"] = groups
                        form_field_options["empty"] = field.default_empty
                        form_field_options["self_item_titles"] = field.self_item_titles

                        return (
                            getFormFieldComponent(
                                field,
                                roleAssignToGroupForm,
                                handleRoleAssignmentToGroupFormDataChange,
                                defaultTexts[field.label],
                                {...form_field_options}
                            )
                        )
                    } else {
                        return <React.Fragment key={`empty_${index}`}></React.Fragment>
                    }
                })}
            </FormGroup>
        )
    }

    const handleEditModeChange = () => {
        handleRoleDetailTabChange("/role-details")
        setEditMode(true)
    }

    const handleEditModeReset = () => {
        setUpdateFormData({...selectedRow})
        handleRoleDetailTabChange("/role-details")
        setEditMode(false)
    }
    
    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }
    const handleSuccessDialogClose = () => {
        handleDrawerClose()
        setSuccessRoleDelete(null);
        setSuccessDialogOpen(false);
    }
    const handleSuccessUpdateDialogClose = () => {
        setSuccessRoleUpdate(null)
        setUpdateFormData({...selectedRow})
        setSuccessUpdateDialogOpen(false);
    }
    const handleConfirmDeleteDialogOpen = () => {
        setConfirmDeleteDialogOpen(true)
    }
    const handleConfirmDeleteDialogClose = () => {
        setConfirmDeleteDialogOpen(false)
    }

    const handleDeleteRole = async () => {
        handleConfirmDeleteDialogClose()
        const url = `${identityServiceDomain}/${identityServiceVersion}/${rolesUrl}/${selectedRow.id}`
        const method = "DELETE"

        const role_response = await openstackRequest({url: url, method: method})
        if (role_response.status_code === rolesUrlResponses.delete.success_response.status_code) {
            setSuccessRoleDelete({
                success_title: rolesUrlResponses.delete.success_response.response_title, 
                success_message: rolesUrlResponses.delete.success_response.response_message
            })
            handleFetchData()
        } else {
            const error_response = rolesUrlResponses.delete.error_response.filter(
                error_item => error_item.status_code === role_response.status_code)
            if (error_response.length > 0) {
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: role_response.error
                }
                setError(errorObject)
            } else {
                const error_response = rolesUrlResponses.delete.error_response.filter(
                    error_item => error_item.status_code === "unknown")
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: role_response.error
                }
                setError(errorObject)
            }
        }
    }

    const handleEditRole = async () => {
        const url = `${identityServiceDomain}/${identityServiceVersion}/${rolesUrl}/${selectedRow.id}`
        const method = "PATCH"

        const role_response = await openstackRequest({
            url: url, 
            method: method, 
            data: {role: updateFormData}
        })
        if (role_response.status_code === rolesUrlResponses.patch.success_response.status_code) {
            handleFetchData()
            handleEditModeReset()
            setSuccessRoleUpdate({
                success_title: rolesUrlResponses.patch.success_response.response_title, 
                success_message: rolesUrlResponses.patch.success_response.response_message
            })
        } else {
            const error_response = rolesUrlResponses.patch.error_response.filter(
                error_item => error_item.status_code === role_response.status_code)
            if (error_response.length > 0) {
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: role_response.error
                }
                setError(errorObject)
            } else {
                const error_response = rolesUrlResponses.patch.error_response.filter(
                    error_item => error_item.status_code === "unknown")
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: role_response.error
                }
                setError(errorObject)
            }
        }
    }

    const handleRoleDetailTabChange = useCallback((navigation) => {
        let newRoleSubmenuData = roleSubMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
            } else {
                item.is_active = false
            }
            return item
        })
        setRoleSubMenu(newRoleSubmenuData)
        setCurrentTab(navigation)
    },[roleSubMenu])

    const handleRoleAssignToUserValidation = () => {
        let validation_passed = true
        let updatedDataFormOptions = {...roleAssignToUserFormOptions}
        if (!roleAssignToUserForm["scope"]) {
                validation_passed = false
                updatedDataFormOptions["scope"] = {}
                updatedDataFormOptions["scope"]["error"] = true
                updatedDataFormOptions["scope"]["errorText"] = defaultTexts.requiredFormFieldError
        }
        if (roleAssignToUserForm["scope"] === "domain_scope" && 
            !roleAssignToUserForm["domain_id"]) {
                validation_passed = false
                updatedDataFormOptions["domain_id"] = {}
                updatedDataFormOptions["domain_id"]["error"] = true
                updatedDataFormOptions["domain_id"]["errorText"] = defaultTexts.requiredFormFieldError
        }
        if (roleAssignToUserForm["scope"] === "project_scope" && 
            !roleAssignToUserForm["project_id"]) {
                validation_passed = false
                updatedDataFormOptions["project_id"] = {}
                updatedDataFormOptions["project_id"]["error"] = true
                updatedDataFormOptions["project_id"]["errorText"] = defaultTexts.requiredFormFieldError
        }
        if (!roleAssignToUserForm["user_id"]) {
                validation_passed = false
                updatedDataFormOptions["user_id"] = {}
                updatedDataFormOptions["user_id"]["error"] = true
                updatedDataFormOptions["user_id"]["errorText"] = defaultTexts.requiredFormFieldError
        }
        
        setRoleAssignToUserFormOptions(updatedDataFormOptions)
        return validation_passed
    }

    const onRoleAssignToUser = async () => {
        const validateFormData = handleRoleAssignToUserValidation()
        if (validateFormData) {
            let url = ""
            if (roleAssignToUserForm["scope"] === "domain_scope") {
                url = `${identityServiceDomain}/${identityServiceVersion}/${domainsUrl}/${roleAssignToUserForm["domain_id"]}/users/${roleAssignToUserForm["user_id"]}/roles/${selectedRow.id}`
            } else if (roleAssignToUserForm["scope"] === "project_scope") {
                url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}/${roleAssignToUserForm["project_id"]}/users/${roleAssignToUserForm["user_id"]}/roles/${selectedRow.id}`
            } else {
                url = `${identityServiceDomain}/${identityServiceVersion}/system/users/${roleAssignToUserForm["user_id"]}/roles/${selectedRow.id}`
            }
            
            const method = "PUT"

            const role_response = await openstackRequest({
                url: url, 
                method: method, 
                data: {}
            })
            if (role_response.status_code === rolesUrlResponses.delete.success_response.status_code) {
                handleAddUserDialogClose()
                setSuccessRoleUpdate({
                    success_title: rolesUrlResponses.patch.success_response.response_title, 
                    success_message: rolesUrlResponses.patch.success_response.response_message
                })
            } else {
                const error_response = rolesUrlResponses.patch.error_response.filter(
                    error_item => error_item.status_code === role_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: role_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = rolesUrlResponses.patch.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: role_response.error
                    }
                    setError(errorObject)
                }
            }
        }
        return validateFormData
    }

    const onRoleUnassignFromUser = async () => {
        const validateFormData = handleRoleAssignToUserValidation()
        if (validateFormData) {
            let url = ""
            if (roleAssignToUserForm["scope"] === "domain_scope") {
                url = `${identityServiceDomain}/${identityServiceVersion}/${domainsUrl}/${roleAssignToUserForm["domain_id"]}/users/${roleAssignToUserForm["user_id"]}/roles/${selectedRow.id}`
            } else if (roleAssignToUserForm["scope"] === "project_scope") {
                url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}/${roleAssignToUserForm["project_id"]}/users/${roleAssignToUserForm["user_id"]}/roles/${selectedRow.id}`
            } else {
                url = `${identityServiceDomain}/${identityServiceVersion}/system/users/${roleAssignToUserForm["user_id"]}/roles/${selectedRow.id}`
            }
            const method = "DELETE"

            const role_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (role_response.status_code === rolesUrlResponses.delete.success_response.status_code) {
                handleRemoveUserDialogClose()
                setSuccessRoleUpdate({
                    success_title: rolesUrlResponses.delete.success_response.response_title, 
                    success_message: rolesUrlResponses.patch.success_response.response_message
                })
            } else {
                const error_response = rolesUrlResponses.patch.error_response.filter(
                    error_item => error_item.status_code === role_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: role_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = rolesUrlResponses.patch.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: role_response.error
                    }
                    setError(errorObject)
                }
            }
        }
        return validateFormData
    }

    const handleRoleAssignToGroupValidation = () => {
        let validation_passed = true
        let updatedDataFormOptions = {...roleAssignToGroupFormOptions}
        if (!roleAssignToGroupForm["scope"]) {
                validation_passed = false
                updatedDataFormOptions["scope"] = {}
                updatedDataFormOptions["scope"]["error"] = true
                updatedDataFormOptions["scope"]["errorText"] = defaultTexts.requiredFormFieldError
        }
        if (roleAssignToGroupForm["scope"] === "domain_scope" && 
            !roleAssignToGroupForm["domain_id"]) {
                validation_passed = false
                updatedDataFormOptions["domain_id"] = {}
                updatedDataFormOptions["domain_id"]["error"] = true
                updatedDataFormOptions["domain_id"]["errorText"] = defaultTexts.requiredFormFieldError
        }
        if (roleAssignToGroupForm["scope"] === "project_scope" && 
            !roleAssignToGroupForm["project_id"]) {
                validation_passed = false
                updatedDataFormOptions["project_id"] = {}
                updatedDataFormOptions["project_id"]["error"] = true
                updatedDataFormOptions["project_id"]["errorText"] = defaultTexts.requiredFormFieldError
        }
        if (!roleAssignToGroupForm["group_id"]) {
                validation_passed = false
                updatedDataFormOptions["group_id"] = {}
                updatedDataFormOptions["group_id"]["error"] = true
                updatedDataFormOptions["group_id"]["errorText"] = defaultTexts.requiredFormFieldError
        }
        
        setRoleAssignToGroupFormOptions(updatedDataFormOptions)
        return validation_passed
    }

    const onRoleAssignToGroup = async () => {
        const validateFormData = handleRoleAssignToGroupValidation()
        if (validateFormData) {
            let url = ""
            if (roleAssignToGroupForm["scope"] === "domain_scope") {
                url = `${identityServiceDomain}/${identityServiceVersion}/${domainsUrl}/${roleAssignToGroupForm["domain_id"]}/groups/${roleAssignToGroupForm["group_id"]}/roles/${selectedRow.id}`
            } else if (roleAssignToGroupForm["scope"] === "project_scope") {
                url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}/${roleAssignToGroupForm["project_id"]}/groups/${roleAssignToGroupForm["group_id"]}/roles/${selectedRow.id}`
            } else {
                url = `${identityServiceDomain}/${identityServiceVersion}/system/groups/${roleAssignToGroupForm["group_id"]}/roles/${selectedRow.id}`
            }
            const method = "PUT"

            const role_response = await openstackRequest({
                url: url, 
                method: method, 
                data: {}
            })
            if (role_response.status_code === rolesUrlResponses.delete.success_response.status_code) {
                handleAddGroupDialogClose()
                setSuccessRoleUpdate({
                    success_title: rolesUrlResponses.patch.success_response.response_title, 
                    success_message: rolesUrlResponses.patch.success_response.response_message
                })
            } else {
                const error_response = rolesUrlResponses.patch.error_response.filter(
                    error_item => error_item.status_code === role_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: role_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = rolesUrlResponses.patch.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: role_response.error
                    }
                    setError(errorObject)
                }
            }
        }
        return validateFormData
    }

    const onRoleUnassignFromGroup = async () => {
        const validateFormData = handleRoleAssignToGroupValidation()
        if (validateFormData) {
            let url = ""
            if (roleAssignToGroupForm["scope"] === "domain_scope") {
                url = `${identityServiceDomain}/${identityServiceVersion}/${domainsUrl}/${roleAssignToGroupForm["domain_id"]}/groups/${roleAssignToGroupForm["group_id"]}/roles/${selectedRow.id}`
            } else if (roleAssignToGroupForm["scope"] === "project_scope") {
                url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}/${roleAssignToGroupForm["project_id"]}/groups/${roleAssignToGroupForm["group_id"]}/roles/${selectedRow.id}`
            } else {
                url = `${identityServiceDomain}/${identityServiceVersion}/system/groups/${roleAssignToGroupForm["group_id"]}/roles/${selectedRow.id}`
            }
            const method = "DELETE"

            const role_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (role_response.status_code === rolesUrlResponses.delete.success_response.status_code) {
                handleRemoveGroupDialogClose()
                setSuccessRoleUpdate({
                    success_title: rolesUrlResponses.delete.success_response.response_title, 
                    success_message: rolesUrlResponses.patch.success_response.response_message
                })
            } else {
                const error_response = rolesUrlResponses.patch.error_response.filter(
                    error_item => error_item.status_code === role_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: role_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = rolesUrlResponses.patch.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: role_response.error
                    }
                    setError(errorObject)
                }
            }
        }
        return validateFormData
    }

    useEffect(() => {
        if (Object.keys(roleAssignToUserForm).length === 0) {
            let new_form_data = {}
            for (const n in roleAssignToUserDataForm) {
                if (
                    roleAssignToUserDataForm[n].field_type === "string" || 
                    roleAssignToUserDataForm[n].field_type === "select" ||
                    roleAssignToUserDataForm[n].field_type === "list"
                    ) {
                    new_form_data[roleAssignToUserDataForm[n].field_key] = ""
                } else if (roleAssignToUserDataForm[n].field_type === "bool") {
                    new_form_data[roleAssignToUserDataForm[n].field_key] = roleAssignToUserDataForm[n].default_value ? 
                    roleAssignToUserDataForm[n].default_value : 
                    false
                }
            }
            setRoleAssignToUserForm(new_form_data)
        }
    },[roleAssignToUserForm]);

    useEffect(() => {
        if (Object.keys(roleAssignToGroupForm).length === 0) {
            let new_form_data = {}
            for (const n in roleAssignToGroupDataForm) {
                if (
                    roleAssignToGroupDataForm[n].field_type === "string" || 
                    roleAssignToGroupDataForm[n].field_type === "select" ||
                    roleAssignToGroupDataForm[n].field_type === "list"
                    ) {
                    new_form_data[roleAssignToGroupDataForm[n].field_key] = ""
                } else if (roleAssignToGroupDataForm[n].field_type === "bool") {
                    new_form_data[roleAssignToGroupDataForm[n].field_key] = roleAssignToGroupDataForm[n].default_value ? 
                    roleAssignToGroupDataForm[n].default_value : 
                    false
                }
            }
            setRoleAssignToGroupForm(new_form_data)
        }
    },[roleAssignToGroupForm]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessDialogOpen(true)
    },[successRoleDelete]);

    useEffect(() => {
        setSuccessUpdateDialogOpen(true)
    },[successRoleUpdate]);

    useEffect(() => {
        if (selectedRow !== null && roleRecord.domain_id !== null) {
            (async () => {
                const url = `${identityServiceDomain}/${identityServiceVersion}/${domainsUrl}/${roleRecord.domain_id}`
                const method = "GET"

                const role_domain = await openstackRequest({
                    url: url, 
                    method: method
                })
                
                if (role_domain.status_code === rolesUrlResponses.get.success_response.status_code) {
                    setRoleDomain(role_domain.data.domain)
                } else {
                    setRoleDomain({})
                    }
            })();
        }
    },[
        selectedRow,
        identityServiceDomain,
        identityServiceVersion,
        domainsUrl,
        roleRecord
    ]);

    useEffect(() => {
        if (selectedRow !== null) {
            (async () => {
                const domain_query = roleRecord.domain_id ? `?domain_id=${roleRecord.domain_id}` : ""
                const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}/${domain_query}`
                const method = "GET"

                const role_domain_projects = await openstackRequest({
                    url: url, 
                    method: method
                })
                if (role_domain_projects.status_code === rolesUrlResponses.get.success_response.status_code) {
                    const project_list = role_domain_projects.data.projects.map(item => {
                        return {keyword: item.name, value: item.id, default: false}
                    })
                    const sorted_list = sortObjectListAscending(project_list,"keyword")
                    setRoleDomainProjects(sorted_list)
                } else {
                    setRoleDomainProjects([])
                    }
            })();
        }
    },[
        selectedRow,
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl,
        roleRecord
    ]);

    useEffect(() => {
        if (selectedRow !== null) {
            let role_actions = []
            let new_action = {}
            new_action = {}
            new_action["value"] = "role_assign_user"
            new_action["action"] = handleAssignUserDialogOpen
            new_action["keyword"] = "roleAssignToUserActionTitle"
            new_action["button_text"] = "selectButtonTitleText"
            role_actions.push({...new_action})
            new_action = {}
            new_action["value"] = "role_unassign_user"
            new_action["action"] = handleUnassignUserDialogOpen
            new_action["keyword"] = "roleUnassignFromUserActionTitle"
            new_action["button_text"] = "selectButtonTitleText"
            role_actions.push({...new_action})
            new_action["value"] = "role_assign_group"
            new_action["action"] = handleAssignGroupDialogOpen
            new_action["keyword"] = "roleAssignToGroupActionTitle"
            new_action["button_text"] = "selectButtonTitleText"
            role_actions.push({...new_action})
            new_action = {}
            new_action["value"] = "role_unassign_group"
            new_action["action"] = handleUnassignGroupDialogOpen
            new_action["keyword"] = "roleUnassignFromGroupActionTitle"
            new_action["button_text"] = "selectButtonTitleText"
            role_actions.push({...new_action})
            
            setRoleActions(role_actions)
        }
    },[
        selectedRow,
        defaultTexts,
        handleAssignUserDialogOpen,
        handleUnassignUserDialogOpen,
        handleAssignGroupDialogOpen,
        handleUnassignGroupDialogOpen
    ]);

    return (
        <React.Fragment>
        {selectedRow !== null && 
        <WrapperBox>
            <ServiceContentHeader 
                service_menu={roleSubMenu}
                service_menu_titles={defaultTexts}
                onClick={handleRoleDetailTabChange}
            />
        </WrapperBox>}
        {currentTab === "/role-details" &&
        <React.Fragment>
            {!editMode ? <Stack 
                spacing={2} 
                sx={{ p: 2, mt: 1, flexWrap: "wrap"}}
            >
                    {roleDataSchema.map((field) => {
                        let value = selectedRow ? 
                        selectedRow[field.field_key] : ""
                        if (field.field_key === "domain_id") {
                            value = roleDomain.name
                        }
                        return (
                            getDetailDataComponent({
                                fieldType: field.field_type,
                                fieldKey: field.field_key,
                                label: defaultTexts[field.label],
                                value: value,
                                textOnTrue: defaultTexts[field.value_on_true] ? 
                                defaultTexts[field.value_on_true] : 
                                defaultTexts.formValueYes,
                                textOnFalse: defaultTexts[field.value_on_false] ? 
                                defaultTexts[field.value_on_false] : 
                                defaultTexts.formValueNo,
                                defaultTexts: defaultTexts
                            })
                        )
                    })}
                </Stack> : 
                <Stack 
                    sx={{
                        m: 2, 
                        alignItems: 'start'
                    }}>
                <CustomText 
                    size="h6" 
                    sx={{
                        color: "primary.main", 
                        mb: 2
                    }}>
                    {defaultTexts.updateRoleFormTitle}
                </CustomText>
                {getDataForm()}
                <Button 
                    variant="contained"
                    color="secondary"
                    sx={{mt: 5}}
                    onClick={handleEditRole}
                >
                    {defaultTexts.saveButtonText}
                </Button>
            </Stack>}
            </React.Fragment>}
            <Paper sx={{ 
                position: 'fixed', 
                bottom: 0, 
                height: Constants.actions_bar_height + 20,
                left: width - (width * widthWeight), 
                right: 12
                }} 
                elevation={24}
                square={true}
            >
                <Grid 
                    container 
                    alignItems="center"  
                    justifyContent="space-between"
                >
                    <Grid item>
                        <CustomSelectField 
                            items={roleActions} 
                            currentValue={currentAction}
                            setCurrentValue={setCurrentAction}
                            item_titles={defaultTexts}
                            label={defaultTexts.actionsDropdownLabelText}
                            empty={true}
                            size="small"
                            sx={{m: 1, width: 280}}
                        />
                        {currentAction.length > 0 && 
                            <Button 
                                    variant="contained"
                                    color="secondary"
                                    sx={{m: 1, height: '70%'}}
                                    onClick={roleActions.filter(
                                        action => action.value === currentAction)[0].action
                                    }
                                >
                                {defaultTexts[roleActions.filter(
                                    action => action.value === currentAction)[0].button_text]}
                            </Button>
                        }
                    </Grid>
                    <Grid item>
                        {!editMode ? <IconButton onClick={handleEditModeChange}>
                                <EditIcon color="primary" />
                            </IconButton> :
                            <IconButton onClick={handleEditModeReset}>
                                <EditOffIcon color="primary"/>
                            </IconButton>
                        }
                        {selectedRow !== null && 
                            <IconButton onClick={handleConfirmDeleteDialogOpen}>
                                <DeleteIcon 
                                    color="primary"
                                />
                            </IconButton>}
                    </Grid>
                </Grid>
            </Paper> 
            {successRoleDelete && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[successRoleDelete.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successRoleDelete.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {successRoleUpdate && <CustomDialog
                open={successUpdateDialogOpen}
                onClose={handleSuccessUpdateDialogClose}
                dialogTitle={{
                    title: defaultTexts[successRoleUpdate.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successRoleUpdate.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>Details:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            {confirmDeleteDialogOpen && <CustomDialog
                open={confirmDeleteDialogOpen}
                onClose={handleConfirmDeleteDialogClose}
                dialogTitle={{
                    title: defaultTexts.deleteConfirmationDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.deleteRoleConfirmationDialogMessage, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: handleDeleteRole, 
                    sx: {color: 'primary.main'}}]}
            />}
            <CustomDialog
                open={assignToUserMode}
                onClose={handleAddUserDialogClose}
                dialogTitle={{
                    title: defaultTexts.assignRoleToUserDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.assignUnassignRoleToUserDialogText, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    variant: "contained",
                    size: "small", 
                    onClick: onRoleAssignToUser, 
                    sx: {color: 'white'}}]}
            >
                {getRoleAssignmentToUserForm()}
            </CustomDialog>
            <CustomDialog
                open={unassignToUserMode}
                onClose={handleRemoveUserDialogClose}
                dialogTitle={{
                    title: defaultTexts.unassignRoleFromUserDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.assignUnassignRoleToUserDialogText, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    variant: "contained",
                    size: "small", 
                    onClick: onRoleUnassignFromUser, 
                    sx: {color: 'white'}}]}
            >
                {getRoleAssignmentToUserForm()}
            </CustomDialog>
            <CustomDialog
                open={assignToGroupMode}
                onClose={handleAddGroupDialogClose}
                dialogTitle={{
                    title: defaultTexts.assignRoleToGroupDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.assignUnassignRoleToGroupDialogText, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    variant: "contained",
                    size: "small", 
                    onClick: onRoleAssignToGroup, 
                    sx: {color: 'white'}}]}
            >
                {getRoleAssignmentToGroupForm()}
            </CustomDialog>
            <CustomDialog
                open={unassignToGroupMode}
                onClose={handleRemoveGroupDialogClose}
                dialogTitle={{
                    title: defaultTexts.unassignRoleFromGroupDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.assignUnassignRoleToGroupDialogText, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText,
                    variant: "contained",
                    size: "small", 
                    onClick: onRoleUnassignFromGroup, 
                    sx: {color: 'white'}}]}
            >
                {getRoleAssignmentToGroupForm()}
            </CustomDialog>
        </React.Fragment>
    )
};

export default IdentityRoleDetailV3;