import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { IconButton } from '@mui/material';
import { RxReset } from "react-icons/rx";
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import Checkbox from "@mui/material/Checkbox";
import TextField from '@mui/material/TextField';
import Constants from '../../../config/constants'
import billingRequest from '../../../_network/billing_request';
import { billingServiceURLs } from '../../../_network/apiUrls';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import FilterButton from '../../_common/FilterButton';
import { setBillingDepositsTableRows } from 
'../../../store/reducers/settingsSlice';
import CustomText from '../../_common/CustomText';
import { timeToAgeConverter } from '../_common/data_formatter';

const drawerWidth = Constants.drawerWidth;

const BillingDepositsTable = (props) => {
    const drawerOpen = useSelector(state => state.drawer.drawerOpened);
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const billingDepositsTableRows = useSelector(state => state.settings.billingDepositsTableRows)
    const { billingDeposits, handleDataFetch, handleDataListFetch } = props
    const { filterMenu } = props
    const { selectedFilter } = props
    const { setSelectedFilter } = props
    const { selectedFilterValue } = props
    const { setSelectedFilterValue } = props
    const { handleFilteredSearch } = props
    const { handleFilterReset } = props
    const { openstackProjects } = props
    const [selectedDeposits, setSelectedDeposits] = useState([])
    const [depositEditMode, setDepositEditMode] = useState(false)
    const purchasedService = useSelector(
        state => state.openstack.purchasedServicesAll)
    const [billingServicesChecked, setBillingServicesChecked] = useState(false)
    const [depositUpdateAllowed, setDepositUpdateAllowed] = useState(false)
    const [page, setPage] = useState(0);
    const [startItem, setStartItem] = useState(0);
    const [endItem, setEndItem] = useState(billingDeposits.length);
    const [billingConfigs, setBillingConfigs] = useState({})

    const ROWS_PER_PAGE_LIST = billingDeposits.length > 100 ? 
    [5, 10, 25, 50, 100, billingDeposits.length] : 
    [5, 10, 25, 50, 100]

    const theme = useTheme();
    const dispatch = useDispatch();

    const statusColors = {
        active: theme.palette.mode === "dark" ?
        theme.palette.dashboardGreenDark : 
        theme.palette.dashboardGreen,
        suspended: theme.palette.mode === "dark" ?
        theme.palette.dashboardPinkDark : 
        theme.palette.dashboardPink,
        inactive: theme.palette.mode === "dark" ?
        theme.palette.customBlueDark : 
        theme.palette.customBlue,
        deleted: theme.palette.mode === "dark" ?
        theme.palette.dashboardPurpleDark : 
        theme.palette.dashboardPurple
    }

    const handleRowsPerPageChange = (event) => {
        const new_rows_per_page = parseInt(event.target.value)
        setPage(0);
        dispatch(setBillingDepositsTableRows({
            billingDepositsTableRows: new_rows_per_page
        }))
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(() => {
        if (!billingDepositsTableRows) {
            dispatch(setBillingDepositsTableRows({
                billingDepositsTableRows: 10
            }))
        }
    },[billingDepositsTableRows,dispatch])

    useEffect(() => {
          const start_item = page*billingDepositsTableRows
          const end_item = page*billingDepositsTableRows+billingDepositsTableRows
          setStartItem(start_item)
          setEndItem(end_item)
        },[
        page,
        billingDepositsTableRows
      ]);
    

    useEffect(() => {
        const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingConfigsEP}`
        let request_data = {
            url: url,
            method: "GET"
        }
        billingRequest(request_data).then((response) => {
            if (response.status_code === 200) {
                setBillingConfigs(response.data)
            }
        }).catch((err) => {
            console.log(err)
    })
        
    },[]);

    const TableSelectField = (props) => {
        const { value } = props
        const { setValue } = props
        const { fieldKey } = props
        const { label } = props
        const { items } = props
        const { id } = props

        const handleChange = (event) => {
            setValue(event, id, fieldKey)
        }

        return (
            <FormControl variant="standard" sx={{ mb: 2, minWidth: 120 }}>
                <InputLabel>{label}</InputLabel>
                <Select
                    value={value}
                    onChange={handleChange}
                    label={label}
                >
                    {items.map(item => 
                        <MenuItem key={item.value} value={item.value}>
                            {item.keyword}
                        </MenuItem>)
                    }
                </Select>
            </FormControl>
        )
    }

    const handleSelectDepositEditing = (event, deposit) => {
        if (event.target.checked) {
            let new_list = [...selectedDeposits, deposit]
            setSelectedDeposits(new_list)

        } else {
            let new_list = selectedDeposits.filter(
                dep => dep.id !== deposit.id)
            setSelectedDeposits(new_list)
        }
    }

    const handleDepositChange = (event, item_id, field_key) => {
        const new_list = selectedDeposits.map(item => {
            if (item.id === item_id) {
                let new_item = {...item}
                if (field_key === "postpaid_invoice_pay_period") {
                    new_item[field_key] = parseInt(event.target.value)
                } else if (field_key === "status") {
                    new_item[field_key] = event.target.value
                    if (item[field_key] === "suspended" && event.target.value !== "suspended") {
                        new_item["properties"] = {...item.properties}
                        delete new_item["properties"]["suspend_time"]
                    } 
                } else {
                    new_item[field_key] = event.target.value
                }
                return new_item
            } else {
                return item
            }
        })
        setSelectedDeposits(new_list)
    }

    const handleDepositUpdateReset = () => {
        setSelectedDeposits([])
        setDepositEditMode(false)
    }

    const handleSaveUpdatedDeposits = async () => {
        if (selectedDeposits.length > 0) {
            const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingDepositsEP}-list`
            let request_data = {
                url: url,
                method: "PUT",
                data: {projects: selectedDeposits}
            }
            const response = await billingRequest(request_data)
            if (response.status_code === 200) {
                setSelectedDeposits([])
                setDepositEditMode(false)
                handleDataFetch()
                handleDataListFetch()
            }
        } else {
            setDepositEditMode(false)
        }
    }

    useEffect(() => {
        if (!billingServicesChecked && purchasedService.length > 0) {
            const billingService = purchasedService.filter(
            service => service.service === Constants.config_service_billing_name)
            const billingConfiguration = billingService[0].config_params[Constants.config_service_custom_admin_ui]
            if (billingConfiguration.filter(
                section => section.section === Constants.billing_sections.billing_deposits && 
                section.permissions.includes(Constants.permission_update)).length > 0) {
                    setDepositUpdateAllowed(true)
            }
            setBillingServicesChecked(true)
        }
    },[
        purchasedService,
        billingServicesChecked
    ])

    return (
        <React.Fragment>
            <Box sx={{
                minHeight: 40,
                display: "flex", 
                flexDirection: "row", 
                alignItems: "end", 
                justifyContent: "space-between"
            }}>
                {depositUpdateAllowed && 
                    <Grid 
                        container 
                        alignItems="center" 
                        
                        sx={{
                            maxWidth: drawerOpen ? 
                            `calc(100vw - ${drawerWidth}px)` : 
                            `calc(100vw - ${110}px)`,
                            my: {xs: 1, sm: 0}
                        }}
                    >
                        <Grid item>
                            {depositEditMode && 
                                <Button 
                                    variant="contained" 
                                    color="primary"
                                    sx={{width: 120}}
                                    onClick={handleSaveUpdatedDeposits}
                                >
                                    {defaultTexts.saveButtonTitle}
                                </Button>
                            }
                            {!depositEditMode && 
                                <Button 
                                    variant="outlined" 
                                    color="primary"
                                    sx={{width: 120}}
                                    onClick={() => setDepositEditMode(true)}
                                >
                                    {defaultTexts.editButtonTitle}
                                </Button>
                            }
                        </Grid>
                        {depositEditMode && <Grid item>
                            <Tooltip title={defaultTexts.resetDepositEditModeTooltipText}>
                                <IconButton onClick={handleDepositUpdateReset} sx={{mr: 1}}>
                                    <RxReset 
                                        color={theme.palette.text.primary}
                                        fontSize={'large'}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Grid>}
                </Grid>}
                <FilterButton
                    choice_mode={Constants.simple_filter_type}
                    currentFilter={selectedFilter}
                    setCurrentFilter={setSelectedFilter}
                    filter_menu_titles={defaultTexts}
                    filter_menu={filterMenu}
                    currentFilterValue={selectedFilterValue}
                    setCurrentFilterValue={setSelectedFilterValue}
                    onFilterSubmit={handleFilteredSearch}
                    onFilterReset={handleFilterReset}
                />
            </Box>
            {<Box sx={{
                maxWidth: drawerOpen ? 
                `calc(100vw - ${drawerWidth}px)` : 
                `calc(100vw - ${110}px)`,
                mt: 2
            }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 300}} size="small">
                        <TableHead>
                            <TableRow>
                                {depositEditMode && <TableCell align="center" style={{width: 30}}/>}
                                <TableCell  align="left">
                                    {defaultTexts.projectFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.statusFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.billingTypeFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.postpaidInvoiceTypeFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.postpaidInvoicePayPeriodFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.accountAgeFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.suspendDurationFormFieldLabel}
                                </TableCell>
                                <TableCell  align="right">
                                    {defaultTexts.balanceFormFieldLabel}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {billingDeposits.slice(startItem, endItem).map((row) => (
                                <TableRow 
                                    key={row.id}
                                >
                                    {depositEditMode && <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            checked={selectedDeposits.filter(
                                                dep => dep.id === row.id).length > 0}
                                            onClick={
                                                (event) => handleSelectDepositEditing(event, row)
                                            }
                                        />
                                    </TableCell>}
                                    <TableCell align="left">{
                                        openstackProjects.filter(
                                            p => p.id === row.project_id).length > 0 ?
                                        openstackProjects.filter(
                                            p => p.id === row.project_id)[0].name :
                                        row.project_id
                                    }</TableCell>
                                    {depositEditMode && selectedDeposits.filter(dep => dep.id === row.id).length > 0 ?
                                        <TableCell  align="left">
                                            <TableSelectField 
                                                value={selectedDeposits.filter(dep => dep.id === row.id)[0].status}
                                                setValue={handleDepositChange}
                                                items={[
                                                    {keyword: defaultTexts.activeDepositStatusText, value: "active"},
                                                    {keyword: defaultTexts.inactiveDepositStatusText, value: "inactive"}
                                                ]}
                                                fieldKey={"status"}
                                                id={row.id}
                                            />
                                        </TableCell> :
                                        <TableCell align="left">
                                            <CustomText 
                                                sx={{
                                                    color: statusColors[row.status],
                                                    textTransform: "capitalize"
                                                }}
                                            >
                                                {row.status}
                                            </CustomText>
                                        </TableCell>
                                    }
                                    {depositEditMode && selectedDeposits.filter(dep => dep.id === row.id).length > 0 ?
                                        <TableCell  align="left">
                                            <TableSelectField 
                                                value={selectedDeposits.filter(dep => dep.id === row.id)[0].billing_type}
                                                setValue={handleDepositChange}
                                                items={[
                                                    {keyword: defaultTexts.prepaidDepositTypeText, value: "prepaid"},
                                                    {keyword: defaultTexts.postpaidDepositTypeText, value: "postpaid"}
                                                ]}
                                                fieldKey={"billing_type"}
                                                id={row.id}
                                            />
                                        </TableCell> :
                                        <TableCell align="left">
                                            <CustomText 
                                                sx={{
                                                    color: row.billing_type === "prepaid" ? 
                                                    (theme.palette.mode === "dark" ?
                                                    theme.palette.dashboardGreenDark :
                                                    theme.palette.dashboardGreen) :
                                                    (theme.palette.mode === "dark" ?
                                                        theme.palette.dashboardPinkDark :
                                                        theme.palette.dashboardPink),
                                                    textTransform: "capitalize"
                                                }}
                                            >
                                                {row.billing_type}
                                            </CustomText>
                                        </TableCell>
                                    }
                                    {depositEditMode && selectedDeposits.filter(dep => dep.id === row.id).length > 0 ?
                                        <TableCell  align="left">
                                            <TableSelectField 
                                                value={selectedDeposits.filter(dep => dep.id === row.id)[0].postpaid_invoice}
                                                setValue={handleDepositChange}
                                                items={[
                                                    {keyword: defaultTexts.autoInvoiceTypeText, value: "auto"},
                                                    {keyword: defaultTexts.manualInvoiceTypeText, value: "manual"}
                                                ]}
                                                fieldKey={"postpaid_invoice"}
                                                id={row.id}
                                            />
                                        </TableCell> :
                                        <TableCell align="left">
                                            <CustomText>
                                                {row.postpaid_invoice === "auto" ? 
                                                    defaultTexts.autoInvoiceTypeDescription :
                                                    defaultTexts.manualInvoiceTypeDescription
                                                }
                                            </CustomText>
                                        </TableCell>
                                    }
                                    {depositEditMode && selectedDeposits.filter(dep => dep.id === row.id).length > 0 ?
                                        <TableCell align="left">
                                            <TextField 
                                                variant="standard" 
                                                value={selectedDeposits.filter(dep => dep.id === row.id)[0].postpaid_invoice_pay_period}
                                                onChange={(event) => handleDepositChange(event, row.id, "postpaid_invoice_pay_period")}
                                                color="primary"
                                                type="number"
                                                sx={{width: 120}}
                                            />
                                        </TableCell> :
                                        <TableCell align="left">
                                            {row.postpaid_invoice_pay_period} {defaultTexts.daysUnitLabel}
                                        </TableCell>
                                    }
                                    <TableCell align="left">{timeToAgeConverter(row.created_at)}</TableCell>
                                    <TableCell align="left">{row.properties.suspend_time ? timeToAgeConverter(row.properties.suspend_time) : ""}</TableCell>
                                    <TableCell align="right">
                                        <CustomText sx={{textTransform: "uppercase"}}>
                                        {row.amount} {billingConfigs.currency ? billingConfigs.currency.name : ""}
                                        </CustomText>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {billingDeposits.length > 0 && <TablePagination
                        rowsPerPageOptions={ROWS_PER_PAGE_LIST}
                        component="div"
                        count={billingDeposits.length}
                        rowsPerPage={billingDepositsTableRows}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        labelRowsPerPage={defaultTexts.rowsPerPageLabel}
                    />}
                </TableContainer>
            </Box>}
        </React.Fragment>
    );

}

export default BillingDepositsTable;