import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { IconButton, Button } from '@mui/material';
import { useTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, 
{ tableCellClasses } from 
'@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';
import DeselectIcon from '@mui/icons-material/Deselect';
import { BiSortAlt2 } from "react-icons/bi";
import { LuEye } from "react-icons/lu";
import PopupState, 
{ bindTrigger, 
    bindMenu } 
from 'material-ui-popup-state';
import { getTableCell } from './common_helpers';
import CustomSelectField from './_form_fields/CustomSelectField';
import { IoMdArrowDropup } from "react-icons/io";
import { IoMdArrowDropdown } from "react-icons/io";
import CustomText from './CustomText';
import Box from '@mui/material/Box';
import Constants from '../../config/constants';

const drawerWidth = Constants.drawerWidth;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    fontWeight: 600
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  // hide last border
  //'&:last-child td, &:last-child th': {
  //  border: 0,
  //},
  '&:hover': {
    backgroundColor: theme.palette.mode === 'dark' ? 
    theme.palette.mediumGray  :
    theme.palette.vLightGray
  }
}));

const CustomTable = (props) => {
  const open = useSelector(state => state.drawer.drawerOpened);
  const { dataRows, setDataRows } = props
  const { withCheckbox } = props
  const { withPagination }  = props
  const { withActions }  = props
  const { withoutColumnsHide, withRowCount } = props
  const { withSorting, selfSorting, sortHandler } = props //
  const { tableHeaders } = props
  const { defaultDataTexts } = props
  const  { identityField } = props
  const { columns } = props 
  const { tableHeadersConfigHandler } = props
  const { rowsPerPage, handleRowsPerPageChange } = props
  const { handleRowSelection, handleCellClick } = props
  const { jsonFields } = props
  const { actionsList } = props
  const { currentAction, setCurrentAction } = props
  const { actionsTexts } = props
  const { sortFields } = props
  const { getSelectedRows } = props

  const ROWS_PER_PAGE_LIST = dataRows.length > 100 ? [5, 10, 25, 50, 100, dataRows.length] : [5, 10, 25, 50, 100]

  const [selectedTableHeaders, setSelectedTableHeaders] = useState( 
    tableHeaders.map(n => n.field_key))

  const defaultTexts = useSelector(state => state.texts.langTexts)
  const [numSelected, setNumSelected] = useState();
  const [numSelectedonCurrentPage, setNumSelectedonCurrentPage] = useState();
  
  const [page, setPage] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  
  const [startItem, setStartItem] = useState(0);
  const [endItem, setEndItem] = useState(
    withPagination ? 
    rowsPerPage :
    dataRows.length);

  const [rowCount, setRowCount] = useState(
  dataRows.slice(startItem,endItem).length);
  const theme = useTheme();

  const handleTableRowClick = (index) => {
    if (!withCheckbox) {
      handleRowSelection(page*rowsPerPage + index)
    }
  }

  const handleTableCellClick = (index) => {
    if (withCheckbox) {
      if (handleRowSelection) {
        handleRowSelection(page*rowsPerPage + index)
      } else {
        handleClick(undefined,dataRows[page*rowsPerPage + index])
      }
    }
  }
  
  const handleSelectAllClick = (event) => {
    const newSelected = dataRows.slice(
      startItem,endItem).map((n) => n[identityField]);
    if (event.target.checked) {
      setSelectedRows([
        ...new Set([
          ...selectedRows, 
          ...newSelected
        ])]);
    } else {
      setSelectedRows(selectedRows.filter(
        n => newSelected.indexOf(n) === -1));
    }
  };

  const handleDeselectAllPagesClick = (event) => {
    setSelectedRows([])
  };

  const handleClick = (event, item) => {
    const selectedIndex = selectedRows.indexOf(item[identityField]);
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = [...selectedRows, item[identityField]]
    } else {
      newSelected = selectedRows.filter(
        n => n !== item[identityField])
    }
    setSelectedRows(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    handleRowsPerPageChange(parseInt(event.target.value, 10))
    setPage(0);
  };

  const handleTableHeadersConfigChange = (event) => {
    if (event.target.value !== identityField ) {
      if (selectedTableHeaders.includes(event.target.value)) {
        const new_list = selectedTableHeaders.filter(
          item => item !== event.target.value)
        setSelectedTableHeaders(new_list)
        tableHeadersConfigHandler(new_list)
  
    } else {
        const new_list = [
          ...selectedTableHeaders, 
          event.target.value
        ]
        setSelectedTableHeaders(new_list)
        tableHeadersConfigHandler(new_list)
      }
    }
  }

  const handleActionRun = () => {
    const action_func = actionsList.filter(action => action.value === currentAction)[0].action
    action_func(selectedRows)
  }

  const handleSorting = (field_key,direction) => {
    if (selfSorting) {
      sortHandler(field_key,direction)
    } else {
      if (direction === "asc") {
        sortAscending(field_key)
      } else {
        sortDescending(field_key)
      }
    }
  }

  const sortAscending = (field_key) => {
    const compare = ( a, b ) => {
      if ( a[field_key] < b[field_key] ){
        return -1;
      }
      if ( a[field_key] > b[field_key] ){
        return 1;
      }
      return 0;
    }

    let sorted_data = [...dataRows]
    sorted_data.sort(compare)
    setDataRows(sorted_data)
  }

  const sortDescending = (field_key) => {
    const compare = ( a, b ) => {
      if ( a[field_key] < b[field_key] ){
        return -1;
      }
      if ( a[field_key] > b[field_key] ){
        return 1;
      }
      return 0;
    }

    let sorted_data = [...dataRows]
    sorted_data.sort(compare)
    sorted_data.reverse()
    setDataRows(sorted_data)
  }

  useEffect(() => {
    if (withPagination) {
      const start_item = page*rowsPerPage
      const end_item = page*rowsPerPage+rowsPerPage
      setStartItem(start_item)
      setEndItem(end_item)
      setRowCount(dataRows.slice(start_item,end_item).length)
    } else {
      setStartItem(0)
      setEndItem(dataRows.length)
      setRowCount(dataRows.length)
    }
  },[
    page,
    rowsPerPage,
    withPagination,
    dataRows
  ]);

  useEffect(() => {
    const countRowsPerPage = () => {
      const rowsOnCurrentPage = dataRows.slice(
        startItem,endItem).map((n) => n[identityField])
      const currentPageSelectedRows = selectedRows.filter(
        n => rowsOnCurrentPage.indexOf(n) !== -1).length
      setNumSelectedonCurrentPage(currentPageSelectedRows)
    }
    setNumSelected(selectedRows.length)
    countRowsPerPage()
    
  },[
    selectedRows,
    startItem,
    endItem,
    identityField,
    rowCount,
    dataRows
  ]);

useEffect(() => {
  if (numSelected === 0 && withActions) {
    setCurrentAction("")
  }
},[
  numSelected, 
  setCurrentAction,
  withActions
]);

useEffect(() => {
  if (typeof getSelectedRows === 'function') {
    getSelectedRows(selectedRows)
  }
},[
  selectedRows,
  getSelectedRows
]);

  if (dataRows.length === 0) {
    return (
      <Paper
        sx={{
          border: 1,
          borderColor: "customBlue",
          borderRadius: 2,
          maxWidth: open ? 
          `calc(100vw - ${drawerWidth}px)` : 
          `calc(100vw - ${110}px)`,
          height: 400,
          alignItems: 'center',
          justifyContent: 'center',
          p: 3,
          mt: 1,
          display: "flex"
        }}
      >
        <CustomText 
          size="h5" 
          sx={{color: "customBlue"}}
        >
          {defaultTexts.noDataToDisplayText}
        </CustomText>
      </Paper>
    )
  }

  return (
    <Box sx={{
      maxWidth: open ? `calc(100vw - ${drawerWidth}px)` : `calc(100vw - ${110}px)`,
      mt: 2
    }}>
      <Grid 
        container 
        spacing={0}
        direction="row" 
        justifyContent="space-between"
        alignItems="center"
      >
        {withCheckbox && <Grid item>
          {defaultTexts.selectedRowsLabel} 
          <Chip 
            label={`${numSelected} / ${dataRows.length}`}
            color="secondary" 
            sx={{m: 1}}
          />
          {withActions && 
            <React.Fragment>
              {numSelected > 0 && <CustomSelectField 
                items={actionsList} 
                currentValue={currentAction}
                setCurrentValue={setCurrentAction}
                item_titles={actionsTexts}
                label={defaultTexts.actionsButtonText}
                empty={true}
                size="small"
                disabled={numSelected === 0}
              />}
              {currentAction.length > 0 && numSelected > 0 &&
                  <Button 
                    variant="contained"
                    color="info"
                    sx={{mx: 1, mb: 1,height: '70%', mt: {xs: 1, sm: 0}}}
                    onClick={handleActionRun}
                  >
                    {actionsTexts[actionsList.filter(
                      action => action.value === currentAction)[0].button_text]}
                </Button>
              }
            </React.Fragment>
          }
          {numSelected > 0 && 
            <Tooltip 
              title={defaultTexts.deselectAllButtonTooltipText}
            >
              <IconButton 
                onClick={handleDeselectAllPagesClick}
              >
                <DeselectIcon />
              </IconButton>
            </Tooltip>}
        </Grid>}
        {!withCheckbox && !withRowCount && <Grid item>
          {defaultTexts.totalRowsLabel} 
          <Chip 
            label={dataRows.length} 
            color="secondary" 
            sx={{m: 1}}
          />
        </Grid>}
        <Grid item>
          {withSorting && 
            <PopupState variant="popover" popupId="table-headers">
              {(popupState) => (
                  <React.Fragment>
                      <Tooltip title={defaultTexts.sortButtonTooltipText}>
                        <IconButton {...bindTrigger(popupState)}>
                          <BiSortAlt2 
                            size={22} 
                            color={theme.palette.primary.main}
                          />
                        </IconButton>
                      </Tooltip>
                      <Menu 
                        {...bindMenu(popupState)}
                      >
                          {sortFields.map(item => {
                            return (
                              <MenuItem 
                                key={item.field_key}
                                sx={{width: 280}}
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  justifyContent="space-between"
                                  sx={{width: "100%"}}
                                >
                                <CustomText size="p">
                                  {defaultDataTexts[item.label] ? 
                                    defaultDataTexts[item.label] : 
                                    item.label}
                                </CustomText>
                                  <Stack 
                                    direction="row" 
                                    alignItems="center"
                                  >
                                    <IconButton
                                      onClick={() => {
                                        handleSorting(item.field_key,"asc"); 
                                        popupState.close()
                                      }}
                                    >
                                      <IoMdArrowDropup />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => {
                                        handleSorting(item.field_key,"desc"); 
                                        popupState.close()
                                      }}
                                    >
                                      <IoMdArrowDropdown />
                                    </IconButton>
                                  </Stack>
                                </Stack>
                              </MenuItem>
                            )
                        })}
                    </Menu>
                  </React.Fragment>
                )}
            </PopupState>
          }
          {!withoutColumnsHide && <PopupState variant="popover" popupId="table-headers">
            {(popupState) => (
                <React.Fragment>
                  <Tooltip title={defaultTexts.addRemoveColumnsButtonTooltipText}>
                    <IconButton {...bindTrigger(popupState)}>
                      <LuEye 
                        size={22} 
                        color={theme.palette.primary.main}
                      />
                    </IconButton>
                  </Tooltip>
                    <Menu {...bindMenu(popupState)}>
                      <FormGroup>
                      {columns.map(item => {
                          return (
                            <MenuItem 
                                key={item.field_key}
                            >
                              <FormControlLabel 
                                  control={
                                      <Checkbox 
                                          checked={selectedTableHeaders.includes(item.field_key)}
                                          value={item.field_key} 
                                          onChange={handleTableHeadersConfigChange} 
                                      />} 
                                  label={defaultDataTexts[item.label] ? defaultDataTexts[item.label] : item.label}
                                  componentsProps={{
                                      typography: {
                                          fontSize: 14,
                                          color: theme.palette.text.primary,
                                          textTransform: 'uppercase'
                                      }
                                  }}
                              />
                            </MenuItem>
                          )
                      })}
                    </FormGroup>
                  </Menu>
                </React.Fragment>
              )}
          </PopupState>}
        </Grid>
      </Grid>
      <TableContainer component={Paper} >
          <Table sx={{ minWidth: 300}} size="small">
              <TableHead>
                  <TableRow>
                    {withCheckbox && 
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          indeterminate={
                            numSelectedonCurrentPage > 0 && 
                            numSelectedonCurrentPage < rowCount
                          }
                          checked={rowCount > 0 && 
                            numSelectedonCurrentPage === rowCount}
                          onChange={handleSelectAllClick}
                        />
                      </StyledTableCell>}
                      {tableHeaders.map((header) => {
                        let sort_key = undefined
                        if (withSorting && sortFields) {
                          sort_key = sortFields.filter(k => k.field_key === header.field_key)
                        }
                        return (
                          <StyledTableCell key={header.field_key}>
                          <Stack direction="row" spacing={1} alignItems="center">
                            {
                              defaultDataTexts[header.label] ? 
                              defaultDataTexts[header.label] : 
                              header.label.toUpperCase()
                            }
                            {sort_key && sort_key.length > 0 && <Stack>
                            <IconButton
                              onClick={() => {
                                handleSorting(sort_key[0].field_key,"asc"); 
                              }}
                            >
                              <IoMdArrowDropup size={12}/>
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                handleSorting(sort_key[0].field_key,"desc"); 
                              }}
                            >
                              <IoMdArrowDropdown size={12}/>
                            </IconButton>
                            </Stack>}
                            </Stack>
                          </StyledTableCell>)
                      })}
                  </TableRow>
              </TableHead>
              <TableBody>
                {dataRows.slice(startItem, endItem).map((row,index) => {
                    return (
                    <StyledTableRow 
                      key={`${row[identityField]}_${index}`}
                      onClick={() => handleTableRowClick(index)}
                      sx={{ 
                        cursor: 'pointer'
                      }}
                    >
                      {withCheckbox && <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={selectedRows.indexOf(row[identityField]) !== -1}
                          onClick={(event) => handleClick(event, row)}
                        />
                      </TableCell>}
                        {tableHeaders.map((header,ind) => {
                          let json_fields = undefined
                          if (header.field_type === "json") {
                            json_fields = jsonFields
                          }
                          return (
                            getTableCell({
                              fieldType: header.field_type,
                              value: row[header.field_key],
                              index: ind,
                              onClickFunction: handleCellClick && Object.keys(handleCellClick).includes(header.field_key) ? handleCellClick[header.field_key] : handleTableCellClick,
                              row: index,
                              textOnTrue: header.value_on_true ? defaultDataTexts[header.value_on_true] : defaultDataTexts.formValueYes,
                              textOnFalse: header.value_on_false ? defaultDataTexts[header.value_on_false] : defaultDataTexts.formValueNo,
                              listItemNumber: 3,
                              defaultTexts: defaultDataTexts,
                              formFields: json_fields
                            })
                          )
                        })}
                    </StyledTableRow>)
                })}
              </TableBody>
          </Table>
          {withPagination && dataRows.length > 0 && <TablePagination
            rowsPerPageOptions={ROWS_PER_PAGE_LIST}
            component="div"
            count={dataRows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage={defaultTexts.rowsPerPageLabel}
          />}
      </TableContainer>
      
      </Box>
  );
};

export default CustomTable;