import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../../../../_common/CustomTable';
import { firewallRuleDataSchema, firewallRulesSortFields } from 
'../../../../../../_data/openstack/neutron/fwaas/v2.0';
import { setFirewallRulesTableConfig } from 
'../../../../../../store/reducers/networkNeutronSlice';

const FirewallRulesTableV20 = (props) => {
    const { firewallRulesData, setFirewallRulesData } = props
    const { handleRowSelection } = props
    const {currentAction} = props
    const {setCurrentAction}  = props
    const { actionsTexts }  = props
    const { actionsList } = props
    const { sortHandler } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);
    
    const firewallRulesTableConfig = useSelector(
        state => state.networkNeutron.firewallRulesTableConfig)
    const rowsPerPage = useSelector(
        state => state.networkNeutron.firewallRulesTableConfig.filter(
        versions => versions.api_version === "v2.0")[0].rows_per_page)
    const firewallRulesTableHeaders = useSelector(
        state => state.networkNeutron.firewallRulesTableConfig.filter(
            versions => versions.api_version === "v2.0")[0].columns)

    
    const identityField = firewallRulesTableHeaders.filter(
        item => item.is_id_field)[0].field_key
    
    
    const dispatch = useDispatch();

    const handleFirewallRuleTableConfig = (value_list) => {
        const newTableConfig = firewallRuleDataSchema.filter(
            n => value_list.includes(n.field_key))
        const new_data = firewallRulesTableConfig.map(version => {
            if (version.api_version === "v2.0") {
                let new_item = {...version}
                new_item.columns = [...newTableConfig]
                return new_item
            } else {
                return version
            }
        })
        dispatch(setFirewallRulesTableConfig({
            firewallRulesTableConfig: new_data
        }))
    }
    
    const handleRowsPerPageChange = (rows_num) => {
        const new_data = firewallRulesTableConfig.map(version => {
            if (version.api_version === "v2.0") {
                let new_item = {...version}
                new_item.rows_per_page = rows_num
                return new_item
            } else {
                return version
            }
        })
        dispatch(setFirewallRulesTableConfig({
            firewallRulesTableConfig: new_data
        }))
    }

    return (
        <React.Fragment>
            <CustomTable
                withPagination={true}
                withCheckbox={true}
                withActions={true}
                withSorting={false}
                defaultDataTexts={{...defaultTexts}} 
                tableHeaders={firewallRulesTableHeaders}
                tableHeadersConfigHandler={handleFirewallRuleTableConfig}
                identityField={identityField}
                columns={[...firewallRuleDataSchema]}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                dataRows={firewallRulesData}
                setDataRows={setFirewallRulesData}
                handleRowSelection={handleRowSelection}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={actionsTexts}
                actionsList={actionsList}
                sortFields={firewallRulesSortFields}
                sortHandler={sortHandler}
                selfSorting={true}
            />
        </React.Fragment>
    )
};

export default FirewallRulesTableV20;