import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CustomDialog from '../../../../../_common/CustomDialog';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import Dimensions from '../../../../../../config/dimensions';
import CustomSideDrawer from '../../../../../_common/CustomSideDrawer';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import { openStackServices, 
        networkNeutronConstants, 
        identityKeystonConstants
} from '../../../../../../config/openStackConstants';
import FirewallPoliciesSubheaderV20 from './firewallPoliciesSubheaderV2.0';
import FirewallPoliciesTableV20 from './firewallPoliciesTableV2.0';
import FirewallPolicyDetailV20 from './firewallPolicyDetailV2.0';
import { firewallPoliciesFilterMenu } 
from '../../../../../../_data/openstack/neutron/fwaas/v2.0';
import { 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../../_network/openstack_request';
import { firewallUrl as firewallUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/fwaas/v2.0';
import { projectsUrl as projectUrlResponses} from 
'../../../../../../_api_responses/openstack/identity/projects/v3';
import CustomCheckboxField from '../../../../../_common/_form_fields/CustomCheckboxField';

const SERVICE_NAME = openStackServices.networkService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const FirewallPoliciesWrapperV20 = (props) => {
    const { navigate, location } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const {currentTab, setCurrentTab} = props
    const [firewallPoliciesData, setFirewallPoliciesData] = useState([])
    const [firewallPolicies, setFirewallPolicies] = useState([])
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = width < Dimensions.tablet_mini.width ? 0.9 : 0.8
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedFirewallPolicy, setSelectedFirewallPolicy] = useState(null);
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [currentAction, setCurrentAction] = useState("");
    const [firewallPolicyDeleteConfirmDialogOpen, setFirewallPolicyDeleteConfirmDialogOpen] = useState(false);
    const [selectedFirewallPolicies, setSelectedFirewallPolicies] = useState([])
    const [firewallPoliciesSortParams, setFirewallPoliciesSortParams] = useState("")
    const [auditedStateChangeDialogOpen, setAuditedStateChangeDialogOpen] = useState(false);
    const [sharedStateChangeDialogOpen, setSharedStateChangeDialogOpen] = useState(false);
    const [selectedAuditedState, setSelectedAuditedState] = useState(true);
    const [selectedSharedState, setSelectedSharedState] = useState(true);
    
    const [firewallPolicyFilterQueryParams, setFirewallPolicyFilterQueryParams] = useState("")
    const [selectedFirewallPolicyFilter, setSelectedFirewallPolicyFilter] = useState(firewallPoliciesFilterMenu[0].value)
    const [selectedFirewallPolicyFilterValue, setSelectedFirewallPolicyFilterValue] = useState("")
    const [firewallPoliciesFilter, setFirewallPoliciesFilter] = useState([...firewallPoliciesFilterMenu])


    const [projects, setProjects] = useState([])

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const firewallPoliciesUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.firewallPoliciesUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleTabChange = (tab_name) => {
        setCurrentTab(tab_name)
    }

    const handleSelectedSharedStateChange = (event) => {
        setSelectedSharedState(event.target.checked)
    }
    const handleSelectedAuditedStateChange = (event) => {
        setSelectedAuditedState(event.target.checked)
    }

    const handleSharedStateChangeDialogClose = () => {
        setSharedStateChangeDialogOpen(false)
    }

    const handleAuditedStateChangeDialogClose = () => {
        setAuditedStateChangeDialogOpen(false)
    }

    const handleSharedStateChangeDialogOpen = (fg_list) => {
        const selected_list = firewallPoliciesData.filter(f => 
            fg_list.includes(f.id))
        setSelectedFirewallPolicies([...selected_list])
        setSharedStateChangeDialogOpen(true)
    }

    const handleAuditedStateChangeDialogOpen = (fg_list) => {
        const selected_list = firewallPoliciesData.filter(f => 
            fg_list.includes(f.id))
        setSelectedFirewallPolicies([...selected_list])
        setAuditedStateChangeDialogOpen(true)
    }

    const handleChangeAuditedState = async (fw_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallPoliciesUrl}/${fw_id}`
            const method = "PUT"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                data: {firewall_policy: {audited: selectedAuditedState}},
                token: project_token
            })

            if (nt_response.status_code === firewallUrlResponses.put.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    }

    const onChangeAuditedState = async () => {
        handleAuditedStateChangeDialogClose()
        let err = []
        for (let n in selectedFirewallPolicies) {
            const resp = await handleChangeAuditedState(selectedFirewallPolicies[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const handleChangeSharedState = async (fw_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallPoliciesUrl}/${fw_id}`
            const method = "PUT"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                data: {firewall_policy: {shared: selectedSharedState}},
                token: project_token
            })

            if (nt_response.status_code === firewallUrlResponses.put.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    }

    const onChangeSharedState = async () => {
        handleSharedStateChangeDialogClose()
        let err = []
        for (let n in selectedFirewallPolicies) {
            const resp = await handleChangeSharedState(selectedFirewallPolicies[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const handleFirewallPolicyFilteredSearch = () => {
        if (selectedFirewallPolicyFilter && selectedFirewallPolicyFilterValue) {
            setFirewallPolicyFilterQueryParams(`${selectedFirewallPolicyFilter}=${selectedFirewallPolicyFilterValue}`)
        } else {
            setFirewallPolicyFilterQueryParams("")
        }
        handleDataFetch()
    }

    const handleFirewallPoliciesDataFormatting = useCallback(() => {
        const formatted_data = firewallPoliciesData.map((item) => {
            let new_item = {...item}
            const project = projects.filter(p => p.id === item.project_id)
            if (project.length > 0) {
                new_item.project_id = project[0].name
            } else {
                new_item.project_id = "-"
            }

            new_item.firewall_rules = item.firewall_rules.length

            return new_item
        })
        setFirewallPolicies(formatted_data)
    },[
        firewallPoliciesData,
        projects
    ])

    const handleFirewallPolicyFilterReset = () => {
        setSelectedFirewallPolicyFilter(firewallPoliciesFilterMenu[0].value)
        setSelectedFirewallPolicyFilterValue("")
        setFirewallPolicyFilterQueryParams("")
        handleDataFetch()
    }

    const handleDetailCardOpen = useCallback((index) => {
        setSelectedFirewallPolicy(firewallPoliciesData[index].id)
        setSelectedRow(firewallPoliciesData[index])
        setTimeout(() => setDetailCardOpen(true),100)
    },[firewallPoliciesData]);

    const handleDetailCardClose = () => {
        setTimeout(() => setDetailCardOpen(false),100)
        setSelectedRow(null)
        setSelectedFirewallPolicy(null)
        navigate(location.path,{})
    };

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleFirewallPoliciesSorting = (field,direction) => {
        const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
        setFirewallPoliciesSortParams(sort_param)
        handleDataFetch()
    }

    const handleFirewallPolicyDelete = async (n_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallPoliciesUrl}/${n_id}`
            const method = "DELETE"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                token: project_token
            })

            if (nt_response.status_code === firewallUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    };

    const onFirewallPolicyDelete = async () => {
        handleFirewallPolicyDeleteConfirmDialogClose()
        let err = []
        for (let n in selectedFirewallPolicies) {
            const resp = await handleFirewallPolicyDelete(selectedFirewallPolicies[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDetailCardClose()
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onFirewallPolicyDeleteConfirm = (n_list) => {
        const selected_n_list = firewallPoliciesData.filter(n => 
            n_list.includes(n.id))
        setSelectedFirewallPolicies([...selected_n_list])
        setFirewallPolicyDeleteConfirmDialogOpen(true)
    }

    const handleFirewallPolicyDeleteConfirmDialogClose = () => {
        setFirewallPolicyDeleteConfirmDialogOpen(false)
    }

    const getFirewallPoliciesActionsList = () => {
        let firewallPolicy_actions = []
        let new_action = {}
        new_action["value"] = "audited_state_update"
        new_action["action"] = handleAuditedStateChangeDialogOpen
        new_action["keyword"] = "auditedStateChangeActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        firewallPolicy_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "share_state_update"
        new_action["action"] = handleSharedStateChangeDialogOpen
        new_action["keyword"] = "shareStateChangeActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        firewallPolicy_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "firewallPolicy_delete"
        new_action["action"] = onFirewallPolicyDeleteConfirm
        new_action["keyword"] = "firewallPolicyDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        firewallPolicy_actions.push({...new_action})
        
        return firewallPolicy_actions
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallPoliciesUrl}?${firewallPolicyFilterQueryParams}${firewallPoliciesSortParams}`
                    const method = "GET"
                    const firewallPolicy_response = await openstackRequest({url:url, method:method, token: project_token})
                    if (firewallPolicy_response.status_code === firewallUrlResponses.get.success_response.status_code) {
                        setFirewallPoliciesData(firewallPolicy_response.data.firewall_policies)
                        if (selectedFirewallPolicy) {
                            const selected_firewallPolicy = firewallPolicy_response.data.firewall_policies.filter(item => item.id === selectedFirewallPolicy)
                            if (selected_firewallPolicy.length > 0) {
                                setSelectedRow(selected_firewallPolicy[0])
                            }
                        }
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        firewallPoliciesUrl, 
        firewallPolicyFilterQueryParams,
        dataFetchingRequired,
        defaultAdminProject,
        firewallPoliciesSortParams,
        selectedFirewallPolicy
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === projectUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        if (firewallPoliciesData.length > 0) {
            handleFirewallPoliciesDataFormatting()
        }
    },[
        firewallPoliciesData,
        handleFirewallPoliciesDataFormatting
    ])

    useEffect(() => {
        let projects_filter = []
        if (projects.length > 0) {
            projects_filter = projects.map(project => {
                return {keyword: project.name, value: project.id, default: false}
            })
        }

        
        let firewallPolicy_filter_menu = firewallPoliciesFilterMenu.map(nt => {
            let new_item = {...nt}
            if (nt.value === "project_id") {
                new_item.items = [...projects_filter]
            }
            return new_item
        })

        setFirewallPoliciesFilter(firewallPolicy_filter_menu)
    },[
        firewallPoliciesData,
        projects
    ])

    useEffect(() => {
        if (!dataFetchingRequired && location.state ) {
            const firewallPolicy_id = location.state ? location.state.firewallPolicy_id : null
                const firewallPolicy_index = firewallPoliciesData.findIndex(v => v.id === firewallPolicy_id);
                if (firewallPolicy_index !== -1) {
                    setTimeout(() => handleDetailCardOpen(firewallPolicy_index), 600)
                }
        }
    },[
        dataFetchingRequired,
        firewallPoliciesData,
        handleDetailCardOpen,
        location
    ])

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <FirewallPoliciesSubheaderV20 
                selectedFilter={selectedFirewallPolicyFilter} 
                setSelectedFilter={setSelectedFirewallPolicyFilter}
                selectedFilterValue={selectedFirewallPolicyFilterValue}
                setSelectedFilterValue={setSelectedFirewallPolicyFilterValue}
                handleDataFetch={handleDataFetch}
                filterMenu={firewallPoliciesFilter}
                handleFilteredSearch={handleFirewallPolicyFilteredSearch}
                handleFilterReset={handleFirewallPolicyFilterReset}
                currentTab={currentTab}
                handleTabChange={handleTabChange}
                projects={projects}
                firewallPolicies={firewallPolicies}
            />
        {isLoading && <CustomBackdrop open={isLoading} />}
        {!isLoading &&
            <FirewallPoliciesTableV20 
                firewallPoliciesData={firewallPolicies}
                setFirewallPoliciesData={setFirewallPolicies}
                handleRowSelection={handleDetailCardOpen}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getFirewallPoliciesActionsList()}
                sortHandler={handleFirewallPoliciesSorting}
                projects={projects}
            />
        }
        {selectedRow !== null && 
            <CustomSideDrawer 
                open={detailCardOpen}
                widthWeight={WIDTH_WEIGHT}
                handleDrawerOpen={handleDetailCardOpen}
                handleDrawerClose={handleDetailCardClose}
            > 
                <FirewallPolicyDetailV20
                    selectedRow={selectedRow}
                    widthWeight={WIDTH_WEIGHT}
                    handleDataFetch={handleDataFetch}
                    handleDelete={onFirewallPolicyDeleteConfirm}
                    projects={projects}
                    firewallPolicies={firewallPoliciesData}
                    auditedStateChange={handleAuditedStateChangeDialogOpen}
                    sharedStateChange={handleSharedStateChangeDialogOpen}
                />
            </CustomSideDrawer>
        }
        <CustomDialog
            open={firewallPolicyDeleteConfirmDialogOpen}
            onClose={handleFirewallPolicyDeleteConfirmDialogClose}
            dialogTitle={{
                title: defaultTexts.fwPolicypDeleteConfirmTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `${defaultTexts.fwPolicyDeleteConfirmText}: [${selectedFirewallPolicies.map(v => v.name).toString()}]`, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onFirewallPolicyDelete, 
                sx: {color: 'primary.main'}}]}
        />
        <CustomDialog
            open={auditedStateChangeDialogOpen}
            onClose={handleAuditedStateChangeDialogClose}
            dialogTitle={{
                title: defaultTexts.changeAuditedStateTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onChangeAuditedState, 
                sx: {color: 'primary.main'}}]}
        >
            <CustomCheckboxField
                currentValue={selectedAuditedState}
                setCurrentValue={handleSelectedAuditedStateChange}
                label={defaultTexts.auditedStateFormFieldLabel}
                default_value={false}
            />
        </CustomDialog>
        <CustomDialog
            open={sharedStateChangeDialogOpen}
            onClose={handleSharedStateChangeDialogClose}
            dialogTitle={{
                title: defaultTexts.changeSharedStateTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onChangeSharedState, 
                sx: {color: 'primary.main'}}]}
        >
            <CustomCheckboxField
                currentValue={selectedSharedState}
                setCurrentValue={handleSelectedSharedStateChange}
                label={defaultTexts.sharedFormFieldLabel}
                default_value={false}
            />
        </CustomDialog>
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts[error.error_title], 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts[error.error_message]}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
    </Box>
    )
};

export default FirewallPoliciesWrapperV20;