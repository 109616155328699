import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import WrapperBox from '../../../../../_common/WrapperBox';
import AddButton from '../../../../../_common/AddButton';
import FormGroup from '@mui/material/FormGroup';
import Constants from '../../../../../../config/constants';
import FilterButton from '../../../../../_common/FilterButton';
import CustomDialog from '../../../../../_common/CustomDialog';
import { 
    openStackServices, 
    networkNeutronConstants
} from '../../../../../../config/openStackConstants';
import { firewallPolicyDataForm } from 
'../../../../../../_data/openstack/neutron/fwaas/v2.0';
import { getFormFieldComponent } from 
'../../../../../_common/_form_fields/form_helpers';
import { 
    getXAuthTokenProjectScope,
    openstackRequest 
} from '../../../../../../_network/openstack_request';
import { firewallUrl as firewallUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/fwaas/v2.0';
import Grid from '@mui/material/Grid';


const SERVICE_NAME = openStackServices.networkService

const FirewallPoliciesSubheaderV20 = (props) => {
    const { currentTab, handleTabChange } = props
    const { selectedFilter, setSelectedFilter } = props
    const { selectedFilterValue, setSelectedFilterValue } = props
    const { handleDataFetch } = props
    const { filterMenu, projects, firewallPolicies } = props
    const { handleFilteredSearch, handleFilterReset } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [error, setError] = useState()
    const [successFirewallPolicyAdd, setSuccessFirewallPolicyAdd] = useState()
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [formData, setFormData] = useState({})
    const [formDataOptions, setFormDataOptions] = useState({});

    const theme = useTheme();

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const firewallPoliciesUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.firewallPoliciesUrl)[0].url)

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleSuccessDialogClose = () => {
        setSuccessFirewallPolicyAdd(null);
        setSuccessDialogOpen(false);
    }

    const handleAddFirewallPolicyFormReset = () => {
        setFormDataOptions({})
        let new_form_data = {}
        for (const n in firewallPolicyDataForm) {
            if (
                firewallPolicyDataForm[n].field_type === "string" || 
                firewallPolicyDataForm[n].field_type === "select"
                ) {
                new_form_data[firewallPolicyDataForm[n].field_key] = firewallPolicyDataForm[n].default_value ? 
                firewallPolicyDataForm[n].default_value : 
                ""
            } else if (firewallPolicyDataForm[n].field_type === "bool") {
                new_form_data[firewallPolicyDataForm[n].field_key] = firewallPolicyDataForm[n].default_value ? 
                firewallPolicyDataForm[n].default_value : 
                false
            }
        }
        setFormData(new_form_data)
    }

    const handleFormDataChange = (event,field_key) => {
        setFormDataOptions({})
        let new_form_data = {...formData}
        if (firewallPolicyDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (firewallPolicyDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setFormData(new_form_data)
    }


    const getDataForm = () => {
        let form = [...firewallPolicyDataForm]
        if (!formData.project_id) {
            form = form.filter(item => item.field_key !== "ingress_firewall_policy_id" && 
                item.field_key !== "egress_firewall_policy_id"
            )
        }
        return (
            <FormGroup>
                {form.map(field => {
                    let form_field_options = {...formDataOptions[field.field_key]}
                    if (field.field_key === "project_id") {
                        const projects_filter = projects.map(p => {
                            return {keyword: p.name, value: p.id, default: false}
                        })
                        form_field_options["items"] = [...projects_filter]
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = true
                    } else if (field.field_key === "ingress_firewall_policy_id") {
                        const in_policies = firewallPolicies.filter(fp => fp.project_id === formData.project_id)
                        const in_filter = in_policies.map(p => {
                            return {keyword: p.name, value: p.id, default: false}
                        })
                        
                        form_field_options["items"] = [...in_filter]
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = true
                    } else if (field.field_key === "egress_firewall_policy_id") {
                        const out_policies = firewallPolicies.filter(fp => fp.project_id === formData.project_id)
                        const out_filter = out_policies.map(p => {
                            return {keyword: p.name, value: p.id, default: false}
                        })
                        
                        form_field_options["items"] = [...out_filter]
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = true
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            formData,
                            handleFormDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleFormDataValidation = () => {
        let validation_faild = true
        let updatedDataFormOptions = {...formDataOptions}
        for (let n in firewallPolicyDataForm) {
            if (firewallPolicyDataForm[n].required && !formData[firewallPolicyDataForm[n].field_key]) {
                validation_faild = false
                updatedDataFormOptions[firewallPolicyDataForm[n].field_key] = {}
                updatedDataFormOptions[firewallPolicyDataForm[n].field_key]["error"] = true
                updatedDataFormOptions[firewallPolicyDataForm[n].field_key]["errorText"] = defaultTexts[firewallPolicyDataForm[n].error_label]
            }
        }

        setFormDataOptions(updatedDataFormOptions)
        return validation_faild
    }

    const onAddFirewallPolicy = async () => {
        const validateFormData = handleFormDataValidation()
        if (validateFormData) {
            let data = {...formData}
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallPoliciesUrl}`
                const method = "POST"
                const firewallPolicy_response = await openstackRequest({
                    url: url, 
                    method: method, 
                    data: {firewall_policy: data},
                    token: project_token
                })
                if (firewallPolicy_response.status_code === firewallUrlResponses.post.success_response.status_code) {
                    handleDataFetch()
                    handleAddFirewallPolicyFormReset()
                    setFormDataOptions({})
                } else {
                    const error_response = firewallUrlResponses.post_async.error_response.filter(
                        error_item => error_item.status_code === firewallPolicy_response.status_code)
                    if (error_response.length > 0) {
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: firewallPolicy_response.error
                        }
                        setError(errorObject)
                    } else {
                        const error_response = firewallUrlResponses.post.error_response.filter(
                            error_item => error_item.status_code === "unknown")
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: firewallPolicy_response.error
                        }
                        setError(errorObject)
                    }
                }
            }
        }
        return validateFormData
    }

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessDialogOpen(true)
    },[successFirewallPolicyAdd]);

    useEffect(() => {
        if (Object.keys(formData).length === 0) {
            let new_form_data = {}
            for (const n in firewallPolicyDataForm) {
                if (
                    firewallPolicyDataForm[n].field_type === "string" || 
                    firewallPolicyDataForm[n].field_type === "select"
                    ) {
                    new_form_data[firewallPolicyDataForm[n].field_key] = firewallPolicyDataForm[n].default_value ? 
                    firewallPolicyDataForm[n].default_value : 
                    ""
                } else if (firewallPolicyDataForm[n].field_type === "bool") {
                    new_form_data[firewallPolicyDataForm[n].field_key] = firewallPolicyDataForm[n].default_value ? 
                    firewallPolicyDataForm[n].default_value : 
                    false
                }
            }
            setFormData(new_form_data)
        }
    },[formData]);

    return (
        <WrapperBox 
            sx={{
                flexDirection: "row", 
                justifyContent: 'space-between',
                mt: 1
            }}>
            <Grid 
                container 
                justifyContent='space-between' 
                alignItems="center">
                <Grid item>
            <AddButton 
                getDataForm={getDataForm}               
                onSubmit={onAddFirewallPolicy}
                formReset={handleAddFirewallPolicyFormReset}
                customTexts={{
                    title: defaultTexts.addFirewallPolicyDialogTitle
                }}
            />
            </Grid>
            <Grid item sx={{flexGrow: 1}}>
            <Button
                onClick={() => handleTabChange("firewall_groups")}
                sx={{
                    color: currentTab === "firewall_groups" ? 
                    "lightBlue" : 
                    theme.palette.text.primary,
                    "&:hover": {
                        backgroundColor: "lightBlue",
                        color: "white"
                    }
                }}
            >
                {defaultTexts.firewallGroupsTabButtonText}
            </Button>
            <Button
                onClick={() => handleTabChange("firewall_policies")}
                sx={{
                    color: currentTab === "firewall_policies" ? 
                    "lightBlue" : 
                    theme.palette.text.primary,
                    "&:hover": {
                        backgroundColor: "lightBlue",
                        color: "white"
                    }
                }}
            >
                {defaultTexts.firewallPoliciesTabButtonText}
            </Button>
            <Button
                onClick={() => handleTabChange("firewall_rules")}
                sx={{
                    color: currentTab === "firewall_rules" ? 
                    "lightBlue" : 
                    theme.palette.text.primary,
                    "&:hover": {
                        backgroundColor: "lightBlue",
                        color: "white"
                    }
                }}
            >
                {defaultTexts.firewallRulesTabButtonText}
            </Button>
                </Grid>
                <Grid item>
                <FilterButton 
                    choice_mode={Constants.simple_filter_type}
                    currentFilter={selectedFilter}
                    setCurrentFilter={setSelectedFilter}
                    filter_menu_titles={defaultTexts}
                    filter_menu={filterMenu}
                    currentFilterValue={selectedFilterValue}
                    setCurrentFilterValue={setSelectedFilterValue}
                    onFilterSubmit={handleFilteredSearch}
                    onFilterReset={handleFilterReset}
                />
                </Grid>
            </Grid>
            {successFirewallPolicyAdd && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[successFirewallPolicyAdd.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successFirewallPolicyAdd.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </WrapperBox>
    )
};

export default FirewallPoliciesSubheaderV20