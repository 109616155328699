import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import WrapperBox from '../../../../../_common/WrapperBox';
import AddButton from '../../../../../_common/AddButton';
import Stack from '@mui/material/Stack';
import FormGroup from '@mui/material/FormGroup';
import Constants from '../../../../../../config/constants';
import FilterButton from '../../../../../_common/FilterButton';
import CustomDialog from '../../../../../_common/CustomDialog';
import { 
    openStackServices, 
    networkNeutronConstants
} from '../../../../../../config/openStackConstants';
import { addressGroupDataForm, addressGroupForm } from 
'../../../../../../_data/openstack/neutron/security/v2.0';
import { getFormFieldComponent } from 
'../../../../../_common/_form_fields/form_helpers';
import { 
    getXAuthTokenProjectScope,
    openstackRequest 
} from '../../../../../../_network/openstack_request';
import { securityUrl as securityUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/security/v2.0';
import Grid from '@mui/material/Grid';
import PlusButton from '../../../../../_common/PlusButton';
import MinusButton from '../../../../../_common/MinusButton';
import CustomText from '../../../../../_common/CustomText';


const SERVICE_NAME = openStackServices.networkService

const AddressGroupsSubheaderV20 = (props) => {
    const { currentTab, handleTabChange } = props
    const { selectedFilter, setSelectedFilter } = props
    const { selectedFilterValue, setSelectedFilterValue } = props
    const { handleDataFetch } = props
    const { filterMenu, projects } = props
    const { handleFilteredSearch, handleFilterReset } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [error, setError] = useState()
    const [successAddressGroupAdd, setSuccessAddressGroupAdd] = useState()
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [formData, setFormData] = useState({})
    const [formDataOptions, setFormDataOptions] = useState({});
    const [addressList, setAddressList] = useState([]);

    const theme = useTheme();

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const addressGroupsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.addressGroupsUrl)[0].url)

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleSuccessDialogClose = () => {
        setSuccessAddressGroupAdd(null);
        setSuccessDialogOpen(false);
    }

    const handleAddAddressGroupFormReset = () => {
        setFormDataOptions({})
        let new_form_data = {}
        for (const n in addressGroupDataForm) {
            if (
                addressGroupDataForm[n].field_type === "string" || 
                addressGroupDataForm[n].field_type === "select"
                ) {
                new_form_data[addressGroupDataForm[n].field_key] = addressGroupDataForm[n].default_value ? 
                addressGroupDataForm[n].default_value : 
                ""
            } else if (addressGroupDataForm[n].field_type === "bool") {
                new_form_data[addressGroupDataForm[n].field_key] = addressGroupDataForm[n].default_value ? 
                addressGroupDataForm[n].default_value : 
                false
            }
        }
        setFormData(new_form_data)
        setAddressList([])
    }

    const handleAddressGroupFormDataChange = (event, field_key, index) => {
        let new_form_data = [...addressList]
        new_form_data[index][field_key] = event.target.value.trim()
        setAddressList(new_form_data)
    }

    const handleFormDataChange = (event,field_key) => {
        setFormDataOptions({})
        let new_form_data = {...formData}
        if (addressGroupDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (addressGroupDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setFormData(new_form_data)
    }

    const handleAddressAdd = () => {
        let new_form_data = {}
        new_form_data["dns_nameserver"] = ""
        const updated_data = [...addressList]
        updated_data.push(new_form_data)
        setAddressList(updated_data)
    }

    const handleAddressRemove = () => {
        let new_data = [...addressList]
        new_data.pop()
        setAddressList(new_data)
    }


    const getDataForm = () => {
        let form = [...addressGroupDataForm]
        
        return (
            <FormGroup>
                {form.map(field => {
                    let form_field_options = {...formDataOptions[field.field_key]}
                    if (field.field_key === "project_id") {
                        const projects_filter = projects.map(p => {
                            return {keyword: p.name, value: p.id, default: false}
                        })
                        form_field_options["items"] = [...projects_filter]
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = true
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            formData,
                            handleFormDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
                {addressList.map((serv,index) => {
                    return (
                        <div key={index}>
                        <CustomText>{defaultTexts.addressFormFieldLabel} {index + 1}</CustomText>
                            {addressGroupForm.map(item => {
                                return (
                                    getFormFieldComponent(
                                        item,
                                        serv,
                                        handleAddressGroupFormDataChange,
                                        defaultTexts[item.label],
                                        {index: index}
                                    )
                                )
                            })}
                        </div>
                    )
                    })}
                    {addressList.length > 0 && <MinusButton 
                        style={{ transform: 'scale(0.7)' }}
                        sx={{height: 5}}
                        onClick={handleAddressRemove}
                    />}
                    {<Stack 
                        direction="row" 
                        spacing={2} 
                        alignItems="center"
                        onClick={handleAddressAdd}
                        sx={{cursor: "pointer"}}
                    >
                        <PlusButton 
                            style={{ transform: 'scale(0.7)' }}
                            sx={{height: 5}}
                            onClick={handleAddressAdd}
                        />
                        <CustomText>
                            {defaultTexts.addAddressFormFieldLabel}
                        </CustomText>
                    </Stack>}
            </FormGroup>
        )
    }

    const handleFormDataValidation = () => {
        let validation_faild = true
        let updatedDataFormOptions = {...formDataOptions}
        for (let n in addressGroupDataForm) {
            if (addressGroupDataForm[n].required && !formData[addressGroupDataForm[n].field_key]) {
                validation_faild = false
                updatedDataFormOptions[addressGroupDataForm[n].field_key] = {}
                updatedDataFormOptions[addressGroupDataForm[n].field_key]["error"] = true
                updatedDataFormOptions[addressGroupDataForm[n].field_key]["errorText"] = defaultTexts[addressGroupDataForm[n].error_label]
            }
        }

        setFormDataOptions(updatedDataFormOptions)
        return validation_faild
    }

    const onAddAddressGroup = async () => {
        const validateFormData = handleFormDataValidation()
        if (validateFormData) {
            let data = {...formData}
            if (addressList.length > 0) {
                let addresses = addressList.filter(item => item.address && item.address.length > 0)
                addresses = addresses.map(item => item.address)
                data["addresses"] = addresses
            }
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${neutronServiceDomain}/${neutronServiceVersion}/${addressGroupsUrl}`
                const method = "POST"
                const addressGroup_response = await openstackRequest({
                    url: url, 
                    method: method, 
                    data: {address_group: data},
                    token: project_token
                })
                if (addressGroup_response.status_code === securityUrlResponses.post.success_response.status_code) {
                    handleDataFetch()
                    handleAddAddressGroupFormReset()
                    setFormDataOptions({})
                } else {
                    const error_response = securityUrlResponses.post_async.error_response.filter(
                        error_item => error_item.status_code === addressGroup_response.status_code)
                    if (error_response.length > 0) {
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: addressGroup_response.error
                        }
                        setError(errorObject)
                    } else {
                        const error_response = securityUrlResponses.post.error_response.filter(
                            error_item => error_item.status_code === "unknown")
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: addressGroup_response.error
                        }
                        setError(errorObject)
                    }
                }
            }
        }
        return validateFormData
    }

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessDialogOpen(true)
    },[successAddressGroupAdd]);

    useEffect(() => {
        if (Object.keys(formData).length === 0) {
            let new_form_data = {}
            for (const n in addressGroupDataForm) {
                if (
                    addressGroupDataForm[n].field_type === "string" || 
                    addressGroupDataForm[n].field_type === "select"
                    ) {
                    new_form_data[addressGroupDataForm[n].field_key] = addressGroupDataForm[n].default_value ? 
                    addressGroupDataForm[n].default_value : 
                    ""
                } else if (addressGroupDataForm[n].field_type === "bool") {
                    new_form_data[addressGroupDataForm[n].field_key] = addressGroupDataForm[n].default_value ? 
                    addressGroupDataForm[n].default_value : 
                    false
                }
            }
            setFormData(new_form_data)
        }
    },[formData]);

    return (
        <WrapperBox 
            sx={{
                flexDirection: "row", 
                justifyContent: 'space-between',
                mt: 1
            }}>
            <Grid 
                container 
                justifyContent='space-between' 
                alignItems="center">
                <Grid item>
            <AddButton 
                getDataForm={getDataForm}               
                onSubmit={onAddAddressGroup}
                formReset={handleAddAddressGroupFormReset}
                customTexts={{
                    title: defaultTexts.addAddressGroupDialogTitle
                }}
            />
            </Grid>
            <Grid item sx={{flexGrow: 1}}>
            <Button
                onClick={() => handleTabChange("security_groups")}
                sx={{
                    color: currentTab === "security_groups" ? 
                    "lightBlue" : 
                    theme.palette.text.primary,
                    "&:hover": {
                        backgroundColor: "lightBlue",
                        color: "white"
                    }
                }}
            >
                {defaultTexts.securityGroupsTabButtonText}
            </Button>
            <Button
                onClick={() => handleTabChange("address_groups")}
                sx={{
                    color: currentTab === "address_groups" ? 
                    "lightBlue" : 
                    theme.palette.text.primary,
                    "&:hover": {
                        backgroundColor: "lightBlue",
                        color: "white"
                    }
                }}
            >
                {defaultTexts.addressGroupsTabButtonText}
            </Button>
            <Button
                onClick={() => handleTabChange("default_rules")}
                sx={{
                    color: currentTab === "default_rules" ? 
                    "lightBlue" : 
                    theme.palette.text.primary,
                    "&:hover": {
                        backgroundColor: "lightBlue",
                        color: "white"
                    }
                }}
            >
                {defaultTexts.defaultRulesTabButtonText}
            </Button>
                </Grid>
                <Grid item>
                <FilterButton 
                    choice_mode={Constants.simple_filter_type}
                    currentFilter={selectedFilter}
                    setCurrentFilter={setSelectedFilter}
                    filter_menu_titles={defaultTexts}
                    filter_menu={filterMenu}
                    currentFilterValue={selectedFilterValue}
                    setCurrentFilterValue={setSelectedFilterValue}
                    onFilterSubmit={handleFilteredSearch}
                    onFilterReset={handleFilterReset}
                />
                </Grid>
            </Grid>
            {successAddressGroupAdd && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[successAddressGroupAdd.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successAddressGroupAdd.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </WrapperBox>
    )
};

export default AddressGroupsSubheaderV20