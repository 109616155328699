import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CustomDialog from '../../../../../_common/CustomDialog';
import useWindowDimensions from '../../../../../_common/WindowDimensions';
import Dimensions from '../../../../../../config/dimensions';
import CustomSideDrawer from '../../../../../_common/CustomSideDrawer';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup'
import { openStackServices, 
        networkNeutronConstants, 
        identityKeystonConstants
} from '../../../../../../config/openStackConstants';
import RoutersSubheaderV20 from './routersSubheaderV2.0';
import RoutersTableV20 from './routersTableV2.0';
import RouterDetailV20 from './routerDetailV2.0';
import { routersFilterMenu } 
from '../../../../../../_data/openstack/neutron/routers/v2.0';
import { 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../../_network/openstack_request';
import { routersUrl as routerUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/routers/v2.0';
import { projectsUrl as projectUrlResponses} from 
'../../../../../../_api_responses/openstack/identity/projects/v3';

const SERVICE_NAME = openStackServices.networkService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const RoutersWrapperV20 = (props) => {
    const { navigate, location, changeMenuActiveTab } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const { virtualIPsSupported } = props
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const {currentTab, setCurrentTab} = props
    const [routersData, setRoutersData] = useState([])
    const [routers, setRouters] = useState([])
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = width < Dimensions.tablet_mini.width ? 0.9 : 0.8
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedRouter, setSelectedRouter] = useState(null);
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [currentAction, setCurrentAction] = useState("");
    const [routerDeleteConfirmDialogOpen, setRouterDeleteConfirmDialogOpen] = useState(false);
    const [selectedRouters, setSelectedRouters] = useState([])
    const [routersSortParams, setRoutersSortParams] = useState("")
    
    const [routerFilterQueryParams, setRouterFilterQueryParams] = useState("")
    const [selectedRouterFilter, setSelectedRouterFilter] = useState(routersFilterMenu[0].value)
    const [selectedRouterFilterValue, setSelectedRouterFilterValue] = useState("")
    const [routersFilter, setRoutersFilter] = useState([...routersFilterMenu])

    const [projects, setProjects] = useState([])
    const [networks, setNetworks] = useState([])
    const [externalNetworks, setExternalNetworks] = useState([])
    const [selectedAdminState,setSelectedAdminState] = useState(true)
    const [changeAdminStateDialogOpen, setChangeAdminStateDialogOpen] = useState(false)

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const routersUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.routersUrl)[0].url)
    const networksUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.networksUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleTabChange = (tab_name) => {
        setCurrentTab(tab_name)
    }

    const handleRouterFilteredSearch = () => {
        if (selectedRouterFilter && selectedRouterFilterValue) {
            setRouterFilterQueryParams(`${selectedRouterFilter}=${selectedRouterFilterValue}`)
        } else {
            setRouterFilterQueryParams("")
        }
        handleDataFetch()
    }

    const handleRoutersDataFormatting = useCallback(() => {
        const formatted_data = routersData.map((item) => {
            let new_item = {...item}
            const project = projects.filter(p => p.id === item.project_id)
            if (project.length > 0) {
                new_item.project_id = project[0].name
            } else {
                new_item.project_id = "-"
            }
            if (item.external_gateway_info) {
                const network = networks.filter(n => n.id === item.external_gateway_info.network_id)
                if (network.length > 0) {
                    new_item["external_network"] = network[0].name
                } else {
                    new_item["external_network"] = "-"
                }
            }
            
            return new_item
        })
        setRouters(formatted_data)
    },[
        routersData,
        projects,
        networks
    ])

    const handleRouterFilterReset = () => {
        setSelectedRouterFilter(routersFilterMenu[0].value)
        setSelectedRouterFilterValue("")
        setRouterFilterQueryParams("")
        handleDataFetch()
    }

    const handleNavigateToNetwork = (network_id,tab_name) => {
        handleDetailCardClose()
        changeMenuActiveTab(tab_name)
        navigate("/networks",{state: {network_id: network_id}})
    }

    const handleDetailCardOpen = useCallback((index) => {
        setSelectedRouter(routersData[index].id)
        setSelectedRow(routersData[index])
        setTimeout(() => setDetailCardOpen(true),100)
    },[routersData]);

    const handleDetailCardClose = () => {
        setTimeout(() => setDetailCardOpen(false),100)
        setSelectedRow(null)
        setSelectedRouter(null)
        navigate(location.path,{})
    };

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleRoutersSorting = (field,direction) => {
        const sort_param = `&&sort_key=${field}&&sort_dir=${direction}`
        setRoutersSortParams(sort_param)
        handleDataFetch()
    }

    const handleRouterDelete = async (n_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${routersUrl}/${n_id}`
            const method = "DELETE"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                token: project_token
            })

            if (nt_response.status_code === routerUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    };

    const handleAdminStateUpdate = async (n_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${routersUrl}/${n_id}`
            const method = "PUT"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                data: {router: {admin_state_up: selectedAdminState}},
                token: project_token
            })

            if (nt_response.status_code === routerUrlResponses.put.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    }
    const onAdminStateUpdate = async() => {
        handleChangeAdminStateDialogClose()
        let err = []
        for (let n in selectedRouters) {
            const resp = await handleAdminStateUpdate(selectedRouters[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onRouterDelete = async () => {
        handleRouterDeleteConfirmDialogClose()
        let err = []
        for (let n in selectedRouters) {
            const resp = await handleRouterDelete(selectedRouters[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDetailCardClose()
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onRouterDeleteConfirm = (n_list) => {
        const selected_n_list = routersData.filter(n => 
            n_list.includes(n.id))
        setSelectedRouters([...selected_n_list])
        setRouterDeleteConfirmDialogOpen(true)
    }

    const handleRouterDeleteConfirmDialogClose = () => {
        setRouterDeleteConfirmDialogOpen(false)
    }

    const handleChangeAdminStateDialogClose = () => {
        setChangeAdminStateDialogOpen(false)
    }

    const handleChangeAdminStateDialogOpen = (router_list) => {
        const selected_r_list = routersData.filter(r => 
            router_list.includes(r.id))
        setSelectedRouters([...selected_r_list])
        setChangeAdminStateDialogOpen(true)
    }

    const handleAdminStateChange = (event) => {
        setSelectedAdminState(event.target.checked)
    }

    const getRoutersActionsList = () => {
        let router_actions = []
        let new_action = {}
        new_action["value"] = "router_delete"
        new_action["action"] = onRouterDeleteConfirm
        new_action["keyword"] = "routerDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        router_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "admin_state_update"
        new_action["action"] = handleChangeAdminStateDialogOpen
        new_action["keyword"] = "changeAdminStateActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        router_actions.push({...new_action})
        
        return router_actions
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${neutronServiceDomain}/${neutronServiceVersion}/${routersUrl}?${routerFilterQueryParams}${routersSortParams}`
                    const method = "GET"
                    const router_response = await openstackRequest({url:url, method:method, token: project_token})
                    if (router_response.status_code === routerUrlResponses.get.success_response.status_code) {
                        setRoutersData(router_response.data.routers)
                        if (selectedRouter) {
                            const selected_router = router_response.data.routers.filter(item => item.id === selectedRouter)
                            if (selected_router.length > 0) {
                                setSelectedRow(selected_router[0])
                            }
                        }
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        routersUrl, 
        routerFilterQueryParams,
        dataFetchingRequired,
        defaultAdminProject,
        routersSortParams,
        selectedRouter
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${neutronServiceDomain}/${neutronServiceVersion}/${networksUrl}`
                const method = "GET"
                const network_response = await openstackRequest({url:url, method:method, token: project_token})
                if (network_response.status_code === routerUrlResponses.get.success_response.status_code) {
                    setNetworks(network_response.data.networks)
                    const external_networks = network_response.data.networks.filter(item => item["router:external"])
                    const external_networks_list = external_networks.map(n => {
                        return {keyword: n.name, value: n.id, default: false}
                    })
                    setExternalNetworks(external_networks_list)
                }
            }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        networksUrl, 
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === projectUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        if (routersData.length > 0) {
            handleRoutersDataFormatting()
        }
    },[
        routersData,
        handleRoutersDataFormatting
    ])

    useEffect(() => {
        let projects_filter = []
        if (projects.length > 0) {
            projects_filter = projects.map(project => {
                return {keyword: project.name, value: project.id, default: false}
            })
        }

        let networks_filter = []
        if (networks.length > 0) {
            networks_filter = networks.map(network => {
                return {keyword: network.name, value: network.id, default: false}
            })
        }
        
        let router_filter_menu = routersFilterMenu.map(nt => {
            let new_item = {...nt}
            if (nt.value === "project_id") {
                new_item.items = [...projects_filter]
            }
            if (nt.value === "network_id") {
                new_item.items = [...networks_filter]
            }
            return new_item
        })

        setRoutersFilter(router_filter_menu)
    },[
        routersData,
        projects,
        networks
    ])

    useEffect(() => {
        if (!dataFetchingRequired && location.state ) {
            const router_id = location.state ? location.state.router_id : null
                const router_index = routersData.findIndex(v => v.id === router_id);
                if (router_index !== -1) {
                    setTimeout(() => handleDetailCardOpen(router_index), 600)
                }
        }
    },[
        dataFetchingRequired,
        routersData,
        handleDetailCardOpen,
        location
    ])

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <RoutersSubheaderV20 
                virtualIPsSupported={virtualIPsSupported}
                selectedFilter={selectedRouterFilter} 
                setSelectedFilter={setSelectedRouterFilter}
                selectedFilterValue={selectedRouterFilterValue}
                setSelectedFilterValue={setSelectedRouterFilterValue}
                handleDataFetch={handleDataFetch}
                filterMenu={routersFilter}
                handleFilteredSearch={handleRouterFilteredSearch}
                handleFilterReset={handleRouterFilterReset}
                currentTab={currentTab}
                handleTabChange={handleTabChange}
                externalNetworks={externalNetworks}
                projects={projects}
            />
        {isLoading && <CustomBackdrop open={isLoading} />}
        {!isLoading &&
            <RoutersTableV20 
                routersData={routers}
                setRoutersData={setRouters}
                handleRowSelection={handleDetailCardOpen}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getRoutersActionsList()}
                sortHandler={handleRoutersSorting}
            />
        }
        {selectedRow !== null && 
            <CustomSideDrawer 
                open={detailCardOpen}
                widthWeight={WIDTH_WEIGHT}
                handleDrawerOpen={handleDetailCardOpen}
                handleDrawerClose={handleDetailCardClose}
            > 
                <RouterDetailV20
                    selectedRow={selectedRow}
                    widthWeight={WIDTH_WEIGHT}
                    handleDataFetch={handleDataFetch}
                    handleNavigateToNetwork={handleNavigateToNetwork}
                    handleDelete={onRouterDeleteConfirm}
                    onAdminStateChange={handleChangeAdminStateDialogOpen}
                    projects={projects}
                    networks={networks}
                />
            </CustomSideDrawer>
        }
        <CustomDialog
            open={routerDeleteConfirmDialogOpen}
            onClose={handleRouterDeleteConfirmDialogClose}
            dialogTitle={{
                title: defaultTexts.routerDeleteConfirmTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `${defaultTexts.routerDeleteConfirmText}: [${selectedRouters.map(v => v.name).toString()}]`, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onRouterDelete, 
                sx: {color: 'primary.main'}}]}
        />
        <CustomDialog
            open={changeAdminStateDialogOpen}
            onClose={handleChangeAdminStateDialogClose}
            dialogTitle={{
                title: defaultTexts.updateRouterAdminStateActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onAdminStateUpdate, 
                sx: {color: 'primary.main'}}]}
        >
            <FormGroup sx={{m:3}}>
                <FormLabel component="legend">
                    {defaultTexts.routerAdminStateFormFieldLabel}
                </FormLabel>
                <FormControlLabel
                    control={
                        <Switch 
                            checked={selectedAdminState} 
                            onChange={handleAdminStateChange} 
                            name="adminState" 
                        />
                    }
                    label={
                        selectedAdminState ? 
                        defaultTexts.upFormFieldLabel : 
                        defaultTexts.downFormFieldLabel 
                    }
                />
            </FormGroup>
        </CustomDialog>
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts[error.error_title], 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts[error.error_message]}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
    </Box>
    )
};

export default RoutersWrapperV20;