export const domainDataSchema = [
    {
        field_key: "name",
        is_id_field: true,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "enabled",
        is_id_field: false,
        field_type: "bool", 
        label: "enabledFormFieldLabel",
        value_on_true: "formValueYes",
        value_on_false: "formValueNo"
    },
    {
        field_key: "id",
        is_id_field: false,
        field_type: "string", 
        label: "idFormFieldLabel"
    },
    {
        field_key: "tags",
        is_id_field: false,
        field_type: "list",
        label: "tagsFormFieldLabel"
    }
]

export const domainDataForm = [
    {
        field_key: "name",
        field_type: "string", 
        required: true, 
        label: "nameFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string", 
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    },
    {
        field_key: "enabled",
        field_type: "bool", 
        required: false,
        label: "enabledFormFieldLabel",
        default_value: true
    }
]


export const domainDataUpdateForm = [
    {
        field_key: "name",
        field_type: "string", 
        required: false, 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "description",
        field_type: "string", 
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    },
    {
        field_key: "enabled",
        field_type: "bool", 
        required: false,
        label: "enabledFormFieldLabel"
    },
    {
        field_key: "tags",
        field_type: "list", 
        required: false, 
        label: "tagsFormFieldLabel",
        with_help_text: true,
        help_text: "listFormFieldHelpText"
    },
]


export const domainsfilterMenu = [
    {
        keyword: "filterName", 
        value: "name", 
        type: "text"
    },
    {
        keyword: "filterEnabled", 
        value: "enabled", 
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "filterChoiceYes", 
                value: "true", 
                default: true
            },
            {
                keyword: "filterChoiceNo", 
                value: "false", 
                default: false
            }
        ]
    }
];