import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Constants from '../../../config/constants'
import CustomDrawer from '../../_common/CustomDrawer';
import LoginForm from '../forms/loginForm';
import HomeContent from './homeContent';
import request from '../../../_network/request';
import { coreServiceURLs } from '../../../_network/apiUrls';
import { setClientAccountID } from '../../../store/reducers/profileSlice';
import { setUIColors, setLogo, setCaptchaConfig } from '../../../store/reducers/settingsSlice';
import Loading from '../../_common/Loading';

const HomeWrapper = (props) => {
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
    const [isLoading, setIsLoading] = useState(true)
    const [clientID, setClientID] = useState("")

    const location = window.location.hostname;
    const dispatch = useDispatch();

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    useEffect(() => {
        if (Constants.my_domain !== location) {
            const query_params = `?service_group=${Constants.config_service_group}&service_type=${Constants.config_service_type_frontend}&service=${Constants.config_service_custom_admin_ui}&service_domain=${location}`
            const url = `${Constants.core_service_domain}/${coreServiceURLs.getClientInitConfig}/${query_params}`
            let request_data = {
                url: url,
                method: "GET"
            }
                request(request_data).then((response) => {
                    if (response.status_code < 400) {
                        setClientID(response.data.client_id)
                        dispatch(setClientAccountID({
                            clientAccountID: response.data.client_id
                        }))
                        if (response.data.config_params.primary_color && response.data.config_params.secondary_color) {
                            dispatch(setUIColors({
                                primaryColor: response.data.config_params.primary_color,  
                                secondaryColor: response.data.config_params.secondary_color
                            }))
                        }
                        if (response.data.config_params.captcha_config) {
                            dispatch(setCaptchaConfig({
                                captchaConfig: response.data.config_params.captcha_config
                            }))
                        }
                        if (response.data.config_params.logo) {
                            dispatch(setLogo({
                                logo: response.data.config_params.logo
                            }))
                        }
                    }
                    handleLoading(false)
                }).catch((err) => {
                    handleLoading(false)
                    console.log(err)
            })
        } else {
            handleLoading(false)
        }
    },[location, dispatch]);
    
    if (isLoading) {
        return <Loading />
    }
    
    return (
        <div>
            {!isLoading && <CustomDrawer>
                {!isAuthenticated && <LoginForm client_id={clientID} />}
                {isAuthenticated && <HomeContent />}
            </CustomDrawer>}
        </div>
    )
}

export default HomeWrapper;