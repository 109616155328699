import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import CustomCard from '../../../_common/CustomCard';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import { FaUsers } from "react-icons/fa";
import { openstackRequest } from 
'../../../../_network/openstack_request';
import CustomText from '../../../_common/CustomText';
import { usersUrl as usersUrlResponses } from 
'../../../../_api_responses/openstack/identity/users/v3';
import { 
    identityKeystonConstants,
    openStackServices
} from '../../../../config/openStackConstants';

const SERVICE_NAME = openStackServices.identityService

const UsersGraphContentV3 = (props) => {
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
    const [usersData, setUsersData] = useState([]);

    const navigate = useNavigate();
    const theme = useTheme();

    if (!isAuthenticated) {
        navigate('/');
    }

    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const usersUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === identityServiceVersion)[0].urls.filter(
                url => url.keyword === identityKeystonConstants.usersUrl)[0].url)

    const purchasedServices = useSelector(state => 
        state.openstack.purchasedServices);

    const purchasedService = purchasedServices.filter(
        item => item.service === SERVICE_NAME)


    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${usersUrl}`
            const method = "GET"

            const users_response = await openstackRequest({url:url, method:method})
            if (users_response.status_code === usersUrlResponses.get.success_response.status_code) {
                setUsersData([...users_response.data.users])
            }
        })();
    },[
        identityServiceDomain, 
        identityServiceVersion, 
        usersUrl
    ]);

    if (purchasedService[0].config_params.api_version === "v3") {
        return (
            <CustomCard 
                cardWidth={'100%'} 
                cardHeight={120} 
                card_sx={{
                    border: theme.palette.mode === "dark" ? 0 : 1, 
                    boxShadow: theme.palette.mode === "dark" ? 0 : 1, 
                    backgroundColor: 
                        theme.palette.mode === "dark" ? 
                        undefined :
                        "vLightGray"
                }}
                box_sx={{pr: 2}}
            >
                <Stack
                    direction="row" 
                    alignItems="center" 
                    justifyContent="space-between"
                    spacing={0}
                >
                <Box sx={{
                    display: "flex", 
                    alignItems: "center", 
                    justifyContent: "center", 
                    borderRadius: 80, 
                    border: 1, 
                    py: 2, 
                    px: 2, 
                    m: 1}}
                >
                    <FaUsers  
                        size={28}
                    />
                </Box>
                <Stack
                    direction="column" 
                    alignItems="end" 
                    justifyContent="center"
                    spacing={0}
                >
                    <CustomText 
                        size="h6" 
                        sx={{
                            fontSize: 28,
                            color: theme.palette.mode === "dark" ? 
                            theme.palette.dashboardGreenDark :
                            theme.palette.dashboardGreen
                        }}
                    >
                        {usersData.length}
                    </CustomText>
                    <CustomText 
                        size="p" 
                        sx={{fontSize: 18}}
                    >
                        {defaultTexts.totalUsersGraphTitle}
                    </CustomText>
                </Stack>
                </Stack>
            </CustomCard>
        )
    }
};

export default UsersGraphContentV3;