import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import WrapperBox from '../../../../../_common/WrapperBox';
import Grid from '@mui/material/Grid';

const HypervisorsSubheaderV21 = (props) => {
    const { currentTab, handleTabChange } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const theme = useTheme();

    return (
        <WrapperBox 
            sx={{
                flexDirection: "row", 
                justifyContent: 'space-between',
                mt: 1
            }}>
            <Grid 
                container 
                justifyContent='space-between' 
                alignItems="center">
                <Grid item sx={{flexGrow: 1}}>
                    <Button
                        onClick={() => handleTabChange("aggregates")}
                        sx={{
                            color: currentTab === "aggregates" ? 
                            "lightBlue" : 
                            theme.palette.text.primary,
                            "&:hover": {
                                backgroundColor: "lightBlue",
                                color: "white"
                            }
                        }}
                        size="small"
                    >
                        {defaultTexts.hostAggregatesTabButtonText}
                    </Button>
                    <Button
                        onClick={() => handleTabChange("availability_zones")}
                        sx={{
                            color: currentTab === "availability_zones" ? 
                            "lightBlue" : 
                            theme.palette.text.primary,
                            "&:hover": {
                                backgroundColor: "lightBlue",
                                color: "white"
                            }
                        }}
                        size="small"
                    >
                        {defaultTexts.availabilityZonesTabButtonText}
                    </Button>
                    <Button
                        onClick={() => handleTabChange("hypervisors")}
                        sx={{
                            color: currentTab === "hypervisors" ? 
                            "lightBlue" : 
                            theme.palette.text.primary,
                            "&:hover": {
                                backgroundColor: "lightBlue",
                                color: "white"
                            }
                        }}
                        size="small"
                    >
                        {defaultTexts.hypervisorsTabButtonText}
                    </Button>
                </Grid>
            </Grid>
        </WrapperBox>
    )
};

export default HypervisorsSubheaderV21;