import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import WrapperBox from '../../../../_common/WrapperBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOffIcon from '@mui/icons-material/EditOff';
import Paper from '@mui/material/Paper';
import useWindowDimensions from 
'../../../../_common/WindowDimensions';
import Constants from '../../../../../config/constants';
import { IconButton }  from '@mui/material';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { 
    volumeCinderRequest, 
    getXAuthTokenProjectScope
 } from 
'../../../../../_network/openstack_request';
import { volumesUrl as qosSpecsUrlResponses } from 
'../../../../../_api_responses/openstack/cinder/volumes/v3';

import { 
    openStackServices, 
    blockStorageCinderConstants
} from 
'../../../../../config/openStackConstants';
import CustomDialog from 
'../../../../_common/CustomDialog';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import { FormGroup } from '@mui/material';
import { 
    qosSpecsDataForm, 
    qosSpecsSchema
} from '../../../../../_data/openstack/cinder/volume_types/v3';
import { getDetailDataComponent } from 
'../../../../_common/common_helpers';
import ServiceContentHeader from '../../../../_common/ServiceContentHeader';
import CustomCard from '../../../../_common/CustomCard';
import { Divider } from '@mui/material';
import CustomSelectField from 
'../../../../_common/_form_fields/CustomSelectField';
import PlusButton from '../../../../_common/PlusButton';
import MinusButton from '../../../../_common/MinusButton';
import CustomTextField from '../../../../_common/_form_fields/CustomTextField';
import CloseIcon from '@mui/icons-material/Close';

const SERVICE_NAME = openStackServices.volumeService
const FOOTER_HEIGHT = Constants.actions_bar_height + 20

const QoSSpecsDetailV3 = (props) => {
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const { selectedRow } = props
    const { widthWeight } = props
    const { width } = useWindowDimensions();
    const FOOTER_WIDTH = width - (width * widthWeight)
    const { handleNavigateToVolumeTypes } = props
    const { volumeTypes } = props
    const { handleFetchData } = props
    const { 
        handleQoSFetchData,
        handleQoSSpecsDelete,
        handleDisassociateAll
    } = props
    
    const [editMode, setEditMode] = useState(false);
    
    const [error, setError] = useState()
    const [updateSpecsMode, setUpdateSpecsMode] = useState(false)
    const [formSpecsFields, setFormSpecsFields] = useState([]);
    const [successQoSSpecUpdate, setSuccessQoSSpecUpdate] = useState()
    const [successUpdateDialogOpen, setSuccessUpdateDialogOpen] = useState(false);
    
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

    const [updateFormData, setUpdateFormData] = useState(
        {...selectedRow}
    )
    const [qosSpecsActions, setQoSSpecsActions] = useState([]);
    const [currentAction, setCurrentAction] = useState("");
    const [selectedQoSSpecAssociations, setSelectedQoSSpecAssociations] = useState([])

    const [associateVolumeTypes, setAssociateVolumeTypes] = useState([])
    const [disassociateVolumeTypes, setDisassociateVolumeTypes] = useState([])
    const [selectedVolumeType, setSelectedVolumeType] = useState("")
    const [associateToVolumeTypeDialogOpen, setAssociateToVolumeTypeDialogOpen] = useState(false)
    const [disassociateVolumeTypeDialogOpen, setDisassociateVolumeTypeDialogOpen] = useState(false)

    const [qosSpecsSubMenu, setQoSSpecsSubMenu] = useState([
        {keyword: "submenuDetails", navigation: "/qs-details", is_active: true},
        {keyword: "submenuQoSSpecs", navigation: "/qs-specs", is_active: false},
        {keyword: "submenuQoSSpecsAssociations", navigation: "/qs-associations", is_active: false}
    ])
    
    const [currentTab, setCurrentTab] = useState("/qs-details")

    const cinderServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const cinderServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const qosSpecsUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.qosSpecsUrl)[0].url)
    
    const handleFormDataChange = (event,field_key) => {
        let new_form_data = {...updateFormData}
        if (qosSpecsDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (qosSpecsDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setUpdateFormData(new_form_data)
    }

    const handleQosSpecsDisassociate = async () => {
        handleDisassociateVolumeTypeDialogClose()
        const result = await handleDisassociateAll(selectedRow.id)
        if (result) {
            setCurrentAction("")
            handleQoSFetchData()
            handleFetchData()
            
        }
    }

    const getEmptyListNoteComponent = (text) => {
        return (
            <WrapperBox>
                <CustomCard 
                    overflow="hidden" 
                    cardHeight={220}
                    cardWidth={300}
                    card_sx={{
                        mt: 15, 
                        opacity: 0.5, 
                        borderRadius: 50,
                        
                    }}
                    box_sx={{
                        display: "flex", 
                        alignItems: "center", 
                        justifyContent: "center",
                        overflow: "hidden",
                        right: 0
                    }}
                >
                    <CustomText 
                        size="h5" 
                        sx={{textAlign: "center"}}
                    >
                        {text}
                    </CustomText>
                </CustomCard>
            </WrapperBox>
        )
    }

    const getDataForm = () => {
        return (
            <FormGroup>
                {qosSpecsDataForm.map(field => {
                    let form_field_options = {}
                    if (field.field_key === "consumer") {
                        form_field_options["items"] = [...field.items]
                        form_field_options["self_item_titles"] = field.self_item_titles
                        form_field_options["default_empty"] = field.default_empty
                        form_field_options["sx"] = {width: "100%", my: 1}
                    }
                return (
                    getFormFieldComponent(
                        field,
                        updateFormData,
                        handleFormDataChange,
                        defaultTexts[field.label],
                        {
                            size:"medium",
                            sx: {
                                my: 1, 
                                width: width * widthWeight * 0.8
                            },
                        ...form_field_options
                        }
                    )
                )
            })}
        </FormGroup>)
    }

    const handleFormSpecsFieldsChange = (event,field_key_list) => {
        let new_extra_data = [...formSpecsFields]
        if (field_key_list[1] === "key") {
            new_extra_data[field_key_list[0]].field_key = event.target.value
        } else {
            new_extra_data[field_key_list[0]].field_value = event.target.value
        }
        setFormSpecsFields(new_extra_data)
    }

    const handleFormSpecsFieldsRemove = (index) => {
        if (formSpecsFields.length > 1) {
            let updated_data = [...formSpecsFields]
            updated_data.splice(index, 1)
            setFormSpecsFields(updated_data)
        } else {
            setFormSpecsFields([])
        }
    }

    const handleUpdateSpecsModeReset = () => {
        if (Object.keys(selectedRow.specs).length > 0) {
            const extra_specs_form = Object.keys(selectedRow.specs).map(key => {
                let new_item = {}
                new_item["field_key"] = key
                new_item["field_value"] = selectedRow.specs[key]
                return new_item
            })
            setFormSpecsFields(extra_specs_form)
        }
        setUpdateSpecsMode(false)
    }

    const getSpecsFormComponents = () => {
        return (
            <WrapperBox
                sx={{
                    m: 2, 
                    alignItems: 'start'
                }}
            >
                <IconButton
                    onClick={handleUpdateSpecsModeReset}
                    sx={{
                    position: 'absolute',
                    right: 8,
                    top: 130,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Stack 
                    direction="row" 
                    spacing={2} 
                    alignItems="center"
                    sx={{my: 3}}
                >
                    <CustomText size="h6">
                        {defaultTexts.specsFormFieldLabel}
                    </CustomText>
                    <PlusButton 
                        onClick={() => setFormSpecsFields([
                            ...formSpecsFields,
                            {field_key: "",field_value: ""}
                        ])} 
                    />
                </Stack>
                {formSpecsFields.map((item,index) => {
                    return (
                        <Stack
                            key={index}
                            direction="row" 
                            spacing={2} 
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{my: 1}}
                        >
                            <CustomTextField
                                currentValue={item.field_key} 
                                setCurrentValue={handleFormSpecsFieldsChange}
                                field_key={[index,"key"]}
                                label={defaultTexts.keyFormFieldLabel}
                            />
                            <CustomTextField
                                currentValue={item.field_value} 
                                setCurrentValue={handleFormSpecsFieldsChange}
                                field_key={[index,"value"]}
                                label={defaultTexts.valueFormFieldLabel}
                            />
                            <MinusButton 
                                sx={{width: 90}} 
                                onClick={() => handleFormSpecsFieldsRemove(index)}
                            />
                        </Stack>
                    )
                })}
                <Button 
                    variant="contained"
                    color="secondary"
                    sx={{mt: 5}}
                    onClick={handleEditQoSSpecsParams}
                >
                    {defaultTexts.saveButtonText}
                </Button>
            </WrapperBox>
        )
    }

    const handleEditModeChange = () => {
        handleQoSSpecsDetailTabChange("/qs-details")
        setEditMode(true)
    }

    const handleEditModeReset = () => {
        setUpdateFormData({...selectedRow})
        handleQoSSpecsDetailTabChange("/qs-details")
        setEditMode(false)
    }
    
    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }
    
    const handleSuccessUpdateDialogClose = () => {
        setSuccessQoSSpecUpdate(null)
        setUpdateFormData({...selectedRow})
        setSuccessUpdateDialogOpen(false);
    }
    const handleConfirmDeleteDialogOpen = () => {
        handleQoSSpecsDelete([selectedRow.id])
    }
    const handleConfirmDeleteDialogClose = () => {
        setConfirmDeleteDialogOpen(false)
    }

    const handleAssociateToVolumeTypeDialogOpen = () => {
        setAssociateToVolumeTypeDialogOpen(true)
    }

    const handleAssociateToVolumeTypeDialogClose = () => {
        setSelectedVolumeType("")
        setAssociateToVolumeTypeDialogOpen(false)
    }

    const handleDisassociateVolumeTypeDialogOpen = () => {
        setDisassociateVolumeTypeDialogOpen(true)
    }

    const handleDisassociateVolumeTypeDialogClose = () => {
        setSelectedVolumeType("")
        setDisassociateVolumeTypeDialogOpen(false)
    }

    const handleEditQoSSpecs = async () => {
        let updated_data = {...updateFormData}
        delete updated_data.specs
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${qosSpecsUrl}/${selectedRow.id}`
            const method = "PUT"

            const qs_response = await volumeCinderRequest({
                url: url, 
                method: method, 
                data: {qos_specs: updated_data},
                token: project_token
            })
            if (qs_response.status_code === qosSpecsUrlResponses.put.success_response.status_code) {
                setCurrentAction("")
                handleQoSFetchData()
                handleEditModeReset()
                setSuccessQoSSpecUpdate({
                    success_title: qosSpecsUrlResponses.put.success_response.response_title, 
                    success_message: qosSpecsUrlResponses.put.success_response.response_message
                })
            } else {
                const error_response = qosSpecsUrlResponses.put.error_response.filter(
                    error_item => error_item.status_code === qs_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: qs_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = qosSpecsUrlResponses.put.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: qs_response.error
                    }
                    setError(errorObject)
                }
            }
        }
    }

    const formatSpecsData = () => {
        let new_data = {}
        for (let i in formSpecsFields) {
            new_data[formSpecsFields[i].field_key] = formSpecsFields[i].field_value
        }
        const keys_to_delete = Object.keys(selectedRow.specs).filter(key => !Object.keys(new_data).includes(key))
        return {keys_to_add: new_data, keys_to_delete: keys_to_delete}
    }

    const handleEditQoSSpecsParams = async () => {
        const data = formatSpecsData()
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${qosSpecsUrl}/${selectedRow.id}`
            const method = "PUT"

            const update_response = await volumeCinderRequest({
                url: url, 
                method: method, 
                data: {qos_specs: data.keys_to_add},
                token: project_token,
                has_response: false
            })
            
            url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${qosSpecsUrl}/${selectedRow.id}/delete_keys`

            const delete_response = await volumeCinderRequest({
                url: url, 
                method: method, 
                data: {keys: data.keys_to_delete},
                token: project_token,
                has_response: false
            })
            
            if (update_response.status_code === qosSpecsUrlResponses.put.success_response.status_code &&
                delete_response.status_code === qosSpecsUrlResponses.delete.success_response.status_code
            ) {
                setCurrentAction("")
                handleQoSFetchData()
                handleFetchData()
                handleUpdateSpecsModeReset()
            } else {
                const errorObject = {
                    error_title: "errorUpdateQoSSpecsTitle", 
                    error_message: "errorUpdateQoSSpecsMessage",
                    error_details: `${update_response.error ? update_response.error : ""} ${delete_response.error ? delete_response.error : ""}`
                }
                
                setError(errorObject)
            }
        }
    }

    const handleQoSSpecsDetailTabChange = useCallback((navigation) => {
        let newQSSubmenuData = qosSpecsSubMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
            } else {
                item.is_active = false
            }
            return item
        })
        setQoSSpecsSubMenu(newQSSubmenuData)
        setCurrentTab(navigation)
    },[
        qosSpecsSubMenu,
        setQoSSpecsSubMenu
    ])

    const onVolumeTypeAssociate = async () => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${qosSpecsUrl}/${selectedRow.id}/associate?vol_type_id=${selectedVolumeType}`
            const method = "GET"
            
            const response = await volumeCinderRequest({
                    url: url, 
                    method: method,
                    token: project_token,
                    has_response: false
                })
            if (response.status_code === qosSpecsUrlResponses.delete.success_response.status_code) {
                handleAssociateToVolumeTypeDialogClose()
                setCurrentAction("")
                handleQoSFetchData()
                handleFetchData()
                setSuccessQoSSpecUpdate({
                    success_title: qosSpecsUrlResponses.put.success_response.response_title, 
                    success_message: qosSpecsUrlResponses.put.success_response.response_message
                })
            } else {
                const error_response = qosSpecsUrlResponses.put.error_response.filter(
                    error_item => error_item.status_code === response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = qosSpecsUrlResponses.put.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: response.error
                    }
                    setError(errorObject)
                } 
            }
        }
    }

    const onVolumeTypeDisassociate = async () => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${qosSpecsUrl}/${selectedRow.id}/disassociate?vol_type_id=${selectedVolumeType}`
            const method = "GET"
            
            const response = await volumeCinderRequest({
                    url: url, 
                    method: method,
                    token: project_token,
                    has_response: false
                })
            if (response.status_code === qosSpecsUrlResponses.delete.success_response.status_code) {
                handleDisassociateVolumeTypeDialogClose()
                setCurrentAction("")
                handleQoSFetchData()
                handleFetchData()
                setSuccessQoSSpecUpdate({
                    success_title: qosSpecsUrlResponses.put.success_response.response_title, 
                    success_message: qosSpecsUrlResponses.put.success_response.response_message
                })
            } else {
                const error_response = qosSpecsUrlResponses.put.error_response.filter(
                    error_item => error_item.status_code === response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = qosSpecsUrlResponses.put.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: response.error
                    }
                    setError(errorObject)
                } 
            }
        }
    }

    const handleUpdateSpecsModeChange = useCallback(() => {
        handleQoSSpecsDetailTabChange("/qs-specs")
        setUpdateSpecsMode(true)
    },[handleQoSSpecsDetailTabChange])

    useEffect(() => {
        if (Object.keys(selectedRow.specs).length > 0) {
            const extra_specs_form = Object.keys(selectedRow.specs).map(key => {
                let new_item = {}
                new_item["field_key"] = key
                new_item["field_value"] = selectedRow.specs[key]
                return new_item
            })
            setFormSpecsFields(extra_specs_form)
        }
    },[
        selectedRow
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessUpdateDialogOpen(true)
    },[successQoSSpecUpdate]);

    useEffect(() => {
        const ids = selectedQoSSpecAssociations.map(item => item.id)
        const volume_types_to_associate = volumeTypes.filter(item => !ids.includes(item.id))
        const vt_items = volume_types_to_associate.map(item => {
            return {keyword: item.name, value: item.id, default: false}
        })
        setAssociateVolumeTypes(vt_items)
    },[
        volumeTypes,
        selectedQoSSpecAssociations
    ])

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${qosSpecsUrl}/${selectedRow.id}/associations`
                const method = "GET"

                const as_response = await volumeCinderRequest({
                    url: url, 
                    method: method, 
                    token: project_token
                })
                if (as_response.status_code === qosSpecsUrlResponses.get.success_response.status_code) {
                    setSelectedQoSSpecAssociations(as_response.data.qos_associations)
                    const dissassociate_list = as_response.data.qos_associations.map(item => {
                        return {keyword: item.name, value: item.id, default: false}
                    })
                    setDisassociateVolumeTypes(dissassociate_list)
                }
            }
        })();
    },[
        cinderServiceDomain,
        cinderServiceVersion,
        defaultAdminProject,
        qosSpecsUrl,
        selectedRow
    ]);

    useEffect(() => {
        if (selectedRow !== null) {
            let qs_actions = []
            let new_action = {}
            new_action = {}
            new_action["value"] = "associate_to_vt"
            new_action["action"] = handleAssociateToVolumeTypeDialogOpen
            new_action["keyword"] = "qosSpecAssociateToVTActionTitle"
            new_action["button_text"] = "selectButtonTitleText"
            qs_actions.push({...new_action})
            new_action = {}
            if (selectedQoSSpecAssociations.length > 0) {
                new_action["value"] = "disassociate_from_vt"
                new_action["action"] = handleDisassociateVolumeTypeDialogOpen
                new_action["keyword"] = "qosSpecDisassociateFromVTActionTitle"
                new_action["button_text"] = "selectButtonTitleText"
                qs_actions.push({...new_action})
            }
            new_action = {}
            new_action["value"] = "update_specs"
            new_action["action"] = handleUpdateSpecsModeChange
            new_action["keyword"] = "qosSpecsUpdateActionTitle"
            new_action["button_text"] = "selectButtonTitleText"
            qs_actions.push({...new_action})
            setQoSSpecsActions(qs_actions)
        }
    },[
        selectedRow,
        defaultTexts,
        selectedQoSSpecAssociations,
        handleUpdateSpecsModeChange
    ]);

    return (
        <React.Fragment>
        {selectedRow !== null && 
            <WrapperBox>
                <ServiceContentHeader 
                    service_menu={qosSpecsSubMenu}
                    service_menu_titles={defaultTexts}
                    onClick={handleQoSSpecsDetailTabChange}
                />
            </WrapperBox>}
            {currentTab === "/qs-details" &&
                <React.Fragment>
                    {!editMode ? 
                    <Stack 
                        spacing={2} 
                        sx={{ p: 2, mt: 1, flexWrap: "wrap"}}
                    >
                        {qosSpecsSchema.map((field) => {
                            let value = selectedRow[field.field_key]
                            return (
                                getDetailDataComponent({
                                    fieldType: field.field_type,
                                    fieldKey: field.field_key,
                                    label: defaultTexts[field.label],
                                    value: value,
                                    textOnTrue: defaultTexts[field.value_on_true] ? 
                                    defaultTexts[field.value_on_true] : 
                                    defaultTexts.formValueYes,
                                    textOnFalse: defaultTexts[field.value_on_false] ? 
                                    defaultTexts[field.value_on_false] : 
                                    defaultTexts.formValueNo,
                                    defaultTexts: defaultTexts
                                })
                            )
                        })}
                        </Stack> : 
                        <Stack sx={{m: 2, alignItems: 'start'}}>
                        <CustomText 
                            size="h6" 
                            sx={{color: "primary.main", mb: 2}}
                        >
                            {defaultTexts.updateQoSSpecsFormTitle}
                        </CustomText>
                        {getDataForm()}
                        <Button 
                            variant="contained"
                            color="secondary"
                            sx={{mt: 5}}
                            onClick={handleEditQoSSpecs}
                        >
                            {defaultTexts.saveButtonText}
                        </Button>
                    </Stack>}
                </React.Fragment>}
                {currentTab === "/qs-specs" && 
                !updateSpecsMode &&  
                    <div>
                        {Object.keys(selectedRow.specs).length > 0 ? 
                            <Stack 
                                spacing={2} 
                                sx={{ p: 2, mt: 1}}
                            >
                                {Object.keys(selectedRow.specs).map(key => {
                                    return (
                                        <Stack key={key} spacing={1} >
                                            <Stack 
                                                direction="row" 
                                                justifyContent="space-between" 
                                                alignItems="center"
                                            >
                                                <CustomText>{key}</CustomText>
                                                <CustomText>{selectedRow.specs[key]}</CustomText>
                                            </Stack>
                                            <Divider />
                                        </Stack>
                                    )
                                })}
                            </Stack> : 
                            getEmptyListNoteComponent(defaultTexts.noQoSSpecsNoteText)}
                    </div>
                }
                {currentTab === "/qs-specs" && 
                    updateSpecsMode &&  
                    getSpecsFormComponents()
                }
                {currentTab === "/qs-associations" && 
                    <div>
                        {Object.keys(selectedQoSSpecAssociations).length > 0 ? 
                            <Stack 
                                spacing={2} 
                                sx={{ p: 2, mt: 1}}
                            >
                                {selectedQoSSpecAssociations.map(item => {
                                    return (
                                        <Stack key={item.id} spacing={1}>
                                            <Stack 
                                                direction="row" 
                                                justifyContent="space-between" 
                                                alignItems="center"
                                                sx={{
                                                    cursor: "pointer"
                                                }}
                                                onClick={() => handleNavigateToVolumeTypes(item.id)}
                                            >
                                                <CustomText>{item.id}</CustomText>
                                                <CustomText sx={{color: "primary.main"}}>{item.name}</CustomText>
                                            </Stack>
                                            <Divider />
                                        </Stack>
                                    )
                                    
                                })}
                            </Stack> : 
                            getEmptyListNoteComponent(defaultTexts.noQoSSpecAssociationsNoteText)}
                    </div>
                }
            <Paper sx={{ 
                position: 'fixed', 
                bottom: 0, 
                height: FOOTER_HEIGHT,
                left: FOOTER_WIDTH, 
                right: 12,
                zIndex: 3000
                }} 
                elevation={24}
                square={true}
            >
                <Grid 
                    container 
                    alignItems="center"  
                    justifyContent="space-between"
                >
                    <Grid item>
                        <CustomSelectField 
                            items={qosSpecsActions} 
                            currentValue={currentAction}
                            setCurrentValue={setCurrentAction}
                            item_titles={defaultTexts}
                            label={defaultTexts.actionsDropdownLabelText}
                            empty={true}
                            size="small"
                            sx={{m: 1}}
                        />
                        {currentAction.length > 0 && 
                            <Button 
                                    variant="contained"
                                    color="secondary"
                                    sx={{m: 1, height: '70%'}}
                                    onClick={qosSpecsActions.filter(
                                        action => action.value === currentAction)[0].action
                                    }
                                >
                                {defaultTexts[qosSpecsActions.filter(
                                    action => action.value === currentAction)[0].button_text]}
                            </Button>
                        }
                    </Grid>
                    <Grid item>
                        {!editMode ? <IconButton onClick={handleEditModeChange}>
                                <EditIcon color="primary" />
                            </IconButton> :
                            <IconButton onClick={handleEditModeReset}>
                                <EditOffIcon color="primary"/>
                            </IconButton>
                        }
                        {selectedRow !== null && 
                            <IconButton onClick={handleConfirmDeleteDialogOpen}>
                                <DeleteIcon 
                                    color="primary"
                                />
                            </IconButton>}
                    </Grid>
                </Grid>
            </Paper> 
            <CustomDialog
                open={associateToVolumeTypeDialogOpen}
                onClose={handleAssociateToVolumeTypeDialogClose}
                dialogTitle={{
                    title: defaultTexts.associateToVolumeTypeActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.associateToVolumeTypeActionDialogMessage, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onVolumeTypeAssociate, 
                    sx: {color: 'primary.main'}}]}
            >
                <CustomSelectField
                    items={associateVolumeTypes}
                    required={true}
                    currentValue={selectedVolumeType}
                    setCurrentValue={setSelectedVolumeType}
                    label={defaultTexts.volumeTypeFormFieldLabel}
                    self_item_titles={true}
                    empty={true}
                    size="large"
                    sx={{my: 1, width: '90%'}}
                />
            </CustomDialog>
            <CustomDialog
                open={disassociateVolumeTypeDialogOpen}
                onClose={handleDisassociateVolumeTypeDialogClose}
                dialogTitle={{
                    title: defaultTexts.disassociateVolumeTypeActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.disassociateVolumeTypeActionDialogMessage, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onVolumeTypeDisassociate, 
                    sx: {color: 'primary.main'}}]}
            >
                <CustomSelectField
                    items={disassociateVolumeTypes}
                    required={true}
                    currentValue={selectedVolumeType}
                    setCurrentValue={setSelectedVolumeType}
                    label={defaultTexts.volumeTypeFormFieldLabel}
                    self_item_titles={true}
                    empty={true}
                    size="large"
                    sx={{my: 1, width: '90%'}}
                />
                <Button
                    variant="text"
                    color="error"
                    sx={{my: 2}}
                    onClick={handleQosSpecsDisassociate}
                >
                    {defaultTexts.qosSpecsDisassociateAllActionTitle}
                </Button>
            </CustomDialog>
            {successQoSSpecUpdate && <CustomDialog
                open={successUpdateDialogOpen}
                onClose={handleSuccessUpdateDialogClose}
                dialogTitle={{
                    title: defaultTexts[successQoSSpecUpdate.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: successQoSSpecUpdate.success_details ?
                        `<span>${defaultTexts[successQoSSpecUpdate.success_message]}</span>
                            <br>
                            <br>
                            <span>Secret Key:</span> 
                            <span style="width: 100px; color: orange; word-wrap: break-word;">
                                ${successQoSSpecUpdate.success_details}
                            </span>` :
                        `<span>${defaultTexts[successQoSSpecUpdate.success_message]}</span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            {confirmDeleteDialogOpen && <CustomDialog
                open={confirmDeleteDialogOpen}
                onClose={handleConfirmDeleteDialogClose}
                dialogTitle={{
                    title: defaultTexts.deleteConfirmationDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.deleteQoSSpecConfirmationDialogMessage, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: () => {}, 
                    sx: {color: 'primary.main'}}]}
            />}
        </React.Fragment>
    )

};

export default QoSSpecsDetailV3;