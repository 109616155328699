import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Constants from '../../../config/constants';
import { IconButton } from '@mui/material';
import { RxReset } from "react-icons/rx";
import Tooltip from '@mui/material/Tooltip';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Paper from '@mui/material/Paper';
import CustomText from '../../_common/CustomText';
import Grid from '@mui/material/Grid';
import Collapse from '@mui/material/Collapse';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Checkbox from "@mui/material/Checkbox";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { 
    openStackServiceLabels, 
    openStackResourceLabels 
} from '../../../config/openStackConstants';
import billingRequest from '../../../_network/billing_request';
import { billingServiceURLs } from '../../../_network/apiUrls';

const drawerWidth = Constants.drawerWidth;

const BillingPricesTable = (props) => {
    const drawerOpen = useSelector(state => state.drawer.drawerOpened);
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const { dataRows, handleDataFetch, billingConfigs } = props
    const [selectedPrices, setSelectedPrices] = useState([])
    const [priceEditMode, setPriceEditMode] = useState(false)
    const purchasedService = useSelector(
        state => state.openstack.purchasedServicesAll)
    const [billingServicesChecked, setBillingServicesChecked] = useState(false)
    const [priceUpdateAllowed, setPriceUpdateAllowed] = useState(false)

    const theme = useTheme();

    const handleSelectPriceEditing = (event, resource) => {
        if (event.target.checked) {
            let new_list = [...selectedPrices, resource]
            setSelectedPrices(new_list)

        } else {
            let new_list = selectedPrices.filter(
                price => price.id !== resource.id)
            setSelectedPrices(new_list)
        }
    }

    const handlePriceChange = (event, item_id) => {
        const new_list = selectedPrices.map(item => {
            if (item.id === item_id) {
                let new_item = {...item}
                new_item.price = parseFloat(event.target.value)
                return new_item
            } else {
                return item
            }
        })
        setSelectedPrices(new_list)
    }

    const handlePriceUpdateReset = () => {
        setSelectedPrices([])
        setPriceEditMode(false)
    }

    const handleSaveUpdatedPrices = async () => {
        if (selectedPrices.length > 0) {
            const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingPricesEP}-list`
            let request_data = {
                url: url,
                method: "PUT",
                data: {resources: selectedPrices.map(res => {return {id: res.id, price: res.price}})}
            }
            const response = await billingRequest(request_data)
            if (response.status_code === 200) {
                setSelectedPrices([])
                setPriceEditMode(false)
                handleDataFetch()
            }
        } else {
            setPriceEditMode(false)
        }
    }

    useEffect(() => {
        if (!billingServicesChecked && purchasedService.length > 0) {
            const billingService = purchasedService.filter(
            service => service.service === Constants.config_service_billing_name)
            const billingConfiguration = billingService[0].config_params[Constants.config_service_custom_admin_ui]
            if (billingConfiguration.filter(
                section => section.section === Constants.billing_sections.billing_prices && 
                section.permissions.includes(Constants.permission_update)).length > 0) {
                    setPriceUpdateAllowed(true)
            }
            setBillingServicesChecked(true)
        }
    },[
        purchasedService,
        billingServicesChecked
    ])

    const ResourceRow = (props) => {
        const { row } = props;
        const [resOpen, setResOpen] = useState(false);

        return (
        <React.Fragment>
            <TableRow  
                sx={{
                    borderBottom: "unset",
                    '&:hover': {
                    backgroundColor: theme.palette.mode === 'dark' ? 
                    theme.palette.mediumGray  :
                    theme.palette.vLightGray,
                    cursor: "pointer"
                    }
                }}
                onClick={() => setResOpen(!resOpen)}
            >
                <TableCell  style={{width: 30}}/>
                <TableCell  style={{width: 30}}/>
                <TableCell>
                    <IconButton
                        size="small"
                        onClick={() => setResOpen(!resOpen)}
                    >
                        {resOpen ? 
                            <KeyboardArrowUpIcon /> : 
                            <KeyboardArrowDownIcon />
                        }
                    </IconButton>
                </TableCell>
            <TableCell 
                component="th" 
                scope="row" 
                style={{
                    color: theme.palette.primary.main
                }}
                align="left"
                colSpan={8}
            >
                {openStackResourceLabels[row.resource_name]}
            </TableCell>
            </TableRow>
            <TableRow>
                <TableCell 
                    style={{ padding: 0 }} 
                    colSpan={8} 
                    sx={{borderBottom: "unset", 
                        borderTop: "unset"}}
                >
                    <Collapse in={resOpen} timeout="auto">
                        <Table size="small">
                            <TableBody>
                                {row.resource_items.map((res, index) => (
                                    <TableRow 
                                        key={index}
                                    >
                                        <TableCell style={{width: 30}}/>
                                        <TableCell style={{width: 30}}/>
                                        <TableCell style={{width: 50}}/>
                                        <TableCell style={{width: 50}}/>
                                        {row.resource_name === "instance" && <TableCell>
                                            <CustomText>{res.props.name}</CustomText>
                                            <CustomText sx={{color: "text.secondary", px: 1}}>
                                                {`(${res.resource_status === "active" ? 
                                                    defaultTexts.instansActiveStatusNoteText : 
                                                    defaultTexts.instansNotActiveStatusNoteText
                                                })`}
                                            </CustomText>
                                        </TableCell>}
                                        {row.resource_name === "volume" && <TableCell>
                                            {res.props.name}
                                        </TableCell>}
                                        {(row.resource_name === "vpn_ike" ||  row.resource_name === "vpn_ipsec") && <TableCell sx={{textTransform: "uppercase"}}>
                                            {res.resource_id}
                                        </TableCell>}
                                        {row.resource_name === "fwaas_rule" && <TableCell>
                                            {defaultTexts.ruleRangeFormFieldTitle} {res.resource_count[0]} - {res.resource_count[1]}
                                        </TableCell>}
                                        <TableCell align="right" sx={{textTransform: "uppercase"}}>
                                            {res.price} {billingConfigs.currency ? billingConfigs.currency.name.toUpperCase() : ""}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
        );
    }

    const RegionRow = (props) => {
        const { row } = props;
        const [open, setOpen] = useState(false)
  
    return (
        <React.Fragment>
            <TableRow  
                sx={{  
                    borderBottom: "unset", 
                    borderTop: "unset",
                    '&:hover': {
                    backgroundColor: theme.palette.mode === 'dark' ? 
                    theme.palette.mediumGray  :
                    theme.palette.vLightGray,
                    cursor: "pointer"
                    }
                }}
                onClick={() => {
                    setOpen(!open)
                }}
            >
                <TableCell  style={{width: 30}}/>
                <TableCell>
                    <IconButton
                        size="small"
                        onClick={() => {
                            setOpen(!open)
                        }}
                    >
                        {open ? 
                            <KeyboardArrowUpIcon /> : 
                            <KeyboardArrowDownIcon />
                        }
                    </IconButton>
                </TableCell>
            <TableCell 
                component="th" 
                scope="row" 
                style={{
                    color: theme.palette.primary.main
                }}
                colSpan={8}
            >
                {row.region_name}
            </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={8}  sx={{borderBottom: "unset", borderTop: "unset"}}>
                    <Collapse in={open} timeout="auto">
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell  style={{width: 30}}/>
                                    <TableCell  style={{width: 50}}/>
                                    <TableCell  style={{width: 30}}/>
                                    <TableCell align="left" colSpan={7}>{defaultTexts.resourceFormFieldLabel}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                    {row.resources.map((res) => (
                                        res.resource_items.length > 0 ? 
                                        <ResourceRow key={res.resource_name} row={res} /> :
                                        <TableRow sx={{borderBottom: 'unset'}}>
                                            <TableCell  style={{width: 30}}/>
                                            <TableCell  style={{width: 50}}/>
                                            <TableCell  style={{width: 30}}/>
                                            <TableCell align="left" >{openStackResourceLabels[res.resource_name]}</TableCell>
                                            <TableCell align="right" sx={{textTransform: "uppercase"}}>
                                                {res.price} {billingConfigs.currency ? billingConfigs.currency.name.toUpperCase() : ""}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
        );
    }

    const Row = (props) => {
        const { row, handleSaveResources, editMode } = props;
        const [open, setOpen] = useState(false)
  
    return (
        <React.Fragment>
            <TableRow  
                sx={{ '& > *': {  
                    borderTop: 'unset' },
                    '&:hover': {
                    backgroundColor: theme.palette.mode === 'dark' ? 
                    theme.palette.mediumGray  :
                    theme.palette.vLightGray,
                    cursor: "pointer"
                    }
                }}
                onClick={() => {
                    setOpen(!open)
                }}
            >
                <TableCell>
                    <IconButton
                        size="small"
                        onClick={() => {
                            setOpen(!open)
                        }}
                    >
                        {open ? 
                            <KeyboardArrowUpIcon /> : 
                            <KeyboardArrowDownIcon />
                        }
                    </IconButton>
                </TableCell>
            <TableCell 
                component="th" 
                scope="row" 
                style={{
                    color: theme.palette.primary.main
                }}
            >
                {openStackServiceLabels[row.service_name]}
            </TableCell>
            {open ? <TableCell align="right">{defaultTexts.priceFormFieldLabel}</TableCell> : 
                <TableCell align="right"></TableCell>
            }
            </TableRow>
            <TableRow>
                <TableCell style={{ padding: 0 }} colSpan={8} sx={{borderBottom: "unset", borderTop: "unset"}}>
                    <Collapse in={open} timeout="auto">
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell  style={{width: 30}}/>
                                    <TableCell  style={{width: 50}}/>
                                    <TableCell align="left" colSpan={7}>{defaultTexts.regionFormFieldLabel}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {row.regions.map((reg, index) => { return (
                                    
                                    <RegionRow 
                                        key={`${row.service_name}_${reg.region_name}_${index}`} 
                                        row={reg} 
                                        handleSaveResources={handleSaveResources}
                                        editMode={editMode}
                                    />
                                )})}
                            </TableBody>
                        </Table>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <Box sx={{height: 40}}>
                {priceUpdateAllowed && <Grid container alignItems="center" justifyContent="flex-end"
                    sx={{
                        maxWidth: drawerOpen ? 
                        `calc(100vw - ${drawerWidth}px)` : 
                        `calc(100vw - ${110}px)`,
                    }}>
                        {priceEditMode && <Grid item>
                            <Tooltip title={defaultTexts.resetPriceEditModeTooltipText}>
                                <IconButton onClick={handlePriceUpdateReset} sx={{mr: 1}}>
                                    <RxReset 
                                        color={theme.palette.text.primary}
                                        fontSize={'large'}
                                    />
                                </IconButton>
                            </Tooltip>
                        </Grid>}
                        <Grid item>
                            {priceEditMode && 
                                <Button 
                                    variant="contained" 
                                    color="primary"
                                    sx={{width: 120}}
                                    onClick={handleSaveUpdatedPrices}
                                >
                                    {defaultTexts.saveButtonTitle}
                                </Button>
                            }
                            {!priceEditMode && 
                                <Button 
                                    variant="outlined" 
                                    color="primary"
                                    sx={{width: 120}}
                                    onClick={() => setPriceEditMode(true)}
                                >
                                    {defaultTexts.editButtonTitle}
                                </Button>
                            }
                        </Grid>
                </Grid>}
            </Box>
            {!priceEditMode && <Box sx={{
                maxWidth: drawerOpen ? 
                `calc(100vw - ${drawerWidth}px)` : 
                `calc(100vw - ${110}px)`,
                mt: 2
            }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 300}} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" style={{width: 30}}/>
                                <TableCell  align="left" colSpan={7}>
                                    {defaultTexts.serviceFormFieldLabel}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataRows.map((row, index) => (
                                <Row 
                                    key={row.service_name} 
                                    row={row}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>}
            {priceEditMode && <Box sx={{
                maxWidth: drawerOpen ? 
                `calc(100vw - ${drawerWidth}px)` : 
                `calc(100vw - ${110}px)`,
                mt: 2
            }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 300}} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" style={{width: 30}}/>
                                <TableCell  align="left">
                                    {defaultTexts.serviceFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.regionFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.resourceFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.descriptionFormFieldLabel}
                                </TableCell>
                                <TableCell  align="right">
                                    {defaultTexts.priceFormFieldLabel}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataRows.map((row) => (
                                <React.Fragment key={row.service_name}>
                                    <TableRow>
                                        <TableCell align="left" style={{width: 30}}/>
                                        <TableCell 
                                            component="th" 
                                            scope="row" 
                                            style={{
                                                color: theme.palette.primary.main
                                            }}
                                            align="left"
                                            colSpan={6}
                                        >
                                            {openStackServiceLabels[row.service_name]}
                                        </TableCell>
                                    </TableRow>
                                    {row.regions.map((reg) => (
                                        <React.Fragment key={`${row.service_name}_${reg.region_name}`}>
                                            {reg.resources.map((res) => (
                                                <React.Fragment key={`${row.service_name}_${reg.region_name}_${res.resource_name}`}>
                                                    {res.resource_items.length === 0 ? 
                                                        <TableRow>
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                color="primary"
                                                                checked={selectedPrices.filter(price => price.id === res.id).length > 0}
                                                                onClick={(event) => handleSelectPriceEditing(event, res)}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="left"/>
                                                            <TableCell>{res.region_name}</TableCell>
                                                            <TableCell>{openStackResourceLabels[res.resource_name]}</TableCell>
                                                            <TableCell />
                                                            {selectedPrices.filter(price => price.id === res.id).length === 0 && 
                                                                <TableCell align="right" sx={{textTransform: "uppercase"}}>
                                                                {res.price} {billingConfigs.currency ? billingConfigs.currency.name.toUpperCase() : ""}
                                                            </TableCell>}
                                                            {selectedPrices.filter(price => price.id === res.id).length > 0 && 
                                                                <TableCell align="right">
                                                                <TextField 
                                                                    variant="standard" 
                                                                    value={selectedPrices.filter(i => i.id === res.id)[0].price}
                                                                    onChange={(event) => handlePriceChange(event, res.id)}
                                                                    color="primary"
                                                                    type="number"
                                                                    inputProps={{
                                                                        step: 0.000001
                                                                    }}
                                                                    sx={{width: 100}}
                                                                />
                                                            </TableCell>}
                                                        </TableRow> :
                                                        <React.Fragment>
                                                            {res.resource_items.map((item) => (
                                                                <TableRow key={item.id}>
                                                                    <TableCell padding="checkbox">
                                                                        <Checkbox
                                                                        color="primary"
                                                                        checked={selectedPrices.filter(price => price.id === item.id).length > 0}
                                                                        onClick={(event) => handleSelectPriceEditing(event, item)}
                                                                        />
                                                                    </TableCell>
                                                                    <TableCell align="left"/>
                                                                    <TableCell>{item.region_name}</TableCell>
                                                                    <TableCell>{openStackResourceLabels[item.resource_name]}</TableCell>
                                                                    {item.resource_name === "instance" && <TableCell>
                                                                        <CustomText>{item.props.name}</CustomText>
                                                                        <CustomText sx={{color: "text.secondary", px: 1}}>
                                                                            {`(${item.resource_status === "active" ? 
                                                                                defaultTexts.instansActiveStatusNoteText : 
                                                                                defaultTexts.instansNotActiveStatusNoteText
                                                                            })`}
                                                                        </CustomText>
                                                                    </TableCell>}
                                                                    {item.resource_name === "volume" && <TableCell>
                                                                        {item.props.name}
                                                                    </TableCell>}
                                                                    {(item.resource_name === "vpn_ike" ||  item.resource_name === "vpn_ipsec") && <TableCell sx={{textTransform: "uppercase"}}>
                                                                        {item.resource_id}
                                                                    </TableCell>}
                                                                    {item.resource_name === "fwaas_rule" && <TableCell>
                                                                        {defaultTexts.ruleRangeFormFieldTitle} {item.resource_count[0]} - {item.resource_count[1]}
                                                                    </TableCell>}
                                                                    {selectedPrices.filter(price => price.id === item.id).length === 0 && 
                                                                        <TableCell align="right" sx={{textTransform: "uppercase"}}>
                                                                        {item.price} {billingConfigs.currency ? billingConfigs.currency.name.toUpperCase() : ""}
                                                                    </TableCell>}
                                                                    {selectedPrices.filter(price => price.id === item.id).length > 0 && 
                                                                        <TableCell align="right">
                                                                        <TextField 
                                                                            variant="standard" 
                                                                            value={selectedPrices.filter(i => i.id === item.id)[0].price}
                                                                            onChange={(event) => handlePriceChange(event, item.id)}
                                                                            color="primary"
                                                                            type="number"
                                                                            inputProps={{
                                                                                step: 0.000001
                                                                            }}
                                                                            sx={{width: 100}}
                                                                        />
                                                                    </TableCell>}
                                                                </TableRow>
                                                            ))}
                                                        </React.Fragment>

                                                    }
                                                </React.Fragment>
                                            ))}
                                        </React.Fragment>
                                    ))}
                                </React.Fragment>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>}
        </React.Fragment>
    );
}

export default BillingPricesTable;