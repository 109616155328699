import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import BackupMetadataV3 from './backupMetadataV3';
import CustomText from '../../../../_common/CustomText';
import WrapperBox from '../../../../_common/WrapperBox';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import useWindowDimensions from 
'../../../../_common/WindowDimensions';
import Constants from '../../../../../config/constants';
import { IconButton }  from '@mui/material';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { 
    volumeCinderRequest, 
    getXAuthTokenProjectScope
 } from 
'../../../../../_network/openstack_request';
import { backupsUrl as backupUrlResponses } from 
'../../../../../_api_responses/openstack/cinder/backups/v3';
import { 
    openStackServices, 
    blockStorageCinderConstants
} from 
'../../../../../config/openStackConstants';
import CustomDialog from 
'../../../../_common/CustomDialog';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import { FormGroup } from '@mui/material';
import { 
    backupUpdateDataForm
} from '../../../../../_data/openstack/cinder/backups/v3';
import ServiceContentHeader from '../../../../_common/ServiceContentHeader';
import CustomSelectField from 
'../../../../_common/_form_fields/CustomSelectField';
import PlusButton from '../../../../_common/PlusButton';
import MinusButton from '../../../../_common/MinusButton';
import CustomTextField from '../../../../_common/_form_fields/CustomTextField';
import CloseIcon from '@mui/icons-material/Close';
import BackupSpecsV3 from './backupSpecsV3';

const SERVICE_NAME = openStackServices.volumeService
const FOOTER_HEIGHT = Constants.actions_bar_height + 20

const BackupDetailV3 = (props) => {
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const { selectedRow, selectedBackup } = props
    const [isCardLoading, setIsCardLoading] = useState(true)
    const AdminProjectId = useSelector(state => state.profile.defaultAdminProject.id)
    const adminProjects = useSelector(state => state.profile.adminProjects)
    const defaultAdminProject = adminProjects.filter(p => p.id === selectedRow[blockStorageCinderConstants.backupProjectIdField]).length > 0 ? 
    adminProjects.filter(p => p.id === selectedRow[blockStorageCinderConstants.backupProjectIdField])[0].id : AdminProjectId
    const { widthWeight } = props
    const { width } = useWindowDimensions();
    const FOOTER_WIDTH = width - (width * widthWeight)
    const { 
        handleFetchData,
        handleNavigateToVolume,
        handleStatusUpdate,
        handleDelete
    } = props
    
    const [updateMetadataMode, setUpdateMetadataMode] = useState(false)
    
    const [error, setError] = useState()
    const [successBackupUpdate, setSuccessBackupUpdate] = useState()
    const [successUpdateDialogOpen, setSuccessUpdateDialogOpen] = useState(false);
    
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [editBackupDialogOpen, setEditBackupDialogOpen] = useState(false);

    const [updateFormData, setUpdateFormData] = useState({})
    const [backupActions, setBackupActions] = useState([]);
    const [currentAction, setCurrentAction] = useState("");
    const [formMetadata, setFormMetadata] = useState([]);
    const [backupSubMenu, setBackupSubMenu] = useState([
        {keyword: "submenuDetails", navigation: "/sn-details", is_active: true},
        {keyword: "submenuMetadata", navigation: "/sn-metadata", is_active: false}
    ])
    
    const [currentTab, setCurrentTab] = useState("/sn-details")

    const cinderServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const cinderServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const backupsUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.backupsUrl)[0].url)
    
    const handleFormDataChange = (event,field_key) => {
        let new_form_data = {...updateFormData}
        if (backupUpdateDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (backupUpdateDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setUpdateFormData(new_form_data)
    }

    const getDataForm = () => {
        return (
            <FormGroup>
                {backupUpdateDataForm.map(field => {
                    let form_field_options = {}
                return (
                    getFormFieldComponent(
                        field,
                        updateFormData,
                        handleFormDataChange,
                        defaultTexts[field.label],
                        {
                            size:"medium",
                        ...form_field_options
                        }
                    )
                )
            })}
        </FormGroup>)
    }

    const handleEditBackupDialogOpen = () => {
        setEditBackupDialogOpen(true)
    }

    const handleEditBackupDialogClose = () => {
        setUpdateFormData({})
        setEditBackupDialogOpen(false)
    }
    
    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }
    
    const handleSuccessUpdateDialogClose = () => {
        setSuccessBackupUpdate(null)
        setUpdateFormData({})
        setSuccessUpdateDialogOpen(false);
    }
    const handleConfirmDeleteDialogOpen = () => {
        handleDelete([selectedRow.id])
        setCurrentAction("")
    }

    const handleEditBackup = async () => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${backupsUrl}/${selectedRow.id}`
            const method = "PUT"

            const sn_response = await volumeCinderRequest({
                url: url, 
                method: method, 
                data: {backup: updateFormData},
                token: project_token
            })
            if (sn_response.status_code === backupUrlResponses.put.success_response.status_code) {
                handleFetchData()
                handleEditBackupDialogClose()
                setCurrentAction("")
                setSuccessBackupUpdate({
                    success_title: backupUrlResponses.put.success_response.response_title, 
                    success_message: backupUrlResponses.put.success_response.response_message
                })
            } else {
                const error_response = backupUrlResponses.put.error_response.filter(
                    error_item => error_item.status_code === sn_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: sn_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = backupUrlResponses.put.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: sn_response.error
                    }
                    setError(errorObject)
                }
            }
        }
    }

    const formatMetadata = () => {
        let new_data = {}
        for (let i in formMetadata) {
            new_data[formMetadata[i].field_key] = formMetadata[i].field_value
        }
        const keys_to_delete = Object.keys(selectedRow.metadata).filter(key => !Object.keys(new_data).includes(key))
        return {keys_to_add: new_data, keys_to_delete: keys_to_delete}
    }

    const handleUpdateMetadata = async () => {
        const data = formatMetadata()
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${backupsUrl}/${selectedRow.id}`
            let method = "PUT"

            const response = await volumeCinderRequest({
                url: url, 
                method: method, 
                data: {backup: {metadata: data.keys_to_add}},
                token: project_token
            })
            if (response.status_code === backupUrlResponses.put.success_response.status_code) {
                setCurrentAction("")
                handleFetchData()
                handleUpdateMetadataModeReset()
            }
        }
    }

    const handleBackupDetailTabChange = useCallback((navigation) => {
        let newVTSubmenuData = backupSubMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
            } else {
                item.is_active = false
            }
            return item
        })
        setBackupSubMenu(newVTSubmenuData)
        setCurrentTab(navigation)
    },[
        backupSubMenu,
        setBackupSubMenu
    ])

    const handleUpdateMetadataModeChange = useCallback(() => {
        handleBackupDetailTabChange("/sn-metadata")
        setUpdateMetadataMode(true)
    },[handleBackupDetailTabChange])

    const handleBackupStatusUpdate = useCallback(() => {
        handleStatusUpdate([selectedRow.id])
        setCurrentAction("")
    },[
        handleStatusUpdate,
        selectedRow
    ])


    const handleMetadataChange = (event,field_key_list) => {
        let new_extra_data = [...formMetadata]
        if (field_key_list[1] === "key") {
            new_extra_data[field_key_list[0]].field_key = event.target.value
        } else {
            new_extra_data[field_key_list[0]].field_value = event.target.value
        }
        setFormMetadata(new_extra_data)
    }

    const handleMetadataRemove = (index) => {
        if (formMetadata.length > 1) {
            let updated_data = [...formMetadata]
            updated_data.splice(index, 1)
            setFormMetadata(updated_data)
        } else {
            setFormMetadata([])
        }
    }

    const handleUpdateMetadataModeReset = () => {
        if (Object.keys(selectedRow.metadata).length > 0) {
            const metadata_form = Object.keys(selectedRow.metadata).map(key => {
                let new_item = {}
                new_item["field_key"] = key
                new_item["field_value"] = selectedRow.metadata[key]
                return new_item
            })
            setFormMetadata(metadata_form)
        }
        setUpdateMetadataMode(false)
    }

    const getMetadataFormComponents = () => {
        return (
            <WrapperBox
                sx={{
                    m: 2, 
                    alignItems: 'start'
                }}
            >
                <IconButton
                    onClick={handleUpdateMetadataModeReset}
                    sx={{
                    position: 'absolute',
                    right: 8,
                    top: 130,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Stack 
                    direction="row" 
                    spacing={2} 
                    alignItems="center"
                    sx={{my: 3}}
                >
                    <CustomText size="h6">
                        {defaultTexts.metadataFormFieldLabel}
                    </CustomText>
                    <PlusButton 
                        onClick={() => setFormMetadata([
                            ...formMetadata,
                            {field_key: "",field_value: ""}
                        ])} 
                    />
                </Stack>
                {formMetadata.map((item,index) => {
                    return (
                        <Stack
                            key={index}
                            direction="row" 
                            spacing={2} 
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{my: 1}}
                        >
                            <CustomTextField
                                currentValue={item.field_key} 
                                setCurrentValue={handleMetadataChange}
                                field_key={[index,"key"]}
                                label={defaultTexts.keyFormFieldLabel}
                            />
                            <CustomTextField
                                currentValue={item.field_value} 
                                setCurrentValue={handleMetadataChange}
                                field_key={[index,"value"]}
                                label={defaultTexts.valueFormFieldLabel}
                            />
                            <MinusButton 
                                sx={{width: 90}} 
                                onClick={() => handleMetadataRemove(index)}
                            />
                        </Stack>
                    )
                })}
                <Button 
                    variant="contained"
                    color="secondary"
                    sx={{mt: 5}}
                    onClick={handleUpdateMetadata}
                >
                    {defaultTexts.saveButtonText}
                </Button>
            </WrapperBox>
        )
    }

    useEffect(() => {
        if (Object.keys(selectedRow.metadata).length > 0) {
            const metadata_form = Object.keys(selectedRow.metadata).map(key => {
                let new_item = {}
                new_item["field_key"] = key
                new_item["field_value"] = selectedRow.metadata[key]
                return new_item
            })
            setFormMetadata(metadata_form)
        }
    },[
        selectedRow
    ]);

    useEffect(() => {
        if (Object.keys(updateFormData).length === 0) {
            let new_form_data = {}
            for (const n in backupUpdateDataForm) {
                new_form_data[backupUpdateDataForm[n].field_key] = selectedRow[backupUpdateDataForm[n].field_key]
            }
            setUpdateFormData(new_form_data)
        }
    },[updateFormData, selectedRow]);


    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessUpdateDialogOpen(true)
    },[successBackupUpdate]);

    useEffect(() => {
        if (selectedRow !== null) {
            let sn_actions = []
            let new_action = {}
            new_action["value"] = "volume_update"
            new_action["action"] = handleEditBackupDialogOpen
            new_action["keyword"] = "backupUpdateActionTitle"
            new_action["button_text"] = "selectButtonTitleText"
            sn_actions.push({...new_action})
            new_action = {}
            new_action["value"] = "volume_status_update"
            new_action["action"] = handleBackupStatusUpdate
            new_action["keyword"] = "backupStatusUpdateActionTitle"
            new_action["button_text"] = "selectButtonTitleText"
            sn_actions.push({...new_action})
            new_action = {}
            new_action["value"] = "update_metadata"
            new_action["action"] = handleUpdateMetadataModeChange
            new_action["keyword"] = "createUpdateMetadataActionTitle"
            new_action["button_text"] = "selectButtonTitleText"
            sn_actions.push({...new_action})
            new_action = {}
            new_action["value"] = "restore_backup"
            new_action["action"] = () => {}
            new_action["keyword"] = "restoreBackupActionTitle"
            new_action["button_text"] = "selectButtonTitleText"
            sn_actions.push({...new_action})
           
            setBackupActions(sn_actions)
        }
    },[
        adminProjects,
        selectedRow,
        defaultTexts,
        handleStatusUpdate,
        handleBackupStatusUpdate,
        handleUpdateMetadataModeChange
    ]);

    useEffect(() => {
        setTimeout(() => setIsCardLoading(false), 600)
    },[])

    return (
        <React.Fragment>
        {selectedRow !== null && 
            <WrapperBox>
                <ServiceContentHeader 
                    service_menu={backupSubMenu}
                    service_menu_titles={defaultTexts}
                    onClick={handleBackupDetailTabChange}
                />
            </WrapperBox>}
            {currentTab === "/sn-details" &&
                <BackupSpecsV3 
                    backupData={selectedBackup}
                    selectedRow={selectedRow}
                    handleNavigateToVolume={handleNavigateToVolume}
                />
            }
                {currentTab === "/sn-metadata" && 
                !updateMetadataMode &&  
                <BackupMetadataV3 
                    backupMetadata={selectedRow.metadata}
                />
                }
                {currentTab === "/sn-metadata" && 
                updateMetadataMode &&  
                    getMetadataFormComponents()
                }
                {!isCardLoading && <Paper sx={{ 
                    position: 'fixed', 
                    bottom: 0, 
                    height: FOOTER_HEIGHT,
                    left: FOOTER_WIDTH, 
                    right: 12,
                    zIndex: 3000
                    }} 
                    elevation={24}
                    square={true}
                >
                    <Grid 
                        container 
                        alignItems="center"  
                        justifyContent="space-between"
                    >
                        <Grid item>
                            <CustomSelectField 
                                items={backupActions} 
                                currentValue={currentAction}
                                setCurrentValue={setCurrentAction}
                                item_titles={defaultTexts}
                                label={defaultTexts.actionsDropdownLabelText}
                                empty={true}
                                size="small"
                                sx={{m: 1}}
                            />
                            {currentAction.length > 0 && 
                                <Button 
                                        variant="contained"
                                        color="secondary"
                                        sx={{m: 1, height: '70%'}}
                                        onClick={backupActions.filter(
                                            action => action.value === currentAction)[0].action
                                        }
                                    >
                                    {defaultTexts[backupActions.filter(
                                        action => action.value === currentAction)[0].button_text]}
                                </Button>
                            }
                        </Grid>
                        <Grid item>
                            {selectedRow !== null && 
                                <IconButton onClick={handleConfirmDeleteDialogOpen}>
                                    <DeleteIcon 
                                        color="primary"
                                    />
                                </IconButton>}
                        </Grid>
                    </Grid>
                </Paper>}
                <CustomDialog
                    open={editBackupDialogOpen}
                    onClose={handleEditBackupDialogClose}
                    dialogTitle={{
                        title: defaultTexts.updateBackupActionTitle, 
                        sx: {color: 'primary.main'}}}
                    dialogBody={{
                        text: "", 
                        sx: {color: 'text.primary'}}}
                    actionButtons={[{
                        title: defaultTexts.submitButtonText, 
                        onClick: handleEditBackup, 
                        sx: {color: 'primary.main'}}]}
                >
                    {getDataForm()}
            </CustomDialog>
            {successBackupUpdate && <CustomDialog
                open={successUpdateDialogOpen}
                onClose={handleSuccessUpdateDialogClose}
                dialogTitle={{
                    title: defaultTexts[successBackupUpdate.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: successBackupUpdate.success_details ?
                        `<span>${defaultTexts[successBackupUpdate.success_message]}</span>
                            <br>
                            <br>
                            <span>Secret Key:</span> 
                            <span style="width: 100px; color: orange; word-wrap: break-word;">
                                ${successBackupUpdate.success_details}
                            </span>` :
                        `<span>${defaultTexts[successBackupUpdate.success_message]}</span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </React.Fragment>
    )

};

export default BackupDetailV3;