import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomDrawer from '../../_common/CustomDrawer';
import ServiceContentHeader from '../../_common/ServiceContentHeader';
import GeneralConfigsContent from './generalConfigsContent';
import ServiceConfigsContent from './servicesConfigsContent';
import Constants from '../../../config/constants';

const BillingConfigsWrapper = (props) => {
    const location = useLocation();
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const purchasedService = useSelector(
        state => state.openstack.purchasedServicesAll)
    const [billingServicesChecked, setBillingServicesChecked] = useState(false)
    const navigate = useNavigate();

    if (!isAuthenticated) {
        navigate('/');
    }

    const [serviceMenu, setServiceMenu] = useState([
        {keyword: "submenuBillingConfigGeneral", navigation: "/billing-config-general", is_active: true, component: GeneralConfigsContent}
    ])

    useEffect(() => {
        if (!billingServicesChecked && purchasedService.length > 0) {
            let updateSubMenu = [...serviceMenu]
            const billingService = purchasedService.filter(
            service => service.service === Constants.config_service_billing_name)
            const billingConfiguration = billingService[0].config_params[Constants.config_service_custom_admin_ui]
            if (billingConfiguration.filter(
                section => section.section === Constants.billing_sections.billing_services && 
                section.permissions.includes(Constants.permission_read)).length > 0) {
                    updateSubMenu.push(
                    {
                        keyword: "submenuBillingServices", 
                        navigation: "/billing-config-services", 
                        is_active: false, 
                        component: ServiceConfigsContent
                    }
                )
            }
            setServiceMenu(updateSubMenu)
            setBillingServicesChecked(true)
        }
        
    },[
        purchasedService,
        defaultTexts,
        billingServicesChecked,
        serviceMenu
    ])    

    const getCurrentTab = () => {
        const currentItem = serviceMenu.filter(item => item.is_active)[0]
        return currentItem
    }

    const [currentTab, setCurrentTab] = useState(getCurrentTab())

    const changeMenuActiveTab = (navigation) => {
        const updated_menu = serviceMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
                setCurrentTab(item)
            } else {
                item.is_active = false
            }
            return item
        })
        setServiceMenu(updated_menu)
    }

    return (
        <CustomDrawer>
            <ServiceContentHeader 
                service_title={defaultTexts.billingConfigsNavLink}
                service_menu={serviceMenu}
                service_menu_titles={defaultTexts}
                onClick={changeMenuActiveTab}
            />
            <currentTab.component 
                navigate={navigate} 
                location={location}
            />
        </CustomDrawer>
    )
}

export default BillingConfigsWrapper;