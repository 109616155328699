export const serviceDataSchema = [
    {
        field_key: "binary",
        is_id_field: true,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "disabled_reason",
        is_id_field: false,
        field_type: "string", 
        label: "disabledReasonFormFieldLabel"
    },
    {
        field_key: "host",
        is_id_field: false,
        field_type: "string", 
        label: "hostFormFieldLabel"
    },
    {
        field_key: "state",
        is_id_field: false,
        field_type: "string", 
        label: "stateFormFieldLabel"
    },
    {
        field_key: "status",
        is_id_field: false,
        field_type: "string", 
        label: "statusFormFieldLabel"
    },
    {
        field_key: "updated_at",
        is_id_field: false,
        field_type: "datetime", 
        label: "updatedAtFormFieldLabel"
    },
    {
        field_key: "forced_down",
        is_id_field: false,
        field_type: "bool", 
        label: "forcedDownFormFieldLabel",
        value_on_true: "formValueYes",
        value_on_false: "formValueNo"
    },
    {
        field_key: "zone",
        is_id_field: false,
        field_type: "string", 
        label: "zoneFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: false,
        field_type: "string", 
        label: "idFormFieldLabel"
    },
]

export const aggregateDataSchema = [
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "availability_zone",
        is_id_field: false,
        field_type: "string", 
        label: "availabilityZoneFormFieldLabel"
    },
    {
        field_key: "uuid",
        is_id_field: false,
        field_type: "string", 
        label: "uuidFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    },
    {
        field_key: "hosts",
        is_id_field: false,
        field_type: "list", 
        label: "hostsFormFieldLabel"
    }
]

export const aggregateDataForm = [
    {
        field_key: "name",
        field_type: "string", 
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "availability_zone",
        field_type: "string", 
        required: false,
        label: "availabilityZoneFormFieldLabel"
    }
]

export const aggregatesFilterMenu = [
    {
        keyword: "nameFormFieldLabel", 
        value: "name", 
        type: "text"
    },
    {
        keyword: "availabilityZoneFormFieldLabel", 
        value: "availability_zone", 
        type: "select",
        items: [],
        self_item_titles: true,
        default_empty: true
    }
]

export const aggregateDetailSchema = [
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "availability_zone",
        is_id_field: false,
        field_type: "string", 
        label: "availabilityZoneFormFieldLabel"
    },
    {
        field_key: "uuid",
        is_id_field: false,
        field_type: "string", 
        label: "uuidFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: false,
        field_type: "string", 
        label: "idFormFieldLabel"
    },
    {
        field_key: "created_at",
        is_id_field: false,
        field_type: "datetime", 
        label: "createdFormFieldLabel"
    },
    {
        field_key: "updated_at",
        is_id_field: false,
        field_type: "datetime", 
        label: "updatedAtFormFieldLabel"
    }
]

export const availabilityZoneDataSchema = [
    {
        field_key: "zoneName",
        is_id_field: true,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "hosts",
        is_id_field: false,
        field_type: "list", 
        label: "hostsFormFieldLabel"
    },
    {
        field_key: "zoneState",
        is_id_field: false,
        field_type: "bool", 
        label: "availableFormFieldLabel",
        value_on_true: "formValueYes",
        value_on_false: "formValueNo"
    },
    //{
    //    field_key: "services",
    //    is_id_field: false,
    //    field_type: "list", 
    //    label: "servicesFormFieldLabel"
    //}
]

export const hypervisorDataSchema = [
    {
        field_key: "hypervisor_hostname",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel",
        max_api_version: null,
        min_api_version: null,
    },
    {
        field_key: "hypervisor_type",
        is_id_field: false,
        field_type: "string", 
        label: "hypervisorTypeFormFieldLabel",
        max_api_version: null,
        min_api_version: null,
    },
    {
        field_key: "host_ip",
        is_id_field: false,
        field_type: "string", 
        label: "hostIPFormFieldLabel",
        max_api_version: null,
        min_api_version: null,
    },
    {
        field_key: "state",
        is_id_field: false,
        field_type: "string", 
        label: "stateFormFieldLabel",
        max_api_version: null,
        min_api_version: null,
    },
    {
        field_key: "status",
        is_id_field: false,
        field_type: "string", 
        label: "statusFormFieldLabel",
        max_api_version: null,
        min_api_version: null,
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel",
        max_api_version: null,
        min_api_version: null,
    },
    {
        field_key: "servers",
        is_id_field: false,
        field_type: "string", 
        label: "serverInstancesFormFieldLabel",
        min_api_version: "2.53",
        max_api_version: null
    },
    {
        field_key: "running_vms",
        is_id_field: false,
        field_type: "string", 
        label: "runningVMsFormFieldLabel",
        max_api_version: "2.52",
        min_api_version: null,
    }
]