export const billingTexts = {
    billingSectionTitle: "Billing",
    billingConfigsNavLink: "Billing Configs",
    billingPricesNavLink: "Manage Prices",
    billingDepositsNavLink: "Manage Accounts",
    billingAnalyticsNavLink: "Billing Analytics",
    submenuBillingConfigGeneral: "General",
    submenuBillingServices: "Services",
    submenuBillingAnalyticsDashboard: "Dashboard",
    submenuBillingAnalyticsAccounting: "Accounting",
    submenuBillingAnalyticsPayments: "Payments",
    submenuBillingAnalyticsInvoices: "Invoices",
    billingServiceSubscriptionLabel: "Billing Subscription",
    billingDefaultDepositTypeLabel: "Default Customer Walet",
    billingInvoiceTypeLabel: "Invoice for Postpaid Customers",
    billingDefaultInvoicePayPeriodLabel: "Default Invoice Pay Period",
    currencyLabel: "Currency",
    billingUseVATTaxLabel: "Use VAT Tax",
    billingVATIncludedLabel: "Price includes VAT Tax",
    billingVATPercentLabel: "VAT Tax Percent",
    paypalCardTitle: "PayPal Payment",
    paymentMethodSeeDetails: "Configuration Details",
    clientIDHelpText: "Client ID",
    configurationOptionsTitle: "Configuration Options",
    paypalCardText: "Customers paying with PayPal method, will use their PayPal account to pay service charges, and all payments will be placed directly to Cloud Provider's PayPal account configured for this payment method.",
    regionFormFieldLabel: "Region",
    minutesUnitLabel: "Minute(s)",
    daysUnitLabel: "Day(s)",
    hoursUnitLabel: "Hour(s)",
    serviceDomainFormFieldLabel: "Service Domain",
    serviceVersionFormFieldLabel: "Service Version",
    resourceSuspendDelayFormFieldLabel: "Suspend Action Delay",
    resourceSuspendPolicyFormFieldLabel: "Suspend Action",
    resourceDeleteDelayFormFieldLabel: "Final Action Delay",
    resourceDeletePolicyFormFieldLabel: "Final Action",
    isIdentityProviderFormFieldLabel: "Is Identity Provider",
    billingServiceUpdateActionTitle: "Update Service",
    billingServiceFormFieldHelpText: "Select Openstack service, for which you want to start billing actions.",
    billingRegionFormFieldHelpText: "Select Openstack region, if your deployment supports multiple regions.",
    billingServiceVersionFormFieldHelpText: "Choose API version for selected Openstack service.",
    billingActionKeepChoiceValue: "Keep resources untuched",
    billingActionSuspendChoiceValue: "Suspend instances",
    billingActionShelveChoiceValue: "Shelve offload instances",
    billingActionDeleteChoiceValue: "Delete all paid resources",
    addBillingServiceDialogTitle: "Add New Service",
    addBillingServiceDialogDescriptionText: "Please, select Openstack service and/or region (for multi-region deployments) and provide all required details. This information is required for billing system to fetch resource usage data and make necessary calculations.",
    billingSuspendActionFormFieldHelpText: "Suspend action policy instructs billing system to perform certain action, when user is out of money (for prepaid account types) or invoice due date has passed (for postpaid account types). Different Openstack services may have different suspend policies. This action is performed after suspend action delay period has passed.",
    billingDeleteActionFormFieldHelpText: "Final action policy instructs billing system to perform certain action, when user is out of money (for prepaid account types) or invoice due date has passed (for postpaid account types) and suspend action has already performed. Different Openstack services may have different final action policies. This action is performed after final action delay period has passed.",
    billingServiceDomainFormFieldHelpText: "Provide address (domain) for Openstack service. Address must be given in the following format: https://service_domain:port_number.",
    billingSuspendPeriodFormFieldHelpText: "Suspend action delay is the time (in minutes), which will be waited before suspend action is being performed by billing system.",
    billingDeletePeriodFormFieldHelpText: "Final action delay is the time (in minutes), which will be waited before final action is being performed by billing system.",
    serviceIsIdentityProviderFormFieldHelpText: "If Openstack service is used for authentication and authorization, this checkbox must be selected. Only one Openstack service can be configured as identity provider service.",
    appCredentialsIDFormFieldHelpText: "Specify Openstack app credentials ID, which will be used for users and billing service authentication and authorization. These credentials must belong to Openstack user with Admin role, but can be limitted to only those services and endpoints, which are required to perform data fetch and perform suspend and final actions. Visit configuration portal to see exact list of required endpoints.",
    appCredentialsSecretFormFieldHelpText: "Specify Openstack app credentials secret, which will be used for users and billing service authentication and authorization. These credentials must belong to Openstack user with Admin role, but can be limitted to only those services and endpoints, which are required to perform data fetch and perform suspend and final actions. Visit configuration portal to see exact list of required endpoints.",
    appCredentialsIDFormFieldLabel: "App Credentials ID",
    appCredentialsSecretFormFieldLabel: "App Credentials Secret",
    requiredAndPositiveFormFieldError: "This field is required and must be positive integer",
    serviceUpdateDialogTitle: "Update Service Details",
    serviceDeleteDialogTitle: "Delete Service",
    serviceDeleteDialogText: "Are you sure, you want to delete this service?",
    filterByRegionNoteText: "Filter by Region",
    filterByServiceNoteText: "Filter by Service",
    filterByResourceNoteText: "Filter by Paid Resource",
    allRegionsFormFieldChoice: "All Regions",
    allServicesFormFieldChoice: "All Services",
    allResourcesFormFieldChoice: "All Resources",
    resourceFormFieldLabel: "Resource",
    configuredPricesTabTitle: "Configured Prices",
    newResourcesTabTitle: "New Resources",
    deletedResourcesTabTitle: "Deleted Resources",
    editButtonTitle: "Edit",
    saveButtonTitle: "Save",
    priceFormFieldLabel: "Price",
    ruleRangeFormFieldTitle: "Rule Range",
    instansActiveStatusNoteText: "Active Instanse Status",
    instansNotActiveStatusNoteText: "Not Active Instanse Status",
    resetFiltersTooltipText: "Reset Filters",
    noPriceTableTitle: "No Price",
    newAccountsCardTitleLabel: "New Accounts",
    todayNewAccountsLabel: "Today",
    thisWeekNewAccountsLabel: "This Week",
    thisMonthNewAccountsLabel: "This Month",
    totalAccountsCardTitleLabel: "Total Accounts",
    prepaidAccountTypeChartTitle: "Prepaid",
    postpaidAccountTypeChartTitle: "Postpaid",
    accountsStatusesCardTitleLabel: "Accounts Statuses",
    activeStatusChartLegent: "Active",
    inactiveStatusChartLegent: "Inactive",
    suspendedStatusChartLegent: "Suspended",
    deletedStatusChartLegent: "Deleted",
    autoInvoiceTypeDescription: "Auto by billing system",
    manualInvoiceTypeDescription: "Manual outside of billing system",
    balanceFormFieldLabel: "Balance",
    billingTypeFormFieldLabel: "Billing Type",
    postpaidInvoiceTypeFormFieldLabel: "If Postpaid: Invoice Type",
    postpaidInvoicePayPeriodFormFieldLabel: "Invoice Pay Period (Days)",
    accountAgeFormFieldLabel: "Account Age",
    suspendDurationFormFieldLabel: "Suspend Duration",
    activeDepositStatusText: "Active",
    inactiveDepositStatusText: "Inactive",
    prepaidDepositTypeText: "Prepaid",
    postpaidDepositTypeText: "Postpaid",
    autoInvoiceTypeText: "Automatic",
    manualInvoiceTypeText: "Manual",
    activeStatusFormFieldLabel: "Active Status",
    otherStatusFormFieldLabel: "Other Status",
    invoiceTypeFilterMenuChoiceText: "Invoice Type",
    noDataNoteTitle: 'No Data',
    incomePieChartLabel: "Income",
    taxPieChartLabel: "Tax",
    prepaidPieChartLabel: "Prepaid",
    postpaidPieChartLabel: "Postpaid",
    topNProjectsFormFieldLabel: "Top N Projects",
    projectUsagePieChartLabel: "Projects",
    incomeSummaryCardTitle: "Income Summary",
    serviceBreakdownCardTitle: "Service Breakdown",
    nTopProjectsCardTitle: "Top N Projects",
    compareUsageCardTitle: "Compare Usage",
    compareIncomeCardTitle: "Compare Income",
    usageSummaryCardTitle: "Usage Summary",
    totalHoursResourceDecription: "Total Hrs",
    firewallRulesMaxCountDescription: "Max Rules",
    pricePerHourDescription: "Price per Hour",
    totalSizeDescription: "Total Size",
    todayPaymentsCardTitle: "Today",
    thisWeekPaymentsCardTitle: "This Week",
    thisMonthPaymentsCardTitle: "This Month",
    paymentsCountCardLabel: "Payments Count",
    paymentsAmountCardLabel: "Total Amount",
    invoiceIDFilterMenuChoiceText: "Invoice ID",
    timeStampFormFieldLabel: "Time Stamp",
    paymentMethodFormFieldLabel: "Payment Method",
    invoiceIDTypeFormFieldLabel: "Invoice ID",
    amountFormFieldLabel: "Amount",
    transactionDetailsFormFieldLabel: "Transaction Details",
    billingTransactionDetailsDialogTitle: "Transaction Details",
    noDataNoteMessage: "No Transaction Data",
    priceIncludesVATTaxText: "Price includes VAT",
    priceNotIncludesVATTaxText: "Price not includes VAT",
    priceNotCountingVATTaxText: "No Tax",
    timeStampFilterChoice: "Time Stamp",
    dueDateFilterChoice: "Due Date",
    pendingStatusChartLegent: "Pending",
    paidStatusChartLegent: "Paid",
    obsoleteStatusChartLegent: "Obsolete",
    dueDateFormFieldLabel: "Due Date",
    vatTaxFormFieldLabel: "VAT Tax",
    billingActionDisableChoiceValue: "Disable all paid resources",
    addNewResourceButtonTitle: "Add New Resouce",
    ruleRangeStartFormFieldLabel: "Rule Range Start",
    ruleRangeEndFormFieldLabel: "Rule Range End",
    selectNewResourceToCreateFormTitle: "Add New Resource",
    selectNewResourceToCreateFormText: "Select resource below, to create new price item.",
    firewallRuleRangeFormFieldLable: "Firewall Rule Range"
}