import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CustomDialog from '../../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../../_common/CustomBackdrop';
import FormGroup from '@mui/material/FormGroup';
import { getFormFieldComponent } from 
'../../../../../_common/_form_fields/form_helpers';
import { openStackServices, 
        networkNeutronConstants, 
        identityKeystonConstants
} from '../../../../../../config/openStackConstants';
import VPNEndpointGroupsSubheaderV20 from './vpnEndpointGroupsSubheaderV2.0';
import VPNEndpointGroupsTableV20 from './vpnEndpointGroupsTableV2.0';
import { vpnEndpointGroupsFilterMenu, vpnEndpointGroupDataForm } 
from '../../../../../../_data/openstack/neutron/vpn/v2.0';
import { 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../../_network/openstack_request';
import { vpnUrl as vpnUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/vpn/v2.0';
import { projectsUrl as projectUrlResponses} from 
'../../../../../../_api_responses/openstack/identity/projects/v3';

const SERVICE_NAME = openStackServices.networkService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const VPNEndpointGroupsWrapperV20 = (props) => {
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const {currentTab, setCurrentTab} = props
    const [vpnEndpointGroupsData, setVPNEndpointGroupsData] = useState([])
    const [vpnEndpointGroups, setVPNEndpointGroups] = useState([])
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [currentAction, setCurrentAction] = useState("");
    const [vpnEndpointGroupDeleteConfirmDialogOpen, setVPNEndpointGroupDeleteConfirmDialogOpen] = useState(false);
    const [selectedVPNEndpointGroups, setSelectedVPNEndpointGroups] = useState([])
    
    const [vpnEndpointGroupFilterQueryParams, setVPNEndpointGroupFilterQueryParams] = useState("")
    const [selectedVPNEndpointGroupFilter, setSelectedVPNEndpointGroupFilter] = useState(vpnEndpointGroupsFilterMenu[0].value)
    const [selectedVPNEndpointGroupFilterValue, setSelectedVPNEndpointGroupFilterValue] = useState("")
    const [vpnEndpointGroupsFilter, setVPNEndpointGroupsFilter] = useState([...vpnEndpointGroupsFilterMenu])

    const [projects, setProjects] = useState([])
    const [subnets, setSubnets] = useState([])
    const [updateDataOptions, setUpdateDataOptions] = useState({});
    const [updateFormData, setUpdateFormData] = useState({});
    const [vpnEndpointGroupUpdateDialogOpen, setVPNEndpointGroupUpdateDialogOpen] = useState(false)
    const [selectedFieldKey, setSelectedFieldKey] = useState("")
    const [selectedFieldTitleText, setSelectedFieldTitleText] = useState("")

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const vpnEndpointGroupsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.vpnEndpointGroupsUrl)[0].url)
    const subnetsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.subnetsUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleTabChange = (tab_name) => {
        setCurrentTab(tab_name)
    }

    const handleVPNEndpointGroupFilteredSearch = () => {
        if (selectedVPNEndpointGroupFilter && selectedVPNEndpointGroupFilterValue) {
            setVPNEndpointGroupFilterQueryParams(`${selectedVPNEndpointGroupFilter}=${selectedVPNEndpointGroupFilterValue}`)
        } else {
            setVPNEndpointGroupFilterQueryParams("")
        }
        handleDataFetch()
    }

    const handleVPNEndpointGroupsDataFormatting = useCallback(() => {
        const formatted_data = vpnEndpointGroupsData.map((item) => {
            let new_item = {...item}
            const project = projects.filter(p => p.id === item.project_id)
            if (project.length > 0) {
                new_item.project_id = project[0].name
            } else {
                new_item.project_id = "-"
            }
            if (item.type === "subnet") {
                const subnet_list = item.endpoints.map(s => {
                    const subnet = subnets.filter(i => i.id === s)
                    if (subnet.length > 0) {
                        return `${subnet[0].name}: ${subnet[0].cidr}`
                    } else {
                        return s
                    }
                })
                new_item.endpoints = subnet_list
            }
            return new_item
        })
        setVPNEndpointGroups(formatted_data)
    },[
        vpnEndpointGroupsData,
        projects,
        subnets
    ])

    const handleVPNEndpointGroupFilterReset = () => {
        setSelectedVPNEndpointGroupFilter(vpnEndpointGroupsFilterMenu[0].value)
        setSelectedVPNEndpointGroupFilterValue("")
        setVPNEndpointGroupFilterQueryParams("")
        handleDataFetch()
    }

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleFormDataChange = (event,field_key) => {
        setUpdateDataOptions({})
        let new_form_data = {...updateFormData}
        if (vpnEndpointGroupDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (vpnEndpointGroupDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setUpdateFormData(new_form_data)
    }

    const getDataForm = () => {
        let form = vpnEndpointGroupDataForm.filter(i => i.field_key === selectedFieldKey)
        return (
            <FormGroup>
                {form.map(field => {
                    let form_field_options = {...updateDataOptions[field.field_key]}
                    form_field_options = {...form_field_options, ...field}
                    delete form_field_options["label"]
                    form_field_options["item_titles"] = defaultTexts
                    return (
                        getFormFieldComponent(
                            field,
                            updateFormData,
                            handleFormDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleUpdateDataValidation = (field_key, data) => {
        if (data[field_key].length === 0) {
            let options = {}
            options[field_key] = {}
            options[field_key]["error"] = true
            options[field_key]["errorText"] = defaultTexts.requiredFormFieldError
            setUpdateDataOptions(options)
            return false
        } else {
            return true
        }
    }

    const handleVPNEndpointGroupUpdate = async (eg_id,field_key,data) => {
        const data_is_valid = handleUpdateDataValidation(field_key,data)
        let update_data = {}
        update_data[field_key] = data[field_key]
        if (data_is_valid) {
            handleVPNEndpointGroupUpdateDialogClose()
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const method = "PUT"
                const vpnGroup_response = await openstackRequest({
                    url: `${neutronServiceDomain}/${neutronServiceVersion}/${vpnEndpointGroupsUrl}/${eg_id}`, 
                    method: method, 
                    data: {endpoint_group: update_data},
                    token: project_token
                })
                if (vpnGroup_response.status_code === vpnUrlResponses.put.success_response.status_code) {
                    return null
                } else {
                    return vpnGroup_response.error
                }
            }
        } 
    }

    const onVPNEndpointGroupUpdate = async () => {
        let err = []
        for (let n in selectedVPNEndpointGroups) {
            const resp = await handleVPNEndpointGroupUpdate(
                selectedVPNEndpointGroups[n].id,
                selectedFieldKey,
                updateFormData)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDataFetch()
        setUpdateDataOptions({})
        setUpdateFormData({})
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onVPNEndpointGroupUpdateNameDialogOpen = useCallback((n_list) => {
        const selected_n_list = vpnEndpointGroupsData.filter(n => 
            n_list.includes(n.id))
        setSelectedVPNEndpointGroups([...selected_n_list])
        setSelectedFieldKey("name")
        setSelectedFieldTitleText(defaultTexts.updateEndpointGroupNameTitle)
        setVPNEndpointGroupUpdateDialogOpen(true)
    },[vpnEndpointGroupsData, defaultTexts])

    const onVPNEndpointGroupUpdateDescriptionDialogOpen = useCallback((n_list) => {
        const selected_n_list = vpnEndpointGroupsData.filter(n => 
            n_list.includes(n.id))
        setSelectedVPNEndpointGroups([...selected_n_list])
        setSelectedFieldKey("description")
        setSelectedFieldTitleText(defaultTexts.updateEndpointGroupDescriptionTitle)
        setVPNEndpointGroupUpdateDialogOpen(true)
    },[vpnEndpointGroupsData, defaultTexts])


    const handleVPNEndpointGroupUpdateDialogClose = () => {
        setVPNEndpointGroupUpdateDialogOpen(false)
    }

    const handleVPNEndpointGroupDelete = async (n_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${neutronServiceDomain}/${neutronServiceVersion}/${vpnEndpointGroupsUrl}/${n_id}`
            const method = "DELETE"
            
            const nt_response = await openstackRequest({
                url:url, 
                method:method,
                token: project_token
            })

            if (nt_response.status_code === vpnUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return nt_response.error
            }
        }
    };

    const onVPNEndpointGroupDelete = async () => {
        handleVPNEndpointGroupDeleteConfirmDialogClose()
        let err = []
        for (let n in selectedVPNEndpointGroups) {
            const resp = await handleVPNEndpointGroupDelete(selectedVPNEndpointGroups[n].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }


    const onVPNEndpointGroupDeleteConfirm = useCallback((n_list) => {
        const selected_n_list = vpnEndpointGroupsData.filter(n => 
            n_list.includes(n.id))
        setSelectedVPNEndpointGroups([...selected_n_list])
        setVPNEndpointGroupDeleteConfirmDialogOpen(true)
    },[vpnEndpointGroupsData])

    const handleVPNEndpointGroupDeleteConfirmDialogClose = () => {
        setVPNEndpointGroupDeleteConfirmDialogOpen(false)
    }

    const getVPNEndpointGroupsActionsList = useCallback(() => {
        let vpnEndpointGroup_actions = []
        let new_action = {}
        new_action["value"] = "vpnEndpointGroup_update_name"
        new_action["action"] = onVPNEndpointGroupUpdateNameDialogOpen
        new_action["keyword"] = "vpnEndpointGroupUpdateNameActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        vpnEndpointGroup_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "vpnEndpointGroup_update_description"
        new_action["action"] = onVPNEndpointGroupUpdateDescriptionDialogOpen
        new_action["keyword"] = "vpnEndpointGroupUpdateDescriptionActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        vpnEndpointGroup_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "vpnEndpointGroup_delete"
        new_action["action"] = onVPNEndpointGroupDeleteConfirm
        new_action["keyword"] = "vpnEndpointGroupDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        vpnEndpointGroup_actions.push({...new_action})
        
        return vpnEndpointGroup_actions
    },[
        onVPNEndpointGroupDeleteConfirm,
        onVPNEndpointGroupUpdateNameDialogOpen,
        onVPNEndpointGroupUpdateDescriptionDialogOpen
    ])

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${neutronServiceDomain}/${neutronServiceVersion}/${vpnEndpointGroupsUrl}?${vpnEndpointGroupFilterQueryParams}`
                    const method = "GET"
                    const vpnEndpointGroup_response = await openstackRequest({url:url, method:method, token: project_token})
                    if (vpnEndpointGroup_response.status_code === vpnUrlResponses.get.success_response.status_code) {
                        setVPNEndpointGroupsData(vpnEndpointGroup_response.data.endpoint_groups)
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        vpnEndpointGroupsUrl, 
        vpnEndpointGroupFilterQueryParams,
        dataFetchingRequired,
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            handleLoading(true)
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${neutronServiceDomain}/${neutronServiceVersion}/${subnetsUrl}`
                const method = "GET"
                const subnets_response = await openstackRequest({url:url, method:method, token: project_token})
                if (subnets_response.status_code === vpnUrlResponses.get.success_response.status_code) {
                    setSubnets(subnets_response.data.subnets)
                }
            }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        subnetsUrl, 
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === projectUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        if (vpnEndpointGroupsData.length > 0) {
            handleVPNEndpointGroupsDataFormatting()
        }
    },[
        vpnEndpointGroupsData,
        handleVPNEndpointGroupsDataFormatting
    ])

    useEffect(() => {
        let projects_filter = []
        if (projects.length > 0) {
            projects_filter = projects.map(project => {
                return {keyword: project.name, value: project.id, default: false}
            })
        }
        let vpnEndpointGroup_filter_menu = vpnEndpointGroupsFilterMenu.map(nt => {
            let new_item = {...nt}
            if (nt.value === "project_id") {
                new_item.items = [...projects_filter]
            }
            return new_item
        })

        setVPNEndpointGroupsFilter(vpnEndpointGroup_filter_menu)
    },[
        vpnEndpointGroupsData,
        projects
    ])

    useEffect(() => {
        if (Object.keys(updateFormData).length === 0) {
            let new_form_data = {}
            for (const n in vpnEndpointGroupDataForm) {
                if (
                    vpnEndpointGroupDataForm[n].field_type === "string" || 
                    vpnEndpointGroupDataForm[n].field_type === "select"
                    ) {
                    new_form_data[vpnEndpointGroupDataForm[n].field_key] = vpnEndpointGroupDataForm[n].default_value ? 
                    vpnEndpointGroupDataForm[n].default_value : 
                    ""
                } else if (vpnEndpointGroupDataForm[n].field_type === "integer") {
                    new_form_data[vpnEndpointGroupDataForm[n].field_key] = 3600
                } else if (vpnEndpointGroupDataForm[n].field_type === "bool") {
                    new_form_data[vpnEndpointGroupDataForm[n].field_key] = vpnEndpointGroupDataForm[n].default_value ? 
                    vpnEndpointGroupDataForm[n].default_value : 
                    false
                }
            }
            setUpdateFormData(new_form_data)
        }
    },[
        updateFormData
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <VPNEndpointGroupsSubheaderV20 
                selectedFilter={selectedVPNEndpointGroupFilter} 
                setSelectedFilter={setSelectedVPNEndpointGroupFilter}
                selectedFilterValue={selectedVPNEndpointGroupFilterValue}
                setSelectedFilterValue={setSelectedVPNEndpointGroupFilterValue}
                handleDataFetch={handleDataFetch}
                filterMenu={vpnEndpointGroupsFilter}
                handleFilteredSearch={handleVPNEndpointGroupFilteredSearch}
                handleFilterReset={handleVPNEndpointGroupFilterReset}
                currentTab={currentTab}
                handleTabChange={handleTabChange}
                projects={projects}
                subnets={subnets}
            />
        {isLoading && <CustomBackdrop open={isLoading} />}
        {!isLoading &&
            <VPNEndpointGroupsTableV20 
                vpnEndpointGroupsData={vpnEndpointGroups}
                setVPNEndpointGroupsData={setVPNEndpointGroups}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getVPNEndpointGroupsActionsList()}
                projects={projects}
            />
        }
        <CustomDialog
            open={vpnEndpointGroupDeleteConfirmDialogOpen}
            onClose={handleVPNEndpointGroupDeleteConfirmDialogClose}
            dialogTitle={{
                title: defaultTexts.vpnEndpointGroupDeleteConfirmTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `${defaultTexts.vpnEndpointGroupDeleteConfirmText}`, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onVPNEndpointGroupDelete, 
                sx: {color: 'primary.main'}}]}
        />
        <CustomDialog
            open={vpnEndpointGroupUpdateDialogOpen}
            onClose={handleVPNEndpointGroupUpdateDialogClose}
            dialogTitle={{
                title: selectedFieldTitleText, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onVPNEndpointGroupUpdate, 
                sx: {color: 'primary.main'}}]}
        >
            {getDataForm()}
        </CustomDialog>
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts[error.error_title], 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts[error.error_message]}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
    </Box>
    )
};

export default VPNEndpointGroupsWrapperV20;