import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../../../../_common/CustomTable';
import { vpnSiteConnectionDataSchema } from 
'../../../../../../_data/openstack/neutron/vpn/v2.0';
import { setVpnSiteConnectionsTableConfig } from 
'../../../../../../store/reducers/networkNeutronSlice';

const VPNSiteConnectionsTableV20 = (props) => {
    const { vpnSiteConnectionsData, setVPNSiteConnectionsData } = props
    const { handleRowSelection } = props
    const {currentAction} = props
    const {setCurrentAction}  = props
    const { actionsTexts }  = props
    const { actionsList } = props
    const { sortHandler } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);
    
    const vpnSiteConnectionsTableConfig = useSelector(
        state => state.networkNeutron.vpnSiteConnectionsTableConfig)
    const rowsPerPage = useSelector(
        state => state.networkNeutron.vpnSiteConnectionsTableConfig.filter(
        versions => versions.api_version === "v2.0")[0].rows_per_page)
    const vpnSiteConnectionsTableHeaders = useSelector(
        state => state.networkNeutron.vpnSiteConnectionsTableConfig.filter(
            versions => versions.api_version === "v2.0")[0].columns)

    
    const identityField = vpnSiteConnectionsTableHeaders.filter(
        item => item.is_id_field)[0].field_key
    
    
    const dispatch = useDispatch();

    const handleVPNSiteConnectionTableConfig = (value_list) => {
        const newTableConfig = vpnSiteConnectionDataSchema.filter(
            n => value_list.includes(n.field_key))
        const new_data = vpnSiteConnectionsTableConfig.map(version => {
            if (version.api_version === "v2.0") {
                let new_item = {...version}
                new_item.columns = [...newTableConfig]
                return new_item
            } else {
                return version
            }
        })
        dispatch(setVpnSiteConnectionsTableConfig({
            vpnSiteConnectionsTableConfig: new_data
        }))
    }
    
    const handleRowsPerPageChange = (rows_num) => {
        const new_data = vpnSiteConnectionsTableConfig.map(version => {
            if (version.api_version === "v2.0") {
                let new_item = {...version}
                new_item.rows_per_page = rows_num
                return new_item
            } else {
                return version
            }
        })
        dispatch(setVpnSiteConnectionsTableConfig({
            vpnSiteConnectionsTableConfig: new_data
        }))
    }

    return (
        <React.Fragment>
            <CustomTable
                withPagination={true}
                withCheckbox={true}
                withActions={true}
                withSorting={false}
                defaultDataTexts={{...defaultTexts}} 
                tableHeaders={vpnSiteConnectionsTableHeaders}
                tableHeadersConfigHandler={handleVPNSiteConnectionTableConfig}
                identityField={identityField}
                columns={[...vpnSiteConnectionDataSchema]}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                dataRows={vpnSiteConnectionsData}
                setDataRows={setVPNSiteConnectionsData}
                getSelectedRows={handleRowSelection}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={actionsTexts}
                actionsList={actionsList}
                sortHandler={sortHandler}
                selfSorting={true}
            />
        </React.Fragment>
    )
};

export default VPNSiteConnectionsTableV20;