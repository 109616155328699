export const networkDataSchema = [
    {
        field_key: "project_id",
        is_id_field: false,
        field_type: "string", 
        label: "projectFormFieldLabel"
    },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "status",
        is_id_field: false,
        field_type: "string", 
        label: "statusFormFieldLabel"
    },
    {
        field_key: "admin_state_up",
        is_id_field: false,
        field_type: "bool", 
        label: "adminStateFormFieldLabel",
        textOnTrue: "upStateFormFieldLabel",
        textOnFalse: "downStateFormFieldLabel"
    },
    {
        field_key: "router:external",
        is_id_field: false,
        field_type: "bool", 
        label: "externalFormFieldLabel"
    },
    {
        field_key: "shared",
        is_id_field: false,
        field_type: "bool", 
        label: "sharedFormFieldLabel"
    },
    {
        field_key: "is_default",
        is_id_field: false,
        field_type: "bool", 
        label: "isDefaultNetworkFormFieldLabel"
    },
    {
        field_key: "mtu",
        is_id_field: false,
        field_type: "string", 
        label: "mtuFormFieldLabel"
    },
    {
        field_key: "port_security_enabled",
        is_id_field: false,
        field_type: "bool",
        label: "portSecurityEnabledFormFieldLabel"
    },
    {
        field_key: "segments",
        is_id_field: false,
        field_type: "string", 
        label: "segmentFormFieldTitle"
    },
    {
        field_key: "revision_number",
        is_id_field: false,
        field_type: "string", 
        label: "revisionNumberFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const networkDataForm = [
    {
        field_key: "project_id",
        field_type: "select",
        required: true,
        label: "projectFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    },
    {
        field_key: "dns_domain",
        field_type: "string",
        required: false,
        label: "dnsDomainFormFieldLabel"
    },
    {
        field_key: "is_default",
        field_type: "bool", 
        required: false,
        label: "defaultNetworkFormFieldLabel",
        default_value: false
    },
    {
        field_key: "admin_state_up",
        field_type: "bool", 
        required: false,
        label: "adminStateUpFormFieldLabel",
        default_value: true
    },
    {
        field_key: "port_security_enabled",
        field_type: "bool", 
        required: false,
        label: "portSecurityEnabledFormFieldLabel",
        default_value: true
    },
    {
        field_key: "router:external",
        field_type: "bool", 
        required: false,
        label: "externalNetworkFormFieldLabel",
        default_value: true
    },
    {
        field_key: "shared",
        field_type: "bool", 
        required: false,
        label: "sharedNetworkFormFieldLabel",
        default_value: false
    },
    {
        field_key: "mtu",
        field_type: "integer", 
        required: false,
        label: "mtuFormFieldLabel"
    }
]

export const networkTypeFieldsForm = [
    {
        field_key: "provider:network_type",
        field_type: "select",
        required: false,
        label: "networkTypeFormFieldLabel",
        self_item_titles: false,
        default_empty: false,
        items: [
            {
                keyword: "localChoiceTitle",
                value: "local",
                default: false
            },
            {
                keyword: "flatNetworkChoiceTitle",
                value: "flat",
                default: false
            },
            {
                keyword: "vlanChoiceTitle",
                value: "vlan",
                default: false
            },
            {
                keyword: "vxlanChoiceTitle",
                value: "vxlan",
                default: false
            },
            {
                keyword: "greChoiceTitle",
                value: "gre",
                default: false
            },
            {
                keyword: "geneveChoiceTitle",
                value: "geneve",
                default: false
            }
        ]
    },
    {
        field_key: "provider:physical_network",
        field_type: "string",
        required: false,
        label: "physicalNetworkFormFieldLabel"
    },
    {
        field_key: "provider:segmentation_id",
        field_type: "integer",
        required: false,
        label: "segmentationIDFormFieldLabel"
    }
]

export const networksSortFields = [
    {
        field_key: "name",
        label: "nameFormFieldLabel"
    },
    {
        field_key: "status",
        label: "statusFormFieldLabel"
    },
    {
        field_key: "project_id",
        label: "projectFormFieldLabel"
    }
]


export const networksFilterMenu = [
    {
        keyword: "nameFormFieldLabel",
        value: "name",
        type: "text"
    },
    {
        keyword: "projectFormFieldLabel",
        value: "project_id",
        type: "select", 
        multiple: false,
        self_item_titles: true,
        items: []
    },
    {
        keyword: "externalNetworkFormFieldLabel", 
        value: "router:external", 
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "filterChoiceYes", 
                value: "true", 
                default: true
            },
            {
                keyword: "filterChoiceNo", 
                value: "false", 
                default: false
            }
        ]
    },
    {
        keyword: "sharedNetworkFormFieldLabel", 
        value: "shared", 
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "filterChoiceYes", 
                value: "true", 
                default: true
            },
            {
                keyword: "filterChoiceNo", 
                value: "false", 
                default: false
            }
        ]
    }
]

export const networkDataUpdateForm = [
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    },
    {
        field_key: "dns_domain",
        field_type: "string",
        required: false,
        label: "dnsDomainFormFieldLabel"
    },
    {
        field_key: "is_default",
        field_type: "bool", 
        required: false,
        label: "defaultNetworkFormFieldLabel",
        default_value: false
    },
    {
        field_key: "admin_state_up",
        field_type: "bool", 
        required: false,
        label: "adminStateUpFormFieldLabel",
        default_value: true
    },
    {
        field_key: "port_security_enabled",
        field_type: "bool", 
        required: false,
        label: "portSecurityEnabledFormFieldLabel",
        default_value: true
    },
    {
        field_key: "router:external",
        field_type: "bool", 
        required: false,
        label: "externalNetworkFormFieldLabel",
        default_value: true
    },
    {
        field_key: "shared",
        field_type: "bool", 
        required: false,
        label: "sharedNetworkFormFieldLabel",
        default_value: false
    },
    {
        field_key: "mtu",
        field_type: "integer", 
        required: false,
        label: "mtuFormFieldLabel"
    }
]