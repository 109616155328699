import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import WrapperBox from '../../../../_common/WrapperBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOffIcon from '@mui/icons-material/EditOff';
import Paper from '@mui/material/Paper';
import useWindowDimensions from 
'../../../../_common/WindowDimensions';
import Constants from '../../../../../config/constants';
import { IconButton }  from '@mui/material';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { openstackRequest } from 
'../../../../../_network/openstack_request';
import { regionsUrl as regionsUrlResponses } from 
'../../../../../_api_responses/openstack/identity/regions/v3';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { identityKeystonConstants } from 
'../../../../../config/openStackConstants';
import CustomDialog from 
'../../../../_common/CustomDialog';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import { FormGroup } from '@mui/material';
import { regionDataUpdateForm, regionDataSchema } from 
'../../../../../_data/openstack/identity/regions/v3';
import { getDetailDataComponent } from 
'../../../../_common/common_helpers';
import { handleEmptyFields } from '../../../helpers/v3/identity_helpers'

const SERVICE_NAME = openStackServices.identityService

const IdentityRegionDetailV3 = (props) => {
    const { selectedRow } = props
    const { regionsData } = props
    const { widthWeight } = props
    const { handleFetchData, handleDrawerClose } = props
    const { handleDeleteRegion } = props
    const { width } = useWindowDimensions();
    const [editMode, setEditMode] = useState(false);
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [error, setError] = useState()
    const [successRegionDelete, setSuccessRegionDelete] = useState()
    const [successRegionUpdate, setSuccessRegionUpdate] = useState()
    
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [successUpdateDialogOpen, setSuccessUpdateDialogOpen] = useState(false);
    
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

    const [updateFormData, setUpdateFormData] = useState(
        {...regionsData[selectedRow]}
    )
    
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const regionsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.regionsUrl)[0].url)

    const handleFormDataChange = (event,field_key) => {
        let new_form_data = {...updateFormData}
        if (regionDataUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else {
            new_form_data[field_key] = event.target.value
        }
        setUpdateFormData(new_form_data)
    }

    const getDataForm = () => {
        return (
            <FormGroup>
                {regionDataUpdateForm.map(field => {
                return (
                    getFormFieldComponent(
                        field,
                        updateFormData,
                        handleFormDataChange,
                        defaultTexts[field.label],
                        {size:"medium",
                        withHelp: field.with_help_text,
                        helpText: defaultTexts[field.help_text],
                        sx: {
                            my: 1, 
                            width: width * widthWeight * 0.8
                        }}
                    )
                )
            })}
        </FormGroup>)
    }

    const handleEditModeChange = () => {
        setEditMode(true)
    }
    const handleEditModeReset = () => {
        setUpdateFormData({...regionsData[selectedRow]})
        setEditMode(false)
    }
    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }
    const handleSuccessDialogClose = () => {
        setSuccessRegionDelete(null);
        setSuccessDialogOpen(false);
        handleDrawerClose()
    }
    const handleSuccessUpdateDialogClose = () => {
        setSuccessRegionUpdate(null)
        setUpdateFormData({...regionsData[selectedRow]})
        setSuccessUpdateDialogOpen(false);
    }
    const handleConfirmDeleteDialogOpen = () => {
        setConfirmDeleteDialogOpen(true)
    }
    const handleConfirmDeleteDialogClose = () => {
        setConfirmDeleteDialogOpen(false)
    }

    const handleEditRegion = async () => {
        let updated_data = {...updateFormData}
        
        const request_data = handleEmptyFields(regionDataUpdateForm, updated_data)
        
        const url = `${identityServiceDomain}/${identityServiceVersion}/${regionsUrl}/${regionsData[selectedRow].id}`
        const method = "PATCH"

        const region_response = await openstackRequest({
            url: url, 
            method: method, 
            data: {region: request_data}
        })
        if (region_response.status_code === regionsUrlResponses.patch.success_response.status_code) {
            handleFetchData()
            handleEditModeReset()
            setSuccessRegionUpdate({
                success_title: regionsUrlResponses.patch.success_response.response_title, 
                success_message: regionsUrlResponses.patch.success_response.response_message
            })
        } else {
            const error_response = regionsUrlResponses.patch.error_response.filter(
                error_item => error_item.status_code === region_response.status_code)
            if (error_response.length > 0) {
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: region_response.error
                }
                setError(errorObject)
            } else {
                const error_response = regionsUrlResponses.patch.error_response.filter(
                    error_item => error_item.status_code === "unknown")
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: region_response.error
                }
                setError(errorObject)
            }
        }
    }

    const onRegionDelete = () => {
        handleConfirmDeleteDialogClose()
        handleDeleteRegion()
    }

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessDialogOpen(true)
    },[successRegionDelete]);

    useEffect(() => {
        setSuccessUpdateDialogOpen(true)
    },[successRegionUpdate]);

    return (
        <React.Fragment>
            <WrapperBox
                sx={{
                    borderBottom: 0.5, 
                    borderColor: 'text.secondary',
                    py: 1,
                    alignItems: 'end'
                }}
            >
                {selectedRow !== null && 
                    <CustomText size="h6">
                        {regionsData[selectedRow].id}
                    </CustomText>}
            </WrapperBox>
            {editMode && 
                <WrapperBox 
                    sx={{
                        m: 2, 
                        alignItems: 'start'
                    }}>
                <CustomText 
                    size="h6" 
                    sx={{
                        color: "primary.main", 
                        mb: 2
                    }}>
                    {defaultTexts.updateRegionFormTitle}
                </CustomText>
                {getDataForm()}
                <Button 
                    variant="contained"
                    color="secondary"
                    sx={{mt: 5}}
                    onClick={handleEditRegion}
                >
                    {defaultTexts.saveButtonText}
                </Button>
            </WrapperBox>}
            {!editMode && 
                <Stack 
                    spacing={2} 
                    sx={{ p: 1, mt: 3 }}
                >
                    {regionDataSchema.map((field) => {
                        return (
                            getDetailDataComponent({
                                fieldType: field.field_type,
                                fieldKey: field.field_key,
                                label: defaultTexts[field.label],
                                value: regionsData[selectedRow][field.field_key],
                                textOnTrue: defaultTexts[field.value_on_true],
                                textOnFalse: defaultTexts[field.value_on_false]
                            })
                        )
                    })}
                </Stack>
            }
            <Paper sx={{ 
                position: 'fixed', 
                bottom: 0, 
                height: Constants.actions_bar_height,
                left: width - (width * widthWeight), 
                right: 12
                }} 
                elevation={24}
                square={true}
                
            >
                <Grid 
                    container 
                    alignItems="center"  
                    justifyContent="space-between"
                >
                    <Grid item>
                        {!editMode && <IconButton onClick={handleEditModeChange}>
                            <EditIcon color="primary" />
                        </IconButton>}
                        {editMode && <IconButton onClick={handleEditModeReset}>
                            <EditOffIcon color="primary" />
                        </IconButton>}
                        {selectedRow !== null && 
                            !regionsData[selectedRow].enabled && 
                            <IconButton onClick={handleConfirmDeleteDialogOpen}>
                                <DeleteIcon 
                                    color="primary"
                                />
                            </IconButton>}
                    </Grid>
                </Grid>
            </Paper> 
            {successRegionDelete && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[successRegionDelete.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successRegionDelete.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {successRegionUpdate && <CustomDialog
                open={successUpdateDialogOpen}
                onClose={handleSuccessUpdateDialogClose}
                dialogTitle={{
                    title: defaultTexts[successRegionUpdate.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successRegionUpdate.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>Details:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            <CustomDialog
                open={confirmDeleteDialogOpen}
                onClose={handleConfirmDeleteDialogClose}
                dialogTitle={{
                    title: defaultTexts.deleteConfirmationDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.deleteRegionConfirmationDialogMessage, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: onRegionDelete, 
                    sx: {color: 'primary.main'}}]}
            />
        </React.Fragment>
    )
};

export default IdentityRegionDetailV3;