import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CustomDrawer from '../../_common/CustomDrawer';
import Constants from '../../../config/constants'
import billingRequest from '../../../_network/billing_request';
import { billingServiceURLs } from '../../../_network/apiUrls';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import MuiStack from '@mui/material/Stack';
import CustomText from '../../_common/CustomText';
import CustomCard from '../../_common/CustomCard';
import Typography from '@mui/material/Typography';
import { PieChart, pieArcLabelClasses  } from '@mui/x-charts/PieChart';
import BillingDepositsTable from './billingDepositsTable';
import CustomTableSkeleton from '../../_common/CustomTableSkeleton';
import { openStackServices } from 
'../../../config/openStackConstants';
import { identityKeystonConstants } from 
'../../../config/openStackConstants';
import { openstackRequest } from 
'../../../_network/openstack_request';
import { projectsUrl as projectsUrlResponses } from 
'../../../_api_responses/openstack/identity/projects/v3';
import { filterMenu } from "./depositData";

const IDENTITY_SERVICE_NAME = openStackServices.identityService

const textStyle = {
    fontSize: {xs: 16, lg: 18},
    fontWeight: "500"
}

const CustomStack = styled(MuiStack)(({ theme }) => ({
    flexDirection: "row" ,
    alignItems: "start",
    justifyContent: "space-between",
    marginTop: "9px"
}));


const BillingDepositsWrapper = (props) => {
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
    const [dataFetchRequired, setDataFetchRequired] = useState(true)
    const [dataListFetchRequired, setDataListFetchRequired] = useState(true)
    const [todayDeposits, setTodayDeposits] = useState(0)
    const [thisWeekDeposits, setThisWeekDeposits] = useState(0)
    const [thisMonthDeposits, setThisMonthDeposits] = useState(0)
    const [billingPostpaidDeposits, setBillingPostpaidDeposits] = useState(0);
    const [billingPrepaidDeposits, setBillingPrepaidDeposits] = useState(0);
    const [billingActiveDeposits, setBillingActiveDeposits] = useState(0);
    const [billingInactiveDeposits, setBillingInactiveDeposits] = useState(0);
    const [billingSuspendedDeposits, setBillingSuspendedDeposits] = useState(0);
    const [billingDeletedDeposits, setBillingDeletedDeposits] = useState(0);
    const [openstackProjects, setOpenstackProjects] = useState([])
    const [depositsFilterMenu, setDepositsFilterMenu] = useState([...filterMenu]);
    const [selectedFilter, setSelectedFilter ] = useState(filterMenu[0].value)
    const [selectedFilterValue, setSelectedFilterValue] = useState("");
    const [filterQueryParams, setFilterQueryParams] = useState("")
    

    const navigate = useNavigate();

    if (!isAuthenticated) {
        navigate('/');
    }
    const [isLoading, setIsLoading] = useState(true)
    const [isTableLoading, setIsTableLoading] = useState(true)
    const [billingDeposits, setBillingDeposits] = useState([]);
    const [billingDepositsList, setBillingDepositsList] = useState([])

    const theme = useTheme();

    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleTableLoading = (mode) => {
        setIsTableLoading(mode)
    }

    const handleDataFetch = () => {
        setDataFetchRequired(true)
    }

    const handleDataListFetch = () => {
        setDataListFetchRequired(true)
    }

    const handleFilteredSearch = () => {
        if (selectedFilter && selectedFilterValue) {
            setFilterQueryParams(`?${selectedFilter}=${selectedFilterValue}`)
        } else {
            setFilterQueryParams("")
        }
    }

    const handleFilterReset = () => {
        setSelectedFilter(filterMenu[0].value)
        setSelectedFilterValue("")
        setFilterQueryParams("")
    }

    const CustomChips = ({text,color}) => (
        <Box sx={{
            borderRadius: 20, 
            border: `2px solid ${color}`,
            py: 0.7, px: 2}}>
            <CustomText sx={{fontSize: {xs: 16, md: 18}, color: color}}>
                {text}
            </CustomText>
        </Box>
    )

    useEffect(() => {
        if (dataListFetchRequired) {
                const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingDepositsEP}`
                let request_data = {
                    url: url,
                    method: "GET"
                }
                billingRequest(request_data).then((response) => {
                    if (response.status_code === 200) {
                        const formatted_data = response.data.map(item => {
                            let new_item = {...item}
                            new_item.created_at = new Date(item.created_at)
                            new_item.amount = item.amount.toFixed(2)
                            if (new_item.status === "suspended") {
                                new_item.properties.suspend_time = new Date(item.properties.suspend_time)
                            }
                            return new_item
                        })
                        setBillingDepositsList(formatted_data)
                    }
                    handleLoading(false)
                    setDataListFetchRequired(false)
                }).catch((err) => {
                    handleLoading(false)
                    console.log(err)
                setDataListFetchRequired(false)
            })
        }
    },[dataListFetchRequired]);

    useEffect(() => {
        if (dataFetchRequired) {
                handleTableLoading(true)
                const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingDepositsEP}${filterQueryParams}`
                let request_data = {
                    url: url,
                    method: "GET"
                }
                billingRequest(request_data).then((response) => {
                    if (response.status_code === 200) {
                        const formatted_data = response.data.map(item => {
                            let new_item = {...item}
                            new_item.created_at = new Date(item.created_at)
                            new_item.amount = item.amount.toFixed(2)
                            if (new_item.status === "suspended") {
                                new_item.properties.suspend_time = new Date(item.properties.suspend_time)
                            }
                            return new_item
                        })
                        setBillingDeposits(formatted_data)
                    }
                    handleTableLoading(false)
                    setDataFetchRequired(false)
                }).catch((err) => {
                    handleTableLoading(false)
                    console.log(err)
                setDataFetchRequired(false)
            })
        }
    },[dataFetchRequired, filterQueryParams]);

    useEffect(() => {
        if (billingDepositsList.length > 0) {
            const today = new Date()
            const today_projects = billingDepositsList.filter(item => 
                item.created_at.getFullYear() === today.getFullYear() &&
                item.created_at.getMonth() === today.getMonth() &&
                item.created_at.getDate() === today.getDate()
            ).length

            const first = today.getDate() - ((today.getDay() + 6) % 7); 
            const current_date = new Date()
            const firstday_of_week = new Date(current_date.setDate(first))
            firstday_of_week.setHours(0)
            firstday_of_week.setMinutes(0)
            firstday_of_week.setSeconds(0)

            const this_week_projects = billingDepositsList.filter(item => 
                item.created_at > firstday_of_week &&
                item.created_at <= today
            ).length

            const this_day = new Date()
            const first_day_of_month = new Date(this_day.setDate(1))
            const this_month_projects = billingDepositsList.filter(item => 
                item.created_at >  first_day_of_month &&
                item.created_at <= today
            ).length

            setTodayDeposits(today_projects)
            setThisWeekDeposits(this_week_projects)
            setThisMonthDeposits(this_month_projects)
        }
    },[
        billingDepositsList
    ])

    useEffect(() => {
        if (billingDepositsList.length > 0) {
            const post_paid = billingDepositsList.filter(item => item.billing_type === "postpaid").length
            const pre_paid = billingDepositsList.filter(item => item.billing_type === "prepaid").length
            setBillingPostpaidDeposits(post_paid)
            setBillingPrepaidDeposits(pre_paid)
            const activeStatus = billingDepositsList.filter(item => item.status === "active").length
            const inactiveStatus = billingDepositsList.filter(item => item.status === "inactive").length
            const suspendedStatus = billingDepositsList.filter(item => item.status === "suspended").length
            const deletedStatus = billingDepositsList.filter(item => item.status === "deleted").length
            setBillingActiveDeposits(activeStatus)
            setBillingInactiveDeposits(inactiveStatus)
            setBillingSuspendedDeposits(suspendedStatus)
            setBillingDeletedDeposits(deletedStatus)
        }
    },[
        billingDepositsList
    ])

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({url:url, method:method})
            if (projects_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                setOpenstackProjects(projects_response.data.projects)
            }
        })();
    },[
        identityServiceDomain, 
        identityServiceVersion, 
        projectsUrl
    ]);


    useEffect(() => {
        if (openstackProjects.length > 0) {
            const project_filter = openstackProjects.map(p => {
                return {keyword: p.name, value: p.id, default: false}
            })
            const new_filter = filterMenu.map(f => {
                let new_item = {...f}
                if (f.value === "project_id") {
                    new_item.items = project_filter
                    new_item["self_item_titles"] = true
                }
                return new_item
            })
            setDepositsFilterMenu(new_filter)
        }
    },[openstackProjects])

    useEffect(() => {
        handleDataFetch()
    },[filterQueryParams])
    
    
    return (
        <CustomDrawer>
            <Box>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={4}>
                        <CustomCard
                            cardWidth={'100%'} 
                            cardHeight={235} 
                            card_sx={{
                                border: theme.palette.mode === "dark" ? 0 : 1, 
                                boxShadow: theme.palette.mode === "dark" ? 0 : 1, 
                                backgroundColor: 
                                    theme.palette.mode === "dark" ? 
                                    undefined :
                                    "vLightGray"
                            }}
                            box_sx={{px: 2, pr: 7}}
                        >
                            <MuiStack spacing={1}>
                                <CustomText 
                                    size="h6"
                                    sx={{color: theme.palette.primary.main}}
                                >
                                    {defaultTexts.newAccountsCardTitleLabel}
                                </CustomText>
                                <CustomStack>
                                    <CustomText sx={{
                                        ...textStyle, 
                                        color: theme.palette.text.secondary
                                    }}>
                                        {defaultTexts.todayNewAccountsLabel}
                                    </CustomText>
                                    {isLoading ? 
                                        <Skeleton width="50%">
                                            <Typography>.</Typography>
                                        </Skeleton> :
                                        <CustomChips 
                                            text={todayDeposits} 
                                            color={
                                                theme.palette.mode === "dark" ? 
                                                theme.palette.dashboardPinkDark :
                                                theme.palette.dashboardPink
                                            } 
                                        />
                                    }
                                </CustomStack>
                                <CustomStack>
                                    <CustomText sx={{
                                        ...textStyle, 
                                        color: theme.palette.text.secondary
                                    }}>
                                        {defaultTexts.thisWeekNewAccountsLabel}
                                    </CustomText>
                                    {isLoading ? 
                                        <Skeleton width="50%">
                                            <Typography>.</Typography>
                                        </Skeleton> :
                                        <CustomChips 
                                            text={thisWeekDeposits} 
                                            color={
                                                theme.palette.mode === "dark" ? 
                                                theme.palette.dashboardGreenDark :
                                                theme.palette.dashboardGreen
                                            } 
                                        />
                                    }
                                </CustomStack>
                                <CustomStack>
                                    <CustomText sx={{
                                        ...textStyle, 
                                        color: theme.palette.text.secondary
                                    }}>
                                        {defaultTexts.thisMonthNewAccountsLabel}
                                    </CustomText>
                                    {isLoading ? 
                                        <Skeleton width="50%">
                                            <Typography>.</Typography>
                                        </Skeleton> :
                                        <CustomChips 
                                            text={thisMonthDeposits} 
                                            color={
                                                theme.palette.mode === "dark" ? 
                                                theme.palette.customBlueDark :
                                                theme.palette.customBlue
                                            } 
                                        />
                                    }
                                </CustomStack>
                            </MuiStack>
                        </CustomCard>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <CustomCard 
                            cardWidth={'100%'} 
                            cardHeight={235} 
                            card_sx={{
                                border: theme.palette.mode === "dark" ? 0 : 1, 
                                boxShadow: theme.palette.mode === "dark" ? 0 : 1, 
                                backgroundColor: 
                                    theme.palette.mode === "dark" ? 
                                    undefined :
                                    "vLightGray"
                            }}
                            box_sx={{pr: 2}}
                        >
                            <CustomText 
                                size="h6"
                                sx={{color: theme.palette.primary.main}}
                            >
                                {defaultTexts.totalAccountsCardTitleLabel}
                            </CustomText>
                            <MuiStack spacing={2} alignItems="center">
                                <CustomText 
                                    size="h4"
                                    sx={{
                                        color: theme.palette.mode === "dark" ?
                                        theme.palette.dashboardPinkDark : 
                                        theme.palette.dashboardPink
                                    }}
                                >
                                    {billingDepositsList.length}
                                </CustomText>
                                {!isLoading && billingDepositsList.length > 0 && <MuiStack direction="row" sx={{width: "100%"}}>
                                    <Box sx={{
                                        width: `${Math.round(billingPostpaidDeposits * 100 / billingDepositsList.length)}%`, 
                                        height: 50, 
                                        backgroundColor: theme.palette.mode === "dark" ? 
                                        theme.palette.dashboardPurpleDark :
                                        theme.palette.dashboardPurple,
                                        borderTopLeftRadius: 5,
                                        borderBottomLeftRadius: 5,
                                        borderTopRightRadius: billingPrepaidDeposits > 0 ? 0 : 5,
                                        borderBottomRightRadius: billingPrepaidDeposits > 0 ? 0 : 5,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <CustomText size="h6" sx={{color: "white"}}>
                                            {billingPostpaidDeposits > 0 ? billingPostpaidDeposits : ""}
                                        </CustomText>
                                    </Box>
                                    <Box sx={{
                                        width: `${Math.round(billingPrepaidDeposits * 100 / billingDepositsList.length)}%`, 
                                        height: 50, 
                                        backgroundColor: theme.palette.mode === "dark" ? 
                                        theme.palette.dashboardGreenDark :
                                        theme.palette.dashboardGreen,
                                        borderTopRightRadius: 5,
                                        borderBottomRightRadius: 5,
                                        borderTopLeftRadius: billingPostpaidDeposits > 0 ? 0 : 5,
                                        borderBottomLeftRadius: billingPostpaidDeposits > 0 ? 0 : 5,
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "center",
                                        alignItems: "center"
                                    }}>
                                        <CustomText size="h6" sx={{color: "white"}}>
                                            {billingPrepaidDeposits > 0 ? billingPrepaidDeposits : ""}
                                        </CustomText>
                                    </Box>
                                </MuiStack>}
                                {isLoading && <Skeleton width="100%" height={50} variant="rounded" />}
                                {!isLoading && <MuiStack 
                                    direction="row" 
                                    spacing={1} 
                                    alignItems="center" 
                                    justifyContent="center"
                                >
                                    <Box sx={{
                                        width: 20, 
                                        height: 20, 
                                        backgroundColor: theme.palette.mode === "dark" ? 
                                        theme.palette.dashboardPurpleDark :
                                        theme.palette.dashboardPurple,
                                    }} />
                                    <CustomText>
                                        {defaultTexts.postpaidAccountTypeChartTitle}
                                    </CustomText>
                                    <Box sx={{
                                        width: 20, 
                                        height: 20, 
                                        backgroundColor: theme.palette.mode === "dark" ? 
                                        theme.palette.dashboardGreenDark :
                                        theme.palette.dashboardGreen,
                                    }} />
                                    <CustomText>
                                        {defaultTexts.prepaidAccountTypeChartTitle}
                                    </CustomText>
                                </MuiStack>}
                            </MuiStack>
                        </CustomCard>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <CustomCard 
                            cardWidth={'100%'} 
                            cardHeight={235} 
                            card_sx={{
                                border: theme.palette.mode === "dark" ? 0 : 1, 
                                boxShadow: theme.palette.mode === "dark" ? 0 : 1, 
                                backgroundColor: 
                                    theme.palette.mode === "dark" ? 
                                    undefined :
                                    "vLightGray"
                            }}
                            box_sx={{pr: 2}}
                        >
                            <CustomText 
                                size="h6"
                                sx={{color: theme.palette.primary.main}}
                            >
                                {defaultTexts.accountsStatusesCardTitleLabel}
                            </CustomText>
                            {!isLoading ? <PieChart
                                series={[
                                    {
                                    arcLabel: (i) => `${i.value}`,
                                    arcLabelMinAngle: 25,
                                    data: [
                                        {label: defaultTexts.activeStatusChartLegent, value: billingActiveDeposits},
                                        {label: defaultTexts.inactiveStatusChartLegent, value: billingInactiveDeposits},
                                        {label: defaultTexts.suspendedStatusChartLegent, value: billingSuspendedDeposits},
                                        {label: defaultTexts.deletedStatusChartLegent, value: billingDeletedDeposits},
                                    ],
                                    innerRadius: 20,
                                    outerRadius: 75,
                                    paddingAngle: 5,
                                    cornerRadius: 4,
                                    startAngle: -300,
                                    endAngle: 147,
                                    cx: 100,
                                    cy: 80,
                                    }
                                ]}
                                sx={{
                                    [`& .${pieArcLabelClasses.root}`]: {
                                    fill: 'white',
                                    fontWeight: '500',
                                    },
                                }}
                            /> : 
                            <MuiStack 
                                direction="row" 
                                justifyContent="space-around" 
                                alignItems="center" 
                                spacing={2}
                                sx={{mt: 3}}
                            >
                                <Skeleton height={120} width={120} variant="circular" />
                                <MuiStack>
                                    <Skeleton width={200} height="50">
                                        <Typography>.</Typography>
                                    </Skeleton>
                                    <Skeleton width={200} height="50">
                                        <Typography>.</Typography>
                                    </Skeleton>
                                    <Skeleton width={200} height="50">
                                        <Typography>.</Typography>
                                    </Skeleton>
                                    <Skeleton width={200} height="50">
                                        <Typography>.</Typography>
                                    </Skeleton>
                                </MuiStack>
                            </MuiStack>
                            }
                        </CustomCard>
                    </Grid>
                </Grid>
            </Box>
            {isTableLoading ? 
                <CustomTableSkeleton /> :
                <Box sx={{mt: 5}}>
                    <BillingDepositsTable 
                        billingDeposits={billingDeposits}
                        filterMenu={depositsFilterMenu}
                        selectedFilter={selectedFilter}
                        setSelectedFilter={setSelectedFilter}
                        selectedFilterValue={selectedFilterValue}
                        setSelectedFilterValue={setSelectedFilterValue}
                        handleFilteredSearch={handleFilteredSearch}
                        handleFilterReset={handleFilterReset}
                        openstackProjects={openstackProjects}
                        handleDataFetch={handleDataFetch}
                        handleDataListFetch={handleDataListFetch}
                    />
                </Box>
            }
        </CustomDrawer>
    )
}

export default BillingDepositsWrapper;