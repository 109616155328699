import CustomTextField from "./CustomTextField";
import CustomCheckboxField from "./CustomCheckboxField";
import CustomSelectField from "./CustomSelectField";
import CustomDateTimeField from "./CustomDateTimeField";
import Stack from '@mui/material/Stack'

export const getFormFieldComponent = (
    field_data,
    currentFormValues,
    setCurrentFormValue,
    label,
    options) => {
    let form_field = null
    switch (field_data.field_type) {
        case "string": 
            form_field = (
                <CustomTextField 
                    key={field_data.field_key}
                    field_key={field_data.field_key}
                    currentValue={
                        currentFormValues[field_data.field_key] ? 
                        currentFormValues[field_data.field_key] : 
                        ""
                    }
                    setCurrentValue={setCurrentFormValue}
                    label={label}
                    required={field_data.required}
                    sx={{my: 1}}
                    {...options}
                />
            )
            break;
        case "bool": 
            form_field = (
                <CustomCheckboxField
                    key={field_data.field_key}
                    field_key={field_data.field_key}
                    currentValue={currentFormValues[field_data.field_key]}
                    setCurrentValue={setCurrentFormValue}
                    label={label}
                    required={field_data.required}
                    sx={{my: 1}}
                    {...options}
                />
            )
            break;
        case "password": 
            form_field = (
                <CustomTextField 
                    key={field_data.field_key}
                    field_key={field_data.field_key}
                    required={field_data.required}
                    currentValue={
                        currentFormValues[field_data.field_key] ? 
                        currentFormValues[field_data.field_key] : 
                        ""
                    }
                    setCurrentValue={setCurrentFormValue}
                    label={label}
                    type='password'
                    sx={{my: 1}}
                    {...options}
                />
            )
            break;
        case "email": 
            form_field = (
                <CustomTextField 
                    key={field_data.field_key}
                    field_key={field_data.field_key}
                    required={field_data.required}
                    currentValue={
                        currentFormValues[field_data.field_key] ? 
                        currentFormValues[field_data.field_key] : 
                        ""
                    }
                    setCurrentValue={setCurrentFormValue}
                    label={label}
                    type='email'
                    sx={{my: 1}}
                    {...options}
                />
            )
            break;
        case "list": 
            form_field = (
                <CustomTextField 
                    key={field_data.field_key}
                    field_key={field_data.field_key}
                    currentValue={currentFormValues[field_data.field_key]}
                    setCurrentValue={setCurrentFormValue}
                    label={label}
                    required={field_data.required}
                    sx={{my: 1}}
                    {...options}
                />
            )
            break;
        case "select": 
            form_field = (
                <CustomSelectField 
                    key={field_data.field_key}
                    field_key={field_data.field_key}
                    currentValue={
                        currentFormValues[field_data.field_key] ? 
                        currentFormValues[field_data.field_key] : 
                        ""
                    }
                    setCurrentValue={setCurrentFormValue}
                    label={label}
                    required={field_data.required}
                    sx={{my: 1, width: '100%'}}
                    {...options}
                />
            )
            break;
        case "datetime": 
            form_field = (
                <CustomDateTimeField 
                    key={field_data.field_key}
                    field_key={field_data.field_key}
                    currentValue={
                        currentFormValues[field_data.field_key] ? 
                        currentFormValues[field_data.field_key] : 
                        null
                    }
                    setCurrentValue={setCurrentFormValue}
                    label={label}
                    sx={{my: 1, width: '100%'}}
                    {...options}
                />
            )
            break;
        case "integer": 
            form_field = (
                <CustomTextField 
                    key={field_data.field_key}
                    field_key={field_data.field_key}
                    currentValue={
                        currentFormValues[field_data.field_key] ? 
                        currentFormValues[field_data.field_key] : 
                        0
                    }
                    setCurrentValue={setCurrentFormValue}
                    label={label}
                    required={field_data.required}
                    type="number"
                    min={field_data.min}
                    step={field_data.step}
                    sx={{my: 1}}
                    {...options}
                />
            )
            break;
        case "float": 
            form_field = (
                <CustomTextField 
                    key={field_data.field_key}
                    field_key={field_data.field_key}
                    currentValue={
                        currentFormValues[field_data.field_key] ? 
                        currentFormValues[field_data.field_key] : 
                        0
                    }
                    setCurrentValue={setCurrentFormValue}
                    label={label}
                    required={field_data.required}
                    type="number"
                    min={field_data.min}
                    step={field_data.step}
                    sx={{my: 1}}
                    {...options}
                />
            )
            break;
    default:
        form_field = (
            <CustomTextField 
                key={field_data.field_key}
                currentValue={
                    currentFormValues[field_data.field_key] ? 
                    currentFormValues[field_data.field_key] : 
                    ""
                }
                setCurrentValue={setCurrentFormValue}
                label={label}
                required={field_data.required}
                sx={{my: 1}}
                {...options}
            />
        )
    }
    return form_field
}


export const getFormComponent = (props) => {
    let form_field = null
    switch (props.type) {
        case "string": 
            form_field = (
                <CustomTextField 
                    sx={{my: 1}}
                    {...props}
                />
            )
            break;
        case "boolean": 
            form_field = (
                <CustomCheckboxField
                    sx={{my: 1}}
                    {...props}
                />
            )
            break;
        case "select": 
            form_field = (
                <CustomSelectField 
                    sx={{my: 1, width: "100%"}}
                    {...props}
                />
            )
            break;
        case "integer": 
            form_field = (
                <CustomTextField 
                    sx={{my: 1}}
                    {...props}
                    type="number"
                />
            )
            break;
        case "array": 
            form_field = (
                <Stack direction="column" spacing={2} alignItems="center" sx={{border: 0.1, p:1}}>
                    <CustomSelectField 
                        sx={{my: 1}}
                        {...props}
                        multiple={true}
                        currentValue={props.choice_value}
                        items={[...props.array_items]}
                        index="enum"
                    />
                    <CustomSelectField 
                        sx={{my: 1}}
                        {...props}
                        currentValue={props.operator_value}
                        items={[...props.operator_items]}
                        index="operator"
                    />
                </Stack>
            )
            break;
    default:
        form_field = (
            <CustomTextField 
                sx={{my: 1}}
                {...props}
            />
        )
    }
    return form_field
}