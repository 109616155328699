import React from 'react';
import { Box } from '@mui/material';
import { Paper } from '@mui/material';
import CustomText from './CustomText';

const CustomCard = (props) => {
    const { cardWidth, cardHeight, minWidth} = props
    const { cardTitle, titleSize, titleColor } = props
    const { card_sx, box_sx } = props

    return (
            <Paper
                sx={{
                    borderRadius: 2,
                    border: 0.3,
                    borderColor: "vLightGray",
                    width: cardWidth,
                    minWidth: minWidth,
                    height: cardHeight ? cardHeight : '100%',
                    p: 1,
                    pr: 0,
                    ...card_sx
                }}            
            >
                <CustomText 
                    size={titleSize ? titleSize : "h6"}
                    sx={{color: titleColor ? titleColor : undefined}}
                >
                    {cardTitle}
                </CustomText>
                <Box
                    sx={{
                        height: '100%',
                        overflow: 'hidden', 
                        position: 'relative',
                    }}
                >
                    <Box sx={{
                        m: 1,
                        height: '90%', 
                        position: 'absolute', 
                        top: 0, 
                        bottom: 0, 
                        left: 0, 
                        right: -19, 
                        overflowY: 'scroll',
                        ...box_sx
                    }}>
                        {props.children}
                    </Box>
                </Box>
            </Paper>
    )
};

export default CustomCard;