import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import WrapperBox from '../../../../../_common/WrapperBox';
import NoDataNote from '../../../../../_common/NoDataNote';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import CustomText from '../../../../../_common/CustomText';
import useWindowDimensions from 
'../../../../../_common/WindowDimensions';
import { getFormFieldComponent } from 
'../../../../../_common/_form_fields/form_helpers';
import { getDetailDataComponent } from 
'../../../../../_common/common_helpers';
import Constants from '../../../../../../config/constants';
import { Grid }  from '@mui/material';
import { 
    computeNovaRequest,
    openstackRequest,
    getXAuthTokenProjectScope} from 
'../../../../../../_network/openstack_request';
import { infrastructureUrl as infrastructureUrlResponses } 
from '../../../../../../_api_responses/openstack/compute/infrastructure/v2.1';
import { openStackServices } from 
'../../../../../../config/openStackConstants';
import { 
    computeNovaConstants
} from '../../../../../../config/openStackConstants';
import ServiceContentHeader from 
'../../../../../_common/ServiceContentHeader';
import CustomSelectField from 
'../../../../../_common/_form_fields/CustomSelectField';
import CustomDialog from 
'../../../../../_common/CustomDialog';
import { 
    aggregateDataForm,
    aggregateDetailSchema
} from '../../../../../../_data/openstack/compute/infrastructure/v2.1';
import CustomTransferList from 
'../../../../../_common/custom_transfer_list/CustomTransferList';

const SERVICE_NAME = openStackServices.computeService
const IMAGE_SERVICE_NAME = openStackServices.imageService
const FOOTER_HEIGHT = Constants.actions_bar_height + 20
const RESOURCE_NAME = "OS::Nova::Aggregate"

const AggregateDetailV21 = (props) => {
    const [isCardLoading, setIsCardLoading] = useState(true)
    const [error, setError] = useState();
    const { selectedRow, handleDataFetch } = props
    const { widthWeight } = props
    const { handleDelete } = props
    const { width } = useWindowDimensions();
    const FOOTER_WIDTH =  width - (width * widthWeight)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [metadataNamespaces, setMetadataNamespaces] = useState([])
    const [metadataCatalog, setMetadataCatalog] = useState([])
    const [aggregateMetadataDialogOpen, setAggregateMetadataDialogOpen] = useState(false);
    const [updatedMetadata, setUpdatedMetadata] = useState({})

    const [aggregateActions, setAggregateActions] = useState([]);
    const [currentAction, setCurrentAction] = useState("");

    const [aggregateUpdateData, setAggregateUpdateData] = useState({})
    const [updateAggregateDialogOpen, setUpdateAggregateDialogOpen] = useState(false)
    const [addHostToAggregateDialogOpen, setAddHostToAggregateDialogOpen] = useState(false)
    const [selectedHost, setSelectedHost] = useState([])
    const [hypervisors, setHypervisors] = useState([])
     
    const [aggregateSubMenu, setAggregateSubMenu] = useState([
        {keyword: "submenuDetails", navigation: "/aggregate-details", is_active: true},
        {keyword: "submenuHosts", navigation: "/aggregate-hosts", is_active: false},
        {keyword: "submenuMetadata", navigation: "/aggregate-metadata", is_active: false}
    ])

    const [currentTab, setCurrentTab] = useState("/aggregate-details")
    
    const computeServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const computeServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const aggregatesUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.aggregatesUrl)[0].url)
    const hypervisorsUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.hypervisorsUrl)[0].url)

    const imageServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IMAGE_SERVICE_NAME)[0].config_params.service_domain)
    const imageServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IMAGE_SERVICE_NAME)[0].config_params.api_version)

    const common_url = `${computeServiceDomain}/${computeServiceVersion}/${aggregatesUrl}/${selectedRow.id}`

    const handleAggregateDetailTabChange = useCallback((navigation) => {
        let newAggregateSubmenuData = aggregateSubMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
            } else {
                item.is_active = false
            }
            return item
        })
        setAggregateSubMenu(newAggregateSubmenuData)
        setCurrentTab(navigation)
    },[
        aggregateSubMenu
    ])

    const handleAggregateMetadataDialogOpen = () => {
        setAggregateMetadataDialogOpen(true)
    }

    const handleAggregateMetadataSelectionDialogClose = () => {
        setUpdatedMetadata([])
        setAggregateMetadataDialogOpen(false)
    }

    const handleConfirmDeleteDialogOpen = () => {
        handleDelete([selectedRow.id])
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const getDataForm = (form,form_options,data,onDataChange) => {
        let form_data = [...form]
        return (
            <FormGroup>
                {form_data.map(field => {
                    let form_field_options = {}
                    return (
                        getFormFieldComponent(
                            field,
                            data,
                            onDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleAggregateUpdateDataChange = (event,field_key) => {
        let new_form_data = {...aggregateUpdateData}
        if (aggregateDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (aggregateDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
                new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setAggregateUpdateData(new_form_data)
    }

    const onAggregateAddHosts = async () => {
        if (selectedHost.length > 0) {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            const url = `${common_url}/action`
            const method = "POST"
            if (project_token) {
                let errors = []
                for (let i in selectedHost) {
                    let data = {}
                    data["add_host"] = {}
                    data["add_host"]["host"] = selectedHost[i]
                    const aggregate_response = await computeNovaRequest({
                        url: url, 
                        method: method, 
                        data: data,
                        token: project_token
                    })
                    if (aggregate_response.status_code !== infrastructureUrlResponses.post.success_response.status_code) {
                        errors = [...errors, aggregate_response.error]
                    }
                }
                handleAddHostToAggregateDialogClose()
                setCurrentAction("")
                handleDataFetch()
                if (errors.length > 0) {
                    let error_object = {}
                    error_object["error_title"] = "errorDeleteRecordTitle"
                    error_object["error_message"] = "errorDeleteRecordMessage"
                    error_object["error_details"] = errors.toString()
                    setError(error_object)
                    setErrorDialogOpen(true)
                }
            }
        }
    }

    const handleAggregateHostUpdate = async (host) => {
        let data = {}
        data["remove_host"] = {}
        data["remove_host"]["host"] = host
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const method = "POST"
            const aggregate_response = await computeNovaRequest({
                url: `${common_url}/action`, 
                method: method, 
                data: data,
                token: project_token
            })
            if (aggregate_response.status_code === infrastructureUrlResponses.post.success_response.status_code) {
                setCurrentAction("")
                handleDataFetch()
            } else {
                const error_response = infrastructureUrlResponses.post.error_response.filter(
                    error_item => error_item.status_code === aggregate_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: aggregate_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = infrastructureUrlResponses.post.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: aggregate_response.error
                    }
                    setError(errorObject)
                }
            }
        }
    }

    const onAggregateMetadataUpdate = async () => {
        let metadata = {...updatedMetadata}
        const keys_to_delete = Object.keys(selectedRow.metadata).filter(key => !Object.keys(updatedMetadata).includes(key))
        for (let k in keys_to_delete) {
            metadata[keys_to_delete[k]] = null
        }
        let data = {}
        data["set_metadata"] = {}
        data["set_metadata"]["metadata"] = metadata
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const method = "POST"
            const aggregate_response = await computeNovaRequest({
                url: `${common_url}/action`, 
                method: method, 
                data: data,
                token: project_token
            })
            if (aggregate_response.status_code === infrastructureUrlResponses.post.success_response.status_code) {
                handleDataFetch()
                handleAggregateMetadataSelectionDialogClose()
                setCurrentAction("")
            } else {
                const error_response = infrastructureUrlResponses.post.error_response.filter(
                    error_item => error_item.status_code === aggregate_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: aggregate_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = infrastructureUrlResponses.post.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: aggregate_response.error
                    }
                    setError(errorObject)
                }
            }
        }
    }

    const handleUpdateAggregateDialogOpen = () => {
        setUpdateAggregateDialogOpen(true)
    }

    const handleUpdateAggregateDialogClose = () => {
        setUpdateAggregateDialogOpen(false)
    }

    const handleAddHostToAggregateDialogOpen = () => {
        setAddHostToAggregateDialogOpen(true)
    }

    const handleAddHostToAggregateDialogClose = () => {
        setSelectedHost([])
        setAddHostToAggregateDialogOpen(false)
    }

    const onAggregateUpdate = useCallback(async (event,data) => {
        let updated_data = {...aggregateUpdateData}
        if (data) {
            updated_data = data
        }
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const method = "PUT"
            const aggregate_response = await computeNovaRequest({
                url: common_url, 
                method: method, 
                data: {aggregate: updated_data},
                token: project_token
            })
            if (aggregate_response.status_code === infrastructureUrlResponses.put.success_response.status_code) {
                setCurrentAction("")
                handleUpdateAggregateDialogClose()
                handleDataFetch()
            } else {
                const error_response = infrastructureUrlResponses.put.error_response.filter(
                    error_item => error_item.status_code === aggregate_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: aggregate_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = infrastructureUrlResponses.put.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: aggregate_response.error
                    }
                    setError(errorObject)
                }
            }
        }
    },[
        common_url,
        defaultAdminProject,
        aggregateUpdateData,
        handleDataFetch
    ])

    useEffect(() => {
        let aggregate_actions = []
        let new_action = {}
        new_action["value"] = "update_aggregate"
        new_action["action"] = handleUpdateAggregateDialogOpen
        new_action["keyword"] = "aggregateUpdateActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        aggregate_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "add_host_to_aggregate"
        new_action["action"] = handleAddHostToAggregateDialogOpen
        new_action["keyword"] = "aggregateAddHostActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        aggregate_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "aggregate_manage_metadata"
        new_action["action"] = handleAggregateMetadataDialogOpen
        new_action["keyword"] = "createUpdateMetadataActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        aggregate_actions.push({...new_action})
        
        setAggregateActions(aggregate_actions)
    },[
        selectedRow
    ])

    useEffect(() => {
        if (Object.keys(aggregateUpdateData).length === 0) {
            let new_form_data = {}
            for (const n in aggregateDataForm) {
                new_form_data[aggregateDataForm[n].field_key] = selectedRow[aggregateDataForm[n].field_key]
            }
            setAggregateUpdateData(new_form_data)
        }
    },[aggregateUpdateData, selectedRow]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${computeServiceDomain}/${computeServiceVersion}/${hypervisorsUrl}`
                const method = "GET"
                const hv_response = await computeNovaRequest({url:url, method:method, token: project_token})
                if (hv_response.status_code === infrastructureUrlResponses.get.success_response.status_code) {
                    const hv_list = hv_response.data.hypervisors.filter(hv => !selectedRow.hosts.includes(hv.hypervisor_hostname))
                    const hypervisor_list = hv_list.map(hv => {
                        return {keyword: hv.hypervisor_hostname, value: hv.hypervisor_hostname, default: false}
                    })
                    setHypervisors(hypervisor_list)
                }
            }
        })();
    },[
        computeServiceDomain, 
        computeServiceVersion, 
        hypervisorsUrl,
        selectedRow,
        defaultAdminProject
    ]);

    const handleMetadataFormatting = useCallback((objs,props) => {
        const metadata_catalog = metadataNamespaces.map(ns => {
            let new_item = {...ns}
            const resource_type_association = ns.resource_type_associations.filter(rs => rs.name === RESOURCE_NAME)[0]
            new_item["value"] = ns.namespace
            new_item["title"] = ns.display_name
            const prefix = resource_type_association.prefix ? resource_type_association.prefix : ""
            new_item["prefix"] = prefix
            new_item["objects"] = objs[ns.namespace].map((obj,index) => {
                let new_object = {...obj}
                new_object["title"] = obj.name
                new_object["value"] = `${ns.namespace}_${prefix}object${index}`
                new_object["properties"] = []
                const obj_props = Object.keys(obj.properties).map(key => {
                    let new_prop = {}
                    new_prop["value"] = `${prefix}${key}`
                    for (const [k,v] of Object.entries(obj.properties[key])) {
                        if (Array.isArray(v)) {
                            new_prop[k] = [...v]
                        } else if (v instanceof Object) {
                            new_prop[k] = {...v}
                        } else {
                            new_prop[k] = v
                        }
                    }
                    return new_prop
                })
                new_object["properties"] = obj_props
                return new_object
            })
            new_item["properties"] = Object.keys(props[ns.namespace]).map(pr_key => {
                let new_pr = {}
                new_pr["value"] = `${prefix}${pr_key}`
                for (let k in props[ns.namespace][pr_key]) {
                    if (k === "items") {
                        new_pr["enum"] = props[ns.namespace][pr_key][k]["enum"]
                    } else if (Array.isArray(props[ns.namespace][pr_key][k])) {
                        new_pr[k] = [...props[ns.namespace][pr_key][k]]
                    } else if (props[ns.namespace][pr_key][k] instanceof Object) {
                        new_pr[k] = {...props[ns.namespace][pr_key][k]}
                    } else {
                        new_pr[k] = props[ns.namespace][pr_key][k]
                    }
                }
                return  new_pr
            })
            return new_item
        })
        setMetadataCatalog(metadata_catalog)
    },[metadataNamespaces])

    useEffect(() => {
        (async () => {
            const url = `${imageServiceDomain}/${imageServiceVersion}/metadefs/namespaces`
            const method = "GET"
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let namespace_list = []
                const metadata_response = await openstackRequest({
                    url:url, 
                    method:method, 
                    token: project_token
                })
                if (metadata_response.status_code === infrastructureUrlResponses.get.success_response.status_code) {
                    const namespaces = metadata_response.data.namespaces.filter(item => Object.keys(item).includes("resource_type_associations") &&
                        item.resource_type_associations.filter(resource => resource.name === RESOURCE_NAME).length > 0
                    )
                    namespace_list = [...namespace_list, ...namespaces]
                    if (Object.keys(metadata_response.data).includes("next")) {
                        let next_url = `${imageServiceDomain}${metadata_response.data.next}`
                        while (next_url) {
                            const next_request = await openstackRequest({
                                url:next_url, 
                                method:method, 
                                token: project_token
                            })
                            const next_namespaces = next_request.data.namespaces.filter(item => Object.keys(item).includes("resource_type_associations") &&
                                item.resource_type_associations.filter(resource => resource.name === RESOURCE_NAME).length > 0
                            )
                            namespace_list = [...namespace_list, ...next_namespaces]
                            if (Object.keys(next_request.data).includes("next")) {
                                next_url = `${imageServiceDomain}${next_request.data.next}`
                            } else {
                                next_url = null
                            }
                        }
                    }
                    const compare = ( a, b ) => {
                        if ( a["display_name"].toLowerCase() < b["display_name"].toLowerCase() ){
                          return -1;
                        }
                        if ( a["display_name"].toLowerCase() > b["display_name"].toLowerCase() ){
                          return 1;
                        }
                        return 0;
                    }
                    namespace_list.sort(compare)
                    setMetadataNamespaces(namespace_list)
                } else {
                    setMetadataNamespaces([])
                }
            }
        })();
    },[
        selectedRow,
        imageServiceDomain,
        imageServiceVersion,
        defaultAdminProject
    ]);

    useEffect(() => {
        if (metadataNamespaces.length > 0) {
            (async () => {
                let total_objects = {}
                let total_properties = {}
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                const method = "GET"
                for (let i in metadataNamespaces) {
                    let namespace_objects = []
                    let namespace_properties = {}
                    if (project_token) {
                        const object_url = `${imageServiceDomain}/${imageServiceVersion}/metadefs/namespaces/${metadataNamespaces[i].namespace}/objects`
                        const object_response = await openstackRequest({
                            url:object_url, 
                            method:method, 
                            token: project_token
                        })
                        if (object_response.status_code === infrastructureUrlResponses.get.success_response.status_code) {
                            namespace_objects = [...namespace_objects, ...object_response.data.objects]
                        }
                        const props_url = `${imageServiceDomain}/${imageServiceVersion}/metadefs/namespaces/${metadataNamespaces[i].namespace}/properties`
                        const props_response = await openstackRequest({
                            url:props_url, 
                            method:method, 
                            token: project_token
                        })
                        if (props_response.status_code === infrastructureUrlResponses.get.success_response.status_code) {
                            namespace_properties = {...namespace_properties, ...props_response.data.properties}
                        }
                    }
                    total_objects[metadataNamespaces[i].namespace] = namespace_objects
                    total_properties[metadataNamespaces[i].namespace] = namespace_properties
                }
                handleMetadataFormatting(total_objects, total_properties)
            })();
        }
    },[
        metadataNamespaces,
        imageServiceDomain,
        imageServiceVersion,
        selectedRow,
        handleMetadataFormatting,
        defaultAdminProject
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setTimeout(() => setIsCardLoading(false), 600)
    },[])

    return (
        <React.Fragment>
        {selectedRow !== null && 
        <WrapperBox>
            <ServiceContentHeader 
                service_menu={aggregateSubMenu}
                service_menu_titles={defaultTexts}
                onClick={handleAggregateDetailTabChange}
            />
        </WrapperBox>}
        {currentTab === "/aggregate-details" &&
            <Stack 
                spacing={5} 
                sx={{ px: 1, mt: 3 }}
            >
                {aggregateDetailSchema.map((field) => {
                    return (
                        getDetailDataComponent({
                            fieldType: field.field_type,
                            fieldKey: field.field_key,
                            label: defaultTexts[field.label],
                            value: selectedRow ? selectedRow[field.field_key] : "",
                            textOnTrue: defaultTexts[field.value_on_true],
                            textOnFalse: defaultTexts[field.value_on_false]
                        })
                    )
                })}
            </Stack>
        }
        {currentTab === "/aggregate-hosts" && selectedRow.hosts.length > 0 &&
            <Stack 
                spacing={5} 
                sx={{ px: 1, mt: 3 }}
            >
                {selectedRow.hosts.map((host) => {
                    return (
                        <div key={host}>
                            <Stack 
                                direction="row" 
                                alignItems="center" 
                                justifyContent="space-between"
                            >
                                <CustomText sx={{p: 1}}>{host}</CustomText>
                                <IconButton 
                                    color="primary" 
                                    onClick={() => handleAggregateHostUpdate(host)}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Stack>
                            <Divider />
                        </div>
                    ) 
                })}
            </Stack>}
        {currentTab === "/aggregate-hosts" && selectedRow.hosts.length === 0 &&
            <NoDataNote text={defaultTexts.noHostNoteText} />
        }
        {currentTab === "/aggregate-metadata" && Object.keys(selectedRow.metadata).length > 0 &&
            <Stack 
                spacing={5} 
                sx={{ px: 1, mt: 3 }}
            >
                {Object.keys(selectedRow.metadata).map((key) => {
                    return (
                        <div key={key}>
                            <Stack 
                                direction="row" 
                                alignItems="center" 
                                justifyContent="space-between"
                            >
                                <CustomText sx={{p: 1}}>{key}:</CustomText>
                                <CustomText sx={{p: 1}}>{selectedRow.metadata[key]}</CustomText>
                            </Stack>
                            <Divider />
                        </div>
                    ) 
                })}
            </Stack>}
        {currentTab === "/aggregate-metadata" && Object.keys(selectedRow.metadata).length === 0 &&
            <NoDataNote text={defaultTexts.noMetadataNoteText} />
        }
        {!isCardLoading && <Paper sx={{ 
            position: 'fixed', 
            bottom: 0, 
            height: FOOTER_HEIGHT,
            left: FOOTER_WIDTH, 
            right: 12,
            zIndex: 3000
            }} 
            elevation={24}
            square={true}
        >
            <Grid 
                container 
                alignItems="center"  
                justifyContent="space-between"
            >
                <Grid item>
                    <CustomSelectField 
                        items={aggregateActions} 
                        currentValue={currentAction}
                        setCurrentValue={setCurrentAction}
                        item_titles={defaultTexts}
                        label={defaultTexts.actionsDropdownLabelText}
                        empty={true}
                        size="small"
                        sx={{m: 1}}
                    />
                    {currentAction.length > 0 && 
                        <Button 
                                variant="contained"
                                color="secondary"
                                sx={{m: 1, height: '70%'}}
                                onClick={aggregateActions.filter(
                                    action => action.value === currentAction)[0].action
                                }
                            >
                            {defaultTexts[aggregateActions.filter(
                                action => action.value === currentAction)[0].button_text]}
                        </Button>
                    }
                </Grid>
                <Grid item>
                    {selectedRow !== null && 
                        <IconButton onClick={handleConfirmDeleteDialogOpen}>
                            <DeleteIcon 
                                color="primary"
                            />
                        </IconButton>}
                </Grid>
            </Grid>
        </Paper>} 
        <CustomDialog
            open={updateAggregateDialogOpen}
            onClose={handleUpdateAggregateDialogClose}
            dialogTitle={{
                title: defaultTexts.updateAggregateActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onAggregateUpdate, 
                sx: {color: 'primary.main'}}]}
        >
            {getDataForm(
                aggregateDataForm,
                {},
                aggregateUpdateData,
                handleAggregateUpdateDataChange
            )}
        </CustomDialog>
        <CustomDialog
            open={addHostToAggregateDialogOpen}
            onClose={handleAddHostToAggregateDialogClose}
            dialogTitle={{
                title: defaultTexts.addHostToAggregateActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: defaultTexts.addHostToAggregateActionText, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onAggregateAddHosts, 
                sx: {color: 'primary.main'}}]}
        >
            <CustomSelectField
                currentValue={selectedHost}
                setCurrentValue={setSelectedHost}
                label={defaultTexts.hostFormFieldLabel}
                empty={true}
                multiple={true}
                items={hypervisors}
                self_item_titles={true}
                sx={{mt: 2, width: "90%"}}
            />
        </CustomDialog>
        <CustomDialog
            open={aggregateMetadataDialogOpen}
            onClose={handleAggregateMetadataSelectionDialogClose}
            dialogTitle={{
                title: defaultTexts.aggregateMetadataUpdateActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onAggregateMetadataUpdate, 
                sx: {color: 'primary.main'}}]}
            maxWidth="lg"
        >
            <CustomTransferList 
                withCustomList={true}
                withInstructions={true}
                catalogList={metadataCatalog}
                appliedFieldList={selectedRow.metadata}
                onDataUpdate={setUpdatedMetadata}
            />
        </CustomDialog>
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts.failedActionErrorDialogTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts.failedActionErrorDialogMessage}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
        </React.Fragment>
    )
};

export default AggregateDetailV21;