import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import WrapperBox from '../../../../_common/WrapperBox';
import { openstackRequest, getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { keypairsUrl as keypairsUrlResponses } from 
'../../../../../_api_responses/openstack/compute/keypairs/v2.1';
import { usersUrl as usersUrlResponses} from
'../../../../../_api_responses/openstack/identity/users/v3';
import { keypairsFilterMenu } from 
'../../../../../_data/openstack/compute/keypairs/v2.1';
import { openStackServices } from 
'../../../../../config/openStackConstants';
//import useWindowDimensions from '../../../../_common/WindowDimensions';
//import Dimensions from '../../../../../config/dimensions';
import { computeNovaConstants, identityKeystonConstants } from 
'../../../../../config/openStackConstants';
import ComputeKeypairsSubheaderV21 from './computeKeypairsSubheaderV2_1';
import ComputeKeypairsTableV21 from './computeKeypairsTableV2_1';
import CustomDialog from '../../../../_common/CustomDialog';
//import CustomSideDrawer from '../../../../_common/CustomSideDrawer';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import CustomText from '../../../../_common/CustomText';
//import ComputeFlavorDetailV21 from './computeFlavorDetailV2_1'
import { Divider } from '@mui/material';
import Box from '@mui/material/Box';

const SERVICE_NAME = openStackServices.computeService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const ComputeKeypairsWrapperV21 = (props) => {
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState()
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    //const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)

    const [filterMenu, setFilterMenu] = useState(keypairsFilterMenu)
    const [keypairsData, setKeypairsData] = useState([]);
    //const { width } = useWindowDimensions();
    //const WIDTH_WEIGHT = width < Dimensions.tablet_mini.width ? 0.9 : 0.8
    //const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedFilter, setSelectedFilter ] = useState(filterMenu[0].value)
    const [selectedFilterValue, setSelectedFilterValue] = useState("");
    const [filterQueryParams, setFilterQueryParams] = useState("")
    const [currentAction, setCurrentAction] = useState("");
    const [selectedKeypair, setSelectedKeypair] = useState({})
    const [usersList, setUsersList] = useState([])
    const [showKeypairDetailsOpen, setShowKeypairDetailsOpen] = useState(false)

    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [fetchDataRequired, setFetchDataRequired] = useState(true);

    const computeServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const computeServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const keypairsUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.keyPairsUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
            service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
            service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const usersUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.usersUrl)[0].url)

    const handleKeypairDataFormatting = useCallback((data) => {
        const formatted_data = data.map(item => {
            let new_item = {...item.keypair}
            new_item["public_key_details"] = 
            <WrapperBox
                sx={{
                    border: 1,
                    borderColor: "primary",
                    borderRadius: 1,
                    p: 1,
                    "&:hover": {backgroundColor: "primary.main"}
                }}
            >
                {defaultTexts.showProjectsAccessTableLabelText}
            </WrapperBox>
            
            return new_item
        })

        setKeypairsData(formatted_data)
    },[defaultTexts])

    const handleShowKeypairDetails = useCallback((row) => {
        setSelectedKeypair(keypairsData[row])
        setShowKeypairDetailsOpen(true)
    },[keypairsData])

    const handleShowKeypairDetailsClose = () => {
        setSelectedKeypair({})
        setShowKeypairDetailsOpen(false)
    }
    
    //const handleSuccessDialogClose = () => {
    //    setSuccessDialogOpen(false);
    //};

    const handleDataFetch = () => {
        setFetchDataRequired(true)
    }

    const handleFilteredSearch = () => {
        if (selectedFilter && selectedFilterValue) {
            setFilterQueryParams(`?${selectedFilter}=${selectedFilterValue}`)
        } else {
            setFilterQueryParams("")
        }
    }

    const handleFilterReset = () => {
        setSelectedFilter(filterMenu[0].value)
        setSelectedFilterValue("")
        setFilterQueryParams("")
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    //const handleDetailCardOpen = (index) => {
    //    setSelectedRow(flavorsData[index])
    //    setDetailCardOpen(true)
    //};

    //const handleDetailCardClose = () => {
    //    setDetailCardOpen(false)
    //    setSelectedRow(null)
    //};

    const handleDeleteKeypairAction = async (keypair_name, token) => {
        const url = `${computeServiceDomain}/${computeServiceVersion}/${keypairsUrl}/${keypair_name}`
        const method = "DELETE"
        await openstackRequest({url:url, method:method, token: token})
    }

    const onKeypairDelete = async (keypair_list) => {
        setIsLoading(true)
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            for (let k in keypair_list) {
                await handleDeleteKeypairAction(keypair_list[k], project_token)
            }
        }
        setCurrentAction("")
        setIsLoading(false)
        handleDataFetch()
    }

    const getKeypairsActionsList = () => {
        let keypair_actions = []
        let new_action = {}

        new_action["value"] = "keypair_delete"
        new_action["action"] = onKeypairDelete
        new_action["keyword"] = "keypairDeleteActionLabel"
        new_action["button_text"] = "applyButtonTitleText"
        keypair_actions.push({...new_action})
        
        return keypair_actions
    }

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    useEffect(() => {
        setFetchDataRequired(true)
    },[filterQueryParams]);

    useEffect(() => {
        handleLoading(true)
        if (fetchDataRequired) {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${computeServiceDomain}/${computeServiceVersion}/${keypairsUrl}${filterQueryParams}`
                const method = "GET"

                const keypairs_response = await openstackRequest({url:url, method:method, token: project_token})
                if (keypairs_response.status_code === keypairsUrlResponses.get.success_response.status_code) {
                    handleKeypairDataFormatting([...keypairs_response.data.keypairs])
                    if (selectedRow) {
                        let new_data = keypairs_response.data.keypairs.filter(keypair => keypair.keypair.name === selectedRow.name)
                        if (new_data.length > 0) {
                            setSelectedRow(new_data[0])
                        } else {
                            setSelectedRow(null)
                        }
                    }
                } else {
                    const error_response = keypairsUrlResponses.get.error_response.filter(
                        error_item => error_item.status_code === keypairs_response.status_code)
                    if (error_response.length > 0) {
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: keypairs_response.error
                        }
                        setError(errorObject)
                    } else {
                        const error_response = keypairsUrlResponses.get.error_response.filter(
                            error_item => error_item.status_code === "unknown")
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: keypairs_response.error
                        }
                        setError(errorObject)
                    }
                }
            }
        })();
        setFetchDataRequired(false)
        }
        setTimeout(()=>{handleLoading(false)},700)
    },[
        computeServiceDomain, 
        computeServiceVersion, 
        keypairsUrl, 
        filterQueryParams,
        fetchDataRequired,
        handleKeypairDataFormatting,
        defaultAdminProject,
        selectedRow
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${usersUrl}`
            const method = "GET"
            const users_response = await openstackRequest({url:url, method:method})
            
            if ( users_response.status_code === usersUrlResponses.get.success_response.status_code) {
                const users_list = users_response.data.users.map(user => {
                    return {keyword: user.name, value: user.id, default: false}
                })
                setUsersList(users_list)
            }
        })()
    },[
        identityServiceDomain,
        identityServiceVersion,
        usersUrl
    ]);

    useEffect(() => {
        const new_filter_menu = keypairsFilterMenu.map(item => {
            if (item.value === "user_id") {
                item.items = usersList
            }
            return item
        })
        setFilterMenu(new_filter_menu)
    },[usersList]);

    return (
        <Box>
            <ComputeKeypairsSubheaderV21 
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                selectedFilterValue={selectedFilterValue}
                setSelectedFilterValue={setSelectedFilterValue}
                filterMenu={filterMenu}
                handleFilteredSearch={handleFilteredSearch}
                handleFilterReset={handleFilterReset}
                handleFetchData={handleDataFetch}
                defaultTexts={defaultTexts}
                users={usersList}
            />
            {isLoading && <CustomBackdrop open={isLoading} />}
            <ComputeKeypairsTableV21 
                keypairsData={keypairsData}
                setKeypairsData={setKeypairsData}
                //handleRowSelection={handleDetailCardOpen}
                handleCellClick={{public_key_details: handleShowKeypairDetails}}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getKeypairsActionsList()}
            />
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>Details:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            <CustomDialog
                open={showKeypairDetailsOpen}
                onClose={handleShowKeypairDetailsClose}
                dialogTitle={{
                    title: defaultTexts.keypairDetailsDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
            >
                    { 
                        <React.Fragment>
                            <WrapperBox 
                                sx={{
                                    my: 1, 
                                    alignItems: "start", 
                                    flexDirection: "row",
                                    justifyContent: "flex-start"
                                }}
                            >
                                <CustomText size="p" sx={{mr: 1, color: "text.secondary"}}>{defaultTexts.nameFormFieldLabel}:</CustomText>
                                <CustomText size="p" sx={{ml: 1}}>{selectedKeypair.name}</CustomText> 
                            </WrapperBox>
                            <Divider />
                            <WrapperBox 
                                sx={{
                                    my: 1, 
                                    alignItems: "start", 
                                    flexDirection: "row",
                                    justifyContent: "flex-start"
                                }}
                            >
                                <CustomText size="p" sx={{mr: 1, color: "text.secondary"}}>{defaultTexts.fingerprintFormFieldLabel}:</CustomText>
                                <CustomText size="p" sx={{ml: 1}}>{selectedKeypair.fingerprint}</CustomText> 
                            </WrapperBox>
                            <Divider />
                            <div style={{'white-space': 'pre-wrap', 'overflow-wrap': 'break-word'}}>
                                <p>{selectedKeypair.public_key}</p>
                            </div>
                        </React.Fragment>
                    }
            </CustomDialog>
        </Box>
    )
}

export default ComputeKeypairsWrapperV21;