import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import WrapperBox from '../../../../_common/WrapperBox';
import CustomSideDrawer from '../../../../_common/CustomSideDrawer';
import { openstackRequest, getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { flavorsUrl as flavorsUrlResponses } from 
'../../../../../_api_responses/openstack/compute/flavors/v2.1';
import { projectsUrl as projectsUrlResponses} from
'../../../../../_api_responses/openstack/identity/projects/v3';
import { flavorsFilterMenu } from 
'../../../../../_data/openstack/compute/flavors/v2.1';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { computeNovaConstants, identityKeystonConstants } from 
'../../../../../config/openStackConstants';
import ComputeFlavorsSubheaderV21 from './computeFlavorsSubheaderV2_1';
import ComputeFlavorsTableV21 from './computeFlavorsTableV2_1';
import CustomDialog from '../../../../_common/CustomDialog';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import CustomText from '../../../../_common/CustomText';
import FlavorDetailV21 from './computeFlavorDetailV2_1'
import { Divider } from '@mui/material';
import CustomSelectField from '../../../../_common/_form_fields/CustomSelectField';
import Box from '@mui/material/Box';
import useWindowDimensions from '../../../../_common/WindowDimensions';
import Dimensions from '../../../../../config/dimensions';

const SERVICE_NAME = openStackServices.computeService
const IDENTITY_SERVICE_NAME = openStackServices.identityService
const default_url_query = "?is_public=none"

const getWidthWeight = (width) => {
    if (width < Dimensions.tablet_mini.width) {
        return 0.9
    } else if (width < Dimensions.tablet.width) {
        return 0.6
    } else {
        return 0.4
    }
}

const ComputeFlavorsWrapperV21 = (props) => {
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState()
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)

    const filterMenu = flavorsFilterMenu
    const [flavorsData, setFlavorsData] = useState([]);
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = getWidthWeight(width)
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedFlavor, setSelectedFlavor] = useState(null);
    const [selectedFilter, setSelectedFilter ] = useState(filterMenu[0].value)
    const [selectedFilterValue, setSelectedFilterValue] = useState("");
    const [filterQueryParams, setFilterQueryParams] = useState(default_url_query)
    const [sortQueryParams, setSortQueryParams] = useState("");
    const [currentAction, setCurrentAction] = useState("");
    const [retrievedFlavorProjects, setRetrievedFlavorProjects] = useState([])
    const [selectedFlavorProject, setSelectedFlavorProject] = useState("")
    const [selectedFlavors, setSelectedFlavors] = useState([])
    const [flavorDeleteConfirmDialogOpen, setFlavorDeleteConfirmDialogOpen] = useState(false);
    const [projectsList, setProjectsList] = useState([])
    const [flavorAccessProjectSelectOpen, setFlavorAccessProjectSelectOpen] = useState(false)
    const [flavorAccessProjectSelectToRemoveOpen, setFlavorAccessProjectSelectToRemoveOpen]  = useState(false)
    const [showFlavorAccessProjectsOpen, setShowFlavorAccessProjectsOpen] = useState(false)

    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [fetchDataRequired, setFetchDataRequired] = useState(true);

    const computeServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const computeServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const flavorsUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.flavorsUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
            service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
            service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    
    const handleShowFlavorAccessProjects = useCallback((row) => {
        getXAuthTokenProjectScope(defaultAdminProject).then(token => {
            const url = `${computeServiceDomain}/${computeServiceVersion}/${flavorsUrl}/${flavorsData[row].id}/os-flavor-access`
            const method = "GET"
            openstackRequest({url:url, method:method, token: token}).then(flavor_response => {
                if (flavor_response.status_code === flavorsUrlResponses.get.success_response.status_code) {
                    showFlavorAccessProjects(flavor_response.data.flavor_access)
                } else {
                    const error_response = flavorsUrlResponses.get.error_response.filter(
                        error_item => error_item.status_code === flavor_response.status_code)
                    if (error_response.length > 0) {
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: flavor_response.error
                        }
                        setError(errorObject)
                    } else {
                        const error_response = flavorsUrlResponses.get.error_response.filter(
                            error_item => error_item.status_code === "unknown")
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: flavor_response.error
                        }
                        setError(errorObject)
                    }
                }
            })
        })
    },[
        computeServiceDomain,
        computeServiceVersion,
        flavorsUrl,
        flavorsData,
        defaultAdminProject
    ])

    const handleFlavorDataFormatting = useCallback((data) => {

        const formatted_data = data.map(item => {
            let new_item = {...item}
            if (item.ram > (1024 * 1024) - 1) {
                new_item.ram = `${Math.round(item.ram / 1024 * 1024)} TB`
            } else if (item.ram > 1023) {
                new_item.ram = `${Math.round(item.ram / 1024)} GB`
            } else {
                new_item.ram = `${item.ram} MB`
            }

            if (item.swap) {
                if (item.swap > 1023) {
                    new_item.swap = `${Math.round(item.swap / 1024 * 1024)} GB`
                } else {
                    new_item.swap = `${item.swap} MB`
                }
            }

            if (item["os-flv-ext-data:ephemeral"]) {
                new_item["os-flv-ext-data:ephemeral"] = `${item["os-flv-ext-data:ephemeral"]} GB`
            }
            new_item.disk = `${item.disk} GB`
            
            
            if (item["os-flavor-access:is_public"]) {
                new_item["os_flavor_access"] = defaultTexts.allProjectsAccessTableLabelText
            } else {
                new_item["os_flavor_access"] = 
                <WrapperBox
                    sx={{
                        border: 1,
                        borderColor: "primary",
                        borderRadius: 1,
                        p: 1,
                        "&:hover": {backgroundColor: "primary.main"}
                    }}
                >
                    {defaultTexts.showProjectsAccessTableLabelText}
                </WrapperBox>
            }
            return new_item
        })

        setFlavorsData(formatted_data)
    },[defaultTexts])

    const showFlavorAccessProjects = (project_list) => {
        setRetrievedFlavorProjects(project_list)
        setShowFlavorAccessProjectsOpen(true)
    }

    const handleShowFlavorAccessProjectsClose = () => {
        setRetrievedFlavorProjects([])
        setShowFlavorAccessProjectsOpen(false)
    }

    const handleDataFetch = () => {
        setFetchDataRequired(true)
    }

    const handleFilteredSearch = () => {
        if (selectedFilter && selectedFilterValue) {
            setFilterQueryParams(`?${selectedFilter}=${selectedFilterValue}`)
        } else {
            setFilterQueryParams(default_url_query)
        }
    }

    const handleFlavorSorting = (field, direction) => {
        let new_query = `&sort_key=${field}&sort_dir=${direction}`
        setSortQueryParams(new_query)
    }

    const handleFilterReset = () => {
        setSelectedFilter(filterMenu[0].value)
        setSelectedFilterValue("")
        setFilterQueryParams(default_url_query)
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleDetailCardOpen = (index) => {
        setSelectedFlavor(flavorsData[index].id)
        setSelectedRow(flavorsData[index])
        setTimeout(() => setDetailCardOpen(true), 100)   
    };

    const handleDetailCardClose = () => {
        setTimeout(() => setDetailCardOpen(false), 100)   
        setSelectedFlavor(null)
        setSelectedRow(null)
    };

    const onFlavorDeleteConfirm = (n_list) => {
        const selected_n_list = flavorsData.filter(n => 
            n_list.includes(n.id))
        setSelectedFlavors([...selected_n_list])
        setFlavorDeleteConfirmDialogOpen(true)
    }

    const handleFlavorDeleteConfirmDialogClose = () => {
        setFlavorDeleteConfirmDialogOpen(false)
    }

    const handleDeleteFlavorAction = async (flavor_id,token) => {
        const url = `${computeServiceDomain}/${computeServiceVersion}/${flavorsUrl}/${flavor_id}`
        const method = "DELETE"
        const nt_response = await openstackRequest({url:url, method:method, token: token})
        if (nt_response.status_code === flavorsUrlResponses.delete.success_response.status_code) {
            return null
        } else {
            return nt_response.error
        }
    }

    const onFlavorDelete = async (flavor_list) => {
        handleFlavorDeleteConfirmDialogClose()
        let err = []
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            for (let n in flavor_list) {
                const resp = await handleDeleteFlavorAction(flavor_list[n].id,project_token)
                if (resp !== null) {
                    err = [...err, resp]
                }
            }
        }
        handleDetailCardClose()
        setCurrentAction("")
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const handleAddFlavorAccessAction = async (flavor_id,token) => {
        const url = `${computeServiceDomain}/${computeServiceVersion}/${flavorsUrl}/${flavor_id}/action`
        const method = "POST"
        const data = {"addTenantAccess":{"tenant": selectedFlavorProject}}
        await openstackRequest({url:url, method:method, data: data, token: token})
    }

    const onFlavorAddAccess = async () => {
        setFlavorAccessProjectSelectOpen(false)
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            for (let f in selectedFlavors) {
                if (!selectedFlavors[f]["os-flavor-access:is_public"]) {
                    await handleAddFlavorAccessAction(selectedFlavors[f],project_token)
                }
            }
        }
        setCurrentAction("")
        setSelectedFlavorProject("")
        handleDataFetch()
    }

    const handleFlavorAccessProjectSelect = (flavor_list) => {
        setSelectedFlavors(flavor_list)
        setFlavorAccessProjectSelectOpen(true)
    }

    const handleflavorAccessProjectSelectClose = () => {
        setSelectedFlavorProject("")
        setFlavorAccessProjectSelectOpen(false)
    }

    const handleFlavorAccessProjectSelectForRemove = (flavor_list) => {
        setSelectedFlavors(flavor_list)
        setFlavorAccessProjectSelectToRemoveOpen(true)
    }

    const handleflavorAccessProjectSelectToRemoveClose = () => {
        setSelectedFlavorProject("")
        setFlavorAccessProjectSelectToRemoveOpen(false)
    }

    const handleRemoveFlavorAccessAction = async (flavor_id,token) => {
        const url = `${computeServiceDomain}/${computeServiceVersion}/${flavorsUrl}/${flavor_id}/action`
        const method = "POST"
        const data = {"removeTenantAccess":{"tenant": selectedFlavorProject}}
        await openstackRequest({url:url, method:method, data: data, token: token})
    }

    const onFlavorRemoveAccess = async () => {
        setFlavorAccessProjectSelectToRemoveOpen(false)
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            for (let f in selectedFlavors) {
                if (!selectedFlavors[f]["os-flavor-access:is_public"]) {
                    await handleRemoveFlavorAccessAction(selectedFlavors[f],project_token)
                }
            }
        }
        setCurrentAction("")
        setSelectedFlavorProject("")
        handleDataFetch()
    }

    const getFlavorsActionsList = () => {
        let flavor_actions = []
        let new_action = {}

        new_action["value"] = "flavor_delete"
        new_action["action"] = onFlavorDeleteConfirm
        new_action["keyword"] = "flavorDeleteActionLabel"
        new_action["button_text"] = "applyButtonTitleText"
        flavor_actions.push({...new_action})

        new_action["value"] = "project_add_access"
        new_action["action"] = handleFlavorAccessProjectSelect
        new_action["keyword"] = "privateFlavorAddProjectAccessActionLabel"
        new_action["button_text"] = "applyButtonTitleText"
        flavor_actions.push({...new_action})
    
        new_action["value"] = "project_remove_access"
        new_action["action"] = handleFlavorAccessProjectSelectForRemove
        new_action["keyword"] = "privateFlavorRemoveProjectAccessActionLabel"
        new_action["button_text"] = "applyButtonTitleText"
        flavor_actions.push({...new_action})
        
        return flavor_actions
    }

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    useEffect(() => {
        setFetchDataRequired(true)
    },[filterQueryParams,sortQueryParams]);

    useEffect(() => {
        if (fetchDataRequired) {
        (async () => {
            handleLoading(true)
            const url = `${computeServiceDomain}/${computeServiceVersion}/${flavorsUrl}/detail${filterQueryParams}${sortQueryParams}`
            const method = "GET"

            const flavors_response = await openstackRequest({url:url, method:method})
            if (flavors_response.status_code === flavorsUrlResponses.get.success_response.status_code) {
                handleFlavorDataFormatting([...flavors_response.data.flavors])
                if (selectedFlavor) {
                    const selected_flavor = flavors_response.data.flavors.filter(item => item.id === selectedFlavor)
                    if (selected_flavor.length > 0) {
                        setSelectedRow(selected_flavor[0])
                    }
                }
            } else {
                const error_response = flavorsUrlResponses.get.error_response.filter(
                    error_item => error_item.status_code === flavors_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: flavors_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = flavorsUrlResponses.get.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: flavors_response.error
                    }
                    setError(errorObject)
                }
            }
        })();
        setFetchDataRequired(false)
        }
        setTimeout(()=>{handleLoading(false)},700)
    },[
        computeServiceDomain, 
        computeServiceVersion, 
        flavorsUrl, 
        selectedFlavor,
        filterQueryParams,
        fetchDataRequired,
        sortQueryParams,
        handleFlavorDataFormatting,
        selectedRow
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"
            const projects_response = await openstackRequest({url:url, method:method})
            
            if ( projects_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                const projects_list = projects_response.data.projects.map(project => {
                    return {keyword: project.name, value: project.id, default: false}
                })
                setProjectsList(projects_list)
            }
        })()
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    return (
        <Box>
            <ComputeFlavorsSubheaderV21 
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                selectedFilterValue={selectedFilterValue}
                setSelectedFilterValue={setSelectedFilterValue}
                filterMenu={filterMenu}
                handleFilteredSearch={handleFilteredSearch}
                handleFilterReset={handleFilterReset}
                handleFetchData={handleDataFetch}
                defaultTexts={defaultTexts}
            />
            {isLoading && <CustomBackdrop open={isLoading} />}
            <ComputeFlavorsTableV21 
                flavorsData={flavorsData}
                setFlavorsData={setFlavorsData}
                handleRowSelection={handleDetailCardOpen}
                handleCellClick={{os_flavor_access: handleShowFlavorAccessProjects}}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getFlavorsActionsList()}
                selfSorting={true}
                sortHandler={handleFlavorSorting}
            />
            {selectedRow !== null && 
                <CustomSideDrawer 
                    open={detailCardOpen}
                    widthWeight={WIDTH_WEIGHT}
                    handleDrawerOpen={handleDetailCardOpen}
                    handleDrawerClose={handleDetailCardClose}
                > 
                    <FlavorDetailV21
                        selectedRow={selectedRow}
                        widthWeight={WIDTH_WEIGHT}
                        handleDataFetch={handleDataFetch}
                        handleDelete={onFlavorDeleteConfirm}
                        handleFlavorAccessProjectSelect={handleFlavorAccessProjectSelect}
                        handleFlavorAccessProjectSelectForRemove={handleFlavorAccessProjectSelectForRemove}
                    />
                </CustomSideDrawer>
            }
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>Details:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            <CustomDialog
                open={showFlavorAccessProjectsOpen}
                onClose={handleShowFlavorAccessProjectsClose}
                dialogTitle={{
                    title: defaultTexts.flavorAccessProjectsDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
            >
                {retrievedFlavorProjects.length > 0 && 
                    retrievedFlavorProjects.map(project => 
                        <React.Fragment key={project.tenant_id}>
                            <WrapperBox sx={{my: 1, alignItems: "start"}}>
                                {projectsList.filter(item => item.value === project.tenant_id)[0].keyword}
                            </WrapperBox>
                            <Divider />
                        </React.Fragment>
               )
            }
                {retrievedFlavorProjects.length === 0 && 
                    <CustomText size="p">{defaultTexts.noAccessProjectsNoteText}</CustomText>
                }
            </CustomDialog>
            <CustomDialog
                open={flavorAccessProjectSelectOpen}
                onClose={handleflavorAccessProjectSelectClose}
                dialogTitle={{
                    title: defaultTexts.addFlavorAccessProjectsDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.flavorAccessProjectsAddDialogMessage, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.applyButtonTitleText, 
                    onClick: onFlavorAddAccess, 
                    variant: "contained",
                    size: "medium"
                }]}
            >
                <CustomSelectField 
                    currentValue={selectedFlavorProject}
                    setCurrentValue={setSelectedFlavorProject}
                    items={projectsList}
                    self_item_titles={true}
                    label={defaultTexts.projectFormFieldLabel}
                    size="large"
                    empty={true}
                    required={true}
                    sx={{mt: 5, width: '90%'}}
                />
            </CustomDialog>
            <CustomDialog
                open={flavorDeleteConfirmDialogOpen}
                onClose={handleFlavorDeleteConfirmDialogClose}
                dialogTitle={{
                    title: defaultTexts.flavorDeleteConfirmTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `${defaultTexts.flavorDeleteConfirmText}: [${selectedFlavors.map(v => v.name).toString()}]`, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: () => onFlavorDelete(selectedFlavors), 
                    sx: {color: 'primary.main'}}]}
            />
            <CustomDialog
                open={flavorAccessProjectSelectToRemoveOpen}
                onClose={handleflavorAccessProjectSelectToRemoveClose}
                dialogTitle={{
                    title: defaultTexts.removeFlavorAccessProjectsDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.flavorAccessProjectsRemoveDialogMessage, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.applyButtonTitleText, 
                    onClick: onFlavorRemoveAccess, 
                    variant: "contained",
                    size: "medium"
                }]}
            >
                <CustomSelectField 
                    currentValue={selectedFlavorProject}
                    setCurrentValue={setSelectedFlavorProject}
                    items={projectsList}
                    self_item_titles={true}
                    label={defaultTexts.projectFormFieldLabel}
                    size="large"
                    empty={true}
                    required={true}
                    sx={{mt: 5, width: '90%'}}
                />
            </CustomDialog>
        </Box>
    )
}

export default ComputeFlavorsWrapperV21;