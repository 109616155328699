import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import CustomDialog from '../../../../_common/CustomDialog';
import useWindowDimensions from '../../../../_common/WindowDimensions';
import Dimensions from '../../../../../config/dimensions';
import CustomSideDrawer from '../../../../_common/CustomSideDrawer';
import CustomTextField from '../../../../_common/_form_fields/CustomTextField';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Switch from '@mui/material/Switch';
import { openStackServices, 
        blockStorageCinderConstants, 
        identityKeystonConstants,
        computeNovaConstants
} from '../../../../../config/openStackConstants';
import VolumesSubheaderV3 from './volumesSubheaderV3';
import VolumeTransfersSubheaderV3 from './volumeTransfersSubheaderV3';
import VolumesTableV3 from './volumesTableV3';
import VolumeTransfersTableV3 from './volumeTransfersTableV3';
import { volumesFilterMenu } 
from '../../../../../_data/openstack/cinder/volumes/v3';
import { 
    volumeCinderRequest, 
    getXAuthTokenProjectScope, 
    openstackRequest 
} from '../../../../../_network/openstack_request';
import { volumesUrl as volumeUrlResponses } 
from '../../../../../_api_responses/openstack/cinder/volumes/v3';
import VolumeDetailV3 from './volumeDetailV3';
import FormGroup from '@mui/material/FormGroup'
import { usersUrl as usersUrlResponses } from 
'../../../../../_api_responses/openstack/identity/users/v3';
import CustomCheckboxField from 
'../../../../_common/_form_fields/CustomCheckboxField';

const SERVICE_NAME = openStackServices.volumeService
const COMPUTE_SERVICE_NAME = openStackServices.computeService
const IDENTITY_SERVICE_NAME = openStackServices.identityService
const TRANSFERS_MIN_API_VERSION = "3.55"

const VolumesWrapperV3 = (props) => {
    const { navigate, location, changeMenuActiveTab } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [isLoading, setIsLoading ] = useState(true);
    const [error, setError] = useState();
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [currentTab, setCurrentTab] = useState("volumes")
    const [volumesData, setVolumesData] = useState([])
    const [volumes, setVolumes] = useState([])
    const [volumeTransfersData, setVolumeTransfersData] = useState([])
    const [volumeTransfers, setVolumeTransfers] = useState([])
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = width < Dimensions.tablet_mini.width ? 0.9 : 0.8
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedVolume, setSelectedVolume] = useState(null);
    const [dataFetchingRequired, setDataFetchingRequired] = useState(true);
    const [fetchTransferDataRequired, setFetchTransferDataRequired] = useState(true);
    const [currentAction, setCurrentAction] = useState("");
    const [currentTransfersAction, setCurrentTransfersAction] = useState("");
    const [volumeDeleteConfirmDialogOpen, setVolumeDeleteConfirmDialogOpen] = useState(false);
    const [volumeTransferDeleteConfirmDialogOpen, setVolumeTransferDeleteConfirmDialogOpen] = useState(false)
    const [selectedVolumes, setSelectedVolumes] = useState([])
    const [selectedVolumeTransfers, setSelectedVolumeTransfers] = useState([])
    const [volumesSortParams, setVolumesSortParams] = useState("")
    
    const [volumeFilterQueryParams, setVolumeFilterQueryParams] = useState("?all_tenants=true")
    const [selectedVolumeFilter, setSelectedVolumeFilter] = useState(volumesFilterMenu[0].value)
    const [selectedVolumeFilterValue, setSelectedVolumeFilterValue] = useState("")
    const [volumesFilter, setVolumesFilter] = useState([...volumesFilterMenu])

    const [projects, setProjects] = useState([])
    const [serversList, setServersList] = useState([])
    const [volumeTypes, setVolumeTypes] = useState([])
    const [volumeTypesList, setVolumeTypesList] = useState([])
    const [volumeGroupsList, setVolumeGroupsList] = useState([])

    const [changeBootableDialogOpen, setChangeBootableDialogOpen] = useState(false)
    const [selectedBootableStatus, setSelectedBootableStatus] = useState(true)

    const [volumeDeleteCascade, setVolumeDeleteCascade] = useState(true)
    const [volumeDeleteForce, setVolumeDeleteForce] = useState(false)
    const [providedAuthKey, setProvidedAuthKey] = useState("")
    const [volumeTransferAcceptDialogOpen, setVolumeTransferAcceptDialogOpen] = useState(false)
    const [providedAuthKeyError, setProvidedAuthKeyError] = useState(false)
    const [volumeGroups, setVolumeGroups] = useState([])

    const cinderServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const cinderServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const maxAPIVersion = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].max_api_version)
    const volumesUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.volumesUrl)[0].url)
    const volumeTransfersUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.volumeTransfersUrl)[0].url)
    const volumeTypesUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.volumeTypesUrl)[0].url)
    const volumeGroupsUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.groupsUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    const computeServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === COMPUTE_SERVICE_NAME)[0].config_params.service_domain)
    const computeServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === COMPUTE_SERVICE_NAME)[0].config_params.api_version)
    const serversUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.serversUrl)[0].url)
    const groupsUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.groupsUrl)[0].url)
    

    const handleDataFetch = () => {
        setDataFetchingRequired(true)
    }

    const handleTransfersDataFetch = () => {
        setFetchTransferDataRequired(true)
    }

    const handleTabChange = (tab_name) => {
        setCurrentTab(tab_name)
    }

    const handleVolumeFilteredSearch = () => {
        if (selectedVolumeFilter && selectedVolumeFilterValue) {
            setVolumeFilterQueryParams(`?${selectedVolumeFilter}=${selectedVolumeFilterValue}&&all_tenants=true`)
        } else {
            setVolumeFilterQueryParams("?all_tenants=true")
        }
        handleDataFetch()
    }

    const handleVolumesDataFormatting = useCallback(() => {
        const formatted_data = volumesData.map((item) => {
            let new_item = {...item}
            new_item.size = `${item.size}GB`
            new_item.bootable = Boolean(item.bootable === "true")
            const project = projects.filter(p => p.id === item[blockStorageCinderConstants.volumeProjectIdField])
            if (project.length > 0) {
                new_item[blockStorageCinderConstants.volumeProjectIdField] = project[0].name
            }
            const vol_group = volumeGroups.filter(p => p.id === item.group_id)
            if (vol_group.length > 0) {
                new_item.group_id = vol_group[0].name ? vol_group[0].name : item.group_id
            }
            if (item.attachments.length > 0) {
                let attachments = item.attachments.map(atch => {
                    const server = serversList.filter(s => s.id === atch.server_id)
                    if (server.length > 0) {
                        return `${server[0].name} -> ${atch.device}`
                    } else {
                        return `${atch.server_id} -> ${atch.device}`
                    }
                    
                })
                new_item.attachments = attachments
            }
            return new_item
        })
        setVolumes(formatted_data)
    },[
        volumesData,
        projects,
        serversList,
        volumeGroups
    ])

    const handleVolumeFilterReset = () => {
        setSelectedVolumeFilter(volumesFilterMenu[0].value)
        setSelectedVolumeFilterValue("")
        setVolumeFilterQueryParams("?all_tenants=true")
        handleDataFetch()
    }

    const handleNavigateToServer = (server_id) => {
        handleDetailCardClose()
        navigate("/compute",{state: {server_id: server_id}})
    }

    const handleNavigateToVolumeGroup = (group_id,tab_name) => {
        handleDetailCardClose()
        changeMenuActiveTab(tab_name)
        navigate("/block-storage",{state: {group_id: group_id}})
    }

    const handleNavigateToVolumeType = (volume_type_id,tab_name) => {
        handleDetailCardClose()
        changeMenuActiveTab(tab_name)
        navigate("/block-storage",{state: {volume_type_id: volume_type_id}})
    }

    const handleNavigateToSnapshot = (snapshot_id,tab_name) => {
        handleDetailCardClose()
        changeMenuActiveTab(tab_name)
        navigate("/block-storage",{state: {snapshot_id: snapshot_id}})
    }

    const handleNavigateToVolume = (volume_id,tab_name) => {
        handleDetailCardClose()
        changeMenuActiveTab(tab_name)
        navigate("/block-storage",{state: {volume_id: volume_id}})
    }

    const handleNavigateToBackup = (backup_id,tab_name) => {
        handleDetailCardClose()
        changeMenuActiveTab(tab_name)
        navigate("/block-storage",{state: {backup_id: backup_id}})
    }

    const handleNavigateToImage = (image_id) => {
        handleDetailCardClose()
        navigate("/images",{state: {image_id: image_id}})
    }

    const handleDetailCardOpen = useCallback((index) => {
        setSelectedVolume(volumesData[index].id)
        setSelectedRow(volumesData[index])
        setTimeout(() => setDetailCardOpen(true),100)
    },[volumesData]);

    const handleDetailCardClose = () => {
        setTimeout(() => setDetailCardOpen(false),100)
        setSelectedRow(null)
        setSelectedVolume(null)
        navigate(location.path,{})
    };

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleVolumesSorting = (field,direction) => {
        const sort_param = `&&sort=${field}:${direction}`
        setVolumesSortParams(sort_param)
        handleDataFetch()
    }

    const handleChangeBootableDialogClose = () => {
        setChangeBootableDialogOpen(false)
    }

    const handleChangeBootableDialogOpen = (volume_list) => {
        const selected_v_list = volumesData.filter(v => 
            volume_list.includes(v.id))
        setSelectedVolumes([...selected_v_list])
        setChangeBootableDialogOpen(true)
    }

    const handleBootableStatusChange = (event) => {
        setSelectedBootableStatus(event.target.checked)
    }

    const handleBootableStatusUpdate = async (volume_id) => {
        let data = {}
        data["os-set_bootable"] = {bootable: selectedBootableStatus}
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumesUrl}/${volume_id}/action`
            const method = "POST"
            
            const v_response = await volumeCinderRequest({
                url:url, 
                method:method,
                data: data,
                token: project_token,
                has_response: false
            })
            if (v_response.status_code === volumeUrlResponses.post.success_response.status_code) {
                return null
            } else {
                return v_response.error
            }
        }
    }

    const onBootableStatusUpdate = async () => {
        handleChangeBootableDialogClose()
        let err = []
        for (let v in selectedVolumes) {
            const resp = await handleBootableStatusUpdate(selectedVolumes[v].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const handleVolumeDelete = async (v_id) => {
        let cascade = ""
        let force = ""
        if (volumeDeleteCascade) {
            cascade = "cascade=true&&"
        }
        if (volumeDeleteForce) {
            force = "force=true"
        }
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumesUrl}/${v_id}?${cascade}${force}`
            const method = "DELETE"
            
            const vt_response = await volumeCinderRequest({
                url:url, 
                method:method,
                token: project_token
            })

            if (vt_response.status_code === volumeUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return vt_response.error
            }
        }
    };

    const onVolumeDelete = async () => {
        handleVolumeDeleteConfirmDialogClose()
        let err = []
        for (let v in selectedVolumes) {
            const resp = await handleVolumeDelete(selectedVolumes[v].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleDetailCardClose()
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onVolumeDeleteConfirm = (v_list) => {
        const selected_v_list = volumesData.filter(v => 
            v_list.includes(v.id))
        setSelectedVolumes([...selected_v_list])
        setVolumeDeleteConfirmDialogOpen(true)
    }

    const handleVolumeTransferDelete = async (t_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            let url = ""
            if (parseFloat(maxAPIVersion) >= parseFloat(TRANSFERS_MIN_API_VERSION)) {
                url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTransfersUrl}/${t_id}`
            } else {
                url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/os-volume-transfer/${t_id}`
            }
            
            const method = "DELETE"
            
            const vt_response = await volumeCinderRequest({
                url:url, 
                method:method,
                token: project_token
            })

            if (vt_response.status_code === volumeUrlResponses.delete.success_response.status_code) {
                return null
            } else {
                return vt_response.error
            }
        }
    };

    const onVolumeTransferDelete = async () => {
        handleVolumeTransferDeleteConfirmDialogClose()
        let err = []
        for (let t in selectedVolumeTransfers) {
            const resp = await handleVolumeTransferDelete(selectedVolumeTransfers[t].id)
            if (resp !== null) {
                err = [...err, resp]
            }
        }
        handleTransfersDataFetch()
        handleDataFetch()
        if (err.length > 0) {
            let error_object = {}
            error_object["error_title"] = "errorDeleteRecordTitle"
            error_object["error_message"] = "errorDeleteRecordMessage"
            error_object["error_details"] = err.toString()
            setError(error_object)
            setErrorDialogOpen(true)
        }
    }

    const onVolumeTransferDeleteConfirm = (t_list) => {
        const selected_t_list = volumeTransfers.filter(t => 
            t_list.includes(t.id))
        setSelectedVolumeTransfers([...selected_t_list])
        setVolumeTransferDeleteConfirmDialogOpen(true)
    }

    const handleVolumeTransferAccept = async (t_id) => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            let url = ""
            if (parseFloat(maxAPIVersion) >= parseFloat(TRANSFERS_MIN_API_VERSION)) {
                url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTransfersUrl}/${t_id}/accept`
            } else {
                url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/os-volume-transfer/${t_id}/accept`
            }
            
            const method = "POST"
            
            const vt_response = await volumeCinderRequest({
                url:url, 
                method:method,
                data: {
                    accept: {
                        "auth_key": providedAuthKey
                    }
                },
                token: project_token
            })

            if (vt_response.status_code === volumeUrlResponses.post_async.success_response.status_code) {
                return null
            } else {
                return vt_response.error
            }
        }
    }

    const onVolumeTransferAccept = async () => {
        if (providedAuthKey.length === 0) {
            setProvidedAuthKeyError(true)
            return false
        } else {
            let err = []
            for (let t in selectedVolumeTransfers) {
                const resp = await handleVolumeTransferAccept(selectedVolumeTransfers[t].id)
                if (resp !== null) {
                    err = [...err, resp]
                }
            }
            handleVolumeTransferAcceptDialogClose()
            handleTransfersDataFetch()
            handleDataFetch()
            if (err.length > 0) {
                let error_object = {}
                error_object["error_title"] = "errorDeleteRecordTitle"
                error_object["error_message"] = "errorDeleteRecordMessage"
                error_object["error_details"] = err.toString()
                setError(error_object)
                setErrorDialogOpen(true)
            }
        }
    }

    const handleVolumeTransferAcceptDialogOpen = (t_list) => {
        const selected_t_list = volumeTransfers.filter(t => 
            t_list.includes(t.id))
        setSelectedVolumeTransfers([...selected_t_list])
        setVolumeTransferAcceptDialogOpen(true)
    }

    const handleVolumeTransferAcceptDialogClose = () => {
        setProvidedAuthKey("")
        setVolumeTransferAcceptDialogOpen(false)
    }

    const handleProvidedAuthKeyChange = (event) => {
        setProvidedAuthKeyError(false)
        setProvidedAuthKey(event.target.value)
    }


    const handleVolumeDeleteConfirmDialogClose = () => {
        setVolumeDeleteCascade(false)
        setVolumeDeleteForce(false)
        setVolumeDeleteConfirmDialogOpen(false)
    }

    const handleVolumeTransferDeleteConfirmDialogClose = () => {
        setVolumeTransferDeleteConfirmDialogOpen(false)
    }

    const handleVolumeDeleteForceChange = (event) => {
        setVolumeDeleteForce(event.target.checked)
    }

    const handleVolumeDeleteCascadeChange = (event) => {
        setVolumeDeleteCascade(event.target.checked)
    }

    const getVolumesActionsList = () => {
        let volume_actions = []
        let new_action = {}
        new_action["value"] = "update_bootable_status"
        new_action["action"] = handleChangeBootableDialogOpen
        new_action["keyword"] = "updateBootableStatusActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        volume_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "volume_delete"
        new_action["action"] = onVolumeDeleteConfirm
        new_action["keyword"] = "volumeDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        volume_actions.push({...new_action})
        
        return volume_actions
    }

    const getVolumeTransfersActionsList = () => {
        let volume_transfer_actions = []
        let new_action = {}
        new_action["value"] = "volume_transfer_accept"
        new_action["action"] = handleVolumeTransferAcceptDialogOpen
        new_action["keyword"] = "volumeTransferAcceptActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        volume_transfer_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "volume_transfer_delete"
        new_action["action"] = onVolumeTransferDeleteConfirm
        new_action["keyword"] = "volumeTransferDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        volume_transfer_actions.push({...new_action})
        
        return volume_transfer_actions
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    useEffect(() => {
        if (dataFetchingRequired) {
            (async () => {
                handleLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumesUrl}/detail${volumeFilterQueryParams}${volumesSortParams}`
                    const method = "GET"
                    const volume_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                    if (volume_response.status_code === volumeUrlResponses.get.success_response.status_code) {
                        setVolumesData(volume_response.data.volumes)
                        if (selectedVolume) {
                            const selected_volume = volume_response.data.volumes.filter(item => item.id === selectedVolume)
                            if (selected_volume.length > 0) {
                                setSelectedRow(selected_volume[0])
                            }
                        }
                    }
                }
            })();
        }
        setDataFetchingRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        cinderServiceDomain, 
        cinderServiceVersion, 
        volumesUrl, 
        volumeFilterQueryParams,
        dataFetchingRequired,
        defaultAdminProject,
        volumesSortParams,
        selectedVolume
    ]);

    useEffect(() => {
        if (fetchTransferDataRequired) {
            (async () => {
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = ""
                    if (parseFloat(maxAPIVersion) >= parseFloat(TRANSFERS_MIN_API_VERSION)) {
                        url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTransfersUrl}/detail?all_tenants=true`
                    } else {
                        url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/os-volume-transfer/detail?all_tenants=true`
                    }
                    
                    const method = "GET"
        
                    const volume_transfers_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                    if (volume_transfers_response.status_code === volumeUrlResponses.get.success_response.status_code) {
                        setVolumeTransfersData(volume_transfers_response.data.transfers)
                    }
                }
            })();
        }
        setFetchTransferDataRequired(false)
        setTimeout(()=>{handleLoading(false)},700)
    },[
        cinderServiceDomain, 
        cinderServiceVersion, 
        volumeTransfersUrl,
        fetchTransferDataRequired,
        defaultAdminProject,
        maxAPIVersion
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({
                url: url, 
                method: method
            })
            if (projects_response.status_code === usersUrlResponses.get.success_response.status_code) {
                setProjects(projects_response.data.projects)
            } else {
                setProjects([])
                }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${groupsUrl}/detail?all_tenants=true`
                const method = "GET"
                const groups_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                if (groups_response.status_code === volumeUrlResponses.get.success_response.status_code) {
                    setVolumeGroups(groups_response.data.groups)
                }
            }
        })();
    },[
        cinderServiceDomain, 
        cinderServiceVersion, 
        groupsUrl, 
        dataFetchingRequired,
        defaultAdminProject
    ]);

    useEffect(() => {
        const formatVolumeTransfersData = (data) => {
            const new_list = data.map(item => {
                let new_item = {...item} 
                if (item.source_project_id) {
                    const source = projects.filter(p => p.id === item.source_project_id)
                    if (source.length > 0) {
                        new_item.source_project_id = source[0].name
                    }
                }
                if (item.destination_project_id) {
                    const destination = projects.filter(p => p.id === item.destination_project_id)
                
                    if (destination.length > 0) {
                        new_item.destination_project_id = destination[0].name
                    }
                }
                const volume = volumesData.filter(v => v.id === item.volume_id)
                if (volume.length > 0) {
                    new_item.volume_id = volume[0].name ? volume[0].name : volume[0].id
                }
                
                return new_item
            })
            setVolumeTransfers(new_list)
        }

        formatVolumeTransfersData(volumeTransfersData)

    },[
        projects,
        volumeTransfersData,
        volumesData
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let all_volume_types = []
                let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}`
                const method = "GET"

                const volume_types_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                if (volume_types_response.status_code === volumeUrlResponses.get.success_response.status_code) {
                    all_volume_types = [...all_volume_types, ...volume_types_response.data.volume_types]
                }
                url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}?is_public=false`
                const response = await volumeCinderRequest({url:url, method:method, token: project_token})
                if (response.status_code === volumeUrlResponses.get.success_response.status_code) {
                    const private_vt = response.data.volume_types.filter(vt => !all_volume_types.map(i => i.id).includes(vt.id))
                    all_volume_types = [...private_vt, ...all_volume_types]
                }
                setVolumeTypes([...all_volume_types])
                const volume_types_list = all_volume_types.map(item => {
                    return {keyword: item.name, value: item.id, default: false}
                })
                setVolumeTypesList(volume_types_list)
            }
        })();
    },[
        cinderServiceDomain,
        cinderServiceVersion,
        defaultAdminProject,
        volumeTypesUrl
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeGroupsUrl}`
                const method = "GET"

                const volume_groups_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                if (volume_groups_response.status_code === volumeUrlResponses.get.success_response.status_code) {
                    const volume_groups_list = volume_groups_response.data.groups.map(item => {
                        return {keyword: item.name, value: item.id, default: false}
                    })
                    setVolumeGroupsList(volume_groups_list)
                }
                
            }
        })();
    },[
        cinderServiceDomain,
        cinderServiceVersion,
        defaultAdminProject,
        volumeGroupsUrl
    ]);

    useEffect(() => {
        (async () => {
            const url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/detail?all_tenants=true`
            const method = "GET"
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const servers_response = await openstackRequest({
                    url:url, 
                    method:method, 
                    token: project_token
                })
                setServersList(servers_response.data.servers)
            }
        })();
    },[
        computeServiceDomain,
        computeServiceVersion,
        serversUrl,
        defaultAdminProject
    ]);

    useEffect(() => {
        if (volumesData.length > 0) {
            handleVolumesDataFormatting()
        }
    },[
        volumesData,
        handleVolumesDataFormatting
    ])

    useEffect(() => {
        let projects_filter = []
        if (projects.length > 0) {
            projects_filter = projects.map(project => {
                return {keyword: project.name, value: project.id, default: false}
            })
        }
        let volume_types = []
        if (volumeTypes.length > 0) {
            volume_types = volumeTypes.map(vt => {
                return {keyword: vt.name, value: vt.id, default: false}
            })
        }
        let volume_filter_menu = volumesFilterMenu.map(fl => {
            let new_item = {...fl}
            if (fl.value === "project_id") {
                new_item.items = [...projects_filter]
            } else if (fl.value === "volume_type") {
                new_item.items = [...volume_types]
            }
            return new_item
        })

        setVolumesFilter(volume_filter_menu)
    },[
        volumesData,
        projects,
        volumeTypes
    ])

    useEffect(() => {
        if (!dataFetchingRequired && location.state ) {
            const volume_id = location.state ? location.state.volume_id : null
                const volume_index = volumesData.findIndex(v => v.id === volume_id);
                if (volume_index !== -1) {
                    setTimeout(() => handleDetailCardOpen(volume_index), 600)
                }
        }
    },[
        dataFetchingRequired,
        volumesData,
        handleDetailCardOpen,
        location
    ])

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
        {currentTab === "volumes" && 
            <VolumesSubheaderV3 
                selectedFilter={selectedVolumeFilter} 
                setSelectedFilter={setSelectedVolumeFilter}
                selectedFilterValue={selectedVolumeFilterValue}
                setSelectedFilterValue={setSelectedVolumeFilterValue}
                handleDataFetch={handleDataFetch}
                filterMenu={volumesFilter}
                handleFilteredSearch={handleVolumeFilteredSearch}
                handleFilterReset={handleVolumeFilterReset}
                currentTab={currentTab}
                handleTabChange={handleTabChange}
                volumeTypes={volumeTypesList}
                volumeGroups={volumeGroupsList}
                volumes={volumesData}
            />
        }
        {currentTab === "volume_transfers" && 
            <VolumeTransfersSubheaderV3 
                handleFetchData={handleTransfersDataFetch}
                currentTab={currentTab}
                handleTabChange={handleTabChange}
            />
        }
        {isLoading && <CustomBackdrop open={isLoading} />}
        {!isLoading && currentTab === "volumes" &&
            <VolumesTableV3 
                volumesData={volumes}
                setVolumesData={setVolumes}
                handleRowSelection={handleDetailCardOpen}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getVolumesActionsList()}
                sortHandler={handleVolumesSorting}
            />
        }
        {!isLoading && currentTab === "volume_transfers" && 
            <VolumeTransfersTableV3 
                volumeTransfersData={volumeTransfers}
                setVolumeTransfersData={setVolumeTransfers}
                currentAction={currentTransfersAction}
                setCurrentAction={setCurrentTransfersAction}
                actionsTexts={defaultTexts}
                actionsList={getVolumeTransfersActionsList()}
            />
        }
        {selectedRow !== null && currentTab === "volumes" && 
            <CustomSideDrawer 
                open={detailCardOpen}
                widthWeight={WIDTH_WEIGHT}
                handleDrawerOpen={handleDetailCardOpen}
                handleDrawerClose={handleDetailCardClose}
            > 
                <VolumeDetailV3
                    selectedRow={selectedRow}
                    widthWeight={WIDTH_WEIGHT}
                    handleDataFetch={handleDataFetch}
                    handleTransfersDataFetch={handleTransfersDataFetch}
                    handleNavigateToServer={handleNavigateToServer}
                    handleNavigateToVolumeGroup={handleNavigateToVolumeGroup}
                    handleNavigateToVolumeType={handleNavigateToVolumeType}
                    handleNavigateToSnapshot={handleNavigateToSnapshot}
                    handleNavigateToVolume={handleNavigateToVolume}
                    handleNavigateToBackup={handleNavigateToBackup}
                    handleNavigateToImage={handleNavigateToImage}
                    handleVolumeDelete={onVolumeDeleteConfirm}
                    projects={projects}
                    volumeGroups={volumeGroups}
                    volumes={volumes}
                    serversList={serversList}
                    volumeTypes={volumeTypes}
                    handleChangeBootableDialogOpen={handleChangeBootableDialogOpen}
                />       
            </CustomSideDrawer>
        }
        <CustomDialog
            open={changeBootableDialogOpen}
            onClose={handleChangeBootableDialogClose}
            dialogTitle={{
                title: defaultTexts.updateBootableStatusActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onBootableStatusUpdate, 
                sx: {color: 'primary.main'}}]}
        >
            <FormGroup sx={{m:3}}>
                <FormLabel component="legend">
                    {defaultTexts.bootableStatusFormFieldLabel}
                </FormLabel>
                <FormControlLabel
                    control={
                        <Switch 
                            checked={selectedBootableStatus} 
                            onChange={handleBootableStatusChange} 
                            name="bootableStatus" 
                        />
                    }
                    label={
                        selectedBootableStatus ? 
                        defaultTexts.enableFormFieldLabel : 
                        defaultTexts.disableFormFieldLabel 
                    }
                />
            </FormGroup>
        </CustomDialog>
        <CustomDialog
            open={volumeDeleteConfirmDialogOpen}
            onClose={handleVolumeDeleteConfirmDialogClose}
            dialogTitle={{
                title: defaultTexts.volumeDeleteConfirmTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `${defaultTexts.volumeDeleteConfirmText}: [${selectedVolumes.map(v => v.name).toString()}]`, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onVolumeDelete, 
                sx: {color: 'primary.main'}}]}
        >
                <Box>
                    <CustomCheckboxField
                        currentValue={volumeDeleteCascade}
                        setCurrentValue={handleVolumeDeleteCascadeChange}
                        label={defaultTexts.withSnapshotsFormFieldLabel}
                        sx={{my: 1}}
                    />
                </Box>
                <Box>
                    <CustomCheckboxField
                        currentValue={volumeDeleteForce}
                        setCurrentValue={handleVolumeDeleteForceChange}
                        label={defaultTexts.forceVolumeDeleteFormFieldLabel}
                        sx={{my: 1}}
                    />
                </Box>
        </CustomDialog>
        <CustomDialog
            open={volumeTransferDeleteConfirmDialogOpen}
            onClose={handleVolumeTransferDeleteConfirmDialogClose}
            dialogTitle={{
                title: defaultTexts.volumeTransferDeleteConfirmTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `${defaultTexts.volumeTransferDeleteConfirmText}: [${selectedVolumeTransfers.map(v => v.name).toString()}]`, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.confirmButtonText, 
                onClick: onVolumeTransferDelete, 
                sx: {color: 'primary.main'}}]}
        />
        
        <CustomDialog
            open={volumeTransferAcceptDialogOpen}
            onClose={handleVolumeTransferAcceptDialogClose}
            dialogTitle={{
                title: defaultTexts.acceptVolumeTransferActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onVolumeTransferAccept, 
                sx: {color: 'primary.main'}}]}
        >
            <CustomTextField
                required={true}
                error={providedAuthKeyError}
                errorText={defaultTexts.requiredFormFieldError}
                currentValue={providedAuthKey}
                setCurrentValue={handleProvidedAuthKeyChange}
                label={defaultTexts.authKeyFormFieldLabel}
                size="large"
                sx={{my: 1, width: '90%'}}
            />
        </CustomDialog>
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts[error.error_title], 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts[error.error_message]}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
    </Box>
    )
};

export default VolumesWrapperV3;