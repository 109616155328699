export const languageServiceURLs = {
    getTranslatedItems: "content",
    getLangConfigs: "clients",
};

export const coreServiceURLs = {
    getSubscriptionPage: "subscribe",
    getClientInitConfig: "client-init-config",
    getClientConfig: "client-config",
    getOpenstackAlerts: "openstack-alerts"
}

export const openstackBackendURLs = {
    getIdentityKeystoneUrls: "identity-keystone-urls"
}

export const billingServiceURLs = {
    billingConfigsEP: "account-configs",
    billingServicesEP: "account-services",
    billingPricesEP: "account-prices",
    billingDepositsEP: "project-deposits",
    billingSupportedServicesEP: "supported-billing-services",
    billingSupportedResourcesEP: "supported-billing-resources",
    billingServiceBreakdownEP: "services-month-breakdown",
    billingTopNProjectsEP: "top-n-projects",
    billingIncomeSummaryEP: "income-summary",
    billingIncomeCompareEP: "income-compare",
    billingResourceUsageSummaryEP: "resource-usage-summary",
    billingResourceUsageCompareEP: "resource-usage-compare",
    billingServiceChargesEP: "service-charges",
    billingPaymentsEP: "deposit-refill",
    billingInvoicesEP: "postpaid-invoices"
}