import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../../../../_common/CustomTable';
import { subnetDataSchema, subnetsSortFields } from 
'../../../../../../_data/openstack/neutron/subnets/v2.0';
import { setNetworkSubnetsTableConfig } from 
'../../../../../../store/reducers/networkNeutronSlice';

const SubnetsTableV20 = (props) => {
    const { subnetsData, setSubnetsData } = props
    const { handleRowSelection } = props
    const {currentAction} = props
    const {setCurrentAction}  = props
    const { actionsTexts }  = props
    const { actionsList } = props
    const { sortHandler } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);
    
    const subnetsTableConfig = useSelector(
        state => state.networkNeutron.networkSubnetsTableConfig)
    const rowsPerPage = useSelector(
        state => state.networkNeutron.networkSubnetsTableConfig.filter(
        versions => versions.api_version === "v2.0")[0].rows_per_page)
    const subnetsTableHeaders = useSelector(
        state => state.networkNeutron.networkSubnetsTableConfig.filter(
            versions => versions.api_version === "v2.0")[0].columns)

    
    const identityField = subnetsTableHeaders.filter(
        item => item.is_id_field)[0].field_key
    
    
    const dispatch = useDispatch();

    const handleSubnetTableConfig = (value_list) => {
        const newTableConfig = subnetDataSchema.filter(
            n => value_list.includes(n.field_key))
        const new_data = subnetsTableConfig.map(version => {
            if (version.api_version === "v2.0") {
                let new_item = {...version}
                new_item.columns = [...newTableConfig]
                return new_item
            } else {
                return version
            }
        })
        dispatch(setNetworkSubnetsTableConfig({
            networkSubnetsTableConfig: new_data
        }))
    }
    
    const handleRowsPerPageChange = (rows_num) => {
        const new_data = subnetsTableConfig.map(version => {
            if (version.api_version === "v2.0") {
                let new_item = {...version}
                new_item.rows_per_page = rows_num
                return new_item
            } else {
                return version
            }
        })
        dispatch(setNetworkSubnetsTableConfig({
            networkSubnetsTableConfig: new_data
        }))
    }

    return (
        <React.Fragment>
            <CustomTable
                withPagination={true}
                withCheckbox={true}
                withActions={true}
                withSorting={true}
                defaultDataTexts={{...defaultTexts}} 
                tableHeaders={subnetsTableHeaders}
                tableHeadersConfigHandler={handleSubnetTableConfig}
                identityField={identityField}
                columns={[...subnetDataSchema]}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                dataRows={subnetsData}
                setDataRows={setSubnetsData}
                handleRowSelection={handleRowSelection}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={actionsTexts}
                actionsList={actionsList}
                sortFields={subnetsSortFields}
                sortHandler={sortHandler}
                selfSorting={true}
            />
        </React.Fragment>
    )
};

export default SubnetsTableV20;