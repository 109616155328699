import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import request from '../../../_network/request';
import { getXAuthToken } from '../../../_network/openstack_request';
import Constants from '../../../config/constants';
import { getAssignedRoles } from '../../identity_keystone/helpers/rolesHelpers'
import IconButton from '@mui/material/IconButton';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import DomainIcon from '@mui/icons-material/Domain';
import BadgeIcon from '@mui/icons-material/Badge';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import BigButton from '../../_common/BigButton';
import CustomDialog from '../../_common/CustomDialog';
import CustomText from '../../_common/CustomText';
import CustomBackdrop from '../../_common/CustomBackdrop';
import WrapperBox from '../../_common/WrapperBox';
import CustomTextField from '../../_common/_form_fields/CustomTextField';
import { coreServiceURLs, languageServiceURLs } from '../../../_network/apiUrls';
import { 
    setSupportedRegions,
    setPurchasedServices, 
    setPurchasedServicesAll
} from '../../../store/reducers/openStackSlice'
import { setRegionName, setSupportedLangs } from '../../../store/reducers/settingsSlice'
import { 
    setClientAccountID,
    setUserCredentials, 
    setUserIsAuthenticated,
    setAdminProjects,
    setDefaultAdminProject,
    setSessionDatetime 
} from '../../../store/reducers/profileSlice';

import { 
    GoogleReCapcha, 
    googleCapchaValidation
} from '../../_common/GoogleReCapcha';

const LoginForm = (props) => {
    const {client_id} = props
    const clientID = useSelector(state => state.profile.clientAccountID)
    const regionName = useSelector(state => state.settings.regionName)
    const clientAccountID = clientID ? clientID : client_id
    const textItems = useSelector(state => state.texts.langTexts)
    const [fieldUsername, setFieldUsername] = useState("");
    const [fieldClientAccountId, setFieldClientAccountId] = useState("");
    const [fieldUsernameError, setFieldUsernameError] = useState(false);
    const [fieldClientAccountIdError, setFieldClientAccountIdError] = useState(false);
    const [fieldPasswordError, setFieldPasswordError] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [fieldPassword, setFieldPassword] = useState("");
    const [fieldDomain, setFieldDomain] = useState(Constants.defaultDomain);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [unauthorizedDialogVisible, setUnauthorizedDialogVisible] = useState(false);
    const captchaRef = useRef("");
    const [capchaValidationError, setCapchaValidationError] = useState(false);
    const [captchaValidated, setCaptchaValidated] = useState(false)

    const theme = useTheme();
    const dispatch = useDispatch();

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };
    
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const passwordInputHandle = (event) => {
        setFieldPasswordError(false)
        setFieldPassword(event.target.value);
    };
    
    const usernameInputHandle = (event) => {
        setFieldUsernameError(false)
        setFieldUsername(event.target.value);
    };

    const domainInputHandle = (event) => {
        setFieldDomain(event.target.value)
    }

    const clientAccountIdInputHandle = (event) => {
        setFieldClientAccountIdError(false)
        setFieldClientAccountId(event.target.value);
    };

    const handleBackdropClose = () => {
        setBackdropOpen(false);
    };

    const handleBackdropToggle = () => {
        setBackdropOpen(!backdropOpen);
    };

    const handleUnauthorizedDialogClose = () => {
        setUnauthorizedDialogVisible(false)
    }

    const loginHandler = async (username,password,client_account_id,domain) => {
        let identity_service = null
        let client_account_config = null
        try {
            const url = `${Constants.core_service_domain}/${coreServiceURLs.getClientConfig}/?client_account_id=${client_account_id}&subscription_package_group=${Constants.subscription_package_group}`
            let request_data = {
                url: url,
                method: "GET"
            }
            const res = await request(request_data)
            if (res.status_code < 400) {
                client_account_config = res.data
                identity_service = res.data.filter(item => item.config_params.is_identity_provider)[0]
            } else {
                setUnauthorizedDialogVisible(true)
                return false
            }
        } catch {
            setUnauthorizedDialogVisible(true)
            return false
        }
        try {
            let lang_url = `${Constants.language_service_domain}/${languageServiceURLs.getLangConfigs}/${Constants.client_credential_id}`

            let lang_request_data = {
                url: lang_url,
                method: "GET"
            }
            const result = await request(lang_request_data)
            if (result.status_code < 400 && result.data) {
                dispatch(setSupportedLangs({supportedLangs: result.data.supported_languages}))
            }
        } catch {
            console.log("Using default languages")
        }
        try {
            const token_response = await getXAuthToken(username,password,identity_service,domain)
            if (token_response && token_response.x_auth_token) {
                const response = await getAssignedRoles(token_response.user_id,identity_service)
                let contains_role = false
                if (response && response.status_code < 400) {
                    const role_assignments = response.data.role_assignments.map(item => {
                        return item.role.name
                    })
                    contains_role = role_assignments.some(element => {
                        return identity_service.config_params.allowed_roles.includes(element);
                    });
                }
                if (contains_role) {
                    const admin_project_list = response.data.role_assignments.filter(item => 
                        item.scope.project &&
                        item.role.name === Constants.admin_role_name
                    )
                    const admin_projects = admin_project_list.map(item => {
                        return {id: item.scope.project.id, name: item.scope.project.name}
                    })
                    
                    const nonBackendServices = client_account_config.filter(s => s.service_type !== "backend")
                    let backendServices = client_account_config.filter(s => s.service_type === "backend")
                    const non_identity_services = client_account_config.filter(s => s.service_type === "backend" && !s.config_params.is_identity_provider)
                    const supported_regions = [...new Set(non_identity_services.map(s => s.config_params.region_name))]
                    dispatch(setSupportedRegions({supportedRegions: supported_regions}))
                    
                    if (regionName) {
                        backendServices = backendServices.filter(
                            s => s.config_params.region_name === regionName
                        )
                        backendServices = [identity_service, ...backendServices]
                    } else if (identity_service.config_params.default_region !== null) {
                        backendServices = backendServices.filter(
                            s => s.config_params.region_name === identity_service.config_params.default_region
                        )
                        backendServices = [identity_service, ...backendServices]
                        dispatch(setRegionName({regionName: identity_service.config_params.default_region}))
                    }
                    const purchasedRegionServices = [...backendServices, ...nonBackendServices]
                    dispatch(setClientAccountID({clientAccountID: client_account_id}))
                    dispatch(setPurchasedServices({purchasedServices: purchasedRegionServices}))
                    dispatch(setPurchasedServicesAll({purchasedServicesAll: client_account_config}))
                    dispatch(setUserCredentials({
                        username: username, 
                        password: password
                    }))
                    dispatch(setUserIsAuthenticated({
                        isAuthenticated: true
                    }))
                    dispatch(setSessionDatetime({
                        sessionDatetime: new Date().toISOString()
                    }))
                    dispatch(setAdminProjects({adminProjects: admin_projects}))
                    if (admin_projects.length > 0) {
                        dispatch(setDefaultAdminProject({defaultAdminProject: admin_projects[0]}))
                    }
                    setFieldUsername("")
                    setFieldPassword("")
                } else {
                    setUnauthorizedDialogVisible(true)
                }
            } else {
                setUnauthorizedDialogVisible(true)
            }
        } catch (err) {
            setUnauthorizedDialogVisible(true)
        }
    };

    const submitLoginForm = async () => {
        handleBackdropToggle();
        let formIsValid = true
        let client_account_id = null

        if (!clientAccountID) {
            if (fieldClientAccountId.length > 0) {
                formIsValid = formIsValid && true
                client_account_id = fieldClientAccountId
            } else {
                setFieldClientAccountIdError(true)
                formIsValid = false
            }
        } else {
            client_account_id = clientAccountID
        }

        if (fieldUsername.length > 0) {
            formIsValid = formIsValid && true
        } else {
            setFieldUsernameError(true)
            formIsValid = false
        }

        if (fieldPassword.length > 0) {
            formIsValid = formIsValid && true
        } else {
            formIsValid = false
            setFieldPasswordError(true)
        }

        const domain_name = fieldDomain.length > 0 ? fieldDomain : Constants.defaultDomain

        if (formIsValid) {
            const reCapchaResponse = captchaValidated ? true : await googleCapchaValidation(captchaRef.current.getValue())
            setCaptchaValidated(reCapchaResponse ? true : false)
            if (reCapchaResponse) {
                await loginHandler(fieldUsername, fieldPassword, client_account_id, domain_name)
            } else {
                formIsValid = false
                setCapchaValidationError(true)
            }
        }
        handleBackdropClose();
    }

    return (
        <WrapperBox>
            <WrapperBox>
            <CustomText size="h4" sx={{color: theme.palette.text.primary}}>
                {textItems.welcomeTitle}
            </CustomText>
            {!clientAccountID && <CustomTextField 
                required={true}
                size="medium"
                error={fieldClientAccountIdError}
                errorText={textItems.missingClientAccountIdError}
                variant="outlined"
                label={textItems.clientAccountIdLabel}
                currentValue={fieldClientAccountId} 
                setCurrentValue={clientAccountIdInputHandle}
                StartIcon={<BadgeIcon />}
                sx={{ 
                    m: 1, 
                    width: {
                        xs: 300, 
                        md: 500}, 
                    mt: 5
                }}
            />}
            <CustomTextField 
                required={false}
                size="medium"
                variant="outlined"
                label={textItems.domainLabel}
                currentValue={fieldDomain}
                setCurrentValue={domainInputHandle}
                StartIcon={<DomainIcon />}
                sx={{ 
                    m: 1, 
                    width: {
                        xs: 300, 
                        md: 500}, 
                    mt: 5
                }}
            />
            <CustomTextField 
                required={true}
                size="medium"
                error={fieldUsernameError}
                errorText={textItems.missingUsernameError}
                variant="outlined"
                label={textItems.usernameLabel}
                currentValue={fieldUsername}
                setCurrentValue={usernameInputHandle}
                StartIcon={<AccountCircleIcon />}
                sx={{ 
                    m: 1, 
                    width: {
                        xs: 300, 
                        md: 500}, 
                    mt: 5
                }}
            />
            <CustomTextField 
                required={true}
                size="medium"
                error={fieldPasswordError}
                errorText={textItems.missingPasswordError}
                variant="outlined"
                label={textItems.passwordLabel}
                currentValue={fieldPassword}
                setCurrentValue={passwordInputHandle}
                type={showPassword ? 'text' : 'password'}
                StartIcon={<LockIcon />}
                EndIcon={
                    <IconButton
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                    >
                    {showPassword ? 
                        <VisibilityOff /> : 
                        <Visibility />}
                    </IconButton>
                }
                sx={{ 
                    m: 1, 
                    width: {
                        xs: 300, 
                        md: 500}, 
                    mt: 5
                }}
            />
            <GoogleReCapcha 
                ref={captchaRef}
                error={capchaValidationError}
            />
            <BigButton 
                btnTitleText={textItems.loginButtonTitle}
                onClickHandler={submitLoginForm}
            />
            <CustomBackdrop open={backdropOpen} />
            <CustomDialog
                open={unauthorizedDialogVisible}
                onClose={handleUnauthorizedDialogClose}
                dialogTitle={{
                    title: textItems.unauthorizedDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: textItems.unauthorizedDialogBody, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: textItems.auauthorizedDialogButtonTitle, 
                    onClick: handleUnauthorizedDialogClose, 
                    sx: {color: 'primary.main'}}]}
            />
            </WrapperBox>
        </WrapperBox>
    )
}

export default LoginForm;