import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../../../_common/CustomTable';
import { userDataSchema, userOptionsForm } from 
'../../../../../_data/openstack/identity/users/v3';
import { setIdentityUsersTableConfig } from 
'../../../../../store/reducers/identityKeystoneSlice';
import { usersSortFields } from 
'../../../../../_data/openstack/identity/users/v3';


const IdentityUsersTableV3 = (props) => {
    const { usersData, setUsersData } = props
    const { handleRowSelection } = props
    const {currentAction} = props
    const {setCurrentAction}  = props
    const { actionsTexts }  = props
    const { actionsList } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);
    
    const usersTableConfig = useSelector(
        state => state.identityKeystone.identityUsersTableConfig)
    const rowsPerPage = useSelector(
        state => state.identityKeystone.identityUsersTableConfig.filter(
        versions => versions.api_version === "v3")[0].rows_per_page)
    const usersTableColumns = useSelector(
        state => state.identityKeystone.identityUsersTableConfig.filter(
            versions => versions.api_version === "v3")[0].columns)
    
    const identityField = usersTableColumns.filter(
        item => item.is_id_field)[0].field_key
    
    const [customUserSchemaFields, setCustomUserSchemaFields] = useState([]);

    const dispatch = useDispatch();

    const handleUserTableConfig = (value_list) => {
        let newTableConfig = userDataSchema.filter(
            n => value_list.includes(n.field_key))
        const customNewTableConfig = customUserSchemaFields.filter(
            n => value_list.includes(n.field_key))
        newTableConfig = [...newTableConfig, ...customNewTableConfig]
        const new_data = usersTableConfig.map(version => {
            if (version.api_version === "v3") {
                let new_item = {...version}
                new_item.columns = [...newTableConfig]
                return new_item
            } else {
                return version
            }
        })
        dispatch(setIdentityUsersTableConfig({
            identityUsersTableConfig: new_data
        }))
    }
    
    const handleRowsPerPageChange = (rows_num) => {
        const new_data = usersTableConfig.map(version => {
            if (version.api_version === "v3") {
                let new_item = {...version}
                new_item.rows_per_page = rows_num
                return new_item
            } else {
                return version
            }
        })
        dispatch(setIdentityUsersTableConfig({
            identityUsersTableConfig: new_data
        }))
    }

    useEffect(() => {
        let userSchemaFields = userDataSchema.map(item => item.field_key)
        let uniqueSchemaFields = []
        let customSchemaFields = []
        for (let u in usersData) {
            const customFields = Object.keys(usersData[u]).filter(f => userSchemaFields.indexOf(f) === -1 && f !== "links")
            uniqueSchemaFields = [...new Set([...uniqueSchemaFields,...customFields])]
        }
        if (uniqueSchemaFields.length > 0) {
            for (let c in uniqueSchemaFields) {
                let newCustomField = {}
                newCustomField["field_key"] = uniqueSchemaFields[c]
                newCustomField["is_id_field"] = false
                newCustomField["field_type"] = "string"
                newCustomField["label"] = uniqueSchemaFields[c]
                customSchemaFields.push(newCustomField)
            }
            
        }
        
        setCustomUserSchemaFields(customSchemaFields)
    },[usersData]);

    return (
        <React.Fragment>
            <CustomTable
                withPagination={true}
                withCheckbox={true}
                withActions={true}
                withSorting={true}
                defaultDataTexts={{...defaultTexts}} 
                tableHeaders={usersTableColumns}
                tableHeadersConfigHandler={handleUserTableConfig}
                identityField={identityField}
                columns={[...userDataSchema,...customUserSchemaFields]}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                dataRows={usersData}
                setDataRows={setUsersData}
                handleRowSelection={handleRowSelection}
                jsonFields={userOptionsForm}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={actionsTexts}
                actionsList={actionsList}
                sortFields={usersSortFields}
            />
        </React.Fragment>
    )
};

export default IdentityUsersTableV3;