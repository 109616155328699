import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import WrapperBox from './WrapperBox';

const CustomLoading = (props) =>  {
    const { sx } = props
  return (
    <WrapperBox sx={{height: 200, ...sx}}>
      <CircularProgress />
    </WrapperBox>
  );
};

export default CustomLoading;