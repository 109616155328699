import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { setDefaultAdminProject } from '../../store/reducers/profileSlice';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Tooltip from '@mui/material/Tooltip';

const AdminProjectSelector = (props) => {
    const defaultAdminProject = useSelector((state) => state.profile.defaultAdminProject);
    const adminProjects = useSelector((state) => state.profile.adminProjects);
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [anchorElProject, setAnchorElProject] = useState(null);

    const handleOpenProjectMenu = (event) => {
        setAnchorElProject(event.currentTarget);
    };
    
    const handleCloseProjectsMenu = () => {
        setAnchorElProject(null);
    };

    const dispatch = useDispatch();
    
    const handleProjectChange = (value) => {
        dispatch(setDefaultAdminProject({defaultAdminProject: value}))
        handleCloseProjectsMenu();
    };

    return (
        <Box sx={{display: 'inline-flex'}}>
            <Tooltip title={defaultTexts.adminProjectSelectorTooltipText}>
                <Button 
                    onClick={handleOpenProjectMenu} 
                    sx={{
                        p: 1,
                        m: 1,
                        color: 'white',
                        
                        display: 'flex',
                        flexDirection: "row",
                        justifyContent: "space-around",
                        alignItems: "center"
                    }}
                >
                    <Typography textAlign="center" >
                        {adminProjects.filter(item => item.id === defaultAdminProject.id)[0].name}
                    </Typography>
                    <ArrowDropDownIcon />
                </Button>
            </Tooltip>
            <Menu
                sx={{ mt: '48px' }}
                anchorEl={anchorElProject}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElProject)}
                onClose={handleCloseProjectsMenu}
            >
                {adminProjects.map((item) => {
                    return (
                        <MenuItem 
                            onClick={() => {handleProjectChange(item)}} 
                            key={item.id} 
                            value={item.id}
                        >
                            {item.name.toUpperCase()}
                        </MenuItem>
                    )
                })}
            </Menu>
        </Box>
    )
}

export default AdminProjectSelector;
