export const snapshotDataForm = [
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
    },
    {
        field_key: "group_id",
        field_type: "select",
        required: true,
        label: "volumeGroupFormFieldLabel",
        error_label: "requiredFormFieldError",
        self_item_titles: true,
        default_empty: true
    }
]

export const snapshotSortFields = [
    {
        field_key: "name",
        label: "nameFormFieldLabel"
    }
]

export const snapshotsFilterMenu = [
    {
        keyword: "nameFormFieldLabel",
        value: "name",
        type: "text"
    },
    {
        keyword: "projectFormFieldLabel",
        value: "project_id",
        type: "select", 
        multiple: false,
        self_item_titles: true,
        items: []
    },
    {
        keyword: "volumeGroupFormFieldLabel",
        value: "group_id",
        type: "select", 
        multiple: false,
        self_item_titles: true,
        items: []
    }
]

export const groupSnapshotDataSchema = [
    {
        field_key: "project_id",
        is_id_field: false,
        field_type: "string", 
        label: "projectFormFieldLabel"
    },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "status",
        is_id_field: false,
        field_type: "string", 
        label: "statusFormFieldLabel"
    },
    {
        field_key: "group_id",
        is_id_field: false,
        field_type: "string", 
        label: "volumeGroupFormFieldLabel"
    },
    {
        field_key: "group_type_id",
        is_id_field: false,
        field_type: "string", 
        label: "volumeGroupTypeFormFieldLabel"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "created_at",
        is_id_field: false,
        field_type: "datetime", 
        label: "createdFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]