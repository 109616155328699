export const groupDataSchema = [
    {
        field_key: "name",
        is_id_field: true,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "domain_id",
        is_id_field: false,
        field_type: "string", 
        label: "domainFormFieldLabel"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: false,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const groupDataForm = [
    {
        field_key: "name",
        field_type: "string", 
        required: true, 
        label: "nameFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string", 
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true,
        on_empty_null: false
    },
    {
        field_key: "domain_id",
        field_type: "select", 
        required: true,
        label: "domainFormFieldLabel",
        on_empty_null: false,
        self_item_titles: true,
        default_empty: true,
        error_label: "requiredFormFieldError"
    }
]


export const groupDataUpdateForm = [
    {
        field_key: "name",
        field_type: "string", 
        required: false, 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "description",
        field_type: "string", 
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    }
]


export const groupsfilterMenu = [
    {
        keyword: "filterName", 
        value: "name", 
        type: "text"
    },
    {
        keyword: "filterDomain", 
        value: "domain_id", 
        type: "select",
        items: [],
        self_item_titles: true,
        default_empty: true
    }
]