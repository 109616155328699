import React, { useState } from 'react';
import Box from '@mui/material/Box';
import SecurityGroupsWrapperV20 from './security_groups/securityGroupsWrapperV2.0';
import AddressGroupsWrapperV20 from './address_groups/addressGroupsWrapperV2.0';
import DefaultRulesWrapperV20 from './default_rules/defaultRulesWrapperV2.0';


const L3NetworkingWrapperV20 = (props) => {
    const { navigate, location, changeMenuActiveTab } = props
    const [currentTab, setCurrentTab] = useState("security_groups")


    return (
        <Box>
            {currentTab === "security_groups" && 
                <SecurityGroupsWrapperV20 
                    navigate={navigate}
                    location={location}
                    changeMenuActiveTab={changeMenuActiveTab}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                />
            }
            {currentTab === "address_groups" && 
                <AddressGroupsWrapperV20
                    navigate={navigate}
                    location={location}
                    changeMenuActiveTab={changeMenuActiveTab}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                />
            }
            {currentTab === "default_rules" &&
                <DefaultRulesWrapperV20
                    navigate={navigate}
                    location={location}
                    changeMenuActiveTab={changeMenuActiveTab}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                />
            }
        </Box>
    )
};

export default L3NetworkingWrapperV20;