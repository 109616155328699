import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useSelector } from 'react-redux';
import ImagesSubheaderV2 from 
'./imagesSubheaderV2';
import Box from '@mui/material/Box';
import WrapperBox from '../../../../_common/WrapperBox';
//import CustomText from '../../../../_common/CustomText';
import ImagesTableV2 from './imagesTableV2';
import { imagesGlanceConstants, identityKeystonConstants } from 
'../../../../../config/openStackConstants';
import { openstackRequest, getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { imagesUrl as imagesUrlResponses } from 
'../../../../../_api_responses/openstack/glance/images/v2';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { imageFilterMenu } from 
'../../../../../_data/openstack/glance/images/v2';
import CustomDialog from '../../../../_common/CustomDialog';
import CustomSideDrawer from '../../../../_common/CustomSideDrawer';
import ImageDetailV2 from './imageDetailV2';
import useWindowDimensions from '../../../../_common/WindowDimensions';
import Dimensions from '../../../../../config/dimensions';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CircularProgress from '@mui/material/CircularProgress';

const SERVICE_NAME = openStackServices.imageService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const getWidthWeight = (width) => {
    if (width < Dimensions.tablet_mini.width) {
        return 0.9
    } else if (width < Dimensions.tablet.width) {
        return 0.8
    } else {
        return 0.8
    }
}

const ImagesWrapperV2 = (props) => {
    const { navigate, location } = props
    const imageRef = useRef();
    const [isLoading, setIsLoading] = useState(true)
    const [imagesData, setImagesData] = useState([])
    const [imagesFormattedData, setImagesFormattedData] = useState([])
    const [filterMenu, setFilterMenu ]= useState(imageFilterMenu)
    const [error, setError] = useState()
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = getWidthWeight(width)
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null)

    const [selectedFilter, setSelectedFilter ] = useState(filterMenu[0].value)
    const [selectedFilterValue, setSelectedFilterValue] = useState("");
    const [filterQueryParams, setFilterQueryParams] = useState("")
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [projectsList, setProjectsList] = useState([]);

    const [fetchDataRequired, setFetchDataRequired] = useState(true);
    const [imageFile, setImageFile] = useState(null)
    const [fileUploadRequired, setFileUploadRequired] = useState(false);
    const [imageRequiredFileUpload, setImageRequiredFileUpload] = useState(null);
    const [imageDeleteConfirmDialogOpen, setImageDeleteConfirmDialogOpen] = useState(false);
    const [selectedImages, setSelectedImages] = useState([])
    const [currentAction, setCurrentAction] = useState("");
    const [selectedImageMd,setSelectedImageMd] = useState(null)

    const imageServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const imageServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const imagesUrl = useSelector(
        state => state.imageGlance.imageGlanceApiUrls.filter(
            version => version.api_version === "v2")[0].urls.filter(
                url => url.keyword === imagesGlanceConstants.imagesUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
            service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
            service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    
    const handleDataFetch = () => {
        setFetchDataRequired(true)
    }

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleFilteredSearch = () => {
        if (selectedFilter && selectedFilterValue) {
            setFilterQueryParams(`?${selectedFilter}=${selectedFilterValue}`)
        } else {
            setFilterQueryParams("")
        }
    }

    const handleFilterReset = () => {
        setSelectedFilter(filterMenu[0].value)
        setSelectedFilterValue("")
        setFilterQueryParams("")
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleDetailCardOpen = useCallback((index) => {
        setSelectedRow(imagesData[index])
        setSelectedImage(imagesFormattedData.filter(item => 
            item.id === imagesData[index].id)[0])
        setTimeout(() => setDetailCardOpen(true),100)
    },[imagesData, imagesFormattedData]);

    const handleDetailCardClose = useCallback(() => {
        setTimeout(() => setDetailCardOpen(false),100)
        setSelectedRow(null)
        navigate(location.path,{})
    },[location,navigate]);

    const handleImageDelete = async (image) => {
        const url = `${imageServiceDomain}/${imageServiceVersion}/${imagesUrl}/${image.id}`
        const method = "DELETE"
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const image_response = await openstackRequest({
                url:url, 
                method:method, 
                token: project_token
            })
            if (image_response.status_code === imagesUrlResponses.delete.success_response.status_code) {
                return true
            } else {
                return false
            }
        }
    }

    const onImageDelete = async () => {
        handleImageDeleteConfirmDialogClose()
        handleDetailCardClose()
        setIsLoading(true)
        for (let i in selectedImages) {
            await handleImageDelete(selectedImages[i])
        }
        setIsLoading(false)
        handleDataFetch()
    }

    const onImageDeleteConfirm = (image_list) => {
        const selected_image_list = imagesData.filter(img => 
            image_list.includes(img.id))
        setSelectedImages([...selected_image_list])
        setImageDeleteConfirmDialogOpen(true)
    }

    const handleImageDeleteConfirmDialogClose = () => {
        setImageDeleteConfirmDialogOpen(false)
    }

    const getImagesActionsList = () => {
        let image_actions = []
        let new_action = {}
        new_action["value"] = "image_delete"
        new_action["action"] = onImageDeleteConfirm
        new_action["keyword"] = "imageDeleteActionTitle"
        new_action["button_text"] = "applyButtonTitleText"
        image_actions.push({...new_action})
        
        return image_actions
    }

    useEffect(() => {
        setFetchDataRequired(true)
    },[filterQueryParams]);

    useEffect(() => {
        if (fetchDataRequired) {
        (async () => {
            handleLoading(true)
            const url = `${imageServiceDomain}/${imageServiceVersion}/${imagesUrl}${filterQueryParams}`
            const method = "GET"
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const images_response = await openstackRequest({url:url, method:method, token: project_token})
                if (images_response.status_code === imagesUrlResponses.get.success_response.status_code) {
                    if (selectedRow) {
                        const not_hidden = images_response.data.images.filter(image => image.id === selectedRow.id)
                        if (not_hidden.length === 0) {
                            handleDetailCardClose()
                        }
                        setSelectedImageMd(images_response.data.images.filter(image => image.id === selectedRow.id)[0])
                    }
                    
                    setImagesData(images_response.data.images)
                } else {
                    const error_response = imagesUrlResponses.get.error_response.filter(
                        error_item => error_item.status_code === images_response.status_code)
                    if (error_response.length > 0) {
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: images_response.error
                        }
                        setError(errorObject)
                    } else {
                        const error_response = imagesUrlResponses.get.error_response.filter(
                            error_item => error_item.status_code === "unknown")
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: images_response.error
                        }
                        setError(errorObject)
                    }
                }
            }
        })();
        setFetchDataRequired(false)
        }
        setTimeout(()=>{handleLoading(false)},1000)
    },[
        imageServiceDomain, 
        imageServiceVersion, 
        imagesUrl, 
        filterQueryParams,
        fetchDataRequired,
        defaultAdminProject,
        selectedRow,
        handleDetailCardClose
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"
            const projects_response = await openstackRequest({url:url, method:method})
            
            if ( projects_response.status_code === imagesUrlResponses.get.success_response.status_code) {
                const projects_list = projects_response.data.projects.map(project => {
                    return {keyword: project.name, value: project.id, default: false}
                })
                setProjectsList(projects_list)
            }
        })()
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        let data_to_update = [...imagesData]
        if (projectsList.length > 0 && imagesData.length > 0) {
            const handleImagesDataFormatting = (data) => {
                const updated_data = data.map(item => {
                    let new_item = {...item}
                    if (projectsList.length > 0) {
                        const item_project = projectsList.filter(p => p.value === item.owner)
                        if (item_project.length > 0) {
                            new_item.owner = item_project[0].keyword
                        }
                    }
                    if (item.status === "active") {
                        new_item["status_icon"] = <TaskAltIcon color="success" />
                    } else {
                        new_item["status_icon"] = <ErrorOutlineIcon color="warning" />
                    }
                    if (item.size < 1024) {
                        new_item.size = item.size
                    } else if (item.size < 1048576) {
                        new_item.size = `${parseInt(item.size / 1024)} KB`
                    } else if (item.size < 1073741824) {
                        new_item.size = `${parseInt(item.size / (1024 * 1024))} MB`
                    } else {
                        new_item.size = `${parseInt(item.size / (1024 * 1024 * 1024))} GB`
                    }
                    new_item.status = item.status.charAt(0).toUpperCase() + item.status.slice(1)
                    new_item.visibility = item.visibility.charAt(0).toUpperCase() + item.visibility.slice(1)
                    if (Object.keys(item).includes("block_device_mapping") && item.block_device_mapping.length > 0) {
                        const mapping = JSON.parse(item.block_device_mapping)
                        new_item.type = mapping[0].source_type === "snapshot" ? 
                        mapping[0].source_type.toUpperCase() :
                        "IMAGE"
                    } else {
                        new_item.type = "IMAGE"
                    }
                    return new_item
                })
                return updated_data
            }
            data_to_update = handleImagesDataFormatting(data_to_update)
            
        }
        if (imageRequiredFileUpload) {
            data_to_update = data_to_update.map(image => {
                if (image.id === imageRequiredFileUpload.id && image.status !== "active") {
                    let new_item = {...image}
                    new_item.status_icon = <WrapperBox>
                            <CircularProgress color="info" />
                        </WrapperBox>
                    return new_item
                } else {
                    return image
                }
            })
        }
        setImagesFormattedData(data_to_update)
        if (selectedRow !== null) {
            setSelectedImage(data_to_update.filter(item => item.id === selectedRow.id)[0])
        }
    },[
        projectsList,
        imagesData,
        imageRequiredFileUpload,
        defaultTexts,
        selectedRow
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        const new_filter_menu = imageFilterMenu.map(item => {
            if (item.value === "owner") {
                item.items = [...projectsList]
            }
            return item
        })
        setFilterMenu(new_filter_menu)
    },[
        projectsList
    ]);

    const handleImageFileRemove = () => {
        setImageFile(null)
        setImageRequiredFileUpload(null)
    }

    useEffect(() => {
        if (fileUploadRequired && imageRequiredFileUpload && imageFile) {
            (async () => {
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                const file_url = `${imageServiceDomain}${imageRequiredFileUpload.file}`
                const file_method = "PUT"
                const file_headers = {}
                file_headers["Content-Type"] = "application/octet-stream"
                const fileFormData = new FormData();
                fileFormData.append('file', imageFile);

                openstackRequest({
                    url:file_url, 
                    method:file_method, 
                    headers: file_headers,
                    token: project_token,
                    form_data: fileFormData}).then(response => {
                        handleDataFetch()
                        handleImageFileRemove()
                }).catch(error => {
                    handleDataFetch()
                    handleImageFileRemove()
                })
            })();
        }
    },[
        fileUploadRequired,
        defaultAdminProject,
        imageServiceDomain,
        imageRequiredFileUpload,
        imageFile
    ]);

    useEffect(() => {
        if (!fetchDataRequired && location.state ) {
            const image_id = location.state ? location.state.image_id: null
                const image_index = imagesData.findIndex(v => v.id === image_id);
                if (image_index !== -1) {
                    setTimeout(() => handleDetailCardOpen(image_index), 600)
                }
        }
    },[
        fetchDataRequired,
        imagesData,
        handleDetailCardOpen,
        location
    ])

    useEffect(() => {setSelectedImageMd(selectedRow)},[selectedRow])

    return (
        <Box>
            <ImagesSubheaderV2 
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                selectedFilterValue={selectedFilterValue}
                setSelectedFilterValue={setSelectedFilterValue}
                filterMenu={filterMenu}
                handleFilteredSearch={handleFilteredSearch}
                handleFilterReset={handleFilterReset}
                handleDataFetch={handleDataFetch}
                imageFile={imageFile}
                setImageFile={setImageFile}
                fileUploadRequired={fileUploadRequired}
                setFileUploadRequired={setFileUploadRequired}
                imageRequiredFileUpload={imageRequiredFileUpload}
                setImageRequiredFileUpload={setImageRequiredFileUpload}
                handleImageFileRemove={handleImageFileRemove}
                ref={imageRef}
            />
            {isLoading && <CustomBackdrop open={isLoading} />}
            {!isLoading && <ImagesTableV2 
                imagesData={imagesFormattedData}
                handleRowSelection={handleDetailCardOpen}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={defaultTexts}
                actionsList={getImagesActionsList()}
            />}
            {selectedRow !== null && <CustomSideDrawer 
                open={detailCardOpen}
                widthWeight={WIDTH_WEIGHT}
                handleDrawerOpen={handleDetailCardOpen}
                handleDrawerClose={handleDetailCardClose}
            > 
			    <ImageDetailV2 
                    selectedRow={selectedRow}
                    selectedImage={selectedImage}
                    selectedImageMd={selectedImageMd}
                    widthWeight={WIDTH_WEIGHT}
                    handleDataFetch={handleDataFetch}
                    handleDrawerClose={handleDetailCardClose}
                    imagesData={imagesData}
                    setImageRequiredFileUpload={setImageRequiredFileUpload}
                    setImageFile={setImageFile}
                    setFileUploadRequired={setFileUploadRequired}
                    ref={imageRef}
                    onImageDeleteConfirm={onImageDeleteConfirm}
                />     
            </CustomSideDrawer>}
            <CustomDialog
                open={imageDeleteConfirmDialogOpen}
                onClose={handleImageDeleteConfirmDialogClose}
                dialogTitle={{
                    title: defaultTexts.imageDeleteConfirmTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `${defaultTexts.imageDeleteConfirmText}: [${selectedImages.map(s => s.name).toString()}]`, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: onImageDelete, 
                    sx: {color: 'primary.main'}}]}
            />
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </Box>
    )
};

export default ImagesWrapperV2;