import React, { useState, useEffect} from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import WrapperBox from '../../../../../_common/WrapperBox';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import CustomText from '../../../../../_common/CustomText';
import { MdSecurity } from "react-icons/md";

const detailTabCardConfig = {
    border: 0,
    borderBottom: 0.3,
    borderStyle: "dashed",
    borderBottomColor: "text.secondary",
    height: 100,
    minWidth: 150,
    alignItems: "start",
    pl: 3
}

const PortSecGroupsV20 = (props) => {
    const { secGroups, secGroupsList, handleNavigateToSecGroup } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [formattedSecGroups, setFormattedSecGroups] = useState([])

    const theme = useTheme();

    useEffect(() => {
        let updated_data = secGroups.map(gr => {
            const sec_group_data = secGroupsList.filter(s_gr => gr.id === s_gr.id)[0]
            let updated_item = {...sec_group_data}
            for (let i in updated_item.security_group_rules) {
                if (updated_item.security_group_rules[i].remote_group_id) {
                    const sec_group_name = secGroupsList.filter(
                        s => s.id === updated_item.security_group_rules[i].remote_group_id
                        )
                    updated_item.security_group_rules[i].remote_group_id = sec_group_name.length > 0 ? 
                    sec_group_name[0].name : 
                    updated_item.security_group_rules[i].remote_group_id
                }
            }
            return updated_item
        })
        setFormattedSecGroups(updated_data)
    },[
        secGroupsList,
        secGroups
    ]);

    return (
        <React.Fragment>
            <WrapperBox
            sx={detailTabCardConfig}
            >
                <Stack
                    direction="row" 
                    alignItems="center" 
                    justifyContent="center"
                    spacing={4}
                >
                <MdSecurity
                    size={28}
                /> 
                    <CustomText 
                        size="p" 
                        sx={{fontSize: {xs: 16, lg: 18}}}
                    >
                        {defaultTexts.submenuSecurityGroups}
                    </CustomText>
                    <CustomText 
                        size="h4" 
                        sx={{
                            color: theme.palette.dashboardPink
                        }}
                    >
                        {formattedSecGroups.length}
                    </CustomText>
                </Stack>
            </WrapperBox>
            <Grid container spacing={2} sx={{mt: 1, mb: 4}}>
                <Grid item xs={12} md={12}>
                    <TableContainer>
                        <Table sx={{ width: "100%" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell 
                                        align="left" 
                                        colSpan={2}
                                    >
                                        {defaultTexts.detailsSubmenuTitleText}
                                    </TableCell>
                                    <TableCell 
                                        align="center"
                                        colSpan={6}
                                    >
                                        {defaultTexts.rulesFormFieldLablel}
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="left">{defaultTexts.nameFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.descriptionFormFieldLabel}</TableCell>
                                    <TableCell align="center">{defaultTexts.directionFormFieldLabel}</TableCell>
                                    <TableCell align="center">{defaultTexts.etherTypeFormFieldLabel}</TableCell>
                                    <TableCell align="center">{defaultTexts.ipProtocolFormFieldLabel}</TableCell>
                                    <TableCell align="center">{defaultTexts.portRangeFormFieldLabel}</TableCell>
                                    <TableCell align="center">{defaultTexts.remoteIPPrefixFormFieldLabel}</TableCell>
                                    <TableCell align="center">{defaultTexts.remoteSecGroupFormFieldLabel}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            
                            {formattedSecGroups.map((row) => 
                                <React.Fragment key={row.id}>
                                    <TableRow>
                                <TableCell 
                                    component="th" 
                                    scope="row"
                                    align="left"
                                    rowSpan={row.security_group_rules.length + 1}
                                    onClick={() => handleNavigateToSecGroup(row.id,"/network-security")}
                                    sx={{cursor: "pointer",color: "primary.main"}}
                                >
                                    {row.name}
                                </TableCell>
                                <TableCell 
                                    align="left"
                                    rowSpan={row.security_group_rules.length + 1}
                                >
                                    {row.description}
                                </TableCell>
                                </TableRow> 
                                {row.security_group_rules.map(rule => 
                                    <TableRow key={rule.id}>
                                        <TableCell 
                                            align="center"
                                        >
                                            {rule.direction.toUpperCase()}
                                        </TableCell>
                                        <TableCell 
                                            align="center"
                                        >
                                            {rule.ethertype}
                                        </TableCell>
                                        <TableCell 
                                            align="center"
                                        >
                                            {rule.protocol ? rule.protocol : defaultTexts.anyKeywordText}
                                        </TableCell>
                                        <TableCell 
                                            align="center"
                                        >
                                            {rule.protocol && (rule.protocol.toLowerCase() === "icmp" || 
                                            rule.protocol === 1) ? 
                                                <Stack direction="column">
                                                    <div>{`ICMP Type ${rule.port_range_min}`}</div>
                                                    <div>{`ICMP Code ${rule.port_range_max}`}</div>
                                                </Stack>
                                                : !rule.port_range_min && !rule.port_range_max ? 
                                                    defaultTexts.anyKeywordText :
                                                    rule.port_range_min === rule.port_range_max || 
                                                    !rule.port_range_max ?
                                                    rule.port_range_min :
                                                    `${rule.port_range_min} - ${rule.port_range_max}`
                                            }
                                        </TableCell>
                                        <TableCell 
                                            align="center"
                                        >
                                            {rule.remote_ip_prefix ? rule.remote_ip_prefix : 
                                                rule.direction === "egress" ? 
                                                rule.ethertype === "IPv4" ? 
                                                "0.0.0.0/0" : "::/0" : "-"
                                            }
                                        </TableCell>
                                        <TableCell 
                                            align="center"
                                        >
                                            {rule.remote_group_id ? rule.remote_group_id : "-"}
                                        </TableCell>
                            </TableRow> 
                                )}
                            </React.Fragment>
                            )}
                            </TableBody>
                        </Table>
                    </TableContainer>
            </Grid>
        </Grid>
        </React.Fragment>
    )
};

export default PortSecGroupsV20;