import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import WrapperBox from '../../../../_common/WrapperBox';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import { GoCopy } from "react-icons/go";

const detailTabCardConfig = {
    border: 0,
    borderBottom: 0.3,
    borderStyle: "dashed",
    borderBottomColor: "text.secondary",
    height: 100,
    minWidth: 150,
    alignItems: "start",
    pl: 3
}

const VolumSnapshotsV3 = (props) => {
    const { volumeSnapshots, handleNavigateToSnapshot } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const theme = useTheme();

    return (
        <React.Fragment>
            <WrapperBox
                sx={detailTabCardConfig}
            >
                <Stack
                    direction="row" 
                    alignItems="center" 
                    justifyContent="center"
                    spacing={4}
                >
                <GoCopy
                    size={28}
                /> 
                    <CustomText 
                        size="p" 
                        sx={{fontSize: {xs: 16, lg: 18}}}
                    >
                        {defaultTexts.volumeSnapshotsTitleText}
                    </CustomText>
                    <CustomText 
                        size="h4" 
                        sx={{
                            color: theme.palette.dashboardPink
                        }}
                    >
                        {volumeSnapshots.length}
                    </CustomText>
                </Stack>
            </WrapperBox>
            <Grid container spacing={2} sx={{mt: 1, mb: 4}}>
                <Grid item xs={12} md={12}>
                    <TableContainer>
                        <Table sx={{ width: "100%" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">{defaultTexts.nameFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.sizeFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.statusFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.createdFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.updatedAtFormFieldLabel}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {volumeSnapshots.map((row) => (
                                <TableRow
                                    key={row.id}
                                    onClick={() => handleNavigateToSnapshot(row.id,"/snapshots")}
                                    hover
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell 
                                        component="th" 
                                        align="left"
                                        scope="row"
                                        sx={{color: "primary.main"}}
                                    >
                                        {row.name}
                                    </TableCell>
                                    <TableCell 
                                        align="left"
                                    >
                                        {`${row.size} GB`}
                                    </TableCell>
                                    <TableCell 
                                        component="th" 
                                        align="left"
                                        scope="row"
                                    >
                                        {row.status}
                                    </TableCell>
                                    <TableCell 
                                        align="left"
                                    >
                                        {new Date(row.created_at).toLocaleString()}
                                    </TableCell>
                                    <TableCell 
                                        align="left"
                                    >
                                        {new Date(row.updated_at).toLocaleString()}
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
            </Grid>
        </Grid>
    </React.Fragment>
    )
};

export default VolumSnapshotsV3;