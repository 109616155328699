export const floatingIPDataSchema = [
    {
        field_key: "project_id",
        is_id_field: false,
        field_type: "string", 
        label: "projectFormFieldLabel"
    },
    {
        field_key: "floating_network_id",
        is_id_field: false,
        field_type: "string", 
        label: "networkFormFieldLabel"
    },
    {
        field_key: "floating_ip_address",
        is_id_field: true,
        field_type: "string", 
        label: "ipAddressFormFieldLabel"
    },
    {
        field_key: "fixed_ip_address",
        is_id_field: false,
        field_type: "string", 
        label: "mappedLocalIPFormFieldLabel"
    },
    {
        field_key: "port_id",
        is_id_field: false,
        field_type: "string", 
        label: "attachedToFormFieldLabel"
    },
    {
        field_key: "status",
        is_id_field: false,
        field_type: "string", 
        label: "statusFormFieldLabel"
    },
    {
        field_key: "dns_domain",
        is_id_field: false,
        field_type: "string", 
        label: "dnsDomainFormFieldLabel"
    },
    {
        field_key: "dns_name",
        is_id_field: false,
        field_type: "string", 
        label: "dnsNameFormFieldLabel"
    },
    {
        field_key: "port_forwardings",
        is_id_field: false,
        field_type: "bool", 
        label: "portForwardingFormFieldLabel",
        textOnTrue: "formValueYes",
        textOnFalse: "formValueNo"
    }
]

export const floatingIPDataForm = [
    {
        field_key: "floating_network_id",
        field_type: "select",
        required: true,
        label: "poolFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "subnet_id",
        field_type: "select",
        required: true,
        label: "subnetFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "project_id",
        field_type: "select",
        required: true,
        label: "projectFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "floating_ip_address",
        field_type: "string",
        required: false,
        label: "specificAddressFormFieldLabel",
    },
    {
        field_key: "dns_domain",
        field_type: "string",
        required: false,
        label: "dnsDomainFormFieldLabel"
    },
    {
        field_key: "dns_name",
        field_type: "string",
        required: false,
        label: "dnsNameFormFieldLabel"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel"
    }
]

export const floatingIPsSortFields = [
    {
        field_key: "floating_ip_address",
        label: "ipAddressFormFieldLabel"
    },
    {
        field_key: "fixed_ip_address",
        label: "mappedLocalIPSortFieldLabel"
    },
    {
        field_key: "project_id",
        label: "projectFormFieldLabel"
    },
    {
        field_key: "floating_network_id",
        label: "poolFormFieldLabel"
    },
    {
        field_key: "status",
        label: "statusFormFieldLabel"
    }
]


export const floatingIPsFilterMenu = [
    {
        keyword: "ipAddressFormFieldLabel",
        value: "floating_ip_address",
        type: "text"
    },
    {
        keyword: "mappedLocalIPFormFieldLabel",
        value: "fixed_ip_address",
        type: "text"
    },
    {
        keyword: "projectFormFieldLabel",
        value: "project_id",
        type: "select", 
        multiple: false,
        self_item_titles: true,
        items: []
    },
    {
        keyword: "poolFormFieldLabel",
        value: "floating_network_id",
        type: "select", 
        multiple: false,
        self_item_titles: true,
        items: []
    },
    {
        keyword: "statusFormFieldLabel", 
        value: "status", 
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "activeStatusText", 
                value: "ACTIVE", 
                default: true
            },
            {
                keyword: "downStatusText", 
                value: "DOWN", 
                default: false
            },
            {
                keyword: "errorStatusText", 
                value: "ERROR", 
                default: false
            }
        ]
    }
]

export const portForwardingForm = [
    {
        field_key: "internal_ip_address",
        field_type: "string",
        required: true,
        label: "internalIPAddressFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "protocol",
        field_type: "select",
        required: true,
        label: "protocolFormFieldLabel",
        error_label: "requiredFormFieldError",
        self_item_titles: false,
        default_empty: false,
        items: [
            {
                keyword: "tcpFormFieldLabel",
                value: "tcp",
                default: true
            },
            {
                keyword: "udpFormFieldLabel",
                value: "udp",
                default: false
            },
        ]
    },
    {
        field_key: "start_internal_port",
        field_type: "integer",
        required: false,
        label: "startInternalPortFormFieldLabel"
    },
    {
        field_key: "end_internal_port",
        field_type: "integer",
        required: false,
        label: "endInternalPortFormFieldLabel"
    },
    {
        field_key: "start_external_port",
        field_type: "integer",
        required: false,
        label: "startExternalPortFormFieldLabel"
    },
    {
        field_key: "end_external_port",
        field_type: "integer",
        required: false,
        label: "endExternalPortFormFieldLabel"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel"
    }
]