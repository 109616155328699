import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import WrapperBox from '../../../../../_common/WrapperBox';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import NoDataNote from '../../../../../_common/NoDataNote';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import VirtualIPSpecsV20 from './virtualIPSpecsV2.0';
import VirtualIPAssociationsV20 from './virtualIPAssociationsV2.0';
import useWindowDimensions from 
'../../../../../_common/WindowDimensions';
import { getFormFieldComponent } from 
'../../../../../_common/_form_fields/form_helpers';
import Constants from '../../../../../../config/constants';
import { Grid }  from '@mui/material';
import { 
    openstackRequest,
    getXAuthTokenProjectScope} from 
'../../../../../../_network/openstack_request';
import { virtualIPsUrl as virtualIPUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/virtual_ips/v2.0';
import { openStackServices } from 
'../../../../../../config/openStackConstants';
import { 
    networkNeutronConstants,
    computeNovaConstants
} from '../../../../../../config/openStackConstants';
import ServiceContentHeader from 
'../../../../../_common/ServiceContentHeader';
import CustomSelectField from 
'../../../../../_common/_form_fields/CustomSelectField';
import CustomDialog from 
'../../../../../_common/CustomDialog';
import { virtualIPUpdateDataForm, virtualIPPortAssociationForm } from 
'../../../../../../_data/openstack/neutron/virtual_ips/v2.0';

const SERVICE_NAME = openStackServices.networkService
const COMPUTE_SERVICE_NAME = openStackServices.computeService
const FOOTER_HEIGHT = Constants.actions_bar_height + 20

const VirtualIPDetailV20 = (props) => {
    const [isCardLoading, setIsCardLoading] = useState(true)
    const [error, setError] = useState();
    const { selectedRow, handleDataFetch } = props
    const { widthWeight } = props
    const { handleDelete } = props
    const { projects, networks } = props
    const { handleNavigateToNetwork } = props
    const { handleNavigateToServer } = props
    const { handleNavigateToRouter } = props
    const { width } = useWindowDimensions();
    const FOOTER_WIDTH =  width - (width * widthWeight)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);

    const [virtualIPActions, setVirtualIPActions] = useState([]);
    const [currentAction, setCurrentAction] = useState("");

    const [updateVirtualIPDialogOpen, setUpdateVirtualIPDialogOpen] = useState(false)
    const [updateFormData, setUpdateFormData] = useState({})
    const [selectedPort, setSelectedPort] = useState("")
    const [projectPorts, setProjectPorts] = useState([])
    const [associateVirtualIPDialogOpen, setAssociateVirtualIPDialogOpen] = useState(false)
    const [associationFormData, setAssociationFormData] = useState({})
    const [virtualIPAssociations, setVirtualIPAssociations] = useState([]);
    const [servers, setServers] = useState([]);
    const [routers, setRouters] = useState([]);
    const [virtualIPAssociationsSelection, setVirtualIPAssociationsSelection] = useState([])
    const [disassociateVirtualIPDialogOpen, setDisassociateVirtualIPDialogOpen] = useState(false)
    const [selectedPortError, setSelectedPortError] = useState(false)
    const [associationDataOptions, setAssociationDataOptions] = useState({})
     
    const [virtualIPSubMenu, setVirtualIPSubMenu] = useState([
        {keyword: "submenuDetails", navigation: "/vip-details", is_active: true},
        {keyword: "submenuPortAssociations", navigation: "/vip-port-associations", is_active: false}
    ])

    const [currentTab, setCurrentTab] = useState("/vip-details")
    
    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const virtualIPsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.virtualIPsUrl)[0].url)
    const portsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.portsUrl)[0].url)
    const routersUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.routersUrl)[0].url)
    const computeServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === COMPUTE_SERVICE_NAME)[0].config_params.service_domain)
    const computeServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === COMPUTE_SERVICE_NAME)[0].config_params.api_version)
    const serversUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.serversUrl)[0].url)

    const common_url = `${neutronServiceDomain}/${neutronServiceVersion}/${virtualIPsUrl}/${selectedRow.id}`

    const getFormattedVirtualIPData = useCallback((data) => {
        let new_item = {...data}
        const project = projects.filter(p => p.id === data.project_id)
        if (project.length > 0) {
            new_item.project_id = project[0].name
        } else {
            new_item.project_id = "-"
        }
        const network = networks.filter(n => n.id === data.network_id)
        if (network.length > 0) {
            new_item.network_id = network[0].name
        } else {
            new_item.network_id = "-"
        }
        
        return new_item
    },[projects, networks])


    const handleVirtualIPDetailTabChange = useCallback((navigation) => {
        let newVirtualIPSubmenuData = virtualIPSubMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
            } else {
                item.is_active = false
            }
            return item
        })
        setVirtualIPSubMenu(newVirtualIPSubmenuData)
        setCurrentTab(navigation)
    },[
        virtualIPSubMenu
    ])

    const handleConfirmDeleteDialogOpen = () => {
        handleDelete([selectedRow.id])
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const getDataForm = (form,form_options,data,onDataChange) => {
        let form_data = [...form]
        return (
            <FormGroup>
                {form_data.map(field => {
                    let form_field_options = {}
                    form_field_options["items"] = field.items ? [...field.items] : []
                    form_field_options["item_titles"] = defaultTexts
                    form_field_options["self_items_titles"] = false
                    form_field_options["empty"] = false
                    return (
                        getFormFieldComponent(
                            field,
                            data,
                            onDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const getAssociateDataForm = () => {
        let form = [...virtualIPPortAssociationForm]
        if (associationFormData.port_type === "server") {
            form = form.filter(item => item.field_key !== "router_id")
        } else if (associationFormData.port_type === "router") {
            form = form.filter(item => item.field_key !== "server_id")
        } else {
            form = form.filter(item => item.field_key !== "server_id" && item.field_key !== "router_id")
        }
        if (associationFormData.fixed_port_id && associationFormData.fixed_port_id.length === 0) {
            form = form.filter(item => item.field_key !== "fixed_ip")
        } else {
            const port_data = projectPorts.filter(p => p.id === associationFormData.fixed_port_id)
            if (port_data.length > 0 && port_data.fixd_ips.length < 2) {
                form = form.filter(item => item.field_key !== "fixed_ip")
            }
        }
        return (
            <FormGroup>
                {form.map(field => {
                    let form_field_options = {...associationDataOptions[field.field_key]}
                    form_field_options = {...form_field_options, ...field}
                    form_field_options["item_titles"] = defaultTexts
                    delete form_field_options.label
                    if (field.field_key === "fixed_port_id") {
                        let port_list = []
                        let port_filter = []
                        if (associationFormData.port_type === "server") {
                            port_list = projectPorts.filter(p => p.device_id === associationFormData.server_id)
                        } else {
                            port_list = projectPorts.filter(p => p.device_id === associationFormData.router_id)
                        }
                        for (let i in port_list) {
                            const id = port_list[i].id
                            const port_items = port_list[i].fixed_ips.map(p => {
                                return {keyword: p.ip_address, value: `${p.ip_address}:${id}`, default: false}
                            })
                            port_filter = [...port_filter, ...port_items]
                        }
                        form_field_options["items"] = port_filter
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = true
                    }
                    if (field.field_key === "server_id") {
                        const server_items = servers.map(s => {
                            return {keyword: s.name, value: s.id, default: false}
                        })
                        form_field_options["items"] = server_items
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = true
                    }
                    if (field.field_key === "router_id") {
                        const router_items = routers.map(r => {
                            return {keyword: r.name, value: r.id, default: false}
                        })
                        form_field_options["items"] = router_items
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = true
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            associationFormData,
                            handleAssociationDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleAssociationDataChange = (event,field_key) => {
        setAssociationDataOptions({})
        let new_form_data = {...associationFormData}
        if (virtualIPPortAssociationForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (virtualIPPortAssociationForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
                new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setAssociationFormData(new_form_data)
    }

    const handleVirtualIPUpdateDataChange = (event,field_key) => {
        let new_form_data = {...updateFormData}
        if (virtualIPUpdateDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (virtualIPUpdateDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
                new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setUpdateFormData(new_form_data)
    }

    const handleUpdateVirtualIPDialogOpen = () => {
        setUpdateVirtualIPDialogOpen(true)
    }

    const handleUpdateVirtualIPDialogClose = () => {
        setUpdateFormData({})
        setUpdateVirtualIPDialogOpen(false)
    }

    const handleAssociateVirtualIPDialogOpen = () => {
        setAssociateVirtualIPDialogOpen(true)
    }

    const handleAssociateVirtualIPDialogClose = () => {
        setSelectedPort("")
        setAssociationFormData({})
        setAssociationDataOptions({})
        setAssociateVirtualIPDialogOpen(false)
    }

    const handleDisassociateVirtualIPDialogOpen = useCallback(() => {
        setDisassociateVirtualIPDialogOpen(true)
    },[])

    const handleDisassociateVirtualIPDialogClose = () => {
        setSelectedPort("")
        setSelectedPortError(false)
        setDisassociateVirtualIPDialogOpen(false)
    }

    const onVirtualIPUpdate = useCallback(async () => {
        let updated_data = {...updateFormData}
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const method = "PUT"
            const fip_response = await openstackRequest({
                url: common_url, 
                method: method, 
                data: {local_ip: updated_data},
                token: project_token
            })
            if (fip_response.status_code === virtualIPUrlResponses.put.success_response.status_code) {
                handleUpdateVirtualIPDialogClose()
                setCurrentAction("")
                handleDataFetch()
            } else {
                const error_response = virtualIPUrlResponses.put.error_response.filter(
                    error_item => error_item.status_code === fip_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: fip_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = virtualIPUrlResponses.put.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: fip_response.error
                    }
                    setError(errorObject)
                }
            }
        }
    },[
        common_url,
        defaultAdminProject,
        handleDataFetch,
        updateFormData
    ])

    const onVirtualIPDisassociate = async () => {
        if (selectedPort.length === 0) {
            setSelectedPortError(true)
            return false
        }
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const method = "DELETE"
            const fip_response = await openstackRequest({
                url: `${common_url}/port_associations/${selectedPort}`, 
                method:method, 
                token: project_token
            })
            if (fip_response.status_code === virtualIPUrlResponses.delete.success_response.status_code) {
                handleDisassociateVirtualIPDialogClose()
                setCurrentAction("")
                handleDataFetch()
            } else {
                const error_response = virtualIPUrlResponses.put.error_response.filter(
                    error_item => error_item.status_code === fip_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: fip_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = virtualIPUrlResponses.put.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: fip_response.error
                    }
                    setError(errorObject)
                }
            }
        }
    }

    const handleFormDataValidation = () => {
        let validation_faild = true
        let updatedDataFormOptions = {...associationDataOptions}
        for (let n in virtualIPPortAssociationForm) {
            if (virtualIPPortAssociationForm[n].required && !associationFormData[virtualIPPortAssociationForm[n].field_key]) {
                validation_faild = false
                updatedDataFormOptions[virtualIPPortAssociationForm[n].field_key] = {}
                updatedDataFormOptions[virtualIPPortAssociationForm[n].field_key]["error"] = true
                updatedDataFormOptions[virtualIPPortAssociationForm[n].field_key]["errorText"] = defaultTexts[virtualIPPortAssociationForm[n].error_label]
            }
        }

        setAssociationDataOptions(updatedDataFormOptions)
        return validation_faild
    }

    const onVirtualIPAssociate = async () => {
        const data_is_valid = handleFormDataValidation()
        if (data_is_valid) {
            let data = {}
            const ip_id_parts = associationFormData.fixed_port_id.split(":")
            data["fixed_port_id"] = ip_id_parts[1]
            data["fixed_ip"] = ip_id_parts[0]

            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const method = "POST"
                const fip_response = await openstackRequest({
                    url: `${common_url}/port_associations`, 
                    method:method, 
                    data: {port_association: data},
                    token: project_token
                })
                if (fip_response.status_code === virtualIPUrlResponses.post.success_response.status_code) {
                    handleAssociateVirtualIPDialogClose()
                    setCurrentAction("")
                    handleDataFetch()
                } else {
                    const error_response = virtualIPUrlResponses.put.error_response.filter(
                        error_item => error_item.status_code === fip_response.status_code)
                    if (error_response.length > 0) {
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: fip_response.error
                        }
                        setError(errorObject)
                    } else {
                        const error_response = virtualIPUrlResponses.put.error_response.filter(
                            error_item => error_item.status_code === "unknown")
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: fip_response.error
                        }
                        setError(errorObject)
                    }
                }
            }
        }
    }

    useEffect(() => {
        let fip_actions = []
        let new_action = {}
        new_action["value"] = "update_virtual_ip"
        new_action["action"] = handleUpdateVirtualIPDialogOpen
        new_action["keyword"] = "updateVirtualIPActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        fip_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "associate_virtual_ip"
        new_action["action"] = handleAssociateVirtualIPDialogOpen
        new_action["keyword"] = "associateVirtualIPActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        fip_actions.push({...new_action})
        if (virtualIPAssociations.length > 0) {
            new_action = {}
            new_action["value"] = "disassociate_virtual_ip"
            new_action["action"] = handleDisassociateVirtualIPDialogOpen
            new_action["keyword"] = "disassociateVirtualIPActionTitle"
            new_action["button_text"] = "selectButtonTitleText"
            fip_actions.push({...new_action})
        }
        
        setVirtualIPActions(fip_actions)
    },[
        selectedRow,
        virtualIPAssociations,
        handleDisassociateVirtualIPDialogOpen
    ])

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${neutronServiceDomain}/${neutronServiceVersion}/${portsUrl}?project_id=${selectedRow.project_id}`
                const method = "GET"
                const port_response = await openstackRequest({url:url, method:method, token: project_token})
                if (port_response.status_code === virtualIPUrlResponses.get.success_response.status_code) {
                    setProjectPorts(port_response.data.ports)
                }
            }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        portsUrl, 
        selectedRow,
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${neutronServiceDomain}/${neutronServiceVersion}/${routersUrl}?project_id=${selectedRow.project_id}`
                const method = "GET"
                const router_response = await openstackRequest({url:url, method:method, token: project_token})
                if (router_response.status_code === virtualIPUrlResponses.get.success_response.status_code) {
                    setRouters(router_response.data.routers)
                }
            }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        routersUrl, 
        selectedRow,
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                const url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/detail?all_tenants=true&project_id=${selectedRow.project_id}`
                const method = "GET"

                const servers_response = await openstackRequest({url:url, method:method, token: project_token})
                if (servers_response.status_code === virtualIPUrlResponses.get.success_response.status_code) {
                    setServers(servers_response.data.servers)
                }
            }
        })();
    },[
        computeServiceDomain, 
        computeServiceVersion, 
        serversUrl,
        defaultAdminProject,
        selectedRow
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const method = "GET"
                const response = await openstackRequest({
                    url: `${common_url}/port_associations`, 
                    method:method, 
                    token: project_token
                })
                if (response.status_code === virtualIPUrlResponses.get.success_response.status_code) {
                    setVirtualIPAssociations(response.data.port_associations)
                    const association_filter = response.data.port_associations.map(item => {
                        return {keyword: item.fixed_ip, value: item.fixed_port_id, default: false}
                    })
                    setVirtualIPAssociationsSelection(association_filter)
                }
            }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        virtualIPsUrl, 
        defaultAdminProject,
        common_url,
        selectedRow
    ]);

    useEffect(() => {
        if (Object.keys(updateFormData).length === 0) {
            let new_form_data = {}
            for (let i in virtualIPUpdateDataForm) {
                new_form_data[virtualIPUpdateDataForm[i].field_key] = selectedRow[virtualIPUpdateDataForm[i].field_key]
            }
            setUpdateFormData(new_form_data)
        }
    },[updateFormData, selectedRow])

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setTimeout(() => setIsCardLoading(false), 600)
    },[])

    return (
        <React.Fragment>
        {selectedRow !== null && 
        <WrapperBox>
            <ServiceContentHeader 
                service_menu={virtualIPSubMenu}
                service_menu_titles={defaultTexts}
                onClick={handleVirtualIPDetailTabChange}
            />
        </WrapperBox>}
        {currentTab === "/vip-details" &&
            <VirtualIPSpecsV20
            virtualIPData={getFormattedVirtualIPData(selectedRow)}
                selectedRow={selectedRow}
                handleNavigateToNetwork={handleNavigateToNetwork}
                handleNavigateToServer={handleNavigateToServer}
                handleNavigateToRouter={handleNavigateToRouter}
            />
        }
        {currentTab === "/vip-port-associations" && virtualIPAssociations.length > 0 &&
            <VirtualIPAssociationsV20
                selectedRow={selectedRow}
                ipAssociations={virtualIPAssociations}
            />
        }
        {currentTab === "/vip-port-associations" && virtualIPAssociations.length === 0 &&
            <NoDataNote text={defaultTexts.noVirtualIPPortAssociationsText} />
        }
        {!isCardLoading && <Paper sx={{ 
            position: 'fixed', 
            bottom: 0, 
            height: FOOTER_HEIGHT,
            left: FOOTER_WIDTH, 
            right: 12,
            zIndex: 3000
            }} 
            elevation={24}
            square={true}
        >
            <Grid 
                container 
                alignItems="center"  
                justifyContent="space-between"
            >
                <Grid item>
                    <CustomSelectField 
                        items={virtualIPActions} 
                        currentValue={currentAction}
                        setCurrentValue={setCurrentAction}
                        item_titles={defaultTexts}
                        label={defaultTexts.actionsDropdownLabelText}
                        empty={true}
                        size="small"
                        sx={{m: 1}}
                    />
                    {currentAction.length > 0 && 
                        <Button 
                                variant="contained"
                                color="secondary"
                                sx={{m: 1, height: '70%'}}
                                onClick={virtualIPActions.filter(
                                    action => action.value === currentAction)[0].action
                                }
                            >
                            {defaultTexts[virtualIPActions.filter(
                                action => action.value === currentAction)[0].button_text]}
                        </Button>
                    }
                </Grid>
                <Grid item>
                    {selectedRow !== null && 
                        <IconButton onClick={handleConfirmDeleteDialogOpen}>
                            <DeleteIcon 
                                color="primary"
                            />
                        </IconButton>}
                </Grid>
            </Grid>
        </Paper>} 
        <CustomDialog
            open={disassociateVirtualIPDialogOpen}
            onClose={handleDisassociateVirtualIPDialogClose}
            dialogTitle={{
                title: defaultTexts.disassociateVirtualIPActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onVirtualIPDisassociate, 
                sx: {color: 'primary.main'}}]}
        >
            <CustomSelectField
                currentValue={selectedPort}
                setCurrentValue={setSelectedPort}
                label={defaultTexts.portFormFieldLabel}
                items={virtualIPAssociationsSelection}
                self_item_titles={true}
                empty={false}
                required={true}
                errorText={defaultTexts.requiredFormFieldError}
                error={selectedPortError}
                sx={{width: "80%", m: 1}}
            />
        </CustomDialog>
        <CustomDialog
            open={updateVirtualIPDialogOpen}
            onClose={handleUpdateVirtualIPDialogClose}
            dialogTitle={{
                title: defaultTexts.updateVirtualIPActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onVirtualIPUpdate, 
                sx: {color: 'primary.main'}}]}
        >
            {getDataForm(
                virtualIPUpdateDataForm,
                {},
                updateFormData,
                handleVirtualIPUpdateDataChange
            )}
        </CustomDialog>
        <CustomDialog
            open={associateVirtualIPDialogOpen}
            onClose={handleAssociateVirtualIPDialogClose}
            dialogTitle={{
                title: defaultTexts.associateVirtualIPActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onVirtualIPAssociate, 
                sx: {color: 'primary.main'}}]}
        >
            {getAssociateDataForm()}
        </CustomDialog>
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts.failedActionErrorDialogTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts.failedActionErrorDialogMessage}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
        </React.Fragment>
    )
};

export default VirtualIPDetailV20;