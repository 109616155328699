export const backupDataForm = [
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
    },
    {
        field_key: "volume_id",
        field_type: "select",
        required: true,
        label: "volumeFormFieldLabel",
        error_label: "requiredFormFieldError",
        self_item_titles: true,
        default_empty: true
    },
    //{
    //    field_key: "incremental",
    //    field_type: "bool",
    //    required: false,
    //    label: "incrementalFormFieldLabel",
    //    default_value: false
    //}
]

export const backupSortFields = [
    {
        field_key: "name",
        label: "nameFormFieldLabel"
    },
    {
        field_key: "size",
        label: "sizeFormFieldLabel"
    }
]

export const backupsFilterMenu = [
    {
        keyword: "nameFormFieldLabel",
        value: "name",
        type: "text"
    },
    {
        keyword: "projectFormFieldLabel",
        value: "project_id",
        type: "select", 
        multiple: false,
        self_item_titles: true,
        items: []
    },
    {
        keyword: "volumeFormFieldLabel",
        value: "volume_id",
        type: "select", 
        multiple: false,
        self_item_titles: true,
        items: []
    },
    {
        keyword: "snapshotFormFieldLabel",
        value: "snapshot_id",
        type: "select", 
        multiple: false,
        self_item_titles: true,
        items: []
    }
]

export const backupDataSchema = [
    {
        field_key: "os-backup-project-attr:project_id",
        is_id_field: false,
        field_type: "string", 
        label: "projectFormFieldLabel"
    },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "size",
        is_id_field: false,
        field_type: "string", 
        label: "sizeFormFieldLabel"
    },
    {
        field_key: "status",
        is_id_field: false,
        field_type: "string", 
        label: "statusFormFieldLabel"
    },
    {
        field_key: "volume_id",
        is_id_field: false,
        field_type: "string", 
        label: "volumeFormFieldLabel"
    },
    {
        field_key: "is_incremental",
        is_id_field: false,
        field_type: "bool", 
        label: "increnemtalFormFieldLabel",
        value_on_true: "formValueYes",
        value_on_false: "formValueNo"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "availability_zone",
        is_id_field: false,
        field_type: "string", 
        label: "availabilityZoneFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const backupStatusList = [
    {
        keyword: "availableStatusFormFieldLabel",
        value: "available",
        default: false
    },
    {
        keyword: "errorStatusFormFieldLabel",
        value: "error",
        default: false
    }
]

export const backupUpdateDataForm = [
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
    }
]