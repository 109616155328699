import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import CustomText from '../../../../_common/CustomText';
import WrapperBox from '../../../../_common/WrapperBox';
import Paper from '@mui/material/Paper';
import useWindowDimensions from 
'../../../../_common/WindowDimensions';
import Constants from '../../../../../config/constants';
import { Stack } from '@mui/material';
import { openstackRequest, computeNovaRequest, getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { usersUrl as usersUrlResponses } from 
'../../../../../_api_responses/openstack/identity/users/v3';
import { projectsUrl as projectsUrlResponses } from 
'../../../../../_api_responses/openstack/identity/projects/v3';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { 
    computeNovaConstants, 
    identityKeystonConstants,
    networkNeutronConstants,
    blockStorageCinderConstants
} from 
'../../../../../config/openStackConstants';
import ServiceContentHeader from 
'../../../../_common/ServiceContentHeader';
import CustomCard from '../../../../_common/CustomCard';
import { Divider } from '@mui/material';
import ComputeServerSpecsV21 from './computeServerSpecsV2_1';
import ComputeServerVolumesV21 from './computeServerVolumesV2_1';
import ComputeServerInterfacesV21 from './computeServerInterfacesV2_1';
import ComputeServerSecGroupsV21 from './computeServerSecGroupsV2_1'
import ComputeServerMetadataV21 from './computeServerMetadataV2_1';
import ComputeServerStateActionsV21 from './computeServerStateActionsV2_1';
import ComputeServerActionsV21 from './computeServerActionsV2_1';
import Box from '@mui/material/Box';
import { flavorsUrl as flavorsUrlResponses } from 
'../../../../../_api_responses/openstack/compute/flavors/v2.1';

const SERVICE_NAME = openStackServices.computeService
const IDENTITY_SERVICE_NAME = openStackServices.identityService
const NETWORK_SERVICE_NAME = openStackServices.networkService
const VOLUMES_SERVICE_NAME = openStackServices.volumeService
const flavors_default_url_query = "?is_public=none"


const ComputeServerDetailV21 = (props) => {
    const { selectedRow, selectedServer, handleDataFetch } = props
    const { widthWeight } = props
    const { serverGroups } = props
    const { handleServerStatusChange } = props
    //const { 
    //    handleDrawerClose
    //} = props
    const { width } = useWindowDimensions();
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [serverData, setServerData] = useState({...selectedRow})
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const [footerIsLoading, setFooterIsLoading] = useState(true);
    const [users, setUsers] = useState([])
    const [projects, setProjects] = useState([]);
    
    const [attachedVolumes, setAttachedVolumes] = useState([]);
    const [attachedInterfaces, setAttachedInterfaces] = useState([]);
    const [serverSecGroups, setServerSecGroups] = useState([]);
    const [subnets, setSubnets] = useState([]);
    const [networks, setNetworks] = useState([]);
    const [secGroups, setSecGroups] = useState([]);
    const [volumes, setVolumes] = useState([]);
    const [flavors, setFlavors] = useState([]);

    const [serverSubMenu, setServerSubMenu] = useState(serverData.fault ? [
        {keyword: "submenuDetails", navigation: "/server-details", is_active: true},
        {keyword: "submenuVolumes", navigation: "/server-volumes", is_active: false},
        {keyword: "submenuInterfaces", navigation: "/server-interfaces", is_active: false},
        {keyword: "submenuSecurityGroups", navigation: "/server-security-groups", is_active: false},
        {keyword: "submenuMetadata", navigation: "/server-metadata", is_active: false},
        {keyword: "submenuUserData", navigation: "/server-user-data", is_active: false},
        {keyword: "submenuFault", navigation: "/server-fault", is_active: false}
    ] : [
        {keyword: "submenuDetails", navigation: "/server-details", is_active: true},
        {keyword: "submenuVolumes", navigation: "/server-volumes", is_active: false},
        {keyword: "submenuInterfaces", navigation: "/server-interfaces", is_active: false},
        {keyword: "submenuSecurityGroups", navigation: "/server-security-groups", is_active: false},
        {keyword: "submenuMetadata", navigation: "/server-metadata", is_active: false},
        {keyword: "submenuUserData", navigation: "/server-user-data", is_active: false}
    ])

    const [currentTab, setCurrentTab] = useState("/server-details")
    
    const computeServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const computeServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const serversUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.serversUrl)[0].url)
    const flavorsUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.flavorsUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const usersUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.usersUrl)[0].url)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    const networkServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === NETWORK_SERVICE_NAME)[0].config_params.service_domain)
    const networkServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === NETWORK_SERVICE_NAME)[0].config_params.api_version)
    const subnetsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.subnetsUrl)[0].url)
    const networksUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.networksUrl)[0].url)
    const secGroupsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.secGroupsUrl)[0].url)
    const blockStorageServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === VOLUMES_SERVICE_NAME)[0].config_params.service_domain)
    const blockStorageServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === VOLUMES_SERVICE_NAME)[0].config_params.api_version)
    const volumesUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.volumesUrl)[0].url)

    const common_url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/${selectedRow.id}`

    const getEmptyListNoteComponent = (text) => {
        return (
            <WrapperBox>
                <CustomCard 
                    overflow="hidden" 
                    cardHeight={220}
                    cardWidth={300}
                    card_sx={{
                        mt: 15, 
                        opacity: 0.5, 
                        borderRadius: 50,
                    }}
                    box_sx={{
                        display: "flex", 
                        alignItems: "center", 
                        justifyContent: "center",
                        overflow: "hidden",
                        right: 0
                    }}
                >
                    <CustomText 
                        size="h5" 
                        sx={{textAlign: "center"}}
                    >
                        {text}
                    </CustomText>
                </CustomCard>
            </WrapperBox>
        )
    }
    
    const getFormattedServerData = (data) => {
        const user = users.filter(u => u.id === data.user_id)[0]
        const project = projects.filter(p => p.id === data.tenant_id)[0]
        let formatted_data = {...data}
        formatted_data.user_id = user ? user.name : data.user_id
        formatted_data.tenant_id = project ? project.name : data.tenant_id
        return formatted_data
    }

    const handleServerDetailTabChange = useCallback((navigation) => {
        let newServerSubmenuData = serverSubMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
            } else {
                item.is_active = false
            }
            return item
        })
        setServerSubMenu(newServerSubmenuData)
        setCurrentTab(navigation)
    },[
        serverSubMenu
    ])

    useEffect(() => {
        (async () => {
            const url = common_url
            const method = "GET"
            const project_token = await getXAuthTokenProjectScope(
                defaultAdminProject
                )
            if (project_token) {
                const server_response = await computeNovaRequest({
                    url:url, 
                    method:method, 
                    token: project_token
                })
                setServerData(server_response.data.server)
            }
        })();
    },[
        common_url,
        defaultAdminProject,
        selectedRow,
        selectedServer
    ]);

    useEffect(() => {
        (async () => {
            const url = `${common_url}/os-volume_attachments`
            const method = "GET"

            const project_token = await getXAuthTokenProjectScope(
                defaultAdminProject)
            if (project_token) {
                const server_response = await openstackRequest({
                    url:url, 
                    method:method, 
                    token: project_token
                })
                setAttachedVolumes(
                    server_response.data.volumeAttachments
                )
            }
        })();
    },[
        common_url,
        defaultAdminProject,
        selectedRow,
        selectedServer
    ]);

    useEffect(() => {
        (async () => {
            const url = `${networkServiceDomain}/${networkServiceVersion}/${subnetsUrl}`
            const method = "GET"
            const project_token = await getXAuthTokenProjectScope(
                defaultAdminProject)
            if (project_token) {
                try {
                    const subnets_response = await openstackRequest({
                        url:url, 
                        method:method,
                        token: project_token
                    })
                    setSubnets(subnets_response.data.subnets)
                } catch {
                    setSubnets([])
                }
            } else {
                setSubnets([])
            }
        })();
    },[
        networkServiceDomain,
        networkServiceVersion,
        subnetsUrl,
        selectedServer,
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const url = `${networkServiceDomain}/${networkServiceVersion}/${networksUrl}`
            const method = "GET"
            const project_token = await getXAuthTokenProjectScope(
                defaultAdminProject)
            
            if (project_token) {
                try {
                    const networks_response = await openstackRequest({
                        url:url, 
                        method:method,
                        token: project_token
                    })
                    setNetworks(networks_response.data.networks)
                } catch {
                    setNetworks([])
                }
            } else {
                setNetworks([])
            }
        })();
    },[
        networkServiceDomain,
        networkServiceVersion,
        networksUrl,
        selectedServer,
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const url = `${networkServiceDomain}/${networkServiceVersion}/${secGroupsUrl}?tenant_id=${selectedServer.tenant_id}`
            const method = "GET"
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                try {
                    const sec_groups_response = await openstackRequest({
                        url:url, 
                        method:method,
                        token: project_token
                    })
                    setSecGroups(sec_groups_response.data.security_groups)
                } catch {
                    setSecGroups([])
                }
            } else {
                setSecGroups([])
            }
        })();
    },[
        networkServiceDomain,
        networkServiceVersion,
        secGroupsUrl,
        serverSecGroups,
        selectedServer,
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const url = `${blockStorageServiceDomain}/${blockStorageServiceVersion}/${defaultAdminProject}/${volumesUrl}/detail?all_tenants=true`
            const method = "GET"
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                try {
                    const volumes_response = await openstackRequest({
                        url:url, 
                        method:method,
                        token: project_token
                    })
                    const filtered_volumes = volumes_response.data.volumes.filter(
                        volume => volume[blockStorageCinderConstants.volumeProjectIdField] === selectedServer.tenant_id)
                    setVolumes(filtered_volumes)
                } catch {
                    setVolumes([])
                }
            } else {
                setVolumes([])
            }
        })();
    },[
        blockStorageServiceDomain,
        blockStorageServiceVersion,
        volumesUrl,
        selectedServer,
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            const url = `${common_url}/os-interface`
            const method = "GET"
            const project_token = await getXAuthTokenProjectScope(
                defaultAdminProject)
            if (project_token) {
                const server_response = await openstackRequest({
                    url:url, 
                    method:method, 
                    token: project_token
                })
                setAttachedInterfaces(
                    server_response.data.interfaceAttachments
                )
            }
        })();
    },[
        common_url,
        defaultAdminProject,
        selectedRow,
        selectedServer
    ]);

    useEffect(() => {
        (async () => {
            const url = `${common_url}/os-security-groups`
            const method = "GET"
            const project_token = await getXAuthTokenProjectScope(
                defaultAdminProject)
            if (project_token) {
                const server_response = await openstackRequest({
                    url:url, 
                    method:method, 
                    token: project_token
                })
                let unique = []
                let sec_groups = []
                const sec_gr_list = server_response.data.security_groups
                for (let gr in sec_gr_list) {
                    if (!unique.includes(sec_gr_list[gr].id)) {
                        unique.push(sec_gr_list[gr].id)
                        sec_groups.push(sec_gr_list[gr])
                    }
                }
                setServerSecGroups(sec_groups)
            }
        })();
    },[
        common_url,
        defaultAdminProject,
        selectedRow,
        selectedServer
    ]);

    useEffect(() => {
        if (selectedRow !== null) {
            (async () => {
                const url = `${identityServiceDomain}/${identityServiceVersion}/${usersUrl}`
                const method = "GET"

                const users_response = await openstackRequest({
                    url: url, 
                    method: method
                })
                if (users_response.status_code === usersUrlResponses.get.success_response.status_code) {
                    setUsers(users_response.data.users)
                } else {
                    setUsers([])
                    }
                }
            )();
        }
    },[ 
        selectedRow,
        identityServiceDomain,
        identityServiceVersion,
        usersUrl
    ])

    useEffect(() => {
        if (selectedRow !== null) {
            (async () => {
                const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
                const method = "GET"

                const projects_response = await openstackRequest({
                    url: url, 
                    method: method
                })
                if (projects_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                    setProjects(projects_response.data.projects)
                } else {
                    setProjects([])
                    }
                }
            )();
        }
    },[
        selectedRow,
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ])

    useEffect(() => {
        (async () => {
            const url = `${computeServiceDomain}/${computeServiceVersion}/${flavorsUrl}/detail${flavors_default_url_query}`
            const method = "GET"

            const flavors_response = await openstackRequest({url:url, method:method})
            if (flavors_response.status_code === flavorsUrlResponses.get.success_response.status_code) {
                setFlavors(flavors_response.data.flavors)
            }
        })();
    },[
        computeServiceDomain, 
        computeServiceVersion, 
        flavorsUrl
    ]);

    useEffect(() => {
        setTimeout(() => setFooterIsLoading(false), 600)
    },[]);

    return (
        <React.Fragment>
        {selectedRow !== null && 
        <WrapperBox sx={{flexDirection: "row"}}>
        
        <ComputeServerStateActionsV21 
                handleDataFetch={handleDataFetch}
                serverData={selectedServer}
                selectedRow={selectedRow}
                handleServerStatusChange={handleServerStatusChange}
            />
        <ServiceContentHeader 
                service_menu={serverSubMenu}
                service_menu_titles={defaultTexts}
                onClick={handleServerDetailTabChange}
            />
        </WrapperBox>}
        {currentTab === "/server-details" &&
            <ComputeServerSpecsV21 
                serverData={getFormattedServerData(serverData)}
                selectedRow={selectedRow}
                serverGroups={serverGroups}
            />
        }
        {currentTab === "/server-volumes" &&
            <ComputeServerVolumesV21 
                attachedVolumes={attachedVolumes} 
            />
        }
        {currentTab === "/server-interfaces" && 
            <ComputeServerInterfacesV21 
                attachedInterfaces={attachedInterfaces}
                subnets={subnets}
                networks={networks}
            />
        }
        {currentTab === "/server-security-groups" && 
            <ComputeServerSecGroupsV21 
                serverSecGroups={serverSecGroups}
                secGroups={secGroups}
            />
        }
        {currentTab === "/server-metadata" && 
            <ComputeServerMetadataV21
                serverMetadata={serverData.metadata}
                serverData={selectedServer}
                handleDataFetch={handleDataFetch}
            />
        }
        
        {currentTab === "/server-user-data" && 
            <div>
            {serverData["OS-EXT-SRV-ATTR:user_data"] ?
                atob(serverData["OS-EXT-SRV-ATTR:user_data"])
                :
                getEmptyListNoteComponent(defaultTexts.noUserDataNoteText)}
            </div>
        }
        {currentTab === "/server-fault" && 
            <Stack
                direction="column"
                spacing={1}
                sx={{m: 1, mb: 5}}
            >
                <CustomText 
                    size="p" 
                >
                    {defaultTexts.faultCodeFormFieldLabel}: {serverData.fault.code}
                </CustomText>
                <Divider />
                <CustomText 
                    size="p" 
                >
                    {defaultTexts.createdFormFieldLabel}: {
                        new Date(serverData.fault.created).toLocaleString()
                    }
                </CustomText>
                <Divider />
                <CustomText 
                    size="p" 
                >
                {defaultTexts.faultMessageFormFieldLabel}: {serverData.fault.message}
                </CustomText>
                <Divider />
                <CustomText 
                    size="p" 
                >
                    {defaultTexts.faultDetailsFormFieldLabel}:
                </CustomText>
                <Box
                    sx={{
                        border: 0.3,
                        borderRadius: 2,
                        borderStyle: "dashed",
                        borderColor: "text.secondary",
                        p: 2,
                        my: 1
                    }}
                >
                <CustomText 
                    size="p" 
                >
                    {serverData.fault.details}
                </CustomText>
                </Box>
            </Stack>
        }
            {!footerIsLoading && <Paper sx={{ 
                position: 'fixed', 
                bottom: 0, 
                height: {xs: Constants.actions_bar_height + 100, md: Constants.actions_bar_height + 30},
                left: width - (width * widthWeight), 
                right: 12,
                overflowY: "scroll"
                }} 
                elevation={24}
                square={true}
            >
                <ComputeServerActionsV21 
                    serverData={selectedServer}
                    serverSecGroups={serverSecGroups}
                    handleDataFetch={handleDataFetch}
                    secGroups={secGroups}
                    networks={networks}
                    subnets={subnets}
                    attachedInterfaces={attachedInterfaces}
                    attachedVolumes={attachedVolumes}
                    volumes={volumes}
                    flavors={flavors}
                />
            </Paper>}
        </React.Fragment>
    )
};

export default ComputeServerDetailV21;
