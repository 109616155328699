import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import WrapperBox from '../../../../_common/WrapperBox';
import AddButton from '../../../../_common/AddButton';
import Stack from '@mui/material/Stack';
import CustomText from '../../../../_common/CustomText';
import PlusButton from '../../../../_common/PlusButton';
import MinusButton from '../../../../_common/MinusButton';
import FormGroup from '@mui/material/FormGroup';
import Constants from '../../../../../config/constants';
import CustomTextField from 
'../../../../_common/_form_fields/CustomTextField';
import FilterButton from '../../../../_common/FilterButton';
import CustomDialog from '../../../../_common/CustomDialog';
import { openStackServices, blockStorageCinderConstants } from 
'../../../../../config/openStackConstants';
import { volumeTypeDataForm } from 
'../../../../../_data/openstack/cinder/volume_types/v3';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import { volumeCinderRequest, getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { volumesUrl as volumeUrlResponses } 
from '../../../../../_api_responses/openstack/cinder/volumes/v3';
import Grid from '@mui/material/Grid'

const SERVICE_NAME = openStackServices.volumeService

const VolumeTypesSubheaderV3 = (props) => {
    const { currentTab, handleTabChange } = props
    const { selectedFilter, setSelectedFilter } = props
    const { selectedFilterValue, setSelectedFilterValue } = props
    const { handleDataFetch } = props
    const { filterMenu } = props
    const { handleFilteredSearch, handleFilterReset } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [error, setError] = useState()
    const [successVolumeTypeAdd, setSuccessVolumeTypeAdd] = useState()
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [formData, setFormData] = useState({})
    const [formDataOptions, setFormDataOptions] = useState({});
    const [formExtraFields, setFormExtraFields] = useState([]);

    const theme = useTheme();

    const cinderServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const cinderServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const volumeTypesUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.volumeTypesUrl)[0].url)

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleSuccessDialogClose = () => {
        setSuccessVolumeTypeAdd(null);
        setSuccessDialogOpen(false);
    }

    const handleAddVolumeTypeFormReset = () => {
        setFormDataOptions({})
        let new_form_data = {}
        for (const n in volumeTypeDataForm) {
            if (volumeTypeDataForm[n].field_type === "string" || 
                volumeTypeDataForm[n].field_type === "select") {
                new_form_data[volumeTypeDataForm[n].field_key] = ""
            } else if (volumeTypeDataForm[n].field_type === "bool") {
                new_form_data[volumeTypeDataForm[n].field_key] = volumeTypeDataForm[n].default_value ? 
                volumeTypeDataForm[n].default_value : 
                false
            }
        }
        setFormData(new_form_data)
        setFormExtraFields([])
    }

    const handleFormDataChange = (event,field_key) => {
        setFormDataOptions({})
        let new_form_data = {...formData}
        if (volumeTypeDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (volumeTypeDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setFormData(new_form_data)
    }

    const handleFormExtraFieldsChange = (event,field_key_list) => {
        let new_extra_data = [...formExtraFields]
        if (field_key_list[1] === "key") {
            new_extra_data[field_key_list[0]].field_key = event.target.value
        } else {
            new_extra_data[field_key_list[0]].field_value = event.target.value
        }
        setFormExtraFields(new_extra_data)
    }

    const handleFormExtraFieldsRemove = (index) => {
        if (formExtraFields.length > 1) {
            let updated_data = [...formExtraFields]
            updated_data.splice(index, 1)
            setFormExtraFields(updated_data)
        } else {
            setFormExtraFields([])
        }
    }

    const handleFormExtraFieldsAddToVolumeType = (data) => {
        let updated_data = {...data}
        updated_data["extra_specs"] = {}
        for (let i in formExtraFields) {
            if (formExtraFields[i].field_key.length > 0) {
                updated_data["extra_specs"][formExtraFields[i].field_key] = formExtraFields[i].field_value
            }
        }
        return updated_data
    }

    const getDataForm = () => {
        return (
            <FormGroup>
                {volumeTypeDataForm.map(field => {
                    let form_field_options = {...formDataOptions[field.field_key]}
                    return (
                        getFormFieldComponent(
                            field,
                            formData,
                            handleFormDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleFormDataValidation = () => {
        let validation_faild = true
        let updatedDataFormOptions = {...formDataOptions}
        for (let n in volumeTypeDataForm) {
            if (volumeTypeDataForm[n].required && !formData[volumeTypeDataForm[n].field_key]) {
                validation_faild = false
                updatedDataFormOptions[volumeTypeDataForm[n].field_key] = {}
                updatedDataFormOptions[volumeTypeDataForm[n].field_key]["error"] = true
                updatedDataFormOptions[volumeTypeDataForm[n].field_key]["errorText"] = defaultTexts[volumeTypeDataForm[n].error_label]
            }
        }

        setFormDataOptions(updatedDataFormOptions)
        return validation_faild
    }

    const onAddVolumeType = async () => {
        const validateFormData = handleFormDataValidation()
        if (validateFormData) {
            const request_data = handleFormExtraFieldsAddToVolumeType(formData)
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}`
                const method = "POST"
                const volume_type_response = await volumeCinderRequest({
                    url: url, 
                    method: method, 
                    data: {volume_type: request_data},
                    token: project_token
                })
                if (volume_type_response.status_code === volumeUrlResponses.post.success_response.status_code) {
                    handleDataFetch()
                    handleAddVolumeTypeFormReset()
                    setFormDataOptions({})
                    setSuccessVolumeTypeAdd({
                        success_title: volumeUrlResponses.post.success_response.response_title, 
                        success_message: volumeUrlResponses.post.success_response.response_message
                    })
                } else {
                    const error_response = volumeUrlResponses.post.error_response.filter(
                        error_item => error_item.status_code === volume_type_response.status_code)
                    if (error_response.length > 0) {
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: volume_type_response.error
                        }
                        setError(errorObject)
                    } else {
                        const error_response = volumeUrlResponses.post.error_response.filter(
                            error_item => error_item.status_code === "unknown")
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: volume_type_response.error
                        }
                        setError(errorObject)
                    }
                }
            }
        }

        return validateFormData
    }

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessDialogOpen(true)
    },[successVolumeTypeAdd]);

    useEffect(() => {
        if (Object.keys(formData).length === 0) {
            let new_form_data = {}
            for (const n in volumeTypeDataForm) {
                if (
                    volumeTypeDataForm[n].field_type === "string" || 
                    volumeTypeDataForm[n].field_type === "select"
                    ) {
                    new_form_data[volumeTypeDataForm[n].field_key] = ""
                } else if (volumeTypeDataForm[n].field_type === "bool") {
                    new_form_data[volumeTypeDataForm[n].field_key] = volumeTypeDataForm[n].default_value ? 
                    volumeTypeDataForm[n].default_value : 
                    false
                }
            }
            setFormData(new_form_data)
        }
    },[formData]);

    return (
        <WrapperBox 
            sx={{
                flexDirection: "row", 
                justifyContent: 'space-between',
                mt: 1
            }}>
            <Grid container justifyContent='space-between' alignItems="center">
                <Grid item>
                    <AddButton 
                        getDataForm={getDataForm}               
                        onSubmit={onAddVolumeType}
                        formReset={handleAddVolumeTypeFormReset}
                        customTexts={{
                            title: defaultTexts.addVolumeTypeDialogTitle
                        }}
                    >
                    <Stack 
                        direction="row" 
                        spacing={2} 
                        alignItems="center"
                        sx={{my: 3}}
                    >
                        <CustomText size="h6">
                            {defaultTexts.extraSpecsFormFieldLabel}
                        </CustomText>
                        <PlusButton onClick={() => setFormExtraFields([
                            ...formExtraFields,
                            {field_key: "", field_value: ""}
                        ])} />
                    </Stack>
                    {formExtraFields.map((item,index) => {
                        return (
                            <Stack
                                key={index}
                                direction="row" 
                                spacing={2} 
                                alignItems="center"
                                justifyContent="space-between"
                                sx={{my: 1}}
                            >
                                <CustomTextField
                                    currentValue={item.field_key} 
                                    setCurrentValue={handleFormExtraFieldsChange}
                                    field_key={[index,"key"]}
                                    label={defaultTexts.keyFormFieldLabel}
                                />
                                <CustomTextField
                                    currentValue={item.field_value} 
                                    setCurrentValue={handleFormExtraFieldsChange}
                                    field_key={[index,"value"]}
                                    label={defaultTexts.valueFormFieldLabel}
                                />
                                <MinusButton sx={{width: 90}} onClick={
                                    () => handleFormExtraFieldsRemove(index)}
                                />
                            </Stack>
                            
                        )
                    })}
                </AddButton>
                </Grid>
                <Grid item sx={{flexGrow: 1}}>
                <Button
                    onClick={() => handleTabChange("volume_types")}
                    sx={{
                        color: currentTab === "volume_types" ? 
                        "lightBlue" : 
                        theme.palette.text.primary,
                        "&:hover": {
                            backgroundColor: "lightBlue",
                            color: "white"
                        }
                    }}
                    size="small"
                >
                    {defaultTexts.volumeTypesTabButtonText}
                </Button>
                <Button
                    onClick={() => handleTabChange("qos_specs")}
                    sx={{
                        color: currentTab === "qos_specs" ? 
                        "lightBlue" : 
                        theme.palette.text.primary,
                        "&:hover": {
                            backgroundColor: "lightBlue",
                            color: "white"
                        }
                    }}
                    size="small"
                >
                    {defaultTexts.qosSpecsTabButtonText}
                </Button>
                <Button
                    onClick={() => handleTabChange("default_vt")}
                    sx={{
                        color: currentTab === "default_vt" ? 
                        "lightBlue" : 
                        theme.palette.text.primary,
                        "&:hover": {
                            backgroundColor: "lightBlue",
                            color: "white"
                        }
                    }}
                    size="small"
                >
                    {defaultTexts.defaultVolumeTypesTabButtonText}
                </Button>
                </Grid>
                <Grid item>
                <FilterButton 
                    choice_mode={Constants.simple_filter_type}
                    currentFilter={selectedFilter}
                    setCurrentFilter={setSelectedFilter}
                    filter_menu_titles={defaultTexts}
                    filter_menu={filterMenu}
                    currentFilterValue={selectedFilterValue}
                    setCurrentFilterValue={setSelectedFilterValue}
                    onFilterSubmit={handleFilteredSearch}
                    onFilterReset={handleFilterReset}
                />
                </Grid>
                </Grid>
                {successVolumeTypeAdd && <CustomDialog
                    open={successDialogOpen}
                    onClose={handleSuccessDialogClose}
                    dialogTitle={{
                        title: defaultTexts[successVolumeTypeAdd.success_title], 
                        sx: {color: 'primary.main'}}}
                    dialogBody={{
                        text: defaultTexts[successVolumeTypeAdd.success_message], 
                        sx: {color: 'text.primary'}}}
                />}
                {error && <CustomDialog
                    open={errorDialogOpen}
                    onClose={handleErrorDialogClose}
                    dialogTitle={{
                        title: defaultTexts[error.error_title], 
                        sx: {color: 'primary.main'}}}
                    dialogBody={{
                        text: `<span>${defaultTexts[error.error_message]}</span>
                                <br>
                                <br>
                                <span>${defaultTexts.detailsErrorNoteDialogText}</span> 
                                <span style="color: orange">
                                    ${error.error_details}
                                </span>`, 
                        sx: {color: 'text.primary'}}}
                />}
        </WrapperBox>
    )
};

export default VolumeTypesSubheaderV3