import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import WrapperBox from '../../../../../_common/WrapperBox';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import FormGroup from '@mui/material/FormGroup';
import FirewallRuleSpecsV20 from './firewallRuleSpecsV2.0';
import useWindowDimensions from 
'../../../../../_common/WindowDimensions';
import { getFormFieldComponent } from 
'../../../../../_common/_form_fields/form_helpers';
import Constants from '../../../../../../config/constants';
import { Grid }  from '@mui/material';
import { 
    openstackRequest,
    getXAuthTokenProjectScope} from 
'../../../../../../_network/openstack_request';
import { firewallUrl as firewallUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/fwaas/v2.0';
import { openStackServices } from 
'../../../../../../config/openStackConstants';
import { 
    networkNeutronConstants
} from '../../../../../../config/openStackConstants';
import ServiceContentHeader from 
'../../../../../_common/ServiceContentHeader';
import CustomSelectField from 
'../../../../../_common/_form_fields/CustomSelectField';
import CustomDialog from 
'../../../../../_common/CustomDialog';
import { 
    firewallRuleDataUpdateForm
} from '../../../../../../_data/openstack/neutron/fwaas/v2.0';

const SERVICE_NAME = openStackServices.networkService
const FOOTER_HEIGHT = Constants.actions_bar_height + 20

const FirewallRuleDetailV20 = (props) => {
    const [isCardLoading, setIsCardLoading] = useState(true)
    const [error, setError] = useState();
    const { selectedRow, handleDataFetch, adminStateChange, sharedStateChange } = props
    const { widthWeight } = props
    const { handleDelete } = props
    const { projects } = props
    const { width } = useWindowDimensions();
    const FOOTER_WIDTH =  width - (width * widthWeight)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);

    const [firewallRuleActions, setFirewallRuleActions] = useState([]);
    const [currentAction, setCurrentAction] = useState("");

    const [firewallRuleUpdateData, setFirewallRuleUpdateData] = useState({})
    const [updateFirewallRuleDialogOpen, setUpdateFirewallRuleDialogOpen] = useState(false)
     
    const [firewallRuleSubMenu, setFirewallRuleSubMenu] = useState([
        {keyword: "submenuDetails", navigation: "/firewall-rule-details", is_active: true}
    ])

    const [currentTab, setCurrentTab] = useState("/firewall-rule-details")
    
    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const firewallRulesUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.firewallRulesUrl)[0].url)

    const common_url = `${neutronServiceDomain}/${neutronServiceVersion}/${firewallRulesUrl}/${selectedRow.id}`

    const getFormattedFirewallRuleData = useCallback((data) => {
        let formatted_data = {...data}
        const project = projects.filter(p => p.id === data.project_id)[0]
        formatted_data.project_id = project ? 
        project.name : 
        data.project_id
        return formatted_data
    },[projects])

    const handleFirewallRuleDetailTabChange = useCallback((navigation) => {
        let newFirewallRuleSubmenuData = firewallRuleSubMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
            } else {
                item.is_active = false
            }
            return item
        })
        setFirewallRuleSubMenu(newFirewallRuleSubmenuData)
        setCurrentTab(navigation)
    },[
        firewallRuleSubMenu
    ])

    const handleConfirmDeleteDialogOpen = () => {
        handleDelete([selectedRow.id])
    }

    const handleAdminStateChangeDialogOpen = useCallback(() => {
        adminStateChange([selectedRow.id])
    },[selectedRow,adminStateChange])

    const handleSharedStateChangeDialogOpen = useCallback(() => {
        sharedStateChange([selectedRow.id])
    },[selectedRow,sharedStateChange])

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const getDataForm = (form,form_options,data,onDataChange) => {
        let form_data = [...form]
        if (data.protocol !== "tcp" && data.protocol !== "udp") {
            form = form.filter(item => 
                item.field_key !== "source_from_port" &&
                item.field_key !== "source_to_port" &&
                item.field_key !== "destination_from_port" &&
                item.field_key !== "destination_to_port"
            )
        }
        return (
            <FormGroup>
                {form_data.map(field => {
                    let form_field_options = {}
                    if (field.field_key === "project_id") {
                        const projects_filter = projects.map(p => {
                            return {keyword: p.name, value: p.id, default: false}
                        })
                        form_field_options["items"] = [...projects_filter]
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = true
                    }
                    form_field_options["item_titles"] = defaultTexts
                    form_field_options = {...form_field_options, ...field}
                    delete form_field_options["label"]
                    return (
                        getFormFieldComponent(
                            field,
                            data,
                            onDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleFirewallRuleUpdateDataChange = (event,field_key) => {
        let new_form_data = {...firewallRuleUpdateData}
        if (firewallRuleDataUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (firewallRuleDataUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
                new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setFirewallRuleUpdateData(new_form_data)
    }

    const handleUpdateFirewallRuleDialogOpen = () => {
        setUpdateFirewallRuleDialogOpen(true)
    }

    const handleUpdateFirewallRuleDialogClose = () => {
        setUpdateFirewallRuleDialogOpen(false)
    }

    const formatData = (formData) => {
        let data = {}
        data["name"] = formData.name
        data["description"] = formData.description
        data["ip_version"] = formData.ip_version
        if (formData.protocol === "any") {
            data["protocol"] = null
        } else {
            data["protocol"] = formData.protocol
        }
        if (formData.destination_ip_address === null || formData.source_ip_address.length === 0) {
            if (formData.ip_version === "4") {
                data["source_ip_address"] = "0.0.0.0/0"
            } else {
                data["source_ip_address"] = "::/0"
            }
        } else {
            data["source_ip_address"] = formData.source_ip_address
        }
        if (formData.destination_ip_address === null || formData.destination_ip_address.length === 0) {
            if (formData.ip_version === "4") {
                data["destination_ip_address"] = "0.0.0.0/0"
            } else {
                data["destination_ip_address"] = "::/0"
            }
        } else {
            data["destination_ip_address"] = formData.destination_ip_address
        }
        if (formData.protocol === "tcp" || formData.protocol === "udp") {
            if (formData.source_from_port > 0 && formData.source_to_port > 0) {
                data["source_port"] = `${formData.source_from_port}:${formData.source_to_port}`
            } else if (formData.source_from_port > 0) {
                data["source_port"] = formData.source_from_port
            }
            if (formData.destination_from_port > 0 && formData.destination_to_port > 0) {
                data["destination_port"] = `${formData.destination_from_port}:${formData.destination_to_port}`
            } else if (formData.destination_from_port > 0) {
                data["destination_port"] = formData.destination_from_port
            }
        }
        data["action"] = formData.action
        data["shared"] = formData.shared
        data["enabled"] = formData.enabled

        return data
    }

    const onFirewallRuleUpdate = useCallback(async (event,data) => {
        let updated_data = {...firewallRuleUpdateData}
        if (data) {
            updated_data = data
        } else {
            updated_data = formatData(updated_data)
        }
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const method = "PUT"
            const firewallRule_response = await openstackRequest({
                url: common_url, 
                method: method, 
                data: {firewall_rule: updated_data},
                token: project_token
            })
            if (firewallRule_response.status_code === firewallUrlResponses.put.success_response.status_code) {
                setCurrentAction("")
                handleUpdateFirewallRuleDialogClose()
                handleDataFetch()
            } else {
                const error_response = firewallUrlResponses.put.error_response.filter(
                    error_item => error_item.status_code === firewallRule_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: firewallRule_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = firewallUrlResponses.put.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: firewallRule_response.error
                    }
                    setError(errorObject)
                }
            }
        }
    },[
        common_url,
        defaultAdminProject,
        firewallRuleUpdateData,
        handleDataFetch
    ])

    useEffect(() => {
        let firewallRule_actions = []
        let new_action = {}
        new_action["value"] = "update_firewall_rule"
        new_action["action"] = handleUpdateFirewallRuleDialogOpen
        new_action["keyword"] = "firewallRuleUpdateActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        firewallRule_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "admin_state_update"
        new_action["action"] = handleAdminStateChangeDialogOpen
        new_action["keyword"] = "enableDisableRuleActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        firewallRule_actions.push({...new_action})
        new_action = {}
        new_action["value"] = "share_state_update"
        new_action["action"] = handleSharedStateChangeDialogOpen
        new_action["keyword"] = "shareStateChangeActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        firewallRule_actions.push({...new_action})
        
        setFirewallRuleActions(firewallRule_actions)
    },[
        selectedRow,
        handleAdminStateChangeDialogOpen,
        handleSharedStateChangeDialogOpen
    ])

    useEffect(() => {
        if (Object.keys(firewallRuleUpdateData).length === 0) {
            let new_form_data = {}
            for (const n in firewallRuleDataUpdateForm) {
                new_form_data[firewallRuleDataUpdateForm[n].field_key] = selectedRow[firewallRuleDataUpdateForm[n].field_key]
                if ((firewallRuleDataUpdateForm[n].field_key === "source_from_port" || 
                    firewallRuleDataUpdateForm[n].field_key === "source_to_port") &&
                    selectedRow.source_port
                ) {
                    const s_port_parts = selectedRow.source_port.split(":")
                    new_form_data["source_from_port"] = parseInt(s_port_parts[0])
                    if (s_port_parts[1]) {
                        new_form_data["source_to_port"] = parseInt(s_port_parts[1])
                    } else {
                        new_form_data["source_to_port"] = 0
                    }
                }
                if ((firewallRuleDataUpdateForm[n].field_key === "destination_from_port" ||
                    firewallRuleDataUpdateForm[n].field_key === "destination_to_port") &&
                    selectedRow.destination_port
                ) {
                    const d_port_parts = selectedRow.destination_port.split(":")
                    new_form_data["destination_from_port"] = parseInt(d_port_parts[0])
                    if (d_port_parts[1]) {
                        new_form_data["destination_to_port"] = parseInt(d_port_parts[1])
                    } else {
                        new_form_data["destination_to_port"] = 0
                    }
                }
            }
            new_form_data.ip_version = JSON.stringify(new_form_data.ip_version)
            setFirewallRuleUpdateData(new_form_data)
        }
    },[firewallRuleUpdateData, selectedRow]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setTimeout(() => setIsCardLoading(false), 600)
    },[])

    return (
        <React.Fragment>
        {selectedRow !== null && 
        <WrapperBox>
            <ServiceContentHeader 
                service_menu={firewallRuleSubMenu}
                service_menu_titles={defaultTexts}
                onClick={handleFirewallRuleDetailTabChange}
            />
        </WrapperBox>}
        {currentTab === "/firewall-rule-details" &&
            <FirewallRuleSpecsV20
                firewallRuleData={getFormattedFirewallRuleData(selectedRow)}
            />
        }
        {!isCardLoading && <Paper sx={{ 
            position: 'fixed', 
            bottom: 0, 
            height: FOOTER_HEIGHT,
            left: FOOTER_WIDTH, 
            right: 12,
            zIndex: 3000
            }} 
            elevation={24}
            square={true}
        >
            <Grid 
                container 
                alignItems="center"  
                justifyContent="space-between"
            >
                <Grid item>
                    <CustomSelectField 
                        items={firewallRuleActions} 
                        currentValue={currentAction}
                        setCurrentValue={setCurrentAction}
                        item_titles={defaultTexts}
                        label={defaultTexts.actionsDropdownLabelText}
                        empty={true}
                        size="small"
                        sx={{m: 1}}
                    />
                    {currentAction.length > 0 && 
                        <Button 
                                variant="contained"
                                color="secondary"
                                sx={{m: 1, height: '70%'}}
                                onClick={firewallRuleActions.filter(
                                    action => action.value === currentAction)[0].action
                                }
                            >
                            {defaultTexts[firewallRuleActions.filter(
                                action => action.value === currentAction)[0].button_text]}
                        </Button>
                    }
                </Grid>
                <Grid item>
                    {selectedRow !== null && 
                        <IconButton onClick={handleConfirmDeleteDialogOpen}>
                            <DeleteIcon 
                                color="primary"
                            />
                        </IconButton>}
                </Grid>
            </Grid>
        </Paper>} 
        <CustomDialog
            open={updateFirewallRuleDialogOpen}
            onClose={handleUpdateFirewallRuleDialogClose}
            dialogTitle={{
                title: defaultTexts.updateFirewallRuleActionTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onFirewallRuleUpdate, 
                sx: {color: 'primary.main'}}]}
        >
            {getDataForm(
                firewallRuleDataUpdateForm,
                {},
                firewallRuleUpdateData,
                handleFirewallRuleUpdateDataChange
            )}
        </CustomDialog>
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts.failedActionErrorDialogTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts.failedActionErrorDialogMessage}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
        </React.Fragment>
    )
};

export default FirewallRuleDetailV20;