import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Constants from '../../../config/constants'
import billingRequest from '../../../_network/billing_request';
import { billingServiceURLs } from '../../../_network/apiUrls';
import { styled, useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import MuiStack from '@mui/material/Stack';
import CustomText from '../../_common/CustomText';
import CustomCard from '../../_common/CustomCard';
import Typography from '@mui/material/Typography';
import BillingPaymentsTable from './billingPaymentsTable';
import CustomTableSkeleton from '../../_common/CustomTableSkeleton';
import { DatePicker } from '@mui/x-date-pickers';
import { FaSearch } from "react-icons/fa";
import FilterButton from '../../_common/FilterButton';
import { openStackServices } from 
'../../../config/openStackConstants';
import { identityKeystonConstants } from 
'../../../config/openStackConstants';
import { openstackRequest } from 
'../../../_network/openstack_request';
import { projectsUrl as projectsUrlResponses } from 
'../../../_api_responses/openstack/identity/projects/v3';
import { filterMenu } from "./paymentsData";
import { Paper } from '@mui/material';

const drawerWidth = Constants.drawerWidth;
const IDENTITY_SERVICE_NAME = openStackServices.identityService
let CURRENT_DATE = new Date()
CURRENT_DATE.setDate(1)

const textStyle = {
    fontSize: {xs: 16, lg: 18},
    fontWeight: "500"
}

const CustomStack = styled(MuiStack)(({ theme }) => ({
    flexDirection: "row" ,
    alignItems: "start",
    justifyContent: "space-between",
    marginTop: "9px"
}));

const BillingPayments = (props) => {
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
    const drawerOpen = useSelector(state => state.drawer.drawerOpened);
    const [isLoading, setIsLoading] = useState(true)
    const [isTableLoading, setIsTableLoading] = useState(true)
    const [dataFetchRequired, setDataFetchRequired] = useState(true)
    const [dataListFetchRequired, setDataListFetchRequired] = useState(true)
    const [todayPayments, setTodayPayments] = useState(0)
    const [thisWeekPayments, setThisWeekPayments] = useState(0)
    const [thisMonthPayments, setThisMonthPayments] = useState(0)
    const [todayPaymentsAmount, setTodayPaymentsAmount] = useState(0)
    const [thisWeekPaymentsAmount, setThisWeekPaymentsAmount] = useState(0)
    const [thisMonthPaymentsAmount, setThisMonthPaymentsAmount] = useState(0)
    const [openstackProjects, setOpenstackProjects] = useState([])
    const [paymentsFilterMenu, setPaymentsFilterMenu] = useState([...filterMenu]);
    const [selectedFilter, setSelectedFilter ] = useState(filterMenu[0].value)
    const [selectedFilterValue, setSelectedFilterValue] = useState("");
    const [filterQueryParams, setFilterQueryParams] = useState("")
    const [paymentStartDate, setPaymentStartDate] = useState(new Date(CURRENT_DATE));
    const [paymentEndDate, setPaymentEndDate] = useState(new Date())
    const [billingPayments, setBillingPayments] = useState([]);
    const [billingPaymentsList, setBillingPaymentsList] = useState([]);
    const [currency, setCurrency] = useState("");

    const navigate = useNavigate();

    if (!isAuthenticated) {
        navigate('/');
    }
    
    const theme = useTheme();

    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleTableLoading = (mode) => {
        setIsTableLoading(mode)
    }

    const handleDataFetch = () => {
        setDataFetchRequired(true)
    }

    const handleFilteredSearch = () => {
        if (selectedFilter && selectedFilterValue) {
            setFilterQueryParams(`${selectedFilter}=${selectedFilterValue}`)
        } else {
            setFilterQueryParams("")
        }
    }

    const handleFilterReset = () => {
        setSelectedFilter(filterMenu[0].value)
        setSelectedFilterValue("")
        setFilterQueryParams("")
    }

    const CustomChips = ({text,color}) => (
        <Box sx={{
            borderRadius: 20, 
            border: `2px solid ${color}`,
            py: 0.7, px: 2}}>
            <CustomText sx={{fontSize: {xs: 16, md: 18}, color: color}}>
                {text}
            </CustomText>
        </Box>
    )

    const DashboardCard = (props) => {
        const { cardTitle } = props
        return (
            <CustomCard
                cardWidth={'100%'} 
                cardHeight={180} 
                cardTitle={cardTitle}
                titleSize="p"
                titleColor="primary.main"
                card_sx={{
                    border: theme.palette.mode === "dark" ? 0 : 1, 
                    boxShadow: theme.palette.mode === "dark" ? 0 : 1, 
                    backgroundColor: 
                        theme.palette.mode === "dark" ? 
                        undefined :
                        "vLightGray"
                }}
                box_sx={{pr: 2}}
            >
                {props.children}
            </CustomCard>
        )
    }

    useEffect(() => {
        const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingConfigsEP}`
        let request_data = {
            url: url,
            method: "GET"
        }
        billingRequest(request_data).then((response) => {
            if (response.status_code === 200) {
                setCurrency(response.data.currency.name)
            }
        }).catch((err) => {
            console.log(err)
    })
        
    },[]);

    useEffect(() => {
        if (dataListFetchRequired) {
            handleLoading(true)
            let current_date = new Date()
            const start_date_param = `${current_date.getFullYear()}-${current_date.getMonth()+1}-01 00:00:00`
            const end_date_param = `${current_date.getFullYear()}-${current_date.getMonth()+1}-${current_date.getDate()} 23:59:59`
            let query_params = `?start_time=${encodeURIComponent(start_date_param)}&end_time=${encodeURIComponent(end_date_param)}`

            const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingPaymentsEP}${query_params}`
            let request_data = {
                url: url,
                method: "GET"
            }
            billingRequest(request_data).then((response) => {
                if (response.status_code === 200) {
                    const formatted_data = response.data.map(item => {
                        let new_item = {...item}
                        new_item.time_stamp = new Date(item.time_stamp)
                        return new_item
                    })
                    setBillingPaymentsList(formatted_data)
                }
                setDataListFetchRequired(false)
                handleLoading(false)
            }).catch((err) => {
                setDataListFetchRequired(false)
                handleLoading(false)
                console.log(err)
            })
        }
    },[
        dataListFetchRequired
    ]);

    useEffect(() => {
        if (dataFetchRequired) {
            handleTableLoading(true)
            const start_date_param = `${paymentStartDate.getFullYear()}-${paymentStartDate.getMonth()+1}-${paymentStartDate.getDate()} 00:00:00`
            const end_date_param = `${paymentEndDate.getFullYear()}-${paymentEndDate.getMonth()+1}-${paymentEndDate.getDate()} 23:59:59`
            let query_params = `?start_time=${encodeURIComponent(start_date_param)}&end_time=${encodeURIComponent(end_date_param)}`

            const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingPaymentsEP}${query_params}&${filterQueryParams}`
            let request_data = {
                url: url,
                method: "GET"
            }
            billingRequest(request_data).then((response) => {
                if (response.status_code === 200) {
                    const formatted_data = response.data.map(item => {
                        let new_item = {...item}
                        new_item.time_stamp = new Date(item.time_stamp)
                        return new_item
                    })
                    setBillingPayments(formatted_data)
                }
                setDataFetchRequired(false)
                handleTableLoading(false)
            }).catch((err) => {
                setDataFetchRequired(false)
                handleTableLoading(false)
                console.log(err)
            })
        }
    },[
        dataFetchRequired,
        paymentStartDate,
        paymentEndDate,
        filterQueryParams
    ]);

    useEffect(() => {
        if (billingPaymentsList.length > 0) {
            let today_amount = 0
            let this_week_amount = 0
            let this_month_amount = 0

            const today = new Date()
            const today_payments = billingPaymentsList.filter(item => 
                item.time_stamp.getFullYear() === today.getFullYear() &&
                item.time_stamp.getMonth() === today.getMonth() &&
                item.time_stamp.getDate() === today.getDate()
            )

            const first = today.getDate() - ((today.getDay() + 6) % 7); 
            const current_date = new Date()
            const firstday_of_week = new Date(current_date.setDate(first))

            const this_week_payments = billingPaymentsList.filter(item => 
                item.time_stamp > firstday_of_week &&
                item.time_stamp <= today
            )

            for (let i in billingPaymentsList) {
                this_month_amount = this_month_amount + billingPaymentsList[i].amount
            }
            for (let i in today_payments) {
                today_amount = today_amount + today_payments[i].amount
            }
            for (let i in this_week_payments) {
                this_week_amount = this_week_amount + this_week_payments[i].amount
            }

            setTodayPayments(today_payments.length)
            setThisWeekPayments(this_week_payments.length)
            setThisMonthPayments(billingPaymentsList.length)

            setTodayPaymentsAmount(today_amount)
            setThisWeekPaymentsAmount(this_week_amount)
            setThisMonthPaymentsAmount(this_month_amount)
        }
    },[
        billingPaymentsList
    ])

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({url:url, method:method})
            if (projects_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                setOpenstackProjects(projects_response.data.projects)
            }
        })();
    },[
        identityServiceDomain, 
        identityServiceVersion, 
        projectsUrl
    ]);


    useEffect(() => {
        if (openstackProjects.length > 0) {
            const project_filter = openstackProjects.map(p => {
                return {keyword: p.name, value: p.id, default: false}
            })
            const new_filter = filterMenu.map(f => {
                let new_item = {...f}
                if (f.value === "project_id") {
                    new_item.items = project_filter
                    new_item["self_item_titles"] = true
                }
                return new_item
            })
            setPaymentsFilterMenu(new_filter)
        }
    },[openstackProjects])

    useEffect(() => {
        handleDataFetch()
    },[filterQueryParams])

    return (
        <Box sx={{mt: 2}}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4}>
                    <DashboardCard>
                        <MuiStack spacing={1}>
                            <CustomText 
                                size="h6"
                                sx={{color: theme.palette.primary.main}}
                            >
                                {defaultTexts.todayPaymentsCardTitle}
                            </CustomText>
                            <CustomStack>
                                <CustomText sx={{
                                    ...textStyle, 
                                    color: theme.palette.text.secondary
                                }}>
                                    {defaultTexts.paymentsCountCardLabel}
                                </CustomText>
                                {isLoading ? 
                                    <Skeleton width="50%">
                                        <Typography>.</Typography>
                                    </Skeleton> :
                                    <CustomChips 
                                        text={todayPayments} 
                                        color={
                                            theme.palette.mode === "dark" ? 
                                            theme.palette.dashboardGreenDark :
                                            theme.palette.dashboardGreen
                                        } 
                                    />
                                }
                            </CustomStack>
                            <CustomStack>
                                <CustomText sx={{
                                    ...textStyle, 
                                    color: theme.palette.text.secondary
                                }}>
                                    {defaultTexts.paymentsAmountCardLabel} {`(${currency.toUpperCase()})`}
                                </CustomText>
                                {isLoading ? 
                                    <Skeleton width="50%">
                                        <Typography>.</Typography>
                                    </Skeleton> :
                                    <CustomChips 
                                        text={todayPaymentsAmount.toFixed(2)} 
                                        color={
                                            theme.palette.mode === "dark" ? 
                                            theme.palette.customBlueDark :
                                            theme.palette.customBlue
                                        } 
                                    />
                                }
                            </CustomStack>
                        </MuiStack>
                    </DashboardCard>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <DashboardCard>
                        <MuiStack spacing={1}>
                            <CustomText 
                                size="h6"
                                sx={{color: theme.palette.primary.main}}
                            >
                                {defaultTexts.thisWeekPaymentsCardTitle}
                            </CustomText>
                            <CustomStack>
                                <CustomText sx={{
                                    ...textStyle, 
                                    color: theme.palette.text.secondary
                                }}>
                                    {defaultTexts.paymentsCountCardLabel}
                                </CustomText>
                                {isLoading ? 
                                    <Skeleton width="50%">
                                        <Typography>.</Typography>
                                    </Skeleton> :
                                    <CustomChips 
                                        text={thisWeekPayments} 
                                        color={
                                            theme.palette.mode === "dark" ? 
                                            theme.palette.dashboardGreenDark :
                                            theme.palette.dashboardGreen
                                        } 
                                    />
                                }
                            </CustomStack>
                            <CustomStack>
                                <CustomText sx={{
                                    ...textStyle, 
                                    color: theme.palette.text.secondary
                                }}>
                                    {defaultTexts.paymentsAmountCardLabel} {`(${currency.toUpperCase()})`}
                                </CustomText>
                                {isLoading ? 
                                    <Skeleton width="50%">
                                        <Typography>.</Typography>
                                    </Skeleton> :
                                    <CustomChips 
                                        text={thisWeekPaymentsAmount.toFixed(2)} 
                                        color={
                                            theme.palette.mode === "dark" ? 
                                            theme.palette.customBlueDark :
                                            theme.palette.customBlue
                                        } 
                                    />
                                }
                            </CustomStack>
                        </MuiStack>
                    </DashboardCard>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <DashboardCard>
                        <MuiStack spacing={1}>
                                <CustomText 
                                    size="h6"
                                    sx={{color: theme.palette.primary.main}}
                                >
                                    {defaultTexts.thisMonthPaymentsCardTitle}
                                </CustomText>
                                <CustomStack>
                                <CustomText sx={{
                                    ...textStyle, 
                                    color: theme.palette.text.secondary
                                }}>
                                    {defaultTexts.paymentsCountCardLabel}
                                </CustomText>
                                {isLoading ? 
                                    <Skeleton width="50%">
                                        <Typography>.</Typography>
                                    </Skeleton> :
                                    <CustomChips 
                                        text={thisMonthPayments} 
                                        color={
                                            theme.palette.mode === "dark" ? 
                                            theme.palette.dashboardGreenDark :
                                            theme.palette.dashboardGreen
                                        } 
                                    />
                                }
                            </CustomStack>
                            <CustomStack>
                                <CustomText sx={{
                                    ...textStyle, 
                                    color: theme.palette.text.secondary
                                }}>
                                    {defaultTexts.paymentsAmountCardLabel} {`(${currency.toUpperCase()})`}
                                </CustomText>
                                {isLoading ? 
                                    <Skeleton width="50%">
                                        <Typography>.</Typography>
                                    </Skeleton> :
                                    <CustomChips 
                                        text={thisMonthPaymentsAmount.toFixed(2)} 
                                        color={
                                            theme.palette.mode === "dark" ? 
                                            theme.palette.customBlueDark :
                                            theme.palette.customBlue
                                        } 
                                    />
                                }
                            </CustomStack>
                        </MuiStack>
                    </DashboardCard>
                </Grid>
            </Grid>
            <Box sx={{
                minHeight: 40,
                mt: 2
            }}>
                <Grid 
                    container 
                    
                    spacing={2}
                    alignItems="end"
                    justifyContent="space-between"
                    sx={{
                        maxWidth: drawerOpen ? 
                        `calc(100vw - ${drawerWidth}px)` : 
                        `calc(100vw - ${110}px)`,
                        my: {xs: 1, sm: 0}
                    }}
                >
                    <Grid item>
                        <Grid 
                            container 
                            spacing={1} 
                            alignItems="center" 
                            justifyContent="start"
                        >
                            <Grid item>
                                <DatePicker 
                                    value={paymentStartDate}
                                    onChange={(newValue) => setPaymentStartDate(newValue)}
                                    maxDate={paymentEndDate}
                                />
                            </Grid>
                            <Grid item>
                                <DatePicker 
                                    value={paymentEndDate}
                                    onChange={(newValue) => setPaymentEndDate(newValue)}
                                    minDate={paymentStartDate}
                                />
                            </Grid>
                            <Grid item>
                                <IconButton 
                                    onClick={handleDataFetch}
                                >
                                    <FaSearch />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <FilterButton
                            choice_mode={Constants.simple_filter_type}
                            currentFilter={selectedFilter}
                            setCurrentFilter={setSelectedFilter}
                            filter_menu_titles={defaultTexts}
                            filter_menu={paymentsFilterMenu}
                            currentFilterValue={selectedFilterValue}
                            setCurrentFilterValue={setSelectedFilterValue}
                            onFilterSubmit={handleFilteredSearch}
                            onFilterReset={handleFilterReset}
                        />
                    </Grid>
                </Grid>
            </Box>
            {isTableLoading ? 
                <CustomTableSkeleton sx={{mt: 1}}/> :
                <Box>
                {billingPayments.length > 0 ? <BillingPaymentsTable 
                    billingPayments={billingPayments}
                    openstackProjects={openstackProjects}
                /> : 
                <Paper
                    sx={{
                        border: 1,
                        borderColor: "customBlue",
                        borderRadius: 2,
                        maxWidth: drawerOpen ? 
                        `calc(100vw - ${drawerWidth}px)` : 
                        `calc(100vw - ${110}px)`,
                        height: "60%",
                        alignItems: 'center',
                        justifyContent: 'center',
                        p: 3,
                        mt: 3,
                        display: "flex"
                    }}
                >
                    <CustomText 
                        size="h5" 
                        sx={{color: "customBlue"}}
                    >
                        {defaultTexts.noDataToDisplayText}
                    </CustomText>
                </Paper>
                }
                </Box>
            }
        </Box>
    )

};

export default BillingPayments;