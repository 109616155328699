import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import WrapperBox from '../../../../_common/WrapperBox';
import Checkbox from '@mui/material/Checkbox';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import { FaServer } from "react-icons/fa"
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { computeNovaConstants} 
from '../../../../../config/openStackConstants';
import { 
    getXAuthTokenProjectScope, 
    computeNovaRequest
} from '../../../../../_network/openstack_request';

const COMPUTE_SERVICE_NAME = openStackServices.computeService

const detailTabCardConfig = {
    border: 0,
    borderBottom: 0.3,
    borderStyle: "dashed",
    borderBottomColor: "text.secondary",
    height: 100,
    width: "100%",
    alignItems: "start",
    pl: 3
}

const VolumeAttachmentsV3 = (props) => {
    const { attachedServers, handleNavigateToServer } = props
    const { selectedRow, handleDataFetch } = props
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [selectedInstancesToDelete, setSelectedInstancesToDelete] = useState([])
    const theme = useTheme();

    const computeServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === COMPUTE_SERVICE_NAME)[0].config_params.service_domain)
    const computeServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === COMPUTE_SERVICE_NAME)[0].config_params.api_version)
    const serversUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.serversUrl)[0].url)

    const handleSelectedInstanceToDeleteChange = (event,row_id) => {
        if (event.target.checked) {
            const updated_list = [...selectedInstancesToDelete, row_id]
            setSelectedInstancesToDelete(updated_list)
        } else {
            const updated_list = selectedInstancesToDelete.filter(i => i !== row_id)
            setSelectedInstancesToDelete(updated_list)
        }
    }

    const onServerVolumeRemove = async () => {
        if (selectedInstancesToDelete.length > 0) {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                for (let i in selectedInstancesToDelete) {
                    const url = `${computeServiceDomain}/${computeServiceVersion}/${serversUrl}/${selectedInstancesToDelete[i]}/os-volume_attachments/${selectedRow.id}`
                    const method = "DELETE"
                    try {
                        await computeNovaRequest({
                            url:url, 
                            method:method, 
                            token: project_token
                        })
                    } catch {
                        console.log("")
                    }
                }
                handleDataFetch()
            }
        }
    }

    return (
        <React.Fragment>
            <WrapperBox
                sx={detailTabCardConfig}
            >
                <Stack
                    direction="row" 
                    alignItems="center" 
                    justifyContent="center"
                    spacing={4}
                >
                <FaServer
                    size={28}
                /> 
                    <CustomText 
                        size="p" 
                        sx={{fontSize: {xs: 16, lg: 18}}}
                    >
                        {defaultTexts.serverAttachmentTitleText}
                    </CustomText>
                    <CustomText 
                        size="h4" 
                        sx={{
                            color: theme.palette.dashboardPink
                        }}
                    >
                        {attachedServers.length}
                    </CustomText>
                </Stack>
            </WrapperBox>
            <Grid container spacing={2} sx={{mt: 1, mb: 4}}>
                <Grid item xs={12} md={12}>
                    <TableContainer>
                        <Table sx={{ width: "100%" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">
                                    {attachedServers.length > 0 && 
                                        <IconButton 
                                            onClick={onServerVolumeRemove}
                                            disabled={selectedInstancesToDelete.lenght === 0}
                                        >
                                            <DeleteIcon 
                                                color="primary"
                                            />
                                        </IconButton>}
                                    </TableCell>
                                    <TableCell align="left">{defaultTexts.nameFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.attachedToFormFieldLabel}</TableCell>
                                    <TableCell align="left">{defaultTexts.hostFormFieldLabel}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {attachedServers.map((row) => (
                                <TableRow
                                    key={row.id}
                                    hover
                                    sx={{ cursor: 'pointer' }}
                                >
                                    <TableCell>
                                        <Checkbox
                                            color="primary"
                                            checked={selectedInstancesToDelete.includes(row.id)}
                                            onChange={(event) => handleSelectedInstanceToDeleteChange(event,row.id)}
                                        />
                                    </TableCell>
                                    <TableCell 
                                        component="th" 
                                        align="left"
                                        scope="row"
                                        sx={{color: "primary.main"}}
                                        onClick={() => handleNavigateToServer(row.id)}
                                    >
                                        {row.name}
                                    </TableCell>
                                    <TableCell 
                                        align="left"
                                        onClick={() => handleNavigateToServer(row.id)}
                                    >
                                        {row.device}
                                    </TableCell>
                                    <TableCell 
                                        align="left"
                                        onClick={() => handleNavigateToServer(row.id)}
                                    >
                                        {row.host_name}
                                    </TableCell>
                                </TableRow>
                            ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
            </Grid>
        </Grid>
    </React.Fragment>
    )
};

export default VolumeAttachmentsV3;