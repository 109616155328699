import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../../../_common/CustomTable';
import { groupTypeDataSchema } from 
'../../../../../_data/openstack/cinder/group_types/v3';
import { setGroupTypesTableConfig } from 
'../../../../../store/reducers/blockStorageCinderSlice';
import { groupTypeSortFields } from 
'../../../../../_data/openstack/cinder/group_types/v3';


const GroupTypesTableV3 = (props) => {
    const { groupTypesData, setGroupTypesData } = props
    const { handleRowSelection } = props
    const {currentAction} = props
    const {setCurrentAction}  = props
    const { actionsTexts }  = props
    const { actionsList } = props
    const { sortHandler } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);
    
    const groupTypesTableConfig = useSelector(
        state => state.blockStorageCinder.groupTypesTableConfig)
    const rowsPerPage = useSelector(
        state => state.blockStorageCinder.groupTypesTableConfig.filter(
        versions => versions.api_version === "v3")[0].rows_per_page)
    const grouptypesTableColumns = useSelector(
        state => state.blockStorageCinder.groupTypesTableConfig.filter(
            versions => versions.api_version === "v3")[0].columns)
    
    const identityField = grouptypesTableColumns.filter(
        item => item.is_id_field)[0].field_key

    const dispatch = useDispatch();

    const handleGroupTypesTableConfig = (value_list) => {
        let newTableConfig = groupTypeDataSchema.filter(
            n => value_list.includes(n.field_key))
        const new_data = groupTypesTableConfig.map(version => {
            if (version.api_version === "v3") {
                let new_item = {...version}
                new_item.columns = [...newTableConfig]
                return new_item
            } else {
                return version
            }
        })
        dispatch(setGroupTypesTableConfig({
            groupTypesTableConfig: new_data
        }))
    }
    
    const handleRowsPerPageChange = (rows_num) => {
        const new_data = groupTypesTableConfig.map(version => {
            if (version.api_version === "v3") {
                let new_item = {...version}
                new_item.rows_per_page = rows_num
                return new_item
            } else {
                return version
            }
        })
        dispatch(setGroupTypesTableConfig({
            groupTypesTableConfig: new_data
        }))
    }

    return (
        <React.Fragment>
            <CustomTable
                withPagination={true}
                withCheckbox={true}
                withActions={true}
                withSorting={true}
                defaultDataTexts={{...defaultTexts}} 
                tableHeaders={grouptypesTableColumns}
                tableHeadersConfigHandler={handleGroupTypesTableConfig}
                identityField={identityField}
                columns={[...groupTypeDataSchema]}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                dataRows={groupTypesData}
                setDataRows={setGroupTypesData}
                handleRowSelection={handleRowSelection}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={actionsTexts}
                actionsList={actionsList}
                sortFields={groupTypeSortFields}
                sortHandler={sortHandler}
            />
        </React.Fragment>
    )
};

export default GroupTypesTableV3;