import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import WrapperBox from '../../../../_common/WrapperBox';
import LimitsDetailV3 from './limitsDetailV3';
import CustomSelectField from 
'../../../../_common/_form_fields/CustomSelectField';
import { projectsUrl as projectsUrlResponses} from
'../../../../../_api_responses/openstack/identity/projects/v3';
import { openstackRequest, volumeCinderRequest, getXAuthTokenProjectScope } from 
'../../../../../_network/openstack_request';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { blockStorageCinderConstants, identityKeystonConstants } from 
'../../../../../config/openStackConstants';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import { Paper, Stack } from '@mui/material';
import CustomText from '../../../../_common/CustomText';

const SERVICE_NAME = openStackServices.volumeService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const LimitsWrapperV3 = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [projects, setProjects] = useState([])
    const [selectedProjects, setSelectedProjects] = useState([defaultAdminProject])
    const [selectedProjectsLimits, setSelectedProjectsLimits] = useState([])
    const defaultTexts = useSelector(state => state.texts.langTexts)

    const cinderServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const cinderServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const limitsUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.limitsUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
            service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
            service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)

    
    const handleCardClose = (project_id) => {
        const updated_project_ids = selectedProjects.filter(item => item !== project_id)
        const updated_project_limits = selectedProjectsLimits.filter(item => item.id !== project_id)
        setSelectedProjects(updated_project_ids)
        setSelectedProjectsLimits(updated_project_limits)
    }

    const handleCardDataUpdate = (data, project_id) => {
        const updated_data = selectedProjectsLimits.map(item => {
            if (item.id === project_id) {
                return data
            } else {
                return item
            }
        })
        setSelectedProjectsLimits(updated_data)
    }

    const handleLoading = () => {
        setIsLoading(true)
    }

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"
            const projects_response = await openstackRequest({url:url, method:method})
            
            if (projects_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                const projects_list = projects_response.data.projects.map(project => {
                    return {keyword: project.name, value: project.id, default: false}
                })
                setProjects(projects_list)
            }
        })()
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl
    ]);

    useEffect(() => {
        (async () => {
            if (selectedProjects.length > 0) {
                handleLoading()
                let updated_data = []
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                for (let p in selectedProjects) {
                    const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${limitsUrl}/${selectedProjects[p]}?usage=true`
                    const method = "GET"
                    const limits_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                    if (limits_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                        let new_item = {}
                        new_item["id"] = selectedProjects[p]
                        new_item["maxTotalVolumes"] = limits_response.data.quota_set.volumes.limit
                        new_item["maxTotalVolumeGigabytes"] = limits_response.data.quota_set.gigabytes.limit
                        new_item["totalVolumesUsed"] = limits_response.data.quota_set.volumes.in_use
                        new_item["totalVolumeGigabytesUsed"] = limits_response.data.quota_set.gigabytes.in_use
                        new_item["maxTotalSnapshots"] = limits_response.data.quota_set.snapshots.limit
                        new_item["totalSnapshotsUsed"] = limits_response.data.quota_set.snapshots.in_use
                        new_item["maxTotalBackups"] = limits_response.data.quota_set.backups.limit
                        new_item["maxTotalBackupGigabytes"] = limits_response.data.quota_set.backup_gigabytes.limit
                        new_item["totalBackupsUsed"] = limits_response.data.quota_set.backups.in_use
                        new_item["totalBackupGigabytesUsed"] = limits_response.data.quota_set.backup_gigabytes.in_use
                        new_item["maxTotalGroups"] = limits_response.data.quota_set.groups.limit
                        new_item["totalGroupsUsed"] = limits_response.data.quota_set.groups.in_use
    
                        updated_data.unshift(new_item)
                    }
                }
            }
            setSelectedProjectsLimits(updated_data)
            }
        })()
        setIsLoading(false)
},[
    cinderServiceDomain,
    cinderServiceVersion,
    limitsUrl,
    defaultAdminProject,
    selectedProjects
])

    
    return (
        <WrapperBox>
            {isLoading && <CustomBackdrop open={isLoading} />}
            <CustomSelectField 
                items={projects}
                self_item_titles={true}
                label={defaultTexts.projectFormFieldLabel}
                size="large"
                empty={true}
                multiple={true}
                currentValue={selectedProjects}
                setCurrentValue={setSelectedProjects}
                sx={{mt: 2, alignSelf: "end"}}
            />
            {!isLoading && selectedProjectsLimits.length > 0 && 
                <Stack direction="column" spacing={5} sx={{width: "100%", mt: 2}}>
                {selectedProjectsLimits.map((item,index) => {
                    return (
                        <LimitsDetailV3 
                            key={item.id} 
                            data={item} 
                            onCardClose={handleCardClose}
                            setData={handleCardDataUpdate}
                            projectName={projects.filter(project => project.value === item.id).length > 0 ?
                                projects.filter(project => project.value === item.id)[0].keyword : ""}
                        />
                    )       
                })}
                </Stack>
            }
            {!isLoading && selectedProjects.length === 0 &&
                <Paper
                    sx={{
                        border: 1,
                        borderColor: "customBlue",
                        borderRadius: 2,
                        minWidth: "100%",
                        height: 400,
                        p: 3,
                        mt: 3,
                        justifyContent: "center",
                        alignItems: "center",
                        display: "flex"
                    }}
                >
                    <CustomText 
                        size="h5" 
                        sx={{color: "customBlue"}}
                    >
                        {defaultTexts.noProjectsSelectedNoteText}
                    </CustomText>
                </Paper>
            }
           
            
            
        </WrapperBox>
    )
};

export default LimitsWrapperV3;