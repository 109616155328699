import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import WrapperBox from '../../../../_common/WrapperBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOffIcon from '@mui/icons-material/EditOff';
import Paper from '@mui/material/Paper';
import useWindowDimensions from 
'../../../../_common/WindowDimensions';
import Constants from '../../../../../config/constants';
import { IconButton }  from '@mui/material';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { openstackRequest } from 
'../../../../../_network/openstack_request';
import { domainsUrl as domainsUrlResponses } from 
'../../../../../_api_responses/openstack/identity/domains/v3';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { identityKeystonConstants } from 
'../../../../../config/openStackConstants';
import CustomDialog from 
'../../../../_common/CustomDialog';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import { FormGroup } from '@mui/material';
import { domainDataUpdateForm, domainDataSchema } from 
'../../../../../_data/openstack/identity/domains/v3';
import { getDetailDataComponent } from 
'../../../../_common/common_helpers';

const SERVICE_NAME = openStackServices.identityService

const IdentityDomainDetailV3 = (props) => {
    const { selectedRow } = props
    const { domainsData } = props
    const { widthWeight } = props
    const { handleFetchData, handleDrawerClose } = props
    const { width } = useWindowDimensions();
    const [editMode, setEditMode] = useState(false);
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const [error, setError] = useState()
    const [successDomainDelete, setSuccessDomainDelete] = useState()
    const [successDomainUpdate, setSuccessDomainUpdate] = useState()
    
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [successUpdateDialogOpen, setSuccessUpdateDialogOpen] = useState(false);
    
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

    const [updateFormData, setUpdateFormData] = useState(
        {...domainsData[selectedRow]}
    )
    
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const domainsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.domainsUrl)[0].url)

    const handleFormDataChange = (event,field_key) => {
        let new_form_data = {...updateFormData}
        if (domainDataUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else {
            new_form_data[field_key] = event.target.value
        }
        setUpdateFormData(new_form_data)
    }

    const getDataForm = () => {
        return (
            <FormGroup>
                {domainDataUpdateForm.map(field => {
                return (
                    getFormFieldComponent(
                        field,
                        updateFormData,
                        handleFormDataChange,
                        defaultTexts[field.label],
                        {size:"medium",
                        withHelp: field.with_help_text,
                        helpText: defaultTexts[field.help_text],
                        sx: {
                            my: 1, 
                            width: width * widthWeight * 0.8
                        }}
                    )
                )
            })}
        </FormGroup>)
    }

    const handleEditModeChange = () => {
        setEditMode(true)
    }
    const handleEditModeReset = () => {
        setUpdateFormData({...domainsData[selectedRow]})
        setEditMode(false)
    }
    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }
    const handleSuccessDialogClose = () => {
        setSuccessDomainDelete(null);
        setSuccessDialogOpen(false);
        handleDrawerClose()
    }
    const handleSuccessUpdateDialogClose = () => {
        setSuccessDomainUpdate(null)
        setUpdateFormData({...domainsData[selectedRow]})
        setSuccessUpdateDialogOpen(false);
    }
    const handleConfirmDeleteDialogOpen = () => {
        setConfirmDeleteDialogOpen(true)
    }
    const handleConfirmDeleteDialogClose = () => {
        setConfirmDeleteDialogOpen(false)
    }

    const handleDeleteDomain = async () => {
        handleConfirmDeleteDialogClose()
        const url = `${identityServiceDomain}/${identityServiceVersion}/${domainsUrl}/${domainsData[selectedRow].id}`
        const method = "DELETE"

        const domain_response = await openstackRequest({url: url, method: method})
        if (domain_response.status_code === domainsUrlResponses.delete.success_response.status_code) {
            setSuccessDomainDelete({
                success_title: domainsUrlResponses.delete.success_response.response_title, 
                success_message: domainsUrlResponses.delete.success_response.response_message
            })
            handleFetchData()
        } else {
            const error_response = domainsUrlResponses.delete.error_response.filter(
                error_item => error_item.status_code === domain_response.status_code)
            if (error_response.length > 0) {
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: domain_response.error
                }
                setError(errorObject)
            } else {
                const error_response = domainsUrlResponses.delete.error_response.filter(
                    error_item => error_item.status_code === "unknown")
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: domain_response.error
                }
                setError(errorObject)
            }
        }
    }

    const handleEditDomain = async () => {
        let updated_data = {...updateFormData}
        if (updateFormData.tags.length > 0 && typeof(updateFormData.tags) === "string") {
            const normalized_tags = updateFormData.tags.trim().split(",").filter(
                item => item.trim().length > 0).map(item => item.trim())
            updated_data.tags = normalized_tags
        }
        
        const url = `${identityServiceDomain}/${identityServiceVersion}/${domainsUrl}/${domainsData[selectedRow].id}`
        const method = "PATCH"

        const domain_response = await openstackRequest({
            url: url, 
            method: method, 
            data: {domain: updated_data}
        })
        if (domain_response.status_code === domainsUrlResponses.patch.success_response.status_code) {
            handleFetchData()
            handleEditModeReset()
            setSuccessDomainUpdate({
                success_title: domainsUrlResponses.patch.success_response.response_title, 
                success_message: domainsUrlResponses.patch.success_response.response_message
            })
        } else {
            const error_response = domainsUrlResponses.patch.error_response.filter(
                error_item => error_item.status_code === domain_response.status_code)
            if (error_response.length > 0) {
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: domain_response.error
                }
                setError(errorObject)
            } else {
                const error_response = domainsUrlResponses.patch.error_response.filter(
                    error_item => error_item.status_code === "unknown")
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: domain_response.error
                }
                setError(errorObject)
            }
        }
    }

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessDialogOpen(true)
    },[successDomainDelete]);

    useEffect(() => {
        setSuccessUpdateDialogOpen(true)
    },[successDomainUpdate]);

    return (
        <React.Fragment>
            <WrapperBox
                sx={{
                    borderBottom: 0.5, 
                    borderColor: 'text.secondary',
                    py: 1,
                    alignItems: 'end'
                }}
            >
                {selectedRow !== null && domainsData[selectedRow] &&
                    <CustomText size="h6">
                        {domainsData[selectedRow].name}
                    </CustomText>}
            </WrapperBox>
            {editMode && 
                <WrapperBox 
                    sx={{
                        m: 2, 
                        alignItems: 'start'
                    }}>
                <CustomText 
                    size="h6" 
                    sx={{
                        color: "primary.main", 
                        mb: 2
                    }}>
                    {defaultTexts.updateDomainFormTitle}
                </CustomText>
                {getDataForm()}
                <Button 
                    variant="contained"
                    color="secondary"
                    sx={{mt: 5}}
                    onClick={handleEditDomain}
                >
                    {defaultTexts.saveButtonText}
                </Button>
            </WrapperBox>}
            {!editMode && 
                <Stack 
                    spacing={2} 
                    sx={{ p: 1, mt: 3 }}
                >
                    {domainDataSchema.map((field) => {
                        return (
                            getDetailDataComponent({
                                fieldType: field.field_type,
                                fieldKey: field.field_key,
                                label: defaultTexts[field.label],
                                value: domainsData[selectedRow] ? domainsData[selectedRow][field.field_key] : "",
                                textOnTrue: defaultTexts[field.value_on_true],
                                textOnFalse: defaultTexts[field.value_on_false]
                            })
                        )
                    })}
                </Stack>
            }
            <Paper sx={{ 
                position: 'fixed', 
                bottom: 0, 
                height: Constants.actions_bar_height,
                left: width - (width * widthWeight), 
                right: 12
                }} 
                elevation={24}
                square={true}
                
            >
                <Grid 
                    container 
                    alignItems="center"  
                    justifyContent="space-between"
                >
                    <Grid item>
                        {!editMode && <IconButton onClick={handleEditModeChange}>
                            <EditIcon color="primary" />
                        </IconButton>}
                        {editMode && <IconButton onClick={handleEditModeReset}>
                            <EditOffIcon color="primary" />
                        </IconButton>}
                        {selectedRow !== null && 
                            !domainsData[selectedRow].enabled && 
                            <IconButton onClick={handleConfirmDeleteDialogOpen}>
                                <DeleteIcon 
                                    color="primary"
                                />
                            </IconButton>}
                    </Grid>
                </Grid>
            </Paper> 
            {successDomainDelete && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[successDomainDelete.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successDomainDelete.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {successDomainUpdate && <CustomDialog
                open={successUpdateDialogOpen}
                onClose={handleSuccessUpdateDialogClose}
                dialogTitle={{
                    title: defaultTexts[successDomainUpdate.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successDomainUpdate.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>Details:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            <CustomDialog
                open={confirmDeleteDialogOpen}
                onClose={handleConfirmDeleteDialogClose}
                dialogTitle={{
                    title: defaultTexts.deleteConfirmationDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.deleteDomainConfirmationDialogMessage, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: handleDeleteDomain, 
                    sx: {color: 'primary.main'}}]}
            />
        </React.Fragment>
    )
};

export default IdentityDomainDetailV3;