import React from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { 
    computeNovaConstants,
    openStackServices
} from '../../../../config/openStackConstants';
import HypervisorsGraphContentV21 from "./hypervisorsGraphContentV21";
import WrapperBox from '../../../_common/WrapperBox';
import NotPurchasedService from '../../../_common/NotPurchasedService';

const SERVICE_NAME = openStackServices.computeService
const SERVICE_TITLE = 'Compute (Nova)'

const HypervisorsGraphWrapper = (props) => {
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
    const navigate = useNavigate();

    if (!isAuthenticated) {
        navigate('/');
    }

    const purchasedServices = useSelector(state => 
        state.openstack.purchasedServices);

    const purchasedService = purchasedServices.filter(
        item => item.service === SERVICE_NAME)
    if (purchasedService.length === 0) {
        return (
            <NotPurchasedService service={SERVICE_TITLE} />
        )
    }
    if (!computeNovaConstants.supported_api_versions.includes(
        purchasedService[0].config_params.api_version)) {
        return (
            <WrapperBox>
                Configured API Version for {SERVICE_TITLE} is not supported!
            </WrapperBox>
        )
    }

    if (purchasedService[0].config_params.api_version === "v2.1") {
        return (
            <HypervisorsGraphContentV21 />
        )
    }
};

export default HypervisorsGraphWrapper;