export const projectDataSchema = [
    {
        field_key: "name",
        is_id_field: true,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "domain_id",
        is_id_field: false,
        field_type: "string", 
        label: "domainFormFieldLabel"
    },
    {
        field_key: "parent_id",
        is_id_field: false,
        field_type: "string", 
        label: "parentProjectFormFieldLabel"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "enabled",
        is_id_field: false,
        field_type: "bool", 
        label: "enabledFormFieldLabel",
        value_on_true: "formValueYes",
        value_on_false: "formValueNo"
    },
    {
        field_key: "is_domain",
        is_id_field: false,
        field_type: "bool",
        label: "isDomainFormFieldLabel",
        value_on_true: "formValueYes",
        value_on_false: "formValueNo"
    },
    {
        field_key: "id",
        is_id_field: false,
        field_type: "string", 
        label: "idFormFieldLabel"
    },
    {
        field_key: "tags",
        is_id_field: false,
        field_type: "list",
        label: "tagsFormFieldLabel"
    }
]

export const projectDataForm = [
    {
        field_key: "name",
        field_type: "string", 
        required: true, 
        label: "nameFormFieldLabel", 
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "is_domain",
        field_type: "bool", 
        required: false,
        label: "isDomainFormFieldLabel",
        default_value: false
    },
    {
        field_key: "description",
        field_type: "string", 
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true,
        on_empty_null: false
    },
    {
        field_key: "domain_id",
        field_type: "select", 
        required: false,
        label: "domainFormFieldLabel",
        on_empty_null: true,
        self_item_titles: true,
        default_empty: true
    },
    {
        field_key: "enabled",
        field_type: "bool", 
        required: false,
        label: "enabledFormFieldLabel",
        default_value: true
    },
    {
        field_key: "parent_id",
        field_type: "select", 
        required: false,
        label: "parentProjectFormFieldLabel",
        with_help_text: true,
        help_text: "parentProjectFormFieldHelpText",
        on_empty_null: true,
        self_item_titles: true,
        default_empty: true
    },
    {
        field_key: "tags",
        field_type: "list", 
        required: false, 
        label: "tagsFormFieldLabel",
        with_help_text: true,
        help_text: "listFormFieldHelpText"
    }
]


export const projectDataUpdateForm = [
    {
        field_key: "name",
        field_type: "string", 
        required: false, 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "description",
        field_type: "string", 
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    },
    {
        field_key: "enabled",
        field_type: "bool", 
        required: false,
        label: "enabledFormFieldLabel"
    },
    {
        field_key: "tags",
        field_type: "list", 
        required: false, 
        label: "tagsFormFieldLabel",
        with_help_text: true,
        help_text: "listFormFieldHelpText"
    },
]


export const projectsfilterMenu = [
    {
        keyword: "filterName", 
        value: "name", 
        type: "text"
    },
    {
        keyword: "filterDomain", 
        value: "domain_id", 
        type: "select",
        items: [],
        self_item_titles: true,
        default_empty: true
    },
    {
        keyword: "filterParentProject", 
        value: "parent_id", 
        type: "select",
        items: [],
        self_item_titles: true,
        default_empty: true
    },
    {
        keyword: "filterIsDomain", 
        value: "is_domain", 
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "filterChoiceYes", 
                value: "true", 
                default: true
            },
            {
                keyword: "filterChoiceNo", 
                value: "false", 
                default: false
            }
        ]
    },
    {
        keyword: "filterEnabled", 
        value: "enabled", 
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "filterChoiceYes", 
                value: "true", 
                default: true
            },
            {
                keyword: "filterChoiceNo", 
                value: "false", 
                default: false
            }
        ]
    },
    {
        keyword: "filterTags",
        value: "tags_filter",
        type: "multifield",
        help_text: "multifieldFilterHelpText",
        fields: [
            {
                keyword: "filterAllTags",
                field_key: "tags",
                value: "tags",
                field_type: "list",
                with_help_text: true,
                help_text: "allTagsFilterHelpText"
            },
            {
                keyword: "filterAnyTags",
                field_key: "tags-any",
                value: "tags-any",
                field_type: "list",
                with_help_text: true,
                help_text: "anyTagsFilterHelpText"
            },
            {
                keyword: "filterNotTags",
                field_key: "not-tags",
                value: "not-tags",
                field_type: "list",
                with_help_text: true,
                help_text: "notTagsFilterHelpText"
            },
            {
                keyword: "filterNotAnyTags",
                field_key: "not-tags-any",
                value: "not-tags-any",
                field_type: "list",
                with_help_text: true,
                help_text: "notAnyTagsFilterHelpText"
            }
        ]
    }
];