export const virtualIPDataSchema = [
    {
        field_key: "project_id",
        is_id_field: false,
        field_type: "string", 
        label: "projectFormFieldLabel"
    },
    {
        field_key: "network_id",
        is_id_field: false,
        field_type: "string", 
        label: "networkFormFieldLabel"
    },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "local_ip_address",
        is_id_field: false,
        field_type: "string", 
        label: "ipAddressFormFieldLabel"
    },
    {
        field_key: "ip_mode",
        is_id_field: false,
        field_type: "string", 
        label: "ipModeFormFieldLabel"
    },
    {
        field_key: "local_port_id",
        is_id_field: false,
        field_type: "string", 
        label: "portFormFieldLabel"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    },
]

export const virtualIPDataForm = [
    {
        field_key: "network_id",
        field_type: "select",
        required: true,
        label: "networkFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    },
    {
        field_key: "local_ip_address",
        field_type: "string",
        required: true,
        label: "ipAddressFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "ip_mode",
        field_type: "select",
        required: true,
        label: "ipModeFormFieldLabel",
        error_label: "requiredFormFieldError",
        default_value: "translate",
        self_item_titles: false,
        default_empty: false,
        items: [
            {
                keyword: "translateModeChoiceFormField",
                value: "translate",
                default: true
            },
            {
                keyword: "passthroughModeChoiceFormField",
                value: "passthrough",
                default: false
            }
        ]
    }
]

export const virtualIPUpdateDataForm = [
    {
        field_key: "name",
        field_type: "string",
        required: false,
        label: "nameFormFieldLabel"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    }
]

export const virtualIPsSortFields = [
    {
        field_key: "local_ip_address",
        label: "ipAddressFormFieldLabel"
    },
    {
        field_key: "project_id",
        label: "projectFormFieldLabel"
    },
    {
        field_key: "network_id",
        label: "networkFormFieldLabel"
    },
    {
        field_key: "name",
        label: "nameFormFieldLabel"
    }
]


export const virtualIPsFilterMenu = [
    {
        keyword: "ipAddressFormFieldLabel",
        value: "local_ip_address",
        type: "text"
    },
    {
        keyword: "nameFormFieldLabel",
        value: "name",
        type: "text"
    },
    {
        keyword: "projectFormFieldLabel",
        value: "project_id",
        type: "select", 
        multiple: false,
        self_item_titles: true,
        items: []
    },
    {
        keyword: "networkFormFieldLabel",
        value: "network_id",
        type: "select", 
        multiple: false,
        self_item_titles: true,
        items: []
    },
    {
        keyword: "ipModeFormFieldLabel", 
        value: "ip_mode", 
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "translateModeChoiceFormField", 
                value: "translate", 
                default: true
            },
            {
                keyword: "passthroughModeChoiceFormField", 
                value: "passthrough", 
                default: false
            }
        ]
    }
]

export const virtualIPPortAssociationForm = [
    {
        field_key: "port_type",
        field_type: "select",
        required: true,
        label: "portTypeFormFieldLabel",
        self_item_titles: false,
        default_empty: false,
        error_label: "requiredFormFieldError",
        items: [
            {
                keyword: "serverInstanceFormFieldLabel",
                value: "server",
                default: true
            },
            {
                keyword: "routerFormFieldLabel",
                value: "router",
                default: false
            },
        ]
    },
    {
        field_key: "server_id",
        field_type: "select",
        required: false,
        label: "serverFormFieldLabel",
        self_item_titles: true,
        default_empty: false
    },
    {
        field_key: "router_id",
        field_type: "select",
        required: false,
        label: "routerFormFieldLabel",
        self_item_titles: true,
        default_empty: false
    },
    {
        field_key: "fixed_port_id",
        field_type: "select",
        required: true,
        label: "portFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        error_label: "requiredFormFieldError"
    }
]