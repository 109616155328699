import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Constants from '../../../../../config/constants';
import AddButton from '../../../../_common/AddButton';
import FilterButton from '../../../../_common/FilterButton';
import WrapperBox from '../../../../_common/WrapperBox';
import { FormGroup } from '@mui/material';
import { flavorDataForm } from 
'../../../../../_data/openstack/compute/flavors/v2.1';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { computeNovaConstants } from 
'../../../../../config/openStackConstants';
import { openstackRequest } from 
'../../../../../_network/openstack_request';
import { flavorsUrl as flavorsUrlResponses } from 
'../../../../../_api_responses/openstack/compute/flavors/v2.1';
import CustomDialog from '../../../../_common/CustomDialog';
import { getXAuthTokenProjectScope } 
from '../../../../../_network/openstack_request';

const SERVICE_NAME = openStackServices.computeService

const ComputeNovaFlavorsSubheaderV21 = (props) => {
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [error, setError] = useState()
    const [successFlavorAdd, setSuccessFlavorAdd] = useState()
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const { selectedFilter, setSelectedFilter } = props
    const { selectedFilterValue, setSelectedFilterValue } = props
    const { handleFetchData } = props
    const { filterMenu, defaultTexts } = props
    const { handleFilteredSearch, handleFilterReset } = props
    const [formData, setFormData] = useState({})
    const [formDataOptions, setFormDataOptions] = useState({});

    const computeServiceDomain = useSelector(state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const computeServiceVersion = useSelector(state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const flavorsUrl = useSelector(
        state => state.computeNova.computeNovaApiUrls.filter(
            version => version.api_version === "v2.1")[0].urls.filter(
                url => url.keyword === computeNovaConstants.flavorsUrl)[0].url)

    const handleFormDataValidation = () => {
        let validation_faild = true
        let updatedDataFormOptions = {...formDataOptions}
        for (let n in flavorDataForm) {
            if (flavorDataForm[n].required && !formData[flavorDataForm[n].field_key]) {
                validation_faild = false
                updatedDataFormOptions[flavorDataForm[n].field_key] = {}
                updatedDataFormOptions[flavorDataForm[n].field_key]["error"] = true
                updatedDataFormOptions[flavorDataForm[n].field_key]["errorText"] = defaultTexts[flavorDataForm[n].error_label]
            }
        }
        setFormDataOptions(updatedDataFormOptions)
        return validation_faild
    }

    const onAddFlavor = async () => {
        const validateFormData = handleFormDataValidation()
        if (validateFormData) {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${computeServiceDomain}/${computeServiceVersion}/${flavorsUrl}`
                const method = "POST"
                
                const flavor_response = await openstackRequest({url:url, method:method, data: {flavor: formData}, token: project_token})
                if (flavor_response.status_code === flavorsUrlResponses.post.success_response.status_code) {
                    handleAddFlavorFormReset()
                    handleFetchData()
                    setSuccessFlavorAdd({
                        success_title: flavorsUrlResponses.post.success_response.response_title, 
                        success_message: flavorsUrlResponses.post.success_response.response_message
                    })
                } else {
                    const error_response = flavorsUrlResponses.post.error_response.filter(
                        error_item => error_item.status_code === flavor_response.status_code)
                    if (error_response.length > 0) {
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: flavor_response.error
                        }
                        setError(errorObject)
                    } else {
                        const error_response = flavorsUrlResponses.post.error_response.filter(
                            error_item => error_item.status_code === "unknown")
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: flavor_response.error
                        }
                        setError(errorObject)
                    }
                }
            }
            
        }
        return validateFormData
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleSuccessDialogClose = () => {
        setSuccessFlavorAdd(null);
        setSuccessDialogOpen(false);
    }

    const handleAddFlavorFormReset = () => {
        setFormDataOptions({})
        let new_form_data = {}
        for (const n in flavorDataForm) {
            if (
                flavorDataForm[n].field_type === "string" || 
                flavorDataForm[n].field_type === "select" ||
                flavorDataForm[n].field_type === "list"
                ) {
                new_form_data[flavorDataForm[n].field_key] = ""
            } else if (flavorDataForm[n].field_type === "bool") {
                new_form_data[flavorDataForm[n].field_key] = flavorDataForm[n].default_value ? 
                flavorDataForm[n].default_value : 
                false
            } else if (flavorDataForm[n].field_type === "integer") {
                new_form_data[flavorDataForm[n].field_key] = flavorDataForm[n].default_value ? 
                flavorDataForm[n].default_value : 
                0
            } else if (flavorDataForm[n].field_type === "float") {
                new_form_data[flavorDataForm[n].field_key] = flavorDataForm[n].default_value ? 
                flavorDataForm[n].default_value : 
                0
            }
        }
        setFormData(new_form_data)
    }

    const handleFormDataChange = (event,field_key) => {

        let new_form_data = {...formData}
        if (flavorDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (flavorDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setFormDataOptions({})
        setFormData(new_form_data)
    }

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessDialogOpen(true)
    },[successFlavorAdd]);

    useEffect(() => {
        if (Object.keys(formData).length === 0) {
            let new_form_data = {}
            for (const n in flavorDataForm) {
                if (
                    flavorDataForm[n].field_type === "string" || 
                    flavorDataForm[n].field_type === "select" ||
                    flavorDataForm[n].field_type === "list"
                    ) {
                    new_form_data[flavorDataForm[n].field_key] = ""
                } else if (flavorDataForm[n].field_type === "bool") {
                    new_form_data[flavorDataForm[n].field_key] = flavorDataForm[n].default_value ? 
                    flavorDataForm[n].default_value : 
                    false
                } else if (flavorDataForm[n].field_type === "integer") {
                    new_form_data[flavorDataForm[n].field_key] = flavorDataForm[n].default_value ? 
                    flavorDataForm[n].default_value : 
                    0
                } else if (flavorDataForm[n].field_type === "float") {
                    new_form_data[flavorDataForm[n].field_key] = flavorDataForm[n].default_value ? 
                    flavorDataForm[n].default_value : 
                    0
                }
            }
            setFormData(new_form_data)
        }
    },[formData]);

    const getDataForm = () => {
        return (
            <FormGroup>
                {flavorDataForm.map(field => {
                    let form_field_options = {...formDataOptions[field.field_key]}
                    form_field_options["withHelp"] = field.with_help
                    form_field_options["helpText"] = field.help_text ? defaultTexts[field.help_text] : ""
                    form_field_options["helpTextWidth"] = 300
                    form_field_options["zIndex"] = 1300
                    if (!field.with_help) {
                        form_field_options["sx"] = {my: 1, width: {xs: "83%", lg: "90%"}}
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            formData,
                            handleFormDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    return (
        <WrapperBox 
            sx={{
                flexDirection: "row", 
                justifyContent: 'space-between',
                mt: 1
            }}>
            <AddButton 
                getDataForm={getDataForm}               
                onSubmit={onAddFlavor}
                formReset={handleAddFlavorFormReset}
                customTexts={{
                    title: defaultTexts.addFlavorDialogTitle,
                    description: defaultTexts.addFlavorDialogDescriptionText
                }}
            />
            <FilterButton 
                choice_mode={Constants.simple_filter_type}
                currentFilter={selectedFilter}
                setCurrentFilter={setSelectedFilter}
                filter_menu_titles={defaultTexts}
                filter_menu={filterMenu}
                currentFilterValue={selectedFilterValue}
                setCurrentFilterValue={setSelectedFilterValue}
                onFilterSubmit={handleFilteredSearch}
                onFilterReset={handleFilterReset}
            />
            {successFlavorAdd && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[successFlavorAdd.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successFlavorAdd.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>Details:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </WrapperBox>
    )
}

export default ComputeNovaFlavorsSubheaderV21;