import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import WrapperBox from '../../../../_common/WrapperBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOffIcon from '@mui/icons-material/EditOff';
import Paper from '@mui/material/Paper';
import useWindowDimensions from 
'../../../../_common/WindowDimensions';
import Constants from '../../../../../config/constants';
import { IconButton }  from '@mui/material';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { openstackRequest } from 
'../../../../../_network/openstack_request';
import { projectsUrl as projectsUrlResponses } from 
'../../../../../_api_responses/openstack/identity/projects/v3';
import { domainsUrl as domainsUrlResponses } from 
'../../../../../_api_responses/openstack/identity/domains/v3';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { identityKeystonConstants } from 
'../../../../../config/openStackConstants';
import CustomDialog from 
'../../../../_common/CustomDialog';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import { FormGroup } from '@mui/material';
import { projectDataUpdateForm, projectDataSchema } from 
'../../../../../_data/openstack/identity/projects/v3';
import { getDetailDataComponent } from 
'../../../../_common/common_helpers';
import CustomLoading from '../../../../_common/CustomLoading';

const SERVICE_NAME = openStackServices.identityService

const IdentityProjectDetailV3 = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const { selectedRow, setSelectedRow } = props
    const { projectsData } = props
    const { widthWeight } = props
    const { handleFetchData, handleDrawerClose } = props
    const { width } = useWindowDimensions();
    const [editMode, setEditMode] = useState(false);
    const defaultTexts = useSelector(state => state.texts.langTexts)
    
    const [selectedProject, setSelectedProject] = useState(null)
    const [error, setError] = useState()
    const [successProjectDelete, setSuccessProjectDelete] = useState()
    const [successProjectUpdate, setSuccessProjectUpdate] = useState()
    
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [successUpdateDialogOpen, setSuccessUpdateDialogOpen] = useState(false);
    
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

    const [updateFormData, setUpdateFormData] = useState({})
    
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)
    const domainsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.domainsUrl)[0].url)

    const handleFormDataChange = (event,field_key) => {
        let new_form_data = {...updateFormData}
        if (projectDataUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else {
            new_form_data[field_key] = event.target.value
        }
        setUpdateFormData(new_form_data)
    }

    const getDataForm = () => {
        return (
            <FormGroup>
                {projectDataUpdateForm.map(field => {
                return (
                    getFormFieldComponent(
                        field,
                        updateFormData,
                        handleFormDataChange,
                        defaultTexts[field.label],
                        {size:"medium",
                        withHelp: field.with_help_text,
                        helpText: defaultTexts[field.help_text],
                        sx: {
                            my: 1, 
                            width: width * widthWeight * 0.8
                        }}
                    )
                )
            })}
        </FormGroup>)
    }

    const handleEditModeChange = () => {
        setEditMode(true)
    }
    const handleEditModeReset = () => {
        setEditMode(false)
    }
    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }
    const handleSuccessDialogClose = () => {
        setSuccessProjectDelete(null);
        setSuccessDialogOpen(false);
        setSelectedRow(null);
        handleDrawerClose()
    }
    const handleSuccessUpdateDialogClose = () => {
        setSuccessProjectUpdate(null)
        setSuccessUpdateDialogOpen(false);
    }
    const handleConfirmDeleteDialogOpen = () => {
        setConfirmDeleteDialogOpen(true)
    }
    const handleConfirmDeleteDialogClose = () => {
        setConfirmDeleteDialogOpen(false)
    }

    const handleDeleteProject = async () => {
        handleConfirmDeleteDialogClose()
        const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}/${projectsData[selectedRow].id}`
        const method = "DELETE"

        const project_response = await openstackRequest({url: url, method: method})
        if (project_response.status_code === projectsUrlResponses.delete.success_response.status_code) {
            setSuccessProjectDelete({
                success_title: projectsUrlResponses.delete.success_response.response_title, 
                success_message: projectsUrlResponses.delete.success_response.response_message
            })
            handleFetchData()
        } else {
            const error_response = projectsUrlResponses.delete.error_response.filter(
                error_item => error_item.status_code === project_response.status_code)
            if (error_response.length > 0) {
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: project_response.error
                }
                setError(errorObject)
            } else {
                const error_response = projectsUrlResponses.delete.error_response.filter(
                    error_item => error_item.status_code === "unknown")
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: project_response.error
                }
                setError(errorObject)
            }
        }
    }

    const handleEditProject = async () => {
        let updated_data = {...updateFormData}
        if (updateFormData.tags.length > 0 && typeof(updateFormData.tags) === "string") {
            const normalized_tags = updateFormData.tags.trim().split(",").filter(
                item => item.trim().length > 0).map(item => item.trim())
            updated_data.tags = normalized_tags
        }
        
        const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}/${projectsData[selectedRow].id}`
        const method = "PATCH"

        const project_response = await openstackRequest({
            url: url, 
            method: method, 
            data: {project: updated_data}
        })
        if (project_response.status_code === projectsUrlResponses.patch.success_response.status_code) {
            handleFetchData()
            handleEditModeReset()
            setSuccessProjectUpdate({
                success_title: projectsUrlResponses.patch.success_response.response_title, 
                success_message: projectsUrlResponses.patch.success_response.response_message
            })
        } else {
            const error_response = projectsUrlResponses.patch.error_response.filter(
                error_item => error_item.status_code === project_response.status_code)
            if (error_response.length > 0) {
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: project_response.error
                }
                setError(errorObject)
            } else {
                const error_response = projectsUrlResponses.patch.error_response.filter(
                    error_item => error_item.status_code === "unknown")
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: project_response.error
                }
                setError(errorObject)
            }
        }
    }

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessDialogOpen(true)
    },[successProjectDelete]);

    useEffect(() => {
        setSuccessUpdateDialogOpen(true)
    },[successProjectUpdate]);

    useEffect(() => {
        if (selectedRow !== null) {
            (async () => {
                setIsLoading(true)
                try {
                    const query_params = "?parents_as_list&&subtree_as_list&&include_limits"
                    const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}/${projectsData[selectedRow].id}/${query_params}`
                    const method = "GET"
        
                    const project_response = await openstackRequest({url: url, method: method})
                    if (project_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                        let response_data = {...project_response.data.project}
                        
                        if (project_response.data.project.domain_id) {
                            const domain_url = `${identityServiceDomain}/${identityServiceVersion}/${domainsUrl}/${project_response.data.project.domain_id}`
                            const domain_response = await openstackRequest({url: domain_url, method: method})
                            
                            if (domain_response.status_code === domainsUrlResponses.get.success_response.status_code) {
                                response_data.domain_id = domain_response.data.domain.name
                            }
                        }
                        if (project_response.data.project.parent_id) {
                            const parent_url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}/${project_response.data.project.parent_id}`
                            const parent_response = await openstackRequest({url: parent_url, method: method})
                            if (project_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                                response_data.parent_id = parent_response.data.project.name
                            }
                        }
                        
                        setSelectedProject(response_data)
                        let new_form_data = {}
                        for (let f in projectDataUpdateForm) {
                            new_form_data[projectDataUpdateForm[f].field_key] = project_response.data.project[projectDataUpdateForm[f].field_key]
                        }
                        
                        setUpdateFormData(new_form_data)
                       
                    } else {
                        const error_response = projectsUrlResponses.get.error_response.filter(
                            error_item => error_item.status_code === project_response.status_code)
                        if (error_response.length > 0) {
                            const errorObject = {
                                error_title: error_response[0].response_title, 
                                error_message: error_response[0].response_message,
                                error_details: project_response.error
                            }
                            setError(errorObject)
                        } else {
                            const error_response = projectsUrlResponses.get.error_response.filter(
                                error_item => error_item.status_code === "unknown")
                            const errorObject = {
                                error_title: error_response[0].response_title, 
                                error_message: error_response[0].response_message,
                                error_details: project_response.error
                            }
                            setError(errorObject)
                        }
                    }
                } catch {
                    console.log()
                }
            })();
        }
        setIsLoading(false)
        
    },[
        identityServiceDomain,
        identityServiceVersion,
        projectsUrl,
        domainsUrl,
        projectsData,
        selectedRow
    ]);

    if (isLoading) {
        return (
            <CustomLoading sx={{height: 300}}/>
        )
    } 

    return (
        <React.Fragment>
            <WrapperBox
                sx={{
                    borderBottom: 0.5, 
                    borderColor: 'text.secondary',
                    py: 1,
                    alignItems: 'end'
                }}
            >
                {selectedProject !== null && 
                    <CustomText size="h5">
                        {selectedProject.name}
                    </CustomText>}
            </WrapperBox>
            {editMode && 
                <WrapperBox 
                    sx={{
                        m: 2, 
                        alignItems: 'start'
                    }}>
                <CustomText 
                    size="h6" 
                    sx={{
                        color: "primary.main", 
                        mb: 2
                    }}>
                    {defaultTexts.updateProjectFormTitle}
                </CustomText>
                {getDataForm()}
                <Button 
                    variant="contained"
                    color="secondary"
                    sx={{mt: 5}}
                    onClick={handleEditProject}
                >
                    {defaultTexts.saveButtonText}
                </Button>
            </WrapperBox>}
            {!editMode && selectedProject &&
                <Stack 
                    spacing={2} 
                    sx={{ p: 1, mt: 3 }}
                >
                    {projectDataSchema.map((field) => {
                        return (
                            getDetailDataComponent({
                                fieldType: field.field_type,
                                fieldKey: field.field_key,
                                label: defaultTexts[field.label],
                                value: selectedProject[field.field_key],
                                textOnTrue: defaultTexts[field.value_on_true],
                                textOnFalse: defaultTexts[field.value_on_false]
                            })
                        )
                    })}
                </Stack>
            }
            <Paper sx={{ 
                position: 'fixed', 
                bottom: 0, 
                height: Constants.actions_bar_height,
                left: width - (width * widthWeight), 
                right: 12
                }} 
                elevation={24}
                square={true}
            >
                <Grid 
                    container 
                    alignItems="center"  
                    justifyContent="space-between"
                >
                    <Grid item>
                        {!editMode && <IconButton onClick={handleEditModeChange}>
                            <EditIcon color="primary" />
                        </IconButton>}
                        {editMode && <IconButton onClick={handleEditModeReset}>
                            <EditOffIcon color="primary" />
                        </IconButton>}
                        {selectedProject !== null && 
                            <IconButton onClick={handleConfirmDeleteDialogOpen}>
                                <DeleteIcon 
                                    color="primary"
                                />
                            </IconButton>}
                    </Grid>
                </Grid>
            </Paper> 
            {successProjectDelete && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[successProjectDelete.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successProjectDelete.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {successProjectUpdate && <CustomDialog
                open={successUpdateDialogOpen}
                onClose={handleSuccessUpdateDialogClose}
                dialogTitle={{
                    title: defaultTexts[successProjectUpdate.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successProjectUpdate.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>Details:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`,
                    sx: {color: 'text.primary'}}}
            />}
            <CustomDialog
                open={confirmDeleteDialogOpen}
                onClose={handleConfirmDeleteDialogClose}
                dialogTitle={{
                    title: defaultTexts.deleteConfirmationDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.deleteProjectConfirmationDialogMessage, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: handleDeleteProject, 
                    sx: {color: 'primary.main'}}]}
            />
        </React.Fragment>
    )
};

export default IdentityProjectDetailV3;