import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import CustomDrawer from '../../_common/CustomDrawer';
import ServiceContentHeader from '../../_common/ServiceContentHeader';
import BillingDashboard from './billingDashboard';
import BillingAccounting from './billingAccounting';
import BillingPayments from './billingPayments';
import BillingInvoices from './billingInvoices';
const BillingAnalyticsWrapper = (props) => {
    const location = useLocation();
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
    const defaultTexts = useSelector(state => state.texts.langTexts)
    
    const navigate = useNavigate();

    if (!isAuthenticated) {
        navigate('/');
    }

    const [serviceMenu, setServiceMenu] = useState([
        {keyword: "submenuBillingAnalyticsDashboard", navigation: "/billing-analytics-dashboard", is_active: true, component: BillingDashboard},
        {keyword: "submenuBillingAnalyticsAccounting", navigation: "/billing-analytics-accounting", is_active: false, component: BillingAccounting},
        {keyword: "submenuBillingAnalyticsPayments", navigation: "/billing-analytics-payments", is_active: false, component: BillingPayments},
        {keyword: "submenuBillingAnalyticsInvoices", navigation: "/billing-analytics-invoices", is_active: false, component: BillingInvoices},
    ])

    const getCurrentTab = () => {
        const currentItem = serviceMenu.filter(item => item.is_active)[0]
        return currentItem
    }

    const [currentTab, setCurrentTab] = useState(getCurrentTab())

    const changeMenuActiveTab = (navigation) => {
        const updated_menu = serviceMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
                setCurrentTab(item)
            } else {
                item.is_active = false
            }
            return item
        })
        setServiceMenu(updated_menu)
    }

    return (
        <CustomDrawer>
            <ServiceContentHeader 
                service_title={defaultTexts.billingAnalyticsNavLink}
                service_menu={serviceMenu}
                service_menu_titles={defaultTexts}
                onClick={changeMenuActiveTab}
            />
            <currentTab.component 
                navigate={navigate} 
                location={location}
            />
        </CustomDrawer>
    )
}

export default BillingAnalyticsWrapper;