import React, { useState, useEffect } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import Grid from "@mui/material/Grid";
import { Box } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import Constants from '../../../config/constants'
import billingRequest from '../../../_network/billing_request';
import { billingServiceURLs } from '../../../_network/apiUrls';
import { 
    openStackServices
 } from '../../../config/openStackConstants';
 import { identityKeystonConstants } from 
'../../../config/openStackConstants';
import { openstackRequest } from 
'../../../_network/openstack_request';
import { projectsUrl as projectsUrlResponses } from 
'../../../_api_responses/openstack/identity/projects/v3';
import BillingAccountingTable from './billingAccountingTable';
import CustomTableSkeleton from '../../_common/CustomTableSkeleton';

const IDENTITY_SERVICE_NAME = openStackServices.identityService
let CURRENT_DATE = new Date()
CURRENT_DATE.setDate(1)

const BillingAccounting = (props) => {
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
    const [projects, setProjects] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [billingConfigs, setBillingConfigs] = useState({})
    const [accountingStartDate, setAccountingStartDate] = useState(new Date(CURRENT_DATE))
    const [accountingEndDate, setAccountingEndDate] = useState(new Date())
    const [accountingData, setAccountingData] = useState([])

    const navigate = useNavigate();

    if (!isAuthenticated) {
        navigate('/');
    }

    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)

    
    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({url:url, method:method})
            if (projects_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                setProjects([...projects_response.data.projects])
            }
        })();
    },[
        identityServiceDomain, 
        identityServiceVersion, 
        projectsUrl
    ]);

    useEffect(() => {
        const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingConfigsEP}`
        let request_data = {
            url: url,
            method: "GET"
        }
        billingRequest(request_data).then((response) => {
            if (response.status_code === 200) {
                setBillingConfigs(response.data)
            }
        }).catch((err) => {
            console.log(err)
    })
        
    },[]);

    useEffect(() => {
        handleLoading(true)
        const start_date_param = `${accountingStartDate.getFullYear()}-${accountingStartDate.getMonth()+1}-${accountingStartDate.getDate()} 00:00:00`
        const end_date_param = `${accountingEndDate.getFullYear()}-${accountingEndDate.getMonth()+1}-${accountingEndDate.getDate()} 23:59:59`
        let query_params = `?start_time=${encodeURIComponent(start_date_param)}&end_time=${encodeURIComponent(end_date_param)}`
        const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingServiceChargesEP}${query_params}`
        let request_data = {
            url: url,
            method: "GET"
        }
        billingRequest(request_data).then((response) => {
            if (response.status_code === 200) {
                setAccountingData(response.data)
            }
            setTimeout(() => handleLoading(false),0)
        }).catch((err) => {
            setTimeout(() => handleLoading(false),0)
            console.log(err)
        })
    },[
        accountingStartDate,
        accountingEndDate
    ]);

    return (
        <Box>
            <Grid 
                container 
                sx={{mt: 1}} 
                spacing={1} 
            >
                <Grid item>
                    <DatePicker 
                        value={accountingStartDate}
                        onChange={(newValue) => setAccountingStartDate(newValue)}
                        maxDate={accountingEndDate}
                    />
                </Grid>
                <Grid item>
                    <DatePicker 
                        value={accountingEndDate}
                        onChange={(newValue) => setAccountingEndDate(newValue)}
                        minDate={accountingStartDate}
                    />
                </Grid>
            </Grid>
            {isLoading ? 
                <CustomTableSkeleton sx={{mt: 1}}/>
                :
                <BillingAccountingTable 
                    accountingData={accountingData}
                    projects={projects}
                    billingConfigs={billingConfigs}
                />
            }
        </Box>
    )
};

export default BillingAccounting