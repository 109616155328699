import React from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import WrapperBox from '../../../../_common/WrapperBox';
import Constants from '../../../../../config/constants';
import FilterButton from '../../../../_common/FilterButton';
import Grid from '@mui/material/Grid'

const DefaultVolumeTypesSubheaderV3 = (props) => {
    const { currentTab, handleTabChange } = props
    const { selectedFilter, setSelectedFilter } = props
    const { selectedFilterValue, setSelectedFilterValue } = props
    const { filterMenu } = props
    const { handleFilteredSearch, handleFilterReset } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)

    const theme = useTheme();

    return (
        <WrapperBox 
            sx={{
                flexDirection: "row", 
                justifyContent: 'space-between',
                mt: 1
            }}>
            <Grid container justifyContent='space-between' alignItems="center">
                <Grid item sx={{flexGrow: 1}}>
            <Button
                onClick={() => handleTabChange("volume_types")}
                sx={{
                    color: currentTab === "volume_types" ? 
                    "lightBlue" : 
                    theme.palette.text.primary,
                    "&:hover": {
                        backgroundColor: "lightBlue",
                        color: "white"
                    }
                }}
                size="small"
            >
                {defaultTexts.volumeTypesTabButtonText}
            </Button>
            <Button
                onClick={() => handleTabChange("qos_specs")}
                sx={{
                    color: currentTab === "qos_specs" ? 
                    "lightBlue" : 
                    theme.palette.text.primary,
                    "&:hover": {
                        backgroundColor: "lightBlue",
                        color: "white"
                    },
                }}
                size="small"
            >
                {defaultTexts.qosSpecsTabButtonText}
            </Button>
            <Button
                onClick={() => handleTabChange("default_vt")}
                sx={{
                    color: currentTab === "default_vt" ? 
                    "lightBlue" : 
                    theme.palette.text.primary,
                    "&:hover": {
                        backgroundColor: "lightBlue",
                        color: "white"
                    }
                }}
                size="small"
            >
                {defaultTexts.defaultVolumeTypesTabButtonText}
            </Button>
            </Grid>
            <Grid item>
            <FilterButton 
                choice_mode={Constants.simple_filter_type}
                currentFilter={selectedFilter}
                setCurrentFilter={setSelectedFilter}
                filter_menu_titles={defaultTexts}
                filter_menu={filterMenu}
                currentFilterValue={selectedFilterValue}
                setCurrentFilterValue={setSelectedFilterValue}
                onFilterSubmit={handleFilteredSearch}
                onFilterReset={handleFilterReset}
            />
            </Grid>
            </Grid>
        </WrapperBox>
    )
};

export default DefaultVolumeTypesSubheaderV3