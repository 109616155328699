import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import WrapperBox from '../../../../_common/WrapperBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOffIcon from '@mui/icons-material/EditOff';
import Paper from '@mui/material/Paper';
import useWindowDimensions from 
'../../../../_common/WindowDimensions';
import Constants from '../../../../../config/constants';
import { IconButton }  from '@mui/material';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { 
    volumeCinderRequest, 
    getXAuthTokenProjectScope
 } from 
'../../../../../_network/openstack_request';
import { volumesUrl as volumesUrlResponses } from 
'../../../../../_api_responses/openstack/cinder/volumes/v3';
import { 
    openStackServices, 
    blockStorageCinderConstants
} from 
'../../../../../config/openStackConstants';
import CustomDialog from 
'../../../../_common/CustomDialog';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import { FormGroup } from '@mui/material';
import { 
    volumeTypeDataForm, 
    volumeTypeDataSchema,
    volumeTypeEncryptionDataSchema,
    volumeTypeEncryptionDataUpdateForm
} from '../../../../../_data/openstack/cinder/volume_types/v3';
import { getDetailDataComponent } from 
'../../../../_common/common_helpers';
import ServiceContentHeader from '../../../../_common/ServiceContentHeader';
import CustomCard from '../../../../_common/CustomCard';
import { Divider } from '@mui/material';
import CustomSelectField from 
'../../../../_common/_form_fields/CustomSelectField';
import PlusButton from '../../../../_common/PlusButton';
import MinusButton from '../../../../_common/MinusButton';
import CustomTextField from '../../../../_common/_form_fields/CustomTextField';
import CloseIcon from '@mui/icons-material/Close';
import CustomCheckboxField from '../../../../_common/_form_fields/CustomCheckboxField';

const SERVICE_NAME = openStackServices.volumeService
const FOOTER_HEIGHT = Constants.actions_bar_height + 20

const VolumeTypeDetailV3 = (props) => {
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const { selectedRow, selectedVolumeType } = props
    const { widthWeight } = props
    const { width } = useWindowDimensions();
    const FOOTER_WIDTH = width - (width * widthWeight)
    const { selectedVTQoSSpecs } = props
    const { handleNavigateToQoSSpecs } = props
    const { projects } = props
    const { 
        handleFetchData,
        handleEncryptionCreate,
        handleVolumeTypeDelete
    } = props
    
    const [editMode, setEditMode] = useState(false);
    const [updateExtraSpecsMode, setUpdateExtraSpecsMode] = useState(false)
    
    const [error, setError] = useState()
    const [successVolumeTypeUpdate, setSuccessVolumeTypeUpdate] = useState()
    const [successUpdateDialogOpen, setSuccessUpdateDialogOpen] = useState(false);
    
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

    const [updateFormData, setUpdateFormData] = useState(
        {...selectedRow}
    )
    const [volumeTypeActions, setVolumeTypeActions] = useState([]);
    const [currentAction, setCurrentAction] = useState("");
    const [selectedVTEncryption, setSelectedVTEncryption] = useState({});
    const [privateVolumeAccess, setPrivateVolumeAccess] = useState([])
    const [accessProjectList, setAccessProjectList] = useState([])
    const [privateAccessUpdateMode, setPrivateAccessUpdateMode] = useState(false);
    const [selectedPrivateAccessProjects, setSelectedPrivateAccessProjects] = useState([]);

    const [encryptionFormData, setEncryptionFormData] = useState({...selectedVTEncryption})
    const [updateEncryptionDialogOpen, setUpdateEncryptionDialogOpen] = useState(false);
    const [formExtraFields, setFormExtraFields] = useState([]);

    const [volumeTypeSubMenu, setVolumeTypeSubMenu] = useState(selectedRow.is_public ? [
        {keyword: "submenuDetails", navigation: "/vt-details", is_active: true},
        {keyword: "submenuExtraSpecs", navigation: "/vt-extra-specs", is_active: false},
        {keyword: "submenuQoSSpecs", navigation: "/vt-qos-specs", is_active: false},
        {keyword: "submenuEncryption", navigation: "/vt-encryption", is_active: false}
    ] :
    [
        {keyword: "submenuDetails", navigation: "/vt-details", is_active: true},
        {keyword: "submenuExtraSpecs", navigation: "/vt-extra-specs", is_active: false},
        {keyword: "submenuQoSSpecs", navigation: "/vt-qos-specs", is_active: false},
        {keyword: "submenuEncryption", navigation: "/vt-encryption", is_active: false},
        {keyword: "submenuAccess", navigation: "/vt-access", is_active: false}
    ]
    )
    
    const [currentTab, setCurrentTab] = useState("/vt-details")

    const cinderServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const cinderServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const volumeTypesUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.volumeTypesUrl)[0].url)
    
    const handleFormDataChange = (event,field_key) => {
        let new_form_data = {...updateFormData}
        if (volumeTypeDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (volumeTypeDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setUpdateFormData(new_form_data)
    }

    const handlePrivateAccessUpdateModeReset = () => {
        const selected_projects = accessProjectList.map(i => i.project_id)
        setSelectedPrivateAccessProjects(selected_projects)
        setPrivateAccessUpdateMode(false)
    }

    const getEmptyListNoteComponent = (text) => {
        return (
            <WrapperBox>
                <CustomCard 
                    overflow="hidden" 
                    cardHeight={220}
                    cardWidth={300}
                    card_sx={{
                        mt: 15, 
                        opacity: 0.5, 
                        borderRadius: 50,
                        
                    }}
                    box_sx={{
                        display: "flex", 
                        alignItems: "center", 
                        justifyContent: "center",
                        overflow: "hidden",
                        right: 0
                    }}
                >
                    <CustomText 
                        size="h5" 
                        sx={{textAlign: "center"}}
                    >
                        {text}
                    </CustomText>
                </CustomCard>
            </WrapperBox>
        )
    }

    const getDataForm = () => {
        return (
            <FormGroup>
                {volumeTypeDataForm.map(field => {
                    let form_field_options = {}
                return (
                    getFormFieldComponent(
                        field,
                        updateFormData,
                        handleFormDataChange,
                        defaultTexts[field.label],
                        {
                            size:"medium",
                            withHelp: field.with_help_text,
                            helpText: defaultTexts[field.help_text],
                            sx: {
                                my: 1, 
                                width: width * widthWeight * 0.8
                            },
                        ...form_field_options
                        }
                    )
                )
            })}
        </FormGroup>)
    }

    const handleEditModeChange = () => {
        handleVolumeTypeDetailTabChange("/vt-details")
        setEditMode(true)
    }

    const handleEditModeReset = () => {
        setUpdateFormData({...selectedRow})
        handleVolumeTypeDetailTabChange("/vt-details")
        setEditMode(false)
    }
    
    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }
    
    const handleSuccessUpdateDialogClose = () => {
        setSuccessVolumeTypeUpdate(null)
        setUpdateFormData({...selectedRow})
        setSuccessUpdateDialogOpen(false);
    }
    const handleConfirmDeleteDialogOpen = () => {
        handleVolumeTypeDelete([selectedRow.id])
    }
    const handleConfirmDeleteDialogClose = () => {
        setConfirmDeleteDialogOpen(false)
    }

    const handleCreateEncryptionDialogOpen = useCallback(() => {
        setCurrentAction("")
        handleEncryptionCreate([selectedRow.id])
    },[
        handleEncryptionCreate,
        selectedRow
    ])

    const dataFormatting = (data) => {
        let update_data = {}
        for (let i in volumeTypeDataForm) {
            if (volumeTypeDataForm[i].field_key !== "os-volume-type-access:is_public") {
                update_data[volumeTypeDataForm[i].field_key] = data[volumeTypeDataForm[i].field_key]
            } else {
                update_data["is_public"] = data["os-volume-type-access:is_public"]
            }
        }
        return update_data
    }

    const handleEncryptionUpdate = async () => {
        handleUpdateEncryptionDialogClose()
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}/${selectedRow.id}/encryption/${selectedVTEncryption.encryption_id}`
                const method = "PUT"
                
                const vt_response = await volumeCinderRequest({
                    url: url, 
                    method: method,
                    data: {encryption: encryptionFormData},
                    token: project_token
                })
        
                if (vt_response.status_code === volumesUrlResponses.put.success_response.status_code) {
                    handleFetchData()
                    return true
                } else {
                    return false
                }
        }
    };

    const handleEditVolumeType = async () => {
        let updated_data = {...updateFormData}
        updated_data = dataFormatting(updated_data)
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}/${selectedRow.id}`
            const method = "PUT"

            const vt_response = await volumeCinderRequest({
                url: url, 
                method: method, 
                data: {volume_type: updated_data},
                token: project_token
            })
            if (vt_response.status_code === volumesUrlResponses.put.success_response.status_code) {
                handleFetchData()
                handleEditModeReset()
                setSuccessVolumeTypeUpdate({
                    success_title: volumesUrlResponses.put.success_response.response_title, 
                    success_message: volumesUrlResponses.put.success_response.response_message
                })
            } else {
                const error_response = volumesUrlResponses.put.error_response.filter(
                    error_item => error_item.status_code === vt_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: vt_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = volumesUrlResponses.put.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: vt_response.error
                    }
                    setError(errorObject)
                }
            }
        }
    }

    const handleEncryptionDelete = useCallback(async () => {
        setCurrentAction("")
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}/${selectedRow.id}/encryption/${selectedVTEncryption.encryption_id}`
            const method = "DELETE"
            
            const vt_response = await volumeCinderRequest({
                url:url, 
                method:method,
                token: project_token
            })

            if (vt_response.status_code === volumesUrlResponses.delete.success_response.status_code) {
                handleFetchData()
                return true
            } else {
                return false
            }
        }
    },[
        cinderServiceDomain,
        cinderServiceVersion,
        volumeTypesUrl,
        defaultAdminProject,
        selectedVTEncryption,
        handleFetchData,
        selectedRow
    ]);

    const formatSpecsData = () => {
        let new_data = {}
        for (let i in formExtraFields) {
            new_data[formExtraFields[i].field_key] = formExtraFields[i].field_value
        }
        const keys_to_delete = Object.keys(selectedRow.extra_specs).filter(key => !Object.keys(new_data).includes(key))
        return {keys_to_add: new_data, keys_to_delete: keys_to_delete}
    }

    const handleUpdateExtraSpecs = async () => {
        const data = formatSpecsData()
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}/${selectedRow.id}/extra_specs`
            let method = "POST"

            const response = await volumeCinderRequest({
                url: url, 
                method: method, 
                data: {extra_specs: data.keys_to_add},
                token: project_token
            })
            for (let d in data.keys_to_delete) {
                url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}/${selectedRow.id}/extra_specs/${data.keys_to_delete[d]}`
                method = "DELETE"

                await volumeCinderRequest({
                    url: url, 
                    method: method, 
                    token: project_token
                })
            }
            if (response.status_code === volumesUrlResponses.post.success_response.status_code) {
                setCurrentAction("")
                handleFetchData()
                handleAddExtraSpecsModeReset()
            }
        }
    }

    const handleUpdatePrivateAccess = async () => {
        const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
        if (project_token) {
            const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}/${selectedRow.id}/action`
            const method = "POST"

            for (let p in selectedPrivateAccessProjects) {
                try {
                    await volumeCinderRequest({
                        url: url, 
                        method: method, 
                        data: {addProjectAccess: {project: selectedPrivateAccessProjects[p]}},
                        token: project_token
                    })
                } catch {

                }
                
            }
            
            const projects_to_delete = accessProjectList.filter(p => !selectedPrivateAccessProjects.includes(p.project_id))
            for (let d in projects_to_delete) {
                try {
                    await volumeCinderRequest({
                        url: url, 
                        method: method, 
                        data: {removeProjectAccess: {project: projects_to_delete[d].project_id}},
                        token: project_token
                    })
                } catch {

                }
                
            }
            setCurrentAction("")
            handleFetchData()
            handlePrivateAccessUpdateModeReset()
            
        }
    }

    const handleVolumeTypeDetailTabChange = useCallback((navigation) => {
        let newVTSubmenuData = volumeTypeSubMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
            } else {
                item.is_active = false
            }
            return item
        })
        setVolumeTypeSubMenu(newVTSubmenuData)
        setCurrentTab(navigation)
    },[
        volumeTypeSubMenu,
        setVolumeTypeSubMenu
    ])

    const handlePrivateAccessUpdateModeSet = useCallback(() => {
        handleVolumeTypeDetailTabChange("/vt-access")
        setPrivateAccessUpdateMode(true)
    },[handleVolumeTypeDetailTabChange])
    
    const handleUpdateExtraSpecsModeChange = useCallback(() => {
        handleVolumeTypeDetailTabChange("/vt-extra-specs")
        setUpdateExtraSpecsMode(true)
    },[handleVolumeTypeDetailTabChange])


    const handleFormExtraFieldsChange = (event,field_key_list) => {
        let new_extra_data = [...formExtraFields]
        if (field_key_list[1] === "key") {
            new_extra_data[field_key_list[0]].field_key = event.target.value
        } else {
            new_extra_data[field_key_list[0]].field_value = event.target.value
        }
        setFormExtraFields(new_extra_data)
    }

    const handleFormExtraFieldsRemove = (index) => {
        if (formExtraFields.length > 1) {
            let updated_data = [...formExtraFields]
            updated_data.splice(index, 1)
            setFormExtraFields(updated_data)
        } else {
            setFormExtraFields([])
        }
    }

    const handleAddExtraSpecsModeReset = () => {
        if (Object.keys(selectedRow.extra_specs).length > 0) {
            const extra_specs_form = Object.keys(selectedRow.extra_specs).map(key => {
                let new_item = {}
                new_item["field_key"] = key
                new_item["field_value"] = selectedRow.extra_specs[key]
                return new_item
            })
            setFormExtraFields(extra_specs_form)
        }
        setUpdateExtraSpecsMode(false)
    }

    const getExtraSpecsFormComponents = () => {
        return (
            <WrapperBox
                sx={{
                    m: 2, 
                    alignItems: 'start'
                }}
            >
                <IconButton
                    onClick={handleAddExtraSpecsModeReset}
                    sx={{
                    position: 'absolute',
                    right: 8,
                    top: 130,
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <Stack 
                    direction="row" 
                    spacing={2} 
                    alignItems="center"
                    sx={{my: 3}}
                >
                    <CustomText size="h6">
                        {defaultTexts.extraFormFieldLabel}
                    </CustomText>
                    <PlusButton 
                        onClick={() => setFormExtraFields([
                            ...formExtraFields,
                            {field_key: "",field_value: ""}
                        ])} 
                    />
                </Stack>
                {formExtraFields.map((item,index) => {
                    return (
                        <Stack
                            key={index}
                            direction="row" 
                            spacing={2} 
                            alignItems="center"
                            justifyContent="space-between"
                            sx={{my: 1}}
                        >
                            <CustomTextField
                                currentValue={item.field_key} 
                                setCurrentValue={handleFormExtraFieldsChange}
                                field_key={[index,"key"]}
                                label={defaultTexts.keyFormFieldLabel}
                            />
                            <CustomTextField
                                currentValue={item.field_value} 
                                setCurrentValue={handleFormExtraFieldsChange}
                                field_key={[index,"value"]}
                                label={defaultTexts.valueFormFieldLabel}
                            />
                            <MinusButton 
                                sx={{width: 90}} 
                                onClick={() => handleFormExtraFieldsRemove(index)}
                            />
                        </Stack>
                    )
                })}
                <Button 
                    variant="contained"
                    color="secondary"
                    sx={{mt: 5}}
                    onClick={handleUpdateExtraSpecs}
                >
                    {defaultTexts.saveButtonText}
                </Button>
            </WrapperBox>
        )
    }

    const handleEncryptionUpdateFormDataChange = (event,field_key) => {
        let new_form_data = {...encryptionFormData}
        if (volumeTypeEncryptionDataUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (volumeTypeEncryptionDataUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setEncryptionFormData(new_form_data)
    }

    const handleUpdateEncryptionDialogOpen = () => {
        setUpdateEncryptionDialogOpen(true)
    }

    const handleUpdateEncryptionDialogClose = () => {
        setCurrentAction("")
        setUpdateEncryptionDialogOpen(false)
    }

    const handleSelectPrivateAccessProjects = (event,field_key) => {
        if (event.target.checked) {
            const new_list = [...selectedPrivateAccessProjects, projects[field_key].id]
            setSelectedPrivateAccessProjects(new_list)
        } else {
            const new_list = selectedPrivateAccessProjects.filter(p => p !== projects[field_key].id)
            setSelectedPrivateAccessProjects(new_list)
        }
    }

    const getEncryptionDataForm = () => {
        return (
            <FormGroup>
                {volumeTypeEncryptionDataUpdateForm.map(field => {
                    let form_field_options = {}
                    if (field.field_key === "provider" || field.field_key === "control_location") {
                        form_field_options["items"] = [...field.items]
                        form_field_options["empty"] = field.default_empty
                        form_field_options["self_item_titles"] = field.self_item_titles
                        form_field_options["default_value"] = field.default_value
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            encryptionFormData,
                            handleEncryptionUpdateFormDataChange,
                            defaultTexts[field.label],
                            {
                                ...form_field_options,
                                item_titles: defaultTexts
                            }
                        )
                    )
                })}
            </FormGroup>
        )
    }

    useEffect(() => {
        if (Object.keys(selectedRow.extra_specs).length > 0) {
            const extra_specs_form = Object.keys(selectedRow.extra_specs).map(key => {
                let new_item = {}
                new_item["field_key"] = key
                new_item["field_value"] = selectedRow.extra_specs[key]
                return new_item
            })
            setFormExtraFields(extra_specs_form)
        }
    },[
        selectedRow
    ]);

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}/${selectedRow.id}/encryption`
                const method = "GET"

                const encr_response = await volumeCinderRequest({
                    url: url, 
                    method: method, 
                    token: project_token
                })
                if (encr_response.status_code === volumesUrlResponses.get.success_response.status_code) {
                    setSelectedVTEncryption(encr_response.data)
                }
            }
        })()
    },[
        cinderServiceDomain,
        cinderServiceVersion,
        defaultAdminProject,
        volumeTypesUrl,
        selectedRow
    ]);

    useEffect(() => {
        if (!selectedRow.is_public) {
            (async () => {
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    const url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumeTypesUrl}/${selectedRow.id}/os-volume-type-access`
                    const method = "GET"

                    const encr_response = await volumeCinderRequest({
                        url: url, 
                        method: method, 
                        token: project_token
                    })
                    if (encr_response.status_code === volumesUrlResponses.get.success_response.status_code) {
                        setPrivateVolumeAccess(encr_response.data.volume_type_access)
                    }
                }
            })()
        }
    },[
        cinderServiceDomain,
        cinderServiceVersion,
        defaultAdminProject,
        volumeTypesUrl,
        selectedRow
    ]);

    useEffect(() => {
        if (privateVolumeAccess.length > 0) {
            const private_projects_access = privateVolumeAccess.map(item => {
                let new_item = {...item}
                const project = projects.filter(p => p.id === item.project_id)
                if (project.length > 0) {
                    new_item.project_name = project[0].name
                }
                return new_item
            })
            setAccessProjectList(private_projects_access)
        } else {
            setAccessProjectList([])
        }
    },[
        privateVolumeAccess,
        selectedRow,
        projects
    ]);

    useEffect(() => {
        if (Object.keys(selectedVTEncryption).length > 0) {
            setEncryptionFormData({...selectedVTEncryption})
        }
    },[
        selectedVTEncryption
    ]);

    useEffect(() => {
        if (accessProjectList.length > 0) {
            const selected_projects = accessProjectList.map(i => i.project_id)
            setSelectedPrivateAccessProjects(selected_projects)
        } else {
            setSelectedPrivateAccessProjects([])
        }
    },[
        accessProjectList
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessUpdateDialogOpen(true)
    },[successVolumeTypeUpdate]);

    useEffect(() => {
        if (selectedRow !== null) {
            let vt_actions = []
            let new_action = {}
            if (Object.keys(selectedVTEncryption).length > 0) {
                new_action["value"] = "encryption_update"
                new_action["action"] = handleUpdateEncryptionDialogOpen
                new_action["keyword"] = "encryptionUpdateActionTitle"
                new_action["button_text"] = "applyButtonTitleText"
                vt_actions.push({...new_action})
                new_action["value"] = "encryption_delete"
                new_action["action"] = handleEncryptionDelete
                new_action["keyword"] = "encryptionDeleteActionTitle"
                new_action["button_text"] = "applyButtonTitleText"
                vt_actions.push({...new_action})
            } else {
                new_action["value"] = "encryption_create"
                new_action["action"] = handleCreateEncryptionDialogOpen
                new_action["keyword"] = "encryptionCreateActionTitle"
                new_action["button_text"] = "applyButtonTitleText"
                vt_actions.push({...new_action})
            }
            new_action = {}
            new_action["value"] = "update_extra_specs"
            new_action["action"] = handleUpdateExtraSpecsModeChange
            new_action["keyword"] = "updateExtraSpecsActionTitle"
            new_action["button_text"] = "selectButtonTitleText"
            vt_actions.push({...new_action})
            if (!selectedRow.is_public) {
                new_action = {}
                new_action["value"] = "update_access"
                new_action["action"] = handlePrivateAccessUpdateModeSet
                new_action["keyword"] = "updateVTAccessActionTitle"
                new_action["button_text"] = "selectButtonTitleText"
                vt_actions.push({...new_action})
            }
            setVolumeTypeActions(vt_actions)
        }
    },[
        selectedRow,
        defaultTexts,
        selectedVTEncryption,
        handleCreateEncryptionDialogOpen,
        handleEncryptionDelete,
        handleUpdateExtraSpecsModeChange,
        handlePrivateAccessUpdateModeSet
    ]);

    return (
        <React.Fragment>
        {selectedRow !== null && 
            <WrapperBox>
                <ServiceContentHeader 
                    service_menu={volumeTypeSubMenu}
                    service_menu_titles={defaultTexts}
                    onClick={handleVolumeTypeDetailTabChange}
                />
            </WrapperBox>}
            {currentTab === "/vt-details" &&
                <React.Fragment>
                    {!editMode ? 
                    <Stack 
                        spacing={2} 
                        sx={{ p: 2, mt: 1, flexWrap: "wrap"}}
                    >
                        {volumeTypeDataSchema.map((field) => {
                            let new_field = {...field}
                            let value = selectedVolumeType ? 
                            selectedVolumeType[field.field_key] : ""
                            if (field.field_key === "qos_specs_id") {
                                new_field["onClick"] = () => handleNavigateToQoSSpecs(selectedRow.qos_specs_id)
                                new_field["text_color"] = "primary.main"
                            }
                            return (
                                getDetailDataComponent({
                                    fieldType: new_field.field_type,
                                    fieldKey: new_field.field_key,
                                    label: defaultTexts[new_field.label],
                                    value: value,
                                    textOnTrue: defaultTexts[new_field.value_on_true] ? 
                                    defaultTexts[new_field.value_on_true] : 
                                    defaultTexts.formValueYes,
                                    textOnFalse: defaultTexts[new_field.value_on_false] ? 
                                    defaultTexts[new_field.value_on_false] : 
                                    defaultTexts.formValueNo,
                                    defaultTexts: defaultTexts,
                                    textColor: new_field.text_color,
                                    onClick: new_field.onClick
                                })
                            )
                        })}
                        </Stack> : 
                        <Stack 
                            sx={{
                                m: 2, 
                                alignItems: 'start'
                            }}>
                        <CustomText 
                            size="h6" 
                            sx={{
                                color: "primary.main", 
                                mb: 2
                            }}>
                            {defaultTexts.updateVolumeTypeFormTitle}
                        </CustomText>
                        {getDataForm()}
                        <Button 
                            variant="contained"
                            color="secondary"
                            sx={{mt: 5}}
                            onClick={handleEditVolumeType}
                        >
                            {defaultTexts.saveButtonText}
                        </Button>
                    </Stack>}
                </React.Fragment>}
                {currentTab === "/vt-extra-specs" && 
                !updateExtraSpecsMode &&  
                    <div>
                        {Object.keys(selectedRow.extra_specs).length > 0 ? 
                            <Stack 
                                spacing={2} 
                                sx={{ p: 2, mt: 1}}
                            >
                                {Object.keys(selectedRow.extra_specs).map(key => {
                                    return (
                                        <Stack key={key} spacing={1} >
                                            <Stack 
                                                direction="row" 
                                                justifyContent="space-between" 
                                                alignItems="center"
                                            >
                                                <CustomText>{key}</CustomText>
                                                <CustomText>{selectedRow.extra_specs[key]}</CustomText>
                                            </Stack>
                                            <Divider />
                                        </Stack>
                                    )
                                    
                                })}
                            </Stack> : 
                            getEmptyListNoteComponent(defaultTexts.noExtraSpecsNoteText)}
                    </div>
                }
                {currentTab === "/vt-extra-specs" && 
                    updateExtraSpecsMode &&  
                    getExtraSpecsFormComponents()
                }
                {currentTab === "/vt-qos-specs" && 
                    <div>
                        {selectedRow.qos_specs_id ?
                        <Stack 
                            spacing={2} 
                            sx={{ p: 2, mt: 1}}
                        >
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                                sx={{
                                    "&:hover": {cursor: "pointer"}
                                }}
                                onClick={() => handleNavigateToQoSSpecs(selectedVTQoSSpecs.id)}
                            >
                                <CustomText sx={{color: "text.secondary"}}>{defaultTexts.idFormFieldLabel}</CustomText>
                                <CustomText sx={{color: "primary.main", textDecoration: "underline"}}>{selectedVTQoSSpecs.id}</CustomText>
                            </Stack>
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText sx={{color: "text.secondary"}}>{defaultTexts.nameFormFieldLabel}</CustomText>
                                <CustomText>{selectedVTQoSSpecs.name}</CustomText>
                            </Stack>
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText sx={{color: "text.secondary"}}>{defaultTexts.consumerFormFieldLabel}</CustomText>
                                <CustomText>{selectedVTQoSSpecs.consumer}</CustomText>
                            </Stack>
                            <CustomText sx={{color: "text.secondary"}}>{defaultTexts.specsFormFieldLabel}:</CustomText>
                           
                            {Object.keys(selectedVTQoSSpecs.specs).map(key => {
                                return (
                                    <Stack key={key} spacing={1} >
                                        <Stack 
                                            direction="row" 
                                            justifyContent="space-between" 
                                            alignItems="center"
                                        >
                                            <CustomText>{key}</CustomText>
                                            <CustomText>{selectedVTQoSSpecs.specs[key]}</CustomText>
                                        </Stack>
                                        <Divider />
                                    </Stack>
                                )
                            })}
                        </Stack> :
                        getEmptyListNoteComponent(defaultTexts.noQoSSpecsAssociationNoteText)}
                    </div>
                } 
                {currentTab === "/vt-encryption" && <div>
                    {Object.keys(selectedVTEncryption).length > 0 ?
                    <Stack 
                        spacing={2} 
                        sx={{ p: 2, mt: 1, flexWrap: "wrap"}}
                    >
                        {volumeTypeEncryptionDataSchema.map((field) => {
                            let value = selectedVTEncryption[field.field_key]
                            return (
                                getDetailDataComponent({
                                    fieldType: field.field_type,
                                    fieldKey: field.field_key,
                                    label: defaultTexts[field.label],
                                    value: value,
                                    textOnTrue: defaultTexts[field.value_on_true] ? 
                                    defaultTexts[field.value_on_true] : 
                                    defaultTexts.formValueYes,
                                    textOnFalse: defaultTexts[field.value_on_false] ? 
                                    defaultTexts[field.value_on_false] : 
                                    defaultTexts.formValueNo,
                                    defaultTexts: defaultTexts
                                })
                            )
                        })}
                    </Stack>
                    :
                    getEmptyListNoteComponent(defaultTexts.noEncryptionNoteText)}
                    </div>
                }
                {currentTab === "/vt-access" && !privateAccessUpdateMode && <div>
                    {accessProjectList.length > 0 ?
                    accessProjectList.map(item => {
                        return (
                            <Stack key={item.project_id} spacing={1} sx={{mt: 2}}>
                                <CustomText>{item.project_name ? item.project_name : item.project_id}</CustomText>
                                <Divider />
                            </Stack>
                        )
                    })
                    :
                    getEmptyListNoteComponent(defaultTexts.noProjectAccessNoteText)}
                    </div>
                }
                {currentTab === "/vt-access" && privateAccessUpdateMode &&
                    <WrapperBox
                        sx={{
                            m: 2, 
                            alignItems: 'start'
                        }}
                    >
                        <IconButton
                            onClick={handlePrivateAccessUpdateModeReset}
                            sx={{
                            position: 'absolute',
                            right: 8,
                            top: 130,
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <FormGroup>
                            {projects.map((p,i) => {
                                return (
                                    <CustomCheckboxField 
                                        key={p.id}
                                        currentValue={selectedPrivateAccessProjects.includes(p.id)}
                                        setCurrentValue={handleSelectPrivateAccessProjects}
                                        label={p.name}
                                        field_key={i}
                                    />
                                )
                            })}
                        </FormGroup>
                        <Button 
                            variant="contained"
                            color="secondary"
                            sx={{mt: 3}}
                            onClick={handleUpdatePrivateAccess}
                        >
                            {defaultTexts.saveButtonText}
                        </Button>
                    </WrapperBox>
                }
            <Paper sx={{ 
                position: 'fixed', 
                bottom: 0, 
                height: FOOTER_HEIGHT,
                left: FOOTER_WIDTH, 
                right: 12,
                zIndex: 3000
                }} 
                elevation={24}
                square={true}
            >
                <Grid 
                    container 
                    alignItems="center"  
                    justifyContent="space-between"
                >
                    <Grid item>
                        <CustomSelectField 
                            items={volumeTypeActions} 
                            currentValue={currentAction}
                            setCurrentValue={setCurrentAction}
                            item_titles={defaultTexts}
                            label={defaultTexts.actionsDropdownLabelText}
                            empty={true}
                            size="small"
                            sx={{m: 1}}
                        />
                        {currentAction.length > 0 && 
                            <Button 
                                    variant="contained"
                                    color="secondary"
                                    sx={{m: 1, height: '70%'}}
                                    onClick={volumeTypeActions.filter(
                                        action => action.value === currentAction)[0].action
                                    }
                                >
                                {defaultTexts[volumeTypeActions.filter(
                                    action => action.value === currentAction)[0].button_text]}
                            </Button>
                        }
                    </Grid>
                    <Grid item>
                        {!editMode ? <IconButton onClick={handleEditModeChange}>
                                <EditIcon color="primary" />
                            </IconButton> :
                            <IconButton onClick={handleEditModeReset}>
                                <EditOffIcon color="primary"/>
                            </IconButton>
                        }
                        {selectedRow !== null && 
                            <IconButton onClick={handleConfirmDeleteDialogOpen}>
                                <DeleteIcon 
                                    color="primary"
                                />
                            </IconButton>}
                    </Grid>
                </Grid>
            </Paper> 
            <CustomDialog
                open={updateEncryptionDialogOpen}
                onClose={handleUpdateEncryptionDialogClose}
                dialogTitle={{
                    title: defaultTexts.encryptionUpdateDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: handleEncryptionUpdate, 
                    sx: {color: 'primary.main'}}]}
            >
                {getEncryptionDataForm()}
            </CustomDialog>
            {successVolumeTypeUpdate && <CustomDialog
                open={successUpdateDialogOpen}
                onClose={handleSuccessUpdateDialogClose}
                dialogTitle={{
                    title: defaultTexts[successVolumeTypeUpdate.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: successVolumeTypeUpdate.success_details ?
                        `<span>${defaultTexts[successVolumeTypeUpdate.success_message]}</span>
                            <br>
                            <br>
                            <span>Secret Key:</span> 
                            <span style="width: 100px; color: orange; word-wrap: break-word;">
                                ${successVolumeTypeUpdate.success_details}
                            </span>` :
                        `<span>${defaultTexts[successVolumeTypeUpdate.success_message]}</span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            {confirmDeleteDialogOpen && <CustomDialog
                open={confirmDeleteDialogOpen}
                onClose={handleConfirmDeleteDialogClose}
                dialogTitle={{
                    title: defaultTexts.deleteConfirmationDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.deleteVTConfirmationDialogMessage, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: () => {}, 
                    sx: {color: 'primary.main'}}]}
            />}
        </React.Fragment>
    )

};

export default VolumeTypeDetailV3;