export const loginFormTexts = {
        welcomeTitle: "Welcome to Universal OpenStack Admin UI",
        missingUsernameError: "Enter valid username!",
        missingPasswordError: "Enter valid password!",
        missingClientAccountIdError: "Enter valid client account ID!",
        clientAccountIdLabel: "Client Account ID",
        usernameLabel: "OpenStack Username",
        passwordLabel: "OpenStack Password",
        domainLabel: "OpenStack Domain Name",
        loginButtonTitle: "Login",
        unauthorizedDialogTitle: "Unauthorized!",
        unauthorizedDialogBody: "Authorization Faild! Try again.",
        auauthorizedDialogButtonTitle: "Close",
        instancesSummaryCardTitle: "Instances",
        noInstancesNoteTitle: "No Instances",
        blockStorageSummaryCardTitle: "Block Storage",
        publicImagesSizeLegentTitle: "Public Images Size",
        privateImagesSizeLegentTitle: "Private Images Size",
        imagesSummaryCardTitle: "Images",
        noImagesNoteTitle: "No Images",
        noStorageObjectsNoteTitle: "No Storage Objects",
        serversNumberPieChartLabel: "Servers Number",
        hypervisorsSummaryCardTitle: "Hypervisors",
        totalRoutersGraphTitle: "Total Routers",
        totalNetworksGraphTitle: "Total Networks",
        totalFloatingIPsGraphTitle: "Total Floating IPs",
        totalSecurityGroupsGraphTitle: "Total Security Groups",
        totalFirewallsGraphTitle: "Total Firewalls",
        totalVPNsGraphTitle: "Total VPNs",
        totalUsersGraphTitle: "Total Users",
        totalDomainsGraphTitle: "Total Domains",
        totalRegionsGraphTitle: "Total Regions",
        totalProjectsGraphTitle: "Total Projects"
};

