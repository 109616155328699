import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ServiceContentHeader from '../../../_common/ServiceContentHeader';
import Box from '@mui/material/Box';
import ComputeFlavorsWrapperV2_1 from './flavors/computeFlavorsWrapperV2_1';
import ComputeKeypairsWrapperV2_1 from './keypairs/computeKeypairsWrapperV2_1';
import ComputeLimitsWrapperV21 from './limits/computeLimitsWrapperV2_1';
import ComputeServersWrapperV21 from './servers/computeServersWrapperV2_1';
import InfrastructureWrapperV21 from './infrastructure/infrastructureWrapperV2.1';

const ComputeNovaContentV2_1 = (props) => {
    const { serverID, setServerID, navigate, location } = props
    const { serviceTitle } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)

    const [serviceMenu, setServiceMenu] = useState([
        {keyword: "submenuServers", navigation: "/servers", is_active: true, component: ComputeServersWrapperV21},
        {keyword: "submenuFlavors", navigation: "/flavors", is_active: false, component: ComputeFlavorsWrapperV2_1},
        {keyword: "submenuKeypairs", navigation: "/keypairs", is_active: false, component: ComputeKeypairsWrapperV2_1},
        {keyword: "submenuLimits", navigation: "/limits", is_active: false, component: ComputeLimitsWrapperV21},
        {keyword: "submenuInfrastructure", navigation: "/infrastructure", is_active: false, component: InfrastructureWrapperV21}
    ])

    const getCurrentTab = () => {
        const currentItem = serviceMenu.filter(item => item.is_active)[0]
        return currentItem
    }

    const [currentTab, setCurrentTab] = useState(getCurrentTab())

    const changeMenuActiveTab = (navigation) => {
        const updated_menu = serviceMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
                setCurrentTab(item)
            } else {
                item.is_active = false
            }
            return item
        })
        setServiceMenu(updated_menu)
    }

    return (
        <Box>
            <ServiceContentHeader 
                service_title={serviceTitle} 
                service_menu={serviceMenu}
                service_menu_titles={defaultTexts}
                onClick={changeMenuActiveTab}
            />
            <currentTab.component 
                serverID={serverID}
                setServerID={setServerID}
                navigate={navigate}
                location={location}
            />
        </Box>
    )
};

export default ComputeNovaContentV2_1;