export const authTokenApiDataV3 = {
    "auth": {
        "identity": {
            "methods": [
                "password"
            ],
            "password": {
                "user": {
                    "name": "{missingUsername}",
                    "domain": {
                        "name": "{missingDomain}"
                    },
                    "password": "{missingPassword}"
                }
            }
        },
        "scope": {
          "system": {
              "all": true
        }
      }
    }
}


export const authTokenProjectScopeApiDataV3 = {
    "auth": {
        "identity": {
            "methods": [
                "password"
            ],
            "password": {
                "user": {
                    "id": "{missingUserID}",
                    "password": "{missingPassword}"
                }
            }
        },
        "scope": {
          "project": {
              "id": "{missingProjectID}"
        }
      }
    }
}