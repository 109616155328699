import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@mui/material';
import CustomText from '../../../../_common/CustomText';
import WrapperBox from '../../../../_common/WrapperBox';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import EditOffIcon from '@mui/icons-material/EditOff';
import Paper from '@mui/material/Paper';
import useWindowDimensions from 
'../../../../_common/WindowDimensions';
import Constants from '../../../../../config/constants';
import { IconButton }  from '@mui/material';
import { Grid }  from '@mui/material';
import { Stack } from '@mui/material';
import { openstackRequest } from 
'../../../../../_network/openstack_request';
import { servicesUrl as servicesUrlResponses } from 
'../../../../../_api_responses/openstack/identity/services_catalog/v3';
import { endpointsUrl as endpointsUrlResponses } from 
'../../../../../_api_responses/openstack/identity/services_catalog/v3';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { identityKeystonConstants } from 
'../../../../../config/openStackConstants';
import CustomDialog from 
'../../../../_common/CustomDialog';
import { getFormFieldComponent } from 
'../../../../_common/_form_fields/form_helpers';
import { FormGroup } from '@mui/material';
import { 
    servicesCatalogDataUpdateForm, 
    servicesCatalogDataSchema,
    endpointDataForm 
} from 
'../../../../../_data/openstack/identity/services_catalog/v3';
import { getDetailDataComponent } from 
'../../../../_common/common_helpers';
import ServiceContentHeader from '../../../../_common/ServiceContentHeader';
import CustomCard from '../../../../_common/CustomCard';
import { Divider } from '@mui/material';
import CustomSelectField from 
'../../../../_common/_form_fields/CustomSelectField';

const SERVICE_NAME = openStackServices.identityService

const IdentityServicesCatalogDetailV3 = (props) => {
    const { selectedRow } = props
    const { servicesCatalogRecords } = props
    const serviceRecord = servicesCatalogRecords.filter(
        service => service.id === selectedRow.id)[0]
    const { widthWeight } = props
    const { 
        handleFetchData, 
        handleDrawerClose
    } = props
    const { width } = useWindowDimensions();
    const [editMode, setEditMode] = useState(false);

    const defaultTexts = useSelector(state => state.texts.langTexts)
    const [error, setError] = useState()
    const [successServiceDelete, setSuccessServiceDelete] = useState()
    const [successServiceUpdate, setSuccessServiceUpdate] = useState()
    const [successEndpointAdd, setSuccessEndpointAdd] = useState()
    const [successEndpointEdit, setSuccessEndpointEdit] = useState()
    const [successEndpointDelete, setSuccessEndpointDelete] = useState()
    
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [successUpdateDialogOpen, setSuccessUpdateDialogOpen] = useState(false);
    
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
    const [confirmDeleteEndpointDialogOpen, setConfirmDeleteEndpointDialogOpen] = useState(false);

    const [updateFormData, setUpdateFormData] = useState(
        {...serviceRecord}
    )

    const [endpointFormData, setEndpointFormData] = useState({})
    const [endpointFormDataOptions, setEndpointFormDataOptions] = useState({});
    const [endpointFetchRequired, setEndpointFetchRequired] = useState(true)

    const [serviceEndpoints, setServiceEndpoints] = useState([])
    const [selectedEndpoint, setSelectedEndpoint] = useState({})
    const [endpointEditMode, setEndpointEditMode] = useState(false)
    const [regions, setRegions] = useState([]);
    
    const [serviceActions, setServiceActions] = useState([]);
    const [currentAction, setCurrentAction] = useState("");

    const [serviceSubMenu, setServiceSubMenu] = useState([
        {keyword: "submenuDetails", navigation: "/service-details", is_active: true},
        {keyword: "submenuEndpoints", navigation: "/service-endpoints", is_active: false}
    ])

    const [currentTab, setCurrentTab] = useState("/service-details")

    const [addEndpointMode, setAddEndpointMode] = useState(false);
    
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const servicesUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.servicesUrl)[0].url)
    const endpointsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.endpointsUrl)[0].url)

    const regionsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.regionsUrl)[0].url)

    const handleAddEndpointDialogClose = () => {
        setAddEndpointMode(false)
    }

    const handleAddEndpointDialogOpen = useCallback(() => {
        setAddEndpointMode(true)
        setCurrentAction("")
    },[])

    const handleEndpointEditDialogOpen = (endpoint) => {
        setSelectedEndpoint(endpoint)
        setEndpointEditMode(true)
    }

    const handleEndpointDeleteDialogOpen = (endpoint) => {
        setSelectedEndpoint(endpoint)
        setConfirmDeleteEndpointDialogOpen(true)
    }

    const handleEndpointEditDialogClose = () => {
        setSelectedEndpoint({})
        setEndpointEditMode(false)
    }

    const handleFormDataChange = (event,field_key) => {
        let new_form_data = {...updateFormData}
        if (servicesCatalogDataUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (servicesCatalogDataUpdateForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setUpdateFormData(new_form_data)
    }

    const handleEndpointFormDataValidation = () => {
        let validation_faild = true
        let updatedDataFormOptions = {...endpointFormDataOptions}
        for (let n in endpointDataForm) {
            if (endpointDataForm[n].required && !endpointFormData[endpointDataForm[n].field_key]) {
                validation_faild = false
                updatedDataFormOptions[endpointDataForm[n].field_key] = {}
                updatedDataFormOptions[endpointDataForm[n].field_key]["error"] = true
                updatedDataFormOptions[endpointDataForm[n].field_key]["errorText"] = defaultTexts[endpointDataForm[n].error_label]
            }
        }
        setEndpointFormDataOptions(updatedDataFormOptions)
        return validation_faild
    }

    const getEmptyListNoteComponent = (text) => {
        return (
            <WrapperBox>
                <CustomCard 
                    overflow="hidden" 
                    cardHeight={220}
                    cardWidth={300}
                    card_sx={{
                        mt: 15, 
                        opacity: 0.5, 
                        borderRadius: 50,
                    }}
                    box_sx={{
                        display: "flex", 
                        alignItems: "center", 
                        justifyContent: "center",
                        overflow: "hidden",
                        right: 0
                    }}
                >
                    <CustomText 
                        size="h5" 
                        sx={{
                            textAlign: "center",
                        }}
                    >
                        {text}
                    </CustomText>
                </CustomCard>
            </WrapperBox>
        )
    }

    const getDataForm = () => {
        return (
            <FormGroup>
                {servicesCatalogDataUpdateForm.map(field => {
                    let field_options = {}
                    if (field.field_key === "type") {
                        field_options["items"] = field.items
                        field_options["on_empty_null"] = field.on_empty_null
                        field_options["self_item_titles"] = field.self_item_titles
                        field_options["default_empty"] = field.default_empty
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            updateFormData,
                            handleFormDataChange,
                            defaultTexts[field.label],
                            {size:"medium",
                            withHelp: field.with_help_text,
                            helpText: defaultTexts[field.help_text],
                            sx: {
                                my: 1, 
                                width: width * widthWeight * 0.8
                            },
                            ...field_options
                            },
                            
                        )
                    )
                }
            )}
        </FormGroup>)
    }

    const handleEditModeChange = () => {
        handleServiceDetailTabChange("/service-details")
        setEditMode(true)
    }

    const handleEditModeReset = () => {
        setUpdateFormData({...selectedRow})
        handleServiceDetailTabChange("/service-details")
        setEditMode(false)
    }
    
    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }
    const handleSuccessDialogClose = () => {
        handleDrawerClose()
        setSuccessServiceDelete(null);
        setSuccessDialogOpen(false);
    }
    const handleSuccessEndpointDialogClose = () => {
        setSuccessEndpointAdd(null);
        setSuccessDialogOpen(false);
    }
    const handleSuccessEndpointEditDialogClose = () => {
        setSuccessEndpointEdit(null);
        setSuccessDialogOpen(false);
    }
    const handleSuccessUpdateDialogClose = () => {
        setSuccessServiceUpdate(null)
        setUpdateFormData({...selectedRow})
        setSuccessUpdateDialogOpen(false);
    }
    const handleConfirmDeleteDialogOpen = () => {
        setConfirmDeleteDialogOpen(true)
    }
    const handleConfirmDeleteDialogClose = () => {
        setConfirmDeleteDialogOpen(false)
    }

    const handleConfirmDeleteEndpointDialogClose = () => {
        setConfirmDeleteEndpointDialogOpen(false)
    }

    const handleDeleteService = async () => {
        handleConfirmDeleteDialogClose()
        const url = `${identityServiceDomain}/${identityServiceVersion}/${servicesUrl}/${selectedRow.id}`
        const method = "DELETE"

        const service_response = await openstackRequest({url: url, method: method})
        if (service_response.status_code === servicesUrlResponses.delete.success_response.status_code) {
            setSuccessServiceDelete({
                success_title: servicesUrlResponses.delete.success_response.response_title, 
                success_message: servicesUrlResponses.delete.success_response.response_message
            })
            handleFetchData()
        } else {
            const error_response = servicesUrlResponses.delete.error_response.filter(
                error_item => error_item.status_code === service_response.status_code)
            if (error_response.length > 0) {
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: service_response.error
                }
                setError(errorObject)
            } else {
                const error_response = servicesUrlResponses.delete.error_response.filter(
                    error_item => error_item.status_code === "unknown")
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: service_response.error
                }
                setError(errorObject)
            }
        }
    }

    const handleEditService = async () => {
        const url = `${identityServiceDomain}/${identityServiceVersion}/${servicesUrl}/${selectedRow.id}`
        const method = "PATCH"

        const service_response = await openstackRequest({
            url: url, 
            method: method, 
            data: {service: updateFormData}
        })
        if (service_response.status_code === servicesUrlResponses.patch.success_response.status_code) {
            handleFetchData()
            handleEditModeReset()
            setSuccessServiceUpdate({
                success_title: servicesUrlResponses.patch.success_response.response_title, 
                success_message: servicesUrlResponses.patch.success_response.response_message
            })
        } else {
            const error_response = servicesUrlResponses.patch.error_response.filter(
                error_item => error_item.status_code === service_response.status_code)
            if (error_response.length > 0) {
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: service_response.error
                }
                setError(errorObject)
            } else {
                const error_response = servicesUrlResponses.patch.error_response.filter(
                    error_item => error_item.status_code === "unknown")
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: service_response.error
                }
                setError(errorObject)
            }
        }
    }

    const handleServiceDetailTabChange = useCallback((navigation) => {
        let newServiceSubmenuData = serviceSubMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
            } else {
                item.is_active = false
            }
            return item
        })
        setServiceSubMenu(newServiceSubmenuData)
        setCurrentTab(navigation)
    },[
        serviceSubMenu
    ])

    const onAddEndpoint = async () => {
        const validateFormData = handleEndpointFormDataValidation()
        if (validateFormData) {
            let request_data = {...endpointFormData}
            request_data["service_id"] = selectedRow.id
            const url = `${identityServiceDomain}/${identityServiceVersion}/${endpointsUrl}`
            const method = "POST"

            const endpoint_response = await openstackRequest({
                url: url, 
                method: method, 
                data: {endpoint: request_data}
            })
            if (endpoint_response.status_code === endpointsUrlResponses.post.success_response.status_code) {
                handleAddEndpointDialogClose()
                setEndpointFetchRequired(true)
                handleAddEndpointFormReset()
                setSuccessEndpointAdd({
                    success_title: endpointsUrlResponses.post.success_response.response_title, 
                    success_message: endpointsUrlResponses.post.success_response.response_message
                })
            } else {
                const error_response = endpointsUrlResponses.post.error_response.filter(
                    error_item => error_item.status_code === endpoint_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: endpoint_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = endpointsUrlResponses.post.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: endpoint_response.error
                    }
                    setError(errorObject)
                }
            }
        }
        return validateFormData
    }

    const onEditEndpoint = async () => {
        const url = `${identityServiceDomain}/${identityServiceVersion}/${endpointsUrl}/${selectedEndpoint.id}`
        const method = "PATCH"

        const endpoint_response = await openstackRequest({
            url: url, 
            method: method, 
            data: {endpoint: selectedEndpoint}
        })
        if (endpoint_response.status_code === endpointsUrlResponses.patch.success_response.status_code) {
            handleEndpointEditDialogClose()
            setEndpointFetchRequired(true)
            setSelectedEndpoint({})
            setSuccessEndpointEdit({
                success_title: endpointsUrlResponses.patch.success_response.response_title, 
                success_message: endpointsUrlResponses.patch.success_response.response_message
            })
        } else {
            const error_response = endpointsUrlResponses.patch.error_response.filter(
                error_item => error_item.status_code === endpoint_response.status_code)
            if (error_response.length > 0) {
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: endpoint_response.error
                }
                setError(errorObject)
            } else {
                const error_response = endpointsUrlResponses.patch.error_response.filter(
                    error_item => error_item.status_code === "unknown")
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: endpoint_response.error
                }
                setError(errorObject)
            }
        }
    }

    const onDeleteEndpoint = async () => {
        handleConfirmDeleteEndpointDialogClose()
        const url = `${identityServiceDomain}/${identityServiceVersion}/${endpointsUrl}/${selectedEndpoint.id}`
        const method = "DELETE"

        const endpoint_response = await openstackRequest({
            url: url, 
            method: method
        })
        if (endpoint_response.status_code === endpointsUrlResponses.delete.success_response.status_code) {
            setSelectedEndpoint({})
            setEndpointFetchRequired(true)
            setSuccessEndpointDelete({
                success_title: endpointsUrlResponses.delete.success_response.response_title, 
                success_message: endpointsUrlResponses.delete.success_response.response_message
            })
        } else {
            const error_response = endpointsUrlResponses.delete.error_response.filter(
                error_item => error_item.status_code === endpoint_response.status_code)
            if (error_response.length > 0) {
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: endpoint_response.error
                }
                setError(errorObject)
            } else {
                const error_response = endpointsUrlResponses.delete.error_response.filter(
                    error_item => error_item.status_code === "unknown")
                const errorObject = {
                    error_title: error_response[0].response_title, 
                    error_message: error_response[0].response_message,
                    error_details: endpoint_response.error
                }
                setError(errorObject)
            }
        }
    }

    const handleAddEndpointFormReset = () => {
        setEndpointFormDataOptions({})
        let new_form_data = {}
        for (const n in endpointDataForm) {
            if (
                endpointDataForm[n].field_type === "string" || 
                endpointDataForm[n].field_type === "select" ||
                endpointDataForm[n].field_type === "list"
                ) {
                new_form_data[endpointDataForm[n].field_key] = ""
            } else if (endpointDataForm[n].field_type === "bool") {
                new_form_data[endpointDataForm[n].field_key] = endpointDataForm[n].default_value ? 
                endpointDataForm[n].default_value : 
                false
            }
        }
        setEndpointFormData(new_form_data)
    }

    const handleEndpointFormDataChange = (event,field_key) => {
        let new_form_data = {...endpointFormData}
        if (endpointDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (endpointDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setEndpointFormData(new_form_data)
    }

    const handleEndpointEditFormDataChange = (event,field_key) => {
        let new_form_data = {...selectedEndpoint}
        if (endpointDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (endpointDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setSelectedEndpoint(new_form_data)
    }

    const getEndpointDataForm = () => {
        return (
            <FormGroup>
                {endpointDataForm.map(field => {
                    let form_field_options = {...endpointFormDataOptions[field.field_key]}
                    if (field.field_key === "interface") {
                        form_field_options["items"] = field.items
                        form_field_options["on_empty_null"] = field.on_empty_null
                        form_field_options["self_item_titles"] = field.self_item_titles
                        form_field_options["default_empty"] = field.default_empty
                        form_field_options["item_titles"] = defaultTexts
                    } else if (field.field_key === "region_id") {
                        const regions_list = regions.map(region => {return {keyword: region.id, value: region.id, default: false}})
                        form_field_options["items"] = regions_list
                        form_field_options["on_empty_null"] = field.on_empty_null
                        form_field_options["self_item_titles"] = field.self_item_titles
                        form_field_options["default_empty"] = field.default_empty
                    }

                    return (
                        getFormFieldComponent(
                            field,
                            endpointFormData,
                            handleEndpointFormDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const getEditEndpointDataForm = () => {
        return (
            <FormGroup>
                {endpointDataForm.map(field => {
                    let form_field_options = {...endpointFormDataOptions[field.field_key]}
                    if (field.field_key === "interface") {
                        form_field_options["items"] = field.items
                        form_field_options["on_empty_null"] = field.on_empty_null
                        form_field_options["self_item_titles"] = field.self_item_titles
                        form_field_options["default_empty"] = field.default_empty
                        form_field_options["item_titles"] = defaultTexts
                    } else if (field.field_key === "region_id") {
                        const regions_list = regions.map(region => {return {keyword: region.id, value: region.id, default: false}})
                        form_field_options["items"] = regions_list
                        form_field_options["on_empty_null"] = field.on_empty_null
                        form_field_options["self_item_titles"] = field.self_item_titles
                        form_field_options["default_empty"] = field.default_empty
                    }

                    return (
                        getFormFieldComponent(
                            field,
                            selectedEndpoint,
                            handleEndpointEditFormDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    useEffect(() => {
        if (Object.keys(endpointFormData).length === 0) {
            let new_form_data = {}
            for (const n in endpointDataForm) {
                if (
                    endpointDataForm[n].field_type === "string" || 
                    endpointDataForm[n].field_type === "select" ||
                    endpointDataForm[n].field_type === "list"
                    ) {
                    new_form_data[endpointDataForm[n].field_key] = ""
                } else if (endpointDataForm[n].field_type === "bool") {
                    new_form_data[endpointDataForm[n].field_key] = endpointDataForm[n].default_value ? 
                    endpointDataForm[n].default_value : 
                    false
                }
            }
            setEndpointFormData(new_form_data)
        }
    },[endpointFormData]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessDialogOpen(true)
    },[
        successServiceDelete,
        successEndpointDelete
    ]);

    useEffect(() => {
        setSuccessUpdateDialogOpen(true)
    },[
        successServiceUpdate,
        successEndpointAdd
    ]);

    useEffect(() => {
        if (selectedRow !== null && endpointFetchRequired) {
            (async () => {
                const url = `${identityServiceDomain}/${identityServiceVersion}/${endpointsUrl}/?service_id=${selectedRow.id}`
                const method = "GET"

                const endpoints_list = await openstackRequest({
                    url: url, 
                    method: method
                })
                if (endpoints_list.status_code === endpointsUrlResponses.get.success_response.status_code) {
                    setServiceEndpoints(endpoints_list.data.endpoints)
                } else {
                    setServiceEndpoints([])
                    }
                }
            )();
            setEndpointFetchRequired(false)
        }
    },[ 
        selectedRow,
        identityServiceDomain,
        identityServiceVersion,
        endpointsUrl,
        endpointFetchRequired
    ])

    useEffect(() => {
        if (selectedRow !== null) {
            (async () => {
                const url = `${identityServiceDomain}/${identityServiceVersion}/${regionsUrl}`
                const method = "GET"

                const regions_list = await openstackRequest({
                    url: url, 
                    method: method
                })
                
                if (regions_list.status_code === endpointsUrlResponses.get.success_response.status_code) {
                    setRegions(regions_list.data.regions)
                } else {
                    setRegions([])
                }
            })();
        }
    },[
        selectedRow,
        identityServiceDomain,
        identityServiceVersion,
        regionsUrl
    ]);

    useEffect(() => {
        if (selectedRow !== null) {
            let service_actions = []
            let new_action = {}
            new_action = {}
            new_action["value"] = "service_add_endpoint"
            new_action["action"] = handleAddEndpointDialogOpen
            new_action["keyword"] = "serviceAddEndpointActionTitle"
            new_action["button_text"] = "selectButtonTitleText"
            service_actions.push({...new_action})
            
            setServiceActions(service_actions)
        }
    },[
        selectedRow,
        defaultTexts,
        handleAddEndpointDialogOpen
    ]);

    return (
        <React.Fragment>
        {selectedRow !== null && 
        <WrapperBox>
            <ServiceContentHeader 
                service_menu={serviceSubMenu}
                service_menu_titles={defaultTexts}
                onClick={handleServiceDetailTabChange}
            />
        </WrapperBox>}
        {currentTab === "/service-details" &&
        <React.Fragment>
            {!editMode ? <Stack 
                spacing={2} 
                sx={{ p: 2, mt: 1, flexWrap: "wrap"}}
            >
                    {servicesCatalogDataSchema.map((field) => {
                        let value = selectedRow ? 
                        selectedRow[field.field_key] : ""
                        return (
                            getDetailDataComponent({
                                fieldType: field.field_type,
                                fieldKey: field.field_key,
                                label: defaultTexts[field.label],
                                value: value,
                                textOnTrue: defaultTexts[field.value_on_true] ? 
                                defaultTexts[field.value_on_true] : 
                                defaultTexts.formValueYes,
                                textOnFalse: defaultTexts[field.value_on_false] ? 
                                defaultTexts[field.value_on_false] : 
                                defaultTexts.formValueNo,
                                defaultTexts: defaultTexts
                            })
                        )
                    })}
                </Stack> : 
                <Stack 
                    sx={{
                        m: 2, 
                        alignItems: 'start'
                    }}>
                <CustomText 
                    size="h6" 
                    sx={{
                        color: "primary.main", 
                        mb: 2
                    }}>
                    {defaultTexts.updateServiceFormTitle}
                </CustomText>
                {getDataForm()}
                <Button 
                    variant="contained"
                    color="secondary"
                    sx={{mt: 5}}
                    onClick={handleEditService}
                >
                    {defaultTexts.saveButtonText}
                </Button>
            </Stack>}
            </React.Fragment>}
            
            {currentTab === "/service-endpoints" && 
            serviceEndpoints.length > 0 &&
                <Stack 
                    spacing={2} 
                    sx={{ p: 2, my: 1}}
                >
                    {serviceEndpoints.map((endpoint,index) => {
                        const last_index = serviceEndpoints.length - 1 === index
                        return (
                            <React.Fragment>
                            <Stack 
                                key={endpoint.id} 
                                direction="row"
                                justifyContent="space-between"
                                alignItems="end"
                            >
                                <Stack> 
                                    <CustomText 
                                        size="p"
                                        sx={{color: "text.secondary"}}
                                    >
                                        {defaultTexts.regionFormFieldLabel}: {endpoint.region_id}
                                    </CustomText>
                                    <CustomText size="p">
                                        {endpoint.interface.toUpperCase()}: {endpoint.url}
                                    </CustomText>
                                    
                                </Stack>
                                <Stack>
                                    <IconButton
                                        color="text.secondary"
                                        onClick={() => handleEndpointEditDialogOpen(endpoint)}
                                    >
                                        <EditIcon/>
                                    </IconButton>
                                    <IconButton
                                        color="error"
                                        onClick={() => handleEndpointDeleteDialogOpen(endpoint)}
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                </Stack>
                                
                            </Stack>
                            {!last_index && <Divider />}
                            </React.Fragment>
                        )
                    })}
                </Stack>
            } 
            {currentTab === "/service-endpoints" && 
            serviceEndpoints.length === 0 &&
                getEmptyListNoteComponent(defaultTexts.noServiceEndpointsNoteText)
            }
            <Paper sx={{ 
                position: 'fixed', 
                bottom: 0, 
                height: Constants.actions_bar_height + 20,
                left: width - (width * widthWeight), 
                right: 12
                }} 
                elevation={24}
                square={true}
            >
                <Grid 
                    container 
                    alignItems="center"  
                    justifyContent="space-between"
                >
                    <Grid item>
                        <CustomSelectField 
                            items={serviceActions} 
                            currentValue={currentAction}
                            setCurrentValue={setCurrentAction}
                            item_titles={defaultTexts}
                            label={defaultTexts.actionsDropdownLabelText}
                            empty={true}
                            size="small"
                            sx={{m: 1, width: 280}}
                        />
                        {currentAction.length > 0 && 
                            <Button 
                                    variant="contained"
                                    color="secondary"
                                    sx={{m: 1, height: '70%'}}
                                    onClick={serviceActions.filter(
                                        action => action.value === currentAction)[0].action
                                    }
                                >
                                {defaultTexts[serviceActions.filter(
                                    action => action.value === currentAction)[0].button_text]}
                            </Button>
                        }
                    </Grid>
                    <Grid item>
                        {!editMode ? <IconButton onClick={handleEditModeChange}>
                                <EditIcon color="primary" />
                            </IconButton> :
                            <IconButton onClick={handleEditModeReset}>
                                <EditOffIcon color="primary"/>
                            </IconButton>
                        }
                        {selectedRow !== null && 
                            <IconButton onClick={handleConfirmDeleteDialogOpen}>
                                <DeleteIcon 
                                    color="primary"
                                />
                            </IconButton>}
                    </Grid>
                </Grid>
            </Paper>
            
            {successServiceDelete && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[successServiceDelete.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successServiceDelete.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {successServiceUpdate && <CustomDialog
                open={successUpdateDialogOpen}
                onClose={handleSuccessUpdateDialogClose}
                dialogTitle={{
                    title: defaultTexts[successServiceUpdate.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successServiceUpdate.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {successEndpointAdd && <CustomDialog
                open={successEndpointAdd}
                onClose={handleSuccessEndpointDialogClose}
                dialogTitle={{
                    title: defaultTexts[successEndpointAdd.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successEndpointAdd.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {successEndpointEdit && <CustomDialog
                open={successEndpointEdit}
                onClose={handleSuccessEndpointEditDialogClose}
                dialogTitle={{
                    title: defaultTexts[successEndpointEdit.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successEndpointEdit.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>Details:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
            {confirmDeleteDialogOpen && <CustomDialog
                open={confirmDeleteDialogOpen}
                onClose={handleConfirmDeleteDialogClose}
                dialogTitle={{
                    title: defaultTexts.deleteConfirmationDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.deleteServiceConfirmationDialogMessage, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: handleDeleteService, 
                    sx: {color: 'primary.main'}}]}
            />}
            <CustomDialog
                open={addEndpointMode}
                onClose={handleAddEndpointDialogClose}
                dialogTitle={{
                    title: defaultTexts.serviceAddEndpointActionTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onAddEndpoint, 
                    sx: {color: 'primary.main'}}]}
            >
                    {getEndpointDataForm()}
            </CustomDialog>
            <CustomDialog
                open={endpointEditMode}
                onClose={handleEndpointEditDialogClose}
                dialogTitle={{
                    title: defaultTexts.serviceEditEndpointTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: "", 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: onEditEndpoint, 
                    sx: {color: 'primary.main'}}]}
            >
                    {getEditEndpointDataForm()}
            </CustomDialog>
            {confirmDeleteEndpointDialogOpen && <CustomDialog
                open={confirmDeleteEndpointDialogOpen}
                onClose={handleConfirmDeleteEndpointDialogClose}
                dialogTitle={{
                    title: defaultTexts.deleteConfirmationDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.deleteEndpointConfirmationDialogMessage, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.confirmButtonText, 
                    onClick: onDeleteEndpoint, 
                    sx: {color: 'primary.main'}}]}
            />}
        </React.Fragment>
    )
};

export default IdentityServicesCatalogDetailV3;