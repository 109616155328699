import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import CustomCard from '../../../_common/CustomCard';
import Box from '@mui/material/Box';
import { PieChart, pieArcLabelClasses  } from '@mui/x-charts/PieChart';
import { RxReset } from "react-icons/rx";
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Dimensions from '../../../../config/dimensions';
import useWindowDimensions from '../../../_common/WindowDimensions';
import Skeleton from '@mui/material/Skeleton';
import MuiStack from '@mui/material/Stack';
import CustomSelectField from 
'../../../_common/_form_fields/CustomSelectField';
import { 
    openstackRequest, 
    volumeCinderRequest,
    getXAuthTokenProjectScope } from 
'../../../../_network/openstack_request';
import CustomText from '../../../_common/CustomText';
import Typography from '@mui/material/Typography';
import { projectsUrl as projectsUrlResponses } from 
'../../../../_api_responses/openstack/identity/projects/v3';
import { volumesUrl as volumeUrlResponses } 
from '../../../../_api_responses/openstack/cinder/volumes/v3';
import { 
    identityKeystonConstants, 
    blockStorageCinderConstants,
    openStackServices
} from '../../../../config/openStackConstants';
import Constants from '../../../../config/constants';


const SERVICE_NAME = openStackServices.volumeService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const drawerWidth = Constants.drawerWidth;


const BlockStorageGraphContentV3 = (props) => {
    const open = useSelector(state => state.drawer.drawerOpened);
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const { width } = useWindowDimensions();
    
    const [selectedProject, setSelectedProject] = useState("")
    const [blockStorageLoading, setBlockStorageLoading] = useState(true)
    const [projects, setProjects] = useState([]);
    const [volumes, setVolumes] = useState([]);
    const [volumesData, setVolumesData] = useState([]);
    const [snapshots, setSnapshots] = useState([]);
    const [snapshotsData, setSnapshotsData] = useState([]);
    const [backups, setBackups] = useState([]);
    const [backupsData, setBackupsData] = useState([]);

    const navigate = useNavigate();
    const theme = useTheme();

    if (!isAuthenticated) {
        navigate('/');
    }

    const getCardHeight = (width) => {
        if (width - (open ? drawerWidth : 110) < Dimensions.tablet_mini.width) {
            return 600
        } else if (((width - (open ? drawerWidth : 110)) / 2) < 600) {
            return 600
        } else {
            return 400
        }
    }

    const CARD_HEIGHT = getCardHeight(width)

    const cinderServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const cinderServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const volumesUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === cinderServiceVersion)[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.volumesUrl)[0].url)
    const snapshotsUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === cinderServiceVersion)[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.snapshotsUrl)[0].url)
    const backupsUrl = useSelector(
        state => state.blockStorageCinder.blockStorageCinderApiUrls.filter(
            version => version.api_version === cinderServiceVersion)[0].urls.filter(
                url => url.keyword === blockStorageCinderConstants.backupsUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === identityServiceVersion)[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)

    const handleBlockStorageLoading = (mode) => {
        setBlockStorageLoading(mode)
    }

    const handleSelectedProjectReset = () => {
        setSelectedProject("")
    }

    const purchasedServices = useSelector(state => 
        state.openstack.purchasedServices);

    const purchasedService = purchasedServices.filter(
        item => item.service === SERVICE_NAME)

    const DashboardCard = (props) => {
        const { cardTitle } = props
        return (
            <CustomCard
                cardWidth={'100%'} 
                cardHeight={CARD_HEIGHT}
                cardTitle={cardTitle}
                titleSize="p"
                titleColor="primary.main"
                card_sx={{
                    border: theme.palette.mode === "dark" ? 0 : 1, 
                    boxShadow: theme.palette.mode === "dark" ? 0 : 1, 
                    backgroundColor: 
                        theme.palette.mode === "dark" ? 
                        undefined :
                        "vLightGray"
                }}
                box_sx={{pr: 2}}
            >
                {props.children}
            </CustomCard>
        )
    }

    const CustomPieChart = (props) => {
        const { data } = props
        return (
            <Box sx={{
                height: CARD_HEIGHT > 400 ? 380 : 260, 
                alignItems: "center", 
                justifyContent: "center"}}
            >
                {data.length > 0 && <PieChart
                    skipAnimation={true}
                    slotProps={{ 
                        legend: {
                            position: CARD_HEIGHT > 400 ? 
                            { vertical: "bottom"} : 
                            { horizontal: "right"}},  
                        }}
                    series={[
                        {
                        arcLabel: (i) => `${i.value}`,
                        arcLabelMinAngle: 15,
                        data: data,
                        innerRadius: 40,
                        outerRadius: 110,
                        paddingAngle: 5,
                        cornerRadius: 4,
                        startAngle: -300,
                        endAngle: 147,
                        cx: 170,
                        cy: 130,
                        }
                    ]}
                    sx={{
                        [`& .${pieArcLabelClasses.root}`]: {
                        fill: 'white',
                        fontWeight: '500',
                        },
                    }}
                />}
                { data.length === 0 && 
                    <Box sx={{
                        width: "100%", 
                        height: "100%",
                        display: "flex", 
                        alignItems: "center", 
                        justifyContent: "center",
                        mt: 1
                    }}>
                        <Box
                            sx={{
                                width: "80%",
                                height: "80%",
                                border: "1px dashed",
                                borderRadius: 2,
                                borderColor: "customBlue",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                        <CustomText sx={{fontSize: 20, color: "customBlue"}}>
                            {defaultTexts.noStorageObjectsNoteTitle}
                        </CustomText>
                        </Box>
                    </Box>
                }
            </Box>
        )
    }

    const CustomSkeleton = () => {
        return (
            <Grid container spacing={2} 
                justifyContent="space-around" 
                alignItems="center" 
                sx={{mt: 3}}
            >
                <Grid item>
                    <Skeleton height={220} width={220} variant="circular" />
                </Grid>
                <Grid item>
                    <MuiStack>
                        <Skeleton width={220} height={40}>
                            <Typography>.</Typography>
                        </Skeleton>
                        <Skeleton width={220} height={40}>
                            <Typography>.</Typography>
                        </Skeleton>
                        <Skeleton width={220} height={40}>
                            <Typography>.</Typography>
                        </Skeleton>
                        <Skeleton width={220} height={40}>
                            <Typography>.</Typography>
                        </Skeleton>
                    </MuiStack>
                </Grid>
            </Grid>
        )
    }

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({url:url, method:method})
            if (projects_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                const projectFilter = projects_response.data.projects.map(item => {
                    return {keyword: item.name, value: item.id, default: false}
                })
                setProjects(projectFilter)
            }
        })();
    },[
        identityServiceDomain, 
        identityServiceVersion, 
        projectsUrl
    ]);

    useEffect(() => {
            (async () => {
                handleBlockStorageLoading(true)
                const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
                if (project_token) {
                    let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${volumesUrl}/detail?all_tenants=true`
                    const method = "GET"
                    const volume_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                    if (volume_response.status_code === volumeUrlResponses.get.success_response.status_code) {
                        setVolumesData(volume_response.data.volumes)
                    }
                }
                setTimeout(()=>{handleBlockStorageLoading(false)},100)
            })();
        },[
        cinderServiceDomain, 
        cinderServiceVersion, 
        volumesUrl, 
        defaultAdminProject
    ]);

    useEffect(() => {
        (async () => {
            handleBlockStorageLoading(true)
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${snapshotsUrl}/detail?all_tenants=true`
                const method = "GET"
                const snapshots_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                if (snapshots_response.status_code === volumeUrlResponses.get.success_response.status_code) {
                    setSnapshotsData(snapshots_response.data.snapshots)
                }
            }
            setTimeout(()=>{handleBlockStorageLoading(false)},100)
        })();
    },[
        cinderServiceDomain, 
        cinderServiceVersion, 
        snapshotsUrl, 
        defaultAdminProject
    ]);


    useEffect(() => {
        (async () => {
            handleBlockStorageLoading(true)
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${cinderServiceDomain}/${cinderServiceVersion}/${defaultAdminProject}/${backupsUrl}/detail?all_tenants=true`
                const method = "GET"
                const backups_response = await volumeCinderRequest({url:url, method:method, token: project_token})
                if (backups_response.status_code === volumeUrlResponses.get.success_response.status_code) {
                    setBackupsData(backups_response.data.backups)
                }
            }
            setTimeout(()=>{handleBlockStorageLoading(false)},100)
        })();
    },[
        cinderServiceDomain, 
        cinderServiceVersion, 
        backupsUrl, 
        defaultAdminProject
    ]);

    useEffect(() => {
        if (selectedProject.length > 0) {
            const filtered_volumes = volumesData.filter(volume => volume[blockStorageCinderConstants.volumeProjectIdField] === selectedProject)
            const filtered_snapshots = snapshotsData.filter(snapshot => snapshot[blockStorageCinderConstants.snapshotProjectIdField] === selectedProject)
            const filtered_backups = backupsData.filter(backup => backup[blockStorageCinderConstants.backupProjectIdField] === selectedProject)

            let volumes_size = 0
            let snapshots_size = 0
            let backups_size = 0

            for (let v in filtered_volumes) {
                volumes_size += filtered_volumes[v].size
            }

            for (let s in filtered_snapshots) {
                snapshots_size += filtered_snapshots[s].size
            }
            for (let b in filtered_backups) {
                backups_size += filtered_backups[b].size
            }

            setVolumes({size: volumes_size, count: filtered_volumes.length})
            setSnapshots({size: snapshots_size, count: filtered_snapshots.length})
            setBackups({size: backups_size, count: filtered_backups.length})

        } else {
            let volumes_size = 0
            let snapshots_size = 0
            let backups_size = 0

            for (let v in volumesData) {
                volumes_size += volumesData[v].size
            }

            for (let s in snapshotsData) {
                snapshots_size += snapshotsData[s].size
            }
            for (let b in backupsData) {
                backups_size += backupsData[b].size
            }

            setVolumes({size: volumes_size, count: volumesData.length})
            setSnapshots({size: snapshots_size, count: snapshotsData.length})
            setBackups({size: backups_size, count: backupsData.length})
        }
    },[
        projects,
        selectedProject,
        volumesData,
        snapshotsData,
        backupsData
    ])

    if (purchasedService[0].config_params.api_version === "v3") {
        return (
            <DashboardCard>
                <CustomText 
                    sx={{
                        color: "primary.main", 
                        fontSize: 20
                    }}>
                        {defaultTexts.blockStorageSummaryCardTitle}
                </CustomText>
                <Grid 
                    container 
                    spacing={2} 
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Grid item>
                        {selectedProject.length > 0 && 
                            <Tooltip title={defaultTexts.resetFiltersTooltipText}>
                                <IconButton onClick={handleSelectedProjectReset}>
                                    <RxReset 
                                        color={theme.palette.text.primary}
                                        fontSize={'large'}
                                    />
                                </IconButton>
                            </Tooltip>}
                    </Grid>
                    <Grid item>
                        <CustomSelectField 
                            currentValue={selectedProject}
                            setCurrentValue={setSelectedProject}
                            label={defaultTexts.projectFormFieldLabel}
                            required={false}
                            items={projects}
                            self_item_titles={true}
                            size="large"
                            sx={{width: 250}}
                        />
                    </Grid>
                </Grid>
                {!blockStorageLoading ? 
                    <CustomPieChart data={(volumes.count > 0 || snapshots.count > 0 || backups.count > 0) ? [
                        {
                            label: `${defaultTexts.volumeFormFieldLabel} ${defaultTexts.sizeFormFieldLabel} (GB), Count ${volumes.count}`, 
                            value: volumes.size
                        },
                        {
                            label: `${defaultTexts.snapshotFormFieldLabel} ${defaultTexts.sizeFormFieldLabel} (GB), Count ${snapshots.count}`, 
                            value: snapshots.size
                        },
                        {
                            label: `${defaultTexts.backupFormFieldLabel} ${defaultTexts.sizeFormFieldLabel} (GB), Count ${backups.count}`, 
                            value: backups.size
                        }
                    ] : []} /> : 
                    <CustomSkeleton />
                }
            </DashboardCard>
        )
    }
};

export default BlockStorageGraphContentV3;