export const imagesGlanceTexts = {
    submenuImages: "Images",
    submenuLimits: "Limits",
    submenuMetadatas: "Metadata",
    visibilityFormFieldLabel: "Visibility",
    protectedFormFieldLabel: "Protected",
    diskFormatFormFieldLabel: "Disk Format",
    sizeFormFieldLabel: "Size",
    queuedImageStatusText: "Queued",
    savingImageStatusText: "Saving",
    activeImageStatusText: "Active",
    killedImageStatusText: "Killed",
    deletedImageStatusText: "Deleted",
    pendingDeleteImageStatusText: "Pending delete",
    deactivatedImageStatusText: "Deactivated",
    uploadingImageStatusText: "Uploading",
    importingImageStatusText: "Importing",
    publicVisibilityImageText: "Public",
    communityVisibilityImageText: "Community",
    sharedVisibilityImageText: "Shared",
    privateVisibilityImageText: "Private",
    hiddenImageStatusText: "Hidden",
    memberStatusImageText: "Member Status",
    acceptedMeberStatusImageText: "Accepted",
    pendingMeberStatusImageText: "Pending",
    rejectedVisibilityImageText: "Rejected",
    imageCreateSuccessResponseMessage: "Image successfully created!",
    imageUpdateSuccessResponseMessage: "Image successfully updates!",
    imageDeleteSuccessResponseMessage: "Image successfully deleted!",
    addImageDialogTitle: "Add New Image",
    addImageDialogDescriptionText: "You can create new image and upload it's file.",
    minDiskFormFieldLabel: "Minimum Disk Required (GB)",
    minRamFormFieldLabel: "Minimum RAM Required (MB)",
    readyFormFieldLabel: "Ready",
    minSizeFormFieldLabel: "Size (Min)",
    maxSizeFormFieldLabel: "Size (Max)",
    amiContainerFormatTitle: "AMI - Amazon Machine Image",
    ariContainerFormatTitle: "ARI - Amazon Ramdisk Image",
    akiContainerFormatTitle: "AKI - Amazon Kernel Image",
    bareContainerFormatTitle: "Bare image container",
    ovfContainerFormatTitle: "OVF container format",
    ovaContainerFormatTitle: "OVA tar archive file",
    dockerContainerFormatTitle: "Docker tar archive",
    vhdContainerFormatTitle: "VHD - Virtual Hard Disk",
    vhdxContainerFormatTitle: "VHDX - Virtual Hard Disk Enhanced",
    vmdkContainerFormatTitle: "VMDK - Virtual Machine Disk",
    rawContainerFormatTitle: "RAW - Unstructured Disk Image",
    qcow2ContainerFormatTitle: "QCOW2 - QEMU Emulator",
    vdiContainerFormatTitle: "VDI - Virtual Disk Image",
    ploopContainerFormatTitle: "PLOOP - Virtuozzo/Parallels Loopback Disk",
    isoContainerFormatTitle: "ISO - Optical Disk Image",
    containerFormatFormFieldLabel: "Container Format",
    chooseFileButtonText: "Choose File",
    chooseImageFileDescriptionText: "Choose image file to upload.",
    checksumFormFieldLabel: "Checksum",
    hashAlgorithmFormFieldLabel: "Hash Algorithm",
    hashValueFormFieldLabel: "Hash Value",
    imageUpdateActionTitle: "Update Image",
    imageDeleteActionTitle: "Delete Image",
    uploadImageFileButtonText: "Upload Image File",
    hiddenFormFieldLabel: "Hidden",
    architectureFormFieldLabel: "Architecture",
    imageDeleteConfirmTitle: "Delete Image",
    imageDeleteConfirmText: "Are you sure, you want to delete selected images? This action is irreversible. Selected images",
    imageMetadataUpdateActionTitle: "Update Image Metadata",
    imageTypeFormFieldLabel: "Image Type",
    defaultImageTypeTitle: "Image",
    fileFormFieldLabel: "File",
    baseImageFormFieldLabel: "Base Image",
    operatingSystemFormFieldLabel: "OS",
    osVersionFormFieldLabel: "OS Version",
    usageTypeFormFieldLabel: "Usage Type",
    instanceFormFieldLabel: "Instance"
}