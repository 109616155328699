import React from 'react';
import { Route, Routes } from "react-router-dom";
import HomeWrapper from './components/home/components/homeWrapper';
import ComputeNovaWrapper from './components/compute_nova/components/computeNovaWrapper';
import VolumesCinderWrapper from './components/volumes_cinder/components/volumesCinderWrapper';
import ImagesGlanceWrapper from './components/images_glance/components/imagesGlanceWrapper';
import NetworksNeutronWrapper from './components/networks_neutron/components/networksNeutronWrapper';
import BackupsDRWrapper from './components/backups_dr/components/backupsDRWrapper';
import IdentityKeystoneWrapper from './components/identity_keystone/components/identityKeystoneWrapper';
import DNSDesignateWrapper from './components/dns_designate/components/dnsDesignateWrapper';
import LBOctaviaWrapper from './components/lb_octavia/components/lbOctaviaWrapper';
import BillingConfigsWrapper from './components/billing/configs/billingConfigsWrapper';
import BillingDepositsWrapper from './components/billing/deposits/billingDepositsWrapper';
import BillingPricesWrapper from './components/billing/prices/billingPricesWrapper';
import BillingAnalyticsWrapper from './components/billing/analytics/billingAnalyticsWrapper';
import CustomersSupportWrapper from './components/customers_support/customersSupportWrapper';
import useAutoLogout from './components/_common/_hooks/sessionAutoLogout';

const AppRoutes = (props) => {
    useAutoLogout()
  
    return (
        <Routes>
            <Route exact path="/" element={<HomeWrapper />} />
            <Route exact path="/compute" element={<ComputeNovaWrapper />} />
            <Route exact path="/block-storage" element={<VolumesCinderWrapper />} />
            <Route exact path="/images" element={<ImagesGlanceWrapper />} />
            <Route exact path="/networks" element={<NetworksNeutronWrapper />} />
            <Route exact path="/backups" element={<BackupsDRWrapper />} />
            <Route exact path="/identity" element={<IdentityKeystoneWrapper />} />
            <Route exact path="/dns" element={<DNSDesignateWrapper />} />
            <Route exact path="/loadbalancers" element={<LBOctaviaWrapper />} />
            <Route exact path="/billing-configs" element={<BillingConfigsWrapper />} />
            <Route exact path="/billing-prices" element={<BillingPricesWrapper />} />
            <Route exact path="/billing-deposits" element={<BillingDepositsWrapper />} />
            <Route exact path="/billing-analytics" element={<BillingAnalyticsWrapper />} />
            <Route exact path="/customers-support" element={<CustomersSupportWrapper />} />
            <Route path='*' element={<HomeWrapper />} />
        </Routes>
  );
}

export default AppRoutes;