import { green, deepOrange, grey } from '@mui/material/colors';
import darkScrollbar from "@mui/material/darkScrollbar";

const getDesignTokens = (mode, primaryColor, secondaryColor) => ({
            palette: {
                mode,
                ...(primaryColor ? {
                primary: {
                    main: primaryColor
                }} :
                {primary: {
                    ...deepOrange, main: deepOrange[600]
                }}),
                ...(secondaryColor ? {
                secondary: {
                    main: secondaryColor
                }} :
                {secondary: {
                    ...green, main: green[600], dark: green[800]
                }}),
                ...(mode === 'dark' && {background: {
                    default: "#15141d",
                    paper: "#2c2939",
                }}),
                ...(mode === 'light' ? {
                text: {
                    primary: grey[700],
                    secondary: grey[600]
                }} :
                {text: {
                    primary: '#fff',
                    secondary: grey[600]
                }}),
                vLightGray: '#F5F5F5',
                mediumGray: '#444057',
                customPink: '#d60c64',
                dashboardPink: "#CE2BE0",
                dashboardPinkDark: "#d900ff",
                dashboardPurple: "#60009b",
                dashboardPurpleDark: "#9000cb",
                dashboardGreen: "#27adaa",
                dashboardGreenDark: "#3eb9b7",
                vDarkBlue: "#02145A",
                lightBlue: "#405dcf",
                customBlue: "#2f96ff",
                customBlueDark: "#72ccff",
                customBlueColor: {
                  main: '#007FFF',
                  light: '#007FFF',
                  dark: '#007FFF',
                  contrastText: '#ffffff'
                }
            },
            components: {
                MuiCssBaseline: {
                  styleOverrides: {
                    html: {
                      ...darkScrollbar(
                        mode === "light"
                          ? {
                              track: grey[200],
                              thumb: grey[400],
                              active: grey[400]
                            }
                          : undefined
                      ),
                      scrollbarWidth: "thin",
                    },
                    div: {
                      ...darkScrollbar(
                        mode === "light"
                          ? {
                              track: grey[200],
                              thumb: grey[400],
                              active: grey[400]
                            }
                          : undefined
                      ),
                      scrollbarWidth: "thin",
                    }
                  }
                }
              }
        });

export default getDesignTokens;