import React from 'react';
import { useSelector } from 'react-redux';
import CustomText from '../../../../_common/CustomText';
import WrapperBox from '../../../../_common/WrapperBox';
import { Grid }  from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { Stack } from '@mui/material';
import { Divider } from '@mui/material';
import { power_states } from 
'../../../../../_data/openstack/compute/servers/v2.1';

const detailTabCardConfig = {
    border: 0.3,
    borderRadius: 2,
    borderStyle: "dashed",
    borderColor: "text.secondary",
    minHeight: 350
}

const ComputeServerSpecsV21 = (props) => {
    const { serverData, selectedRow, serverGroups } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);

    const getServerAge = () => {
        const age_mins = (new Date() - new Date(serverData.created)) / 1000 / 60
            if (age_mins < 60) {
                return `${parseInt(age_mins)} ${defaultTexts.minutesText}`
            } else if (age_mins < 1440) {
                return `${parseInt(age_mins / 60)} ${defaultTexts.hoursText}, ${parseInt(age_mins % 60)} ${defaultTexts.minutesText}`
            } else {
                return `${parseInt(age_mins / 1440)} ${defaultTexts.daysText}`
            }
    }

    const getLunchDate = () => {
        let lunch_date = new Date(serverData["OS-SRV-USG:launched_at"])
        const time_zone_offset = new Date().getTimezoneOffset() / 60
        lunch_date.setHours(lunch_date.getHours() - time_zone_offset)
        return lunch_date.toLocaleString()
    }

    return (
        <React.Fragment>
            <Grid container spacing={2} sx={{mt: 1, mb: 4}}>
                <Grid item xs={12} lg={6}>
                    <WrapperBox
                        sx={detailTabCardConfig}
                    >
                        <Stack direction="column" spacing={1} sx={{width: "90%"}}>
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.idFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData ? serverData.id : ""}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.nameFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData.name}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.projectFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData.tenant_id}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.userFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData.user_id}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.serverGroupsFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {(Object.keys(serverData).includes("server_groups") && 
                                    serverData.server_groups.length > 0 && 
                                    serverGroups.length > 0) ? 
                                        serverGroups.filter(
                                            sg => sg.id === serverData.server_groups[0])[0].name : 
                                            "-"
                                    }
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.ageTableHeaderTitleText}
                                </CustomText>
                                <CustomText size="p">
                                    {getServerAge()}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.statusFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData.status}
                                </CustomText>
                            </Stack>
                            <Divider />
                        </Stack>
                    </WrapperBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <WrapperBox
                        sx={detailTabCardConfig}
                    >
                        <Stack 
                            direction="column" 
                            spacing={1} 
                            sx={{width: "90%"}}
                        >
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.flavorIDTitleText}
                                </CustomText>
                                <CustomText size="p">
                                    {selectedRow.flavor_details ? 
                                        selectedRow.flavor_details.id : ""}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.flavorNameTitleText}
                                </CustomText>
                                <CustomText size="p">
                                    {selectedRow.flavor_details ? 
                                        selectedRow.flavor_details.name : ""}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.vcpusFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {selectedRow.flavor_details ? 
                                        selectedRow.flavor_details.vcpus : ""}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.ramFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {selectedRow.flavor_details ? 
                                        selectedRow.flavor_details.ram : ""}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.diskTableHeaderLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {selectedRow.flavor_details ? 
                                        selectedRow.flavor_details.disk : ""}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.ephemeralTableHeaderLabel}
                                </CustomText>
                                <CustomText size="p">{
                                    selectedRow.flavor_details && 
                                    selectedRow.flavor_details.ephemeral ? 
                                    selectedRow.flavor_details.ephemeral : 
                                    "-"}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.swapTableHeaderLabel}
                                </CustomText>
                                <CustomText size="p">{
                                    selectedRow.flavor_details && 
                                    selectedRow.flavor_details.swap ? 
                                    selectedRow.flavor_details.swap : 
                                    "-"}
                                </CustomText>
                            </Stack>
                            <Divider />
                        </Stack>
                    </WrapperBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <WrapperBox
                        sx={detailTabCardConfig}
                    >
                        <Stack 
                            direction="column" 
                            spacing={1} 
                            sx={{width: "90%"}}
                        >
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.accessIPv4FormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData.accessIPv4 ? 
                                        serverData.accessIPv4 : "-"}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.accessIPv6FormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData.accessIPv6 ? 
                                        serverData.accessIPv6 : "-"}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.availabilityZoneFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData["OS-EXT-AZ:availability_zone"]}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.computeHostFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData["OS-EXT-SRV-ATTR:host"]}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.hypervisorHostnameFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData["OS-EXT-SRV-ATTR:hypervisor_hostname"]}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.sshKeyNameFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData.key_name ? serverData.key_name : "-"}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.descriptionFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData.description ? serverData.description : "-"}
                                </CustomText>
                            </Stack>
                            <Divider />
                        </Stack>
                    </WrapperBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <WrapperBox
                        sx={detailTabCardConfig}
                    >
                    <Stack direction="column" spacing={1} sx={{width: "90%"}}>
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.powerStateFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {defaultTexts[power_states[serverData["OS-EXT-STS:power_state"]]]}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.vmStateFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData["OS-EXT-STS:vm_state"]}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.taskStateFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData["OS-EXT-STS:task_state"]}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.createTimeHeaderLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {new Date(serverData.created).toLocaleString()}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.lunchedAtFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {getLunchDate()}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.updatedAtFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {new Date(serverData.updated).toLocaleString()}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.terminatedAtFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">{
                                    serverData["OS-SRV-USG:terminated_at"] ? 
                                    new Date(serverData["OS-SRV-USG:terminated_at"]).toLocaleString() :
                                    "-"
                                }</CustomText>
                            </Stack>
                            <Divider />
                        </Stack>
                    </WrapperBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <WrapperBox
                        sx={detailTabCardConfig}
                    >
                        <Stack 
                            direction="column" 
                            spacing={1} 
                            sx={{width: "90%"}}
                        >
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.imageFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData.image.id}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.configDriveFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData.config_drive ? 
                                        defaultTexts.formValueYes : 
                                        defaultTexts.formValueNo
                                    }
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.diskConfigFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData["OS-DCF:diskConfig"]}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.instanceNameFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData["OS-EXT-SRV-ATTR:instance_name"]}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.kernelIDFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData["OS-EXT-SRV-ATTR:kernel_id"]}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.isLockedFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData.locked ? 
                                        defaultTexts.formValueYes : 
                                        defaultTexts.formValueNo}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.lockReasonFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData.locked_reason}
                                </CustomText>
                            </Stack>
                            <Divider />
                        </Stack>
                    </WrapperBox>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <WrapperBox
                        sx={detailTabCardConfig}
                    >
                        <Stack 
                            direction="column" 
                            spacing={1} 
                            sx={{width: "90%"}}
                        >
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.lunchIndexFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData["OS-EXT-SRV-ATTR:launch_index"]}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.ramdiskIDFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData["OS-EXT-SRV-ATTR:ramdisk_id"]}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.reservationIDFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData["OS-EXT-SRV-ATTR:reservation_id"]}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.rootDeviceNameFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData["OS-EXT-SRV-ATTR:root_device_name"]}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.tagsFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData.tags}
                                </CustomText>
                            </Stack>
                            <Divider />
                            <Tooltip title={serverData.hostId}>
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.hostIDFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {`${serverData.hostId.slice(0,35)}...`}
                                </CustomText>
                            </Stack>
                            </Tooltip>
                            <Divider />
                            <Stack 
                                direction="row" 
                                justifyContent="space-between" 
                                alignItems="center"
                            >
                                <CustomText 
                                    size="p" 
                                    sx={{color: "text.secondary"}}
                                >
                                    {defaultTexts.hostnameFormFieldLabel}
                                </CustomText>
                                <CustomText size="p">
                                    {serverData["OS-EXT-SRV-ATTR:hostname"]}
                                </CustomText>
                            </Stack>
                            <Divider />
                        </Stack>
                    </WrapperBox>
                </Grid>
            </Grid>
            </React.Fragment>
    )
};

export default ComputeServerSpecsV21;