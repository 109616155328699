import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import CustomCard from '../../_common/CustomCard';
import { BarChart } from '@mui/x-charts/BarChart';
import Box from '@mui/material/Box';
import { DatePicker } from '@mui/x-date-pickers';
import Constants from '../../../config/constants'
import billingRequest from '../../../_network/billing_request';
import { billingServiceURLs } from '../../../_network/apiUrls';
import { PieChart, pieArcLabelClasses  } from '@mui/x-charts/PieChart';
import Dimensions from '../../../config/dimensions';
import useWindowDimensions from '../../_common/WindowDimensions';
import Skeleton from '@mui/material/Skeleton';
import MuiStack from '@mui/material/Stack';
import { regionsUrl as regionsUrlResponses } from 
'../../../_api_responses/openstack/identity/regions/v3';
import { 
    openStackServices,
    openStackServiceLabels
 } from '../../../config/openStackConstants';
import CustomSelectField from 
'../../_common/_form_fields/CustomSelectField';
import CustomTextField from 
'../../_common/_form_fields/CustomTextField';
import { identityKeystonConstants } from 
'../../../config/openStackConstants';
import { openstackRequest } from 
'../../../_network/openstack_request';
import CustomText from '../../_common/CustomText';
import Typography from '@mui/material/Typography';
import { projectsUrl as projectsUrlResponses } from 
'../../../_api_responses/openstack/identity/projects/v3';

const IDENTITY_SERVICE_NAME = openStackServices.identityService

const getCardHeight = (width) => {
    if (width < Dimensions.tablet_mini.width) {
        return 600
    } else if ((width / 2) < 600) {
        return 600
    } else {
        return 400
    }
}

const CURRENT_YEAR = new Date().getFullYear()

const BillingDashboard = (props) => {
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
    const { width } = useWindowDimensions();
    const CARD_HEIGHT = getCardHeight(width)
    const [serviceBreakdowns, setServiceBreakdowns] = useState([])
    const [serviceBreakdownsDate, setServiceBreakdownsDate] = useState(new Date())
    const [serviceBreakdownsRegion, setServiceBreakdownsRegion] = useState("")
    const [serviceBreakdownsLoading, setServiceBreakdownsLoading] = useState(true);
    const [regions, setRegions] = useState([]);
    const [projects, setProjects] = useState([])
    const [incomeCompareStartDate, setIncomeCompareStartDate] = useState(new Date(CURRENT_YEAR, 0, 1))
    const [incomeCompareEndDate, setIncomeCompareEndDate] = useState(new Date())
    const [incomeCompareLoading, setIncomeCompareLoading] = useState(true)
    const [incomeCompareRegion, setIncomeCompareRegion] = useState("");
    const [usageCompareStartDate, setUsageCompareStartDate] = useState(new Date(CURRENT_YEAR, 0, 1))
    const [usageCompareEndDate, setUsageCompareEndDate] = useState(new Date())
    const [usageCompareLoading, setUsageCompareLoading] = useState(true)
    const [topNProjectsNumber, setTopNProjectsNumber] = useState(5)
    const [incomeLoading, setIncomeLoading] = useState(true)
    const [incomeRegion, setIncomeRegion] = useState("");
    const [incomeDate, setIncomeDate] = useState(new Date())
    const [incomeData, setIncomeData] = useState({})
    const [incomeRawData, setIncomeRawData] = useState({})
    const [billingConfigs, setBillingConfigs] = useState({}) 
    const [usageData, setUsageData] = useState({})
    const [usageDate, setUsageDate] = useState(new Date())
    const [usageRegion, setUsageRegion] = useState("")
    const [usageLoading, setUsageLoading] = useState(true);
    const [incomeCompareData, setIncomeCompareData] = useState({})
    const [incomeCompareRawData, setIncomeCompareRawData] = useState({})
    const [usageCompareData, setUsageCompareData] = useState({})
    const [usageCompareRegion, setUsageCompareRegion] = useState("")
    const [topNProjectsData, setTopNProjectsData] = useState({})
    const [topNProjectsStartDate, setTopNProjectsStartDate] = useState(new Date())
    const [topNProjectsLoading, setTopNProjectsLoading] = useState(true)

    const navigate = useNavigate();
    const theme = useTheme();

    if (!isAuthenticated) {
        navigate('/');
    }

    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const regionsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.regionsUrl)[0].url)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)

    const handleServiceBreakdownsLoading = (mode) => {
        setServiceBreakdownsLoading(mode)
    }

    const handleIncomeCompareLoading = (mode) => {
        setIncomeCompareLoading(mode)
    }

    const handleUsageCompareLoading = (mode) => {
        setUsageCompareLoading(mode)
    }

    const handleIncomeLoading = (mode) => {
        setIncomeLoading(mode)
    }

    const handleUsageLoading = (mode) => {
        setUsageLoading(mode)
    }

    const handleTopNProjectsLoading = (mode) => {
        setTopNProjectsLoading(mode)
    }

    const DashboardCard = (props) => {
        const { cardTitle } = props
        return (
            <CustomCard
                cardWidth={'100%'} 
                cardHeight={CARD_HEIGHT}
                cardTitle={cardTitle}
                titleSize="p"
                titleColor="primary.main"
                card_sx={{
                    border: theme.palette.mode === "dark" ? 0 : 1, 
                    boxShadow: theme.palette.mode === "dark" ? 0 : 1, 
                    backgroundColor: 
                        theme.palette.mode === "dark" ? 
                        undefined :
                        "vLightGray"
                }}
                box_sx={{pr: 2}}
            >
                {props.children}
            </CustomCard>
        )
    }

    const CustomPieChart = (props) => {
        const { data } = props
        return (
            <Box sx={{
                height: CARD_HEIGHT > 400 ? 380 : 260, 
                alignItems: "center", 
                justifyContent: "center"}}
            >
                {data.length > 0 && <PieChart
                    skipAnimation={true}
                    slotProps={{ 
                        legend: {
                            position: CARD_HEIGHT > 400 ? 
                            { vertical: "bottom"} : 
                            { horizontal: "right"}},  
                        }}
                    series={[
                        {
                        arcLabel: (i) => `${i.value}`,
                        arcLabelMinAngle: 15,
                        data: data,
                        innerRadius: 40,
                        outerRadius: 110,
                        paddingAngle: 5,
                        cornerRadius: 4,
                        startAngle: -300,
                        endAngle: 147,
                        cx: 170,
                        cy: 130,
                        }
                    ]}
                    sx={{
                        [`& .${pieArcLabelClasses.root}`]: {
                        fill: 'white',
                        fontWeight: '500',
                        },
                    }}
                />}
                { data.length === 0 && 
                    <Box sx={{
                        width: "100%", 
                        height: "100%",
                        display: "flex", 
                        alignItems: "center", 
                        justifyContent: "center",
                        mt: 1
                    }}>
                        <Box
                            sx={{
                                width: "80%",
                                height: "80%",
                                border: "1px dashed",
                                borderRadius: 2,
                                borderColor: "customBlue",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                        <CustomText sx={{fontSize: 20, color: "customBlue"}}>
                            {defaultTexts.noDataNoteTitle}
                        </CustomText>
                        </Box>
                    </Box>
                }
            </Box>
        )
    }

    const CustomBarChart = (props) => {
        const { data } = props
        const { colors } = props
        const { orientation } = props
        let xAxis = undefined
        let yAxis = undefined
        let series = []
        if (data.aData) {
            series = [{data: data.aData.values, label: data.aData.label, stack: 'total'}]
        }
        if (data.bData) {
            series.push({data: data.bData.values, label: data.bData.label, stack: 'total'})
        }
        if (orientation === "horizontal") {
            yAxis = [{ data: data.units, scaleType: 'band' }]
        } else {
            xAxis = [{ data: data.units, scaleType: 'band' }]
        }
        return (
            <Box sx={{
                height: CARD_HEIGHT > 400 ? 380 : 260, 
                alignItems: "center", 
                justifyContent: "center"}}
            >
                {((data.aData && data.aData.values.length > 0) || (data.bData && data.bData.values.length > 0)) && <BarChart
                    slotProps={{ 
                        legend: {
                            position: { vertical: "top"} 
                    }}}
                    series={series}
                    xAxis={xAxis}
                    yAxis={yAxis}
                    colors={colors}
                    borderRadius={10}
                    layout={orientation ? orientation : "vertical"}
                />}
                {data.length === 0 && 
                    <Box sx={{
                        width: "100%", 
                        height: "100%",
                        display: "flex", 
                        alignItems: "center", 
                        justifyContent: "center",
                        mt: 1
                    }}>
                        <Box
                            sx={{
                                width: "80%",
                                height: "80%",
                                border: "1px dashed",
                                borderRadius: 2,
                                borderColor: "customBlue",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                        <CustomText sx={{fontSize: 20, color: "customBlue"}}>
                            {defaultTexts.noDataNoteTitle}
                        </CustomText>
                        </Box>
                    </Box>
                }
            </Box>
          );
    }

    const CustomSkeleton = () => {
        return (
            <Grid container spacing={2} 
                justifyContent="space-around" 
                alignItems="center" 
                sx={{mt: 3}}
            >
                <Grid item>
                    <Skeleton height={220} width={220} variant="circular" />
                </Grid>
                <Grid item>
                    <MuiStack>
                        <Skeleton width={220} height={40}>
                            <Typography>.</Typography>
                        </Skeleton>
                        <Skeleton width={220} height={40}>
                            <Typography>.</Typography>
                        </Skeleton>
                        <Skeleton width={220} height={40}>
                            <Typography>.</Typography>
                        </Skeleton>
                        <Skeleton width={220} height={40}>
                            <Typography>.</Typography>
                        </Skeleton>
                    </MuiStack>
                </Grid>
            </Grid>
        )
    }

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({url:url, method:method})
            if (projects_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                setProjects([...projects_response.data.projects])
            }
        })();
    },[
        identityServiceDomain, 
        identityServiceVersion, 
        projectsUrl
    ]);

    useEffect(() => {
        handleServiceBreakdownsLoading(true)
        let query_params = `?month_time=${serviceBreakdownsDate.toISOString()}`

        if (serviceBreakdownsRegion.length > 0) {
            query_params = query_params + `&region_name=${serviceBreakdownsRegion}`
        }
        const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingServiceBreakdownEP}${query_params}`
        let request_data = {
            url: url,
            method: "GET"
        }
            billingRequest(request_data).then((response) => {
                if (response.status_code === 200) {
                    const graph_data = response.data.map(service => {
                        return {label: openStackServiceLabels[service.service_name], value: service.service_cost}
                    })
                    setServiceBreakdowns(graph_data)
                }
                handleServiceBreakdownsLoading(false)
            }).catch((err) => {
                handleServiceBreakdownsLoading(false)
        })
    },[serviceBreakdownsDate,serviceBreakdownsRegion]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${regionsUrl}`
            const method = "GET"

            const regions_response = await openstackRequest({url:url, method:method})
            if (regions_response.status_code === regionsUrlResponses.get.success_response.status_code) {
                const regions_list = regions_response.data.regions.map(region => {
                    return {keyword: region.id, value: region.id, default: false}
                })
                setRegions(regions_list)
            }
            }
        )();
    },[
        identityServiceDomain, 
        identityServiceVersion, 
        regionsUrl,
        defaultTexts
    ]);

    useEffect(() => {
        const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingConfigsEP}`
        let request_data = {
            url: url,
            method: "GET"
        }
        billingRequest(request_data).then((response) => {
            if (response.status_code === 200) {
                setBillingConfigs(response.data)
            }
        }).catch((err) => {
            console.log(err)
    })
        
    },[]);

    useEffect(() => {
        handleIncomeLoading(true)
        let query_params = `?month_time=${incomeDate.toISOString()}`

        if (incomeRegion.length > 0) {
            query_params = query_params + `&region_name=${incomeRegion}`
        }
        const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingIncomeSummaryEP}${query_params}`
        let request_data = {
            url: url,
            method: "GET"
        }
            billingRequest(request_data).then((response) => {
                if (response.status_code === 200) {
                    setIncomeRawData(response.data)
                }
                handleIncomeLoading(false)
            }).catch((err) => {
                handleIncomeLoading(false)
            })
    },[
        incomeDate,
        incomeRegion
    ]);

    useEffect(() => {
        if (Object.keys(incomeRawData).length > 0) {
            let new_data = {}
            if (billingConfigs.use_vat_tax) {
                if (billingConfigs.price_includes_vat) {
                    new_data["income"] = Math.round((incomeRawData.total_income / 100 * (100 - billingConfigs.vat)) * 100) / 100
                    new_data["tax"] = Math.round((incomeRawData.total_income / 100 * billingConfigs.vat) * 100 ) / 100
                } else {
                    const total_income = incomeRawData.total_income * 100 / (100 - billingConfigs.vat)
                    new_data["income"] = incomeRawData.total_income
                    new_data["tax"] = Math.round((total_income - incomeRawData.total_income) * 100 ) / 100
                }
            } else {
                new_data["income"] = incomeRawData.total_income
                new_data["tax"] = 0
            }
            setIncomeData(new_data)
        }
    },[
        billingConfigs,
        incomeRawData
    ]);

    useEffect(() => {
        handleUsageLoading(true)
        let query_params = `?month_time=${usageDate.toISOString()}`

        if (usageRegion.length > 0) {
            query_params = query_params + `&region_name=${usageRegion}`
        }
        const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingResourceUsageSummaryEP}${query_params}`
        let request_data = {
            url: url,
            method: "GET"
        }
            billingRequest(request_data).then((response) => {
                if (response.status_code === 200) {
                    let new_data = {}
                    new_data["prepaid"] = response.data.total_usage_prepaid
                    new_data["postpaid"] = response.data.total_usage_postpaid
                    setUsageData(new_data)
                }
                handleUsageLoading(false)
            }).catch((err) => {
                handleUsageLoading(false)
        })
    },[
        usageDate,
        usageRegion
    ]);

    useEffect(() => {
        handleIncomeCompareLoading(true)
        const start_year = incomeCompareStartDate.getFullYear()
        const start_month = incomeCompareStartDate.getMonth() + 1 < 10 ? `0${incomeCompareStartDate.getMonth() + 1}` : incomeCompareStartDate.getMonth() + 1
        const end_year = incomeCompareEndDate.getFullYear()
        const end_month = incomeCompareEndDate.getMonth() + 1 < 10 ? `0${incomeCompareEndDate.getMonth() + 1}` : incomeCompareEndDate.getMonth() + 1
        let query_params = `?start_time=${start_year}-${start_month}-01&end_time=${end_year}-${end_month}-01`
        if (incomeCompareRegion.length > 0) {
            query_params = query_params + `&region_name=${incomeCompareRegion}`
        }
        const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingIncomeCompareEP}${query_params}`
        let request_data = {
            url: url,
            method: "GET"
        }
            billingRequest(request_data).then((response) => {
                if (response.status_code === 200) {
                    setIncomeCompareRawData(response.data)
                }
                handleIncomeCompareLoading(false)
            }).catch((err) => {
                handleIncomeCompareLoading(false)
        })
    },[
        incomeCompareStartDate,
        incomeCompareEndDate,
        incomeCompareRegion
    ]);

    useEffect(() => {
        if (Object.keys(incomeCompareRawData).length > 0) {
            let new_data = {}
            const times_data = incomeCompareRawData.map(item => item.time)
            new_data["times"] = times_data
            new_data["incomes"] = []
            new_data["taxes"] = []
            if (billingConfigs.use_vat_tax) {
                if (billingConfigs.price_includes_vat) {
                    new_data["incomes"] = incomeCompareRawData.map(item => Math.round((item.total_income / 100 * (100 - billingConfigs.vat)) * 100) / 100)
                    new_data["taxes"] = incomeCompareRawData.map(item => Math.round((item.total_income / 100 * billingConfigs.vat) * 100) / 100)
                } else {
                    for (let i in incomeCompareRawData) {
                        const total_income = incomeCompareRawData[i].total_income * 100 / (100 - billingConfigs.vat)
                        const income = Math.round(incomeCompareRawData[i].total_income * 100) / 100
                        const tax = Math.round((total_income - incomeCompareRawData[i].total_income) * 100) / 100
                        new_data["incomes"].push(income)
                        new_data["taxes"].push(tax)
                    }
                }
            } else {
                new_data["incomes"] = incomeCompareRawData.map(item => item.total_income)
            }
            setIncomeCompareData(new_data)
        }
    },[
        billingConfigs,
        incomeCompareRawData
    ]);

    useEffect(() => {
        handleUsageCompareLoading(true)
        const start_year = usageCompareStartDate.getFullYear()
        const start_month = usageCompareStartDate.getMonth() + 1 < 10 ? `0${usageCompareStartDate.getMonth() + 1}` : usageCompareStartDate.getMonth() + 1
        const end_year = usageCompareEndDate.getFullYear()
        const end_month = usageCompareEndDate.getMonth() + 1 < 10 ? `0${usageCompareEndDate.getMonth() + 1}` : usageCompareEndDate.getMonth() + 1
        let query_params = `?start_time=${start_year}-${start_month}-01&end_time=${end_year}-${end_month}-01`

        if (usageCompareRegion.length > 0) {
            query_params = query_params + `&region_name=${usageCompareRegion}`
        }
        const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingResourceUsageCompareEP}${query_params}`
        let request_data = {
            url: url,
            method: "GET"
        }
            billingRequest(request_data).then((response) => {
                if (response.status_code === 200) {
                    let new_data = {}
                    const times_data = response.data.map(item => item.time)
                    new_data["times"] = times_data
                    new_data["prepaid"] = response.data.map(item => item.total_usage_prepaid)
                    new_data["postpaid"] = response.data.map(item => item.total_usage_postpaid)
                    setUsageCompareData(new_data)
                }
                handleUsageCompareLoading(false)
            }).catch((err) => {
                handleUsageCompareLoading(false)
        })
        
    },[
        usageCompareRegion,
        usageCompareStartDate,
        usageCompareEndDate
    ]);

    useEffect(() => {
        handleTopNProjectsLoading(true)
        let query_params = `?month_time=${topNProjectsStartDate.toISOString()}&limit=${topNProjectsNumber}`

        const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingTopNProjectsEP}${query_params}`
        let request_data = {
            url: url,
            method: "GET"
        }
        billingRequest(request_data).then((response) => {
            if (response.status_code === 200) {
                let new_data = {}
                new_data["values"] = response.data.map(item => item.total_cost)
                new_data["projects"] = response.data.map(item => {
                    const project_name = projects.filter(p => p.id === item.project_id)
                    if (project_name.length > 0) {
                        return project_name[0].name
                    } else {
                        return item.project_id
                    }
                })
                setTopNProjectsData(new_data)
            }
            handleTopNProjectsLoading(false)
        }).catch((err) => {
            handleTopNProjectsLoading(false)
    })
    },[
        topNProjectsStartDate,
        topNProjectsNumber,
        projects
    ]);

    return (
        <Box>
            <Grid container spacing={2} sx={{mt: 1}}>
                <Grid item xs={12} md={6}>
                    <DashboardCard cardTitle={defaultTexts.incomeSummaryCardTitle}>
                        <Grid container sx={{mt: 1}} spacing={2} justifyContent="center">
                            <Grid item>
                                <DatePicker 
                                    views={['year', 'month']}
                                    openTo="month"
                                    value={incomeDate}
                                    onChange={(newValue) => setIncomeDate(newValue)}
                                    sx={{width: 200}}
                                />
                            </Grid>
                            <Grid item>
                                <CustomSelectField 
                                    currentValue={incomeRegion}
                                    setCurrentValue={setIncomeRegion}
                                    label={defaultTexts.regionFormFieldLabel}
                                    required={false}
                                    items={regions}
                                    self_item_titles={true}
                                    size="large"
                                    sx={{width: 200}}
                                />
                            </Grid>
                        </Grid>
                        {!incomeLoading ? 
                            <CustomPieChart data={incomeData.income > 0 ? [{
                                label: `${defaultTexts.incomePieChartLabel} ${billingConfigs.currency ? 
                                billingConfigs.currency.name.toUpperCase() : ""}`, 
                                value: incomeData.income},{
                                    label: `${defaultTexts.taxPieChartLabel} ${billingConfigs.currency ? 
                                billingConfigs.currency.name.toUpperCase() : ""}`, 
                                    value: incomeData.tax}] : []} /> : 
                            <CustomSkeleton />
                        }
                    </DashboardCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    <DashboardCard cardTitle={defaultTexts.usageSummaryCardTitle}>
                        <Grid 
                            container sx={{mt: 1}} 
                            spacing={2} 
                            justifyContent="center"
                        >
                            <Grid item>
                                <DatePicker 
                                    views={['year', 'month']}
                                    openTo="month"
                                    value={usageDate}
                                    onChange={(newValue) => setUsageDate(newValue)}
                                    sx={{width: 200}}
                                />
                            </Grid>
                            <Grid item>
                                <CustomSelectField 
                                    currentValue={usageRegion}
                                    setCurrentValue={setUsageRegion}
                                    label={defaultTexts.regionFormFieldLabel}
                                    required={false}
                                    items={regions}
                                    self_item_titles={true}
                                    size="large"
                                    sx={{width: 200}}
                                />
                            </Grid>
                        </Grid>
                        {!usageLoading ? 
                            <CustomPieChart 
                                data={(usageData.prepaid > 0 || usageData.postpaid > 0) ? [{
                                    label: `${defaultTexts.prepaidPieChartLabel} ${billingConfigs.currency ? 
                                billingConfigs.currency.name.toUpperCase() : ""}`, 
                                    value: usageData.prepaid, 
                                    color: theme.palette.mode === "dark" ? theme.palette.dashboardPinkDark : theme.palette.dashboardPink},
                                    {label: `${defaultTexts.postpaidPieChartLabel} ${billingConfigs.currency ? 
                                billingConfigs.currency.name.toUpperCase() : ""}`, value: usageData.postpaid, 
                                        color: theme.palette.mode === "dark" ? theme.palette.dashboardPurpleDark : theme.palette.dashboardPurple}] : []} 
                            /> : 
                            <CustomSkeleton />
                        }
                    </DashboardCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    <DashboardCard cardTitle={defaultTexts.compareIncomeCardTitle}>
                        <Grid 
                            container 
                            sx={{mt: 1}} 
                            spacing={2} 
                            justifyContent="center"
                        >
                            <Grid item>
                                <DatePicker 
                                    views={['year', 'month']}
                                    openTo="month"
                                    value={incomeCompareStartDate}
                                    onChange={(newValue) => setIncomeCompareStartDate(newValue)}
                                    maxDate={incomeCompareEndDate}
                                    sx={{width: 200}}
                                />
                            </Grid>
                            <Grid item>
                                <DatePicker 
                                    views={['year', 'month']}
                                    openTo="month"
                                    value={incomeCompareEndDate}
                                    onChange={(newValue) => setIncomeCompareEndDate(newValue)}
                                    minDate={incomeCompareStartDate}
                                    sx={{width: 200}}
                                />
                            </Grid>
                            <Grid item>
                                <CustomSelectField 
                                    currentValue={incomeCompareRegion}
                                    setCurrentValue={setIncomeCompareRegion}
                                    label={defaultTexts.regionFormFieldLabel}
                                    required={false}
                                    items={regions}
                                    self_item_titles={true}
                                    size="large"
                                    sx={{width: 200}}
                                />
                            </Grid>
                        </Grid>
                        {!incomeCompareLoading ? 
                            <CustomBarChart 
                                data={incomeCompareData.incomes && incomeCompareData.times ? {
                                    aData: {
                                        values: incomeCompareData.incomes,
                                        label: `${defaultTexts.incomePieChartLabel} ${billingConfigs.currency ? 
                                billingConfigs.currency.name.toUpperCase() : ""}`
                                    },
                                    bData: {
                                        values: incomeCompareData.taxes,
                                        label: `${defaultTexts.taxPieChartLabel} ${billingConfigs.currency ? 
                                billingConfigs.currency.name.toUpperCase() : ""}`
                                    },
                                    units: incomeCompareData.times
                                } : {aData: {
                                        values: [],
                                        label: defaultTexts.incomePieChartLabel
                                    },
                                    bData: {
                                        values: [],
                                        label: defaultTexts.taxPieChartLabel
                                    }
                                }}
                            /> :
                            <CustomSkeleton />
                        }
                    </DashboardCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    <DashboardCard cardTitle={defaultTexts.compareUsageCardTitle}>
                        <Grid 
                            container 
                            sx={{mt: 1}} 
                            spacing={2} 
                            justifyContent="center"
                        >
                            <Grid item>
                                <DatePicker 
                                    views={['year', 'month']}
                                    openTo="month"
                                    value={usageCompareStartDate}
                                    maxDate={usageCompareEndDate}
                                    onChange={(newValue) => setUsageCompareStartDate(newValue)}
                                    sx={{width: 200}}
                                />
                            </Grid>
                            <Grid item>
                                <DatePicker 
                                    views={['year', 'month']}
                                    openTo="month"
                                    value={usageCompareEndDate}
                                    minDate={usageCompareStartDate}
                                    onChange={(newValue) => setUsageCompareEndDate(newValue)}
                                    sx={{width: 200}}
                                />
                            </Grid>
                            <Grid item>
                                <CustomSelectField 
                                    currentValue={usageCompareRegion}
                                    setCurrentValue={setUsageCompareRegion}
                                    label={defaultTexts.regionFormFieldLabel}
                                    required={false}
                                    items={regions}
                                    self_item_titles={true}
                                    size="large"
                                    sx={{width: 200}}
                                />
                            </Grid>
                        </Grid>
                        {!usageCompareLoading ? 
                            <CustomBarChart 
                                colors={[
                                    theme.palette.mode === "dark" ? 
                                    theme.palette.dashboardPinkDark : 
                                    theme.palette.dashboardPink,
                                    theme.palette.mode === "dark" ? 
                                    theme.palette.dashboardPurpleDark : 
                                    theme.palette.dashboardPurple]}
                                data={usageCompareData.prepaid || usageCompareData.postpaid ? {
                                    aData: {
                                        values: usageCompareData.prepaid,
                                        label: `${defaultTexts.prepaidPieChartLabel} ${billingConfigs.currency ? 
                                billingConfigs.currency.name.toUpperCase() : ""}`
                                    },
                                    bData: {
                                        values: usageCompareData.postpaid,
                                        label: `${defaultTexts.postpaidPieChartLabel} ${billingConfigs.currency ? 
                                billingConfigs.currency.name.toUpperCase() : ""}`
                                    },
                                    units: usageCompareData.times
                                } : 
                                {aData: {
                                    values: [],
                                    label: defaultTexts.prepaidPieChartLabel
                                },
                                bData: {
                                    values: [],
                                    label: defaultTexts.postpaidPieChartLabel
                                }
                            }
                            }
                            /> :
                            <CustomSkeleton />
                        }
                    </DashboardCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    <DashboardCard cardTitle={defaultTexts.nTopProjectsCardTitle}>
                        <Grid 
                            container 
                            sx={{mt: 1}} 
                            spacing={2} justifyContent="center"
                        >
                            <Grid item>
                                <DatePicker 
                                    views={['year', 'month']}
                                    openTo="month"
                                    value={topNProjectsStartDate}
                                    onChange={(newValue) => setTopNProjectsStartDate(newValue)}
                                    sx={{width: 200}}
                                />
                            </Grid>
                            <Grid item>
                                <CustomTextField 
                                    currentValue={topNProjectsNumber}
                                    setCurrentValue={(event) => setTopNProjectsNumber(event.target.value)}
                                    label={defaultTexts.topNProjectsFormFieldLabel}
                                    default={5}
                                    type="number"
                                    size="large"
                                    sx={{width: 200}}
                                />
                            </Grid>
                        </Grid>
                        {!topNProjectsLoading ? 
                            <CustomBarChart 
                                colors={[
                                    theme.palette.mode === "dark" ? 
                                    theme.palette.customBlueDark : 
                                    theme.palette.customBlue]}
                                data={topNProjectsData.values && topNProjectsData.values.length > 0 ? {
                                    aData: {
                                        values: topNProjectsData.values,
                                        label: `${defaultTexts.projectUsagePieChartLabel} ${billingConfigs.currency ? 
                                billingConfigs.currency.name.toUpperCase() : ""}`
                                    },
                                    units: topNProjectsData.projects
                                } : []}
                                orientation={"horizontal"}
                            /> :
                            <CustomSkeleton />
                        }
                    </DashboardCard>
                </Grid>
                <Grid item xs={12} md={6}>
                    <DashboardCard cardTitle={defaultTexts.serviceBreakdownCardTitle}>
                        <Grid container sx={{mt: 1}} spacing={2} justifyContent="center">
                            <Grid item>
                                <DatePicker 
                                    views={['year', 'month']}
                                    openTo="month"
                                    value={serviceBreakdownsDate}
                                    onChange={(newValue) => setServiceBreakdownsDate(newValue)}
                                    sx={{width: 200}}
                                />
                            </Grid>
                            <Grid item>
                                <CustomSelectField 
                                    currentValue={serviceBreakdownsRegion}
                                    setCurrentValue={setServiceBreakdownsRegion}
                                    label={defaultTexts.regionFormFieldLabel}
                                    required={false}
                                    items={regions}
                                    self_item_titles={true}
                                    size="large"
                                    sx={{width: 200}}
                                />
                            </Grid>
                        </Grid>
                        {!serviceBreakdownsLoading ? 
                            <CustomPieChart data={serviceBreakdowns} /> : 
                            <CustomSkeleton />
                        }
                    </DashboardCard>
                </Grid>
            </Grid>
        </Box>
    )
};

export default BillingDashboard;