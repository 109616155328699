import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CustomDialog from './CustomDialog';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

const AddButton = (props) => {
    const { onSubmit, formReset, preDialogClose } = props;
    const { getDataForm } = props
    const { customTexts } = props
    const { color } = props;
    const { size } = props;
    const { sx } = props;

    const [dialogOpen, setDialogOpen] = useState(false);
    const defaultTexts = useSelector(state => state.texts.langTexts)

    const formSubmit = async () => {
        if (preDialogClose) {
            setDialogOpen(false)
        }
        const result = await onSubmit();
        if (result) {
            setDialogOpen(false)
        } else {
            setDialogOpen(true)
        }
    }

    const onClick = () => {
        setDialogOpen(true)
    }

    const onClose = () => {
        formReset()
        setDialogOpen(false)
    }

    return (
        <React.Fragment>
            <Fab
                onClick={onClick}
                color={color ? color : 'primary'}
                size={size ? size : 'small'}
                sx={{m: 1, ...sx}}
            >
                <AddIcon />
            </Fab>
            <CustomDialog
                open={dialogOpen}
                onClose={onClose}
                dialogTitle={{
                    title: customTexts.title, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: customTexts.description ? customTexts.description : "", 
                    sx: {color: 'text.primary', mb: 3}}}
                actionButtons={[{
                    title: defaultTexts.submitButtonText, 
                    onClick: formSubmit, 
                    variant: "contained",
                    size: "medium"
                }]}
            >
                {getDataForm()}
                {props.children}
            </CustomDialog>
        </React.Fragment>
    )
};

export default AddButton;