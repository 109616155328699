import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import Grid from "@mui/material/Grid";
import CustomCard from '../../../_common/CustomCard';
import Box from '@mui/material/Box';
import { PieChart, pieArcLabelClasses  } from '@mui/x-charts/PieChart';
import { RxReset } from "react-icons/rx";
import { IconButton } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import Dimensions from '../../../../config/dimensions';
import useWindowDimensions from '../../../_common/WindowDimensions';
import Skeleton from '@mui/material/Skeleton';
import MuiStack from '@mui/material/Stack';
import CustomSelectField from 
'../../../_common/_form_fields/CustomSelectField';
import { 
    openstackRequest,
    getXAuthTokenProjectScope } from 
'../../../../_network/openstack_request';
import CustomText from '../../../_common/CustomText';
import Typography from '@mui/material/Typography';
import { projectsUrl as projectsUrlResponses } from 
'../../../../_api_responses/openstack/identity/projects/v3';
import { imagesUrl as imagesUrlResponses } from 
'../../../../_api_responses/openstack/glance/images/v2';
import { 
    identityKeystonConstants, 
    imagesGlanceConstants,
    openStackServices
} from '../../../../config/openStackConstants';
import Constants from '../../../../config/constants';


const SERVICE_NAME = openStackServices.imageService
const IDENTITY_SERVICE_NAME = openStackServices.identityService

const drawerWidth = Constants.drawerWidth;

const ImagesGraphContentV2 = (props) => {
    const open = useSelector(state => state.drawer.drawerOpened);
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const isAuthenticated = useSelector(state => state.profile.isAuthenticated)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const { width } = useWindowDimensions();
    
    const [selectedProject, setSelectedProject] = useState("")
    const [imagesLoading, setImagesLoading] = useState(true)
    const [projects, setProjects] = useState([]);
    const [publicImagesSize, setPublicImagesSize] = useState(0);
    const [publicImagesUnit, setPublicImagesUnit] = useState("");
    const [privateImagesSize, setPrivateImagesSize] = useState(0);
    const [privateImagesUnit, setPrivateImagesUnit] = useState("");
    const [imagesData, setImagesData] = useState([]);

    const navigate = useNavigate();
    const theme = useTheme();

    if (!isAuthenticated) {
        navigate('/');
    }

    const getCardHeight = (width) => {
        if (width - (open ? drawerWidth : 110) < Dimensions.tablet_mini.width) {
            return 600
        } else if (((width - (open ? drawerWidth : 110)) / 2) < 600) {
            return 600
        } else {
            return 400
        }
    }

    const CARD_HEIGHT = getCardHeight(width)

    const imageServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const imageServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const imagesUrl = useSelector(
        state => state.imageGlance.imageGlanceApiUrls.filter(
            version => version.api_version === imageServiceVersion)[0].urls.filter(
                url => url.keyword === imagesGlanceConstants.imagesUrl)[0].url)
    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === IDENTITY_SERVICE_NAME)[0].config_params.api_version)
    const projectsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === identityServiceVersion)[0].urls.filter(
                url => url.keyword === identityKeystonConstants.projectsUrl)[0].url)

    const handleImagesLoading = (mode) => {
        setImagesLoading(mode)
    }

    const handleSelectedProjectReset = () => {
        setSelectedProject("")
    }

    const purchasedServices = useSelector(state => 
        state.openstack.purchasedServices);

    const purchasedService = purchasedServices.filter(
        item => item.service === SERVICE_NAME)

    const DashboardCard = (props) => {
        const { cardTitle } = props
        return (
            <CustomCard
                cardWidth={'100%'} 
                cardHeight={CARD_HEIGHT}
                cardTitle={cardTitle}
                titleSize="p"
                titleColor="primary.main"
                card_sx={{
                    border: theme.palette.mode === "dark" ? 0 : 1, 
                    boxShadow: theme.palette.mode === "dark" ? 0 : 1, 
                    backgroundColor: 
                        theme.palette.mode === "dark" ? 
                        undefined :
                        "vLightGray"
                }}
                box_sx={{pr: 2}}
            >
                {props.children}
            </CustomCard>
        )
    }

    const CustomPieChart = (props) => {
        const { data } = props
        return (
            <Box sx={{
                height: CARD_HEIGHT > 400 ? 380 : 260, 
                alignItems: "center", 
                justifyContent: "center"}}
            >
                {data.length > 0 && <PieChart
                    skipAnimation={true}
                    slotProps={{ 
                        legend: {
                            position: CARD_HEIGHT > 400 ? 
                            { vertical: "bottom"} : 
                            { horizontal: "right"}},  
                        }}
                    series={[
                        {
                        arcLabel: (i) => `${i.value}`,
                        arcLabelMinAngle: 15,
                        data: data,
                        innerRadius: 40,
                        outerRadius: 110,
                        paddingAngle: 5,
                        cornerRadius: 4,
                        startAngle: -300,
                        endAngle: 147,
                        cx: 170,
                        cy: 130,
                        }
                    ]}
                    sx={{
                        [`& .${pieArcLabelClasses.root}`]: {
                        fill: 'white',
                        fontWeight: '500',
                        },
                    }}
                />}
                { data.length === 0 && 
                    <Box sx={{
                        width: "100%", 
                        height: "100%",
                        display: "flex", 
                        alignItems: "center", 
                        justifyContent: "center",
                        mt: 1
                    }}>
                        <Box
                            sx={{
                                width: "80%",
                                height: "80%",
                                border: "1px dashed",
                                borderRadius: 2,
                                borderColor: "customBlue",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                        <CustomText sx={{fontSize: 20, color: "customBlue"}}>
                            {defaultTexts.noImagesNoteTitle}
                        </CustomText>
                        </Box>
                    </Box>
                }
            </Box>
        )
    }

    const CustomSkeleton = () => {
        return (
            <Grid container spacing={2} 
                justifyContent="space-around" 
                alignItems="center" 
                sx={{mt: 3}}
            >
                <Grid item>
                    <Skeleton height={220} width={220} variant="circular" />
                </Grid>
                <Grid item>
                    <MuiStack>
                        <Skeleton width={220} height={40}>
                            <Typography>.</Typography>
                        </Skeleton>
                        <Skeleton width={220} height={40}>
                            <Typography>.</Typography>
                        </Skeleton>
                        <Skeleton width={220} height={40}>
                            <Typography>.</Typography>
                        </Skeleton>
                        <Skeleton width={220} height={40}>
                            <Typography>.</Typography>
                        </Skeleton>
                    </MuiStack>
                </Grid>
            </Grid>
        )
    }

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${projectsUrl}`
            const method = "GET"

            const projects_response = await openstackRequest({url:url, method:method})
            if (projects_response.status_code === projectsUrlResponses.get.success_response.status_code) {
                const projectFilter = projects_response.data.projects.map(item => {
                    return {keyword: item.name, value: item.id, default: false}
                })
                setProjects(projectFilter)
            }
        })();
    },[
        identityServiceDomain, 
        identityServiceVersion, 
        projectsUrl
    ]);

    useEffect(() => {
        (async () => {
            handleImagesLoading(true)
            const url = `${imageServiceDomain}/${imageServiceVersion}/${imagesUrl}`
            const method = "GET"
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const images_response = await openstackRequest({url:url, method:method, token: project_token})
                if (images_response.status_code === imagesUrlResponses.get.success_response.status_code) {
                    setImagesData(images_response.data.images)
                }
            }
            setTimeout(()=>{handleImagesLoading(false)},100)
        })();
    },[
        imageServiceDomain, 
        imageServiceVersion, 
        imagesUrl, 
        defaultAdminProject
    ]);

    useEffect(() => {
        let filtered_public_images = []
        let filtered_private_images = []
        if (selectedProject.length > 0) {
            filtered_public_images = imagesData.filter(image => image.owner === selectedProject && image.visibility !== "private")
            filtered_private_images = imagesData.filter(image => image.owner === selectedProject && image.visibility === "private")
        } else {
            filtered_public_images = imagesData.filter(image => image.visibility !== "private")
            filtered_private_images = imagesData.filter(image => image.visibility === "private")
        }
        let public_images_size = 0
        let private_images_size = 0
        let public_unit = "Bytes"
        let private_unit = "Bytes"
        for (let i in filtered_public_images) {
            public_images_size += filtered_public_images[i].size
        }
        for (let i in filtered_private_images) {
            private_images_size += filtered_private_images[i].size
        }
        if (public_images_size > 1073741823) {
            public_images_size = parseInt(public_images_size / 1073741824)
            public_unit = "GB"
        } else if (public_images_size > 1048575) {
            public_images_size = parseInt(public_images_size / 1048576)
            public_unit = "MB"
        } else if (public_images_size > 1023) {
            public_images_size = parseInt(public_images_size / 1024)
            public_unit = "KB"
        }

        if (private_images_size > 1073741823) {
            private_images_size = parseInt(private_images_size / 1073741824)
            private_unit = "GB"
        } else if (private_images_size > 1048575) {
            private_images_size = parseInt(private_images_size / 1048576)
            private_unit = "MB"
        } else if (private_images_size > 1023) {
            private_images_size = parseInt(private_images_size / 1024)
            private_unit = "KB"
        }
        
        setPublicImagesSize(public_images_size)
        setPublicImagesUnit(public_unit)
        setPrivateImagesSize(private_images_size)
        setPrivateImagesUnit(private_unit)
    },[
        projects,
        selectedProject,
        imagesData
    ])

    if (purchasedService[0].config_params.api_version === "v2") {
        return (
            <DashboardCard>
                <CustomText 
                    sx={{
                        color: "primary.main", 
                        fontSize: 20
                    }}>
                        {defaultTexts.imagesSummaryCardTitle}
                </CustomText>
                <Grid 
                    container 
                    spacing={2} 
                    justifyContent="flex-end"
                    alignItems="center"
                >
                    <Grid item>
                        {selectedProject.length > 0 && 
                            <Tooltip title={defaultTexts.resetFiltersTooltipText}>
                                <IconButton onClick={handleSelectedProjectReset}>
                                    <RxReset 
                                        color={theme.palette.text.primary}
                                        fontSize={'large'}
                                    />
                                </IconButton>
                            </Tooltip>}
                    </Grid>
                    <Grid item>
                        <CustomSelectField 
                            currentValue={selectedProject}
                            setCurrentValue={setSelectedProject}
                            label={defaultTexts.projectFormFieldLabel}
                            required={false}
                            items={projects}
                            self_item_titles={true}
                            size="large"
                            sx={{width: 250}}
                        />
                    </Grid>
                </Grid>
                {!imagesLoading ? 
                    <CustomPieChart data={(publicImagesSize > 0 || privateImagesSize > 0 ) ? [{
                        label: `${defaultTexts.publicImagesSizeLegentTitle} (${publicImagesUnit})`, 
                        value: publicImagesSize
                    },{
                        label: `${defaultTexts.privateImagesSizeLegentTitle} (${privateImagesUnit})`, 
                        value: privateImagesSize
                    }] : []} /> : 
                    <CustomSkeleton />
                }
            </DashboardCard>
        )
    }
};

export default ImagesGraphContentV2;