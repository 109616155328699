import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import IdentityKeystoneGroupsSubheaderV3 from 
'./identityKeystoneGroupsSubheaderV3';
import IdentityGroupsTableV3 from './identityGroupsTableV3';
import { identityKeystonConstants } from 
'../../../../../config/openStackConstants';
import { openstackRequest } from 
'../../../../../_network/openstack_request';
import { groupsUrl as groupsUrlResponses } from 
'../../../../../_api_responses/openstack/identity/groups/v3';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { groupsfilterMenu } from 
'../../../../../_data/openstack/identity/groups/v3';
import CustomDialog from '../../../../_common/CustomDialog';
import CustomSideDrawer from '../../../../_common/CustomSideDrawer';
import IdentityGroupDetailV3 from './identityGroupDetailV3';
import CustomBackdrop from '../../../../_common/CustomBackdrop';
import useWindowDimensions from '../../../../_common/WindowDimensions';
import Dimensions from '../../../../../config/dimensions';
import Box from '@mui/material/Box';

const SERVICE_NAME = openStackServices.identityService

const getWidthWeight = (width) => {
    if (width < Dimensions.tablet_mini.width) {
        return 0.9
    } else if (width < Dimensions.tablet.width) {
        return 0.6
    } else {
        return 0.4
    }
}

const IdentityGroupsWrapperV3 = (props) => {
    const [isLoading, setIsLoading ] = useState(true);
    const [filterMenu, setFilterMenu] = useState(groupsfilterMenu)
    const [groupsData, setGroupsData] = useState([])
    const [groups, setGroups] = useState([])
    const [domains, setDomains] = useState([]);
    const [error, setError] = useState()
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const { width } = useWindowDimensions();
    const WIDTH_WEIGHT = getWidthWeight(width)
    const [detailCardOpen, setDetailCardOpen] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const [selectedFilter, setSelectedFilter ] = useState(filterMenu[0].value)
    const [selectedFilterValue, setSelectedFilterValue] = useState("");
    const [filterQueryParams, setFilterQueryParams] = useState("")
    const apiResponseTexts = useSelector(state => state.texts.langTexts);

    const [fetchDataRequired, setFetchDataRequired] = useState(true);

    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const groupsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.groupsUrl)[0].url)

    const domainsUrl = useSelector(
        state => state.identityKeystone.identityKeystoneApiUrls.filter(
            version => version.api_version === "v3")[0].urls.filter(
                url => url.keyword === identityKeystonConstants.domainsUrl)[0].url)

    const handleDataFetch = () => {
        setFetchDataRequired(true)
    }

    const handleLoading = (mode) => {
        setIsLoading(mode)
    }

    const handleFilteredSearch = () => {
        if (selectedFilter && selectedFilterValue) {
            setFilterQueryParams(`/?${selectedFilter}=${selectedFilterValue}`)
        } else {
            setFilterQueryParams("")
        }
    }

    const handleFilterReset = () => {
        setSelectedFilter(filterMenu[0].value)
        setSelectedFilterValue("")
        setFilterQueryParams("")
    }

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleDetailCardOpen = (index) => {
        setSelectedRow(groupsData[index])
        setTimeout(() => setDetailCardOpen(true), 100) 
    };

    const handleDetailCardClose = () => {
        setSelectedRow(null)
        setTimeout(() => setDetailCardOpen(false), 100) 
    };

    useEffect(() => {
        setFetchDataRequired(true)
    },[filterQueryParams]);

    useEffect(() => {
        const domains_filter = domains.map(domain => {
            return {keyword: domain.name, value: domain.id, default: false}
        })

        const new_filter_menu = groupsfilterMenu.map(item => {
            if (item.value === "domain_id") {
                item.items = domains_filter
            }
            return item
        })
        setFilterMenu(new_filter_menu)

        let updated_groups_data = groupsData.map(group => {
            const domain = domains.filter(item => item.id === group.domain_id)
            
            let updated_data = {...group}
            if (domain.length > 0) {
                updated_data.domain_id = domain[0].name
            }

            return updated_data
        })
        setGroups(updated_groups_data)
        setIsLoading(false)
    },[
        domains, 
        groupsData
    ]);

    useEffect(() => {
        (async () => {
            const url = `${identityServiceDomain}/${identityServiceVersion}/${domainsUrl}`
            const method = "GET"

            const domains_response = await openstackRequest({url:url, method:method})
            if (domains_response.status_code === groupsUrlResponses.get.success_response.status_code) {
                setDomains([...domains_response.data.domains])
            } else {
                setDomains([])
            }
        })();
    },[
        identityServiceDomain,
        identityServiceVersion,
        domainsUrl,
        groupsData
    ]);

    useEffect(() => {
        if (fetchDataRequired) {
        (async () => {
            handleLoading(true)
            const url = `${identityServiceDomain}/${identityServiceVersion}/${groupsUrl}${filterQueryParams}`
            const method = "GET"

            const groups_response = await openstackRequest({url:url, method:method})
            if (groups_response.status_code === groupsUrlResponses.get.success_response.status_code) {
                setGroupsData([...groups_response.data.groups])
                if (selectedRow) {
                    let new_data = groups_response.data.groups.filter(group => group.id === selectedRow.id)
                    if (new_data.length > 0) {
                        setSelectedRow(new_data[0])
                    } else {
                        setSelectedRow(null)
                    }
                }
            } else {
                const error_response = groupsUrlResponses.get.error_response.filter(
                    error_item => error_item.status_code === groups_response.status_code)
                if (error_response.length > 0) {
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: groups_response.error
                    }
                    setError(errorObject)
                } else {
                    const error_response = groupsUrlResponses.get.error_response.filter(
                        error_item => error_item.status_code === "unknown")
                    const errorObject = {
                        error_title: error_response[0].response_title, 
                        error_message: error_response[0].response_message,
                        error_details: groups_response.error
                    }
                    setError(errorObject)
                }
            }
        })();
        setFetchDataRequired(false)
        }
        setTimeout(()=>{handleLoading(false)},700)
    },[
        identityServiceDomain, 
        identityServiceVersion, 
        groupsUrl, 
        filterQueryParams,
        fetchDataRequired,
        selectedRow
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    return (
        <Box>
            <IdentityKeystoneGroupsSubheaderV3 
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                selectedFilterValue={selectedFilterValue}
                setSelectedFilterValue={setSelectedFilterValue}
                filterMenu={filterMenu}
                handleFilteredSearch={handleFilteredSearch}
                handleFilterReset={handleFilterReset}
                handleFetchData={handleDataFetch}
                domains={domains}
            />
            {isLoading && <CustomBackdrop open={isLoading} />}
            {!isLoading && <IdentityGroupsTableV3 
                groupsData={groups}
                handleRowSelection={handleDetailCardOpen}
            />}
            {selectedRow !== null && <CustomSideDrawer 
                open={detailCardOpen}
                widthWeight={WIDTH_WEIGHT}
                handleDrawerOpen={handleDetailCardOpen}
                handleDrawerClose={handleDetailCardClose}
            > 
                <IdentityGroupDetailV3 
                    selectedRow={selectedRow}
                    widthWeight={WIDTH_WEIGHT}
                    handleFetchData={handleDataFetch}
                    handleDrawerClose={handleDetailCardClose}
                    groupsRecords={groupsData}
                />            
            </CustomSideDrawer>}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: apiResponseTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${apiResponseTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>Details:</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`,
                    sx: {color: 'text.primary'}}}
            />}
        </Box>
    )
};

export default IdentityGroupsWrapperV3;