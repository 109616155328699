import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import WrapperBox from '../../../../../_common/WrapperBox';
import AddButton from '../../../../../_common/AddButton';
import FormGroup from '@mui/material/FormGroup';
import Constants from '../../../../../../config/constants';
import FilterButton from '../../../../../_common/FilterButton';
import CustomDialog from '../../../../../_common/CustomDialog';
import { 
    openStackServices, 
    networkNeutronConstants
} from '../../../../../../config/openStackConstants';
import { trunkDataForm } from 
'../../../../../../_data/openstack/neutron/trunks/v2.0';
import { getFormFieldComponent } from 
'../../../../../_common/_form_fields/form_helpers';
import { 
    getXAuthTokenProjectScope,
    openstackRequest 
} from '../../../../../../_network/openstack_request';
import { trunksUrl as trunkUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/trunks/v2.0';
import Grid from '@mui/material/Grid';


const SERVICE_NAME = openStackServices.networkService

const TrunksSubheaderV20 = (props) => {
    const { currentTab, handleTabChange } = props
    const { selectedFilter, setSelectedFilter } = props
    const { selectedFilterValue, setSelectedFilterValue } = props
    const { handleDataFetch } = props
    const { filterMenu } = props
    const { projects, subnets } = props 
    const { segmentsSupported, trunksSupported } = props
    const { handleFilteredSearch, handleFilterReset } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [error, setError] = useState()
    const [successTrunkAdd, setSuccessTrunkAdd] = useState()
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [formData, setFormData] = useState({})
    const [formDataOptions, setFormDataOptions] = useState({});
    const [ports, setPorts] = useState([]);

    const theme = useTheme();

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const trunksUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.trunksUrl)[0].url)
    const portsUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.portsUrl)[0].url)

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleSuccessDialogClose = () => {
        setSuccessTrunkAdd(null);
        setSuccessDialogOpen(false);
    }

    const handleAddTrunkFormReset = () => {
        setFormDataOptions({})
        let new_form_data = {}
        for (const n in trunkDataForm) {
            if (
                trunkDataForm[n].field_type === "string" || 
                trunkDataForm[n].field_type === "select"
                ) {
                new_form_data[trunkDataForm[n].field_key] = ""
            } else if (trunkDataForm[n].field_type === "bool") {
                new_form_data[trunkDataForm[n].field_key] = trunkDataForm[n].default_value ? 
                trunkDataForm[n].default_value : 
                false
            }
        }
        setFormData(new_form_data)
    }

    const handleFormDataChange = (event,field_key) => {
        setFormDataOptions({})
        let new_form_data = {...formData}
        if (trunkDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (trunkDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select")  {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setFormData(new_form_data)
    }

    const getDataForm = () => {
        let form = [...trunkDataForm]
        const project_ports = ports.filter(p => p.project_id === formData.project_id)
        return (
            <FormGroup>
                {form.map(field => {
                    let form_field_options = {...formDataOptions[field.field_key]}
                    if (field.field_key === "project_id") {
                        const projects_filter = projects.map(n => {
                            return {keyword: n.name, value: n.id, default: false}
                        })
                        form_field_options["items"] = [...projects_filter]
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = false
                    } else if (field.field_key === "port_id") {
                        const port_filter = project_ports.map(p => {
                            const subnet = subnets.filter(s => s.id === p.fixed_ips[0].subnet_id)
                            return {keyword: `${subnet[0].name}: ${p.fixed_ips[0].ip_address}`, value: p.id, default: false}
                        })
                        form_field_options["items"] = [...port_filter]
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = false
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            formData,
                            handleFormDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleFormDataValidation = () => {
        let validation_faild = true
        let updatedDataFormOptions = {...formDataOptions}
        for (let n in trunkDataForm) {
            if (trunkDataForm[n].required && !formData[trunkDataForm[n].field_key]) {
                validation_faild = false
                updatedDataFormOptions[trunkDataForm[n].field_key] = {}
                updatedDataFormOptions[trunkDataForm[n].field_key]["error"] = true
                updatedDataFormOptions[trunkDataForm[n].field_key]["errorText"] = defaultTexts[trunkDataForm[n].error_label]
            }
        }

        setFormDataOptions(updatedDataFormOptions)
        return validation_faild
    }

    const formatData = () => {
        let new_data = {...formData}
        return new_data
    }

    const onAddTrunk = async () => {
        const validateFormData = handleFormDataValidation()
        if (validateFormData) {
            let data = formatData()
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const url = `${neutronServiceDomain}/${neutronServiceVersion}/${trunksUrl}`
                const method = "POST"
                const trunk_response = await openstackRequest({
                    url: url, 
                    method: method, 
                    data: {trunk: data},
                    token: project_token
                })
                if (trunk_response.status_code === trunkUrlResponses.post.success_response.status_code) {
                    handleDataFetch()
                    handleAddTrunkFormReset()
                    setFormDataOptions({})
                } else {
                    const error_response = trunkUrlResponses.post_async.error_response.filter(
                        error_item => error_item.status_code === trunk_response.status_code)
                    if (error_response.length > 0) {
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: trunk_response.error
                        }
                        setError(errorObject)
                    } else {
                        const error_response = trunkUrlResponses.post.error_response.filter(
                            error_item => error_item.status_code === "unknown")
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: trunk_response.error
                        }
                        setError(errorObject)
                    }
                }
            }
        }
        return validateFormData
    }

    useEffect(() => {
        (async () => {
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                let url = `${neutronServiceDomain}/${neutronServiceVersion}/${portsUrl}`
                const method = "GET"
                const network_response = await openstackRequest({url:url, method:method, token: project_token})
                if (network_response.status_code === trunkUrlResponses.get.success_response.status_code) {
                    setPorts(network_response.data.ports)
                }
            }
        })();
    },[
        neutronServiceDomain, 
        neutronServiceVersion, 
        portsUrl,
        defaultAdminProject
    ]);

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessDialogOpen(true)
    },[successTrunkAdd]);

    useEffect(() => {
        if (Object.keys(formData).length === 0) {
            let new_form_data = {}
            for (const n in trunkDataForm) {
                if (
                    trunkDataForm[n].field_type === "string" || 
                    trunkDataForm[n].field_type === "select"
                    ) {
                    new_form_data[trunkDataForm[n].field_key] = ""
                } else if (trunkDataForm[n].field_type === "bool") {
                    new_form_data[trunkDataForm[n].field_key] = trunkDataForm[n].default_value ? 
                    trunkDataForm[n].default_value : 
                    false
                }
            }
            setFormData(new_form_data)
        }
    },[formData]);

    return (
        <WrapperBox 
            sx={{
                flexDirection: "row", 
                justifyContent: 'space-between',
                mt: 1
            }}>
            <Grid 
                container 
                justifyContent='space-between' 
                alignItems="center">
                <Grid item>
            <AddButton 
                getDataForm={getDataForm}               
                onSubmit={onAddTrunk}
                formReset={handleAddTrunkFormReset}
                customTexts={{
                    title: defaultTexts.addTrunkDialogTitle
                }}
            />
            </Grid>
            <Grid item sx={{flexGrow: 1}}>
            <Button
                onClick={() => handleTabChange("networks")}
                sx={{
                    color: currentTab === "networks" ? 
                    "lightBlue" : 
                    theme.palette.text.primary,
                    "&:hover": {
                        backgroundColor: "lightBlue",
                        color: "white"
                    }
                }}
            >
                {defaultTexts.networksTabButtonText}
            </Button>
            <Button
                onClick={() => handleTabChange("ports")}
                sx={{
                    color: currentTab === "ports" ? 
                    "lightBlue" : 
                    theme.palette.text.primary,
                    "&:hover": {
                        backgroundColor: "lightBlue",
                        color: "white"
                    }
                }}
            >
                {defaultTexts.portsTabButtonText}
            </Button>
            {segmentsSupported && <Button
                onClick={() => handleTabChange("segments")}
                sx={{
                    color: currentTab === "segments" ? 
                    "lightBlue" : 
                    theme.palette.text.primary,
                    "&:hover": {
                        backgroundColor: "lightBlue",
                        color: "white"
                    }
                }}
            >
                {defaultTexts.segmentsTabButtonText}
            </Button>}
            {trunksSupported && <Button
                onClick={() => handleTabChange("trunks")}
                sx={{
                    color: currentTab === "trunks" ? 
                    "lightBlue" : 
                    theme.palette.text.primary,
                    "&:hover": {
                        backgroundColor: "lightBlue",
                        color: "white"
                    }
                }}
            >
                {defaultTexts.trunksTabButtonText}
            </Button>}
                </Grid>
                <Grid item>
                <FilterButton 
                    choice_mode={Constants.simple_filter_type}
                    currentFilter={selectedFilter}
                    setCurrentFilter={setSelectedFilter}
                    filter_menu_titles={defaultTexts}
                    filter_menu={filterMenu}
                    currentFilterValue={selectedFilterValue}
                    setCurrentFilterValue={setSelectedFilterValue}
                    onFilterSubmit={handleFilteredSearch}
                    onFilterReset={handleFilterReset}
                />
                </Grid>
            </Grid>
            {successTrunkAdd && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[successTrunkAdd.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successTrunkAdd.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </WrapperBox>
    )
};

export default TrunksSubheaderV20