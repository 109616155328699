import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TablePagination from '@mui/material/TablePagination';
import Constants from '../../../config/constants'
import CustomText from '../../_common/CustomText';
import Box from '@mui/material/Box';
import { setBillingInvoicesTableRows } from 
'../../../store/reducers/settingsSlice';

const drawerWidth = Constants.drawerWidth;

const BillingInvoicesTable = (props) => {
    const { billingInvoices } = props
    const { openstackProjects } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const drawerOpen = useSelector(state => state.drawer.drawerOpened);
    const billingInvoicesTableRows = useSelector(state => state.settings.billingInvoicesTableRows)
    const [page, setPage] = useState(0);
    const [startItem, setStartItem] = useState(0);
    const [endItem, setEndItem] = useState(billingInvoices.length);

    const theme = useTheme();

    const statusColors = {
        paid: theme.palette.mode === "dark" ?
        theme.palette.dashboardGreenDark : 
        theme.palette.dashboardGreen,
        obsolete: theme.palette.mode === "dark" ?
        theme.palette.dashboardPinkDark : 
        theme.palette.dashboardPink,
        pending: theme.palette.mode === "dark" ?
        theme.palette.customBlueDark : 
        theme.palette.customBlue
    }
    
    const ROWS_PER_PAGE_LIST = billingInvoices.length > 100 ? 
    [5, 10, 25, 50, 100, billingInvoices.length] : 
    [5, 10, 25, 50, 100]

    const dispatch = useDispatch();

    const handleRowsPerPageChange = (event) => {
        const new_rows_per_page = parseInt(event.target.value)
        setPage(0);
        dispatch(setBillingInvoicesTableRows({
            billingInvoicesTableRows: new_rows_per_page
        }))
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    useEffect(() => {
        if (!billingInvoicesTableRows) {
            dispatch(setBillingInvoicesTableRows({
                billingInvoicesTableRows: 10
            }))
        }
    },[billingInvoicesTableRows,dispatch])

    useEffect(() => {
          const start_item = page*billingInvoicesTableRows
          const end_item = page*billingInvoicesTableRows+billingInvoicesTableRows
          setStartItem(start_item)
          setEndItem(end_item)
        },[
        page,
        billingInvoicesTableRows
      ]);

    return (
        <React.Fragment>
            {<Box sx={{
                maxWidth: drawerOpen ? 
                `calc(100vw - ${drawerWidth}px)` : 
                `calc(100vw - ${110}px)`,
                mt: 2
            }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 300}} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell  align="left">
                                    {defaultTexts.projectFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.timeStampFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.dueDateFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.invoiceIDTypeFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.vatTaxFormFieldLabel}
                                </TableCell>
                                <TableCell  align="left">
                                    {defaultTexts.statusFormFieldLabel}
                                </TableCell>
                                <TableCell  align="right">
                                    {defaultTexts.amountFormFieldLabel}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {billingInvoices.slice(startItem, endItem).map((row) => (
                                <TableRow 
                                    key={row.id}
                                >
                                    <TableCell align="left">{
                                        openstackProjects.filter(
                                            p => p.id === row.project_id).length > 0 ?
                                        openstackProjects.filter(
                                            p => p.id === row.project_id)[0].name :
                                        row.project_id}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.time_stamp.toLocaleString()}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.due_date.toLocaleString()}
                                    </TableCell>
                                    <TableCell align="left">
                                        {row.id}
                                    </TableCell>
                                    {row.use_vat_tax && <TableCell align="left">
                                        {row.vat_included ? 
                                            defaultTexts.priceIncludesVATTaxText : 
                                            defaultTexts.priceNotIncludesVATTaxText} {row.vat}{"%"}
                                    </TableCell>}
                                    {!row.use_vat_tax && <TableCell align="left">
                                        {defaultTexts.priceNotCountingVATTaxText}
                                    </TableCell>}
                                    <TableCell>
                                        <CustomText 
                                            sx={{
                                                color: statusColors[row.status],
                                                textTransform: "capitalize"
                                            }}
                                        >
                                            {row.status}
                                        </CustomText>
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.amount} {row.currency.name.toUpperCase()}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                    {billingInvoices.length > 0 && <TablePagination
                        rowsPerPageOptions={ROWS_PER_PAGE_LIST}
                        component="div"
                        count={billingInvoices.length}
                        rowsPerPage={billingInvoicesTableRows}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleRowsPerPageChange}
                        labelRowsPerPage={defaultTexts.rowsPerPageLabel}
                    />}
                </TableContainer>
            </Box>}
        </React.Fragment>
    )
};

export default BillingInvoicesTable;