import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import WrapperBox from '../../_common/WrapperBox';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from "@mui/material/Stack";
import useWindowDimensions from 
'../../_common/WindowDimensions';
import { getServiceDetailDataComponent } from '../_common/data_formatter';
import Constants from '../../../config/constants';
import { Grid }  from '@mui/material';
import ServiceContentHeader from 
'../../_common/ServiceContentHeader';
import CustomSelectField from 
'../../_common/_form_fields/CustomSelectField';
import CustomDialog from 
'../../_common/CustomDialog';
import { billingServiceDataSchema } 
from '../../../_data/billing/billing_data';
import billingRequest from '../../../_network/billing_request';
import { billingServiceURLs } from '../../../_network/apiUrls';
import BillingServiceForm from '../_common/forms/billingServiceForm';


const FOOTER_HEIGHT = Constants.actions_bar_height + 20

const BillingServiceDetail = (props) => {
    const [isCardLoading, setIsCardLoading] = useState(true)
    const clientID = useSelector(state => state.profile.clientAccountID)
    const [error, setError] = useState();
    const { selectedRow, handleDataFetch, handleLoading } = props
    const { widthWeight, handleDetailCardClose } = props
    const { width } = useWindowDimensions();
    const FOOTER_WIDTH =  width - (width * widthWeight)
    const defaultTexts = useSelector(state => state.texts.langTexts);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const purchasedService = useSelector(
        state => state.openstack.purchasedServicesAll)
    const [billingServicesChecked, setBillingServicesChecked] = useState(false)
    const [serviceUpdateAllowed, setServiceUpdateAllowed] = useState(false)
    const [serviceDeleteAllowed, setServiceDeleteAllowed] = useState(false)
    const [serviceDeleteDialogOpen, setServiceDeleteDialogOpen] = useState(false)

    const [serviceActions, setServiceActions] = useState([]);
    const [currentAction, setCurrentAction] = useState("");
    const [serviceUpdateDialogOpen, setServiceUpdateDialogOpen] = useState(false)
    const [serviceForm, setServiceForm] = useState({...selectedRow})
     
    const [serviceSubMenu, setServiceSubMenu] = useState([
        {keyword: "submenuDetails", navigation: "/service-details", is_active: true}
    ])

    const [currentTab, setCurrentTab] = useState("/service-details")
    
    const handleServiceDetailTabChange = useCallback((navigation) => {
        let newServiceSubmenuData = serviceSubMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
            } else {
                item.is_active = false
            }
            return item
        })
        setServiceSubMenu(newServiceSubmenuData)
        setCurrentTab(navigation)
    },[
        serviceSubMenu
    ])

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleServiceDeleteDialogOpen = () => {
        setServiceDeleteDialogOpen(true)
    }

    const handleServiceDeleteDialogClose = () => {
        setServiceDeleteDialogOpen(false)
    }

    const handleServiceUpdateDialogOpen = () => {
        setServiceUpdateDialogOpen(true)
    }

    const handleServiceUpdateDialogClose = () => {
        handleUpdateServiceFormReset()
        setServiceUpdateDialogOpen(false)
    }

    const handleServiceFormUpdate = (event, field_key) => {
        let updated_form = {...serviceForm}
        if (field_key === "service_domain" || 
            field_key === "resource_suspend_period" ||
            field_key === "resource_delete_period" ||
            field_key === "credentials_id" ||
            field_key === "credentials_secret"
        ) {
            updated_form[field_key] = event.target.value
        } else if (field_key === "is_identity_provider") {
            updated_form[field_key] = event.target.checked
        } else {
            updated_form[field_key] = event
        }

        if (field_key === "is_identity_provider" && event.target.checked) {
            updated_form["credentials_id"] = ""
            updated_form["credentials_secret"] = ""
        }

        setServiceForm(updated_form)
    }


    const handleUpdateServiceFormReset = () => {
        setServiceForm({...selectedRow})
    }

    const formatServiceForm = () => {
        let new_data = {...serviceForm}
        new_data["account_id"] = clientID
        if ((new_data.credentials_id && new_data.credentials_id.length > 0) && 
            (new_data.credentials_secret && new_data.credentials_secret.length > 0)) {
            new_data["credentials"] = {}
            new_data["credentials"]["app_auth_id"] = new_data.credentials_id
            new_data["credentials"]["app_auth_secret"] = new_data.credentials_secret
        }

        return new_data
    }

    const onServiceUpdate = async () => {
        const data = formatServiceForm()
        const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingServicesEP}/${selectedRow.id}`
        let request_data = {
            url: url,
            method: "PUT",
            data: data
        }
        const response = await billingRequest(request_data)
        if (response.status_code === 200) {
            handleDataFetch()
            handleServiceUpdateDialogClose()
        }
    }

    const onServiceDelete = async () => {
        handleServiceUpdateDialogClose()
        handleLoading(true)
        const url = `${Constants.billing_service_domain}/${billingServiceURLs.billingServicesEP}/${selectedRow.id}`
        let request_data = {
            url: url,
            method: "DELETE"
        }
        const response = await billingRequest(request_data)
        if (response.status_code === 204) {
            handleDetailCardClose()
            handleDataFetch()
        }
        handleLoading(false)
    }

    useEffect(() => {
        let service_actions = []
        let new_action = {}
        new_action["value"] = "update_service"
        new_action["action"] = handleServiceUpdateDialogOpen
        new_action["keyword"] = "billingServiceUpdateActionTitle"
        new_action["button_text"] = "selectButtonTitleText"
        service_actions.push({...new_action})
        
        setServiceActions(service_actions)
    },[])

    useEffect(() => {
        if (!billingServicesChecked && purchasedService.length > 0) {
            const billingService = purchasedService.filter(
            service => service.service === Constants.config_service_billing_name)
            const billingConfiguration = billingService[0].config_params[Constants.config_service_custom_admin_ui]
            if (billingConfiguration.filter(
                section => section.section === Constants.billing_sections.billing_services && 
                section.permissions.includes(Constants.permission_update)).length > 0) {
                    setServiceUpdateAllowed(true)
            }
            if (billingConfiguration.filter(
                section => section.section === Constants.billing_sections.billing_services && 
                section.permissions.includes(Constants.permission_delete)).length > 0) {
                    setServiceDeleteAllowed(true)
            }
            setBillingServicesChecked(true)
        }
        
    },[
        purchasedService,
        billingServicesChecked
    ])

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setTimeout(() => setIsCardLoading(false), 600)
    },[])

    return (
        <React.Fragment>
        {selectedRow !== null && 
        <WrapperBox>
            <ServiceContentHeader
                service_menu={serviceSubMenu}
                service_menu_titles={defaultTexts}
                onClick={handleServiceDetailTabChange}
            />
        </WrapperBox>}
        {currentTab === "/service-details" &&
            <Stack 
                spacing={5} 
                sx={{ px: 1, my: 5}}
            >
                {billingServiceDataSchema.map((field) => {
                    return (
                        getServiceDetailDataComponent({
                            fieldKey: field.field_key,
                            label: field.label,
                            value: selectedRow ? selectedRow[field.field_key] : "",
                            textOnTrue: defaultTexts[field.value_on_true],
                            textOnFalse: defaultTexts[field.value_on_false],
                            defaultTexts: defaultTexts
                        })
                    )
                })}
            </Stack>
        }
        {!isCardLoading && <Paper sx={{ 
            position: 'fixed', 
            bottom: 0, 
            height: FOOTER_HEIGHT,
            left: FOOTER_WIDTH, 
            right: 12,
            zIndex: 3000
            }} 
            elevation={24}
            square={true}
        >
            <Grid 
                container 
                alignItems="center"  
                justifyContent="space-between"
            >
                {serviceUpdateAllowed && <Grid item>
                    <CustomSelectField 
                        items={serviceActions} 
                        currentValue={currentAction}
                        setCurrentValue={setCurrentAction}
                        item_titles={defaultTexts}
                        label={defaultTexts.actionsDropdownLabelText}
                        empty={true}
                        size="small"
                        sx={{m: 1}}
                    />
                    {currentAction.length > 0 && 
                        <Button 
                                variant="contained"
                                color="secondary"
                                sx={{m: 1, height: '70%'}}
                                onClick={serviceActions.filter(
                                    action => action.value === currentAction)[0].action
                                }
                            >
                            {defaultTexts[serviceActions.filter(
                                action => action.value === currentAction)[0].button_text]}
                        </Button>
                    }
                </Grid>}
                {serviceDeleteAllowed && <Grid item>
                    {selectedRow !== null && 
                        <IconButton onClick={handleServiceDeleteDialogOpen}>
                            <DeleteIcon 
                                color="primary"
                            />
                        </IconButton>}
                </Grid>}
            </Grid>
        </Paper>} 
        <CustomDialog
            open={serviceUpdateDialogOpen}
            onClose={handleServiceUpdateDialogClose}
            dialogTitle={{
                title: defaultTexts.serviceUpdateDialogTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: "", 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onServiceUpdate, 
                variant: "contained",
                size: "medium"
            }]}
        >
            <BillingServiceForm 
                data={serviceForm} 
                options={{}}
                handleDataUpdate={handleServiceFormUpdate}
                updateForm={true}
            />
        </CustomDialog>
        <CustomDialog
            open={serviceDeleteDialogOpen}
            onClose={handleServiceDeleteDialogClose}
            dialogTitle={{
                title: defaultTexts.serviceDeleteDialogTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: defaultTexts.serviceDeleteDialogText, 
                sx: {color: 'text.primary'}}}
            actionButtons={[{
                title: defaultTexts.submitButtonText, 
                onClick: onServiceDelete, 
                variant: "contained",
                size: "medium"
            }]}
        />
        {error && <CustomDialog
            open={errorDialogOpen}
            onClose={handleErrorDialogClose}
            dialogTitle={{
                title: defaultTexts.failedActionErrorDialogTitle, 
                sx: {color: 'primary.main'}}}
            dialogBody={{
                text: `<span>${defaultTexts.failedActionErrorDialogMessage}</span>
                        <br>
                        <br>
                        <span>${defaultTexts.detailsErrorNoteDialogText}:</span> 
                        <span style="color: orange">
                            ${error.error_details}
                        </span>`, 
                sx: {color: 'text.primary'}}}
        />}
        </React.Fragment>
    )
};

export default BillingServiceDetail;