import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CustomTable from '../../../../../_common/CustomTable';
import { aggregateDataSchema } from 
'../../../../../../_data/openstack/compute/infrastructure/v2.1';
import { setAggregatesTableConfig } from 
'../../../../../../store/reducers/computeNovaSlice';

const AggregatesTableV21 = (props) => {
    const { aggregatesData, setAggregatesData } = props
    const { handleRowSelection } = props
    const { currentAction } = props
    const { setCurrentAction }  = props
    const { actionsTexts }  = props
    const { actionsList } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);
    
    const aggregatesTableConfig = useSelector(
        state => state.computeNova.aggregatesTableConfig)
    const rowsPerPage = useSelector(
        state => state.computeNova.aggregatesTableConfig.filter(
        versions => versions.api_version === "v2.1")[0].rows_per_page)
    const aggregatesTableHeaders = useSelector(
        state => state.computeNova.aggregatesTableConfig.filter(
            versions => versions.api_version === "v2.1")[0].columns)

    
    const identityField = aggregatesTableHeaders.filter(
        item => item.is_id_field)[0].field_key
    
    
    const dispatch = useDispatch();

    const handleAggregateTableConfig = (value_list) => {
        const newTableConfig = aggregateDataSchema.filter(
            n => value_list.includes(n.field_key))
        const new_data = aggregatesTableConfig.map(version => {
            if (version.api_version === "v2.1") {
                let new_item = {...version}
                new_item.columns = [...newTableConfig]
                return new_item
            } else {
                return version
            }
        })
        dispatch(setAggregatesTableConfig({
            aggregatesTableConfig: new_data
        }))
    }
    
    const handleRowsPerPageChange = (rows_num) => {
        const new_data = aggregatesTableConfig.map(version => {
            if (version.api_version === "v2.1") {
                let new_item = {...version}
                new_item.rows_per_page = rows_num
                return new_item
            } else {
                return version
            }
        })
        dispatch(setAggregatesTableConfig({
            aggregatesTableConfig: new_data
        }))
    }

    return (
        <React.Fragment>
            <CustomTable
                withPagination={true}
                withCheckbox={true}
                withActions={true}
                defaultDataTexts={{...defaultTexts}} 
                tableHeaders={aggregatesTableHeaders}
                tableHeadersConfigHandler={handleAggregateTableConfig}
                identityField={identityField}
                columns={[...aggregateDataSchema]}
                rowsPerPage={rowsPerPage}
                handleRowsPerPageChange={handleRowsPerPageChange}
                dataRows={aggregatesData}
                setDataRows={setAggregatesData}
                handleRowSelection={handleRowSelection}
                currentAction={currentAction}
                setCurrentAction={setCurrentAction}
                actionsTexts={actionsTexts}
                actionsList={actionsList}
            />
        </React.Fragment>
    )
};

export default AggregatesTableV21;