export const segmentDataSchema = [
    {
        field_key: "network_id",
        is_id_field: false,
        field_type: "string", 
        label: "networkFormFieldLabel"
    },
    {
        field_key: "physical_network",
        is_id_field: false,
        field_type: "string", 
        label: "physicalNetworkFormFieldLabel"
    },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "network_type",
        is_id_field: false,
        field_type: "string", 
        label: "networkTypeFormFieldLabel"
    },
    {
        field_key: "segmentation_id",
        is_id_field: false,
        field_type: "string", 
        label: "segmentationIDFormFieldLabel"
    },
    {
        field_key: "revision_number",
        is_id_field: false,
        field_type: "string", 
        label: "revisionNumberFormFieldLabel"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const segmentDataForm = [
    {
        field_key: "network_id",
        field_type: "select",
        required: true,
        label: "networkFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "physical_network",
        field_type: "string",
        required: false,
        label: "physicalNetworkFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "network_type",
        field_type: "select",
        required: true,
        label: "networkTypeFormFieldLabel",
        self_item_titles: false,
        default_empty: false,
        default_value: "vlan",
        error_label: "requiredFormFieldError",
        items: [
            {
                keyword: "vlanFormFieldLabel",
                value: "vlan",
                default: true
            },
            {
                keyword: "vxlanFormFieldLabel",
                value: "vxlan",
                default: true
            },
            {
                keyword: "flatFormFieldLabel",
                value: "flat",
                default: true
            },
            {
                keyword: "greFormFieldLabel",
                value: "gre",
                default: true
            }
        ]
    },
    {
        field_key: "vlan_id",
        field_type: "integer",
        required: false,
        label: "vlanIDFormFieldLabel"
    },
    {
        field_key: "gre_key",
        field_type: "integer",
        required: false,
        label: "greKeyFormFieldLabel"
    },
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    }
]

export const segmentsSortFields = [
    {
        field_key: "network_type",
        label: "networkTypeFormFieldLabel"
    },
    {
        field_key: "network_id",
        label: "networkFormFieldLabel"
    },
    {
        field_key: "name",
        label: "nameFormFieldLabel"
    },
    {
        field_key: "segmentation_id",
        label: "segmentationIDFormFieldLabel"
    },
]


export const segmentsFilterMenu = [
    {
        keyword: "networkTypeFormFieldLabel",
        value: "network_type",
        type: "select",
        multiple: false,
        items: [
            {
                keyword: "vlanFormFieldLabel",
                value: "vlan",
                default: true
            },
            {
                keyword: "vxlanFormFieldLabel",
                value: "vxlan",
                default: true
            },
            {
                keyword: "flatFormFieldLabel",
                value: "flat",
                default: true
            },
            {
                keyword: "greFormFieldLabel",
                value: "gre",
                default: true
            }
        ]
    },
    {
        keyword: "nameFormFieldLabel",
        value: "name",
        type: "text"
    },
    {
        keyword: "networkFormFieldLabel",
        value: "network_id",
        type: "select", 
        multiple: false,
        self_item_titles: true,
        items: []
    },
    {
        keyword: "segmentationIDFormFieldLabel",
        value: "segmentation_id",
        type: "integer"
    }
]

export const segmentDataUpdateForm = [
    {
        field_key: "name",
        field_type: "string",
        required: false,
        label: "nameFormFieldLabel"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    }
]