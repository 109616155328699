import React, { useState } from 'react';
import Box from '@mui/material/Box';
import IKEPoliciesWrapperV20 from './vpn_ike_policies/ikePoliciesWrapperV2.0';
import IPsecPoliciesWrapperV20 from './vpn_ipsec_policies/ipsecPoliciesWrapperV2.0';
import VPNServicesWrapperV20 from './vpn_services/vpnServicesWrapperV2.0';
import EndpointGroupsWrapperV20 from './vpn_endpoint_groups/vpnEndpointGroupsWrapperV2.0';
import SiteConnectionsWrapperV20 from './vpn_site_connections/siteConnectionsWrapperV2.0';


const VPNaaSWrapperV20 = (props) => {
    const { navigate, location, changeMenuActiveTab } = props
    const [currentTab, setCurrentTab] = useState("ike_policies")


    return (
        <Box>
            {currentTab === "ike_policies" && 
                <IKEPoliciesWrapperV20 
                    navigate={navigate}
                    location={location}
                    changeMenuActiveTab={changeMenuActiveTab}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                />
            }
            {currentTab === "ipsec_policies" && 
                <IPsecPoliciesWrapperV20
                    navigate={navigate}
                    location={location}
                    changeMenuActiveTab={changeMenuActiveTab}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                />
            }
            {currentTab === "vpn_services" &&
                <VPNServicesWrapperV20
                    navigate={navigate}
                    location={location}
                    changeMenuActiveTab={changeMenuActiveTab}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                />
            }
            {currentTab === "endpoint_groups" &&
                <EndpointGroupsWrapperV20
                    navigate={navigate}
                    location={location}
                    changeMenuActiveTab={changeMenuActiveTab}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                />
            }
            {currentTab === "site_connections" &&
                <SiteConnectionsWrapperV20
                    navigate={navigate}
                    location={location}
                    changeMenuActiveTab={changeMenuActiveTab}
                    currentTab={currentTab}
                    setCurrentTab={setCurrentTab}
                />
            }
        </Box>
    )
};

export default VPNaaSWrapperV20;