import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ServiceContentHeader from '../../../_common/ServiceContentHeader';
import Box from '@mui/material/Box';

const BackupsDRContentV2 = (props) => {
    //const { serviceName } = props
    const { serviceTitle } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)

    const [serviceMenu, setServiceMenu] = useState([
        {keyword: "submenuBackups", navigation: "/backups", is_active: true},
        {keyword: "submenuBackupClients", navigation: "/backup-clients", is_active: false},
        {keyword: "submenuBackupJobs", navigation: "/backup-jobs", is_active: false},
        {keyword: "submenuActions", navigation: "/backup-actions", is_active: false},
        {keyword: "submenuSessions", navigation: "/backup-sessions", is_active: false}
    ])

    const changeMenuActiveTab = (navigation) => {
        const updated_menu = serviceMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
            } else {
                item.is_active = false
            }
            return item
        })
        setServiceMenu(updated_menu)
    }

    return (
        <Box>
            <ServiceContentHeader 
                service_title={serviceTitle} 
                service_menu={serviceMenu}
                service_menu_titles={defaultTexts}
                onClick={changeMenuActiveTab}
            />
        </Box>
    )
};

export default BackupsDRContentV2;