import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { setRegionName } from '../../store/reducers/settingsSlice';
import { setPurchasedServices } from '../../store/reducers/openStackSlice';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Tooltip from '@mui/material/Tooltip';

const RegionSelector = (props) => {
    const regionName = useSelector((state) => state.settings.regionName);
    const supportedRegions = useSelector((state) => state.openstack.supportedRegions);
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const purchasedServicesAll = useSelector(state => state.openstack.purchasedServicesAll)
    const [anchorElRegion, setAnchorElRegion] = useState(null);

    const handleOpenRegionMenu = (event) => {
        setAnchorElRegion(event.currentTarget);
    };
    
    const handleCloseRegionsMenu = () => {
        setAnchorElRegion(null);
    };

    const dispatch = useDispatch();
    
    const handleRegionChange = (value) => {
        const nonBackendServices = purchasedServicesAll.filter(s => s.service_type !== "backend")
        const identity_service = purchasedServicesAll.find(s => s.config_params.is_identity_provider)
        let backendServices = purchasedServicesAll.filter(s => !s.config_params.is_identity_provider && s.service_type === "backend" && s.config_params.region_name === value)
        backendServices = [identity_service, ...backendServices]
        const purchasedRegionServices = [...backendServices, ...nonBackendServices]
        dispatch(setRegionName({regionName: value}))
        dispatch(setPurchasedServices({purchasedServices: purchasedRegionServices}))
        handleCloseRegionsMenu();
    };

    return (
        <Box sx={{display: 'inline-flex'}}>
            <Tooltip title={defaultTexts.regionSelectorTooltipText}>
                <Button 
                    onClick={handleOpenRegionMenu} 
                    sx={{
                        p: 1,
                        m: 1,
                        color: 'white',
                        
                        display: 'flex',
                        flexDirection: "row",
                        justifyContent: "space-around",
                        alignItems: "center"
                    }}
                >
                    <Typography textAlign="center" >
                        {supportedRegions.find(item => item === regionName)}
                    </Typography>
                    <ArrowDropDownIcon />
                </Button>
            </Tooltip>
            <Menu
                sx={{ mt: '48px' }}
                anchorEl={anchorElRegion}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={Boolean(anchorElRegion)}
                onClose={handleCloseRegionsMenu}
            >
                {supportedRegions.map((item) => {
                    return (
                        <MenuItem 
                            onClick={() => {handleRegionChange(item)}} 
                            key={item} 
                            value={item}
                        >
                            {item}
                        </MenuItem>
                    )
                })}
            </Menu>
        </Box>
    )
}

export default RegionSelector;
