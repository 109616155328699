import React, { useState }  from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Constants from '../../../config/constants'
import CustomText from '../../_common/CustomText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { 
    openStackServiceLabels, 
    openStackResourceLabels 
} from '../../../config/openStackConstants';

const drawerWidth = Constants.drawerWidth;

const BillingAccountingTable = (props) => {
    const drawerOpen = useSelector(state => state.drawer.drawerOpened);
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const { accountingData } = props
    const { projects } = props
    const { billingConfigs } = props
    const currency = billingConfigs.currency ? billingConfigs.currency.name : ""
 
    const theme = useTheme();


    const Row = (props) => {
        const { row, hasChildren } = props;
        const { level } = props
        const { colSpan } = props
        const [open, setOpen] = useState(false)

        const handleServiceTotalCost = (service) => {
            let total_cost = 0
            for (let p in service.projects) {
                const project_cost = handleProjectTotalCost(service.projects[p])
                total_cost = total_cost + project_cost
            }
            return total_cost

        }
        const handleProjectTotalCost = (project) => {
            let total_cost = 0
            for (let r in project.regions) {
                const region_cost = handleRegionTotalCost(project.regions[r])
                total_cost = total_cost + region_cost
            }
            return total_cost
        }
        const handleRegionTotalCost = (region) => {
            let total_cost = 0
            for (let s in region.service_resources) {
                const service_resource_cost = handleResourceTotalCost(region.service_resources[s])
                total_cost = total_cost + service_resource_cost
            }
            return total_cost
        }
        const handleResourceTotalCost = (resource) => {
            let total_cost = 0
            if (resource.resources) {
                for (let res in resource.resources) {
                    total_cost = total_cost + resource.resources[res].data.total_cost
                }
            } else {
                total_cost = total_cost + resource.data.total_cost
            }
            return total_cost
        }
  
        return (
            <React.Fragment>
                <TableRow  
                    sx={{ '& > *': {  
                        borderTop: 'unset' },
                        '&:hover': {
                        backgroundColor: theme.palette.mode === 'dark' ? 
                        theme.palette.mediumGray  :
                        theme.palette.vLightGray,
                        cursor: "pointer"
                        }
                    }}
                    onClick={() => {
                        setOpen(!open)
                    }}
                >
                    {level === 1 &&
                        <TableCell  align="left" style={{width: 10}}>
                                            
                        </TableCell>
                    }
                    {level === 2 &&
                        <React.Fragment>
                        <TableCell  align="left" style={{width: 10}}>
                                            
                        </TableCell>
                        <TableCell  align="left" style={{width: 10}}>
                                            
                        </TableCell>
                        </React.Fragment>
                    }
                    {level === 3 &&
                        <React.Fragment>
                        <TableCell  align="left" style={{width: 10}}>
                                            
                        </TableCell>
                        <TableCell  align="left" style={{width: 10}}>
                                            
                        </TableCell>
                        <TableCell  align="left" style={{width: 10}}>
                                            
                        </TableCell>
                        </React.Fragment>
                    }
                    <TableCell>
                        {hasChildren && <IconButton
                            size="small"
                            onClick={() => {
                                setOpen(!open)
                            }}
                        >
                            {open ? 
                                <KeyboardArrowUpIcon /> : 
                                <KeyboardArrowDownIcon />
                            }
                        </IconButton>}
                    </TableCell>
                    
                    <TableCell 
                        component="th" 
                        scope="row"
                        align="left" 
                        style={{
                            color: theme.palette.primary.main
                        }}
                        colSpan={colSpan}
                    >
                        {row.service_name && openStackServiceLabels[row.service_name]}
                        {row.project_id && (projects.length > 0 ? 
                            projects.filter(p => p.id === row.project_id)[0].name : row.project_id)}
                        {row.region_name && row.region_name}
                        {row.resource_name && openStackResourceLabels[row.resource_name]}
                    </TableCell>
                    <TableCell align="right">
                        {row.service_name && handleServiceTotalCost(row).toFixed(2)}
                        {row.project_id && handleProjectTotalCost(row).toFixed(2)}
                        {row.region_name && handleRegionTotalCost(row).toFixed(2)}
                        {row.resource_name && handleResourceTotalCost(row).toFixed(2)}
                        {` ${currency.toUpperCase()}`}
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell 
                        style={{ padding: 0 }} 
                        colSpan={8} 
                        sx={{
                            borderBottom: "unset", 
                            borderTop: "unset"
                        }}>
                        <Collapse in={open} timeout="auto">
                            <Table size="small">
                                <TableHead>
                                    <TableRow
                                        sx={{ '& > *': {  
                                            borderTop: 'unset', borderBottom: 'unset' }
                                        }}
                                    >
                                        <TableCell align="left" style={{width: 30}}/>
                                        <TableCell  align="left" style={{width: 30}} />
                                        <TableCell  align="left" style={{width: 30}} />
                                        <TableCell  align="left"  style={{width: 30}} />
                                        <TableCell  align="left" />
                                        <TableCell  align="left" />
                                        <TableCell  align="left" />
                                        <TableCell  align="right" />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.projects && row.projects.map((pr, index) => { 
                                        return (
                                            <Row 
                                                key={pr.project_id} 
                                                row={pr} 
                                                hasChildren={true}
                                                level={1}
                                                colSpan={colSpan - 1}
                                            />
                                    )})}
                                    {row.regions && row.regions.map((reg, index) => { 
                                        return (
                                            <Row 
                                                key={reg.region_name} 
                                                row={reg} 
                                                hasChildren={true}
                                                level={2}
                                                colSpan={colSpan - 1}
                                            />
                                    )})}
                                    {row.service_resources && row.service_resources.map((res, index) => { 
                                        return (
                                            res.resources ?
                                            <Row 
                                                key={res.resource_name} 
                                                row={res} 
                                                hasChildren={true} 
                                                level={3}
                                                colSpan={colSpan - 1}
                                            /> :
                                            <TableRow 
                                                key={index}
                                            >
                                                <TableCell align="left" style={{width: 30}}/>
                                                <TableCell  align="left" style={{width: 30}} />
                                                <TableCell  align="left" style={{width: 30}} />
                                                <TableCell  align="left"  style={{width: 30}} />
                                                <TableCell  align="left"  style={{width: 30}} />
                                                {(res.resource_name === "snapshot" || res.resource_name === "backup") && 
                                                    <TableCell align="left">
                                                        <CustomText sx={{color: theme.palette.mode === "dark" ? 
                                                            theme.palette.customBlueDark : 
                                                            theme.palette.customBlue}}>
                                                            {openStackResourceLabels[res.resource_name]}
                                                        </CustomText>
                                                        <CustomText sx={{color: "text.secondary"}}>
                                                            {` (${res.data.price} * Size (GB) ${currency.toUpperCase()}/Hr)`}
                                                        </CustomText>
                                                    </TableCell>
                                                }
                                                {(res.resource_name === "snapshot" || res.resource_name === "backup") && 
                                                    <TableCell  align="right">
                                                        <CustomText sx={{color: "text.secondary"}}>
                                                            {`${defaultTexts.totalSizeDescription}: ${res.data.total_size} GB, `} 
                                                            {defaultTexts.totalHoursResourceDecription}: {res.data.total_hours}
                                                        </CustomText>
                                                    </TableCell>}
                                                {res.resource_name === "image" && 
                                                    <TableCell align="left">
                                                        <CustomText sx={{color: theme.palette.mode === "dark" ? 
                                                            theme.palette.customBlueDark : 
                                                            theme.palette.customBlue}}>
                                                            {openStackResourceLabels[res.resource_name]}
                                                        </CustomText>
                                                        <CustomText sx={{color: "text.secondary"}}>
                                                            {` (${res.data.price} * Size (Bytes) ${currency.toUpperCase()}/Hr)`}
                                                        </CustomText>
                                                    </TableCell>
                                                }
                                                {res.resource_name === "image" && <TableCell  align="right">
                                                    <CustomText sx={{color: "text.secondary"}}>
                                                        {`${defaultTexts.totalSizeDescription}: `} 
                                                        {res.data.total_size > 1073741823 && `${parseInt(res.data.total_size / 1073741824)} GB, `}
                                                        {res.data.total_size > 1048575 && res.data.total_size < 1073741824 && `${parseInt(res.data.total_size / 1048576)} MB, `}
                                                        {res.data.total_size > 1023 && res.data.total_size < 1048575 && `${parseInt(res.data.total_size / 1024)} KB, `}
                                                        {res.data.total_size < 1024 && `${res.data.total_size} Bytes, `} 
                                                        {defaultTexts.totalHoursResourceDecription} {res.data.total_hours}
                                                    </CustomText>
                                                </TableCell>}
                                                {(res.resource_name === "router" || res.resource_name === "vpn" || res.resource_name === "floating_ip" ) && 
                                                    <TableCell align="left">
                                                        <CustomText sx={{color: theme.palette.mode === "dark" ? theme.palette.customBlueDark : theme.palette.customBlue}}>
                                                            {openStackResourceLabels[res.resource_name]}
                                                        </CustomText>
                                                        <CustomText sx={{color: "text.secondary"}}>
                                                            {(res.resource_name === "router" || res.resource_name === "floating_ip") && ` (${res.data.price} ${currency.toUpperCase()}/Hr)`}
                                                        </CustomText>
                                                    </TableCell>
                                                }
                                                {(res.resource_name === "router" || res.resource_name === "vpn" || res.resource_name === "floating_ip") && <TableCell  align="right">
                                                    <CustomText sx={{color: "text.secondary"}}>
                                                        {defaultTexts.totalHoursResourceDecription} {res.data.total_hours}
                                                    </CustomText>
                                                </TableCell>}
                                                <TableCell align="right">
                                                    <CustomText sx={{textTransform: "uppercase"}}>
                                                        {res.data.total_cost} {currency}
                                                    </CustomText>
                                                </TableCell>
                                            </TableRow>
                                    )})}
                                    {row.resources && row.resources.map((res_item, index) => { 
                                        return (
                                            <TableRow 
                                                key={index}
                                            >
                                                <TableCell align="left" style={{width: 30}}/>
                                                <TableCell align="left" style={{width: 30}}/>
                                                <TableCell align="left" style={{width: 30}}/>
                                                <TableCell align="left" style={{width: 30}}/>
                                                <TableCell align="left" style={{width: 30}}/>
                                                {row.resource_name === "flavor" && 
                                                    <TableCell align="left">
                                                        <CustomText sx={{color: theme.palette.mode === "dark" ? 
                                                            theme.palette.customBlueDark : 
                                                            theme.palette.customBlue}}>
                                                            {res_item.data.name}
                                                        </CustomText>
                                                        <CustomText sx={{color: "text.secondary"}}>
                                                            {` (${res_item.data.price.active} ${currency.toUpperCase()}/Hr)`}
                                                        </CustomText>
                                                    </TableCell>
                                                }
                                                {row.resource_name === "flavor" && <TableCell  align="right">
                                                    <CustomText sx={{color: "text.secondary"}}>
                                                        {`(vCPUs: ${res_item.data.cpu}, `} 
                                                        {res_item.data.ram > 1023 ? `RAM: ${parseInt(res_item.data.ram / 1024)} GB` : 
                                                            `RAM: ${res_item.data.ram} MB, `} {`Disk: ${res_item.data.disk} GB)`}
                                                    </CustomText>
                                                </TableCell>}
                                                {row.resource_name === "volume_type" && 
                                                    <TableCell align="left">
                                                        <CustomText sx={{color: theme.palette.mode === "dark" ? 
                                                            theme.palette.customBlueDark : 
                                                            theme.palette.customBlue}}>
                                                            {res_item.data.name}
                                                        </CustomText>
                                                        <CustomText sx={{color: "text.secondary"}}>
                                                            {` (${res_item.data.price} * Size (GB) ${currency.toUpperCase()}/Hr)`}
                                                        </CustomText>
                                                    </TableCell>
                                                }
                                                {row.resource_name === "volume_type" && <TableCell  align="right">
                                                    <CustomText sx={{color: "text.secondary"}}>
                                                        {`${defaultTexts.totalSizeDescription}: ${res_item.data.total_size} GB, `} 
                                                        {defaultTexts.totalHoursResourceDecription}: {res_item.data.total_hours}
                                                    </CustomText>
                                                </TableCell>}
                                                {row.resource_name === "fwaas_rule" && 
                                                    <TableCell align="left">
                                                        <CustomText sx={{color: theme.palette.mode === "dark" ? 
                                                            theme.palette.customBlueDark : 
                                                            theme.palette.customBlue}}>
                                                            {openStackResourceLabels.fwaas_rule} 
                                                            {parseInt(res_item.rule_range[0])} - 
                                                            {parseInt(res_item.rule_range[1])}
                                                        </CustomText>
                                                        <CustomText sx={{color: "text.secondary"}}>
                                                            {` (${res_item.data.price} ${currency.toUpperCase()}/Hr)`}
                                                        </CustomText>
                                                    </TableCell>
                                                }
                                                {row.resource_name === "fwaas_rule" && <TableCell  align="right">
                                                    <CustomText sx={{color: "text.secondary"}}>
                                                        {defaultTexts.totalHoursResourceDecription}: {res_item.data.total_hours} 
                                                        {` (${defaultTexts.firewallRulesMaxCountDescription} ${res_item.data.max_count})`}
                                                    </CustomText>
                                                </TableCell>}
                                                <TableCell  align="right">
                                                    <CustomText sx={{textTransform: "uppercase"}}>
                                                        {res_item.data.total_cost} {currency}
                                                    </CustomText>
                                                </TableCell>
                                            </TableRow>
                                        )})}
                                </TableBody>
                            </Table>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }


    if (accountingData.length === 0) {
        return (
            <Paper
                sx={{
                border: 1,
                borderColor: "customBlue",
                borderRadius: 2,
                maxWidth: drawerOpen ? 
                `calc(100vw - ${drawerWidth}px)` : 
                `calc(100vw - ${110}px)`,
                height: 400,
                alignItems: 'center',
                justifyContent: 'center',
                p: 3,
                mt: 1,
                display: "flex"
                }}
            >
                <CustomText 
                size="h5" 
                sx={{color: "customBlue"}}
                >
                {defaultTexts.noDataToDisplayText}
                </CustomText>
            </Paper>
        )
    }

    return (
        <React.Fragment>
            <Box sx={{
                maxWidth: drawerOpen ? 
                `calc(100vw - ${drawerWidth}px)` : 
                `calc(100vw - ${110}px)`,
                mt: 2
            }}>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 300}} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left" style={{width: 30}}/>
                                <TableCell  align="left" colSpan={5}>
                                    {defaultTexts.serviceFormFieldLabel}
                                </TableCell>
                                <TableCell  align="right">
                                    {defaultTexts.descriptionFormFieldLabel}
                                </TableCell>
                                <TableCell  align="right">
                                    {defaultTexts.priceFormFieldLabel}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {accountingData.map((row, index) => (
                                <Row 
                                    key={row.service_name} 
                                    row={row}
                                    hasChildren={true}
                                    level={0}
                                    colSpan={6}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </React.Fragment>
    )
};

export default BillingAccountingTable;