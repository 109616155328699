import React from 'react';
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import InstancesGraphWrapper from './computeNova/instancesGraphWrapper';
import BlockStorageGraphWrapper from './volumesCinder/blockStorageGraphWrapper';
import ImagesGraphWrapper from './imagesGlance/imagesGraphWrapper';
import HypervisorsGraphWrapper from './computeNova/hypervisorsGraphWrapper';
import NetworksGraphWrapper from './networksNeutron/networksGraphWrapper';
import IdentityGraphWrapper from './identityKeystone/identityGraphWrapper';

const HomeContent = (props) => {
    return (
        <Box>
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <InstancesGraphWrapper />
                </Grid>
                <Grid item xs={12} md={6}>
                    <BlockStorageGraphWrapper />
                </Grid>
                <Grid item xs={12} md={6}>
                    <ImagesGraphWrapper />
                </Grid>
                <Grid item xs={12} md={6}>
                    <HypervisorsGraphWrapper />
                </Grid>
                <Grid item xs={12} md={6}>
                    <NetworksGraphWrapper />
                </Grid>
                <Grid item xs={12} md={6}>
                    <IdentityGraphWrapper />
                </Grid>
            </Grid>
        </Box>
    )
};

export default HomeContent;