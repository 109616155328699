import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import ServiceContentHeader from '../../../_common/ServiceContentHeader';
import Box from '@mui/material/Box';

import IdentityDomainsWrapperV3 from './domains/identityDomainsWrapperV3';
import IdentityRegionsWrapperV3 from './regions/identityRegionsWrapperV3';
import IdentityProjectsWrapperV3 from './projects/identityProjectsWrapperV3';
import IdentityUsersWrapperV3 from './users/identityUsersWrapperV3';
import IdentityGroupsWrapperV3 from './groups/identityGroupsWrapperV3';
import IdentityRolesWrapperV3 from './roles/identityRolesWrapperV3';
import IdentityServicesCatalogWrapperV3 from './services_catalog/identityServicesCatalogWrapperV3';

const IdentityKeystoneContentV3 = (props) => {
    //const { serviceName } = props
    const { serviceTitle } = props
    const defaultTexts = useSelector(state => state.texts.langTexts);

    const [serviceMenu, setServiceMenu] = useState([
        {keyword: "submenuDomains", navigation: "/domains", is_active: true, component: IdentityDomainsWrapperV3},
        {keyword: "submenuRegions", navigation: "/regions", is_active: false, component: IdentityRegionsWrapperV3},
        {keyword: "submenuProjects", navigation: "/projects", is_active: false, component: IdentityProjectsWrapperV3},
        {keyword: "submenuUsers", navigation: "/users", is_active: false, component: IdentityUsersWrapperV3},
        {keyword: "submenuGroups", navigation: "/groups", is_active: false, component: IdentityGroupsWrapperV3},
        {keyword: "submenuRoles", navigation: "/roles", is_active: false, component: IdentityRolesWrapperV3},
        //{keyword: "submenuUnifiedLimits", navigation: "/unified-limits", is_active: false},
        {keyword: "submenuServices", navigation: "/services-catalog", is_active: false, component: IdentityServicesCatalogWrapperV3}
    ])

    const getCurrentTab = () => {
        const currentItem = serviceMenu.filter(item => item.is_active)[0]
        return currentItem
    }

    const [currentTab, setCurrentTab] = useState(getCurrentTab())

    const changeMenuActiveTab = (navigation) => {
        const updated_menu = serviceMenu.map(item => {
            if (item.navigation === navigation) {
                item.is_active = true
                setCurrentTab(item)
            } else {
                item.is_active = false
            }
            return item
        })
        setServiceMenu(updated_menu)
    }

    return (
        <Box>
            <ServiceContentHeader 
                service_title={serviceTitle} 
                service_menu={serviceMenu}
                service_menu_titles={defaultTexts}
                onClick={changeMenuActiveTab}
            />
            <currentTab.component />
        </Box>
    )
};

export default IdentityKeystoneContentV3;