export const securityGroupDataSchema = [
    {
        field_key: "project_id",
        is_id_field: false,
        field_type: "string", 
        label: "projectFormFieldLabel"
    },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "stateful",
        is_id_field: false,
        field_type: "bool", 
        label: "statefulFormFieldLabel",
        textOnTrue: "formValueYes",
        textOnFalse: "formValueNo"
    },
    {
        field_key: "security_group_rules",
        is_id_field: false,
        field_type: "integer", 
        label: "securityGroupsNumberFormFieldLabel"
    },
    {
        field_key: "revision_number",
        is_id_field: false,
        field_type: "string", 
        label: "revisionNumberFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const securityGroupDataForm = [
    {
        field_key: "project_id",
        field_type: "select",
        required: true,
        label: "projectFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    },
    {
        field_key: "stateful",
        field_type: "bool", 
        required: false,
        label: "statefulFormFieldLabel",
        default_value: false
    }
]

export const securityGroupsSortFields = [
    {
        field_key: "name",
        label: "nameFormFieldLabel"
    },
    {
        field_key: "project_id",
        label: "projectFormFieldLabel"
    }
]


export const securityGroupsFilterMenu = [
    {
        keyword: "nameFormFieldLabel",
        value: "name",
        type: "text"
    },
    {
        keyword: "projectFormFieldLabel",
        value: "project_id",
        type: "select", 
        multiple: false,
        self_item_titles: true,
        items: []
    }
]

export const securityGroupDataUpdateForm = [
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    }
]

export const secGroupRuleDataForm = [
    {
        field_key: "direction",
        field_type: "select",
        required: true,
        label: "directionFormFieldLabel",
        error_label: "requiredFormFieldError",
        default_empty: false,
        self_item_titles: false,
        items: [
            {
                keyword: "ingressFormFieldLabel",
                value: "ingress",
                default: true
            },
            {
                keyword: "egressFormFieldLabel",
                value: "egress",
                default: false
            }
        ]
    },
    {
        field_key: "ethertype",
        field_type: "select",
        required: true,
        label: "ethertypeFormFieldLabel",
        error_label: "requiredFormFieldError",
        default_empty: false,
        self_item_titles: false,
        items: [
            {
                keyword: "ipv4FormFieldLabel",
                value: "ipv4",
                default: true
            },
            {
                keyword: "ipv6FormFieldLabel",
                value: "ipv6",
                default: false
            }
        ]
    },
    {
        field_key: "protocol_selection",
        field_type: "select",
        required: true,
        label: "protocolFormFieldLabel",
        error_label: "requiredFormFieldError",
        default_empty: false,
        self_item_titles: false,
        items: [
            {
                keyword: "tcpFormFieldLabel",
                value: "tcp",
                default: false
            },
            {
                keyword: "udpFormFieldLabel",
                value: "udp",
                default: false
            },
            {
                keyword: "icmpFormFieldLabel",
                value: "icmp",
                default: false
            },
            {
                keyword: "otherProtocolFormFieldLabel",
                value: "other",
                default: false
            },
            {
                keyword: "anyFormFieldLabel",
                value: "any",
                default: false
            },
        ]
    },
    {
        field_key: "protocol",
        field_type: "integer",
        required: false,
        label: "protocolNumberFormFieldLabel"
    },
    {
        field_key: "port_range_min",
        field_type: "integer",
        required: false,
        label: "fromPortFormFieldLabel"
    },
    {
        field_key: "port_range_max",
        field_type: "integer",
        required: false,
        label: "toPortFormFieldLabel"
    },
    {
        field_key: "icmp_type",
        field_type: "integer",
        required: false,
        label: "icmpTypeFormFieldLabel"
    },
    {
        field_key: "icmp_code",
        field_type: "integer",
        required: false,
        label: "icmpCodeFormFieldLabel"
    },
    {
        field_key: "remote_type",
        field_type: "select",
        required: true,
        label: "remoteFormFieldLabel",
        error_label: "requiredFormFieldError",
        default_empty: false,
        self_item_titles: false,
        items: [
            {
                keyword: "securityGroupFormFieldLabel",
                value: "security_group",
                default: false
            },
            {
                keyword: "addressGroupFormFieldLabel",
                value: "address_group",
                default: false
            },
            {
                keyword: "ipPrefixFormFieldLabel",
                value: "ip_prefix",
                default: false
            }
        ]
    },
    {
        field_key: "remote_group_id",
        field_type: "select",
        required: false,
        label: "remoteSecurityGroupFormFieldLabel",
        self_item_titles: true,
        default_empty: false
    },
    {
        field_key: "remote_address_group_id",
        field_type: "select",
        required: false,
        label: "remoteAddressGroupFormFieldLabel",
        self_item_titles: true,
        default_empty: false
    },
    {
        field_key: "remote_ip_prefix",
        field_type: "string",
        required: false,
        label: "remoteIPPrefixFormFieldLabel"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel"
    }
]

export const securityGroupRuleDataForm = [
    {
        field_key: "security_group_id",
        field_type: "select",
        required: true,
        label: "securityGroupFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        error_label: "requiredFormFieldError"
    },
    ...secGroupRuleDataForm
]

export const defaultRuleDataForm = [
    {
        field_key: "direction",
        field_type: "select",
        required: true,
        label: "directionFormFieldLabel",
        error_label: "requiredFormFieldError",
        default_empty: false,
        self_item_titles: false,
        items: [
            {
                keyword: "ingressFormFieldLabel",
                value: "ingress",
                default: true
            },
            {
                keyword: "egressFormFieldLabel",
                value: "egress",
                default: false
            }
        ]
    },
    {
        field_key: "ethertype",
        field_type: "select",
        required: true,
        label: "ethertypeFormFieldLabel",
        error_label: "requiredFormFieldError",
        default_empty: false,
        self_item_titles: false,
        items: [
            {
                keyword: "ipv4FormFieldLabel",
                value: "ipv4",
                default: true
            },
            {
                keyword: "ipv6FormFieldLabel",
                value: "ipv6",
                default: false
            }
        ]
    },
    {
        field_key: "protocol_selection",
        field_type: "select",
        required: true,
        label: "protocolFormFieldLabel",
        error_label: "requiredFormFieldError",
        default_empty: false,
        self_item_titles: false,
        items: [
            {
                keyword: "tcpFormFieldLabel",
                value: "tcp",
                default: false
            },
            {
                keyword: "udpFormFieldLabel",
                value: "udp",
                default: false
            },
            {
                keyword: "icmpFormFieldLabel",
                value: "icmp",
                default: false
            },
            {
                keyword: "otherProtocolFormFieldLabel",
                value: "other",
                default: false
            },
            {
                keyword: "anyFormFieldLabel",
                value: "any",
                default: false
            },
        ]
    },
    {
        field_key: "protocol",
        field_type: "integer",
        required: false,
        label: "protocolNumberFormFieldLabel"
    },
    {
        field_key: "port_range_min",
        field_type: "integer",
        required: false,
        label: "fromPortFormFieldLabel"
    },
    {
        field_key: "port_range_max",
        field_type: "integer",
        required: false,
        label: "toPortFormFieldLabel"
    },
    {
        field_key: "icmp_type",
        field_type: "integer",
        required: false,
        label: "icmpTypeFormFieldLabel"
    },
    {
        field_key: "icmp_code",
        field_type: "integer",
        required: false,
        label: "icmpCodeFormFieldLabel"
    },
    {
        field_key: "remote_type",
        field_type: "select",
        required: true,
        label: "remoteFormFieldLabel",
        error_label: "requiredFormFieldError",
        default_empty: false,
        self_item_titles: false,
        items: [
            {
                keyword: "securityGroupFormFieldLabel",
                value: "security_group",
                default: false
            },
            {
                keyword: "ipPrefixFormFieldLabel",
                value: "ip_prefix",
                default: false
            }
        ]
    },
    {
        field_key: "remote_group_id",
        field_type: "select",
        required: false,
        label: "remoteSecurityGroupFormFieldLabel",
        self_item_titles: false,
        default_empty: false,
        items: [
            {
                keyword: "parentSecurityGroupFormFieldLabel",
                value: "PARENT",
                default: true
            },
        ]
    },
    {
        field_key: "remote_ip_prefix",
        field_type: "string",
        required: false,
        label: "remoteIPPrefixFormFieldLabel"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "used_in_default_sg",
        field_type: "bool",
        required: false,
        default_value: true,
        label: "usedInDefSGFormFieldLabel"
    },
    {
        field_key: "used_in_non_default_sg",
        field_type: "bool",
        required: false,
        default_value: true,
        label: "usedInNonDefSGFormFieldLabel"
    }
]

export const defaultRulesFilterMenu = [
    {
        keyword: "directionFormFieldLabel",
        value: "direction",
        type: "select", 
        multiple: false,
        self_item_titles: false,
        items: [
            {
                keyword: "ingressFormFieldLabel", 
                value: "ingress", 
                default: true
            },
            {
                keyword: "egressFormFieldLabel", 
                value: "egress", 
                default: false
            }
        ]
    },
    {
        keyword: "ethertypeFormFieldLabel",
        value: "ethertype",
        type: "select", 
        multiple: false,
        self_item_titles: false,
        items: [
            {
                keyword: "ipv4FormFieldLabel", 
                value: "ipv4", 
                default: true
            },
            {
                keyword: "ipv6FormFieldLabel", 
                value: "ipv6", 
                default: false
            }
        ]
    },
    {
        keyword: "protocolFormFieldLabel",
        value: "protocol",
        type: "text"
    },
    {
        keyword: "fromPortFormFieldLabel",
        value: "port_range_min",
        type: "integer"
    },
    {
        keyword: "toPortFormFieldLabel",
        value: "port_range_max",
        type: "integer"
    },
    {
        keyword: "remoteSecurityGroupFormFieldLabel",
        value: "remote_group_id",
        type: "select", 
        multiple: false,
        self_item_titles: true,
        items: []
    },
    {
        keyword: "remoteAddressGroupFormFieldLabel",
        value: "remote_address_group_id",
        type: "select", 
        multiple: false,
        self_item_titles: true,
        items: []
    },
    {
        keyword: "remoteIPPrefixFormFieldLabel",
        value: "remote_ip_prefix",
        type: "text"
    },
    {
        keyword: "usedInDefSGFormFieldLabel", 
        value: "used_in_default_sg", 
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "filterChoiceYes", 
                value: "true", 
                default: true
            },
            {
                keyword: "filterChoiceNo", 
                value: "false", 
                default: false
            }
        ]
    },
    {
        keyword: "usedInNonDefSGFormFieldLabel", 
        value: "used_in_non_default_sg", 
        type: "select", 
        multiple: false,
        items: [
            {
                keyword: "filterChoiceYes", 
                value: "true", 
                default: true
            },
            {
                keyword: "filterChoiceNo", 
                value: "false", 
                default: false
            }
        ]
    }
]

export const defaultRulesSortFields = [
    {
        field_key: "direction",
        label: "directionFormFieldLabel"
    },
    {
        field_key: "ethertype",
        label: "ethertypeFormFieldLabel"
    },
    {
        field_key: "port_range_min",
        label: "fromPortFormFieldLabel"
    },
    {
        field_key: "port_range_max",
        label: "toPortFormFieldLabel"
    },
    {
        field_key: "protocol",
        label: "protocolFormFieldLabel"
    },
    {
        field_key: "remote_group_id",
        label: "remoteSecurityGroupFormFieldLabel"
    },
    {
        field_key: "remote_ip_prefix",
        label: "remoteIPPrefixFormFieldLabel"
    },
    {
        field_key: "remote_address_group_id",
        label: "remoteAddressGroupFormFieldLabel"
    }
]

export const defaultRuleDataSchema = [
    {
        field_key: "direction",
        is_id_field: false,
        field_type: "string", 
        label: "directionFormFieldLabel"
    },
    {
        field_key: "ethertype",
        is_id_field: false,
        field_type: "string", 
        label: "ethertypeFormFieldLabel"
    },
    {
        field_key: "protocol",
        is_id_field: false,
        field_type: "string", 
        label: "protocolFormFieldLabel"
    },
    {
        field_key: "ports",
        is_id_field: false,
        field_type: "string", 
        label: "portsFormFieldLabel"
    },
    {
        field_key: "remote",
        is_id_field: false,
        field_type: "string", 
        label: "remoteFormFieldLabel"
    },
    {
        field_key: "used_in_default_sg",
        is_id_field: false,
        field_type: "bool", 
        label: "usedInDefSGFormFieldLabel",
        textOnTrue: "formValueYes",
        textOnFalse: "formValueNo"
    },
    {
        field_key: "used_in_non_default_sg",
        is_id_field: false,
        field_type: "bool", 
        label: "usedInNonDefSGFormFieldLabel",
        textOnTrue: "formValueYes",
        textOnFalse: "formValueNo"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const addressGroupDataForm = [
    {
        field_key: "project_id",
        field_type: "select",
        required: true,
        label: "projectFormFieldLabel",
        self_item_titles: true,
        default_empty: false,
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "name",
        field_type: "string",
        required: true,
        label: "nameFormFieldLabel",
        error_label: "requiredFormFieldError"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    }
]

export const addressGroupsSortFields = [
    {
        field_key: "name",
        label: "nameFormFieldLabel"
    },
    {
        field_key: "project_id",
        label: "projectFormFieldLabel"
    }
]


export const addressGroupsFilterMenu = [
    {
        keyword: "nameFormFieldLabel",
        value: "name",
        type: "text"
    },
    {
        keyword: "projectFormFieldLabel",
        value: "project_id",
        type: "select", 
        multiple: false,
        self_item_titles: true,
        items: []
    }
]

export const addressGroupDataSchema = [
    {
        field_key: "project_id",
        is_id_field: false,
        field_type: "string", 
        label: "projectFormFieldLabel"
    },
    {
        field_key: "name",
        is_id_field: false,
        field_type: "string", 
        label: "nameFormFieldLabel"
    },
    {
        field_key: "addresses",
        is_id_field: false,
        field_type: "list", 
        label: "addressesFormFieldLabel"
    },
    {
        field_key: "description",
        is_id_field: false,
        field_type: "string", 
        label: "descriptionFormFieldLabel"
    },
    {
        field_key: "id",
        is_id_field: true,
        field_type: "string", 
        label: "idFormFieldLabel"
    }
]

export const addressGroupForm = [
    {
        field_key: "address",
        field_type: "string",
        required: false,
        label: "addressFormFieldLabel"
    }
]

export const addressGroupUpdateForm = [
    {
        field_key: "name",
        field_type: "string",
        required: false,
        label: "nameFormFieldLabel"
    },
    {
        field_key: "description",
        field_type: "string",
        required: false,
        label: "descriptionFormFieldLabel",
        multiline: true
    }
]