import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import WrapperBox from '../../../../../_common/WrapperBox';
import AddButton from '../../../../../_common/AddButton';
import FormGroup from '@mui/material/FormGroup';
import Constants from '../../../../../../config/constants';
import FilterButton from '../../../../../_common/FilterButton';
import CustomDialog from '../../../../../_common/CustomDialog';
import { 
    openStackServices, 
    networkNeutronConstants
} from '../../../../../../config/openStackConstants';
import { vpnIKEPolicyDataForm } from 
'../../../../../../_data/openstack/neutron/vpn/v2.0';
import { getFormFieldComponent } from 
'../../../../../_common/_form_fields/form_helpers';
import { 
    getXAuthTokenProjectScope,
    openstackRequest 
} from '../../../../../../_network/openstack_request';
import { vpnUrl as vpnUrlResponses } 
from '../../../../../../_api_responses/openstack/neutron/vpn/v2.0';
import Grid from '@mui/material/Grid';

const SERVICE_NAME = openStackServices.networkService

const IKEPoliciesSubheaderV20 = (props) => {
    const { currentTab, handleTabChange } = props
    const { selectedFilter, setSelectedFilter } = props
    const { selectedFilterValue, setSelectedFilterValue } = props
    const { handleDataFetch } = props
    const { filterMenu, projects } = props
    const { handleFilteredSearch, handleFilterReset } = props
    const defaultTexts = useSelector(state => state.texts.langTexts)
    const defaultAdminProject = useSelector(state => state.profile.defaultAdminProject.id)
    const [error, setError] = useState()
    const [successIkePolicyAdd, setSuccessIkePolicyAdd] = useState()
    const [successDialogOpen, setSuccessDialogOpen] = useState(false);
    const [errorDialogOpen, setErrorDialogOpen] = useState(false);
    const [formData, setFormData] = useState({})
    const [formDataOptions, setFormDataOptions] = useState({});

    const theme = useTheme();

    const neutronServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const neutronServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)
    const vpnIKEPoliciesUrl = useSelector(
        state => state.networkNeutron.networkNeutronApiUrls.filter(
            version => version.api_version === "v2.0")[0].urls.filter(
                url => url.keyword === networkNeutronConstants.vpnIKEPoliciesUrl)[0].url)

    const handleErrorDialogClose = () => {
        setError(null);
        setErrorDialogOpen(false);
    }

    const handleSuccessDialogClose = () => {
        setSuccessIkePolicyAdd(null);
        setSuccessDialogOpen(false);
    }

    const handleAddIkePolicyFormReset = () => {
        setFormDataOptions({})
        setFormData({})
    }

    const handleFormDataChange = (event,field_key) => {
        setFormDataOptions({})
        let new_form_data = {...formData}
        if (vpnIKEPolicyDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "bool") {
            new_form_data[field_key] = event.target.checked
        } else if (vpnIKEPolicyDataForm.filter(
            item => item.field_key === field_key)[0].field_type === "select") {
            new_form_data[field_key] = event
        } else {
            new_form_data[field_key] = event.target.value
        }
        setFormData(new_form_data)
    }

    const getDataForm = () => {
        let form = [...vpnIKEPolicyDataForm]
        return (
            <FormGroup>
                {form.map(field => {
                    let form_field_options = {...formDataOptions[field.field_key]}
                    form_field_options = {...form_field_options, ...field}
                    delete form_field_options["label"]
                    form_field_options["item_titles"] = defaultTexts
                    if (field.field_key === "project_id") {
                        const project_list = projects.map(p => {
                            return {keyword: p.name, value: p.id, default: false}
                        })
                        form_field_options["items"] = [...project_list]
                        form_field_options["self_item_titles"] = true
                        form_field_options["empty"] = false
                    }
                    return (
                        getFormFieldComponent(
                            field,
                            formData,
                            handleFormDataChange,
                            defaultTexts[field.label],
                            {...form_field_options}
                        )
                    )
                })}
            </FormGroup>
        )
    }

    const handleFormDataValidation = () => {
        let validation_faild = true
        let updatedDataFormOptions = {...formDataOptions}
        for (let n in vpnIKEPolicyDataForm) {
            if (vpnIKEPolicyDataForm[n].required && !formData[vpnIKEPolicyDataForm[n].field_key]) {
                validation_faild = false
                updatedDataFormOptions[vpnIKEPolicyDataForm[n].field_key] = {}
                updatedDataFormOptions[vpnIKEPolicyDataForm[n].field_key]["error"] = true
                updatedDataFormOptions[vpnIKEPolicyDataForm[n].field_key]["errorText"] = defaultTexts[vpnIKEPolicyDataForm[n].error_label]
            }
        }

        setFormDataOptions(updatedDataFormOptions)
        return validation_faild
    }

    const formatRuleData = () => {
        let updated_data = {...formData}
        updated_data.lifetime = {}
        updated_data.lifetime["value"] = formData.lifetime
        updated_data.lifetime["units"] = "seconds"
        return updated_data
    }

    const onAddIkePolicy = async () => {
        const data_is_valid = handleFormDataValidation()
        if (data_is_valid) {
            const formatted_data = formatRuleData()
            const project_token = await getXAuthTokenProjectScope(defaultAdminProject)
            if (project_token) {
                const method = "POST"
                const vpnGroup_response = await openstackRequest({
                    url: `${neutronServiceDomain}/${neutronServiceVersion}/${vpnIKEPoliciesUrl}`, 
                    method: method, 
                    data: {ikepolicy: formatted_data},
                    token: project_token
                })
                if (vpnGroup_response.status_code === vpnUrlResponses.post.success_response.status_code) {
                    handleAddIkePolicyFormReset()
                    handleDataFetch()
                } else {
                    const error_response = vpnUrlResponses.post.error_response.filter(
                        error_item => error_item.status_code === vpnGroup_response.status_code)
                    if (error_response.length > 0) {
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: vpnGroup_response.error
                        }
                        setError(errorObject)
                    } else {
                        const error_response = vpnUrlResponses.post.error_response.filter(
                            error_item => error_item.status_code === "unknown")
                        const errorObject = {
                            error_title: error_response[0].response_title, 
                            error_message: error_response[0].response_message,
                            error_details: vpnGroup_response.error
                        }
                        setError(errorObject)
                    }
                }
            }
        } 
        return data_is_valid
    }

    useEffect(() => {
        setErrorDialogOpen(true)
    },[error]);

    useEffect(() => {
        setSuccessDialogOpen(true)
    },[successIkePolicyAdd]);

    useEffect(() => {
        if (Object.keys(formData).length === 0) {
            let new_form_data = {}
            for (const n in vpnIKEPolicyDataForm) {
                if (
                    vpnIKEPolicyDataForm[n].field_type === "string" || 
                    vpnIKEPolicyDataForm[n].field_type === "select"
                    ) {
                    new_form_data[vpnIKEPolicyDataForm[n].field_key] = vpnIKEPolicyDataForm[n].default_value ? 
                    vpnIKEPolicyDataForm[n].default_value : 
                    ""
                } else if (vpnIKEPolicyDataForm[n].field_type === "integer") {
                    new_form_data[vpnIKEPolicyDataForm[n].field_key] = 3600
                } else if (vpnIKEPolicyDataForm[n].field_type === "bool") {
                    new_form_data[vpnIKEPolicyDataForm[n].field_key] = vpnIKEPolicyDataForm[n].default_value ? 
                    vpnIKEPolicyDataForm[n].default_value : 
                    false
                }
            }
            setFormData(new_form_data)
        }
    },[
        formData
    ]);

    return (
        <WrapperBox 
            sx={{
                flexDirection: "row", 
                justifyContent: 'space-between',
                mt: 1
            }}>
            <Grid 
                container 
                justifyContent='space-between' 
                alignItems="center">
                
            <Grid item>
            <AddButton 
                getDataForm={getDataForm}               
                onSubmit={onAddIkePolicy}
                formReset={handleAddIkePolicyFormReset}
                customTexts={{
                    title: defaultTexts.addIkePolicyDialogTitle
                }}
            />
            </Grid>
            <Grid item sx={{flexGrow: 1}}>
            <Button
                onClick={() => handleTabChange("ike_policies")}
                sx={{
                    color: currentTab === "ike_policies" ? 
                    "lightBlue" : 
                    theme.palette.text.primary,
                    "&:hover": {
                        backgroundColor: "lightBlue",
                        color: "white"
                    }
                }}
            >
                {defaultTexts.ikePoliciesTabButtonText}
            </Button>
            <Button
                onClick={() => handleTabChange("ipsec_policies")}
                sx={{
                    color: currentTab === "ipsec_policies" ? 
                    "lightBlue" : 
                    theme.palette.text.primary,
                    "&:hover": {
                        backgroundColor: "lightBlue",
                        color: "white"
                    }
                }}
            >
                {defaultTexts.ipsecPoliciesTabButtonText}
            </Button>
            <Button
                onClick={() => handleTabChange("vpn_services")}
                sx={{
                    color: currentTab === "vpn_services" ? 
                    "lightBlue" : 
                    theme.palette.text.primary,
                    "&:hover": {
                        backgroundColor: "lightBlue",
                        color: "white"
                    }
                }}
            >
                {defaultTexts.vpnServicesTabButtonText}
            </Button>
            <Button
                onClick={() => handleTabChange("endpoint_groups")}
                sx={{
                    color: currentTab === "endpoint_groups" ? 
                    "lightBlue" : 
                    theme.palette.text.primary,
                    "&:hover": {
                        backgroundColor: "lightBlue",
                        color: "white"
                    }
                }}
            >
                {defaultTexts.endpointGroupsTabButtonText}
            </Button>
            <Button
                onClick={() => handleTabChange("site_connections")}
                sx={{
                    color: currentTab === "site_connections" ? 
                    "lightBlue" : 
                    theme.palette.text.primary,
                    "&:hover": {
                        backgroundColor: "lightBlue",
                        color: "white"
                    }
                }}
            >
                {defaultTexts.siteConnectionsTabButtonText}
            </Button>
                </Grid>
                <Grid item>
                <FilterButton 
                    choice_mode={Constants.simple_filter_type}
                    currentFilter={selectedFilter}
                    setCurrentFilter={setSelectedFilter}
                    filter_menu_titles={defaultTexts}
                    filter_menu={filterMenu}
                    currentFilterValue={selectedFilterValue}
                    setCurrentFilterValue={setSelectedFilterValue}
                    onFilterSubmit={handleFilteredSearch}
                    onFilterReset={handleFilterReset}
                />
                </Grid>
            </Grid>
            {successIkePolicyAdd && <CustomDialog
                open={successDialogOpen}
                onClose={handleSuccessDialogClose}
                dialogTitle={{
                    title: defaultTexts[successIkePolicyAdd.success_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts[successIkePolicyAdd.success_message], 
                    sx: {color: 'text.primary'}}}
            />}
            {error && <CustomDialog
                open={errorDialogOpen}
                onClose={handleErrorDialogClose}
                dialogTitle={{
                    title: defaultTexts[error.error_title], 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: `<span>${defaultTexts[error.error_message]}</span>
                            <br>
                            <br>
                            <span>${defaultTexts.detailsErrorNoteDialogText}</span> 
                            <span style="color: orange">
                                ${error.error_details}
                            </span>`, 
                    sx: {color: 'text.primary'}}}
            />}
        </WrapperBox>
    )
};

export default IKEPoliciesSubheaderV20