import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { alpha } from "@mui/material";
import WrapperBox from '../../../../_common/WrapperBox';
import CustomText from '../../../../_common/CustomText';
import Divider from '@mui/material/Divider';
import { Stack } from '@mui/material';
import { IconButton } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { openStackServices } from 
'../../../../../config/openStackConstants';
import { openstackRequest } from 
'../../../../../_network/openstack_request';
import useWindowDimensions from '../../../../_common/WindowDimensions';
import Dimensions from '../../../../../config/dimensions';
import CustomDialog from '../../../../_common/CustomDialog';

const SERVICE_NAME = openStackServices.identityService

const IdentityRoleAssignmentsV3 = (props) => {
    const { roleAssignments, handleRoleAssignmentChange } = props
    const { target } = props
    const { width } = useWindowDimensions();

    const [assignmentDeleteConfirmDialogOpen, setAssignmentDeleteConfirmDialogOpen] = useState(false);
    const [selectedScope, setSelectedScope] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const defaultTexts = useSelector(state => state.texts.langTexts)

    const theme = useTheme();

    const identityServiceDomain = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.service_domain)
    const identityServiceVersion = useSelector(
        state => state.openstack.purchasedServices.filter(
        service => service.service === SERVICE_NAME)[0].config_params.api_version)

    const onAssignmentDeleteConfirm = (item,scope) => {
        setSelectedScope(scope)
        setSelectedItem(item)
        setAssignmentDeleteConfirmDialogOpen(true)
    }

    const handleassignmentDeleteConfirmDialogClose = () => {
        setSelectedScope(null)
        setSelectedItem(null)
        setAssignmentDeleteConfirmDialogOpen(false)
    }

    const onAssignmentDelete = async () => {
        if (selectedItem && selectedScope) {
            
            let target_type = null
            let target_id = null
            if (Object.keys(selectedItem).includes("user")) {
                target_type = "users"
                target_id = selectedItem.user.id
            } else {
                target_type = "groups"
                target_id = selectedItem.group.id
            }

            const method = "DELETE"
            let url = ""

            if (selectedScope === "system") {
                url = `${identityServiceDomain}/${identityServiceVersion}/system/${target_type}/${target_id}/roles/${selectedItem.role.id}`
            } else if (selectedScope === "domain") {
                url = `${identityServiceDomain}/${identityServiceVersion}/domains/${selectedItem.scope.domain.id}/${target_type}/${target_id}/roles/${selectedItem.role.id}`
            } else if (selectedScope === "project") {
                url = `${identityServiceDomain}/${identityServiceVersion}/projects/${selectedItem.scope.project.id}/${target_type}/${target_id}/roles/${selectedItem.role.id}`
            }

            if (url.length > 0) {
                await openstackRequest({url: url, method: method})
                handleRoleAssignmentChange()
            }
            handleassignmentDeleteConfirmDialogClose()
        }
    }

    const getScopeAssignments = (scope) => {
        const system_scope_list = roleAssignments.filter(
            item => Object.keys(item.scope).includes(scope)
        )

        const object_list = system_scope_list.map(item => {
            const role_name = item.role.name
            const role_domain = Object.keys(item.role).includes("domain") ? item.role.domain.name : null
            let scope_name = null
            if (scope === "domain") {
                scope_name = item.scope.domain.name
            } else if (scope === "project") {
                scope_name = item.scope.project.name
            }
            
            return (
                <WrapperBox
                    sx={{
                        backgroundColor: alpha(theme.palette.primary.main, 0.5),
                        border: 1,
                        borderColor: 'vLightGray',
                        borderRadius: 1,
                        px: 2,
                        py: 2,
                        minWidth: 250
                    }}
                >
                    <Stack 
                        direction="row" 
                        spacing={1}
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <div>
                            <div>
                                {`${defaultTexts.roleFormFieldLabel}: ${role_name}`}
                                <CustomText 
                                    size="p" 
                                    sx={{
                                        fontStyle: 'italic', 
                                        color: theme.palette.text.secondary
                                    }}>
                                        {role_domain && ` (${role_domain})`}
                                </CustomText>
                            </div>
                            {scope_name && 
                                <div>
                                    {defaultTexts.scopeFormFieldLabel}: {scope_name}
                                </div>
                            }
                        </div>
                        <IconButton
                            onClick={() => onAssignmentDeleteConfirm(item,scope)}
                        >
                            <DeleteIcon />
                        </IconButton>
                    </Stack>
                </WrapperBox>
            )
        })

        return (
            <Stack direction="column" spacing={1}>
            {object_list.length > 0 ? object_list : 
                <WrapperBox
                    sx={{
                        border: 1,
                        borderColor: 'vLightGray',
                        borderRadius: 1,
                        px: 2,
                        py: 2,
                        minWidth: 250
                    }}
                >
                    {defaultTexts.noRoleAssignmentsNoteText}
                </WrapperBox>
            }
            </Stack>
        )
    }

    return (
        <WrapperBox>
            <WrapperBox
                sx={{
                    border: 2,
                    borderColor: 'secondary.main',
                    backgroundColor: alpha(theme.palette.secondary.main, 0.2),
                    borderRadius: 50,
                    px: 2,
                    py: 2,
                    minWidth: 250,
                    justifyContent: "center"
                }}
            >
                {target.toUpperCase()}
            </WrapperBox>
            
            <Stack
                direction={width > Dimensions.tablet.width ? "row" : "column"}
                spacing={width > Dimensions.tablet.width ? 0 : 1}
                alignItems="center"
                justifyContent="space-between"
                sx={{width: '83%', mt: 3}}
            >
                <WrapperBox
                    sx={{
                        backgroundColor: alpha(theme.palette.secondary.main, 0.2),
                        border: 2,
                        borderColor: 'secondary.main',
                        borderRadius: 50,
                        px: 2,
                        py: 2,
                        minWidth: 250
                    }}
                >
                    {defaultTexts.roleSystemScopeTitle}
                </WrapperBox>
                {width <= Dimensions.tablet.width && getScopeAssignments("system")}
                {width <= Dimensions.tablet.width && <Divider 
                    orientation="vertical" 
                    variant="middle" 
                    sx={{
                        height: 50,
                        m:0,
                        border: 1.5,
                        borderColor: 'secondary.main'
                    }}
                />}
                <WrapperBox
                    sx={{
                        backgroundColor: alpha(theme.palette.secondary.main, 0.2),
                        border: 2,
                        borderColor: 'secondary.main',
                        borderRadius: 50,
                        px: 2,
                        py: 2,
                        minWidth: 250
                    }}
                >
                    {defaultTexts.roleDomainScopeTitle}
                </WrapperBox>
                {width <= Dimensions.tablet.width && getScopeAssignments("domain")}
                {width <= Dimensions.tablet.width && <Divider 
                    orientation="vertical" 
                    variant="middle" 
                    sx={{
                        height: 50,
                        m:0,
                        border: 1.5,
                        borderColor: 'secondary.main'
                    }}
                />}
                <WrapperBox
                    sx={{
                        backgroundColor: alpha(theme.palette.secondary.main, 0.2),
                        border: 2,
                        borderColor: 'secondary.main',
                        borderRadius: 50,
                        px: 2,
                        py: 2,
                        minWidth: 250
                    }}
                >
                    {defaultTexts.roleProjectScopeTitle}
                </WrapperBox>
                {width <= Dimensions.tablet.width && getScopeAssignments("project")}
            </Stack>
            {width > Dimensions.tablet.width && <Stack
                direction="row"
                alignItems="start"
                justifyContent="space-between"
                sx={{width: '83%', mt: 3}}
            >
                {getScopeAssignments("system")}
                {getScopeAssignments("domain")}
                {getScopeAssignments("project")}
            </Stack>}
            <CustomDialog
                open={assignmentDeleteConfirmDialogOpen}
                onClose={handleassignmentDeleteConfirmDialogClose}
                dialogTitle={{
                    title: defaultTexts.deleteRoleAssignmentDialogTitle, 
                    sx: {color: 'primary.main'}}}
                dialogBody={{
                    text: defaultTexts.deleteRoleAssignmentDialogMessage, 
                    sx: {color: 'text.primary'}}}
                actionButtons={[{
                    title: defaultTexts.deleteButtonText, 
                    onClick: onAssignmentDelete, 
                    sx: {color: 'primary.main'}}]}
            />
        </WrapperBox>
    )
}

export default IdentityRoleAssignmentsV3;
